import React, { useState, useEffect, useRef, useMemo } from "react";

import emptyAlbums from "../../../imgs/emptyAlbums.svg";
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
} from "firebase/firestore";
import { Box, Button, Typography, CircularProgress } from "@mui/material";

import { firebaseApp } from "../../../firebaseInit";
import NewGenImageCard from "./childAtoms/NewGenImageCard";
import EmptyGenCard from "./childAtoms/EmptyGenCard";

const ITEMS_PER_PAGE = 3;

const GeneratedAlbumsBox = ({ user }) => {
    const db = getFirestore(firebaseApp);

    const [loading, setLoading] = useState(true);
    const [singleImages, setSingleImages] = useState([]);
    const [allImagesData, setAllImagesData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const sortAlbumsByDate = (albums) => {
        // Make a copy of the original array to avoid mutating it
        const sortedAlbums = albums.slice();

        // Sort the copy based on the 'addedAt' property
        sortedAlbums.sort((a, b) => {
            return b.addedAt - a.addedAt;
        });

        return sortedAlbums;
    };

    useEffect(() => {
        if (user?.email) {
            const q = query(
                collection(db, "tuned_models"),
                where("email", "==", user.email),
                orderBy("created_at", "desc")
            );

            const unsubscribe = onSnapshot(
                q,
                (snapshot) => {
                    const singleImagesData = [];
                    const allImages = [];
                    snapshot.forEach((doc) => {
                        const data = doc.data();
                        console.log(data.generations, "--data.generations--");
                        if (data.generations) {
                            data.generations.forEach((generation) => {
                                if (generation.results) {
                                    generation.results.forEach((result) => {
                                        if (result.image) {
                                            allImages.push(result);
                                        }
                                    });
                                    singleImagesData.push({ ...generation });
                                }
                            });
                        }
                    });

                    //setSingleImages(singleImagesData);
                    setAllImagesData(allImages);

                    const sortedImagesData = sortAlbumsByDate(singleImagesData);
                    //console.log(allImages, "--allImagesData--");
                    console.log(singleImagesData, "--singleImagesData2--");

                    console.log(sortedImagesData, "--myArr23--");
                    //setSingleImages([]);
                    setSingleImages(sortedImagesData);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                    setLoading(false);
                }
            );

            // Cleanup the listener on component unmount
            return () => unsubscribe();
        }
    }, [user?.email, db]);

    const totalPages = useMemo(() => {
        return Math.ceil(singleImages.length / ITEMS_PER_PAGE);
    }, [singleImages]);

    const paginatedData = useMemo(() => {
        return singleImages.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            currentPage * ITEMS_PER_PAGE
        );
    }, [singleImages, currentPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) return <CircularProgress size={50} />;

    return (
        <Box sx={{ width: "100%", minHeight: "600px" }}>
            <Box
                sx={{
                    gap: "1rem",
                    flexWrap: "wrap",
                    height: "100%",
                    paddingY: "1.3rem",
                    paddingX: "0",
                    maxHeight: "900px",
                    overflowY: "scroll",
                    alignItems: { xs: "center", md: "left" },
                    justifyContent: { xs: "center", md: "flex-start" },
                }}
            >
                {paginatedData.length > 0 ? (
                    paginatedData.map((it, idx) => (
                        <Box
                            key={idx + 1}
                            sx={{
                                width: "100%",
                                paddingBottom: "2rem",
                                marginBottom: "2rem",
                                borderBottom:
                                    "1px solid rgba(128, 128, 128, 0.22)",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",

                                    letterSpacing: "-0.576px",
                                    fontSize: { xs: "18px", md: "24px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: {
                                        xs: "20px",
                                        md: "26px",
                                    } /* 114.583% */,
                                    textAlign: { xs: "center", md: "left" },
                                    marginBottom: "1rem",
                                }}
                            >
                                {it.template}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "0.8rem",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: {
                                        xs: "center",
                                        md: "flex-start",
                                    },
                                }}
                            >
                                {it.results.map((item, index) => (
                                    <NewGenImageCard
                                        item={item}
                                        index={index}
                                        key={item.id}
                                        allImages={it.results}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))
                ) : (
                    <EmptyGenCard
                        emptyTitle={"No Albums Created!"}
                        emptyDescription={
                            "Start by choosing a model or selecting from our preset themes to generate personalized albums."
                        }
                        emptyImgSrc={emptyAlbums}
                    />
                )}
            </Box>

            {singleImages.length > ITEMS_PER_PAGE && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "1.5rem 0",
                    }}
                >
                    <Box sx={{ width: "fit-content" }}>
                        <Button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            sx={{
                                background: "#1E293B",
                                color: "white",
                                textTransform: "capitalize",
                                "&.Mui-disabled": {
                                    color: "white",
                                    opacity: 0.5,
                                },
                                "&:hover": {
                                    background: "black",
                                    color: "white",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    /* body-medium */
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Prev
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                margin: "0 20px",
                                background: "#4338CA",
                                color: "white",
                                borderRadius: "0.6rem",
                                wdith: "32px",
                                height: "32px",
                                padding: "0px",
                                "&:hover": {
                                    background: "#4338CA",
                                    color: "white",
                                },
                                pointerEvents: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    /* body-medium */
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                {currentPage}
                            </Typography>
                        </Button>
                        <Button
                            onClick={nextPage}
                            disabled={currentPage >= totalPages}
                            sx={{
                                background: "#1E293B",
                                color: "white",
                                textTransform: "capitalize",
                                "&.Mui-disabled": {
                                    color: "white",
                                    opacity: 0.5,
                                },
                                "&:hover": {
                                    background: "black",
                                    color: "white",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    /* body-medium */
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Next
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default GeneratedAlbumsBox;
