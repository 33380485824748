import React, { useState, useEffect, useMemo } from "react";
import {
    Typography,
    Box,
    Button,
    IconButton,
    Grid,
    CircularProgress,
} from "@mui/material";

import {
    HiOutlineDownload,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
} from "react-icons/hi";
import SingleImageCard from "./SingleImageCard";
import { saveAs } from "file-saver";

const ProGeneratedImages = ({
    user,
    generatedImageUrlArr,
    resetStates,
    proGenImagesLoading,
    newHeadshotsDocId,
    albumNameValue
}) => {
    const [isFullView, setIsFullView] = useState(false);
    const [imageForFullViewIndex, setImageForFullViewIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [feedbackFormSubmitted, setFeedbackFormSubmitted] = useState(false);

    const itemsPerPage = 12;

    const handleDownloadClick = () => {
        saveAs(generatedImageUrlArr[imageForFullViewIndex], "headshot-download.png");
    };

    const paginatedSrcs2 = [
        "https://replicate.delivery/pbxt/0G4DtfAKQWVTfEBGrzGelwoh8FNfOxxxbDe5eU7KTDbp5E7jE/1706103525.jpg",
        "https://replicate.delivery/pbxt/SfXtH9PqFByXTaY518PMcK4RohpsQS2GYwF8Rrl08217J2HJA/1706103543.jpg",
        "https://replicate.delivery/pbxt/jX49AqzgRXLOJtRYY0F2v6tbXHVvtU2NzDwJaXqtTIXCF7jE/1706103561.jpg",
    ]





    const paginatedSrcs = useMemo(() => {
        const startIdx = (currentPage - 1) * itemsPerPage;
        const endIdx = startIdx + itemsPerPage;
        return generatedImageUrlArr.slice(startIdx, endIdx);
    }, [generatedImageUrlArr, currentPage, itemsPerPage]);

    const totalPages = Math.ceil(generatedImageUrlArr.length / itemsPerPage);

    const openFullView = () => {
        setIsFullView(true);
    };

    const changeFullViewImgIndex = (index) => {
        setImageForFullViewIndex(index);
    };

    const incrementFullViewImgIndex = () => {
        // if (imageForFullViewIndex + 1 > paginatedSrcs.length - 1) {
        if (imageForFullViewIndex + 1 > generatedImageUrlArr.length - 1) {
            setImageForFullViewIndex(imageForFullViewIndex);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex + 1);
        }
    };
    const decrementFullViewImgIndex = () => {
        setImageForFullViewIndex(imageForFullViewIndex + 1);
        if (imageForFullViewIndex - 1 < 0) {
            setImageForFullViewIndex(0);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex - 1);
        }
    };
    /* const [vidProgress, setVidProgress] = useState(item.id); */



    return (
        <Box>
            {isFullView && (
                <Box
                    sx={{
                        background: "rgba(15, 23, 42, 0.4)",
                        position: "fixed",
                        backdropFilter: "blur(5px)",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        top: "0",
                        zIndex: "1000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            console.log("Outer box clicked");
                            setIsFullView(false);
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: "0.4rem", md: "1.4rem" },
                        }}
                    >
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={decrementFullViewImgIndex}
                        >
                            <HiOutlineChevronLeft size={25} />
                        </IconButton>
                        <Box
                            sx={{
                                width: { xs: "210px", md: "460px" },
                                height: { xs: "210px", md: "460px" },
                                position: "relative",
                                borderRadius: "15px",
                            }}
                        >
                            <img
                                alt=""
                                src={generatedImageUrlArr[imageForFullViewIndex]}
                                /* src={paginatedSrcs[imageForFullViewIndex]} */
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                            <IconButton
                                sx={{
                                    background: "white",
                                    color: "#0F172A",
                                    "&:hover": {
                                        background: "#6363FF",
                                        color: "white",
                                    },
                                    position: "absolute",
                                    top: "12px",
                                    right: "12px",
                                }}
                                onClick={handleDownloadClick}
                            >
                                <HiOutlineDownload size={25} />
                            </IconButton>
                        </Box>
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={incrementFullViewImgIndex}
                        >
                            <HiOutlineChevronRight size={25} />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    background: "#0F172A",
                    color: "white",
                    padding: { xs: "0.7rem 0.5rem", md: "1rem 6rem" },
                    minHeight: "90vh",
                    width: "100%",
                }}
            >
                {" "}
                <Box
                    sx={{
                        marginTop: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#FFF",
                            /* h2 */
                            fontFamily: "Inter",
                            fontSize: { xs: "20px", md: "30px" },
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: { xs: "23px", md: "36px" },
                            letterSpacing: "-0.225px",
                        }}
                    >
                        {albumNameValue !== "" ? `Album: ${albumNameValue}` : "Your Headshots"}
                    </Typography>
                    <Button
                        onClick={() => {
                            //navigate("/products/headshot");
                            //window.location.reload();
                            resetStates();
                            //console.log(myHeadshotsData, "--imageSrcs--");
                        }}
                        sx={{
                            background: "#6363FF",
                            color: "white",
                            borderRadius: "70px",
                            textTransform: "capitalize",
                            padding: "12px 27px",
                            /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                            "&:hover": {
                                background: "#4E4EC8",
                                color: "white",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: { xs: "14px", md: "16px" },
                            }}
                        >
                            Generate Again
                        </Typography>
                    </Button>
                </Box>
                {/* <div>
                    <img src={generatedImageUrlArr[0]} />
                </div> */}
                {/* {proGenImagesLoading || generatedImageUrlArr.length < 1 ? ( */}
                {proGenImagesLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "500px",
                        }}
                    >
                        <CircularProgress size={90} />
                    </Box>
                ) : (
                    <>
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                marginTop: "1rem",
                            }}
                        >
                            {generatedImageUrlArr.map((headshot, index) => (
                                <SingleImageCard
                                    key={index}
                                    headshot={headshot}
                                    openFullView={openFullView}
                                    changeFullViewImgIndex={
                                        changeFullViewImgIndex
                                    }
                                    index={index}
                                    newHeadshotsDocId={newHeadshotsDocId}
                                    feedbackFormSubmitted={feedbackFormSubmitted}
                                    setFeedbackFormSubmitted={setFeedbackFormSubmitted}
                                    albumNameValue={albumNameValue}
                                />
                            ))}
                        </Grid>
                        <Box
                            sx={{
                                display: "none",
                                /* display: "flex", */
                                justifyContent: "flex-end",
                                margin: "1.5rem 0",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "fit-content",
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.max(prev - 1, 1)
                                        )
                                    }
                                    sx={{
                                        background: "#1E293B",
                                        color: "white",
                                        textTransform: "capitalize",
                                    }}
                                    disabled={currentPage === 1}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Prev
                                    </Typography>
                                </Button>
                                <Button
                                    sx={{
                                        margin: "0 20px",
                                        background: "#4338CA",
                                        color: "white",
                                        borderRadius: "0.6rem",
                                        wdith: "32px",
                                        height: "32px",
                                        padding: "0px",
                                        "&:hover": {
                                            background: "#4338CA",
                                            color: "white",
                                        },
                                        pointerEvents: "none",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        {currentPage}
                                    </Typography>
                                </Button>
                                {/* <Button
                                    sx={{
                                        margin: "0 20px",
                                        background: "#4338CA",
                                    }}
                                >
                                    Page {currentPage} of {totalPages}
                                </Button> */}
                                <Button
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.min(prev + 1, totalPages)
                                        )
                                    }
                                    disabled={currentPage === totalPages}
                                    sx={{
                                        background: "#1E293B",
                                        color: "white",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Next
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ProGeneratedImages;
