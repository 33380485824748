import React, { useState, useEffect, useRef } from "react";
import avatarsGenSuccessConfetti from "../../imgs/avatarsGenSuccessConfetti.svg";

import { Box, Button, IconButton, Typography } from "@mui/material";
import { HiOutlineX } from "react-icons/hi";
import { useTheme } from "@mui/material/styles";
import NewModelSidebarBox from "./Atoms/NewModelSidebarBox";
import SidebarInputBox from "./Atoms/SidebarInputBox";
import MainAvatarsComponent from "./Atoms/MainAvatarsComponent";
import NewModelPopup from "./Atoms/childAtoms/NewModelPopup";
import FooterV2 from "../footer/FooterV2";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { firebaseApp } from "../../firebaseInit";
import AiAvatarsLayout from "../layout/AiAvatarsLayout";
import { useAPI } from "../../StateProvider";
import OnboardingPopup from "./Atoms/OnboardingPopup";

const AiAvatars = ({ user }) => {
    const db = getFirestore(firebaseApp);

    const { data } = useAPI();

    const theme = useTheme();
    const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);

    const [isModelPopupOpen, setIsModelPopupOpen] = useState(false);
    const [activeModel, setActiveModel] = useState({});

    const [isPresetsSelected, setIsPresetsSelected] = useState(true);
    const [selectedPresets, setSelectedPresets] = useState([]);

    const [currentJobDocId, setCurrentJobDocId] = useState(null);
    //const [currentJobDocId, setCurrentJobDocId] = useState(null);
    //const [currentJobDocId, setCurrentJobDocId] = useState(null);
    const [currentJobData, setCurrentJobData] = useState(null);

    const [isGalleryEnabled, setIsGalleryEnabled] = useState(false);

    const toggleModelPopup = () => {
        setIsModelPopupOpen(!isModelPopupOpen);
    };
    const [promptVal, setPromptVal] = useState("");

    const handleModelOptionClick = (currModel) => {
        setActiveModel({ ...currModel });
        console.log({ ...currModel });
    };

    const handleViewImageClickToAlbums = () => {
        console.log("handleViewImageClickToAlbums");
        closeGeneratedAlbumsSuccessPopup();
        setIsGalleryEnabled(!isGalleryEnabled);
    };

    const closeGeneratedAlbumsSuccessPopup = () => {
        setCurrentJobDocId(null);
        setCurrentJobData(null);
        setSelectedPresets([]);
        //setExtraBool(false);
    };

    useEffect(() => {
        if (user?.email && currentJobDocId) {
            const docRef = doc(db, "jobs", currentJobDocId);

            const unsubscribe = onSnapshot(
                docRef,
                (doc) => {
                    if (doc.exists()) {
                        const docData = doc.data();
                        setCurrentJobData(docData);
                        console.log("Document data:", docData);
                    } else {
                        console.log("No such document!");
                    }
                },
                (error) => {
                    console.error("Error fetching document: ", error);
                }
            );

            // Cleanup the listener on component unmount
            return () => unsubscribe();
        }
    }, [user?.email, db, currentJobDocId]);

    const prevAvatarModelsCredits = useRef(undefined);
    const prevAvatarPhotosCredits = useRef(undefined);
    const prevAvatarFreeCredits = useRef(undefined);
    const prevAvatarFreeAlbumsCredits = useRef(undefined);

    useEffect(() => {
        if (data?.hasOwnProperty("credits")) {
            console.log("retrieved");
            if (
                prevAvatarModelsCredits.current === undefined &&
                prevAvatarPhotosCredits.current === undefined &&
                prevAvatarFreeCredits.current === undefined &&
                prevAvatarFreeAlbumsCredits.current === undefined
            ) {
                console.log("dsda");
                prevAvatarModelsCredits.current = data?.avatar_models;
                prevAvatarPhotosCredits.current = data?.avatar_photos;
                prevAvatarFreeCredits.current = data?.avatarsFreeCredits;
                prevAvatarFreeAlbumsCredits.current =
                    data?.avatarsFreeAlbumsCredits;
            }
            if (
                prevAvatarModelsCredits.current === data?.avatar_models &&
                prevAvatarPhotosCredits.current === data?.avatar_photos &&
                prevAvatarFreeCredits.current === data?.avatarsFreeCredits &&
                prevAvatarFreeAlbumsCredits.current ===
                    data?.avatarsFreeAlbumsCredits
            ) {
                if (
                    data?.avatar_subscription?.status !== "active" ||
                    !data?.avatar_subscription ||
                    !data?.avatar_subscription?.status
                ) {
                    setTimeout(() => {
                        setIsOnboardingOpen(true);
                    }, 1000);
                } else {
                    setIsOnboardingOpen(false);
                }
            }
        } else {
            console.log("not retrieved yet");
        }

        // Update the refs for the next render
        prevAvatarModelsCredits.current = data?.avatar_models;
        prevAvatarPhotosCredits.current = data?.avatar_photos;
        prevAvatarFreeCredits.current = data?.avatarsFreeCredits;
        prevAvatarFreeAlbumsCredits.current = data?.avatarsFreeAlbumsCredits;
    }, [data]);

    return (
        <AiAvatarsLayout footer={<FooterV2 />}>
            {isOnboardingOpen ? (
                <OnboardingPopup
                    isOnboardingOpen={isOnboardingOpen}
                    setIsOnboardingOpen={setIsOnboardingOpen}
                />
            ) : null}
            {isModelPopupOpen ? (
                <NewModelPopup
                    isModelPopupOpen={isModelPopupOpen}
                    toggleModelPopup={toggleModelPopup}
                />
            ) : null}
            {currentJobData?.status === "succeeded" && (
                <Box
                    sx={{
                        background: "rgba(27, 15, 70, 0.6)",
                        backdropFilter: "blur(2px)",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        zIndex: 101,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            background: "white",
                            padding: "3rem",
                            borderRadius: "0.5rem",
                            maxWidth: { xs: "90vw", md: "700px" },
                            maxHeight: "600px",
                            overflowY: "scroll",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "12px",
                                right: "12px",
                            }}
                            onClick={() => closeGeneratedAlbumsSuccessPopup()}
                        >
                            <HiOutlineX size={25} />
                        </IconButton>
                        <img
                            alt=""
                            src={avatarsGenSuccessConfetti}
                            style={{
                                width: "120px",
                                marginBottom: "1.4rem",
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: "1.7rem", md: "2.2rem" },
                                fontWeight: "800",
                                fontFamily: "Inter",
                                marginBottom: "0.1rem",
                                textAlign: "center",
                            }}
                        >
                            Your images are ready
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: { xs: "0.9rem", md: "1.1rem" },
                                fontWeight: "400",
                                fontFamily: "Inter",
                                marginBottom: "0.6rem",
                                textAlign: "center",
                            }}
                        >
                            Step into the galllery to view all of your AI
                            crafted creations
                        </Typography>
                        <Button
                            sx={{
                                background:
                                    "linear-gradient(47deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                                /* background:
                                        "linear-gradient(75deg, #EF54FE 0%, #8980FF 57.81%, #62B5FF 100%)", */
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                gap: "11px",
                                textTransform: "capitalize",
                                fontWeight: "600",
                                transform: "transition all 0.3s ease-in",

                                "&:hover": {
                                    background:
                                        "linear-gradient(27deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                                },
                                width: "fit-content",
                                fontSize: { xs: "1.1rem", md: "1.2rem" },
                                fontFamily: "Inter",
                                textAlign: "center",
                                paddingX: "1rem",
                                borderRadius: "0.56rem",
                                marginTop: "1.6rem",
                            }}
                            onClick={() => handleViewImageClickToAlbums()}
                        >
                            View Images
                        </Button>
                    </Box>
                </Box>
            )}

            <Box
                sx={{
                    background:
                        theme.palette.mode === "light" ? "#FBFCFF" : "#121212",
                }}
            >
                <Box
                    sx={{
                        padding: { xs: "0.7rem 0.5rem", md: "0.8rem 4rem" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "2rem",
                            minHeight: "80vh",
                        }}
                    >
                        <Box
                            sx={{
                                border:
                                    theme.palette.mode === "light"
                                        ? "1px solid rgba(0, 0, 0, 0.07)"
                                        : "1px solid #5F6A79",
                                paddingTop: "40px",
                                width: "348px",
                                paddingX: "23px",
                                /* boxShadow: "1px 0px 0px 0px #F6F6F6", */
                                paddingBottom: "40px",
                                borderRadius: "0.3rem",
                            }}
                        >
                            <NewModelSidebarBox
                                toggleModelPopup={toggleModelPopup}
                                user={user}
                                handleModelOptionClick={handleModelOptionClick}
                                activeModel={activeModel}
                                setSelectedPresets={setSelectedPresets}
                            />
                            <SidebarInputBox
                                user={user}
                                promptVal={promptVal}
                                setPromptVal={setPromptVal}
                                handleModelOptionClick={handleModelOptionClick}
                                activeModel={activeModel}
                                setCurrentJobDocId={setCurrentJobDocId}
                                isPresetsSelected={isPresetsSelected}
                                selectedPresets={selectedPresets}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <MainAvatarsComponent
                                setPromptVal={setPromptVal}
                                user={user}
                                setIsPresetsSelected={setIsPresetsSelected}
                                setSelectedPresets={setSelectedPresets}
                                selectedPresets={selectedPresets}
                                isGalleryEnabled={isGalleryEnabled}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AiAvatarsLayout>
    );
};

export default AiAvatars;
