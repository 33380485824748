import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Divider, IconButton } from "@mui/material";
import VideoPlayer from "./VideoPlayer";
import { saveAs } from "file-saver";
import {
    HiOutlineRefresh,
    HiOutlineDownload,
    HiOutlineThumbUp,
    HiOutlineThumbDown,
    HiOutlineCheckCircle,
    HiOutlineExclamation,
} from "react-icons/hi";
import ToastNotification from "../../atoms/ToastNotification";
import VideoSnackbar from "./VideoSnackbar";
import VideoPlayerSm from "../../MyVideos/VideoPlayerSm";
import ReelVideoPlayer from "./ReelVideoPlayer";
import {
    addDoc,
    collection,
    getFirestore,
    serverTimestamp,
    query,
    where,
    onSnapshot,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    increment,
} from "firebase/firestore";
import { firebaseApp } from "../../../firebaseInit";
import DarkToastNotification from "../../atoms/DarkToastNotification";
import VideoFeedbackModal from "./VideoFeedbackModal";

const OutputPhaseMain = ({
    setIsInputPhase,
    generatedVideoSrc,
    showApiError,
    setGeneratedVideoSrc,
    userInputObject,
    setUserInputObject,
}) => {
    const [progress, setProgress] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [text, setText] = useState(
        "🎬 Setting the stage for your masterpiece!"
    );

    const [likeUpdateSuccess, setLikeUpdateSuccess] = useState(false);
    const [dislikeUpdateSuccess, setDislikeUpdateSuccess] = useState(false);

    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const displayToast = (message, error = false) => {
        setToastMessage(message);
        setIsError(error);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
    };

    const db = getFirestore(firebaseApp);

    useEffect(() => {
        console.log(generatedVideoSrc, "generatedVideoSrc1");
        console.log(userInputObject, "userInputObject");
        const simulateProgress = () => {
            let currentProgress = 0;
            const interval = setInterval(() => {
                currentProgress += 1;
                setProgress(currentProgress);

                // Update text at specific progress percentages
                if (currentProgress === 20) {
                    setText("🌈 Molding your text into vibrant visuals.");
                } else if (currentProgress === 40) {
                    setText(
                        "⌛ Aligning the perfect voice and tone to your content."
                    );
                } else if (currentProgress === 60) {
                    setText("🌟 Adding the sparkle. Almost there!");
                } else if (currentProgress === 80) {
                    setText(
                        "🎉 Your video is almost ready! Time to impress your audience"
                    );
                } else if (currentProgress === 90) {
                    // Enable the Download button at 90%
                }

                if (currentProgress >= 90) {
                    clearInterval(interval);
                }
            }, 2667); // Using the calculated interval of approximately 2.67 seconds
        };

        if (!generatedVideoSrc) {
            simulateProgress();
        }
    }, []);

    useEffect(() => {
        console.log(generatedVideoSrc, "generatedVideoSrc2");
        //if (generatedVideoSrc && progress === 90) {
        if (generatedVideoSrc) {
            setProgress(100);
            setButtonDisabled(false);
        }
    }, [generatedVideoSrc, progress]);

    const handleDownload = () => {
        // Implement download logic here
        saveAs(generatedVideoSrc);
    };

    const handleLikeClick = async () => {
        const docRef = doc(db, "Idea2Motions", userInputObject.docId);

        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const newLikeCount = data.likeCount ? data.likeCount + 1 : 1;
                const newDislikeCount = data.dislikeCount
                    ? data.dislikeCount
                    : 0;

                await updateDoc(docRef, {
                    likedByUser: true,
                    likeCount: newLikeCount,
                    dislikeCount: newDislikeCount,
                });

                setLikeUpdateSuccess(true);
                setTimeout(() => setLikeUpdateSuccess(false), 2000);
                displayToast("Liked successfully!");
            } else {
                console.log("Document does not exist!");
                displayToast("Error updating like!", true);
            }
        } catch (error) {
            console.error("Error in handleLikeClick:", error);
        }
    };

    const handleDislikeClick = async () => {
        const docRef = doc(db, "Idea2Motions", userInputObject.docId);

        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                const newLikeCount = data.likeCount ? data.likeCount : 0;
                const newDislikeCount = data.dislikeCount
                    ? data.dislikeCount + 1
                    : 1;

                await updateDoc(docRef, {
                    likedByUser: false,
                    likeCount: newLikeCount,
                    dislikeCount: newDislikeCount,
                });

                setDislikeUpdateSuccess(true);
                setTimeout(() => setDislikeUpdateSuccess(false), 2000);
                displayToast("Disliked successfully!");

                setShowFeedbackModal(true);
            } else {
                console.log("Document does not exist!");
                displayToast("Error updating dislike!", true);
            }
        } catch (error) {
            console.error("Error in handleDislikeClick:", error);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {showFeedbackModal && (
                <VideoFeedbackModal
                    showFeedbackModal={showFeedbackModal}
                    setShowFeedbackModal={setShowFeedbackModal}
                    userInputObject={userInputObject}
                />
            )}
            {showToast && (
                <DarkToastNotification
                    message={toastMessage}
                    type={isError ? "error" : "success"}
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {/* {showToast && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        left: "0",
                        transform: showToast
                            ? "translateX(0)"
                            : "translateX(-100%)",
                        opacity: showToast ? 1 : 0,
                        backgroundColor: isError ? "red" : "green",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        transition:
                            "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
                        zIndex: 1000,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    {isError ? (
                        <HiOutlineExclamation />
                    ) : (
                        <HiOutlineCheckCircle />
                    )}
                    {toastMessage}
                </div>
            )} */}
            {/* <ToastNotification
                message="Your video will be generated soon.."
                type="success"
                duration={3000}
                position="bottomLeft"
            /> */}
            <VideoSnackbar
                message="Your video will be generated soon.."
                type="success"
                duration={3000}
                position="bottomLeft"
            />

            {showApiError && (
                <ToastNotification
                    message="An error occurred!"
                    type="error"
                    /* type="neutral" */
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {/* {progress < 100 && !generatedVideoSrc ? (
                <>
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            letterSpacing: "-0.576px",
                            fontSize: { xs: "22px", md: "30px" },
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: {
                                xs: "24px",
                                md: "36px",
                            },
                            textAlign: { xs: "center", md: "left" },
                            textAlign: "center",
                            marginBottom: "28px",
                        }}
                    >
                        Crafting Your Video...
                    </Typography>
                    <Box
                        sx={{
                            minWidth: { xs: "92vw", md: "750px" },
                            minHeight: { xs: "220px", md: "450px" },
                            position: "relative",
                            borderRadius: "2rem",
                            marginBottom: "32px",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Inter",
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "22px", md: "30px" },
                                fontStyle: "normal",
                                fontWeight: "800",
                                lineHeight: {
                                    xs: "24px",
                                    md: "36px",
                                },
                                textAlign: { xs: "center", md: "left" },
                                textAlign: "center",
                                marginBottom: "28px",
                                zIndex: "1000",
                                position: "absolute",
                                left: "50%",
                                top: "45%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            {progress} %
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: "5px",
                                background: "rgba(0, 0, 0, 0.01)",
                                backdropFilter: "blur(16px)",
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                top: "0",
                                zIndex: "100",
                                borderRadius: "inherit",
                            }}
                        ></Box>
                        <img
                            src="/assets/dummyVideoCoverImage.png"
                            style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                top: "0",
                                zIndex: "50",
                                borderRadius: "inherit",
                            }}
                            alt="Video Cover"
                        />
                    </Box>
                    <>
                        <Box
                            sx={{
                                width: { xs: "330px", md: "482px" },
                                height: "9px",
                                background: "#334155",
                                borderRadius: "50px",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    background:
                                        "linear-gradient(90deg, #0068EE -0.9%, #BE76FF 102.66%)",
                                    width: `${progress}%`,
                                    height: "100%",
                                    transition: "width 0.3s ease-in-out",
                                    borderRadius: "inherit",
                                }}
                            ></Box>
                        </Box>
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Inter",
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "1.1rem", md: "20px" },
                                fontStyle: "normal",
                                fontWeight: "800",
                                textAlign: { xs: "center", md: "left" },
                                textAlign: "center",
                                marginBottom: "8px",
                                marginTop: "24px",
                                opacity: 1, // Initialize opacity
                                transition: "opacity 0.3s ease-in-out",
                            }}
                            style={{ opacity: progress === 0 ? 0 : 1 }}
                        >
                            {text}
                        </Typography>{" "}
                    </>
                </>
            ) : (
                <Box
                    sx={{
                        minWidth: { xs: "92vw", md: "750px" },
                        minHeight: { xs: "220px", md: "450px" },
                        position: "relative",
                        borderRadius: "2rem",
                        marginBottom: "32px",
                    }}
                >
                    {window.innerWidth < 768 ? (
                        <VideoPlayerSm generatedVideoSrc={generatedVideoSrc} />
                    ) : (
                        <VideoPlayer generatedVideoSrc={generatedVideoSrc} />
                    )}
                </Box>
            )} */}

            {progress < 100 && !generatedVideoSrc ? (
                <Box
                    sx={{
                        display: "flex",
                        gap: "80px",
                        alignItems: "center",
                        flexDirection: { xs: "column", md: "row" },
                    }}
                >
                    {userInputObject.video_type === "landscape" ? (
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <Typography
                                sx={{
                                    color: "#FFF",
                                    fontFamily: "Inter",
                                    letterSpacing: "-0.576px",
                                    fontSize: { xs: "22px", md: "30px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: {
                                        xs: "24px",
                                        md: "36px",
                                    },
                                    textAlign: { xs: "center", md: "left" },
                                    textAlign: "center",
                                    marginBottom: "28px",
                                }}
                            >
                                Crafting Your Video...
                            </Typography>
                            <Box
                                sx={{
                                    minWidth: { xs: "92vw", md: "750px" },
                                    minHeight: { xs: "220px", md: "450px" },
                                    position: "relative",
                                    borderRadius: "2rem",
                                    marginBottom: "32px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "22px", md: "30px" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                        lineHeight: {
                                            xs: "24px",
                                            md: "36px",
                                        },
                                        textAlign: { xs: "center", md: "left" },
                                        textAlign: "center",
                                        marginBottom: "28px",
                                        zIndex: "1000",
                                        position: "absolute",
                                        left: "50%",
                                        top: "45%",
                                        transform: "translateX(-50%)",
                                    }}
                                >
                                    {progress} %
                                </Typography>
                                <Box
                                    sx={{
                                        borderRadius: "5px",
                                        background: "rgba(0, 0, 0, 0.01)",
                                        backdropFilter: "blur(16px)",
                                        position: "absolute",
                                        left: "0",
                                        bottom: "0",
                                        right: "0",
                                        top: "0",
                                        zIndex: "100",
                                        borderRadius: "inherit",
                                    }}
                                ></Box>
                                <img
                                    src="/assets/dummyVideoCoverImage.png"
                                    style={{
                                        position: "absolute",
                                        left: "0",
                                        bottom: "0",
                                        right: "0",
                                        top: "0",
                                        zIndex: "50",
                                        borderRadius: "inherit",
                                    }}
                                    alt="Video Cover"
                                />
                            </Box>
                            <>
                                <Box
                                    sx={{
                                        width: { xs: "330px", md: "482px" },
                                        height: "9px",
                                        background: "#334155",
                                        borderRadius: "50px",
                                        position: "relative",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(90deg, #0068EE -0.9%, #BE76FF 102.66%)",
                                            width: `${progress}%`,
                                            height: "100%",
                                            transition: "width 0.3s ease-in-out",
                                            borderRadius: "inherit",
                                        }}
                                    ></Box>
                                </Box>
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "1.1rem", md: "20px" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                        textAlign: { xs: "center", md: "left" },
                                        textAlign: "center",
                                        marginBottom: "8px",
                                        marginTop: "24px",
                                        opacity: 1, // Initialize opacity
                                        transition: "opacity 0.3s ease-in-out",
                                    }}
                                    style={{ opacity: progress === 0 ? 0 : 1 }}
                                >
                                    {text}
                                </Typography>{" "}
                            </>
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "332px",
                                    height: "590px",
                                    borderRadius: "1.5rem",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "22px", md: "30px" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                        lineHeight: {
                                            xs: "24px",
                                            md: "36px",
                                        },
                                        textAlign: { xs: "center", md: "left" },
                                        textAlign: "center",
                                        marginBottom: "28px",
                                        zIndex: "1000",
                                        position: "absolute",
                                        left: "50%",
                                        top: "45%",
                                        transform: "translateX(-50%)",
                                    }}
                                >
                                    {progress} %
                                </Typography>
                                <Box
                                    sx={{
                                        borderRadius: "5px",
                                        background: "rgba(0, 0, 0, 0.01)",
                                        backdropFilter: "blur(16px)",
                                        position: "absolute",
                                        left: "0",
                                        bottom: "0",
                                        right: "0",
                                        top: "0",
                                        zIndex: "100",
                                        borderRadius: "1.5rem",
                                    }}
                                ></Box>
                                <img
                                    src="/assets/dummyReelCoverImage.png"
                                    style={{
                                        position: "absolute",
                                        left: "0",
                                        bottom: "0",
                                        right: "0",
                                        top: "0",
                                        zIndex: "50",
                                        borderRadius: "1.5rem",
                                    }}
                                    alt="Video Cover"
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: { xs: "center", md: "start" },
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "22px", md: "30px" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                        lineHeight: {
                                            xs: "24px",
                                            md: "36px",
                                        },
                                        textAlign: { xs: "center", md: "left" },
                                        textAlign: "center",
                                        marginBottom: "28px",
                                    }}
                                >
                                    Crafting Your Video...
                                </Typography>
                                <Box
                                    sx={{
                                        width: { xs: "330px", md: "482px" },
                                        height: "9px",
                                        background: "#334155",
                                        borderRadius: "50px",
                                        position: "relative",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(90deg, #0068EE -0.9%, #BE76FF 102.66%)",
                                            width: `${progress}%`,
                                            height: "100%",
                                            transition: "width 0.3s ease-in-out",
                                            borderRadius: "inherit",
                                        }}
                                    ></Box>
                                </Box>
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "1.1rem", md: "20px" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                        textAlign: { xs: "center", md: "left" },
                                        marginBottom: "8px",
                                        marginTop: "24px",
                                        opacity: 1, // Initialize opacity
                                        transition: "opacity 0.3s ease-in-out",
                                    }}
                                    style={{ opacity: progress === 0 ? 0 : 1 }}
                                >
                                    {text}
                                </Typography>
                            </Box>
                        </>
                    )}

                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        gap: "80px",
                        alignItems: {
                            xs: "center",
                            md: userInputObject.video_type === "landscape" ? "start" : "center"
                        },
                        flexDirection: {
                            xs: "column",
                            md: userInputObject.video_type === "landscape" ? "column" : "row"
                        },
                        width: "fit"
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            width: {
                                xs: "90vw",
                                md: userInputObject.video_type === "landscape" ? "800px" : "370px"
                            },
                            /* width: "332px", */
                            /* height: "590px", */
                            borderRadius: "1.5rem",
                        }}
                    >
                        {userInputObject.video_type === "landscape" ? (
                            <VideoPlayer generatedVideoSrc={generatedVideoSrc} />
                        ) : (
                            <ReelVideoPlayer
                                generatedVideoSrc={generatedVideoSrc}
                            />
                        )}

                    </Box>
                    <Box
                        sx={{
                            width: { xs: "90vw", md: userInputObject.video_type === "landscape" ? "800px" : "fit-content" },
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Inter",
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "1rem", md: "18px" },
                                fontStyle: "normal",
                                fontWeight: "800",
                                textAlign: { xs: "left", md: "left" },
                                marginBottom: "16px",
                                marginTop: "24px",
                                opacity: 1, // Initialize opacity
                                transition: "opacity 0.3s ease-in-out",
                            }}
                        >
                            Your Idea
                        </Typography>
                        <Typography
                            sx={{
                                color: "#CBD5E1",
                                fontFamily: "Inter",
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                                fontStyle: "normal",
                                textAlign: { xs: "left", md: "left" },
                                marginBottom: "16px",
                                opacity: 1, // Initialize opacity
                                transition: "opacity 0.3s ease-in-out",
                                maxWidth: { xs: "fit-content", md: "400px" },
                            }}
                        >
                            {userInputObject.user_idea}
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            gap: userInputObject.video_type === "landscape" ? "24px" : "0px",
                            flexDirection: {
                                xs: "column",
                                md: userInputObject.video_type === "landscape" ? "row" : "column"
                            },
                            /* marginBottom: "16px", */
                            flexWrap: "wrap",
                            borderBottom: userInputObject.video_type === "landscape" ? "2px solid #334155" : "0px",
                        }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "24px",
                                    flexDirection: { xs: "column", md: "row" },
                                    marginBottom: "16px",
                                    flexWrap: "wrap",

                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "1rem", md: "18px" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            marginBottom: "16px",
                                            marginTop: "24px",
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                        }}
                                    >
                                        Audience
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#CBD5E1",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                            border: "1.5px solid #CBD5E1",
                                            borderRadius: "1.7rem",
                                            padding: "0.3rem 0.6rem",
                                            width: "fit-content",
                                            maxWidth: "200px",
                                        }}
                                    >
                                        {userInputObject.audience_focus}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "1rem", md: "18px" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            marginBottom: "16px",
                                            marginTop: "24px",
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                        }}
                                    >
                                        {/* Video Type */}
                                        Format
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#CBD5E1",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                            border: "1.5px solid #CBD5E1",
                                            borderRadius: "1.7rem",
                                            padding: "0.3rem 0.6rem",
                                            width: "fit-content",
                                        }}
                                    >
                                        {userInputObject.video_type !== "landscape" ? "Shorts" : "Long Video"}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "24px",
                                    flexDirection: { xs: "column", md: "row" },
                                    marginBottom: "16px",
                                    flexWrap: "wrap",
                                    borderBottom: userInputObject.video_type === "landscape" ? "0px" : "1.5px solid #334155",
                                    paddingBottom: "1.4rem",
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "1rem", md: "18px" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            marginBottom: "16px",
                                            marginTop: "24px",
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                        }}
                                    >
                                        Video Style
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#CBD5E1",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                            border: "1.5px solid #CBD5E1",
                                            borderRadius: "1.7rem",
                                            padding: "0.3rem 0.6rem",
                                            width: "fit-content",
                                        }}
                                    >
                                        {userInputObject.video_style}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "1rem", md: "18px" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            marginBottom: "16px",
                                            marginTop: "24px",
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                        }}
                                    >
                                        Content Focus
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#CBD5E1",
                                            fontFamily: "Inter",
                                            letterSpacing: "-0.576px",
                                            fontSize: { xs: "0.9rem", md: "1rem" },
                                            fontStyle: "normal",
                                            fontWeight: "800",
                                            textAlign: { xs: "left", md: "left" },
                                            opacity: 1, // Initialize opacity
                                            transition: "opacity 0.3s ease-in-out",
                                            border: "1.5px solid #CBD5E1",
                                            borderRadius: "1.7rem",
                                            padding: "0.3rem 0.6rem",
                                            width: "fit-content",
                                        }}
                                    >
                                        {userInputObject.content_focus}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "fit-content",
                                gap: { xs: "1rem", md: "1rem" },
                                marginTop: "40px",
                            }}
                        >
                            {/* <IconButton sx={{
                                background: "#1E293B",
                                border: "2px solid #475569",

                                "&:hover": {
                                    background: "#475569",
                                }
                                
                                
                            }}
                            onClick={() => handleLikeClick()}
                            >
                                <HiOutlineThumbUp size={26} color="white" />
                            </IconButton>
                            <IconButton sx={{
                                background: "#1E293B",
                                border: "2px solid #475569",

                                "&:hover": {
                                    background: "#475569",
                                }
                                
                            }}
                            onClick={() => handleDislikeClick()}
                            >
                                <HiOutlineThumbDown size={26} color="white" />
                            </IconButton> */}
                            <IconButton
                                onClick={handleLikeClick}
                                sx={{
                                    // Example: Change background color on success
                                    background: likeUpdateSuccess
                                        ? "#4CAF50"
                                        : "#1E293B",
                                    border: "2px solid #475569",

                                    "&:hover": {
                                        background: likeUpdateSuccess
                                            ? "#4CAF50"
                                            : "#475569",
                                    },
                                    // other styles
                                }}
                            >
                                {likeUpdateSuccess ? (
                                    <HiOutlineCheckCircle color="white" />
                                ) : (
                                    <HiOutlineThumbUp size={26} color="white" />
                                )}
                            </IconButton>
                            <IconButton
                                onClick={handleDislikeClick}
                                sx={{
                                    // Example: Change background color on success
                                    background: dislikeUpdateSuccess
                                        ? "#4CAF50"
                                        : "#1E293B",
                                    border: "2px solid #475569",

                                    "&:hover": {
                                        background: dislikeUpdateSuccess
                                            ? "#4CAF50"
                                            : "#475569",
                                    },
                                    // other styles
                                }}
                            >
                                {dislikeUpdateSuccess ? (
                                    <HiOutlineCheckCircle color="white" />
                                ) : (
                                    <HiOutlineThumbDown
                                        size={26}
                                        color="white"
                                    />
                                )}
                            </IconButton>
                        </Box>

                        {/* <Typography>{userInputObject.user_idea}</Typography>
                        <Typography>Audience</Typography>
                        <Typography>{userInputObject.audience_type}</Typography>
                        <Typography>Platform</Typography>
                        <Typography>{userInputObject.platform}</Typography> */}
                        {/* <Typography>Duration</Typography>
                        <Typography>{userInputObject.user_idea}</Typography> */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column-reverse",
                                    md: "row",
                                },
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "fit-content",
                                gap: { xs: "1.45rem", md: "2rem" },
                                marginTop: "40px",
                            }}
                        >
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    color: "white",
                                    diplay: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    background: "#1E293B",
                                    width: "168px",
                                    height: "50px",
                                    fontWeight: "bold",
                                    "&:hover": {
                                        background: "#4A5464",
                                    },
                                    paddingY: "1rem",
                                    borderRadius: "0.7rem",
                                    "&:disabled": {
                                        background: "#1E293B",
                                        color: "#64748B",
                                        cursor: "no-drop !important",
                                        cursor: "not-allowed",
                                        pointerEvents: "all !important",
                                    },
                                    fontFamily: "Inter",
                                    fontSize: { xs: "0.9rem", md: "1rem" },
                                }}
                                onClick={() => {
                                    setIsInputPhase(true);
                                    setGeneratedVideoSrc("");
                                    setProgress(0);
                                    setText(
                                        "🎬 Setting the stage for your masterpiece!"
                                    );
                                    setUserInputObject({});
                                }}
                            >
                                <span>Create Again</span>
                                <HiOutlineRefresh size={24} />
                            </Button>
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    color: "white",
                                    diplay: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    background: "#6464FF",
                                    width: "168px",
                                    height: "50px",
                                    fontWeight: "bold",
                                    "&:hover": {
                                        background: "#4E51CE",
                                    },
                                    paddingY: "1rem",
                                    borderRadius: "0.7rem",
                                    "&:disabled": {
                                        background: "#1F2937",
                                        color: "#64748B",
                                        cursor: "no-drop !important",
                                        cursor: "not-allowed",
                                        pointerEvents: "all !important",
                                    },
                                    fontFamily: "Inter",
                                    fontSize: { xs: "0.9rem", md: "1rem" },
                                }}
                                onClick={() => handleDownload()}
                                disabled={buttonDisabled}
                            >
                                <span>Download</span>
                                <HiOutlineDownload size={24} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}

            {/* <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "fit-content",
                    gap: { xs: "1.45rem", md: "2rem" },
                    marginTop: "40px",
                }}
            >
                <Button
                    sx={{
                        textTransform: "capitalize",
                        color: "white",
                        diplay: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                        background: "#1E293B",
                        width: "168px",
                        height: "50px",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#4A5464",
                        },
                        paddingY: "1rem",
                        borderRadius: "0.7rem",
                        "&:disabled": {
                            background: "#1E293B",
                            color: "#64748B",
                            cursor: "no-drop !important",
                            cursor: "not-allowed",
                            pointerEvents: "all !important",
                        },
                        fontFamily: "Inter",
                        fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                    onClick={() => {
                        setIsInputPhase(true);
                        setGeneratedVideoSrc("");
                        setProgress(0);
                        setText("🎬 Setting the stage for your masterpiece!");
                    }}
                >
                    <span>Create Again</span>
                    <HiOutlineRefresh size={24} />
                </Button>
                <Button
                    sx={{
                        textTransform: "capitalize",
                        color: "white",
                        diplay: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.5rem",
                        background: "#6464FF",
                        width: "168px",
                        height: "50px",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#4E51CE",
                        },
                        paddingY: "1rem",
                        borderRadius: "0.7rem",
                        "&:disabled": {
                            background: "#1F2937",
                            color: "#64748B",
                            cursor: "no-drop !important",
                            cursor: "not-allowed",
                            pointerEvents: "all !important",
                        },
                        fontFamily: "Inter",
                        fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                    onClick={() => handleDownload()}
                    disabled={buttonDisabled}
                >
                    <span>Download</span>
                    <HiOutlineDownload size={24} />
                </Button>
            </Box> */}
        </Box>
    );
};

export default OutputPhaseMain;
