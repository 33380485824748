import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedModel: "",
  selectedModelPrompt: "",
  selectedPackages: [],
  allPackages: [],
  generationPageSelectedModel: "",
  allDreamBoothModels: [],
};

export const dreamBoothSilce = createSlice({
  name: "config",
  initialState,
  reducers: {
    setSelectedPackages: (state, action) => {
      state.selectedPackages = action.payload.selectedPackages;
    },
    setSelectedModelPrompt: (state, action) => {
      state.selectedModelPrompt = action.payload.selectedModelPrompt;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload.selectedModel;
    },
    setAllPackages: (state, action) => {
      // state.allPackages = action.payload.setAllPackages;
      state.allPackages = [
        ...state.allPackages,
        ...action.payload.setAllPackages,
      ];
    },
    setGenerationPageSelectedModel: (state, action) => {
      state.generationPageSelectedModel = action.payload;
    },
  },
});

export const {
  setSelectedPackages,
  setAllPackages,
  setSelectedModel,
  setSelectedModelPrompt,
  setGenerationPageSelectedModel,
} = dreamBoothSilce.actions;
export default dreamBoothSilce.reducer;
