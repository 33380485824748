import { Box } from "@mui/material";
import React from "react";
import Nav from "./Nav";
import NavBar from "../NavBar/NavBar";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
    //   marginTop: "120px",
    minHeight: "100vh",
}));

const WrapperBox = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
}));

export default function LayoutNew(props) {
    return (
        <WrapperBox>
            <div className="">
                <NavBar />
                <StyledBox>{props.children}</StyledBox>
                {props.footer}
            </div>
        </WrapperBox>
    );
}
