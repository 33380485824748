import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { PrettoSlider } from "./styled/prettoSlider";

function CanvasImage({
    canvasRef,
    generatedImageSelected,
    setMask,
    maskedImageRef,
}) {
    const [imageStrength, setImageStrength] = React.useState(20);
    const [colorPic, setColorPic] = React.useState("#000");
    const inpaintedImage = useSelector(
        (state) => state.imageInpainting.inpaintedImage
    );
    const inpainting = useSelector((state) => state.imageInpainting.inpainting);

    const canvasWidth = useSelector(
        (state) => state.imageInpainting.canvasWidth
    );
    const canvasHeight = useSelector(
        (state) => state.imageInpainting.canvasHeight
    );
    // const canvasRef = useRef(null)

    function clearCanvas() {
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    function undo() {
        console.log("Undo Button Clicked.");
    }

    async function downloadPaintedImage() {
        const image = await fetch(inpaintedImage);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement("a");
        link.href = imageURL;
        link.download = "download";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    console.log(generatedImageSelected, "Generated Image Sel");

    console.log("CANVAS REF", canvasRef);

    return (
        <Grid container spacing={4} sx={{ mt: 5, py: 2 }}>
            <Grid item xs={12} md={8}>
                {inpainting ? (
                    <Box
                        sx={{
                            margin: "0 auto",
                            position: "relative",
                        }}
                    >
                        <Skeleton
                            sx={{
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                            variant="rectangular"
                            width={canvasWidth}
                            height={canvasHeight}
                        />
                    </Box>
                ) : null}

                <div style={{ visibility: inpainting ? "hidden" : "visible" }}>
                    <Canvas
                        canvasRef={canvasRef}
                        maskedImageRef={maskedImageRef}
                        colorPic={colorPic}
                        imageStrength={imageStrength}
                        generatedImageSelected={generatedImageSelected}
                        width={canvasWidth}
                        height={canvasHeight}
                        setMask={setMask}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack spacing={3} sx={{ maxWidth: "500px", margin: "0 auto" }}>
                    <Typography>
                        Black (mask) pixels will be inpainted and the rest will
                        be preserved
                    </Typography>
                    <Box>
                        <Typography color="text.secondary" gutterBottom>
                            <strong>Configurations</strong>
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                gutterBottom
                            >
                                Brush Size
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                gutterBottom
                            >
                                {imageStrength}
                            </Typography>
                        </div>
                        <Box
                            sx={{
                                display: "flex",
                                borderRadius: "5px",
                                alignItems: "center",
                                height: "36px",
                            }}
                        >
                            <PrettoSlider
                                onChange={(event) =>
                                    setImageStrength(event.target.value)
                                }
                                // value={imageStrength}
                                valueLabelDisplay="auto"
                                aria-label="pretto slider"
                                defaultValue={imageStrength}
                                min={0}
                                max={100}
                            />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Button
                                onClick={clearCanvas}
                                variant="outlined"
                                size="small"
                                sx={{ mr: 2 }}
                            >
                                Clear
                            </Button>
                            {/* <Button
                                onClick={undo}
                                variant="outlined"
                                size="small"
                                sx={{ mr: 2 }}>
                                Undo
                            </Button> */}

                            <Button
                                onClick={downloadPaintedImage}
                                variant="outlined"
                                size="small"
                                disabled={!inpaintedImage}
                            >
                                Download
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

const Canvas = ({
    colorPic,
    imageStrength,
    width,
    height,
    generatedImageSelected,
    setMask,
    maskedImageRef,
    canvasRef,
}) => {
    const { setCanvasRef, onCanvasMouseDown } = useOnDraw(onDraw, canvasRef);

    const brushSize = imageStrength;
    const [showCursor, setShowCursor] = React.useState(false);
    const drawingStarted = React.useRef(false);

    function onDraw(ctx, point, prevPoint) {
        drawLine(prevPoint, point, ctx, "#000000", 5);
    }

    function drawLine(start, end, ctx, color, width) {
        start = start ?? end;
        ctx.beginPath();
        ctx.lineWidth = imageStrength;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.fillStyle = colorPic;
        ctx.beginPath();
        ctx.arc(start.x, start.y, imageStrength, 0, 2 * Math.PI);
        ctx.fill();
    }

    const inpaintedImage = useSelector(
        (state) => state.imageInpainting.inpaintedImage
    );

    const cursorRef = React.useRef(null);

    const positionCursor = (e) => {
        const mouseY = e.clientY;
        const mouseX = e.clientX;
        const canvasrect = canvasRef.current.getBoundingClientRect();
        const cursorRect = cursorRef.current.getBoundingClientRect();

        const canvasParent = canvasRef.current.parentElement;
        const parentRect = canvasParent.getBoundingClientRect();

        const offsetX = canvasrect.left - parentRect.left;
        const offsetY = canvasrect.top - parentRect.top;
        console.log(cursorRect);
        console.log(canvasrect);
        console.log(mouseX);
        console.log(mouseY);
        const left = mouseX - canvasrect.left + offsetX;
        const top = mouseY - canvasrect.top + offsetY;
        console.log(left, " LEFT ", top, " Top");
        // cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
        // cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
        cursorRef.current.style.top = `${top - brushSize}px`;
        cursorRef.current.style.left = `${left - brushSize}px`;
    };

    return (
        <Box
            sx={{
                textAlign: "center",
                position: "relative",
                minHeight: "500px",
            }}
        >
            {/* <Box sx={{ width: "50%" }}> */}
            {/* <p>inpaintedImage: {inpaintedImage}</p> */}
            {inpaintedImage ? (
                <img
                    src={inpaintedImage}
                    alt=""
                    style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                        height: height,
                        width: width,
                        cursor: "none",
                    }}
                />
            ) : (
                <img
                    src={generatedImageSelected}
                    alt=""
                    style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                        height: height,
                        width: width,
                        cursor: "none",
                    }}
                />
            )}
            {/* <canvas
                id="image-canvas"
                width={width}
                height={height}
                style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 10
                }}
            /> */}
            <canvas
                width={width}
                height={500}
                onMouseDown={onCanvasMouseDown}
                onMouseMove={positionCursor}
                onMouseEnter={() => {
                    setShowCursor(true);
                    drawingStarted.current = true;
                }}
                onMouseLeave={() => setShowCursor(false)}
                // style={}
                ref={setCanvasRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    background: "transparent",
                    zIndex: 11,
                    cursor: "none",
                    // backgroundImage: `url(${generatedImageSelected})`,
                    // backgroundRepeat: "no-repeat",
                    // backgroundSize: "cover",
                    // border: "2px solid #666",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: height,
                    width: width,
                    cursor: "none",
                    zIndex: 9,
                    visibility: drawingStarted.current ? "hidden" : "visible",
                }}
            ></div>

            <div
                ref={cursorRef}
                id="rounded-cursor"
                style={{
                    height: `${brushSize * 2}px`,
                    width: `${brushSize * 2}px`,
                    borderRadius: "50%",
                    background: "black",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    visibility: showCursor ? "visible" : "hidden",
                }}
            ></div>
        </Box>
    );
};

function useOnDraw(onDraw, canvasRef) {
    const isDrawingRef = useRef(false);
    const prevPointRef = useRef(null);

    const mouseMoveListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);

    function setCanvasRef(ref) {
        canvasRef.current = ref;
        console.log("main", canvasRef.current);
    }
    async function imagedata() {
        canvasRef.current.toBlob((blob) => {
            let file = new File([blob], "mask.png", { type: "image/png" });
            console.log(file);
        }, "image/png");

        const image = canvasRef.current.toDataURL("image/png");
        const blob = await (await fetch(image)).blob();
        const blobURL = URL.createObjectURL(blob);
        return blobURL;
    }

    function fillBlankSpace() {
        const ctx = canvasRef.current.getContext("2d");
        ctx.globalCompositeOperation = "destination-over";
        // Now draw!
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    function onCanvasToFile(callback) {
        canvasRef.current.toBlob((blob) => {
            let file = new File([blob], "mask.png", { type: "image/png" });
            callback(file);
        }, "image/png");
    }

    function clearCanvas() {
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    // function downloadFromCanvas(){
    //     const anchor = document.createElement("a");
    //     anchor.href = canvasRef.current.toDataURL("image/png");
    //     anchor.download = "mask_image.png";
    //     anchor.click();
    // }

    function onCanvasMouseDown() {
        isDrawingRef.current = true;
    }

    useEffect(() => {
        function computePointInCanvas(clientX, clientY) {
            if (canvasRef.current) {
                const boundingRect = canvasRef.current.getBoundingClientRect();
                return {
                    x: clientX - boundingRect.left,
                    y: clientY - boundingRect.top,
                };
            } else {
                return null;
            }
        }
        function initMouseMoveListener() {
            const mouseMoveListener = (e) => {
                if (isDrawingRef.current && canvasRef.current) {
                    const point = computePointInCanvas(e.clientX, e.clientY);
                    const ctx = canvasRef.current.getContext("2d");
                    if (onDraw) onDraw(ctx, point, prevPointRef.current);
                    prevPointRef.current = point;
                    console.log(point);
                }
            };
            mouseMoveListenerRef.current = mouseMoveListener;
            window.addEventListener("mousemove", mouseMoveListener);
        }

        function initMouseUpListener() {
            const listener = () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
            };
            mouseUpListenerRef.current = listener;
            window.addEventListener("mouseup", listener);
        }

        function cleanup() {
            if (mouseMoveListenerRef.current) {
                window.removeEventListener(
                    "mousemove",
                    mouseMoveListenerRef.current
                );
            }
            if (mouseUpListenerRef.current) {
                window.removeEventListener(
                    "mouseup",
                    mouseUpListenerRef.current
                );
            }
        }

        initMouseMoveListener();
        initMouseUpListener();
        return () => cleanup();
    }, [onDraw]);

    return {
        setCanvasRef,
        imagedata,
        onCanvasMouseDown,
        onCanvasToFile,
        fillBlankSpace,
        clearCanvas,
    };
}
export default CanvasImage;
