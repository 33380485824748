import React, { useState, useEffect, useContext } from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import { handleLogout } from "../../firestore/authHandlers";
import {
    HiOutlineMoon,
    HiOutlineUser,
    HiChevronDown,
    HiOutlineMenuAlt3,
    HiOutlineX,
    HiOutlineSun,
} from "react-icons/hi";
import { ColorModeContext } from "../../theme";
import imgsrc from "../../imgs/aiseo-symble.png";
import MobileMenu from "./MobileMenu";
import styles from "./navBar.module.css";
import { navItemsData } from "./navItemsData";
import { profileMenuItemsData } from "./profileMenuItemsData";
import { useTheme } from "@mui/material/styles";

const NavBar = () => {
    const [user, loading] = useAuthState(auth);
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [subMenuEl, setSubMenuEl] = useState(null);
    const [subMenuElTwo, setSubMenuElTwo] = useState(null);
    const [pSubMenuEl, setPSubMenuEl] = useState(null);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(
        window.innerWidth < 900
    );

    let customLink = "";

    if (location.pathname === "/video") {
        customLink = "/pricing?active=video";
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobileDevice(window.innerWidth < 900);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleLoginNav = () => {
        navigate("../login");
    };

    const handleMobileMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleMenuOneOpen = (event) => {
        setSubMenuEl(event.currentTarget);
    };

    const handleMenuOneClose = () => {
        setSubMenuEl(null);
    };

    const handleMenuTwoOpen = (event) => {
        setSubMenuElTwo(event.currentTarget);
    };

    const handleMenuTwoClose = () => {
        setSubMenuElTwo(null);
    };

    const handleProfileMenuOpen = (event) => {
        setPSubMenuEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setPSubMenuEl(null);
    };

    const handleLogoutClick = () => {
        const value = localStorage.getItem("dontShowMultistepPopup");
        if (value) {
            localStorage.removeItem("dontShowMultistepPopup");
        }
        handleLogout();
    };

    return (
        <Box
            sx={{
                background:
                    theme.palette.mode === "light" ? "white" : "#2C2929",
                borderBottom: `1.4px solid ${theme.palette.mode === "light" ? "#E5E5E5" : "#2E2C2C"}`,
                position: "sticky",
                top: "0",
                zIndex: 100,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: "80vw",
                    margin: "0 auto",
                    /* borderBottom: "1.4px solid #E5E5E5", */

                    padding: { xs: "0.7rem 0.5rem", md: "0.8rem 0" },
                }}
            >
                <Link
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    underline="none"
                    href="/"
                >
                    <img src={imgsrc} className={styles.navLogoImg} alt="" />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: "block",
                            fontWeight: "bold",
                            color:
                                theme.palette.mode === "light"
                                    ? "#281142"
                                    : "white",
                        }}
                    >
                        AISEO Art
                    </Typography>
                </Link>
                <Box
                    sx={{
                        fontFamily: "Inter, Roboto, Arial, sans-serif",
                        display: {
                            xs: "none",
                            md: "flex",
                        },
                    }}
                    style={{
                        gap: "0.5rem",
                    }}
                >
                    {navItemsData.map((item, index) => {
                        if (item.to === "") {
                            return (
                                <React.Fragment key={index}>
                                    <Button
                                        key={index}
                                        onClick={
                                            item.title === "Products"
                                                ? handleMenuTwoOpen
                                                : handleMenuOneOpen
                                        }
                                        sx={{
                                            py: "0rem",
                                            px: "0.7rem",
                                            height: "2.5rem",
                                            borderRadius: "0.7rem",
                                            backgroundColor: "transparent",
                                            color:
                                                theme.palette.mode === "light"
                                                    ? "#6363FF"
                                                    : "#90CAF9",
                                            fontSize: "0.9rem",
                                            transition: "none",
                                            /* transition:
                                                "background-color 0.3s ease-in, color 0.3s ease-in", */
                                            "&:hover": {
                                                backgroundColor: "#6363FF",
                                                color: "white",
                                            },
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                        style={{
                                            gap: "0.3rem",
                                        }}
                                    >
                                        <span style={{ marginTop: "2px" }}>
                                            {item.title}
                                        </span>
                                        <span style={{ marginTop: "5px" }}>
                                            <HiChevronDown />
                                        </span>
                                    </Button>
                                    <Popover
                                        open={
                                            item.title === "Products"
                                                ? Boolean(subMenuElTwo)
                                                : Boolean(subMenuEl)
                                        }
                                        anchorEl={
                                            item.title === "Products"
                                                ? subMenuElTwo
                                                : subMenuEl
                                        }
                                        onClose={
                                            item.title === "Products"
                                                ? handleMenuTwoClose
                                                : handleMenuOneClose
                                        }
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        PaperProps={{
                                            elevation: 1,
                                            sx: {
                                                backgroundColor:
                                                    theme.palette.mode ===
                                                        "light"
                                                        ? "white"
                                                        : "#3F3C3C",
                                                display: "flex",
                                                flexDirection: "column",
                                                p: "0.7rem",
                                                borderRadius: "0.7rem",
                                                border: "1.5px solid #D6D2DA",
                                            },
                                        }}
                                    >
                                        {item.subMenu.map(
                                            (subItem, subIndex) => (
                                                <MenuItem
                                                    key={subIndex}
                                                    component={Link}
                                                    href={subItem.to}
                                                    underline="none"
                                                    onClick={handleMenuOneClose}
                                                    sx={{
                                                        backgroundColor:
                                                            "#E5E5FA",
                                                        color: "#281142",
                                                        margin: "0.5rem",
                                                        borderRadius: "0.5rem",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "#D3D3FF",
                                                        },
                                                    }}
                                                    target={
                                                        subItem.to.startsWith(
                                                            "http"
                                                        )
                                                            ? "_blank"
                                                            : undefined
                                                    }
                                                >
                                                    {subItem.title}
                                                </MenuItem>
                                            )
                                        )}
                                    </Popover>
                                </React.Fragment>
                            );
                        }

                        return (
                            <Link
                                href={
                                    item.title === "Pricing" &&
                                        customLink !== ""
                                        ? customLink
                                        : item.to
                                }
                                underline="none"
                                key={item.id}
                                sx={{
                                    py: "0rem",
                                    px: "1.3rem",
                                    height: "2.5rem",
                                    borderRadius: "0.7rem",
                                    backgroundColor:
                                        item.to === currentPath
                                            ? "#6363FF"
                                            : "transparent",
                                    color:
                                        item.to === currentPath
                                            ? "white"
                                            : theme.palette.mode === "light"
                                                ? "#6363FF"
                                                : "#90CAF9",

                                    fontSize: "0.9rem",
                                    /* transition:
                                        "background-color 0.3s ease-in, color 0.3s ease-in", */
                                    "&:hover": {
                                        backgroundColor: "#6363FF",
                                        color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.2rem",
                                    fontWeight: "bold",
                                }}
                                target={
                                    item.to.startsWith("http")
                                        ? "_blank"
                                        : undefined
                                }
                            >
                                <span>{item.title}</span>
                            </Link>
                        );
                    })}
                </Box>
                <Box
                    sx={{
                        fontFamily: "Inter, Roboto, Arial, sans-serif",
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "center",
                    }}
                    style={{
                        gap: "0.5rem",
                    }}
                >
                    <IconButton
                        sx={{
                            background:
                                theme.palette.mode === "light"
                                    ? "#2C2929"
                                    : "#FFE483",
                            color:
                                theme.palette.mode === "light"
                                    ? "white"
                                    : "#2C2929",
                            border:
                                theme.palette.mode === "light"
                                    ? "2px solid #2C2929"
                                    : "2px solid #FFE483",
                            width: "2.5rem",
                            height: "2.5rem",

                            "&:hover": {
                                background:
                                    theme.palette.mode === "light"
                                        ? "#3F3C3C"
                                        : "#FFE072",
                                border:
                                    theme.palette.mode === "light"
                                        ? "2px solid #3F3C3C"
                                        : "2px solid #FFE483",
                            },
                            /* display: {
                                xs: "none",
                                md: "block",
                            }, */
                        }}
                        onClick={colorMode.toggleColorMode}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {theme.palette.mode === "light" ? (
                                <HiOutlineMoon />
                            ) : (
                                <HiOutlineSun />
                            )}
                        </Box>
                    </IconButton>

                    <IconButton
                        sx={{
                            background: "#6363FF",
                            color: "white",
                            border: "2px solid #6363FF",
                            width: "2.4rem",
                            height: "2.4rem",
                            "&:hover": {
                                background: "#6363FF",
                                color: "white",
                            },
                            borderRadius: "0.3rem",
                            display: { sm: "block", md: "none" },
                        }}
                        onClick={handleMobileMenuClick}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {isMobileDevice && isMobileMenuOpen ? (
                                <HiOutlineX />
                            ) : (
                                <HiOutlineMenuAlt3 />
                            )}
                        </Box>
                    </IconButton>
                    <React.Fragment>
                        {
                            <IconButton
                                sx={{
                                    background: "white",
                                    /* background: "#6DDAF8", */
                                    color: "#281142",
                                    border: "2px solid lightgrey",
                                    width: "2.5rem",
                                    height: "2.5rem",
                                    "&:hover": {
                                        background: "#6363FF",
                                        color: "white",
                                    },
                                    display: {
                                        xs: "none",
                                        md: "block",
                                    },
                                }}
                                onClick={handleProfileMenuOpen}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    {user ? (
                                        <img
                                            src={user.photoURL}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                                scale: "1.7",
                                                borderRadius: "50%", // Applying the parent's border radius
                                            }}
                                            alt="User Profile"
                                        />
                                    ) : (
                                        <HiOutlineUser />
                                    )}
                                </Box>
                            </IconButton>
                        }
                        <Popover
                            open={Boolean(pSubMenuEl)}
                            anchorEl={pSubMenuEl}
                            onClose={handleProfileMenuClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                elevation: 1,
                                sx: {
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? "white"
                                            : "#3F3C3C",
                                    display: "flex",
                                    flexDirection: "column",
                                    p: "0.7rem",
                                    borderRadius: "0.7rem",
                                    border: "1.5px solid #D6D2DA",
                                },
                            }}
                        >
                            {profileMenuItemsData.map(
                                (subProfileItem, subProfileIndex) => (
                                    <MenuItem
                                        key={subProfileIndex}
                                        component={Link}
                                        href={subProfileItem.to}
                                        underline="none"
                                        onClick={handleProfileMenuClose}
                                        sx={{
                                            backgroundColor: "#E5E5FA",
                                            color: "#281142",
                                            margin: "0.5rem",
                                            borderRadius: "0.5rem",
                                            "&:hover": {
                                                backgroundColor: "#D3D3FF",
                                            },
                                        }}
                                    >
                                        {subProfileItem.title}
                                    </MenuItem>
                                )
                            )}
                            {user ? (
                                <MenuItem
                                    component={Button}
                                    underline="none"
                                    onClick={handleLogoutClick}
                                    sx={{
                                        backgroundColor: "#6363FF",
                                        color: "white",
                                        margin: "0.5rem",
                                        borderRadius: "0.5rem",
                                        "&:hover": {
                                            backgroundColor: "#5050D6",
                                        },
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    component={Button}
                                    underline="none"
                                    onClick={handleLoginNav}
                                    sx={{
                                        backgroundColor: "#6363FF",
                                        color: "white",
                                        margin: "0.5rem",
                                        borderRadius: "0.5rem",
                                        "&:hover": {
                                            backgroundColor: "#5050D6",
                                        },
                                    }}
                                >
                                    Login
                                </MenuItem>
                            )}
                        </Popover>
                    </React.Fragment>
                </Box>
            </Box>
            {isMobileMenuOpen && isMobileDevice && (
                <MobileMenu
                    navItemsData={navItemsData}
                    profileMenuItemsData={profileMenuItemsData}
                    handleLoginNav={handleLoginNav}
                    handleLogout={handleLogoutClick}
                    user={user}
                    setIsMobileMenuOpen={setIsMobileDevice}
                    theme={theme}
                />
            )}
        </Box>
    );
};

export default NavBar;
