import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../firebaseInit";

const functions = getFunctions(firebaseApp);
const autocomplete = httpsCallable(functions, "autocompletePrompt");
const autocompleteNew = httpsCallable(functions, "autocompletePromptNew");
const verifyEmail = httpsCallable(functions, "verifyEmail");

export const autoCompletePrompt = async (text, style) => {
    return new Promise(async (resolve, reject) => {
        let textResult;
        if (text.endsWith(".")) {
            textResult = text.trim();
        } else {
            textResult = text.trim() + ".";
        }

        console.log("SENDING", textResult);
        console.log("STYLE:", style);
        await autocompleteNew({
            text: textResult,
            style,
        })
            .then((res) => {
                console.log("RES", res);
                resolve(res.data);
            })
            .catch((err) => {
                console.log("ERR", err);
            });
    });
};


export { verifyEmail };
