import React, { useState, useEffect } from "react";
import "./DarkToastNotification.css";
import { Typography } from "@mui/material";
import {
    HiX,
    HiOutlineCheckCircle,
    HiOutlineInformationCircle,
    HiOutlineExclamationCircle,
    HiOutlineStop,
} from "react-icons/hi";

const DarkToastNotification = ({ message, type, duration, position }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [duration]);

    const handleClose = () => {
        setVisible(false);
    };

    return (
        visible && (
            <div className={`toast toast-${type} toast-${position}`}>
                <Typography className="toastContent">
                    <span>
                        {type === "success" ? (
                            <HiOutlineCheckCircle size={18} />
                        ) : type === "error" ? (
                            <HiOutlineExclamationCircle size={18} />
                        ) : type === "warning" ? (
                            <HiOutlineInformationCircle size={18} />
                        ) : (
                            <HiOutlineStop size={18} />
                        )}
                    </span>
                    <span>{message}</span>
                </Typography>

                <button onClick={handleClose}>
                    <HiX size={15} />
                </button>
            </div>
        )
    );
};

export default DarkToastNotification;
