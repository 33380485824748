import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    templates: [],
    selectedTemplate: {},
    customTemplates: "",
};

const templateSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {
        templatesFetched: (state, action) => {
            state.templates = action.payload.templates;
        },
        templatesSortedSet: (state, action) => {
            state.templates = action.payload.templates;
        },
        templateSelected: (state, action) => {
            state.selectedTemplate = action.payload.selectedTemplate;
        },
        customTemplatesFetched: (state, action) => {
            state.customTemplates = action.payload.customTemplates;
        },
    },
});

export const {
    templatesFetched,
    templateSelected,
    customTemplatesFetched,
    templatesSortedSet,
} = templateSlice.actions;

export default templateSlice.reducer;
