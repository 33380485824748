import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
    Tooltip,
    TextField,
    FormControl,
    Select,
    Badge,
    Chip,
    Slider,
} from "@mui/material";
import {
    HiOutlineClock,
    HiOutlineChip,
    HiOutlineX,
    HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { changeInputs } from "../../../../features/image/adProductSlice";

const WorkSpaceInputBox = ({
    productData,
    onGenerateOutput,
    selectedImage,
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.adProduct);
    const { inputImage, outputs, imageNum, prompt, generating, errorMessage } =
        state;
    const formRef = useRef();

    const theme = useTheme();
    const [tipsPopupOpen, setTipsPopupOpen] = useState(false);

    const [rangeValue, setRangeValue] = useState(20);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [selectInputValue, setSelectedInputValue] = useState("");

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);

        dispatch(
            changeInputs({
                text: e.target.value,
                num: Number(selectInputValue),
            })
        );
    };

    const handleSelectChange = (e) => {
        setSelectedInputValue(e.target.value);
        dispatch(
            changeInputs({ text: textAreaValue, num: Number(e.target.value) })
        );
    };

    const handleRangeChange = (event, newValue) => {
        setRangeValue(newValue);
    };

    const handleTipsPopupOpen = () => {
        setTipsPopupOpen(true);
    };

    const handleTipsPopupClose = () => {
        setTipsPopupOpen(false);
    };

    const convertToBlobURL = async (file) => {
        try {
            const blob = await fetch(URL.createObjectURL(file)).then((r) =>
                r.blob()
            );
            // Use the blob object as needed
            console.log(blob);
        } catch (error) {
            console.error("Error converting image to blob:", error);
        }
    };

    useEffect(() => {
        /* const imgBlob =  */ convertToBlobURL(selectedImage);
        const onImageUpload = async (e) => {
            // const { path } = await uploadToStorage({ file: e.target.files[0] });
            //console.log(e.target.files[0].type, "File Tpye");
            const url = URL.createObjectURL(selectedImage);
            console.log(url, "my url");
            /* dispatch(imageUploaded({ inputImage: url }));
            scrollToImage(); */
        };
        onImageUpload();
    }, [selectedImage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        /* const formData = new FormData(formRef.current);
        formData.append("prompt", textAreaValue);
        formData.append("image_file", inputImage);
        formData.append("image_num", Number(selectInputValue));
        for (let entry of formData.entries()) {
            console.log(entry);
        } */

        onGenerateOutput(e, "replace");
    };

    return (
        <Box
            sx={{
                width: { xs: "100%", lg: "30%" },
                display: "flex",
                flexDirection: "column",
            }}
            style={{ gap: "1rem" }}
        >
            <Box>
                <React.Fragment>
                    {
                        <Chip
                            label="Tips"
                            sx={{ fontSize: "0.9rem", cursor: "pointer" }}
                            variant="outlined"
                            color="primary"
                            icon={<HiOutlineQuestionMarkCircle size={20} />}
                            onClick={handleTipsPopupOpen}
                        />
                    }
                    <Popover
                        open={Boolean(tipsPopupOpen)}
                        anchorEl={tipsPopupOpen}
                        onClose={handleTipsPopupClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            elevation: 1,
                            sx: {
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? "white"
                                        : "#3F3C3C",
                                display: "flex",
                                flexDirection: "column",
                                p: "0.7rem",
                                borderRadius: "0.7rem",
                                border: "1.5px solid #D6D2DA",
                                width: "400px",
                            },
                        }}
                    >
                        <Typography>
                            <b>Pro tips: </b>
                            {productData.productInputBox.tips}
                        </Typography>
                    </Popover>
                </React.Fragment>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        fontSize: { xs: "1rem", lg: "1.2rem" },
                    }}
                >
                    Generate {productData.porductTitle}
                </Typography>
            </Box>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
                ref={formRef}
            >
                {productData.productInputBox.primaryInput.isActive && (
                    <Box>
                        <TextField
                            placeholder={
                                productData.productInputBox.primaryInput
                                    .placeHolderText
                            }
                            label={
                                productData.productInputBox.primaryInput
                                    .labelText
                            }
                            multiline
                            rows={3}
                            required
                            value={textAreaValue}
                            onChange={handleTextAreaChange}
                            sx={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                /* "& textarea": {
                                resize: "vertical",
                                maxHeight: "150px",
                            }, */
                            }}
                        />
                    </Box>
                )}
                {productData.productInputBox.quantityDropdown.isActive && (
                    <Box>
                        <FormControl sx={{ width: "100%" }}>
                            <Typography>
                                {
                                    productData.productInputBox.quantityDropdown
                                        .labelText
                                }
                            </Typography>
                            <Select
                                value={selectInputValue}
                                onChange={handleSelectChange}
                                displayEmpty
                                /* inputProps={{ "aria-label": "Without label" }} */
                                required
                            >
                                {productData.productInputBox.quantityDropdown.options.map(
                                    (item, index) => (
                                        <MenuItem value={item} key={index}>
                                            {item}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {productData.productInputBox.brush.isActive && (
                    <Box sx={{ width: "100%" }}>
                        <Typography id="slider-label">
                            {productData.productInputBox.brush.labelText}:{" "}
                            {rangeValue}
                        </Typography>
                        <Slider
                            value={rangeValue}
                            onChange={handleRangeChange}
                            aria-labelledby="slider-label"
                            min={productData.productInputBox.brush.min}
                            max={productData.productInputBox.brush.max}
                            step={1}
                            required
                        />
                    </Box>
                )}

                {/* <Box>
                    <FormControl sx={{ width: "100%" }}>
                        <Select
                            value={selectedValue}
                            onChange={handleSelectChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            required
                        >
                            {activeTool.options.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box> */}

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="submit"
                        sx={{
                            py: "0.5rem",
                            px: "1.5rem",
                            width: "fit-content",
                            /*  height: "2.5rem", */
                            borderRadius: "2rem",
                            background:
                                "linear-gradient(25deg, #6363FF 10%, #281142 90%)",
                            color: "white",
                            fontSize: "0.9rem",
                            transition: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            border: "1.6px solid white",
                            boxShadow: "none",
                            /* margin: "0 auto", */

                            "&:hover": {
                                background:
                                    "linear-gradient(85deg, #6363FF 10%, #281142 90%)",
                                transition: "all 0.3s ease-in",
                                /* boxShadow: " 1px 7px 400px -2px black", */
                            },
                        }}
                        style={{
                            gap: "0.3rem",
                        }}
                    >
                        <>
                            <Typography sx={{ fontSize: "1rem" }}>
                                Generate
                            </Typography>
                            <span style={{ marginTop: "3px" }}>
                                <HiOutlineChip size={20} />
                            </span>
                        </>
                        {/* {isLoading ? (
                            <Typography sx={{ fontSize: "1rem" }}>
                                Generating Image ...
                            </Typography>
                        ) : (
                            <>
                                <Typography sx={{ fontSize: "1rem" }}>
                                    Generate
                                </Typography>
                                <span style={{ marginTop: "3px" }}>
                                    <HiOutlineChip size={20} />
                                </span>
                            </>
                        )} */}
                    </Button>
                </Box>
                {/* {isError && showError && (
                    <Box
                        sx={{
                            background: "#FFDADA",
                            py: "1rem",
                            px: "1.5rem",
                            borderRadius: "0.5rem",
                            border: "2px solid #FF5454",
                            color: "#FF5454",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                        style={{
                            gap: " 1rem",
                        }}
                    >
                        <Typography sx={{ fontSize: "1rem" }}>
                            "Oops! Please try again"
                        </Typography>
                        <IconButton
                            onClick={() => setShowError(false)}
                            sx={{
                                background: "grey",
                                "&:hover": {
                                    background: "#6464F4",
                                },
                            }}
                        >
                            <HiOutlineX />
                        </IconButton>
                    </Box>
                )} */}
            </form>
        </Box>
    );
};

export default WorkSpaceInputBox;
