import {
    HiOutlineChip,
    HiOutlineAcademicCap,
    HiOutlineMusicNote,
    HiOutlineSpeakerphone,
    HiOutlineSparkles,
    HiOutlineStatusOnline,
    HiInbox,
} from "react-icons/hi";
import { IoGameController } from "react-icons/io5";

export const useCaseArr = [
    {
        title: "All",
        icon: <HiOutlineSparkles size={20} />,
    },
    {
        title: "Film & Video",
        icon: <HiOutlineMusicNote size={20} />,
    },
    {
        title: "Lofi",
        icon: <HiOutlineAcademicCap size={20} />,
    },
    {
        title: "Podcast",
        icon: <HiOutlineSpeakerphone size={20} />,
    },
    {
        title: "Meditation",
        icon: <HiOutlineStatusOnline size={20} />,
    },
    {
        title: "Gaming",
        icon: <IoGameController size={20} />,
    },
    {
        title: "Sample",
        icon: <HiInbox size={20} />,
    },
    {
        title: "Synth",
        icon: <HiOutlineChip size={20} />,
    },
];
