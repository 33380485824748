import { getApp, initializeApp } from "firebase/app";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from "firebase/functions";

import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//     apiKey: "AIzaSyDQEwHkFxgYzWsWrYeuDZF4qoImjR3lmLE",
//     authDomain: "aiseo-art-d43f7.firebaseapp.com",
//     projectId: "aiseo-art-d43f7",
//     storageBucket: "aiseo-art-d43f7.appspot.com",
//     messagingSenderId: "823001121601",
//     appId: "1:823001121601:web:5d60df27fd77cd79bc97c0",
//     measurementId: "G-MYT2XY1PY3",
// };

const firebaseConfig = {
    apiKey: "AIzaSyCk8UH5EHZwreKcNdRJckpP3sXXp7i-7x4",
    authDomain: "code-translate-c9640.firebaseapp.com",
    projectId: "code-translate-c9640",
    storageBucket: "code-translate-c9640.appspot.com",
    messagingSenderId: "601470906647",
    appId: "1:601470906647:web:fc8f4b2fbf40dbb7d70752",
    measurementId: "G-JG9VPQKZ0S",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
const functions = getFunctions(getApp());

let url = window.location.hostname;
if (url === "localhost") {
    // connectFunctionsEmulator(functions, "127.0.0.1", 5002);
    // connectFunctionsEmulator(functions, "localhost", 5001);
} else {
    console.log = () => {};
}

// Initialize Firebase
// const analytics = getAnalytics(firebaseApp);

export function onGettingIdToken(callback) {
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const token = await getIdToken(user);
            callback(token);
        }
    });
}

export async function getFirebaseAuthToken() {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await getIdToken(user);
                resolve(token);
            } else {
                resolve(null);
            }
        });
        unsub();
    });
}

export const checkoutCredit = httpsCallable(functions, "checkoutCredit");
export const checkout = httpsCallable(functions, "checkout");
export const imageGeneration = httpsCallable(functions, "imageGenerationNew");
export const getLexicaImages = httpsCallable(functions, "lexicaImages");
export const rewardsHandler = httpsCallable(functions, "rewardsHandler");
export const imagetoImage = httpsCallable(functions, "image_to_image");
export const commandOpenAi = httpsCallable(functions, "commandOpenAi");
export const generateSDXLimage = httpsCallable(functions, "generateSDXLimage");
export const faceSwapReplicate = httpsCallable(functions, "faceSwapReplicate");
export const templateDescription = httpsCallable(
    functions,
    "templateDescription"
);

export const getZipUploadUrl = httpsCallable(functions, "getZipUploadUrl");
export const bulkDreamboothGeneration = httpsCallable(
    functions,
    "bulkDreamboothGeneration"
);

export const startTrainingModel = httpsCallable(
    functions,
    "startTrainingModel"
);

export const checkTrainingStatus = httpsCallable(
    functions,
    "checkTrainingStatus"
);

export const uploadZippedImages = httpsCallable(
    functions,
    "uploadZippedImages"
);

export const retrieveProration = httpsCallable(functions, "retrieveProration");
export const upgradeSubscription = httpsCallable(
    functions,
    "upgradeSubscription"
);
