import axios from "axios";
import { getFirebaseAuthToken } from "../firebaseInit";
import { uploadProductImage } from "../firestore/storageHandler";

const BASE_ART_URL =
    "https://us-central1-code-translate-c9640.cloudfunctions.net/art";

const generateImage = async ({ formData, token }) => {
    const resp = await axios.post(
        "https://us-central1-code-translate-c9640.cloudfunctions.net/prodimageGen",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }
    );
    const data = resp.data;
    const { url } = await uploadProductImage({ data, ext: "jpeg" });
    return url;
};

export const getAdProductImage = async (formData) => {
    const token = await getFirebaseAuthToken();
    let imageNum = formData.get("image_num");
    console.log(imageNum);
    let arr = Array.apply(null, Array(parseInt(imageNum)));
    let images = await Promise.all(
        arr.map((_, i) => {
            return generateImage({ formData, token });
        })
    );
    return { data: [...images] };
};

export const getDragganImage = async (formData) => {
    const token = await getFirebaseAuthToken();
    const resp = await axios.post("http://127.0.0.1:4000", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    });
    const data = resp.data;
    return data;
};

// TODO: Use Interceptor
export const uploadFiles = async (formData) => {
    console.log("Upload Files entered");
    const token = await getFirebaseAuthToken();
    return await axios.post(BASE_ART_URL + "/upload-images", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    });
};

// TODO: Use Interceptor
export const generateVideo = async (apiInputData) => {
    console.log("generate video api call entered");
    const token = await getFirebaseAuthToken();
    console.log(apiInputData, "--apiData");

    return await axios.post(
        "https://redis-text2video-t6pxynw6cq-uc.a.run.app/metamorph",
        apiInputData,
        {
            headers: {
                "Content-Type": "application/json",
                /* Authorization: `Bearer ${token}`, */
            },
        }
    );

    //legacy url
    //https://text2motion-t6pxynw6cq-uc.a.run.app/metamorph
    //https://str-prompt-feat-t6pxynw6cq-uc.a.run.app/metamorph
    //https://deepgramsrt-t6pxynw6cq-uc.a.run.app/metamorph
    //https://aicaption-t6pxynw6cq-uc.a.run.app/metamorph
    //https://longvid-t6pxynw6cq-uc.a.run.app/metamorph
    //https://centercaptiont2v-t6pxynw6cq-uc.a.run.app/metamorph
    //"https://pexelst2v-t6pxynw6cq-uc.a.run.app/metamorph",
};


export const generateSubtitleVideo = async (apiInputData) => {
    console.log("generate subtitle video api call entered");
    const token = await getFirebaseAuthToken();
    console.log(apiInputData, "--apiData");

    return await axios.post(
        "https://redis-text2video-t6pxynw6cq-uc.a.run.app/generate_autocaption",
        apiInputData,
        {
            headers: {
                "Content-Type": "application/json",
                /* Authorization: `Bearer ${token}`, */
            },
        }
    );

    //legacy url
    //https://text2motion-t6pxynw6cq-uc.a.run.app/metamorph
    //https://str-prompt-feat-t6pxynw6cq-uc.a.run.app/metamorph
    //https://deepgramsrt-t6pxynw6cq-uc.a.run.app/metamorph
};
