import React, { useState, useEffect, useRef } from "react";
import OptionSelected from "../../atoms/OptionSelected";

import { Box, Button, Typography, CircularProgress } from "@mui/material";

import PresetPackageCard from "./childAtoms/PresetPackageCard";
import GeneratedPhotosBox from "./GeneratedPhotosBox";
import GeneratedAlbumsBox from "./GeneratedAlbumsBox";
import {
    getFirestore,
    collection,
    query,
    onSnapshot,
} from "firebase/firestore";
import { firebaseApp } from "../../../firebaseInit";
import { useLocation, useNavigate } from "react-router-dom";

const styleConfigProp = {
    option: {
        active: {
            bgColor: "#0F172A",
            textColor: "white",
        },
        inactive: {
            bgColor: "transparent",
            textColor: "#0F172A",
        },
    },
    container: {
        lightMode: {
            bgColor: "#EEF2FF",
            borderColor: "#DADFEB",
            boxShadow: "0px 4px 15px #E8FAFF",
        },
        darkMode: {
            bgColor: "#EEF2FF",
            borderColor: "#DADFEB",
            boxShadow: "none",
        },
    },
    centered: false,
};

const MainAvatarsComponent = ({
    setPromptVal,
    user,
    setIsPresetsSelected,
    setSelectedPresets,
    selectedPresets,
    isGalleryEnabled,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore(firebaseApp);
    const [loading, setLoading] = useState(true);
    const [albumsLoading, setAlbumsLoading] = useState(true);
    const [premadePromptsLoading, setPremadePromptsLoading] = useState(true);
    const [presetsFromDb, setPresetsFromDb] = useState([]);
    const [premadePrompts, setPremadePrompts] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        const q = query(collection(db, "dreamboothTemplatesV2"));

        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                const presetsData = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    presetsData.push(data);
                });
                setPresetsFromDb(presetsData);
                setAlbumsLoading(false);
                console.log(presetsData, "--presetsData--");
            },
            (error) => {
                console.error("Error fetching documents: ", error);
                setAlbumsLoading(false);
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, [db]);

    useEffect(() => {
        const q = query(collection(db, "avatarPrompts"));

        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                const premadePromptsData = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    premadePromptsData.push(data);
                });
                setPremadePrompts(premadePromptsData);
                //setLoading(false);
                setPremadePromptsLoading(false);
                console.log(premadePromptsData, "--premadePromptsData--");
            },
            (error) => {
                console.error("Error fetching documents: ", error);
                //setLoading(false);
                setPremadePromptsLoading(false);
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, []);

    const enableGalleryOption = () => {
        setIsPresetsSelected(false);
        handleActivePageChange(2);
        setSelectedPresets([]);
        console.log("kop2");
    };

    useEffect(() => {
        if (isGalleryEnabled) {
            enableGalleryOption();
            console.log("kop");
        }
    }, [isGalleryEnabled]);

    const optionItems = ["Albums", "Photos"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const [activePage, setActivePage] = useState(1);

    const handleActivePageChange = (currStep) => {
        setActivePage(currStep);
    };

    const handleUsePromptClick = (currPrompt) => {
        setPromptVal(currPrompt);
    };

    const handleUserCheck = () => {
        if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                if (location.pathname === "/ai-photoshoots") {
                    navigate("/login");
                    console.log("/login");
                }
            }, 1300);
            return false;
        }
        return true;
    };
    return (
        <Box>
            <Box sx={{}}>
                <Box
                    sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: { xs: "center", md: "left" },
                        justifyContent: { xs: "center", md: "flex-start" },
                    }}
                >
                    <Typography
                        sx={{
                            padding: "0.4rem 0",
                            borderBottom:
                                activePage === 1
                                    ? "3px solid #6464FF"
                                    : "3px solid transparent",
                            cursor: "pointer",
                            "&:hover": {
                                background: "rgba(223, 223, 236, 0.4)",
                            },
                        }}
                        onClick={() => {
                            //if (!handleUserCheck()) return;
                            setIsPresetsSelected(true);

                            handleActivePageChange(1);
                        }}
                    >
                        Albums
                    </Typography>
                    <Typography
                        sx={{
                            padding: "0.4rem 0",
                            borderBottom:
                                activePage === 0
                                    ? "3px solid #6464FF"
                                    : "3px solid transparent",
                            cursor: "pointer",
                            "&:hover": {
                                background: "rgba(223, 223, 236, 0.4)",
                            },
                        }}
                        onClick={() => {
                            setIsPresetsSelected(false);
                            handleActivePageChange(0);
                            setSelectedPresets([]);
                        }}
                    >
                        Prompts
                    </Typography>
                    <Typography
                        sx={{
                            padding: "0.4rem 0",
                            borderBottom:
                                activePage === 2
                                    ? "3px solid #6464FF"
                                    : "3px solid transparent",
                            cursor: "pointer",
                            "&:hover": {
                                background: "rgba(223, 223, 236, 0.4)",
                            },
                        }}
                        onClick={() => {
                            if (!handleUserCheck()) return;

                            setIsPresetsSelected(false);
                            handleActivePageChange(2);
                            setSelectedPresets([]);
                        }}
                    >
                        Image Gallery
                    </Typography>
                </Box>
                {activePage === 0 && (
                    <>
                        {premadePromptsLoading ? (
                            <CircularProgress
                                size={50}
                                sx={{
                                    margin: "2.4rem 0",
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                    height: "100%",
                                    overflowY: "scroll",
                                    paddingY: "1.3rem",
                                    paddingX: "0",
                                    alignItems: "center",
                                    justifyContent: {
                                        xs: "center",
                                        md: "initial",
                                    },
                                }}
                            >
                                {premadePrompts.map((model, index) => (
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "250px",
                                            height: "250px",
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                            "&:hover > div": {
                                                visibility: "visible",
                                                opacity: "1",
                                                transition: "all 0.11s ease-in",
                                            },
                                            cursor: "pointer",
                                        }}
                                    >
                                        <img
                                            src={model.imgSrc}
                                            style={{
                                                position: "absolute",
                                                left: "0",
                                                right: "0",
                                                top: "0",
                                                bottom: "0",
                                                width: "250px",
                                                height: "250px",
                                                zIndex: "80",
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                background:
                                                    "rgba(0, 0, 0, 0.5)",
                                                color: "white",
                                                fontSize: "13px",
                                                textAlign: "center",
                                                position: "absolute",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                                zIndex: "90",
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "12px",
                                                visibility: "hidden",
                                                opacity: "0",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    width: "55%",
                                                    /* maxWidth: "45%", */
                                                    color: "#E2E8F0",
                                                    fontSize: "12.5px",
                                                }}
                                            >
                                                {model.promptText}
                                            </Typography>
                                            <Button
                                                sx={{
                                                    color: "#4ADE80",
                                                    fontWeight: "bold",
                                                    fontSize: "14px",
                                                    whiteSpace: "nowrap", // This ensures the text doesn't wrap.
                                                    overflow: "hidden",
                                                    textTransform: "capitalize",
                                                }}
                                                onClick={() =>
                                                    handleUsePromptClick(
                                                        model.promptText
                                                    )
                                                }
                                            >
                                                Use Prompt
                                            </Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </>
                )}
                {activePage === 1 && (
                    <>
                        {albumsLoading ? (
                            <CircularProgress
                                size={50}
                                sx={{
                                    margin: "2.4rem 0",
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1.2rem",
                                    flexWrap: "wrap",
                                    height: "100%",
                                    overflowY: "scroll",
                                    paddingY: "1.3rem",
                                    paddingX: "0",

                                    alignItems: "center",

                                    justifyContent: {
                                        xs: "center",
                                        md: "flex-start",
                                    },
                                }}
                            >
                                {presetsFromDb.map((item, index) => (
                                    <PresetPackageCard
                                        item={item}
                                        index={index}
                                        setSelectedPresets={setSelectedPresets}
                                        selectedPresets={selectedPresets}
                                    />
                                ))}
                            </Box>
                        )}
                    </>
                )}
                {activePage === 2 && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: {
                                    xs: "center",
                                    md: "flex-start",
                                },
                                margin: "30px 0 24px 0",
                            }}
                        >
                            <OptionSelected
                                optionItems={optionItems}
                                optionSelected={optionSelected}
                                setOptionSelected={setOptionSelected}
                                styleConfigProp={styleConfigProp}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "1rem",
                                flexWrap: "wrap",
                                height: "100%",
                                /* overflowY: "scroll", */
                                paddingY: "1.3rem",
                                paddingX: "0",
                                width: "100%",
                            }}
                        >
                            {optionSelected === "Photos" ? (
                                <GeneratedPhotosBox user={user} />
                            ) : (
                                <GeneratedAlbumsBox user={user} />
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default MainAvatarsComponent;
