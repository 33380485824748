import React from "react";
import LayoutNew from "../layout/LayoutNew";
import Footer from "../footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import ImgUpSection from "./ImgUpSection/ImgUpSection";
import { Container } from "@mui/material";
import VariationImgUpSection from "./VariationImgUpSection/VariationImgUpSection";
import InstructImgUpSection from "./InstructImgUpSection/InstructImgUpSection";
import EraseReplaceImgUpSection from "./EraseReplaceImgUpSection/EraseReplaceImgUpSection";
import DragganImgUpSection from "./DragganImgUpSection/DragganImgUpSection";
import OurToolsSection from "../Landing/OurToolsSection";
import ExtraHelperBox from "./ImgUpSection/WorkSpace/ExtraHelperBox";

const MainProducts = ({ productData, onGenerateOutput, user, credits }) => {
    return (
        <>
            <LayoutNew footer={<Footer />}>
                <Container
                    sx={{
                        fontFamily: "Inter, Roboto, Arial, sans-serif",
                        marginBottom: "20rem",
                    }}
                >
                    <HeroSection productData={productData} />
                    <ExtraHelperBox productData={productData} />

                    {/* {productData.productSlug === "advertisement-product" && (
                        <ImgUpSection
                            productData={productData}
                            onGenerateOutput={onGenerateOutput}
                            user={user}
                        />
                    )} */}
                    {productData.porductTitle === "Advertisement Product" && (
                        <ImgUpSection
                            productData={productData}
                            onGenerateOutput={onGenerateOutput}
                            user={user}
                        />
                    )}

                    {productData.porductTitle === "Image Variation" && (
                        <VariationImgUpSection
                            productData={productData}
                            onGenerateOutput={onGenerateOutput}
                            user={user}
                            credits={credits}
                        />
                    )}

                    {productData.porductTitle === "Instruct" && (
                        <InstructImgUpSection
                            productData={productData}
                            onGenerateOutput={onGenerateOutput}
                            user={user}
                            credits={credits}
                        />
                    )}

                    {productData.porductTitle === "Erase & Replace" && (
                        <EraseReplaceImgUpSection
                            productData={productData}
                            onGenerateOutput={onGenerateOutput}
                            user={user}
                            credits={credits}
                        />
                    )}

                    {productData.porductTitle === "Draggan Tool" && (
                        <DragganImgUpSection
                            productData={productData}
                            user={user}
                            credits={credits}
                        />
                    )}
                    <OurToolsSection />
                </Container>
            </LayoutNew>
        </>
    );
};

export default MainProducts;
