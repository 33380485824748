import React, { useState } from "react";
import axios from "axios";
import {
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    MenuItem,
    FormControl,
    Select,
    CircularProgress,
    Tooltip
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineX,
    HiOutlineDownload,
} from "react-icons/hi";
import headshotGenExampleImage from "../../imgs/headshotGenExampleImage.png";
import circleRegenrateIcon from "../../imgs/circleRegenrateIcon.png";
import arrowTopIconHeadshotPitch from "../../imgs/arrowTopIconHeadshotPitch.svg";
import camFlashIconHeadshotPitch from "../../imgs/camFlashIconHeadshotPitch.svg";
import { saveAs } from "file-saver";
import { getFirebaseAuthToken } from "../../firebaseInit";
import { useAPI } from "../../StateProvider";

import ToastNotification from "../atoms/ToastNotification";
import UpgradePopupHeadshot from "./UpgradePopupHeadshot";
import FreeGenImages from "./FreeGenImages";

const FreeVersionHeadshot = ({ user, credits }) => {
    const { data } = useAPI();

    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [validationMessage, setValidationMessage] = useState("");
    const [showValidationError, setShowValidationError] = useState(false);

    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const [generationFailedErrorMsg, setGenerationFailedErrorMsg] =
        useState("");
    const [showGenerationFailedError, setShowGenerationFailedError] =
        useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [genderInputVal, setGenderInputVal] = useState("");
    const [dragging, setDragging] = useState(false);
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState("");

    const [generatedImageUrl, setGeneratedImageUrl] = useState("");

    const [images, setImages] = useState([]); // New state for handling multiple images
    const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
    const [generatedImageUrlArr, setGeneratedImageUrlArr] = useState([]);
    const [showCannotUploadMoreError, setShowCannotUploadMoreError] =
        useState(false);

    const [freeGenImagesLoading, setFreeGenImagesLoading] = useState(false);
    const [newHeadshotsDocId, setNewHeadshotsDocId] = useState("");

    const resetStates = () => {
        setNewHeadshotsDocId("");
        setFreeGenImagesLoading(false);
        setGeneratedImageUrlArr([]);
        setImagePreviewUrls([]);
        setImages([]);
        setGenderInputVal("");
        setIsLoading("");
        setShowGenerationFailedError(false);
        setGenerationFailedErrorMsg("");
        setCurrentPage(0);
        setValidationMessage("");
        setShowValidationError(false);
        setShowUpgradePopup(false);

    }


    /* const handleDrop = (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        if (files.length) {
            setImage(files[0]);
            setImagePreviewUrl(URL.createObjectURL(files[0]));
        }
        setDragging(false);
    }; */
    const handleDrop = (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        setImages((prevImages) => [...prevImages, ...files]);
        setImagePreviewUrls((prevUrls) => [
            ...prevUrls,
            ...files.map((file) => URL.createObjectURL(file)),
        ]);
        /* if (images.length + files.length <= 5) {
            setImages((prevImages) => [...prevImages, ...files]);
            setImagePreviewUrls((prevUrls) => [
                ...prevUrls,
                ...files.map((file) => URL.createObjectURL(file)),
            ]);
        } else {
            setShowCannotUploadMoreError(true);
            setTimeout(() => {
                setShowCannotUploadMoreError(false);
            }, 3200);
        } */
        setDragging(false);
    };

    /*  const handleFileChange = (e) => {
         e.preventDefault();
         const file = e.target.files[0];
         setImage(file);
         setImagePreviewUrl(URL.createObjectURL(file));
     }; */
    const handleFileChange = (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        setImages((prevImages) => [...prevImages, ...files]);
        setImagePreviewUrls((prevUrls) => [
            ...prevUrls,
            ...files.map((file) => URL.createObjectURL(file)),
        ]);
        /* if (images.length + files.length <= 5) {
            setImages((prevImages) => [...prevImages, ...files]);
            setImagePreviewUrls((prevUrls) => [
                ...prevUrls,
                ...files.map((file) => URL.createObjectURL(file)),
            ]);
        } else {
            setShowCannotUploadMoreError(true);
            setTimeout(() => {
                setShowCannotUploadMoreError(false);
            }, 3200);
        } */
        e.target.value = null;
    };



    const handleHeadshotGenerateClick = async () => {
        if (!user) {
            navigate("/login");
            return;
        }
        if (data?.credits < 2) {
            console.log("please buy credits");
            setShowUpgradePopup(true);

            return;
        }
        if (!image || genderInputVal === "") {
            setShowValidationError(true);
            if (!image) {
                setValidationMessage("Please select an image");
            } else {
                setValidationMessage("Please select a gender");
            }

            setTimeout(() => {
                setShowValidationError(false);
            }, 3200);
            return;
        }
        console.log("--Loading--");
        setIsLoading(true);

        const formData = new FormData();
        formData.append("image", image);
        formData.append("gender", genderInputVal);
        formData.append("api_type", "generate_headshot");

        const token = await getFirebaseAuthToken();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.post(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/API",
                formData,
                config
            );
            /* const response = await axios.post(
                "http://127.0.0.1:8080/API",
                formData,
                config
            ); */
            console.log(response.data);
            setGeneratedImageUrl(response.data.generatedHeadshot);
            setIsLoading(false);

            setCurrentPage(1);
        } catch (error) {
            console.error("Error uploading image:", error);
            setIsLoading(false);
            setShowGenerationFailedError(true);
            setGenerationFailedErrorMsg("Generation Failed! Please try again");
            setTimeout(() => {
                setShowGenerationFailedError(false);
            }, 3200);
        }
    };


    const handleHeadshotGenerateClick2 = async () => {
        // setCurrentPage(1);
        // setFreeGenImagesLoading(true);

        // setTimeout(() => {
        //     setFreeGenImagesLoading(false);

        // }, 3000);
        // return;
        if (!user) {
            navigate("/login");
            return;
        }

        const isProUser = data?.subscription?.type === "Free" ? false : true;
        //console.log("isProUser", isProUser);
        if (images.length === 0 || images.length < 4 || genderInputVal === "") {
            setShowValidationError(true);
            if (images.length === 0) {
                setValidationMessage("Please select an image");
            } else if (images.length < 4) {
                setValidationMessage("Please select 4 images");
            } else {
                setValidationMessage("Please select a gender");
            }

            setTimeout(() => {
                setShowValidationError(false);
            }, 3200);
            return;
        }


        // const generationQty = isProUser ? 4 : 1;
        const generationQty = 2;
        if (
            data?.credits < generationQty
        ) {
            console.log("Please Upgrade!");
            setShowUpgradePopup(true);
            return;
        }


        // console.log(isProUser ? "Pro" : "free")
        // console.log(generationQty, "generationQty")
        // console.log("helping text")

        /* mixpanel.track("Generations headshots", {
            Description:
                "Headshots - page - total generations",
        }); */


        setCurrentPage(1);

        setIsLoading(true);

        setFreeGenImagesLoading(true);

        const token = await getFirebaseAuthToken();

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        console.log("Config--", config);


        const formData = new FormData();
        images.forEach((image, index) => {
            if (index < 4) {
                formData.append(`image${index + 1}`, image); // Start indexing from 1
            }
        });


        formData.append("gender", genderInputVal);
        formData.append("uid", user.uid);
        formData.append("userEmail", user.email);
        formData.append("api_type", "generate_multiple_headshots");
        formData.append("isProUser", isProUser);


        try {
            const resp = await axios.post(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/API_headshot_gen/",
                /* "http://127.0.0.1:4000/API_headshot_gen/", */
                /* "https://us-central1-code-translate-c9640.cloudfunctions.net/API", */
                formData,
                config
            );

            const imgOutput = await [...resp.data.data.generated_img_output_url];
            const outputDocId = resp.data.data.saved_doc_id;
            setNewHeadshotsDocId(outputDocId);

            console.log("response min: ", resp);
            console.log("response min: ", false || [...resp.data.data.generated_img_output_url].length < 1);
            setGeneratedImageUrlArr(imgOutput);

            /* mixpanel.track("Successful Generations Headshots", {
                Description:
                    "Headshots - page - Successful generations",
            }); */




        } catch (err) {
            console.error("Error generating headshots:", err);
            setShowGenerationFailedError(true);
            setTimeout(() => {
                setShowGenerationFailedError(false);
            }, 4000)
            setGenerationFailedErrorMsg("Generation Failed! Please try again");
            setFreeGenImagesLoading(false);

            /* setShowMainError(true);
            setIsLoading(false);
            setProGenImagesLoading(false);
            setTimeout(() => {
                setShowMainError(false);

            }, 4000) */
            resetStates();
            /* mixpanel.track("Failed Generations Headshots", {
                Description:
                    "Headshots - page - Unsuccessful generations",
            }); */
        } finally {
            setIsLoading(false);
            setFreeGenImagesLoading(false);

        }


    };

    const handleGenderInputValChange = (e) => {
        setGenderInputVal(e.target.value);
    };

    /* const removeUploadedImage = () => {
        setImagePreviewUrl("");
        setImage(null);
    }; */
    const removeUploadedImage = (index) => {
        setImagePreviewUrls((prevUrls) =>
            prevUrls.filter((_, idx) => idx !== index)
        );
        setImages((prevImages) => prevImages.filter((_, idx) => idx !== index));
        document.getElementById("file-input").value = null;
    };

    const handleDownloadClick = () => {
        saveAs(generatedImageUrl, "download-image.png");
    };

    const closeUpgradePopup = () => {
        setShowUpgradePopup(!showUpgradePopup);
    };




    return (
        <Box>
            {showUpgradePopup && (
                <UpgradePopupHeadshot closeUpgradePopup={closeUpgradePopup} />
            )}
            {showValidationError && (
                <ToastNotification
                    message={validationMessage}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showGenerationFailedError && (
                <ToastNotification
                    message={generationFailedErrorMsg}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {currentPage === 0 && (
                <Box
                    sx={{
                        background: "#0F172A",
                        color: "white",
                        display: "flex",
                        gap: "7rem",
                        alignItems: "center",
                        flexDirection: { xs: "column", md: "row" },
                        /* padding: { xs: "0.7rem 0.5rem", md: "0.8rem 4rem" }, */
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "533px",
                            padding: { xs: "0 1rem", md: "0 0" },
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                /* h1 */
                                fontFamily: "Inter",
                                /* fontSize: "48px",
                                fontStyle: "normal",
                                fontWeight: "800",
                                lineHeight: "55px", */
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "30px", md: "48px" },
                                fontStyle: "normal",
                                fontWeight: "800",
                                lineHeight: {
                                    xs: "32px",
                                    md: "55px",
                                } /* 114.583% */,
                                textAlign: { xs: "center", md: "left" },
                            }}
                        >
                            Create Your <br />
                            <span style={{ color: "#818CF8" }}>
                                Professional
                            </span>{" "}
                            Headshot
                        </Typography>

                        <Box
                            sx={{
                                marginTop: "48px",
                                ".MuiMenu-paper": {
                                    bgcolor: "red", // the background color you want
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    marginBottom: "0.4rem",
                                    fontFamily: "Inter",

                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Select Your Gender
                            </Typography>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    marginTop: "10px",
                                    "& > div": {
                                        borderRadius: "8px",
                                        background: "#242E3D",
                                        border: "1px solid #334155",
                                    },
                                }}
                            >
                                <Select
                                    labelId="cfg-val-label"
                                    value={genderInputVal}
                                    onChange={handleGenderInputValChange}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                        },
                                        color: "white",
                                        "& .MuiSelect-icon": {
                                            // This targets the dropdown icon
                                            color: "white", // Change this to your desired color
                                        },
                                    }}
                                >
                                    <MenuItem value={"man"}>Man</MenuItem>
                                    <MenuItem value={"woman"}>Woman</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                marginTop: "1.7rem",
                            }}
                        >
                            <Typography
                                sx={{
                                    marginBottom: "0.4rem",
                                    fontFamily: "Inter",

                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Upload Photos {" "}<span style={{
                                    color: "#94A3B8"
                                }}>(4 photos minimum)</span>
                            </Typography>
                            <Box
                                onDrop={handleDrop}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={() => setDragging(true)}
                                onDragLeave={() => setDragging(false)}
                                style={{
                                    background: "#242E3D",
                                    border: "1px solid #334155",
                                    borderRadius: "8px",

                                    padding: "64px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderColor: dragging
                                        ? "#6363FF"
                                        : "#334155",
                                }}
                            >{imagePreviewUrls.length > 0 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        gap: "1rem",
                                        width: "100%",
                                        /* flexDirection: {
                                            xs: "col",
                                            md: "row",
                                        }, */
                                    }}
                                >
                                    {imagePreviewUrls.map((url, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                width: "100px",
                                                height: "100px",
                                                position: "relative",
                                                borderRadius: "1rem",
                                            }}
                                        >
                                            <img
                                                src={url}
                                                alt={`Preview ${index}`}
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                            <IconButton
                                                sx={{
                                                    background: "#fee2e2",
                                                    color: "#ef4444",
                                                    position: "absolute",
                                                    top: "-8px",
                                                    right: "-8px",
                                                    "&:hover": {
                                                        background:
                                                            "#ef4444",
                                                        color: "white",
                                                    },
                                                }}
                                                onClick={() =>
                                                    removeUploadedImage(
                                                        index
                                                    )
                                                }
                                            >
                                                <HiOutlineX size={20} />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                                <>
                                    <label htmlFor="file-input">
                                        <Button
                                            variant="contained"
                                            component="span"
                                            sx={{
                                                background: "#111827",
                                                color: "white",
                                                borderRadius: "7px",
                                                textTransform: "capitalize",
                                                padding: "12px 22px",
                                                marginTop: "1.3rem",
                                            }}
                                            disableElevation
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                }}
                                            >
                                                Upload Image
                                            </Typography>
                                        </Button>
                                    </label>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "7px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                            }}
                                        >
                                            or
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                            }}
                                        >
                                            Drag and drop an image
                                        </Typography>
                                    </Box>
                                </>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="file-input"
                                    onChange={handleFileChange}
                                />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                md: "row",
                            },
                            gap: "1rem",
                            alignItems: "center",
                            marginTop: "30px",
                        }}>
                            <Button
                                component="span"
                                onClick={handleHeadshotGenerateClick2}
                                sx={{
                                    background: "#6363FF",
                                    color: "white",
                                    borderRadius: "70px",
                                    textTransform: "capitalize",
                                    padding: "12px 27px",

                                    /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                                    "&:hover": {
                                        background: "#4E4EC8",
                                        color: "white",
                                    },
                                }}
                                disableElevation
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                    }}
                                >
                                    Generate Headshots
                                </Typography>
                                {isLoading && (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: "white",
                                            marginLeft: "0.7rem",
                                        }}
                                    />
                                )}
                            </Button>

                            <Tooltip title="Pro feature! Please Upgrade">

                                <Button
                                    component="span"

                                    sx={{
                                        background: "#334155",
                                        color: "#94A3B8",
                                        borderRadius: "70px",
                                        textTransform: "capitalize",
                                        padding: "12px 27px",

                                        /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                                        "&:hover": {
                                            background: "#334155",
                                            color: "#94A3B8",
                                        },
                                        /* pointerEvents: "none", */
                                        /* cursor: "not-allowed", */
                                    }}
                                    disableElevation
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "7px"
                                        }}
                                    >
                                        <span>Generate Albums</span>
                                        <img src="/assets/ProIcon.svg" style={{
                                            width: "44px"
                                        }} />
                                    </Typography>

                                </Button>
                            </Tooltip>
                        </Box>

                    </Box>
                    <Box
                        sx={{
                            width: { xs: "90%", md: "40%" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "24px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "32px" /* 133.333% */,
                                    letterSpacing: "-0.144px",
                                    textAlign: "center",
                                }}
                            >
                                Unlimited Headshots & Exclusive Albums with PRO!
                            </Typography>
                            <Box
                                sx={{
                                    width: "450px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    margin: "27px auto 0 auto",
                                }}
                            >
                                <img
                                    alt=""
                                    src="/assets/headshotGenExampleImage2.png"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                />
                            </Box>

                            <Button
                                component="span"
                                onClick={() => {
                                    navigate("/pricing");
                                }}
                                sx={{
                                    background: "#9333EA",
                                    /* background: "#A855F7", */
                                    color: "white",
                                    borderRadius: "70px",
                                    textTransform: "capitalize",
                                    padding: "12px 27px",
                                    margin: "30px auto 0 auto",
                                    /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                                    "&:hover": {
                                        background: "#A855F7",
                                        color: "white",
                                    },
                                    border: "2px solid #C084FC"
                                }}
                                disableElevation
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        textAlign: "center",
                                        display: "flex",
                                        gap: "0.5rem"
                                    }}
                                >
                                    <img src="/assets/upgradeSparkIcon.svg" style={{
                                        width: "24px"
                                    }} />
                                    <span>Upgrade to Premium</span>

                                </Typography>
                            </Button>
                        </Box>

                    </Box>
                </Box>
            )}

            {currentPage === 1 && (
                <FreeGenImages
                    generatedImageUrlArr={generatedImageUrlArr}
                    user={user}
                    resetStates={resetStates}
                    freeGenImagesLoading={freeGenImagesLoading}
                    newHeadshotsDocId={newHeadshotsDocId}
                />
            )}

            {/* {currentPage === 1 && (
                
            )} */}


        </Box>
    );
};

export default FreeVersionHeadshot;
