import React, { useState, useRef, useEffect } from "react";
import arrSvg from "./arrSvg.svg";
import { heroButtonsData } from "./heroButtonsData";

import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Slider,
    CircularProgress,
    Slide,
    Modal,
    Divider,
    Grid,
    Badge,
    Chip,
    Paper,
} from "@mui/material";
import {
    HiChevronLeft,
    HiChevronRight,
    HiOutlineExternalLink,
    HiOutlineClock,
    HiOutlineArrowSmLeft,
    HiOutlineChip,
    HiOutlineInformationCircle,
    HiOutlineFolder,
    HiOutlineX,
    HiChip,
    HiOutlineArrowSmRight,
    HiOutlinePhotograph,
    HiOutlineVideoCamera,
    HiOutlineViewGrid,
} from "react-icons/hi";
import ToolItemBox from "./ToolItemBox";
import CustomFaq from "./CustomFaq";

const OurToolsSection = () => {
    return (
        <Box
            sx={{
                /* height: "80vh", */
                marginTop: { xs: "5vh", md: "0" },
                /* marginBottom: "40vh", */
            }}
        >
            <Container
                sx={{
                    margin: "0 auto",
                    padding: "6rem 0",
                }}
            >
                <Typography
                    sx={{
                        /* fontSize: "1.8rem",
                        fontWeight: "bold", */
                        marginBottom: "1.6rem",
                        fontWeight: "800",
                        fontSize: { xs: "1.6rem", md: "2.4rem" },
                        lineHeight: { md: "2.6rem" },
                        textAlign: "center",
                        textAlign: "center",
                    }}
                >
                    Our Tools
                </Typography>
                {/* <Box
                    sx={{
                        display: "flex",
                        gap: "1.8rem",
                        flexWrap: "wrap",
                        margin: "1.5rem auto 0 auto",
                        width: "fit-content",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {heroButtonsData.map((item, index) => (
                    ))}
                </Box> */}
                <Grid container spacing={3} sx={{ padding: "1rem" }}>
                    {heroButtonsData.map((item, index) => (
                        <ToolItemBox item={item} key={index} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default OurToolsSection;
