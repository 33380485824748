import React, { useRef, useEffect } from "react";
import Layout from "../layout/Layout";
import Footer from "../footer/Footer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import { fetchPersonalGeneratedImages } from "../../firestore/dataHandler";
import { setConfig } from "../../features/configs/configSilce";
import { setSavedImages } from "../../features/image/savedImageSlice";
import { useDispatch } from "react-redux";
import LandingPage from "../LandingPage";
import { useNavigate, useLocation } from "react-router-dom";

const ArtGenerator = ({ user, credits }) => {
    const sectionRef = useRef(null);
    const location = useLocation();
    let locPromptText;
    if (location) {
        locPromptText = location?.state?.landingPrompt;
    }

    let promptFromTemplate = location.state?.prompt;
    if (promptFromTemplate) {
        promptFromTemplate = promptFromTemplate.replaceAll(/<\/?b>/g, "");
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading] = useAuthState(auth);

    const [authenticated, setAuthenticated] = React.useState(false);
    const [landingPrompt, setLandingPrompt] = React.useState("");

    React.useEffect(() => {
        if (!user) return;

        if (user.emailVerified) {
            setAuthenticated(true);
        }

        fetchPersonalGeneratedImages(user).then((res) => {
            // console.log("RESPONSE IS", res);
            // setGeneratedImage(res);
            let finalArray = [];
            res.forEach((each) => {
                let obj = {
                    ...each,
                    src: `https://storage.googleapis.com/code-translate-c9640.appspot.com/generations/${each.id}`,
                };
                finalArray.push(obj);
            });
            // console.log("RES BEFORE DISPATCH", finalArray);
            dispatch(setSavedImages(finalArray));
            // get unique folder names.
            let folders = [
                ...new Set(finalArray.map((item) => item.folderName)),
            ];
            // remove empty strings...
            folders = folders.filter((each) => each);
            // dispatch
            dispatch(
                setConfig({
                    name: "folders",
                    value: folders,
                })
            );
        });
    }, [user, dispatch]);

    useEffect(() => {
        // if (!user) return;
        //console.log(landingPrompt, "l PROMPT");
        setLandingPrompt(promptFromTemplate);
    }, [promptFromTemplate]);

    /*
    const handleTemplateClick = () => {
        navigate("/templates");
    };
    const handleGetStartedClick = () => {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handlePromptClick = (promptText) => {
        setLandingPrompt(promptText);
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
        console.log(promptText);
    };

    const handleNavigateToProducts = (productLink) => {
        navigate(productLink);
    };
    */

    return (
        <Layout footer={<Footer />}>
            <LandingPage
                user={user}
                loading={loading}
                authenticated={authenticated}
                credits={credits}
                landingPrompt={landingPrompt}
                // subscription={subscription}
            />
        </Layout>
    );
};

export default ArtGenerator;
