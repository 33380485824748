import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const OptionSelected = ({ optionItems, optionSelected, setOptionSelected }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    /* const optionItems = ["Type Url", "Upload Image"]; */

    const handleOptionSelect = (e) => {
        /* if (e.target.innerText === "Avatars") {
            navigate("/ai-photoshoots-pricing");
        } */
        console.log(e.target.innerText, "--optionSelected--");
        setOptionSelected(e.target.innerText);
    };

    return (
        <Box
            sx={{
                background:
                    theme.palette.mode === "light" ? "white" : "transparent",
                width: "fit-content",
                margin: "0 auto",
                border: "2px solid #D0F4FF",
                /* theme.palette.mode === "light"
                        ? "2px solid #D0F4FF"
                        : "2px solid #3F3C3C", */
                borderRadius: "30px",
                display: "flex",
                padding: "0.3rem",
                boxShadow:
                    theme.palette.mode === "light"
                        ? "0px 4px 15px #E8FAFF"
                        : "none",
            }}
        >
            {optionItems.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        background:
                            item === optionSelected ? "#D5F6FF" : "transparent",
                        /*  background:
                            item === optionSelected ? "#14AAFF" : "transparent", */
                        padding: "0.5rem 1rem",
                        borderRadius: "inherit",
                        cursor: "pointer",
                        color: item === optionSelected ? "#14AAFF" : "#7A92A5",
                        fontWeight: "500",
                        fontFamily: "Inter",
                    }}
                    onClick={handleOptionSelect}
                >
                    {item}
                </Box>
            ))}
        </Box>
    );
};

export default OptionSelected;
