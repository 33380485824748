import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainSerpSelectedImage: "",
  allDocuments: "",
  selectedDoc: "",
};

export const serpImageSlice = createSlice({
  name: "serpImage",
  initialState,
  reducers: {
    setMainSerpSelectedImage: (state, action) => {
      state.mainSerpSelectedImage = action.payload.mainSerpSelectedImage;
    },
    setAllPersonalSerpDocuments: (state, action) => {
      state.allDocuments = action.payload;
    },
    setSelectedDoc: (state, action) => {
      state.selectedDoc = action.payload;
    },
  },
});

export const {
  setMainSerpSelectedImage,
  setAllPersonalSerpDocuments,
  setSelectedDoc,
} = serpImageSlice.actions;
export default serpImageSlice.reducer;
