import {
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    updateDoc,
    where,
    increment,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseInit";
import { onAuth } from "./authHandlers";
const db = getFirestore(firebaseApp);

// query all templates
export const getAllTemplates = async () => {
    const querySnapshot = await getDocs(collection(db, "templates"));
    let temps = [];
    querySnapshot.forEach((doc) => {
        // console.log(doc.data());
        // TODO: Remove/Serialize Timestamp
        let data = doc.data();
        data.createdAt = data.createdAt.toDate();
        temps.push(data);
    });
    return temps;
};

// query all user generated public templates
export const getAllPublicTemplates = async () => {
    // const querySnapshot = await getDocs(collection(db, "customTemplates"));
    // let temps = [];
    // querySnapshot.forEach((doc) => {
    //     console.log(doc.data());
    //     // TODO: Remove/Serialize Timestamp
    //     temps.push(doc.data());
    // });
    // return temps;

    const queryParam = query(collection(db, "publicTemplates"));
    /* const queryParam = query(
        collection(db, "customTemplates"),
        where("public", "==", true)
    ); */
    const querySnapshot = await getDocs(queryParam);
    let docs = [];
    querySnapshot.forEach((doc) => {
        docs.push(doc.data());
    });

    return docs;
};

// query update a template by upvote and downvote
// const voteData = {
//     id: template.id,
//     upvote: liked,
//   };
export const updateTemplateByVote = async (voteData) => {
    const { id, upvote } = voteData;
    const q = query(collection(db, "customTemplates"), where("id", "==", id));

    var upvoteValue = 1;
    var downvoteValue = 1;
    var voterDetailsValue = [];

    const querySnapshot = await getDocs(q);
    let docs = [];

    onAuth(async (user) => {
        var updateData = {
            upvote: upvoteValue,
            voterDetails: [...voterDetailsValue, { email: user.email }],
        };
        try {
            querySnapshot.forEach(async (item) => {
                docs.push(item.data());
                const docId = item.id;
                const docRef = doc(db, "customTemplates", docId);

                if (upvote === true) {
                    updateData = {
                        upvote:
                            item.data().upvote >= 0
                                ? item.data().upvote + 1
                                : 1,
                        voterDetails: [
                            ...voterDetailsValue,
                            { email: user.email },
                        ],
                    };
                } else {
                    updateData = {
                        downvote:
                            item.data().downvote >= 0
                                ? item.data().downvote + 1
                                : 1,
                        voterDetails: [{ email: user.email }],
                    };
                }
                updateDoc(docRef, updateData);
            });
        } catch (error) {
            //   resolve({ status: "error" });
        }
    });

    console.log("single template docs:", docs);
    //   console.log("single template docs:", docs[0]);
    //   voterDetails: [{ email: "nahid@gmail.com" }]

    //   return docs[0];
};

export const storeToolsDetails = async (voteData) => {
    const { email } = voteData;
    console.log("Email: " + email);

    const upvote = false;
    const q = query(collection(db, "tools"), where("email", "==", email));

    var upvoteValue = 1;
    var downvoteValue = 1;
    var voterDetailsValue = [];

    const querySnapshot = await getDocs(q);
    let docs = [];

    onAuth(async (user) => {
        var updateData = {};
        try {
            querySnapshot.forEach(async (item) => {
                docs.push(item.data());
                const docId = item.id;
                const docRef = doc(db, "customTemplates", docId);

                if (upvote === true) {
                    updateData = {
                        upvote:
                            item.data().upvote >= 0
                                ? item.data().upvote + 1
                                : 1,
                        voterDetails: [
                            ...voterDetailsValue,
                            { email: user.email },
                        ],
                    };
                } else {
                    updateData = {
                        downvote:
                            item.data().downvote >= 0
                                ? item.data().downvote + 1
                                : 1,
                        voterDetails: [{ email: user.email }],
                    };
                }
                updateDoc(docRef, updateData);
            });
        } catch (error) {
            //   resolve({ status: "error" });
        }
    });

    console.log("single template docs:", docs);
    //   console.log("single template docs:", docs[0]);
    //   voterDetails: [{ email: "nahid@gmail.com" }]

    //   return docs[0];
};

// query a template by id
export const getTemplateById = async (id) => {
    const q = query(collection(db, "templates"), where("id", "==", id));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
        docs.push(doc.data());
    });
    return docs[0];
};

export const getPublicTemplateById = async (id) => {
    const q = query(collection(db, "publicTemplates"), where("id", "==", id));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
        docs.push(doc.data());
    });
    return docs[0];
};
// query a template by id
export const getCustomTemplateById = async (id) => {
    const q = query(collection(db, "customTemplates"), where("id", "==", id));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
        docs.push(doc.data());
    });
    return docs[0];
};

export const updateViewNumber = async (templateId, userEmail) => {
    const q = query(collection(db, "templates"), where("id", "==", templateId));

    const querySnapshot = await getDocs(q);
    const templateDoc = querySnapshot.docs[0];
    const templateData = templateDoc.data();
    console.log(templateData, "templateData");
    //console.log(templateData, "datao");

    if (templateDoc.exists()) {
        const templateRef = doc(db, "templates", templateDoc.id);
        await updateDoc(templateRef, { viewQty: increment(1) });

        /* if (templateData.hasOwnProperty("viewersDetails")) {
            // Check if userEmail already exists in viewersDetails
            const existingUser = templateData.viewersDetails.some(
                (user) => user.userEmail === userEmail
            );
            //console.log(!existingUser);
            if (!existingUser) {
                await updateDoc(templateRef, {
                    viewersDetails: [
                        ...templateData.viewersDetails,
                        { userEmail },
                    ],
                });
                try {
                    await updateDoc(templateRef, { viewQty: increment(1) });
                    console.log("viewQty incremented successfully!");
                } catch (error) {
                    console.error("Error incrementing viewQty:", error);
                }
                console.log("User added to viewersDetails");
            } else {
            }
        } else {
            // Create viewersDetails property with the current user email
            await updateDoc(templateRef, {
                viewersDetails: [{ userEmail }],
            });
            try {
                await updateDoc(templateRef, { viewQty: increment(1) });
                console.log("viewQty incremented successfully!");
            } catch (error) {
                console.error("Error incrementing viewQty:", error);
            }
            console.log("viewersDetails created with the current user");
        } */
    } else {
        console.log("Template not found");
    }
};
/* export const updateViewNumber = async (templateId) => {
    const q = query(collection(db, "templates"), where("id", "==", templateId));

    const querySnapshot = await getDocs(q);
    const templateDoc = querySnapshot.docs[0];

    if (templateDoc.exists()) {
        const templateRef = doc(db, "templates", templateDoc.id);

        try {
            await updateDoc(templateRef, { viewQty: increment(1) });
            console.log("viewQty incremented successfully!");
        } catch (error) {
            console.error("Error incrementing viewQty:", error);
        }
    } else {
        console.log("Template not found");
    }
}; */

export const updateUsedToCreateNumber = async (templateId) => {
    const q = query(collection(db, "templates"), where("id", "==", templateId));

    const querySnapshot = await getDocs(q);
    const templateDoc = querySnapshot.docs[0];

    if (templateDoc.exists()) {
        const templateRef = doc(db, "templates", templateDoc.id);

        try {
            await updateDoc(templateRef, { usedQty: increment(1) });
            console.log("Used Qty incremented successfully!");
        } catch (error) {
            console.error("Error incrementing Used Qty:", error);
        }
    } else {
        console.log("Template not found");
    }
};
