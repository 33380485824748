/**
 * Customize image generation parameters
 */
import {
  Box,
  Button,
  InputBase,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setModelConfig,
  setSteps,
} from "../../../features/modelConfig/modelConfigSlice";

const Input = styled(InputBase)(({ theme }) => ({
  // borderColor: "green",
  border: "1px solid lightgray",
  borderRadius: "4px",
  fontSize: "14px",
  lineHeight: "20px",
  // border: "white",
  // margin: "0 auto",
  // bottom: 0,
  // marginTop: theme.spacing(1),
  // ":hover": {
  //   borderColor: "white",
  // },
}));

export default function ModelSettings() {
  const modelConfig = useSelector((state) => state.modelConfig);
  const { cfgScale, seed, steps, sampler } = modelConfig;

  const dispatch = useDispatch();

  // const routingLoaded = useTypedSelector(state => state.routing.routingLoaded);

  console.log(
    "cfgScale, seed, steps, sampler --------------------:",
    cfgScale,
    seed,
    steps,
    sampler
  );

  // const [sliderValue, setSliderValue] = React.useState(cfgScale)

  const [samplerState, setSamplerState] = React.useState(sampler);

  const handleFieldValueChange = (e) => {
    e.preventDefault();

    const key = e.target.name;
    const value = e.target.value;
    console.log("value", value);

    if (key === "cfgScale" && value > 20) return;
    if (key === "steps" && value > 50) return;
    if (key === "seed" && value > 429496729) return;
    dispatch(
      setModelConfig({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleChange = (event) => {
    setSamplerState(event.target.value);
    dispatch(
      setModelConfig({
        ...modelConfig,
        sampler: event.target.value,
      })
    );
  };

  useEffect(() => {
    console.log("re-rendered component");
  }, [modelConfig, samplerState]);
  return (
    <Box>
      {/* {
                inputFields.map((field, i) => (
                    <Box key={i} sx={{ pt: 1 }}>
                        <Typography sx={{ fontSize: "14px", color: "gray" }}><b>{field.title}</b></Typography>
                        <Input
                            fullWidth
                            name={field.name}
                            value={getFieldValue(field.name)}
                            onChange={handleFieldValueChange}
                            sx={{ px: 1 }} />
                        <Typography variant='subtitle' sx={{ color: "gray" }}>{field.subtitle}</Typography>
                    </Box>
                ))
            } */}
      <Box sx={{ pt: 1 }}>
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          <b>Guidance Scale</b>
        </Typography>
        {/* <Input
                    fullWidth
                    type="number"
                    name="cfgScale"
                    value={cfgScale}
                    onChange={handleFieldValueChange}
                    /> */}
        <Box
          sx={{
            width: "70%",
            px: 2,
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Slider
            aria-label="Temperature"
            name="cfgScale"
            defaultValue={cfgScale}
            onChange={handleFieldValueChange}
            // onChange={(e) => console.log()}
            // getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={20}
          />
          <Button
            sx={{ border: "1px solid #666", padding: "5px", marginLeft: "5px" }}
          >
            {cfgScale}
          </Button>
        </Box>
        <Typography variant="subtitle" sx={{ color: "gray" }}>
          Any number between 1 and 20. The guidance scale adjusts how much the
          image looks closer to the prompt(description).
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          <b>Seed</b>
        </Typography>
        <Input
          fullWidth
          type="number"
          name="seed"
          value={seed}
          onChange={handleFieldValueChange}
          sx={{ px: 1 }}
        />
        <Typography variant="subtitle" sx={{ color: "gray" }}>
          Leave it blank to use random value. By setting seed value (any number)
          you can generate reproducible image. Seed can be any random number.
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography sx={{ fontSize: "14px", color: "gray" }}>
          <b>Steps</b>
        </Typography>
        <Input
          fullWidth
          type="number"
          name="steps"
          value={steps}
          onChange={handleFieldValueChange}
          sx={{ px: 1 }}
        />
        <Typography variant="subtitle" sx={{ color: "gray" }}></Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography sx={{ fontSize: "14px", color: "gray", mb: 1 }}>
          <b>Sampler</b>
        </Typography>
        {/* <Input
                    fullWidth
                    type='text'
                    name="steps"
                    value={sampler}
                    onChange={handleFieldValueChange}
                    sx={{ px: 1 }} /> */}

        <Select
          fullWidth
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={samplerState}
          // label="Age"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {/* <MenuItem value="k_dpmpp_2m">Ten</MenuItem> */}

          {[
            "ddim",
            "plms",
            "k_euler",
            "k_euler_ancestral",
            "k_heun",
            "k_dpm_2",
            "k_dpm_2_ancestral",
            "k_dpmpp_2s_ancestral",
            "k_lms",
            "k_dpmpp_2m",
          ].map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>

        <Typography variant="subtitle" sx={{ color: "gray" }}></Typography>
      </Box>
    </Box>
  );
}
