import { Suspense } from "react";
import Loader from "../Loader";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { HiOutlineSearch } from "react-icons/hi";

import { BiLike, BiDislike, BiUpvote, BiDownvote } from "react-icons/bi";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
    customTemplatesFetched,
    templatesFetched,
    templatesSortedSet,
} from "../../features/templates/templateSlice";
import {
    getAllPublicTemplates,
    getAllTemplates,
    updateTemplateByVote,
} from "../../firestore/templateHandler";
import Footer from "../footer/Footer";
import Layout from "../layout/Layout";
import "./styles.css";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { BsFillCaretUpFill } from "react-icons/bs";

import { styled } from "@mui/material/styles";
import { sortbyCreatedAt } from "../utils/sorting";
import TemplateCardLatest from "./TemplateCardLatest";
import { Container } from "@mui/system";
import TemplatesHeader from "./TemplatesHeader";
import PublicTemplateCardLatest from "./PublicTemplateCardLatest";
import TemplateCardLatestV2 from "./TemplateCardLatestV2";

const CardWrapper = styled(Box)(({ theme }) => ({
    transition: "all .2s ease-in-out",
    // border: "2px solid red",
    ":hover": {
        transform: "scale(1.03)",
    },
}));

// returns an (a)array of (b)arrays of template object
// each (b)arrays represents a column
const processImageData = (images) => {
    if (!images || images.length === 0) return [];
    const tempImages = [...images];
    const columnsNum = 4;
    const itemsPerCol = parseInt(tempImages.length / columnsNum);
    const data = [];
    for (let i = 0; i < columnsNum; i++) {
        data.push(tempImages.splice(0, itemsPerCol));
        if (i === columnsNum - 1 && tempImages.length !== 0) {
            for (let j = 0; j < tempImages.length; j++) {
                data[j].push(tempImages[j]);
            }
        }
    }
    return data;
};

const TemplateCard = ({ template }) => {
    return (
        <CardWrapper>
            <Card sx={{ borderRadius: "8px" }}>
                <img
                    src={template.url}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                />
                <div className="card_contents">
                    <Typography sx={{ px: 1 }}>
                        <strong>{template.category}</strong>
                    </Typography>
                </div>
            </Card>
        </CardWrapper>
    );
};

const CustomTemplateCard = ({ template }) => {
    const navigate = useNavigate();
    const [liked, setLiked] = React.useState(null);
    const handleRemixClick = (prompt) => {
        console.log(template);
        let mainPrompt = template.mainPrompt;
        navigate("../", { state: { prompt: mainPrompt } });
    };

    const handleOpenClick = (template) => {
        navigate(`/stocks/${template.id}`);
    };

    const handleReaction = (e, state) => {
        e.preventDefault();
        // console.log("Template ID: ", template.id);
        // console.log("Template ID: ", template);

        // console.log(generatedImage);
        if (state === "liked") {
            setLiked(true);
            mixpanel.track("like image - AI Avatar");

            setTimeout(() => {
                const voteData = {
                    id: template.id,
                    upvote: state === "liked" && true,
                };

                console.log("voteData", voteData);
                updateTemplateByVote(voteData);
            }, 1000);
        } else {
            setLiked(false);
            mixpanel.track("dislike image - AI Avatar");

            const voteData = {
                id: template.id,
                upvote: state === "liked" && false,
            };

            console.log("voteData", voteData);
            updateTemplateByVote(voteData);
        }
    };

    // console.log("templatesss:", template);

    return (
        <div className="template_card">
            {/* <Card sx={{ borderRadius: "8px", p: 2 }}> */}
            {/* <img
        src={template.url}
        style={{ width: "100%", borderRadius: "8px", height: "auto" }}
        alt=""
      /> */}
            <div class="col s4">
                <div class="card ">
                    <div class="card-image waves-effect waves-block waves-light">
                        <div class="grid ">
                            <figure class="effect-ming">
                                <img
                                    style={{
                                        borderRadius: "10px",
                                    }}
                                    src={template.url}
                                    alt="img08"
                                />
                                <figcaption>
                                    <h2>
                                        {/* <strong>{template.category}</strong> */}
                                    </h2>
                                    <p>{template?.mainPrompt}</p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>

                    <p style={{ visibility: "hidden" }}>s</p>
                    <h2
                        style={{
                            fontFamily: "Inter, Roboto, Arial, sans-serif",
                        }}
                    >
                        <strong style={{ padding: "0px" }}>
                            {template.category}
                        </strong>
                    </h2>
                    <Box sx={{ my: 1 }}>
                        {template?.variants.length &&
                            template?.variants.map((each) => (
                                <>
                                    <EachVariantTwo
                                        variant={each}
                                    ></EachVariantTwo>
                                </>
                            ))}
                    </Box>

                    <Box style={cardStyle1}>
                        {/* <FiDownload /> */}
                        <IconButton
                            style={iconStyle}
                            title="Upvote"
                            onClick={(e) => {
                                setLiked(true);
                                handleReaction(e, "liked");
                            }}
                            color={liked ? "primary" : "inherit"}
                        >
                            <BsFillCaretUpFill />
                            <small style={{ fontSize: "18px" }}>
                                {template.upvote}
                            </small>
                        </IconButton>
                        <IconButton
                            style={iconStyle}
                            title="Downvote"
                            onClick={(e) => handleReaction(e, "disliked")}
                            color={
                                liked !== null && !liked ? "primary" : "inherit"
                            }
                        >
                            <BiDownvote />
                        </IconButton>
                    </Box>
                    {/* <div class="card-content">
            <span class="card-title activator grey-text text-darken-4">
              JULIA<i class="material-icons right">more_vert</i>
            </span>
            <p>
              <a class="activator" href="#">
                Read More about Julia →
              </a>
            </p>
          </div>
          <div class="card-reveal">
            <span class="card-title grey-text text-darken-4">
              Card Title<i class="material-icons right">close</i>
            </span>
            <p>
              Here is some more information about this product that is only
              revealed once clicked on.
            </p>
          </div> */}
                </div>
            </div>
            <div className="card_contents">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {/* <Typography>
            <strong>{template.category}</strong>
          </Typography> */}
                </Box>

                {/* <Typography>{template.mainPrompt.substring(0, 200)}</Typography> */}

                <Box></Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        /* gap: "10px", */
                        padding: "0.5rem 0",
                    }}
                >
                    {/* remxi button.. */}
                    <Button
                        variant="outlined"
                        onClick={() => {
                            mixpanel.track("Remix", {
                                Description: "Public Template - Remix",
                            });
                            handleRemixClick(template);
                        }}
                        /* className="forceRadious" */
                        sx={{
                            background: "#6363FF",
                            color: "white !important",
                            borderRadius: "100px",
                            border: "2px solid #6363FF",

                            "&:hover": {
                                background: "#5050D6",
                                transition: "all 0.12s ease-in",
                                border: "2px solid #6363FF",
                            },
                        }}
                        /* style={buttonStyle} */
                    >
                        <EditIcon
                            sx={{
                                fontSize: "16px",
                                marginRight: ".8rem",
                                color: "white",
                            }}
                        />
                        Remix
                    </Button>

                    {template.stock_image ? (
                        <Button
                            onClick={() => handleOpenClick(template)}
                            variant="outlined"
                            sx={{
                                background: "transparent",
                                color: "#6363FF !important",
                                borderRadius: "100px",
                                border: "2px solid #6363FF",

                                "&:hover": {
                                    background: "#6464F4",
                                    transition: "all 0.12s ease-in",
                                    border: "2px solid #6363FF",
                                    color: "white",
                                },
                            }}
                        >
                            Open
                        </Button>
                    ) : null}
                </Box>
                {/* <Box style={cardStyle1}>
          
            <IconButton
          
              title="like"
              onClick={(e) => handleReaction(e, "liked")}
              color={liked ? "primary" : "inherit"}
            >
              <BiUpvote />
            </IconButton>
            <IconButton
            
              title="dislike"
              onClick={(e) => handleReaction(e, "disliked")}
              color={liked !== null && !liked ? "primary" : "inherit"}
            >
              <BiDownvote />
            </IconButton>
          </Box> */}
            </div>
        </div>
    );
};

const buttonStyle = {
    // border : 'none',
    borderRadius: "20px !important",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    display: "flex",
    alignItems: "center",
    // padding: '.5rem 1rem',
    cursor: "pointer",
    color: "#1976D2",
    marginTop: "1rem",
    width: "50%",
};

const EachVariant = ({ variant }) => {
    //console.log("Variant", variant);
    return (
        // {each.parent.value.map((eachVariant)=>(<>
        //     <Typography>{eachVariant}</Typography>
        // </>))}
        <>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {variant.value.map((eachVariant) => (
                    <Typography
                        style={
                            variant.selected === eachVariant
                                ? { background: "#B6B6FF" }
                                : null
                        }
                        sx={{
                            border: "1px solid lightgray",
                            padding: "2px 10px!important",
                            fontSize: "13px",
                            borderRadius: "25px",
                            marginLeft: "2px",
                            marginRight: "5px",
                            marginTop: "3px",
                            color: "#6363FF",
                            border: "1.5px solid #B6B6FF",
                            fontFamily: "Inter, Roboto, Arial, sans-serif",
                        }}
                    >
                        key: {eachVariant}
                    </Typography>
                ))}
            </Box>
        </>
    );
};

const EachVariantTwo = ({ variant }) => {
    //console.log("Variant", variant);
    return (
        // {each.parent.value.map((eachVariant)=>(<>
        //     <Typography>{eachVariant}</Typography>
        // </>))}
        <>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                }}
            >
                {variant.value.map((eachVariant) => (
                    <Chip
                        style={
                            variant.selected === eachVariant
                                ? { background: "#E4E4FF" }
                                : { background: "transparent" }
                        }
                        sx={{
                            border: "1px solid lightgray",
                            padding: "2px 10px!important",
                            fontSize: "13px",
                            borderRadius: "25px",
                            marginLeft: "2px",
                            marginRight: "5px",
                            marginTop: "3px",
                            color: "#6363FF",
                            border: "1.5px solid #B6B6FF",
                            fontFamily: "Inter, Roboto, Arial, sans-serif",
                        }}
                        label={`key: ${eachVariant}`}
                    ></Chip>
                ))}
            </Box>
        </>
    );
};

const FilterByCategory = ({ categories, onCategoryChange }) => {
    const [selectedCategory, setSelectedCategory] = useState("");

    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        onCategoryChange(category);
    };

    return (
        <Box sx={{ minWidth: 200, maxWidth: "230px", margin: "0 auto" }}>
            <FormControl fullWidth>
                <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    /* label="Filter by Category" */
                    size="small"
                >
                    <MenuItem value="">All</MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category} value={category}>
                            {category}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default function Templates({ user }) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let templates = useSelector((state) => state.templates.templates);
    const [showSortOptions, setShowSortOptions] = useState(true);
    const [displayableItems, setDisplayableItems] = useState(templates);
    const [renderedMoreThanOnce, setRenderedMoreThanOnce] = useState(false);
    const customTemplates = useSelector(
        (state) => state.templates.customTemplates
    );
    const [btnstate, setBtnstate] = React.useState("default");
    const [showStockImage, setShowStockImage] = React.useState(false);
    const [customTemplatesContainer, setCustomTemplatesContainer] =
        React.useState();
    const [mainData, setMainData] = React.useState();
    console.log(templates, "Templates....... ALL");
    // const localTemplates = processImageData(templates);
    // const publicTemplates = processImageData(customTemplates);

    const categories = ["Category 1", "Category 2", "Category 3"];

    const handleCustomTemplateChange = (cTemp) => {
        console.log(cTemp, "ctemp from parent");
        setCustomTemplatesContainer(cTemp);
    };

    const optionItems = ["Templates", "Public Templates"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const handleCategoryChange = (category) => {
        console.log("Selected category:", category);
    };

    React.useEffect(() => {
        // make four 4 arrays from templates
        // if(publicTemplates){
        //     setCustomTemplatesContainer(publicTemplates);
        // }

        // get the default templates...
        getAllTemplates().then((data) => {
            data.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            dispatch(
                templatesFetched({
                    templates: data.sort((a, b) => b.usedQty - a.usedQty),
                })
            );
        });

        setDisplayableItems(templates);

        // get the user generated template....
        getAllPublicTemplates().then((customData) => {
            console.log("customData", customData);

            let sortedProducts = customData.sort(
                (a, b) =>
                    // (a.upvote < b.upvote ? 1 : b.upvot < a.upvote ? -1 : 0)
                    b.upvote - a.upvote
            );
            console.log("sortedProducts", sortedProducts);

            // store to redux....
            dispatch(
                customTemplatesFetched({
                    customTemplates: customData,
                })
            );

            console.log("AFTER GETTING RESULT", customTemplates);
            // setMainData(customTemplates);
            setMainData(customData);

            let publicTemplates1 = processImageData(customData);
            console.log("WHAT WE GET AFTER CONVERSION", publicTemplates1);

            setCustomTemplatesContainer(customData);
        });
    }, []);

    //   useEffect(() => {
    // let sortedProducts = products.sort((p1, p2) =>
    //   p1.price < p2.price ? 1 : p1.price > p2.price ? -1 : 0
    // );
    //   }, []);

    const handleStockimageSelection = (event) => {
        console.log(event.target.checked, "CHECKED STATES");
        console.log(mainData, "Main Data Status", mainData);
        console.log(customTemplatesContainer);
        if (!mainData) {
            return;
        }
        if (event.target.checked) {
            setShowStockImage(true);
            let selectedData = mainData.filter(
                (each) => each.stock_image === true
            );
            console.log("selectedData", selectedData);
            let publicTemplates1 = processImageData(selectedData);
            setCustomTemplatesContainer(selectedData);
        } else {
            setShowStockImage(false);
            let publicTemplates1 = processImageData(mainData);
            setCustomTemplatesContainer(mainData);
        }
    };

    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const makePublicTemplateActive =
            location?.state?.makePublicTemplateActive;

        const singleKey = queryParams.get("tab");
        if (!singleKey) return;

        if (singleKey === "public") {
            //return setBtnstate("public");
            return setOptionSelected("Public Templates");
        }

        if (makePublicTemplateActive === "Yes") {
            return setOptionSelected("Public Templates");
        }
    }, []);

    const [searchTermDefault, setSearchTermDefault] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchInputChange = (e) => {
        if (optionSelected === "Templates") {
            /* if (btnstate === "default") { */
            setSearchTermDefault(e.target.value.toLowerCase());
        } else {
            setSearchTermDefault(e.target.value.toLowerCase());
        }

        /* setDisplayableItems(
            templates.filter((item) =>
                item.category
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        ); */

        /* setCustomTemplatesContainer(
            customTemplatesContainer.filter((item) =>
                item.category
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        ); */
    };

    const handleRedirect = (templateId) => {
        navigate(`/templates/${templateId}`);
    };

    return (
        <Layout footer={<Footer />}>
            {/* <ImageGal /> */}
            <br />
            <TemplatesHeader
                handleSearchInputChange={handleSearchInputChange}
                optionItems={optionItems}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
                handleStockimageSelection={handleStockimageSelection}
                handleCustomTemplateChange={handleCustomTemplateChange}
                customTemplatesContainer={customTemplatesContainer}
                setRenderedMoreThanOnce={setRenderedMoreThanOnce}
                user={user}
                showSortOptions={showSortOptions}
                setShowSortOptions={setShowSortOptions}
                /* handleSortByChange={handleSortByChange} */
            />
            {/* <Box
                sx={{
                    background: "transparent",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marign: "0 auto",

                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <TextField
                    sx={{
                        margin: "0.4rem auto 1rem auto",
                        borderRadius: "0.3rem",
                    }}
                    size="small"
                    placeholder="Search"
                    onChange={handleSearchInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HiOutlineSearch />
                            </InputAdornment>
                        ),
                    }}
                />
                <FilterByCategory
                    categories={categories}
                    onCategoryChange={handleCategoryChange}
                />
            </Box>

            <Container
                sx={{
                    padding: "0",
                    padding: { xs: "0.7rem 0.5rem", md: "0.8rem 0" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        mx: "auto",
                        justifyContent: "space-between",
                        // border : '1px solid',
                        width: "90%",
                        flexDirection: { xs: "column", md: "row" },
                    }}
                    style={{
                        gap: window.innerWidth < 768 ? "1rem" : "0",
                    }}
                >
                    <Box
                        style={{
                            flexBasis: "35%",
                            // border : '1px solid'
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                textTransform: "capitalize",
                                margin: "0 auto",
                            }}
                            onClick={() => navigate("/")}
                        >
                            Generation Dashboard
                        </Button>
                    </Box>

                    <Box
                        style={{
                            flexBasic: "30%",
                            // border : '1px solid'
                        }}
                    >
                        <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                        >
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    minWidth: {
                                        xs: "fit-content",
                                        md: "150px !important",
                                    },
                                }}
                                onClick={() => setBtnstate("default")}
                                variant={
                                    btnstate === "default"
                                        ? "contained"
                                        : "outlined"
                                }
                            >
                                Templates
                            </Button>

                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    minWidth: "150px",
                                }}
                                onClick={() => {
                                    mixpanel.track("Public Template", {
                                        Description:
                                            "User Visit public Template",
                                    });
                                    setBtnstate("public");
                                }}
                                variant={
                                    btnstate === "public"
                                        ? "contained"
                                        : "outlined"
                                }
                            >
                                Public Templates
                            </Button>
                        </ButtonGroup>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",

                            alignItems: "center",
                            flexBasis: { xs: "100%", md: "35%" },
                            textAlign: { xs: "right", md: "center" },
                            // border : '1px solid',
                            justifyContent: { xs: "center", md: "flex-end" },
                        }}
                    >
                        {btnstate !== "default" ? (
                            <FormGroup
                                sx={
                                    {
                                        // position :'absolute',
                                        // left :'10px'
                                    }
                                }
                            >
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={<Switch />}
                                    onChange={handleStockimageSelection}
                                    label="Stock Images"
                                />
                            </FormGroup>
                        ) : null}

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                                navigate("../customTemplate", { replace: true })
                            }
                            sx={{
                                // position : 'absolute',
                                // right : "2rem",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                marginLeft: "1rem",
                            }}
                        >
                            + Add Custom Template
                        </Button>
                    </Box>
                </Box>
            </Container> */}

            {/* switch the tab respect to `btnstate` useState */}
            {optionSelected === "Templates" ? (
                /* {btnstate === "default" ? ( */
                <>
                    <Suspense fallback={<Loader />}>
                        <Container
                            sx={{
                                padding: "0",
                                padding: {
                                    xs: "0.7rem 0.5rem",
                                    md: "0.8rem 0",
                                },
                            }}
                        >
                            <Grid container spacing={4}>
                                {templates
                                    .filter((item) =>
                                        item.category
                                            .toLowerCase()
                                            .includes(searchTermDefault)
                                    )
                                    .map((template, j) => (
                                        <Grid
                                            item
                                            key={j}
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                        >
                                            <Box
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <TemplateCardLatestV2
                                                    template={template}
                                                    key={j}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>
                    </Suspense>
                </>
            ) : (
                <>
                    <Suspense fallback={<Loader />}>
                        <Container
                            sx={{
                                padding: "0",
                                padding: {
                                    xs: "0.7rem 0.5rem",
                                    md: "0.8rem 0",
                                },
                            }}
                        >
                            <Grid container spacing={4}>
                                {customTemplatesContainer
                                    ?.filter((item) =>
                                        item.category
                                            .toLowerCase()
                                            .includes(searchTermDefault)
                                    )
                                    .map((template, j) => (
                                        <Grid
                                            item
                                            key={j}
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                        >
                                            <Box
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <PublicTemplateCardLatest
                                                    template={template}
                                                    key={j}
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>
                    </Suspense>
                </>
            )}
        </Layout>
    );
}

//prev public templates
{
    /* <div className="image_container_row">
                        

                        <Container
                            sx={{
                                padding: "0",
                                padding: {
                                    xs: "0.7rem 0.5rem",
                                    md: "0.8rem 0",
                                },
                            }}
                        >
                            <Grid container spacing={4}>
                                {customTemplatesContainer
                                    ?.filter((item) =>
                                        item.category
                                            .toLowerCase()
                                            .includes(searchTerm)
                                    )
                                    .map((template, j) => (
                                        <Grid
                                            item
                                            key={j}
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                        >
                                            <CustomTemplateCard
                                                template={template}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>

                    </div> */
}

const cardStyle1 = {
    borderRadius: "5px",
    background: "#rgb(0 0 0 / 0%)",
    width: "100px",

    // margin: "20px",
    //   color: "#000",

    display: "flex",
    justifyContent: "space-between",
};
const iconStyle = {
    marginTop: "5px",
    border: "1px solid #e6e6e6",
    display: "flex",
    flexDirection: "column",
    width: "50px",
    marginLeft: "5px",
    borderRadius: "5px",
};
