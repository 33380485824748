import React, { useState } from "react";
import {
    Typography,
    Box,
    Button,
    Popover,
    TextField,
    Chip,
} from "@mui/material";
import {
    HiOutlineChip,
    HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { useTheme } from "@mui/material/styles";
// import { useDispatch } from "react-redux";

const InstructInputBox = ({
    productData,
    handlesubmit,
    textAreaValue,
    setTextAreaValue,
}) => {
    const theme = useTheme();

    const [tipsPopupOpen, setTipsPopupOpen] = useState(false);

    // const [selectInputValue, setSelectedInputValue] = useState("");

    /* const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);

        dispatch(
            changeInputs({
                text: e.target.value,
                num: Number(selectInputValue),
            })
        );
    }; */

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };

    const handleTipsPopupOpen = () => {
        setTipsPopupOpen(true);
    };
    const handleTipsPopupClose = () => {
        setTipsPopupOpen(false);
    };

    return (
        <Box
            sx={{
                width: { xs: "100%", lg: "30%" },
                display: "flex",
                flexDirection: "column",
            }}
            style={{ gap: "1rem" }}
        >
            <Box>
                <React.Fragment>
                    {
                        <Chip
                            label="Tips"
                            sx={{ fontSize: "0.9rem", cursor: "pointer" }}
                            variant="outlined"
                            color="primary"
                            icon={<HiOutlineQuestionMarkCircle size={20} />}
                            onClick={handleTipsPopupOpen}
                        />
                    }
                    <Popover
                        open={Boolean(tipsPopupOpen)}
                        anchorEl={tipsPopupOpen}
                        onClose={handleTipsPopupClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            elevation: 1,
                            sx: {
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? "white"
                                        : "#3F3C3C",
                                display: "flex",
                                flexDirection: "column",
                                p: "0.7rem",
                                borderRadius: "0.7rem",
                                border: "1.5px solid #D6D2DA",
                                width: "400px",
                            },
                        }}
                    >
                        <Typography>
                            <b>Pro tips: </b>
                            {productData.productInputBox.tips}
                        </Typography>
                    </Popover>
                </React.Fragment>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        fontSize: { xs: "1rem", lg: "1.2rem" },
                    }}
                >
                    Generate {productData.porductTitle}
                </Typography>
            </Box>
            <form
                onSubmit={(e) => handlesubmit(e)}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
                /* ref={formRef} */
            >
                {productData.productInputBox.primaryInput.isActive && (
                    <Box>
                        <TextField
                            placeholder={
                                productData.productInputBox.primaryInput
                                    .placeHolderText
                            }
                            label={
                                productData.productInputBox.primaryInput
                                    .labelText
                            }
                            multiline
                            rows={3}
                            required
                            value={textAreaValue}
                            onChange={handleTextAreaChange}
                            sx={{
                                width: "100%",
                                border: "none",
                                outline: "none",
                                /* "& textarea": {
                                resize: "vertical",
                                maxHeight: "150px",
                            }, */
                            }}
                        />
                    </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="submit"
                        sx={{
                            py: "0.5rem",
                            px: "1.5rem",
                            width: "fit-content",
                            /*  height: "2.5rem", */
                            borderRadius: "2rem",
                            background:
                                "linear-gradient(25deg, #6363FF 10%, #281142 90%)",
                            color: "white",
                            fontSize: "0.9rem",
                            transition: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "bold",
                            border: "1.6px solid white",
                            boxShadow: "none",
                            /* margin: "0 auto", */

                            "&:hover": {
                                background:
                                    "linear-gradient(85deg, #6363FF 10%, #281142 90%)",
                                transition: "all 0.3s ease-in",
                                /* boxShadow: " 1px 7px 400px -2px black", */
                            },
                        }}
                        style={{
                            gap: "0.3rem",
                        }}
                        /* onClick={(e) => handleSubmit(e)} */
                    >
                        <>
                            <Typography sx={{ fontSize: "1rem" }}>
                                Submit
                            </Typography>
                            <span style={{ marginTop: "3px" }}>
                                <HiOutlineChip size={20} />
                            </span>
                        </>
                        {/* {isLoading ? (
                            <Typography sx={{ fontSize: "1rem" }}>
                                Generating Image ...
                            </Typography>
                        ) : (
                            <>
                                <Typography sx={{ fontSize: "1rem" }}>
                                    Generate
                                </Typography>
                                <span style={{ marginTop: "3px" }}>
                                    <HiOutlineChip size={20} />
                                </span>
                            </>
                        )} */}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default InstructInputBox;
