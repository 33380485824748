import React, { useEffect, useState } from "react";
import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import { HiOutlineDuplicate, HiOutlineCloudDownload } from "react-icons/hi";

import { firebaseApp } from "../../firebaseInit";
import { getFirestore, onSnapshot, getDoc, doc } from "firebase/firestore";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { saveAs } from "file-saver";
import VideoPlayerSm from "./VideoPlayerSm";

const VideoCard2 = (
    item,
    index,
    videoRefs,
    theme,
    handleMouseEnter,
    handleMouseLeave
) => {
    const db = getFirestore(firebaseApp);

    return (
        <div>
            <VideoPlayerSm generatedVideoSrc={item.item} />
        </div>
    );
};

export default VideoCard2;
