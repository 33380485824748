import axios from "axios";
import {
    storeToolsDetails,
    toolsDetailsbyCat,
    uploadToStorage,
    uploadToStorage2,
} from "../../firestore/storageHandler";
import { fetchLexicalData } from "../../axios/fetchdata";
import { v4 as uuidv4 } from "uuid";
import {
    ref,
    uploadString,
    getDownloadURL,
    getStorage,
} from "firebase/storage";
import { fetchImageBlob } from "../../firebaseInit";

export const genImgApiFunc = async (data) => {
    const response = await axios.post(
        "https://us-central1-code-translate-c9640.cloudfunctions.net/OpenAiImageGeneration",
        {
            data: {
                prompt: data.prompt,
                rn: data.dimention,
            },
        }
    );
    //console.log(response);
    let imageUrl = response.data.result;
    //console.log(imageUrl, " Image Url ");

    // Cloud function url to fetch raw image data
    const imageFetchUrl =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/fetch_image";

    // Alternative Approach: fetch image with axios
    const blobResponse = await axios.post(
        imageFetchUrl,
        { imageUrl },
        {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "blob",
        }
    );
    //console.log(blobResponse, " blobResponse ");

    const blob = blobResponse.data;

    try {
        const respponseFirebaseUrl = await uploadToStorage({
            file: blob,
            uid: data.uid,
        });
        //console.log(respponseFirebaseUrl, "Firebase response url");
        return { ...respponseFirebaseUrl, ...data };
    } catch (error) {
        console.log(error, "Firebase error - image");
    }
};

//
export const genSocailMediaContent = async (data) => {
    const firstApiUrl =
        "https://us-central1-aiseo-official.cloudfunctions.net/newApiFree";
    let response;
    try {
        response = await axios.post(firstApiUrl, {
            type: "chatOpenAI",
            data: {
                prompt: data.socialTitlePrompt,
                system: data.systemText,
                max_length: 400,
            },
            cost: 0,
        });
    } catch (err) {
        console.log(err);
    }

    const getPromptForGeneratingImage = (sourceString) => {
        const splitArray = sourceString.split("Prompt:");
        if (splitArray.length > 1) {
            return splitArray[splitArray.length - 1].trim();
        }
        return "";
    };

    const getSanitizedPostTitle = (sourceString) => {
        const splitArray = sourceString.split("Prompt:");
        if (splitArray.length > 1) {
            return splitArray[0].replace(/\n/g, "\n\r");
        }
        return "";
    };
    let promptForImage = getPromptForGeneratingImage(response.data.data);
    let formattedText = getSanitizedPostTitle(response.data.data);

    //img generation satrts
    let mainStr =
        "{Object}, flat style illustration for business ideas, flat design vector, industrial, light and magical, high resolution, engineering/ construction and design, colored cartoon style, light indigo and light gold, cad( computer aided design) , white background --ar 3:2 --v 5";

    const updatedPromptForImage = mainStr.replace("{object}", promptForImage);
    const secondApiUrl =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/OpenAiImageGeneration";

    const response2 = await axios.post(secondApiUrl, {
        data: {
            prompt: updatedPromptForImage,
            rn: 1,
        },
    });
    //console.log(response);
    let imageUrl = response2.data.result;
    //console.log(imageUrl, " Image Url ");

    // Cloud function url to fetch raw image data
    const imageFetchUrl =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/fetch_image";

    // Alternative Approach: fetch image with axios
    const blobResponse = await axios.post(
        imageFetchUrl,
        { imageUrl },
        {
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "blob",
        }
    );
    //console.log(blobResponse, " blobResponse ");

    const blob = blobResponse.data;
    try {
        const respponseFirebaseUrl = await uploadToStorage({
            file: blob,
            uid: data.uid,
        });
        //console.log(respponseFirebaseUrl, "Firebase response url");
        console.log({
            ...respponseFirebaseUrl,
            ...data,
            postCaption: formattedText,
        });
        return { ...respponseFirebaseUrl, ...data, postCaption: formattedText };
    } catch (error) {
        console.log(error, "Firebase error - image");
    }
};

export const genImgApiFuncTwo = async (inputData) => {
    //preprocess seed and cfgscale
    inputData.seed = parseInt(inputData.seed);
    inputData.cfgScale = parseInt(inputData.cfgScale);
    if (!inputData.seed) inputData.seed = Math.ceil(Math.random() * 100000000);
    if (!inputData.cfgScale) inputData.cfgScale = 7.5;
    if (!inputData.steps) inputData.steps = 30;

    const data = {
        prompt: inputData.text,
        width: inputData.width,
        height: inputData.height,
        cfg_scale: inputData.cfgScale,
        seed: inputData.seed,
        image_size: inputData.size,
        steps: inputData.steps,
        model_name: inputData.modelName,
        model_version: inputData.modelVersion,
        // provider: "replicate",
        provider: inputData.provider,
        // provider: providers[2],
    };
    const config = {
        headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.post(
        "https://us-central1-code-translate-c9640.cloudfunctions.net/OpenAiImageGeneration",
        data,
        config
    );

    return { ...response.data, ...inputData };
};

export const getHistoryIamges = async (data) => {
    toolsDetailsbyCat({
        email: data.userEmail,
        category: data.activeTool,
    }).then((res) => {
        return res?.generatedImage;
    });
};
