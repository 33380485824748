import React from "react";
import indigoCheckCircleIcon from "../../imgs/indigoCheckCircleIcon.svg";

import { Box, Button, Typography } from "@mui/material";

const AiAvatarsPricingCard = ({
    item,
    currentPlan,
    cardsSelected,
    setCardsSelected,
    index,
}) => {
    const handleSelectChange = () => {
        let newArr = [...cardsSelected];
        newArr.forEach((item2, index2) => {
            if (index2 === index) {
                newArr[index2] = true;
            } else {
                newArr[index2] = false;
            }
        });
        console.log(newArr, "--newArr--");

        setCardsSelected(newArr);
    };

    return (
        <Box
            sx={{
                padding: "1.4rem",
                borderRadius: "0.8rem",
                border:
                    cardsSelected[index] === true
                        ? "3px solid #6366F1"
                        : "3px solid rgba(128, 128, 128, 0.09)",
                width: "230px",
                boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
            }}
        >
            <Box
                sx={{
                    background: "#DCDDFF",
                    color: "#6366F1",
                    fontFamily: "Inter",
                    fontSize: "0.875rem",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    textTransform: "capitalize",
                    padding: "0.4rem 0.65rem",
                    borderRadius: "1rem",
                    width: "fit-content",
                    opacity: currentPlan !== item.title ? "0" : "1",
                }}
            >
                Current Plan
            </Box>
            <Box sx={{ margin: "1.5rem 0" }}>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1.3rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        textTransform: "capitalize",
                    }}
                >
                    {item.title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textTransform: "capitalize",
                    }}
                >
                    {item.desc}
                </Typography>
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1.3rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        textTransform: "capitalize",
                    }}
                >
                    ${item.price}.00
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textTransform: "capitalize",
                    }}
                >
                    per month
                </Typography>
            </Box>

            <Button
                sx={{
                    background: "white",
                    border: "1.6px solid rgba(32, 34, 36, 0.4)",
                    width: "100%",
                    margin: "2rem 0 0 0",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.4rem",
                    padding: "0.6rem auto",
                }}
                onClick={() => handleSelectChange()}
            >
                {cardsSelected[index] === true ? (
                    <img
                        alt=""
                        src={indigoCheckCircleIcon}
                        style={{
                            width: "1rem",
                        }}
                    />
                ) : null}{" "}
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "1rem",
                        fontStyle: "normal",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: "#6366F1",
                    }}
                >
                    {cardsSelected[index] === true ? "Selected" : "Select"}
                </Typography>
            </Button>
        </Box>
    );
};

export default AiAvatarsPricingCard;
