import React, { useState } from "react";

import { Box, Button, Typography, Slide } from "@mui/material";

import photoshootOnboardingImg1 from "../../../imgs/photoshootOnboardingImg1.svg";
import photoshootOnboardingImg2 from "../../../imgs/photoshootOnboardingImg2.svg";
import photoshootOnboardingImg3 from "../../../imgs/photoshootOnboardingImg3.svg";
import photoshootOnboardingImg4 from "../../../imgs/photoshootOnboardingImg4.svg";
import photoshootOnboardingImg5 from "../../../imgs/photoshootOnboardingImg5.svg";
import { useTheme } from "@mui/material/styles";
import { useAPI } from "../../../StateProvider";

const onboardingData = [
    {
        title: "Welcome to AI Photoshoots!",
        desc: "Crafting realistic AI photos has never been this effortless.",
        imgSrc: photoshootOnboardingImg1,
    },
    {
        title: "Get Started with Your Model",
        desc: "Opt for a pre-trained model or craft one uniquely yours.",
        imgSrc: photoshootOnboardingImg2,
        features: [
            {
                emoji: "🔍",
                text: "Train your model using your own images for personalized results.",
            },
            {
                emoji: "🤖",
                text: "Choose from our various pre-trained model.",
            },
        ],
    },
    {
        title: "Explore Themed Albums",
        desc: ` From "Headshots" to "Holiday vibes", pick albums that fits your mood.`,
        imgSrc: photoshootOnboardingImg3,
        features: [
            {
                emoji: "🎭",
                text: "Multiple pre-packaged album selection for diverse photo results.",
            },
            {
                emoji: "🔄",
                text: "Automated photoshoot based on the album's theme.",
            },
        ],
    },
    {
        title: "Shape Your Photoshoot",
        desc: ` Use prompts to guide the AI in your desired direction.`,
        imgSrc: photoshootOnboardingImg4,
        features: [
            {
                emoji: "🚀",
                text: "Choose from hundreds of handpicked prompt suggestions.",
            },
            {
                emoji: "📜",
                text: "Or write your own prompts for customized shoots and get realistic results.",
            },
        ],
    },
    {
        title: "The World of AI Photoshoot Awaits!",
        desc: ` Dive in and bring your visions to life.`,
        imgSrc: photoshootOnboardingImg5,
    },
];

const myStepArr = [1, 2, 3, 4];

const OnboardingPopup = ({ isOnboardingOpen, setIsOnboardingOpen }) => {
    const [activePage, setActivePage] = useState(0);
    const theme = useTheme();

    const { data } = useAPI();

    const handeNext = () => {
        let currPage = activePage;
        if (currPage + 1 > onboardingData.length - 1) {
            setIsOnboardingOpen(false);
        } else {
            currPage = currPage + 1;
        }
        setActivePage(currPage);
    };
    const handePrev = () => {
        let currPage = activePage;
        if (currPage - 1 < 0) {
            currPage = 0;
        } else {
            currPage = currPage - 1;
        }
        setActivePage(currPage);
    };
    const handleSkip = () => {
        //setIsOnboardingOpen(false);
        setActivePage(onboardingData.length - 1);
    };

    return (
        <Box
            sx={{
                background: "rgba(27, 15, 70, 0.6)",
                backdropFilter: "blur(2px)",
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
                bottom: "0",
                zIndex: 101,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === "light" ? "#F8FAFC" : "#1E293B",
                    width: { xs: "90vw", md: "60vw" },
                    height: { xs: "100vh", md: "90vh" },
                    borderRadius: "10px",
                    padding: { xs: "1rem", md: "65px 110px" },
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    /* overflowY: "scroll", */
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: { xs: "26px", md: "48px" },
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: { xs: "27px", md: "48px" },
                            letterSpacing: "-0.576px",
                        }}
                    >
                        {onboardingData[activePage].title}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: { xs: "0.9rem", md: "18px" },
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: { xs: "1.1rem", md: "29px" },
                            letterSpacing: "-0.576px",
                            marginTop: { xs: "8px", md: "12px" },
                        }}
                    >
                        {onboardingData[activePage].desc}
                    </Typography>

                    {activePage < onboardingData.length - 1 && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                                justifyContent: "center",
                                marginTop: "23px",
                            }}
                        >
                            {myStepArr.map((it2, idx2) => (
                                <Box
                                    sx={{
                                        background:
                                            idx2 <= activePage
                                                ? "#9333EA"
                                                : "#F3E8FF",
                                        width: "50px",
                                        height: "6px",
                                        borderRadius: "1rem",
                                    }}
                                    key={idx2}
                                ></Box>
                            ))}
                        </Box>
                    )}
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        gap: "2.6rem",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.8rem",
                        }}
                    >
                        {onboardingData[activePage]?.features &&
                            onboardingData[activePage]?.features.map(
                                (it, idx) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "0.5rem",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: {
                                                    xs: "1.4rem",
                                                    md: "2rem",
                                                },
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                lineHeight: "29px",
                                                letterSpacing: "-0.576px",
                                                marginTop: "0.5rem",
                                            }}
                                        >
                                            {it.emoji}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: {
                                                    xs: "0.9rem",
                                                    md: "16px",
                                                },
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                letterSpacing: "-0.576px",
                                            }}
                                        >
                                            {it.text}
                                        </Typography>
                                    </Box>
                                )
                            )}
                    </Box>
                    <img
                        src={onboardingData[activePage].imgSrc}
                        style={{
                            width: window.innerWidth < 1290 ? "260px" : "390px",
                            /* width: "390px", */
                            margin: "0 auto 0 auto",
                        }}
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        gap: "1.3rem",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1rem",
                        paddingBottom: "1rem",
                    }}
                >
                    <Button
                        sx={{
                            display: activePage === 0 ? "none" : "flex",
                            width: { xs: "90px", md: "123px" },
                            height: "45px",
                            padding: "8px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "50px",
                            background: "#EEF2FF",
                            color: "#4338CA",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "29px",
                            letterSpacing: "-0.576px",
                            marginTop: "12px",
                            textTransform: "capitalize",
                            "&:hover": {
                                background: "#C4CEEF",
                            },
                        }}
                        onClick={() => handePrev()}
                        disabled={activePage === 0 ? true : false}
                    >
                        Prev
                    </Button>
                    <Button
                        sx={{
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "29px",
                            letterSpacing: "-0.576px",
                            marginTop: "12px",
                            textTransform: "capitalize",
                            display: activePage === 4 ? "none" : "flex",
                        }}
                        onClick={() => handleSkip()}
                    >
                        Skip
                    </Button>
                    <Button
                        sx={{
                            display: "flex",
                            width: { xs: "90px", md: "123px" },
                            height: "45px",
                            padding: "8px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "50px",
                            background:
                                "linear-gradient(85deg, #EF54FE 0%, #8980FF 65.1%, #62B5FF 127.42%)",
                            color: "white",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "29px",
                            letterSpacing: "-0.576px",
                            marginTop: "12px",
                            textTransform: "capitalize",
                            transition: "all 0.15s ease-in",

                            "&:hover": {
                                opacity: "0.92",
                                transition: "all 0.15s ease-in",
                            },
                        }}
                        onClick={() => handeNext()}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default OnboardingPopup;
