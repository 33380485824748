import React, { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { HiOutlineX } from "react-icons/hi";
import "./VideoSnackbar.css";

const VideoSnackbar = () => {
    const [visible, setVisible] = useState(true);

    /* useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, duration);

        return () => {
            clearTimeout(timer);
        };
    }, [duration]); */

    const handleClose = () => {
        setVisible(false);
    };
    return (
        visible && (
            <Box
                sx={{
                    position: "fixed",
                    left: "20px",
                    bottom: "20px",
                    background: "#1E293B",
                    color: "white",

                    borderRadius: "1.4rem",
                    maxWidth: { xs: "85vw", md: "424px" },
                    zIndex: "101",
                }}
                className="video-snackbar-parent"
            >
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        overflow: "hidden",
                        borderRadius: "8px",
                    }}
                >
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: { xs: "5px", md: "5px" },
                            right: { xs: "5px", md: "5px" },
                            border: "1.5px solid transparent",
                            "&:hover": {
                                border: "1.5px solid rgba(128, 128, 128, 0.1)",

                                transition: "all 0.15s ease-in",
                            },
                            color: "white",
                        }}
                        onClick={handleClose}
                    >
                        <HiOutlineX size={20} />
                    </IconButton>

                    <Box
                        sx={{
                            display: { xs: "none", md: "inline-flex" },
                        }}
                    >
                        <img
                            src="/assets/snackbarMainImg.png"
                            style={{
                                width:
                                    window.innerWidth < 768 ? "45px" : "80px",
                                height: "auto",
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            padding: "20px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.7rem",
                            }}
                        >
                            <img
                                src="/assets/snackbarTextIcon.svg"
                                style={{
                                    width: "1rem",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    color: "white",
                                    fontSize: { xs: "1rem", md: "18px" },
                                    fontWeight: "600",
                                }}
                            >
                                Video Creation in Progress
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                color: "#E2E8F0",
                                fontSize: { xs: "0.9rem", md: "15px" },
                                fontWeight: "500",
                            }}
                        >
                            Your video will be ready in about 4-5 minutes. We
                            appreciate your patience as we craft your creation.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    );
};

export default VideoSnackbar;
