/**
 * Firestore auth handling includes
 * all sorts of auth realated reading, updating, deleting data
 * To ensure easily maintainable/readble code let's follow the following patterns
 * 1. Avoid using Promise constructor when possible.
 *    Firestore CRUD operations return promise by default
 * 2. Avoid redundancy in code. Less code, less bug.
 * 3. Remove unnecessary comments
 * 4. Avoid writing giant function/ better split for very specific job
 */

import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getIdToken,
} from "firebase/auth";
import mixpanel from "mixpanel-browser";

import { auth } from "../firebaseInit";
import { createAccount } from "./accountHandler";
// const db = getFirestore(firebaseApp);

const provider = new GoogleAuthProvider();
auth.languageCode = "en";

/**
 * @Details : for forgot password..
 */
export const forgotPassword = async (email) => {
    return new Promise((resolve, reject) => {
        sendPasswordResetEmail(auth, email)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                reject(error);
            });
    });
};

export const signUp = async (name, email, password) => {
    // Provide user's email and password
    return new Promise(async (resolve, reject) => {
        try {
            let pass;
            if (
                email.endsWith("@gmail.com") ||
                email.endsWith("@yahoo.com") ||
                email.endsWith("@hotmail.com") ||
                email.endsWith("@outlook.com")
            ) {
                pass = true;
            } else {
                // check email is disposable or not.
                await fetch("https://api.mailcheck.ai/email/" + email)
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        if (data.status === 200 && data.disposable === true) {
                            pass = false;
                            // Disposable email addresses are not allowed. Please use a different email provider        //
                            // it's means limit is crossed.
                        } else if (data.status === 429) {
                            pass = true;
                        } else {
                            pass = true;
                        }
                    })
                    .catch((err) => {
                        pass = true;
                        console.log(err);
                    });
            }
            console.log("PASS", pass);
            // pass = true;
            if (pass) {
                let userCredentials = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                // Send verification email.
                if (userCredentials) {
                    await createAccount(userCredentials.user, name);
                    await sendEmailVerification(userCredentials.user);
                    resolve({ status: "ok" });
                }
            } else {
                // alert("something went wrong");
                let error = {
                    code: "local/disposable-email",
                };
                reject(error);
            }
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};

// handle Login....
export const login = async (email, password) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                resolve(user);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// Google Sign in..
export const googleSignIn = async () => {
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                console.log({ credential, token, user });
                // create Firebase Account..
                createAccount(user);
                // ...
                resolve(user);
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential =
                    GoogleAuthProvider.credentialFromError(error);
                reject(error);
                // ...
            });
    });
};

export const onAuth = (callback) => {
    onAuthStateChanged(auth, (user) => {
        callback(user);
    });
};

// Logout handler.
export const handleLogout = async () => {
    // return new Promise((resolve,reject)=>{
    // mixpanel.track("Logout");
    signOut(auth)
        .then(() => {
            // Sign-out successful.
            console.log("logout");
            localStorage.clear();
            // resolve({
            //   "success" : true,
            //   "message" : 'Logout Successfull'
            // })
        })
        .catch((error) => {
            console.log(error);
            // An error happened.
            // reject({
            //   "success" : false,
            //   "message" : error
            // })
        });
    // })
};
