export const navItemsData = [
    /* {
        title: "Art",
        to: "/products/art-generator",
        id: "item-1",
    }, */
    /* {
        title: "Art",
        to: "/",
        id: "item-1",
    }, */

    {
        title: "Headshot Generator",
        to: "/products/headshot",
        id: "item-2",
    },
    {
        title: "Video Generator",
        to: "/video",
        id: "item-4",
    },
    /* {
        title: "Audio Generator",
        to: "/audio",
        id: "item-2",
    }, */
    {
        title: "AI Photoshoots",
        to: "/ai-photoshoots",
        id: "item-3",

    },


    /* {
        title: "Blog Image Gen",
        to: "https://aiseo.ai/blog-images/index.html",
        id: "item-3",
    }, */
    {
        title: "Products",
        to: "",
        subMenu: [
            {
                title: "Art",
                to: "/products/art-generator",
            },
            /* {
                title: "Headshot Generator",
                to: "/products/headshot",
            }, */
            {
                title: "Audio Generator",
                to: "/audio",
            },
            /* {
                title: "Art",
                to: "/products/art-generator",
            }, */
            {
                title: "Advertisement Product",
                to: "/products/advertisement-product",
            },
            {
                title: "Image Variation",
                to: "/products/image-variation",
            },
            {
                title: "Erase & Replace",
                to: "/products/erase-replace",
            },
            {
                title: "Instruct",
                to: "/products/instruct",
            },
            /* {
                title: "AI Avatars",
                to: "/ai-avatars",
            }, */
            /* {
                title: "AI Avatars Pricing",
                to: "/ai-avatars-pricing",
            }, */
            {
                title: "Chrome Extension",
                to: "https://chrome.google.com/webstore/detail/aiseo-art-extension/hbdpcocpnklcmdnencjgopobbmmjeadb",
            },
            /* {
                title: "Draggan Tool",
                to: "/products/draggan-tool",
            }, */
            /* {
                title: "Erase & Replace",
                to: "/products/erase-replace",
            },
            {
                title: "Instruct",
                to: "/products/instruct",
            },
            {
                title: "Video Generator",
                to: "/video",
            }, */
        ],
        id: "item-5",
    },
    {
        title: "AISEO tools",
        to: "",
        subMenu: [
            {
                title: "Paraphrasing Tool",
                to: "https://aiseo.ai/tools/paraphrasing-tool.html",
            },
            {
                title: "Readability Improver",
                to: "https://aiseo.ai/tools/hemingway-editor.html",
            },
            {
                title: "Ourank Article",
                to: "https://aiseo.ai/article-writer/index.html",
            },

            {
                title: "Bypass AI Detection",
                to: "https://aiseo.ai/AI-tools/bypass-ai.html",
            },

            {
                title: "Blog Image Gen",
                to: "https://aiseo.ai/blog-images/index.html",
            },
            {
                title: "Humanize AI Text",
                to: "https://humanizeaitext.ai/",
            },

        ],
        id: "item-6",
    },
    {
        title: "Pricing",
        to: "/pricing",
        id: "item-7",
    },
    /* {
        title: "🔥 Free Credits",
        to: "/rewards",
        id: "item-6",
    }, */

    /* {
        title: "Roadmap",
        to: "/roadmap",
        id: "item-7",
    }, */
];
