import React, { useState, useEffect, useMemo } from "react";
import LayoutV3 from "../layout/LayoutV3";
import FooterV3 from "../footer/FooterV3";
import {
    Typography,
    Box,
    Button,
    IconButton,
    Grid,
    CircularProgress,
    Paper
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineDownload,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
    HiChevronLeft
} from "react-icons/hi";
import { useAPI } from "../../StateProvider";
import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
} from "firebase/firestore";
import SingleImageCard from "./SingleImageCard";
import { saveAs } from "file-saver";
import OptionSelected from "../atoms/OptionSelected";

const AlbumsHistory = ({ albumDocsSrcs }) => {
    const [albumSelected, setAlbumSelected] = useState(false)
    const [selectedAlbum, setSelectedAlbum] = useState({})
    const [selectedImgs, setSelectedImgs] = useState([])
    const [isFullView, setIsFullView] = useState(false);
    const [imageForFullViewIndex, setImageForFullViewIndex] = useState(0);

    const resetStates = () => {
        setAlbumSelected(false)
        setSelectedAlbum({})
        setSelectedImgs([])
        setIsFullView(false)
        setImageForFullViewIndex(0)
    }


    const handleAlbumClick = (currAlbum) => {
        setAlbumSelected(true)
        setSelectedAlbum({ ...currAlbum })
        setSelectedImgs(currAlbum.generatedImages)
    }

    const openFullView = () => {
        setIsFullView(true);
    };

    const changeFullViewImgIndex = (index) => {
        setImageForFullViewIndex(index);
    };

    const incrementFullViewImgIndex = () => {
        if (imageForFullViewIndex + 1 > selectedImgs.length - 1) {
            /* if (imageForFullViewIndex + 1 > paginatedSrcs.length - 1) { */
            setImageForFullViewIndex(imageForFullViewIndex);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex + 1);
        }
    };
    const decrementFullViewImgIndex = () => {
        setImageForFullViewIndex(imageForFullViewIndex + 1);
        if (imageForFullViewIndex - 1 < 0) {
            setImageForFullViewIndex(0);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex - 1);
        }
    };

    const handleDownloadClick = () => {
        saveAs(selectedImgs[imageForFullViewIndex], "headshot-download.png");
    };

    const handleGoBackClick = () => {
        resetStates();
    };
    return (
        <>
            {isFullView && (
                <Box
                    sx={{
                        background: "rgba(15, 23, 42, 0.4)",
                        position: "fixed",
                        backdropFilter: "blur(5px)",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        top: "0",
                        zIndex: "1000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            console.log("Outer box clicked");
                            setIsFullView(false);
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: "0.4rem", md: "1.4rem" },
                        }}
                    >
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={decrementFullViewImgIndex}
                        >
                            <HiOutlineChevronLeft size={25} />
                        </IconButton>
                        <Box
                            sx={{
                                width: { xs: "210px", md: "460px" },
                                height: { xs: "210px", md: "460px" },
                                position: "relative",
                                borderRadius: "15px",
                            }}
                        >
                            <img
                                alt=""
                                src={selectedImgs[imageForFullViewIndex]}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                            <IconButton
                                sx={{
                                    background: "white",
                                    color: "#0F172A",
                                    "&:hover": {
                                        background: "#6363FF",
                                        color: "white",
                                    },
                                    position: "absolute",
                                    top: "12px",
                                    right: "12px",
                                }}
                                onClick={handleDownloadClick}
                            >
                                <HiOutlineDownload size={25} />
                            </IconButton>
                        </Box>
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={incrementFullViewImgIndex}
                        >
                            <HiOutlineChevronRight size={25} />
                        </IconButton>
                    </Box>
                </Box>
            )}
            {albumSelected ? (
                <>
                    <Box sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        padding: "1.3rem 0 0 0"
                    }}>
                        <Typography
                            onClick={handleGoBackClick}
                            sx={{
                                display: "flex",
                                gap: "0.4remrem",
                                alignItems: "center",
                                fontFamily: "Inter",
                                padding: "0.5rem",
                                borderRadius: "0.5rem",
                                "&:hover": {
                                    background: "#1E293B",

                                },
                                fontSize: "0.9rem",
                                cursor: "pointer"
                            }}
                        >
                            <HiChevronLeft size={20} />
                            <span>Back</span>
                        </Typography>

                        <Typography sx={{
                            textTransform: "capitalize",
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: { xs: "20px", md: "20px" },
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: { xs: "23px", md: "36px" },
                            letterSpacing: "-0.225px",
                        }}>{selectedAlbum.albumName}</Typography>
                    </Box>
                    <Grid
                        container
                        spacing={4}
                        sx={{
                            marginTop: "1rem",
                        }}
                    >
                        {selectedImgs.map((headshot, index) => (
                            <SingleImageCard
                                key={index}
                                headshot={headshot}
                                openFullView={openFullView}
                                changeFullViewImgIndex={
                                    changeFullViewImgIndex
                                }
                                index={index}
                            />
                        ))}
                    </Grid>
                    {/* <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            margin: "1.5rem 0",
                        }}
                    >
                        <Box
                            sx={{
                                width: "fit-content",
                            }}
                        >
                            <Button
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.max(prev - 1, 1)
                                    )
                                }
                                sx={{
                                    background: "#1E293B",
                                    color: "white",
                                    textTransform: "capitalize",
                                }}
                                disabled={currentPage === 1}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Prev
                                </Typography>
                            </Button>
                            <Button
                                sx={{
                                    margin: "0 20px",
                                    background: "#4338CA",
                                    color: "white",
                                    borderRadius: "0.6rem",
                                    wdith: "32px",
                                    height: "32px",
                                    padding: "0px",
                                    "&:hover": {
                                        background: "#4338CA",
                                        color: "white",
                                    },
                                    pointerEvents: "none",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {currentPage}
                                </Typography>
                            </Button>

                            <Button
                                onClick={() =>
                                    setCurrentPage((prev) =>
                                        Math.min(prev + 1, totalPages)
                                    )
                                }
                                disabled={currentPage === totalPages}
                                sx={{
                                    background: "#1E293B",
                                    color: "white",
                                    textTransform: "capitalize",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "24px",
                                    }}
                                >
                                    Next
                                </Typography>
                            </Button>
                        </Box>
                    </Box> */}
                </>
            ) : (
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    items: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                    padding: "1.5rem 0"
                }}>
                    {albumDocsSrcs.map((album, albumIndex) => (
                        <Box
                            key={albumIndex}
                            onClick={() => handleAlbumClick(album)}
                            sx={{
                                width: "220px",
                                height: "260px",
                                borderRadius: "10px",
                                background: "#1E293B",
                                padding: "10px",
                                border: "1.5px solid #475569",
                                cursor: "pointer",
                                "&:hover": {
                                    scale: "1.05",
                                    transition: "all 0.1s ease-in"
                                }

                            }}

                        >
                            <Grid container spacing={2}>
                                {
                                    album.generatedImages.slice(0, 4).map((genImg, genImgIndex) => (
                                        <Grid
                                            key={genImgIndex}
                                            item
                                            xs={6}
                                            sx={{ borderRadius: "8px" }}
                                        >
                                            <img
                                                style={{

                                                }}
                                                src={genImg}
                                            />

                                        </Grid>
                                    ))
                                }


                            </Grid>

                            <Typography sx={{
                                color: "white",
                                fontFamily: "Inter",
                                marginTop: "0.7rem"
                            }}>
                                {album.albumName}
                            </Typography>

                        </Box>
                    ))}


                </Box>
            )}
        </>

    )
}

export default AlbumsHistory