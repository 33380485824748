import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   searchig: false,
  //   generating: false,
  //   selectedLexicaImage: null,
  //   selectedGeneratdImage: null,
  //   urlSearchedImages: [],
  //   promptSearchedImages: [],
  //   activeTab: 0,
  //   generationPrompt: "",
  //   searchedPrompt: "",

  //   states
  selectedImage: "",
  prompt: "",
  strength: 1,
  imageFileSize: 0,
  generatedImage: [],
};

export const image2imageSlice = createSlice({
  name: "image2image",
  initialState,
  reducers: {
    setSelectedImage: (state, action) => {
      console.log(action.payload);

      state.selectedImage = action.payload.selectedImage;
    },
    setSelectedImageDetalis: (state, action) => {
      console.log(action.payload);

      state.prompt = action.payload.prompt;
      state.strength = action.payload.strength;
      state.imageFileSize = action.payload.imageFileSize;
    },

    setGeneratedImage: (state, action) => {
      // array pathabo
      state.generatedImage = action.payload.generatedImage;
    },
    // generationStart: (state) => {
    //   state.generating = true;
    //   state.searchig = false;
    // },
    // generationSucceed: (state, action) => {
    //   state.generating = false;
    //   state.selectedGeneratdImage = action.payload;
    // },
    // generationFailed: (state) => {
    //   state.generating = false;
    // },
    // promptSearchedSucceed: (state, action) => {
    //   state.promptSearchedImages = action.payload;
    // },
    // urlSearchedSucceed: (state, action) => {
    //   state.urlSearchedImages = action.payload;
    // },
    // lexicaImageSelected: (state, action) => {
    //   state.generating = false;
    //   state.selectedLexicaImage = action.payload;
    //   state.selectedGeneratdImage = null;
    // },
    // tabChnage: (state, action) => {
    //   state.activeTab = action.payload.activeTab;
    //   state[action.payload.key] = action.payload.value;
    // },
    // generatedImageSelected: (state, action) => {
    //   state.selectedGeneratdImage = action.payload;
    //   state.selectedLexicaImage = null;
    // },
  },
});

export const {
  setSelectedImage,
  setGeneratedImage,
  setSelectedImageDetalis,
  //   generationStart,
  //   generationFailed,
  //   lexicaImageSelected,
  //   generationSucceed,
  //   tabChnage,
  //   promptSearchedSucceed,
  //   urlSearchedSucceed,
} = image2imageSlice.actions;
export default image2imageSlice.reducer;
