import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import {
    HiOutlineArrowsExpand,
    HiOutlineDownload,
    HiChevronLeft,
    HiChevronRight,
    HiOutlineX,
} from "react-icons/hi";
import { saveAs } from "file-saver";

const NewGenImageCard = ({ item, index, allImages }) => {
    const theme = useTheme();

    const [isFullView, setIsFullView] = useState(false);
    const [formattedAllImages, setFormattedAllImages] = useState([]);
    const [activeIndex, setActiveIndex] = useState(index ? index : 0);
    const [activeImage, setActiveImage] = useState(
        item?.image ? item.image : ""
    );

    const closeFullView = () => {
        setIsFullView(false);

        setActiveImage(item.image);
        setActiveIndex(index);
    };
    const handleViewFullViewImage = () => {
        setIsFullView(true);
    };
    const downloadImage = () => {
        saveAs(activeImage, "download-image.png");
    };
    const handleActiveImageChange = (currentActiveIndex) => {
        setActiveImage(formattedAllImages[currentActiveIndex].image);
        setActiveIndex(currentActiveIndex);
    };
    const goLeftActiveImage = () => {
        handleActiveImageChange(activeIndex - 1 < 0 ? 0 : activeIndex - 1);
    };
    const goRightActiveImage = () => {
        handleActiveImageChange(
            activeIndex + 1 > formattedAllImages.length - 1
                ? formattedAllImages.length - 1
                : activeIndex + 1
        );
    };

    useEffect(() => {
        setActiveImage(item.image);
    }, [item]);

    useEffect(() => {
        const newArr = [];
        allImages.forEach((cItem, cIndex) => {
            if (cItem.image === "" || cItem.image === undefined) {
            } else {
                newArr.push(cItem);
                console.log(cItem);
            }
        });
        console.log(newArr, "--allImages--");
        setFormattedAllImages(newArr);
    }, [allImages]);

    if (item?.image === undefined || item?.image === "") {
        return null;
    }
    return (
        <Box
            sx={{
                position: "relative",
                width: "250px",
                height: "250px",
                borderRadius: "10px",
                overflow: "hidden",
                "&:hover > div": {
                    // This targets the direct child div (Box) of the main Box on hover.
                    visibility: "visible",
                    opacity: "1",
                    transition: "all 0.11s ease-in",
                },
                cursor: "pointer",
            }}
        >
            {isFullView && (
                <Box
                    sx={{
                        background: "rgba(27, 15, 70, 0.6)",
                        backdropFilter: "blur(2px)",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        zIndex: 101,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "90%",
                            height: "fit-content",
                            borderRadius: "0.7rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "1rem",
                            }}
                        >
                            <IconButton
                                onClick={goLeftActiveImage}
                                /* size="small" */
                                sx={{
                                    background:
                                        theme.palette.mode === "light"
                                            ? "#1E293B"
                                            : "transparent",
                                    color: "white",
                                    "&:hover": {
                                        background:
                                            theme.palette.mode === "light"
                                                ? "#6464FF"
                                                : "transparent",
                                    },
                                }}
                            >
                                <HiChevronLeft />
                            </IconButton>
                            <Box
                                sx={{
                                    width: { xs: "240px", md: "80vh" },
                                    height: { xs: "240px", md: "80vh" },
                                    borderRadius: "0.7rem",
                                    position: "relative",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "0.8rem",
                                        position: "absolute",
                                        right: "8px",
                                        top: "8px",
                                    }}
                                >
                                    <IconButton
                                        sx={{
                                            color: "white",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            "&:hover": {
                                                background: "#6464FF",
                                            },
                                        }}
                                        onClick={downloadImage}
                                    >
                                        <HiOutlineDownload size={20} />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            color: "white",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            "&:hover": {
                                                background: "#6464FF",
                                            },
                                        }}
                                        onClick={closeFullView}
                                    >
                                        <HiOutlineX size={20} />
                                    </IconButton>
                                </Box>

                                <img
                                    src={activeImage}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "0.7rem",
                                    }}
                                />
                            </Box>
                            <IconButton
                                onClick={goRightActiveImage}
                                /* size="small" */
                                sx={{
                                    background:
                                        theme.palette.mode === "light"
                                            ? "#1E293B"
                                            : "transparent",
                                    color: "white",
                                    "&:hover": {
                                        background:
                                            theme.palette.mode === "light"
                                                ? "#6464FF"
                                                : "transparent",
                                    },
                                }}
                            >
                                <HiChevronRight />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        {formattedAllImages.map((subItem, subIndex) => {
                            return (
                                <Box
                                    sx={{
                                        width: "68px",
                                        height: "68px",
                                        borderRadius: "0.6rem",
                                        border:
                                            subIndex === activeIndex
                                                ? "1.5px solid white"
                                                : "none",
                                        "&:hover": {
                                            border: "1.5px solid white",
                                            transition: "all 0.15s ease-in",
                                        },
                                    }}
                                    onClick={() =>
                                        handleActiveImageChange(subIndex)
                                    }
                                >
                                    <img
                                        src={subItem.image}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "0.6rem",
                                        }}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            )}
            <img
                src={item.image}
                style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    width: "250px",
                    height: "250px",
                    zIndex: "80",
                }}
            />
            <Box
                sx={{
                    /* background: "rgba(0, 0, 0, 0.5)", */
                    color: "white",
                    fontSize: "13px",
                    textAlign: "center",
                    position: "absolute",
                    top: "0",
                    bottom: "0",
                    right: "0",
                    zIndex: "90",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    visibility: "hidden",
                    opacity: "0",
                    width: "20%",
                    flexDirection: "column",
                }}
            >
                <IconButton
                    sx={{
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        "&:hover": {
                            background: "#6464FF",
                        },
                    }}
                    onClick={handleViewFullViewImage}
                >
                    <HiOutlineArrowsExpand size={20} />
                </IconButton>
                <IconButton
                    sx={{
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        "&:hover": {
                            background: "#6464FF",
                        },
                    }}
                    onClick={downloadImage}
                >
                    <HiOutlineDownload size={20} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default NewGenImageCard;
