export const instructProductData = {
    porductTitle: "Instruct",
    productSlug: "instruct",
    productDetails:
        "Instruct your image to change in any way possible with styling and dynamics",
    productShowcaseImg:
        "https://images.pexels.com/photos/16248187/pexels-photo-16248187/free-photo-of-jetty-light-sea-dawn.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    productShowcaseVideo:
        "https://static.clipdrop.co/web/homepage/tools/ReplaceBackground.webm#t=0.1",
    productHasInputVariations: true,
    productTryImages: [
        "https://static.clipdrop.co/web/replace-background/examples/car.jpg",
        "https://static.clipdrop.co/web/replace-background/examples/shoes.jpg",
        "https://static.clipdrop.co/web/replace-background/examples/woman.jpg",
    ],
    productVideoTutorial: "",
    productExampleImage: "",
    productInputBox: {
        primaryInput: {
            isActive: true,
            placeHolderText: "Type your prompt",
            labelText: "Prompt",
        },
        tips: "Consider experimenting with different styles by adding, subtracting, or envisioning new modifications for the image. For instance, you could use: 'Turn the cat into a dog' or 'Make it more like van Gogh'.",
        secondaryInput: false,
        range: {
            isActive: true,
            placeHolderText: "Type your prompt",
            labelText: "Quantity",
            min: 1,
            max: 3,
        },
        quantityDropdown: {
            isActive: false,
            /* options: ["1"], */
            options: ["1", "2", "3"],
            labelText: "Quantity",
        },
    },
    singleExample: {
        isActive: false,
        imgSrc: "https://storage.googleapis.com/code-translate-c9640.appspot.com/uploads/f47c8d3e-05f0-453e-85ad-2850b9992d81.png",
    },
    multipleExamples: {
        isActive: true,
        images: [
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/random/landscape.jpg",
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/random/abbey.jpg",
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/random/adam.jpg",
        ],
    },
    variationExamples: {
        isActive: true,
        images: {
            inputImage:
                "https://art.aiseo.ai/static/media/variation1.ea7653d727de773779b7.jpg",
            outputImages: [
                "https://art.aiseo.ai/static/media/variation2.6e190f703ee48be2c686.jpg",
                "https://art.aiseo.ai/static/media/variation3.6e367abf20e653da981b.jpg",
                "https://art.aiseo.ai/static/media/variation4.ea1e7207182013c1e2e6.jpg",
            ],
        },
    },
    hasCanvas: false,
    productTips:
        "Consider experimenting with different styles by adding, subtracting, or envisioning new modifications for the image. For instance, you could use: 'Turn the cat into a dog' or 'Make it more like van Gogh'.",
    /* tryWithExample: { yes: true, imgs: [imgUrl1, url2, url3] }, */
    useCasesData: [{}],
};
