import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   searchig: false,
  //   generating: false,
  //   selectedLexicaImage: null,
  //   selectedGeneratdImage: null,
  //   urlSearchedImages: [],
  //   promptSearchedImages: [],
  //   activeTab: 0,
  //   generationPrompt: "",
  //   searchedPrompt: "",

  //   states
  selectedImageVariation: "",
  lexicalImagePathFromVeriationPage : "",
  imageQuantity: 0,
  generatedImage: [],
};

export const ImageVariation = createSlice({
  name: "imageVariation",
  initialState,
  reducers: {
    setSelectedImagVariation: (state, action) => {
      // console.log(action.payload);

      state.selectedImageVariation = action.payload.selectedImageVariation;
    },
    setSelectedImageDetalis: (state, action) => {
      console.log(action.payload);

      state.imageQuantity = action.payload.imageQuantity;
    },

    setGeneratedImage: (state, action) => {
      // array pathabo
      state.generatedImage = action.payload.generatedImage;
    },
    setImagePathForImageVariationLexical : (state, action)=>{
      state.lexicalImagePathFromVeriationPage = action.payload.lexicalImagePathFromVeriationPage
    }
    // generationStart: (state) => {
    //   state.generating = true;
    //   state.searchig = false;
    // },
    // generationSucceed: (state, action) => {
    //   state.generating = false;
    //   state.selectedGeneratdImage = action.payload;
    // },
    // generationFailed: (state) => {
    //   state.generating = false;
    // },
    // promptSearchedSucceed: (state, action) => {
    //   state.promptSearchedImages = action.payload;
    // },
    // urlSearchedSucceed: (state, action) => {
    //   state.urlSearchedImages = action.payload;
    // },
    // lexicaImageSelected: (state, action) => {
    //   state.generating = false;
    //   state.selectedLexicaImage = action.payload;
    //   state.selectedGeneratdImage = null;
    // },
    // tabChnage: (state, action) => {
    //   state.activeTab = action.payload.activeTab;
    //   state[action.payload.key] = action.payload.value;
    // },
    // generatedImageSelected: (state, action) => {
    //   state.selectedGeneratdImage = action.payload;
    //   state.selectedLexicaImage = null;
    // },
  },
});

export const {
  setSelectedImagVariation,
  setGeneratedImage,
  setSelectedImageDetalis,
  setImagePathForImageVariationLexical
  //   generationStart,
  //   generationFailed,
  //   lexicaImageSelected,
  //   generationSucceed,
  //   tabChnage,
  //   promptSearchedSucceed,
  //   urlSearchedSucceed,
} = ImageVariation.actions;
export default ImageVariation.reducer;
