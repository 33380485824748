import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Button,
    IconButton,
    Dialog,
    Tooltip,
} from "@mui/material";
import { HiOutlineX } from "react-icons/hi";
import avatarGroupsImg from "../../imgs/avatarGroupsImg.svg";
import avatarsPricingGroupImage from "../../imgs/avatarsPricingGroupImage.svg";
import avatarsPricingPopupGroupImageMobile from "../../imgs/avatarsPricingPopupGroupImageMobile.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import pinkHeartIcon from "../../imgs/pinkHeartIcon.svg";
import ToastNotification from "../atoms/ToastNotification";
import { HiOutlineDuplicate } from "react-icons/hi";
import mixpanel from "mixpanel-browser";

const NeedToBuyAvatarsPlan = ({
    lockWarning,
    setLockWarning,
    resetProhibitedState,
    showFreeCreditsFinishedNotyf,
    showCannotSelectAnymoreAlbumsNotyf,
    type,
}) => {
    const [showCopiedNotification, setShowCopiedNotification] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        mixpanel.track("AIPHOTOSHOOTS - Pricing Popup - showed", {
            Description:
                "Ai photoshoots - Pricing Popup - showed when tried to create models or generate images out of credits",
            /* category: event.target.value, */
        });
    }, []);

    console.log(type, "TYPE");
    let linkToPricing = "../pricing";
    const handleAgree = () => {
        setLockWarning(false);
        if (location.pathname === "/tools") {
            resetProhibitedState();
        }
        if (location.pathname === "/video") {
            linkToPricing = "/pricing?active=video";
        }
        if (location.pathname === "/ai-photoshoots") {
            linkToPricing = "/ai-photoshoots-pricing";
        }

        navigate(linkToPricing);
    };

    const DISCOUNT_COUPON = "AIPHOTO20";

    /* const handleCopy = useCallback(() => {
        navigator.clipboard
            .writeText(DISCOUNT_COUPON)
            .then(() => {
                console.log("Text copied to clipboard");
            })
            .catch((err) => {
                console.error("Could not copy text: ", err);
            });
    }, []); */
    const handleCopy = () => {
        navigator.clipboard
            .writeText(DISCOUNT_COUPON)
            .then(() => {
                console.log("Text copied to clipboard");
                setShowCopiedNotification(true);

                setTimeout(() => {
                    setShowCopiedNotification(false);
                }, 3200);
            })
            .catch((err) => {
                console.error("Could not copy text: ", err);
            });
    };
    return (
        <Dialog
            keepMounted
            sx={{
                p: 1,
                "& > div > div": {
                    background:
                        theme.palette.mode === "light" ? "white" : "#1E293B",
                    borderRadius: "0.5rem",
                    maxWidth: { xs: "100vw", md: "70vw" },
                    width: { xs: "100vw", md: "fit-content" },
                    overflowY: window.innerWidth < 321 ? "scroll" : "hidden",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxHeight: { xs: "98vh", md: "90vh" },
                    position: "relative",
                    margin: "0px",
                },
                "& > div": {
                    padding: "0",
                },
                background: "rgba(82, 68, 132, 0.5)",
                backdropFilter: "blur(1px)",
            }}
            open={lockWarning}
            aria-describedby="alert-dialog-slide-description"
        >
            <Box
                sx={{
                    display: "flex",
                    padding: { xs: "0", md: "4rem" },
                    gap: { xs: "0px", md: "160px" },
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {showFreeCreditsFinishedNotyf && (
                    <ToastNotification
                        message="Ran out of free credits. Please subscribe to get more credits"
                        type="error"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
                {showCannotSelectAnymoreAlbumsNotyf && (
                    <ToastNotification
                        message="Multiple Albums is a premium feature. Please upgrade!"
                        type="error"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
                {showCopiedNotification && (
                    <ToastNotification
                        message="Copied!"
                        type="success"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
                <Box
                    sx={{
                        width: { xs: "90%", md: "50%" },
                        height: { xs: "97vh", md: "fit-content" },
                        paddingTop: { xs: "2rem", md: "0" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xs: "center", md: "start" },
                        }}
                    >
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === "light"
                                        ? "#0F172A"
                                        : "white",
                                fontSize: { xs: "1rem", md: "40px" },
                                width: { xs: "fit-content", md: "500px" },
                                lineHeight: { xs: "1.7rem", md: "50px" },
                                fontWeight: "800",
                                letterSpacing: {
                                    xs: "0.001rem",
                                    md: "-0.1rem",
                                },
                                marginBottom: { xs: "0.5rem", md: "1rem" },
                                /* display: { xs: "none", md: "inline-flex" }, */
                            }}
                        >
                            Realistic AI Photoshoot{" "}
                            {/* <br /> No Camera Required! */}
                        </Typography>
                        {/* <Typography
                            sx={{
                                color:
                                    theme.palette.mode === "light"
                                        ? "#0F172A"
                                        : "white",
                                fontSize: { xs: "1.2rem", md: "40px" },
                                width: { xs: "fit-content", md: "100%" },
                                lineHeight: { xs: "1.7rem", md: "50px" },
                                fontWeight: "800",
                                letterSpacing: {
                                    xs: "0.001rem",
                                    md: "-0.1rem",
                                },
                                marginBottom: { xs: "0.5rem", md: "1rem" },
                                display: { xs: "none", md: "inline-flex" },
                            }}
                        >
                            Realistic AI Avatars{" "}
                        </Typography> */}
                        {/* <Typography
                            sx={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "bold",
                                color:
                                    theme.palette.mode === "light"
                                        ? "#0F172A"
                                        : "#DBE2EA",
                                display: { xs: "none", md: "inline-flex" },
                            }}
                        >
                            Join today and get the chance to train models based
                            on your images. No Camera Required!
                        </Typography> */}
                        <Box
                            sx={{
                                width: "260px",
                                height: "210px",
                                display: { xs: "block", md: "none" },
                                marginTop: "0.5rem",
                            }}
                        >
                            <img
                                alt=""
                                src={avatarsPricingPopupGroupImageMobile}
                                style={{ width: "100%", height: "100%" }}
                            />
                            {/* <img
                                src={popupShowcaseTrialMobileImg}
                                style={{ width: "100%", height: "100%" }}
                            /> */}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            margin: { xs: "5px 0", md: "24px 0" },
                            display: "flex",
                            flexDirection: "column",
                            gap: { xs: "0.4rem", md: "0.9rem" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🚀</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Smart Prompts
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Input your ideas, and AISEO translates them
                                    into vivid visuals, be it one or many
                                    images.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>📸</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    True-to-Life Quality
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Dive into hyper-realistic photos that push
                                    the boundaries of AI capabilities.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🧠</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Adaptable Training Models
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Use pre-optimized models or train your own
                                    for unparalleled photo uniqueness.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>📔</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Themed Albums & Presets
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Explore photos across various themes.
                                    Customize or choose from our curated
                                    collections for desired photos.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🌄</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Image Gallery
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    View, manage, and marvel at your
                                    AI-generated photos in a sleek,
                                    user-friendly interface.
                                </Typography>
                            </Box>
                        </Box>
                        {/* <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>⏰</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Limited-Time Deal
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Experience the magic of AISEO Avatar
                                    starting at just
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#8F86FF",
                                        fontWeight: "bold",
                                    }}
                                >
                                    $29.00/month.
                                </Typography>
                            </Box>
                        </Box> */}
                    </Box>
                    <Box
                        sx={{
                            background:
                                theme.palette.mode === "light"
                                    ? "#0F172A"
                                    : "#0F172A",
                            color: "#F8FAFC",
                            padding: "0.9rem 0.9rem 3.7rem 0.9rem",
                            borderRadius: "10px",
                            display: { xs: "flex", md: "flex" },
                            gap: "8px",
                            position: "relative",
                        }}
                    >
                        <span>🎉</span>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { xs: "0.9rem", md: "16px" },
                                    lineHeight: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                Special Offer!
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "0.87rem",
                                }}
                            >
                                Get an exclusive 20% off with the code
                            </Typography>

                            <Tooltip title="Click to Copy">
                                <Box
                                    sx={{
                                        color: "#8F86FF",
                                        fontWeight: "bold",
                                        border: "2",
                                        background:
                                            "linear-gradient(89deg, #E58BFC -10.02%, #E6C162 113.44%)",
                                        width: "fit-content",
                                        fontFamily: "Inter",
                                        padding: "0.18rem",
                                        borderRadius: "1.7rem",
                                        cursor: "pointer",
                                        position: "absolute",
                                        right: "1rem",
                                        bottom: "1rem",
                                    }}
                                    onClick={handleCopy}
                                >
                                    <Typography
                                        sx={{
                                            background:
                                                theme.palette.mode === "light"
                                                    ? "#0F172A"
                                                    : "#0F172A",
                                            borderRadius: "inherit",
                                            padding: "0.3rem 1.6rem",
                                            color: "white",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "0.5rem",
                                            fontSize: {
                                                xs: "0.9rem",
                                                md: "1rem",
                                            },
                                        }}
                                    >
                                        <span>{DISCOUNT_COUPON}</span>
                                        <span
                                            /* sx={{
                                                color: "white",
                                                background: "red",
                                                "&:hover": {
                                                    background: "transparent",
                                                },
                                            }} */
                                            style={{
                                                color: "white",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <HiOutlineDuplicate size={18} />
                                        </span>
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column-reverse", md: "row" },
                            gap: { xs: "0.7rem", md: "68px" },
                            margin: "1.34rem 0 0 0",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.9rem",
                            }}
                        >
                            <Button
                                /* variant="contained"
                            size="small" */
                                /* onClick={handleAgree} */
                                sx={{
                                    /* background: "#4F46E5", */
                                    background:
                                        "linear-gradient(78deg, #4F46E5 31.4%, #D21DFF 104.77%)",

                                    borderRadius: "30px",
                                    whiteSpace: "nowrap",
                                    color: "white",
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    /* transition: "all 0.15s ease-in", */
                                    transition: "opacity 0.15s ease-in",
                                    opacity: 1,

                                    "&:hover": {
                                        opacity: 0.92,
                                        transition: "opacity 0.15s ease-in",
                                    },

                                    /* "&:hover": {
                                        background:
                                            "linear-gradient(190deg, #4F46E5 31.4%, #D21DFF 104.77%)",
                                        transition: "all 0.15s ease-in",
                                    }, */
                                    fontFamily: "Inter",
                                }}
                                onClick={handleAgree}
                            >
                                <Typography
                                    sx={{
                                        padding: "6px 18px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {" "}
                                    {/* {location.pathname === "/ai-avatars"
                                    ? "Purchase"
                                    : "Start Free Trial"} */}
                                    Upgrade Now & Save 20%
                                </Typography>
                            </Button>
                            <Box
                                sx={{
                                    gap: "0.5rem",
                                    alignItems: "center",
                                    display: {
                                        xs: "none",
                                        md: "flex",
                                    },
                                }}
                            >
                                <img
                                    alt=""
                                    src={pinkHeartIcon}
                                    style={{
                                        width: "1.2rem",
                                        height: "1.2rem",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontWeight: "bold",
                                        maxWidth: "200px",
                                    }}
                                >
                                    Spoiler: You'll love it!
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: "none", md: "flex" },
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                            }}
                        >
                            <Box sx={{ width: "157px" }}>
                                <img
                                    alt=""
                                    src={avatarGroupsImg}
                                    style={{ width: "100%" }}
                                />
                            </Box>

                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    color:
                                        theme.palette.mode === "light"
                                            ? "#0F172A"
                                            : "white",
                                    fontWeight: "bold",
                                    maxWidth: "200px",
                                }}
                            >
                                250,000+ people already loved our tools.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        maxWidth: "350px",
                        height: "640px",
                        display: { xs: "none", md: "block" },
                    }}
                >
                    <img
                        alt=""
                        src={avatarsPricingGroupImage}
                        style={{ width: "100%", height: "100%" }}
                    />
                </Box>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "5px", md: "2rem" },
                        right: { xs: "5px", md: "2rem" },
                        border: "1.5px solid transparent",
                        "&:hover": {
                            border: "1.5px solid grey",
                            transition: "all 0.15s ease-in",
                        },
                    }}
                    onClick={handleAgree}
                >
                    <HiOutlineX size={25} />
                </IconButton>
            </Box>
        </Dialog>
    );
};

export default NeedToBuyAvatarsPlan;
