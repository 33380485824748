import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import OptionsBadgeBox from "./atoms/OptionsBadgeBox";

const stageData = [
    /* {
        labelTitle: "Gender",
        labelIcon: "/assets/videoGenderPromptIcon.svg",
        options: [
            {
                id: "gender1",
                value: "Male",
                isSelected: false,
            },
            {
                id: "gender2",
                value: "Female",
                isSelected: false,
            },
        ],
    }, */
    // Educational, Entertaining, Inspirational, Vlog
    {
        labelTitle: "Video Style",
        labelIcon: "/assets/videoAccentPromptIcon.svg",
        options: [
            {
                id: "vidStyle1",
                value: "Inspirational",
                isSelected: false,
            },
            {
                id: "vidStyle2",
                value: "Instructional",
                isSelected: false,
            },
            {
                id: "vidStyle3",
                value: "Documentary",
                isSelected: false,
            },
            {
                id: "vidStyle4",
                value: "Entertainment",
                isSelected: false,
            },
            {
                id: "vidStyle5",
                value: "Cinematic",
                isSelected: false,
            },
            {
                id: "vidStyle6",
                value: "Vlog",
                isSelected: false,
            },
            {
                id: "vidStyle7",
                value: "Interview",
                isSelected: false,
            },
        ],
    },
    {
        labelTitle: "Content focus",
        labelIcon: "/assets/videoTonePromptIcon.svg",
        options: [
            {
                id: "contentFocus1",
                value: "Educational",
                isSelected: false,
            },
            {
                id: "contentFocus2",
                value: "Motivational",
                isSelected: false,
            },
            {
                id: "contentFocus3",
                value: "Narrative",
                isSelected: false,
            },
            {
                id: "contentFocus4",
                value: "How-to",
                isSelected: false,
            },
            {
                id: "contentFocus5",
                value: "Review",
                isSelected: false,
            },
            {
                id: "contentFocus6",
                value: "News and Current Affairs",
                isSelected: false,
            },
            {
                id: "contentFocus7",
                value: "Behind-the-Scenes",
                isSelected: false,
            },
        ],
    },
    {
        // 'Busy Professionals', '', '', '', '', '', '', '', '']
        labelTitle: "Audience",
        labelIcon: "/assets/videoAudiencePromptIcon.svg",
        options: [
            {
                id: "audience1",
                value: "Busy Professionals",
                isSelected: false,
            },
            {
                id: "audience2",
                value: "Students",
                isSelected: false,
            },
            {
                id: "audience3",
                value: "Young Adults",
                isSelected: false,
            },
            {
                id: "audience4",
                value: "Children",
                isSelected: false,
            },
            {
                id: "audience5",
                value: "Fitness Enthusiasts",
                isSelected: false,
            },
            {
                id: "audience6",
                value: "Entrepreneurs",
                isSelected: false,
            },
            {
                id: "audience7",
                value: "Seniors",
                isSelected: false,
            },
            {
                id: "audience8",
                value: "Hobbyists",
                isSelected: false,
            },
            {
                id: "audience9",
                value: "General Audience",
                isSelected: false,
            },
        ],
        customAddOption: true,
    },
    /* {
        labelTitle: "Accent",
        labelIcon: "/assets/videoAccentPromptIcon.svg",
        options: [
            {
                id: "accent1",
                value: "Brummie",
                isSelected: false,
            },
            {
                id: "accent2",
                value: "Northwestern",
                isSelected: false,
            },
            {
                id: "accent3",
                value: "Mancunian",
                isSelected: false,
            },
        ],
    },
    {
        labelTitle: "Tone",
        labelIcon: "/assets/videoTonePromptIcon.svg",
        options: [
            {
                id: "tone1",
                value: "Humour",
                isSelected: false,
            },
            {
                id: "tone2",
                value: "Serious",
                isSelected: false,
            },
            {
                id: "tone3",
                value: "Mystery",
                isSelected: false,
            },
        ],
    }, */
];

const InputStageTwo = ({
    setAudienceInputValue,
    setVidStyleInputValue,
    setContentFocusInputValue,
}) => {
    const [isRangeBeingControlled, setIsRangeBeingControlled] = useState(false);
    const [stageTwoData, setStageTwoData] = useState([...stageData]);
    const [isVisible, setIsVisible] = useState(false);

    const handleRangeMouseDown = () => {
        setIsRangeBeingControlled(true);
    };

    const handleRangeMouseUp = () => {
        setIsRangeBeingControlled(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 30);
    }, []);

    const handleOptionValChange = (currVal, currLabelTitle) => {
        // console.log(currVal);
        // console.log(currLabelTitle);
        console.log(currVal);

        if (currLabelTitle === "Video Style") {
            setVidStyleInputValue(currVal);
        } else if (currLabelTitle === "Content focus") {
            setContentFocusInputValue(currVal);
        } else if (currLabelTitle === "Audience") {
            setAudienceInputValue(currVal);
        }
    };

    return (
        <Box
            sx={{
                opacity: isVisible ? 1 : 0.005, // Set opacity based on visibility
                transition: "opacity 0.5s ease-in-out", //
            }}
        >

            <Box>
                <Typography
                    sx={{
                        color: "#FFF",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "22px", md: "30px" },
                        fontStyle: "normal",
                        fontWeight: "800",
                        lineHeight: {
                            xs: "24px",
                            md: "36px",
                        } /* 114.583% */,
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                    }}
                >
                    {/* Choose the Perfect Style and focus */}
                    Final Touches
                </Typography>
                <Typography
                    sx={{
                        color: "#E2E8F0",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: {
                            xs: "1rem",
                            md: "1.2rem",
                        } /* 114.583% */,
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                    }}
                >
                    Ensure everything aligns with your vision.
                    {/* Your video deserves to be focused. */}
                    {/* Your video deserves the best sound. */}
                </Typography>
            </Box>
            <Box
                sx={{
                    margin: "2.5rem 0",
                }}
            >
                <Box
                    sx={{
                        marginBottom: "1.8rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "46px",
                    }}
                >
                    {stageTwoData.map((it, idx) => (
                        <Box
                            key={idx + 1}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "17px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}
                            >
                                <img
                                    src={it.labelIcon}
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        color: "#E2E8F0",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "0.9rem", md: "1rem" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                    }}
                                >
                                    {it.labelTitle}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                }}
                            >
                                <OptionsBadgeBox
                                    options={it.options}
                                    labelTitle={it.labelTitle}
                                    handleOptionValChange={
                                        handleOptionValChange
                                    }
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default InputStageTwo;
