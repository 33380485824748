import {
    HiPlay,
    HiOutlinePause,
    HiOutlineVolumeUp,
    HiOutlineVolumeOff,
    HiOutlineArrowsExpand,
    HiOutlineDownload,
} from "react-icons/hi";
import React, { useState, useRef } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadIcon from "@mui/icons-material/Download";
import Slider from "@mui/material/Slider";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { saveAs } from "file-saver";

const VideoPlayerSm = ({ generatedVideoSrc }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(100);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [progress, setProgress] = useState(0);

    const togglePlay = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const toggleMute = () => {
        if (videoRef.current.muted) {
            videoRef.current.muted = false;
            setIsMuted(false);
        } else {
            videoRef.current.muted = true;
            setIsMuted(true);
        }
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
        videoRef.current.volume = newValue / 100;
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    const handleProgressChange = (event, newValue) => {
        setProgress(newValue);
        videoRef.current.currentTime =
            (newValue / 100) * videoRef.current.duration;
    };

    const handleTimeUpdate = () => {
        const currentTime =
            (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setProgress(currentTime);
    };

    const handleDownload = () => {
        // Implement download logic here
        saveAs(generatedVideoSrc);
    };

    return (
        <Box
            sx={{
                position: "relative",
                background: "",
                marginTop: "1.5rem",
            }}
        >
            <video
                ref={videoRef}
                src={generatedVideoSrc}
                /* src="https://replicate.delivery/mgxm/08e3a2d5-4594-4934-a34a-6e249744ee52/out.mp4" */
                onTimeUpdate={handleTimeUpdate}
                onEnded={() => setIsPlaying(false)}
                style={{
                    /* width: window.innerWidth < 768 ? "92vw" : "750px",
                    height: window.innerWidth < 768 ? "220px" : "450px", */
                    width: "100%",
                    height: "100%",
                    aspectRatio: "16 / 9",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.8rem",
                    background: "rgba(30, 41, 59, 0.3)",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    padding: "0.5rem",
                }}
            >
                <IconButton
                    onClick={togglePlay}
                    sx={{
                        color: "white",
                    }}
                >
                    {isPlaying ? (
                        <HiOutlinePause size={30} />
                    ) : (
                        <img
                            src="/assets/vidPlayIcon.svg"
                            style={{ width: "30px", height: "30px" }}
                        />
                    )}
                    {/* {isPlaying ? <HiOutlinePause /> : <HiPlay size={20} />} */}
                </IconButton>

                {/* <Box
                        sx={{
                            width: "482px",
                            height: "9px",
                            background: "#334155",
                            borderRadius: "50px",
                            position: "relative",
                        }}
                    >
                        <Box
                            sx={{
                                background:
                                    "linear-gradient(90deg, #0068EE -0.9%, #BE76FF 102.66%)",
                                width: `${progress}%`,
                                height: "100%",
                                transition: "width 0.3s ease-in-out",
                                borderRadius: "inherit",
                            }}
                        ></Box>
                    </Box> */}
                <Slider
                    value={progress}
                    onChange={handleProgressChange}
                    min={0}
                    max={100}
                    aria-label="Video Progress"
                    sx={{
                        color: "white",
                    }}
                />
                {/* <Typography variant="body2">{progress.toFixed(2)}</Typography> */}
                <IconButton
                    onClick={toggleMute}
                    sx={{
                        color: "white",
                    }}
                >
                    {isMuted ? (
                        <HiOutlineVolumeOff size={30} />
                    ) : (
                        <HiOutlineVolumeUp size={30} />
                    )}
                </IconButton>
                {/* <Slider
                    value={volume}
                    onChange={handleVolumeChange}
                    min={0}
                    max={100}
                    aria-label="Volume"
                /> */}
                <IconButton onClick={toggleFullscreen}>
                    <img
                        src="/assets/vidFullSizeIcon.svg"
                        style={{ width: "30px", height: "30px" }}
                    />
                    {/* <HiOutlineArrowsExpand size={20} /> */}
                </IconButton>
                <IconButton
                    onClick={handleDownload}
                    sx={{
                        color: "white",
                    }}
                >
                    <HiOutlineDownload size={30} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default VideoPlayerSm;
