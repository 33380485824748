import React, { useState, useEffect, useRef } from "react";
import viewIcon from "../../imgs/viewIcon.png";
import useIcon from "../../imgs/useIcon.png";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    Tooltip,
    Skeleton,
    Slide,
    CircularProgress,
    Container,
    Select,
    MenuItem,
} from "@mui/material";
import {
    HiOutlineEye,
    HiOutlinePencil,
    HiOutlinePlus,
    HiOutlineSearch,
} from "react-icons/hi";
import { styled, useTheme } from "@mui/material/styles";
import { updateViewNumber } from "../../firestore/templateHandler";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseInit";

const TemplateCardLatestV2 = ({ template }) => {
    const theme = useTheme();
    const [user, loading] = useAuthState(auth);

    const viewNumRef = useRef(null);
    const navigate = useNavigate();

    const incrementViewQty = async (templateId) => {
        viewNumRef.current.innerText = Number(viewNumRef.current.innerText) + 1;
        console.log("Hello");
        updateViewNumber(templateId, user.email);
    };

    const handleRedirect = (template) => {
        incrementViewQty(template.id);
        navigate(`/templates/${template.id}`);
        /* if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                navigate("/login");
            }, 3000);
        } else {
            incrementViewQty(template.id);
            navigate(`/templates/${template.id}`);
        } */
    };

    return (
        <Box
            sx={{
                borderRadius: "8px",
                boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                transition: "all 0.1s ease-in",
                background:
                    theme.palette.mode === "light" ? "white" : "#0F172A",

                "&:hover": {
                    boxShadow:
                        theme.palette.mode === "light"
                            ? "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;"
                            : "none",
                    transition: "all 0.1s ease-in-out",
                    transform: "scale(1.04)",
                    background: "#1E293B",
                    color: "white",
                },
                position: "relative",
                overflow: "hidden",
                padding: "0.5rem",
                height: "373px",
            }}
            onClick={() => handleRedirect(template)}
            /* onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={() => handleRedirect(template)} */
        >
            <Box
                sx={{
                    width: "100%",
                    aspectRatio: "1/1",
                    borderRadius: "7px",
                    overflow: "hidden",
                }}
            >
                <img
                    src={template?.url}
                    style={{
                        width: "100%",
                        aspectRatio: "1/1",
                    }}
                />
            </Box>
            <Box>
                <Typography
                    sx={{
                        fontSize: "0.9rem",
                        maxWidth: "97%",
                        textAlign: "center",
                        margin: "1.2rem auto 0.7rem auto",
                        fontWeight: "600",
                        lineHeight: "1rem",
                    }}
                >
                    {template.category}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.7rem",
                        justifyContent: "center",
                        marginBottom: "0.7rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.34rem",
                        }}
                    >
                        <img
                            src={viewIcon}
                            style={{
                                width: "16px",
                                height: "16px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "0.87rem",
                            }}
                        >
                            {template.viewQty}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.34rem",
                        }}
                    >
                        <img
                            src={useIcon}
                            style={{
                                width: "16px",
                                height: "16px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "0.87rem",
                            }}
                        >
                            {template.usedQty}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TemplateCardLatestV2;
