import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function useDeviceChecker() {
    const theme = useTheme();
    // const sm = useMediaQuery(theme.breakpoints.down("md"));
    // const sm = useMediaQuery(theme.breakpoints.down("sm"));
    const md = useMediaQuery(theme.breakpoints.up("sm"));
    const lg = useMediaQuery(theme.breakpoints.up("md"));
    if (lg) return "lg";
    else if (md) return "md";
    else return "sm";
}
