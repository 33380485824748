import React, { useEffect, useState } from "react";
import LayoutV3 from "../layout/LayoutV3";
import FooterV3 from "../footer/FooterV3";
import axios from "axios";
import {
    Box,
    CircularProgress
} from "@mui/material";
import FreeVersionHeadshot from "./FreeVersionHeadshot";
import { useAPI } from "../../StateProvider";
import ProVersionHeadshot from "./ProVersionHeadshot";


const HeadshotMain = ({ user, credits }) => {
    const { data, dataFetched } = useAPI();

    useEffect(() => {
        console.log("helpingtext:", dataFetched)
    }, [dataFetched])

    return (
        <LayoutV3 footer={<FooterV3 />}>
            {dataFetched ? (
                <Box
                    sx={{
                        background: "#0F172A",
                        color: "white",
                        padding: { xs: "0.7rem 0.5rem", md: "1rem 6rem" },
                        minHeight: "90vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {!user || data?.subscription?.type === "Free" ? (
                        <FreeVersionHeadshot user={user} credits={credits} />
                    ) : (
                        <ProVersionHeadshot user={user} credits={credits} />
                    )}
                    {/* <ProVersionHeadshot user={user} credits={credits} /> */}
                </Box>
            ) : (
                <Box sx={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <CircularProgress size={90} />
                </Box>)}

        </LayoutV3>
    );
};

export default HeadshotMain;
