import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generationDetails: "",
    generatedImage : "",
};

const customTemplateSlice = createSlice({
    name: "customTemplate",
    initialState,
    reducers: {
        setGeneratedImageCustomTemplate: (state, action) => {
            console.log("HERE ACTION", action);
            state.generatedImage = action.payload.url;
        },
        setGenerationDetails : (state, action)=>{
            console.log(action);
            state.generationDetails = action.payload
        }
    },
});

export const {setGenerationDetails, setGeneratedImageCustomTemplate } = customTemplateSlice.actions;

export default customTemplateSlice.reducer;
