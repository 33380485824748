import React, { useState, useEffect, useRef } from "react";
import {
    Typography,
    Box,
    Slide,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiChevronDoubleRight,
} from "react-icons/hi";
import { uploadToStorage } from "../../../firestore/storageHandler";

import OptionSelected from "../../atoms/OptionSelected";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import DisplayLexicaImagesForImageVariation from "../../form/DisplayLexicaImagesForImageVariation";
import {
    imageUploaded,
} from "../../../features/image/adProductSlice";
import { saveAs } from "file-saver";
import {
    setGeneratedImage,
    setImagePathForImageVariationLexical,
    setSelectedImagVariation,
} from "../../../features/image/ImageVariation";
import { onGettingIdToken } from "../../../firebaseInit";
import { fetchVariantImages, fetchLexicalData } from "../../../axios/fetchdata";
import { uploadVariationsResult } from "../../../firestore/variationsHandler";
import { useAPI } from "../../../StateProvider";
import NeedToBuyAPlan from "../../model/NeedToBuyAPlan";
import { getCreditBalance } from "../../../firestore/dataHandler";
import { onAuth } from "../../../firestore/authHandlers";
import { resizeImageFile } from "../../utils/resizeImageFile";
import VariationInputBox from "./VariationInputBox";
import VariationOutputBox from "./VariationOutputBox";
import ToastNotification from "../../atoms/ToastNotification";

const VariationImgUpSection = ({ user, productData, credits }) => {
    const uploadedImageSizeRef = React.useRef({ w: 512, h: 512 });
    const openFile = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { generatedImage } = useSelector((state) => state.imageVariation);
    const { data, dataFetched } = useAPI();

    const [loginPopup, setLoginPopup] = useState(false);

    const optionItems = ["By Url", "Upload Image"];
    const [optionSelected, setOptionSelected] = useState(optionItems[1]);

    const [rangeValue, setRangeValue] = useState(1);
    /* const [rangeValue, setRangeValue] = useState(1); */

    const [selectedImage, setSelectedImage] = useState(null);
    const [inputImgUrl, setInputImgUrl] = useState("");
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [variationError, setVariationError] = useState("");

    const [allFetchedLexicalImages, setAllFetchedLexicalImages] = useState();
    const [images, setImages] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    const [lockWarning, setLockWarning] = useState(false);
    const [uploadedImgByInput, setUploadedImgByInput] = useState({});

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const config = useSelector((state) => state.imageVariation);
    // const ifImageisGenerated = config.generatedImage;
    const ifImageisGenerated = config.lexicalImagePathFromVeriationPage;
    //console.log(config, "dsad");
    useEffect(() => {
        if (ifImageisGenerated !== "") {
            // fetch the lexical data..
            console.log("calling", ifImageisGenerated);
            fetchLexicalData(ifImageisGenerated, "variation").then((res) => {
                console.log("CONSOLE FOR HERE", res);
                setAllFetchedLexicalImages(res);
                // setImages(res.splice(0, 20));
                setImages(res);
            });
        }

        // mixpanel.track("Rendering image Variation");
    }, [ifImageisGenerated]);

    const convertImageToBlob = (inputImage) => {
        console.log(inputImage, "img src");
        fetch(inputImage)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                console.log(url, "input text url blob");
            });
        // Create a new FileReader object.
        /* let reader = new FileReader();
        reader.readAsDataURL(inputImage);

        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: "image/jpeg" });

            // Return the Blob object.
            console.log(blob, " url blob ");
            return blob;
        }; */

        // Read the image data from the URL.
        /* if (optionSelected === "Type Url") {
            reader.readAsDataURL(inputImage);

            // Once the image data is read, create a new Blob object.
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: "image/jpeg" });

                // Return the Blob object.
                console.log(blob, " url blob ");
                return blob;
            };
        }

        reader.readAsArrayBuffer(inputImage);
        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: inputImage.type });
            // Return the Blob object.
            return blob;
        }; */
    };

    /*
    const handleInputImgUrlSubmit = (event) => {
        event.preventDefault();
        setSelectedImage(inputImgUrl);
        convertImageToBlob(inputImgUrl);
    };
    */

    /*
    const handleDefaultInput = (event) => {
        if (event.target.files && event.target.files[0]) {
            const url = URL.createObjectURL(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            console.log(url, "defaultInput url");
            if (productData.productSlug === "advertisement-product") {
                dispatch(imageUploaded({ inputImage: url }));
            }
        }
        setIsLoadingImg(true);
    };
    */

    async function getImageSize(src) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = function () {
                let h = this.height;
                let w = this.width;
                if (w > 512) {
                    let ratio = 512 / w;
                    // console.log(ratio);
                    let revisedHeight = parseInt(h * ratio);
                    // console.log(revisedHeight);
                    resolve({
                        height: revisedHeight,
                        width: 512,
                    });
                } else {
                    resolve({
                        height: this.height,
                        width: this.width,
                    });
                }
            };
            image.src = src;
        });
    }
    
    /*
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    */

    const processUploadAndGenerate = async (file) => {
        // CREDIT REDUCTION
        // await reduceTheCredit(EXPENSE_RATE.imageReversal);
        // setUploadedImgsrc(imageString);
        const result = await uploadToStorage({
            file,
            uid: user.uid,
        });
        const { path } = result;

        // transer the url to redux..
        if (path) {
            dispatch(
                setImagePathForImageVariationLexical({
                    lexicalImagePathFromVeriationPage: path,
                })
            );
        }
    };

    const ImageHandler = async (e) => {
        /* UploadImage === null
            ? window.scrollTo({
                  top: inputedImageRef.current.offsetTop + 100,
                  left: 0,
                  behavior: "smooth",
              })
            : window.scrollTo({
                  top: inputedImageRef.current.offsetTop + 200,
                  left: 0,
                  behavior: "smooth",
              }); */
        const mainfile = e.target.files[0];
        console.log(mainfile, "formDara mainFile image from by upload");

        // console.log(mainfile);

        // console.log("Top", inputedImageRef.current.offsetTop);
        let imageUrl = URL.createObjectURL(mainfile);
        console.log(imageUrl, "image url from ImageHandler");

        const tmpSize = await getImageSize(imageUrl);
        setSelectedImage(imageUrl);

        uploadedImageSizeRef.current = {
            w: tmpSize.width,
            h: tmpSize.height,
        };
        /* setUploadImage(imageUrl); */
        // selec image
        dispatch(
            setSelectedImagVariation({
                selectedImageVariation: imageUrl,
            })
        );

        if (mainfile) {
            console.log(mainfile);
            setUploadedImgByInput(mainfile);
        }

        // make is ready for lexical image search...
        processUploadAndGenerate(mainfile);
    };

    const handleInputImgUrlChange = (event) => {
        setInputImgUrl(event.target.value);
        //fakeApiBgRemove();
        processUploadAndGenerate(event.target.value);
    };

    const requestHandler = (formData) => {
        onGettingIdToken((token) => {
            console.log("Fetching started");

            fetchVariantImages({ formData, token })
                .then((data) => {
                    setIsLoading(false);
                    uploadVariationsResult(data.data);
                    console.log("image to dispatch");
                    dispatch(
                        setGeneratedImage({
                            generatedImage: data.data,
                        })
                    );
                    setShowSuccessPopup(true);
                })
                .catch((error) => {
                    setVariationError(
                        "Oops! Something Went Wrong. Please Try Again."
                    );
                    setIsLoading(false);
                    setShowErrorPopup(true);
                });
        });
    };

    const handleImgUrlSubmitClick = () => {
        setIsLoading(true);
        setVariationError("");
        handleSubmit(inputImgUrl);
    };

    const handleRangeChange = (event, newValue) => {
        setRangeValue(newValue);
        //console.log(newValue, "new Val");
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        setShowSuccessPopup(false);
        setShowErrorPopup(false);

        let imageSrc = "";
        let formElement;
        if (typeof e === "object" && e !== null && e.type) {
            e.preventDefault();
            formElement = uploadedImgByInput;
        } else {
            imageSrc = e;
        }

        if (!data.subscription || data?.subscription.type === "Free") {
            // navigate to pricing page...
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            setLockWarning(true);
            setIsLoading(false);

            return;
        }

        onAuth(async (current_user) => {
            if (!current_user) {
                //setShowPopup(true);
                setIsLoading(false);
            } else {
                getCreditBalance(current_user.email).then(async (credits) => {
                    const finalCredit = credits - 2;
                    if (finalCredit < 0) {
                        //setOpen(true);
                        setIsLoading(false);
                        return;
                    }

                    let formData;

                    if (optionSelected === "By Url") {
                        //console.log(imageSrc, "Image Source");
                        formData = new FormData();
                        formData.append("input_image", inputImgUrl);
                        formData.append("num_outputs", 1);
                        formData.append("service", "variation");
                        /* console.log(
                            formData.get("input_image"),
                            "formDara image from by url"
                        );*/
                        /* for (const [key, value] of formData.entries()) {
                            console.log(key, value, );
                        } */
                        requestHandler(formData);
                    } else {
                        formData = new FormData();

                        formData.append("num_outputs", rangeValue);
                        formData.append("service", "variation");
                        // const formProps = Object.fromEntries(formData);
                        //const existingFile = formData.get("input_image");
                        const resizedFile = await resizeImageFile(
                            uploadedImgByInput
                        );
                        resizedFile === null && alert("No data");
                        // console.log(resizedFile, "resizedFile");
                        formData.set("input_image", resizedFile);
                        /* for (const [key, value] of formData.entries()) {
                            console.log(key, value, "myOne");
                        } */
                        requestHandler(formData);
                    }
                });
            }
        });
    };

    /*
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file, "inputted image");

        if (file) {
            handleSubmit(e);
        }
    };
    */

    /*
    const downloadImage = () => {
        saveAs(generatedImage[0], "download-image.png");
    };
    */

    return (
        <Box>
            {isLoading && (
                <ToastNotification
                    message="AISEO is generating your image"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {loginPopup && (
                <ToastNotification
                    message="Please Login"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showErrorPopup && (
                <ToastNotification
                    message="An error occurred, Try again"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showSuccessPopup && (
                <ToastNotification
                    message="Image generated Successfully"
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}
            <OptionSelected
                optionItems={optionItems}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
            />
            <Box
                sx={{
                    height: "120px",
                    border: `4px dashed ${
                        theme.palette.mode === "light" ? "black" : "white"
                    }`,
                    maxWidth: "900px",
                    margin: "1.5rem auto",
                    borderRadius: "1.8rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                }}
            >
                <form
                    onSubmit={(e) => {
                        setIsLoading(true);
                        setVariationError("");
                        handleSubmit(e);
                    }}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        borderRadius: "inherit",
                    }}
                >
                    {optionSelected === optionItems[0] ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                borderRadius: "inherit",
                            }}
                            style={{ gap: "0.3rem" }}
                        >
                            {/* <form
                                onSubmit={handleInputImgUrlSubmit}
                                style={{ display: "flex" }}
                            > */}
                            <input
                                type="url"
                                placeholder="Type the URL of the image"
                                value={inputImgUrl}
                                onChange={handleInputImgUrlChange}
                                style={{
                                    background: "#E8FAFF",
                                    padding: "0.3rem 0.8rem",
                                    borderRadius: "0.5rem",
                                    border: "2px solid #2596FF",
                                    width:
                                        window.innerWidth < 768
                                            ? "90%"
                                            : "20vw",
                                    fontSize: "1rem",
                                }}
                                required
                            ></input>
                            <button
                                /* type="submit" */
                                style={{
                                    background: "#2596FF",
                                    padding: "0.3rem",
                                    borderRadius: "0.5rem",
                                    border: "2px solid #2596FF",
                                    marginLeft: "0.3rem",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={handleImgUrlSubmitClick}
                            >
                                <span style={{ marginRight: "0.2rem" }}>
                                    Make Variant
                                </span>
                                <HiChevronDoubleRight />
                            </button>
                            {/* </form> */}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                "&:hover": {
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? "#e5e7eb"
                                            : "#3F3C3C",
                                },
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                borderRadius: "1.6rem",

                                cursor: "pointer",
                                backgroud: "orange",
                            }}
                        >
                            <Typography
                                sx={{
                                    color:
                                        theme.palette.mode === "light"
                                            ? "#281142"
                                            : "#EFEFEF",
                                    fontWeight: "500",
                                    fontSize: "1.2rem",
                                    cursor: "pointer",
                                }}
                            >
                                Choose an image
                            </Typography>

                            <input
                                type="file"
                                accept="image/png,image/jpeg,image/webp"
                                /* onChange={(e) => handleDefaultInput(e)} */
                                /* onChange={(e) => handleFileChange(e)} */
                                onChange={(e) => ImageHandler(e)}
                                /* ref={openFile} */

                                ref={openFile}
                                /* ref={fileInputRef} */
                                required
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    right: "0",
                                    bottom: "0",
                                    opacity: "0",
                                    cursor: "pointer",
                                }}
                            ></input>
                        </Box>
                    )}
                </form>
            </Box>
            {selectedImage && optionSelected === "Upload Image" && (
                <Box
                    sx={
                        {
                            /* background: "red" */
                        }
                    }
                >
                    {/* <Box>
                        <Typography
                            onClick={() => console.log(uploadedImgByInput)}
                        >
                            Selected Image
                        </Typography>

                        <Box
                            sx={{
                                background: "rgba(63, 60, 60, 0.34)",
                                padding: "0.3rem",
                                borderRadius: "0.3rem",
                                padding: "0.8rem",
                            }}
                        >
                            <img
                                src={selectedImage}
                                style={{
                                    width: "150px",
                                    border: "2px solid black",
                                    padding: "0.3rem",
                                    borderRadius: "0.3rem",
                                }}
                            />
                        </Box>
                    </Box> */}
                    <Box sx={{ margin: { xs: "0 0 0rem 0", md: "0" } }}>
                        <Box
                            sx={{
                                background:
                                    theme.palette.mode === "light"
                                        ? "#EFEFEF"
                                        : "black",
                                padding: "0.5rem",
                                border: `3px solid ${
                                    theme.palette.mode === "light"
                                        ? "black"
                                        : "#E9E9E9"
                                }`,
                                borderRadius: "0.5rem",
                                width: { xs: "100%", md: "50%" },
                                margin: "0 auto",
                            }}
                        >
                            <Typography onClick={() => console.log(images)}>
                                Selected Image
                            </Typography>
                            <img
                                src={selectedImage}
                                alt=""
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "0.5rem",
                                }}
                            />
                        </Box>
                        {/* <ExtraHelperBox productData={productData} /> */}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            height: "fit-content",
                            justifyContent: "center",
                            /* border: "1px solid black", */
                            flexDirection: { xs: "column-reverse", md: "row" },
                            marginTop: "4rem",
                        }}
                        style={{ gap: "2rem" }}
                    >
                        <VariationInputBox
                            productData={productData}
                            selectedImage={selectedImage}
                            rangeValue={rangeValue}
                            handleRangeChange={handleRangeChange}
                            handleSubmit={handleSubmit}
                            /* onGenerateOutput={onGenerateOutput} */
                        />
                        <VariationOutputBox
                            productData={productData}
                            generatedImage={generatedImage}
                            selectedImage={selectedImage}
                            isLoading={isLoading}
                            optionSelected={optionSelected}
                            /*  onGenerateOutput={onGenerateOutput} */
                        />
                    </Box>
                </Box>
            )}

            {inputImgUrl && optionSelected === "By Url" && (
                <Box sx={{}}>
                    <Box sx={{ margin: { xs: "0 0 4rem 0", md: "0" } }}>
                        <Box
                            sx={{
                                background:
                                    theme.palette.mode === "light"
                                        ? "#EFEFEF"
                                        : "black",
                                padding: "0.5rem",
                                border: `3px solid ${
                                    theme.palette.mode === "light"
                                        ? "black"
                                        : "#E9E9E9"
                                }`,
                                borderRadius: "0.5rem",
                                width: { xs: "100%", md: "50%" },
                                margin: "0 auto",
                            }}
                        >
                            <Typography>Selected Image</Typography>
                            <img
                                src={inputImgUrl}
                                alt=""
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "0.5rem",
                                }}
                            />
                        </Box>
                        {/* <ExtraHelperBox productData={productData} /> */}
                    </Box>
                    <Box
                        sx={{
                            height: "80vh",
                            /* border: "1px solid black", */
                            flexDirection: { xs: "column-reverse", md: "row" },
                            margin: "0rem auto",
                            width: { xs: "80vw", md: "500px" },
                        }}
                        style={{ gap: "2rem" }}
                    >
                        <VariationOutputBox
                            productData={productData}
                            generatedImage={generatedImage}
                            selectedImage={inputImgUrl}
                            isLoading={isLoading}
                            optionSelected={optionSelected}
                            /*  onGenerateOutput={onGenerateOutput} */
                        />
                    </Box>
                </Box>
            )}

            {/* {selectedImage ? (
                <WorkSpace
                    productData={productData}
                    selectedImage={selectedImage}
                    
                />
            ) : (
                <></>
            )} */}
            {images && optionSelected === "Upload Image" ? (
                <Box sx={{ marginTop: "8rem" }}>
                    <DisplayLexicaImagesForImageVariation
                        images={images}
                        setImages={allFetchedLexicalImages}
                        credits={credits}
                        copyPrompt={false}
                        tryThis={true}
                        /* handlePromptSelection={handlePromptSelection} */
                        openLightBox={true}
                        user={user}
                    />
                </Box>
            ) : null}
        </Box>
    );
};

export default VariationImgUpSection;
