import React, { useState, useRef } from "react";

import ToastNotification from "../../../atoms/ToastNotification";
import { useAuthState } from "react-firebase-hooks/auth";
import {
    firebaseApp,
    getFirebaseAuthToken,
    auth,
} from "../../../../firebaseInit";
import axios from "axios";

import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    CircularProgress,
} from "@mui/material";
import { HiOutlineX, HiPlus } from "react-icons/hi";
import { useTheme } from "@mui/material/styles";
import { uploadToStorage } from "../../../../firestore/storageHandler";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { uploadFiles } from "../../../../axios";

const typeValArr = ["Male", "Female", "Others"];

const NewModelPopup = ({ isModelPopupOpen, toggleModelPopup }) => {
    const db = getFirestore(firebaseApp);

    const [user, loading] = useAuthState(auth);
    const [isTrainingLoading, setIsTrainingLoading] = useState(false);
    const [isImagesUploading, setIsImagesUploading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showImageProcessError, setShowImageProcessError] = useState(false);
    const [showTrainingError, setShowTrainingError] = useState(false);
    const [showExcessImagesPopup, setShowExcessImagesPopup] = useState(false);
    const [showValidationPopup, setShowValidationPopup] = useState(false);
    const [vaidationErrorText, setVaidationErrorText] = useState("");

    const [uploadImage, setUploadImage] = useState([]);
    const [uploadImageShowArr, setUploadImageShowArr] = useState([]);

    const openFile = useRef();
    const theme = useTheme();
    const [modelNameInputVal, setModelNameInputVal] = useState("");
    const [typeVal, setTypeVal] = useState("");

    const handleModelNameInputValChange = (e) => {
        setModelNameInputVal(e.target.value);
    };
    const handleTypeValChange = (e) => {
        setTypeVal(e.target.value);
    };

    let fileObj = [];
    let fileArray = [];
    let fileArray2 = [];

    /* const ImageHandler = (e) => {
        // Calculate total number of images after the current selection
        let totalImagesAfterSelection = fileObj.length + e.target.files.length;

        if (uploadImageShowArr.length > 0) {
            totalImagesAfterSelection =
                uploadImageShowArr.length + totalImagesAfterSelection;
        }

        for (let i = 0; i < e.target.files.length; i++) {
            fileObj.push(e.target.files[i]);
            fileArray.push(URL.createObjectURL(e.target.files[i]));
            fileArray2.push(e.target.files[i]);
        }
        console.log(fileObj, "fileObj"); // This should log all the selected files over multiple selections
        console.log(fileArray, "fileArrayfinal"); // This should log URLs for all the selected files over multiple selections
        console.log([...uploadImage, ...fileArray2], "setUploadImage");
        setUploadImage([...uploadImage, ...fileArray2]);
        console.log([...uploadImageShowArr, ...fileArray], "fileArrayfinal"); // This should log URLs for all the selected files over multiple selections
        setUploadImageShowArr([...uploadImageShowArr, ...fileArray]);
    }; */

    const ImageHandler = (e) => {
        setIsImagesUploading(true);

        //disbale the train model button
        let totalImagesAfterSelection = fileObj.length + e.target.files.length;

        if (uploadImageShowArr.length > 0) {
            totalImagesAfterSelection =
                uploadImageShowArr.length + totalImagesAfterSelection;
        }

        const convertToPNG = async (imageFile, index) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = URL.createObjectURL(imageFile);

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    canvas.toBlob((blob) => {
                        const pngFile = new File(
                            [blob],
                            `image-${index + 1}.png`,
                            {
                                type: "image/png",
                            }
                        );
                        resolve(pngFile);
                    }, "image/png");
                };
            });
        };

        const processSelectedImages = async () => {
            const pngImages = await Promise.all(
                Array.from(e.target.files).map(async (file, index) => {
                    console.log(file.type, "--file-type--");

                    if (
                        file.type === "image/png" ||
                        file.type === "image/jpg" ||
                        file.type === "image/jpeg"
                    ) {
                        console.log(
                            `Image "${file.name}" is already in PNG format.`
                        );
                        return file;
                    } else {
                        const pngFile = await convertToPNG(file, index);
                        console.log(
                            `Image "${file.name}" has been converted to PNG.`
                        );
                        return pngFile;
                    }
                })
            );

            fileObj.push(...pngImages);
            fileArray.push(
                ...pngImages.map((pngFile) => URL.createObjectURL(pngFile))
            );
            fileArray2.push(...pngImages);

            setUploadImage([...uploadImage, ...fileArray2]);
            setUploadImageShowArr([...uploadImageShowArr, ...fileArray]);
            console.log([...uploadImage, ...fileArray2], "fileArrayfinal23111");
            console.log(
                [...uploadImageShowArr, ...fileArray],
                "fileArrayfinal"
            );
            setIsImagesUploading(false);

            //enable the train model
            //console.log([...uploadImageShowArr, ...fileArray], "fileArrayfinal");
        };

        processSelectedImages();
    };

    const addDocToTunedModels = async (selfieUrl) => {
        let tunedModelDocRef;
        try {
            // setIsLoading(true);

            // setLoadingMsg("Sending Scenes over to AISEO ...");

            const dataToSubmit = {
                selfie: selfieUrl,
            };

            tunedModelDocRef = await addDoc(
                collection(db, "tuned_models"),
                dataToSubmit
            );
        } catch (err) {
            console.log("Firebase write error:" + err);
        }
        console.log("Document written with ID: ", tunedModelDocRef.id);
        return tunedModelDocRef.id;
    };

    // const sendTrainingData = async (downloadUrl, tunedModelDocId) => {
    //     console.log("Entered Training Data");
    //     console.log(downloadUrl, "Entered Training Data: downloadUrl");
    //     console.log(tunedModelDocId, "Entered Training Data: tunedModelDocId");

    //     try {
    //         const url =
    //             "https://us-central1-code-translate-c9640.cloudfunctions.net/trainSDXL"; // Replace with your

    //         const token = await getFirebaseAuthToken();
    //         console.log(token, "-- token found --");

    //         if (!token) {
    //             console.log("No token found");
    //             return;
    //         }

    //         const config = {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //             },
    //         };

    //         const modelNameDefault = "dilyarbuzan/aiseo-ai-avatar";
    //         console.log(modelNameInputVal, "modelNameInputVal");
    //         console.log(typeVal, "typeValGender");
    //         const requestBody = {
    //             title: modelNameInputVal,
    //             gender_type: typeVal,
    //             doc_id: tunedModelDocId,
    //             email: user.email,
    //             uid: user.uid,
    //             input_images: downloadUrl,
    //             api_type: "train_sdxl",
    //             model_name: modelNameDefault,
    //         };

    //         console.log(requestBody, "--request-body---");

    //         const response = await axios.post(url, requestBody, config);
    //         console.log("response.data: ", response.data);
    //         if (response.data.status === "ok") {
    //             setShowSuccessPopup(true);

    //             setIsTrainingLoading(false);

    //             setTimeout(() => {
    //                 setShowSuccessPopup(false);
    //             }, 3200);
    //             setTimeout(() => {
    //                 toggleModelPopup();
    //             }, 3300);
    //         }
    //     } catch (error) {
    //         console.error(error, " API call error ");
    //         setIsTrainingLoading(false);
    //         setIsTrainingLoading(false);
    //     }
    // };

    const sendTrainingData = async (downloadUrl, tunedModelDocId) => {
        console.log("Entered Training Data");
        console.log(downloadUrl, "Entered Training Data: downloadUrl");
        console.log(tunedModelDocId, "Entered Training Data: tunedModelDocId");

        let retryCount = 0;
        const maxRetries = 3; // Set your maximum retry limit here

        while (retryCount < maxRetries) {
            console.log(retryCount, "retryCount");
            try {
                const url =
                    "https://us-central1-code-translate-c9640.cloudfunctions.net/trainSDXL"; // Replace with your actual API endpoint

                const token = await getFirebaseAuthToken();
                console.log(token, "-- token found --");

                if (!token) {
                    console.log("No token found");
                    return;
                }

                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                };

                const modelNameDefault = "dilyarbuzan/aiseo-ai-avatar";
                console.log(modelNameInputVal, "modelNameInputVal");
                console.log(typeVal, "typeValGender");
                const requestBody = {
                    title: modelNameInputVal,
                    gender_type: typeVal,
                    doc_id: tunedModelDocId,
                    email: user.email,
                    uid: user.uid,
                    input_images: downloadUrl,
                    api_type: "train_sdxl",
                    model_name: modelNameDefault,
                };

                console.log(requestBody, "--request-body---");

                const response = await axios.post(url, requestBody, config);
                console.log("response.data: ", response.data);
                if (response.data.status === "ok") {
                    setShowSuccessPopup(true);

                    //toggleModelPopup();
                    setIsTrainingLoading(false);

                    setTimeout(() => {
                        setShowSuccessPopup(false);
                    }, 3200);
                    setTimeout(() => {
                        toggleModelPopup();
                    }, 3300);

                    break; // Exit the loop if the operation was successful
                }
            } catch (error) {
                console.error(error, " API call error ");
                setIsTrainingLoading(false);

                if (retryCount < maxRetries - 1) {
                    // If not reached the maximum retry limit, retry after a delay
                    retryCount++;
                    console.log(`Retrying (Attempt ${retryCount + 1})...`);
                    /* await new Promise((resolve) => setTimeout(resolve, 2000)); */ // Adjust the delay as needed
                } else {
                    console.error("Maximum retry limit reached");
                    setIsTrainingLoading(false);
                    setShowTrainingError(true);
                    setTimeout(() => {
                        setShowTrainingError(false);
                    }, 3200);
                    break; // Exit the loop if the maximum retry limit is reached
                }
            }
        }
    };

    const resetClientStates = () => {
        setModelNameInputVal("");
        setTypeVal("");
        fileObj = [];
        fileArray = [];
        fileArray2 = [];
        setUploadImage([]);
        setUploadImageShowArr([]);
    };

    /* const handleSubmit = async (e) => {
        if (
            modelNameInputVal === "" ||
            typeVal === "" ||
            uploadImageShowArr.length < 4
        ) {
            if (modelNameInputVal === "") {
                setVaidationErrorText("Please include a model name");
            } else if (typeVal === "") {
                setVaidationErrorText("Please set a gender type");
            } else {
                //setVaidationErrorText("You can only ");
                setVaidationErrorText("Please add atleast 4 images");
            }

            
            setShowValidationPopup(true);
            setTimeout(() => {
                setShowValidationPopup(false);
            }, 3200);
            return;
        }
        //return;
        setIsTrainingLoading(true);
        e.preventDefault();
        // setIsDisablebtn(true);
        // setIsSubmitClick(isSubmitClick + 1);

        console.log("--upload-image", uploadImage);

        // const submitData = () => {
        let zip = new JSZip();
        let data = zip.folder("data");
        uploadImage.forEach((item) => {
            data.file(item.name, item);
        });
        console.log(data, "--data--");
        console.log("uploadImage up zip", uploadImage);

        //return;

        let selfieUrl;
        const uploadResult = await uploadToStorage({
            file: uploadImage[0],
            uid: user.uid,
        }); // Replace 'your-uid' with the actual uid
        selfieUrl = await uploadResult.path;
        console.log(uploadResult, "--uploadResult--");
        console.log(selfieUrl, "--selfieUrl--");

        const tunedModelDocId = await addDocToTunedModels(selfieUrl);

        zip.generateAsync({ type: "blob" }).then((content) => {
            console.log(content, "--content--");

            uploadZipForAiAvatarModels({ uid: uuidv4(), file: content }).then(
                async (downloadUrl) => {
                    function formatUrl(url) {
                        // Step 1: Remove the query string
                        url = url.split("?")[0];

                        // https://storage.googleapis.com/code-translate-c9640.appspot.com/o/sdxl-tune/c3a7ac0a-fd0b-4115-b6d3-e241e3a079ac/data.zip

                        // Step 2: Replace the base URL
                        url = url.replace(
                            "https://firebasestorage.googleapis.com/v0/b/",
                            "https://storage.googleapis.com/"
                        );

                        url = url.replace("/o/", "/");

                        // Step 3: Replace '%2F' with '/'
                        url = url.replace(/%2F/g, "/");

                        return url;
                    }

                    const formattedDownloadUrl = formatUrl(downloadUrl.url);
                    console.log(
                        downloadUrl.url,
                        "--download zip url - achieved--"
                    );
                    console.log(
                        formattedDownloadUrl,
                        "--download zip url formatted - achieved--"
                    );

                    if (downloadUrl.url) {
                        console.log(
                            formattedDownloadUrl,
                            "--formattedDownloadUrl--"
                        );
                        await sendTrainingData(
                            formattedDownloadUrl,
                            tunedModelDocId
                        );
                    }
                }
            );
        });
    };
 */
    /* const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            modelNameInputVal === "" ||
            typeVal === "" ||
            uploadImageShowArr.length < 4
        ) {
            let errorText = "Please add at least 4 images";
            if (modelNameInputVal === "") {
                errorText = "Please include a model name";
            } else if (typeVal === "") {
                errorText = "Please set a gender type";
            }

            setVaidationErrorText(errorText);
            setShowValidationPopup(true);
            setTimeout(() => {
                setShowValidationPopup(false);
            }, 3200);
            return;
        }

        setIsTrainingLoading(true);

        try {
            console.log("--upload-image", uploadImage);
            let zip = new JSZip();
            let data = zip.folder("data");

            uploadImage.forEach((item) => {
                data.file(item.name, item);
            });
            console.log(data, "--data--");
            console.log("uploadImage up zip state - ", uploadImage);

            const uploadResult = await uploadToStorage({
                file: uploadImage[0],
                uid: user.uid,
            });
            const selfieUrl = uploadResult.path;
            console.log(uploadResult, "--uploadResult--");
            console.log(selfieUrl, "--selfieUrl--");

            //const tunedModelDocId = await addDocToTunedModels(selfieUrl);

            const content = await zip.generateAsync({ type: "blob" });

            const contentSize = content.size; // Get the blob size
            console.log(content, "--content--");

            console.log(`Zipped file size: ${contentSize} bytes`); // Log the zipped file size

            const downloadUrl = await uploadZipForAiAvatarModels({
                uid: uuidv4(),
                file: content,
            });
            const formattedDownloadUrl = formatUrl(downloadUrl.url);
            console.log(downloadUrl.url, "--download zip url - achieved--");
            console.log(
                formattedDownloadUrl,
                "--download zip url formatted - achieved--"
            );

            if (downloadUrl.url) {
                console.log(formattedDownloadUrl, "--formattedDownloadUrl--");
                //await sendTrainingData(formattedDownloadUrl, tunedModelDocId);
            }
        } catch (error) {
            console.error("Error during submission", error);
            // Handle the error or rethrow it if needed
            // You might also want to show a notification to the user
        } finally {
            setIsTrainingLoading(false);
        }
    }; */

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            modelNameInputVal === "" ||
            typeVal === "" ||
            uploadImageShowArr.length < 4
        ) {
            let errorText = "Please add at least 4 images";
            if (modelNameInputVal === "") {
                errorText = "Please include a model name";
            } else if (typeVal === "") {
                errorText = "Please set a gender type";
            }

            setVaidationErrorText(errorText);
            setShowValidationPopup(true);
            setTimeout(() => {
                setShowValidationPopup(false);
            }, 3200);
            return;
        }

        setIsTrainingLoading(true);

        try {
            const formData = new FormData();

            uploadImage.forEach((image, index) => {
                formData.append(`image${index}`, image);
            });

            // Convert formData to a plain JavaScript object for logging
            const formDataObject = {};
            formData.forEach((value, key) => {
                formDataObject[key] = value;
            });

            console.log(typeof formData, "--formDataObject--"); // Log the formData object
            console.log(formDataObject, "--formDataObject--");

            const uploadResult = await uploadToStorage({
                file: uploadImage[0],
                uid: user.uid,
            });
            const selfieUrl = uploadResult.path;
            console.log(uploadResult, "--uploadResult--");
            console.log(selfieUrl, "--selfieUrl--");

            const tunedModelDocId = await addDocToTunedModels(selfieUrl);

            uploadFiles(formData)
                .then(async (resp) => {
                    console.log(resp);
                    console.log(resp.data);

                    if (resp.data.status === "ok") {
                        if (resp.data.downloadUrl) {
                            console.log(
                                resp.data.downloadUrl,
                                "--formattedDownloadUrl--"
                            );
                            await sendTrainingData(
                                resp.data.downloadUrl,
                                tunedModelDocId
                            );
                        }
                    } else {
                        setShowTrainingError(true);
                        setTimeout(() => {
                            setShowTrainingError(false);
                        }, 3200);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.error("Error during submission", error);
                    setShowTrainingError(true);
                    setTimeout(() => {
                        setShowTrainingError(false);
                    }, 3200);
                });

            //return;

            //todos
            //1. make the api call function to get the download zipped url
            //2. and then move to next part

            // let contentSize;
            // let content;
            // let retry = 0;
            // const maxRetryLimit = 5;
            // let isRetryLimitReachedError = false;
            // let tunedModelDocId;

            // while (!contentSize || contentSize < 3 * 1024) {
            //     if (retry > maxRetryLimit) {
            //         console.log("max retry limit reached");
            //         isRetryLimitReachedError = true;
            //         setShowImageProcessError(true);
            //         setTimeout(() => {
            //             setShowImageProcessError(false);
            //         }, 3200);
            //         break;
            //     }
            //     console.log("--try count: ", retry);
            //     console.log("--upload-image", uploadImage);
            //     let zip = new JSZip();
            //     let data = zip.folder("data");
            //     uploadImage.forEach((item) => {
            //         data.file(item.name, item);
            //     });
            //     console.log(data, "--data--");
            //     console.log("uploadImage up zip state - ", uploadImage);

            //     const uploadResult = await uploadToStorage({
            //         file: uploadImage[0],
            //         uid: user.uid,
            //     });
            //     const selfieUrl = uploadResult.path;
            //     console.log(uploadResult, "--uploadResult--");
            //     console.log(selfieUrl, "--selfieUrl--");

            //     tunedModelDocId = await addDocToTunedModels(selfieUrl);

            //     content = await zip.generateAsync({ type: "blob" });
            //     contentSize = content.size; // Get the blob size
            //     console.log(content, "--content--");

            //     console.log(`Zipped file size: ${contentSize} bytes`); // Log the zipped file size
            //     retry++;
            // }

            // //return;

            // if (!isRetryLimitReachedError) {
            //     console.log("Limit not reached");

            //     //return;
            //     const downloadUrl = await uploadZipForAiAvatarModels({
            //         uid: uuidv4(),
            //         file: content,
            //     });
            //     const formattedDownloadUrl = formatUrl(downloadUrl.url);
            //     console.log(downloadUrl.url, "--download zip url - achieved--");
            //     console.log(
            //         formattedDownloadUrl,
            //         "--download zip url formatted - achieved--"
            //     );

            //     if (downloadUrl.url) {
            //         console.log(
            //             formattedDownloadUrl,
            //             "--formattedDownloadUrl--"
            //         );
            //         await sendTrainingData(
            //             formattedDownloadUrl,
            //             tunedModelDocId
            //         );
            //     }
            // } else {
            //     console.log("Limit reached");
            // }
        } catch (error) {
            console.error("Error during submission", error);
            setShowTrainingError(true);
            setTimeout(() => {
                setShowTrainingError(false);
            }, 3200);
            // Handle the error or rethrow it if needed
            // You might also want to show a notification to the user
        } finally {
            //setIsTrainingLoading(false);
        }
    };

    function formatUrl(url) {
        url = url.split("?")[0];
        url = url.replace(
            "https://firebasestorage.googleapis.com/v0/b/",
            "https://storage.googleapis.com/"
        );
        url = url.replace("/o/", "/");
        url = url.replace(/%2F/g, "/");
        return url;
    }

    const mockApiCall = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    status: 200,
                    data: "Mocked API data",
                });
            }, 5000); // 5 seconds delay
        });
    };

    const openFilehandler = () => {
        openFile.current.click();
    };

    const handleRemoveImage = (indexToRemove) => {
        const newImages = uploadImageShowArr.filter(
            (_, index) => index !== indexToRemove
        );
        setUploadImageShowArr(newImages);
        console.log(newImages, "--newimages--");

        const newImages2 = uploadImage.filter(
            (_, index) => index !== indexToRemove
        );
        setUploadImage(newImages2);
        console.log(newImages2, "--newimages2--");

        const newObjArr = [...fileObj].filter(
            (item, index) => index !== indexToRemove
        );
        fileObj = [...newObjArr];
    };

    return (
        <Box
            sx={{
                background: "rgba(27, 15, 70, 0.6)",
                backdropFilter: "blur(2px)",
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
                bottom: "0",
                zIndex: 101,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {showImageProcessError && (
                <ToastNotification
                    message="Error occurred during Image processing. Please try again!"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showTrainingError && (
                <ToastNotification
                    message="Error occurred while training the model. Please try again!"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showSuccessPopup && (
                <ToastNotification
                    message="Model is being trained. You will be notified ..."
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showValidationPopup && (
                <ToastNotification
                    message={vaidationErrorText}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {/* {showExcessImagesPopup && (
                <ToastNotification
                    message="Do not select more than 4 images"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )} */}
            <Box
                sx={{
                    background: "#F8FAFC",
                    width: { xs: "90vw", md: "50vw" },
                    height: { xs: "90vh", md: "75vh" },
                    /* height: { xs: "90vh", md: "650px" }, */
                    borderRadius: "10px",
                    padding: { xs: "1rem", md: "65px 110px" },
                    position: "relative",
                    overflowY: "scroll",
                }}
            >
                <IconButton
                    sx={{
                        position: "absolute",
                        right: "0.8rem",
                        top: "0.8rem",
                    }}
                    onClick={toggleModelPopup}
                >
                    <HiOutlineX size={20} />
                </IconButton>
                <Typography
                    sx={{
                        color: "#0F172A",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: { xs: "1.2rem", md: "30px" },
                        fontWeight: "700",
                        lineHeight: "36px" /* 120% */,
                        letterSpacing: "-0.225px",
                        marginBottom: "1.6rem",
                    }}
                >
                    Model Information
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        marginBottom: "1rem",
                        flexDirection: { xs: "column", md: "row" },
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                }}
                            >
                                Model Name (required)
                            </Typography>
                        </Box>
                        <TextField
                            placeholder="What's the name of the model?"
                            value={modelNameInputVal}
                            onChange={handleModelNameInputValChange}
                            sx={{
                                width: "100%",
                                marginTop: "10px",
                                "& > div": {
                                    borderRadius: "8px",
                                    borderColor: "#EAEFFF",
                                    padding: "0rem",
                                    outline: "2px solid #EAEFFF",
                                },

                                background: "white",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                }}
                            >
                                Type (required)
                            </Typography>
                        </Box>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            sx={{
                                marginTop: "10px",
                                "& > div": {
                                    borderRadius: "8px",
                                    outline: "2px solid #EAEFFF",
                                },
                                background: "white",
                            }}
                        >
                            <Select
                                labelId="typeVal-label"
                                value={typeVal}
                                onChange={handleTypeValChange}
                                /* label="Select CFG Value" */
                                sx={{}}
                            >
                                {typeValArr.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    sx={{
                        margin: "25px 0",
                        borderRadius: "9px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "white",
                        border: "2px solid #EAEFFF",
                        /* height: "220px", */
                        height: "fit-content",
                        flexDirection: "column",
                        padding: "2rem 0",
                    }}
                >
                    {uploadImageShowArr.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1rem",
                                marginBottom: "2rem",
                                maxWidth: "100%",
                                flexWrap: "wrap",
                                height:
                                    uploadImageShowArr.length > 7
                                        ? "120px"
                                        : "170px",
                                overflowY:
                                    uploadImageShowArr.length > 7
                                        ? "scroll"
                                        : "hidden",
                                overflowX: "hidden",
                            }}
                        >
                            {uploadImageShowArr.map((imageItem, imageIndex) => (
                                <Box
                                    key={imageIndex}
                                    sx={{
                                        width: "70px",
                                        height: "70px",
                                        position: "relative",
                                        marginBottom: "25px",
                                    }}
                                >
                                    <img
                                        className="content-image"
                                        style={{
                                            width: "150px",
                                            height: "100px",
                                            objectFit: "cover",
                                            padding: "3px",
                                            borderRadius: "10px",

                                            border: "1px solid #e6e6e6",
                                            marginLeft: "5px",
                                            marginBottom: "5px",
                                        }}
                                        alt="single"
                                        src={imageItem}
                                    />
                                    <IconButton
                                        sx={{
                                            background: "#fef2f2",
                                            color: "#ef4444",
                                            "&:hover": {
                                                background: "#ef4444",
                                                color: "white",
                                            },
                                            position: "absolute",
                                            top: "-5px",
                                            right: "-5px",
                                        }}
                                        onClick={() =>
                                            handleRemoveImage(imageIndex)
                                        }
                                    >
                                        <HiOutlineX size={15} />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            sx={{
                                textTransform: "capitalize",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.3rem",
                                background:
                                    theme.palette.mode === "light"
                                        ? "#1C1917"
                                        : "#6464FF",
                                width: "fit-content",
                                fontWeight: "bold",
                                paddingX: "1.2rem",

                                "&:hover": {
                                    background:
                                        theme.palette.mode === "light"
                                            ? "#6464FF"
                                            : "#4848C2",
                                },
                                paddingY: "1rem",
                                borderRadius: "0.7rem",
                                "&:disabled": {
                                    background: "grey",
                                    cursor: "not-allowed",
                                },
                            }}
                            onClick={openFilehandler}
                            /* disabled={
                                uploadImageShowArr.length === 4 ? true : false
                            } */
                        >
                            {isImagesUploading ? (
                                <span>Uploading..</span>
                            ) : (
                                <span>Upload Photos</span>
                            )}
                            {isImagesUploading ? (
                                <CircularProgress
                                    size={20}
                                    sx={{ color: "white" }}
                                />
                            ) : (
                                <HiPlus size={20} />
                            )}
                        </Button>
                        <Typography
                            sx={{
                                marginTop: "1rem",
                            }}
                        >
                            Select at least 4 photos
                        </Typography>
                        <input
                            style={{ display: "none" }}
                            // accept=".jpg,.jpeg,.png"
                            type="file"
                            name="init_image"
                            multiple
                            onChange={(e) => ImageHandler(e)}
                            ref={openFile}
                        />
                    </Box>
                </Box>
                <Box>
                    <Button
                        sx={{
                            background:
                                "linear-gradient(47deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                            /* background:
                                        "linear-gradient(75deg, #EF54FE 0%, #8980FF 57.81%, #62B5FF 100%)", */
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: "11px",
                            textTransform: "capitalize",
                            fontWeight: "600",
                            transform: "transition all 0.3s ease-in",

                            "&:hover": {
                                background:
                                    "linear-gradient(27deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                            },
                            width: "fit-content",
                            padding: "1rem 1.5rem",
                            margin: "0 auto",
                            borderRadius: "0.7rem",
                            "&:disabled": {
                                background: "#1F2937",
                                color: "#64748B",
                                cursor: "no-drop !important",
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            },
                            pointerEvents: isTrainingLoading ? "none" : "auto",
                        }}
                        onClick={handleSubmit}
                        disabled={isImagesUploading ? true : false}
                    >
                        <Typography
                            sx={{
                                fontWeight: "600",
                                letterSpacing: "0.1rem",
                            }}
                        >
                            Train The Model
                        </Typography>
                        {isTrainingLoading && (
                            <CircularProgress
                                size={12}
                                sx={{
                                    color: "white",
                                }}
                            />
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NewModelPopup;
