import React, { useState, useEffect } from "react";
import AnotherAudioLoader from "./AnotherAudioLoader.svg";
import {
    Container,
    Typography,
    Box,
    Button,
    Divider,
} from "@mui/material";
import {
    HiOutlineMusicNote,
} from "react-icons/hi";
import Layout from "../layout/Layout";
import Footer from "../footer/Footer";
import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "../../firebaseInit";
import CustomAudioPlayer from "./CustomAudioPlayer";

const MyAudios = ({ user }) => {
    const db = getFirestore(firebaseApp);

    const [myAudiosData, setMyAudiosData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        async function getDocumentsByEmail() {
            setIsLoading(true);
            const q = query(
                collection(db, "audios"),
                where("user_email", "==", user.email)
            );

            const querySnapshot = await getDocs(q);

            let docs = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                docs.push({ id: doc.id, ...doc.data() });
            });

            setMyAudiosData(docs);
            setIsLoading(false);

            console.log(docs);
        }
        if (user?.email) {
            getDocumentsByEmail();
        }
    }, [user]);

    const itemsPerPage = 3; // or however many you want per page

    const totalPages = Math.ceil(myAudiosData.length / itemsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return (
        <Layout footer={<Footer />}>
            <Container
                sx={{
                    padding: {
                        xs: "0.7rem 0.5rem",
                        md: "0.8rem 0",
                    },
                }}
            >
                <Box sx={{ padding: "1rem 0" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem",
                            marginBottom: "0.7rem",
                        }}
                    >
                        <HiOutlineMusicNote size={30}></HiOutlineMusicNote>
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                        >
                            My Audios
                        </Typography>
                    </Box>
                    {isLoading ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                alt=""
                                src={AnotherAudioLoader}
                                style={{
                                    width: "50%",
                                    height: "50%",
                                }}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                borderRadius: "1rem",
                                border: {
                                    xs: "0px",
                                    md: "2px solid rgba(145, 158, 171, 0.4)",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: "none", md: "flex" },
                                    padding: "1rem 1.5rem",
                                    borderBottom:
                                        "1.5px solid rgba(211, 211, 211, 0.7)",
                                }}
                            >
                                <Box sx={{ width: "50%" }}>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Prompt
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "20%" }}>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Status
                                    </Typography>
                                </Box>
                                <Box sx={{ width: "30%" }}>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Media
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                            {myAudiosData
                                .slice(startIndex, endIndex)
                                .map((item, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            flexDirection: {
                                                xs: "column-reverse",
                                                md: "row",
                                            },
                                            padding: "1rem 1.5rem",
                                            gap: "0.6rem",
                                            borderBottom:
                                                index < myAudiosData.length - 1
                                                    ? "1.5px solid rgba(211, 211, 211, 0.7)"
                                                    : "",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    md: "50%",
                                                },
                                            }}
                                        >
                                            <Typography>
                                                {/* <Typography
                                                    sx={{
                                                        display: {
                                                            xs: "inline-block",
                                                            md: "none",
                                                        },
                                                    }}
                                                >
                                                    Promt:
                                                </Typography>{" "} */}
                                                {item.initialPrompt}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    md: "20%",
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    background:
                                                        "rgba(111, 111, 250, 0.2)",
                                                    padding: "0.3rem",
                                                    borderRadius: "0.7rem",
                                                    width: "fit-content",
                                                }}
                                            >
                                                {item.status}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    md: "30%",
                                                },
                                            }}
                                        >
                                            {/* <audio
                                        src={item.generated_audio_url}
                                        controls
                                        autoplay
                                        style={{
                                            width: "100%",
                                        }}
                                    ></audio> */}
                                            <CustomAudioPlayer
                                                audioSrc={
                                                    item.generated_audio_url
                                                }
                                            />
                                        </Box>
                                    </Box>
                                ))}
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "end",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",

                                        alignItems: "center",
                                        padding: "1rem",
                                        width: "fir-content",
                                        gap: "0.4rem",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <Button
                                        onClick={handlePrev}
                                        disabled={currentPage === 1}
                                        sx={{
                                            textTransform: "capitalize",
                                            /* background: "white", */
                                            border: "1.8px solid #AAAAEF",
                                        }}
                                    >
                                        Prev
                                    </Button>
                                    <Typography
                                        sx={{
                                            padding: "0.3rem",
                                            borderRadius: "0.4rem",
                                            background: "#D6D6FF",
                                            color: "#6464FF",
                                            width: "2rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        {currentPage}
                                    </Typography>
                                    <Button
                                        onClick={handleNext}
                                        disabled={currentPage === totalPages}
                                        sx={{
                                            textTransform: "capitalize",
                                            border: "1.8px solid #AAAAEF",
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </Layout>
    );
};

export default MyAudios;
