import {
    doc,
    getDoc,
    getFirestore,
    setDoc,
    updateDoc,
    collection,
    where,
    query,
    getDocs,
} from "firebase/firestore";
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    uploadString,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { firebaseApp } from "../firebaseInit";

const db = getFirestore(firebaseApp);
const storage = getStorage();
/**
 * @desc save used uploaded image name to firestore
 * @param {{ uid: string, uuid: string }} param0
 */

async function saveImagePath({ uid, uuid }) {
    const docRef = doc(db, "uploads", uid);
    const snap = await getDoc(docRef);
    console.log("Firestore Updating");
    if (snap.exists()) {
        const data = snap.data();
        const paths = data.imageIds;
        await updateDoc({
            imageIds: [...paths, uuid],
        });
    } else {
        await setDoc({
            imageIds: [uuid],
        });
    }
}

export async function uploadToStorage({ file, uid }) {
    console.log(file.type, " Image File Type");
    let ext = file.type ? file.type.split("/")[1] : "";
    const uuid = uuidv4();
    const storageRef = ref(storage, "uploads/" + uuid + "." + ext);
    const path = `https://storage.googleapis.com/code-translate-c9640.appspot.com/uploads/${uuid}.${ext}`;

    return new Promise((resolve, reject) => {
        uploadBytes(storageRef, file, {
            contentDisposition: `attachment; filename="${uuid}.${ext}"`,
        }).then((snapshot) => {
            console.log("Upload Done");
            getDownloadURL(storageRef).then((url) => {
                resolve({ url, path });
            });
        });
    });
}

// const reduceTheCredit = async (cost) => {
//     if (!cost) return;

//     const email = auth.currentUser.email;
//     console.log("CURRENT EMAIL", email);
//     const docRef = doc(db, "arts", email);
//     const snap = await getDoc(docRef);

//     if (snap.exists()) {
//         let data = snap.data();
//         if (data.credits) {
//             let credits = data.credits;
//             //* dont call update if credits are unlimited
//             if (isNaN(credits)) return;
//             let finalCredit = credits - cost;
//             console.log("FINAL COST", finalCredit);
//             await updateDoc(docRef, { credits: finalCredit });
//         }
//     } else {
//         // doc doesn't exist
//     }
// };

async function storeImageDetails(obj, email) {
    const docRef = doc(db, "generations", email);
    const snap = await getDoc(docRef);
    if (snap.exists()) {
        let data = snap.data();
        if (data.created) {
            let arrayList = [obj, ...data.created];
            await updateDoc(docRef, { created: arrayList });
        } else {
            await setDoc(docRef, { created: [obj] });
        }
    } else {
        await setDoc(docRef, { created: [obj] });
    }
}

export async function toolsDetailsbyCat({ email, category, image }) {
    const docRef = doc(db, "tools", email);
    const toolsName = category;
    const snap = await getDoc(docRef);
    console.log("Category details: ", snap);

    if (snap.exists()) {
        let data = snap.data();
        console.log("Data: ", data);

        // data.tools == animaa = {

        // }

        // find toolsName is exit or not
        const isExist = data.tools.find((item) => item.category === toolsName);
        console.log("isExist", isExist);
        return isExist;
    }
}

export async function storeToolsDetails({ email, prompt, category, image }) {
    console.log(email, "Email");
    const toolsName = category;
    const obj = {};
    const docRef = doc(db, "tools", email);

    // const q = query(collection(db, "tools"), where("category", "==", "Anime"));

    const snap = await getDoc(docRef);

    // const querySnapshot = await getDocs(q);
    // console.log("querySnapshot", querySnapshot);
    // console.log("querySnapshot - Q: ", q);

    // const subcollectionRef = docRef.collection("category");

    // subcollectionRef.add({
    //   name: "John",
    //   age: 30,
    //   email: "john@example.com",
    // });

    if (snap.exists()) {
        let data = snap.data();
        console.log("Data: ", data);

        // data.tools == animaa = {

        // }

        // find toolsName is exit or not
        const isExist = data.tools.find((item) => item.category === toolsName);
        console.log("isExist", isExist);

        if (isExist) {
            const isSeleected = data.tools.filter(
                (item) => item.category !== toolsName
            );

            const newExistedData = {
                ...isExist,
                generatedImage: [
                    ...isExist.generatedImage,
                    {
                        image: image,
                        prompt: prompt,
                    },
                ],
            };

            const newTools = [newExistedData, ...isSeleected];
            console.log("Tools isExist : ", isExist);
            console.log("Tools All : ", data.tools);
            console.log("Tools isSeleected : ", isSeleected);

            await updateDoc(docRef, {
                tools: [...newTools],
            });
        } else {
            await setDoc(docRef, {
                tools: [
                    ...data.tools,
                    {
                        category: toolsName,
                        generatedImage: [
                            {
                                image: image,
                                prompt: prompt,
                            },
                        ],
                    },
                ],
            });
        }

        // if (data.tools.generatedImage) {
        //   let generatedImageArray = [
        //     ...data.tools.generatedImage,
        //     "http://localhost:3000/static/media/anim.c812a583d0f07a40dad0.png",
        //   ];

        // } else {
        //   await setDoc(docRef, { created: [obj] });
        // }
    } else {
        await setDoc(docRef, {
            email: email,
            tools: [
                {
                    category: toolsName,
                    generatedImage: [
                        {
                            image: image,
                            prompt: prompt,
                        },
                    ],
                },
            ],
        });
    }
}

// @@DESC
// this take the base64 and save it on firestore...
export async function uploadGenerateImageToStorage(param) {
    const {
        imageString,
        prompt,
        email,
        id,
        folderName,
        seed,
        cfgScale,
        height,
        width,
        steps,
    } = param;

    const storageRef = ref(storage, "generations/" + id + ".png");
    const path =
        "https://storage.googleapis.com/code-translate-c9640.appspot.com/generations/" +
        id +
        ".png";

    // upload on store...
    //
    uploadString(storageRef, imageString, "data_url").then((snapshot) => {
        console.log("Uploaded a base64 string!", snapshot);
        // get the download url..
        getDownloadURL(storageRef).then((url) => {
            console.log("DOWNLOAD URL:", url);
            console.log("DOWNLOAD Path:", path);
            let name = snapshot.metadata.name;

            let obj = {
                prompt,
                seed,
                cfgScale,
                steps,
                width,
                height,
                src: path,
                id: name,
                createdAt: new Date().getTime(),
            };

            if (folderName) {
                obj.folderName = folderName;
            }

            console.log("OBJ", obj);
            storeImageDetails(obj, email);
        });
    });
}

export async function deleteImageFromStorage(imageId, email) {
    // Create a reference to the file to delete
    console.log(imageId);
    const desertRef = ref(storage, "generations/" + imageId);
    return new Promise((resolve, reject) => {
        // Delete the file
        deleteObject(desertRef)
            .then(async (res) => {
                // File deleted successfully
                console.log("image deleted successfully");
                // also Remove from firebase...
                const docRef = await doc(db, "generations", email);
                const snap = await getDoc(docRef);
                if (snap.exists()) {
                    let data = snap.data();
                    if (data.created) {
                        // let arrayList = [obj, ...data.created];
                        let filteredList = data.created.filter(
                            (each) => each.id !== imageId
                        );
                        console.log("filterList", filteredList);
                        await updateDoc(docRef, { created: filteredList }).then(
                            (res) => {
                                resolve(res);
                            }
                        );
                    }
                }
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
                console.log("showing error", error);
                reject(error);
            });
    });
}

export async function uploadForDreambooth({ uid, file }) {
    const storageRef = ref(storage, `dreambooth/${uid}/data.zip`);

    return new Promise((resolve, reject) => {
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log("Upload Done");
            getDownloadURL(storageRef).then((url) => {
                resolve({ url });
            });
        });
    });
}

export async function uploadZipForAiAvatarModels({ uid, file }) {
    const storageRef = ref(storage, `sdxl-tune/${uid}/data.zip`);

    return new Promise((resolve, reject) => {
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log("Upload Done");
            getDownloadURL(storageRef).then((url) => {
                console.log(storageRef, "--storageRef--");
                resolve({ url });
            });
        });
    });
}

/**
 * @param {{ data: string, ext: string }} param0
 * param0.data represents base64 data url of image
 * param0.ext represents type of image (e.g jpeg)
 * @returns
 */
export async function uploadProductImage({ data, ext }) {
    const uuid = uuidv4();
    const storageRef = ref(storage, "uploads/" + uuid + "." + ext);
    const path = `https://storage.googleapis.com/code-translate-c9640.appspot.com/products/${uuid}.${ext}`;
    return new Promise((resolve, reject) => {
        uploadString(storageRef, data, "data_url", {
            contentDisposition: `attachment; filename="${uuid}.${ext}"`,
        }).then(() => {
            getDownloadURL(storageRef).then((url) => {
                resolve({ url, path });
            });
        });
    });
}
