import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   states
  selectedImageInpainting: "",
  selectedImageMask: "",
  inpaintedImage: "",
  inpainting: false,
  generatedinppaintedImage: [],
  error: "",
  canvasWidth: 512,
  canvasHeight: 512,

  //command
  uploadedImage: [],
  selectedImages: null,
  generatedImages: "",
};

export const CommandSlice = createSlice({
  name: "CommandSlice",
  initialState,
  reducers: {
    fileUploaded: (state, action) => {
      state.selectedImageInpainting = action.payload.selectedImageInpainting;
      state.inpaintedImage = "";
      state.canvasWidth = action.payload.canvasWidth;
      state.canvasHeight = action.payload.canvasHeight;
    },
    inpaintingStarted: (state, action) => {
      state.inpainting = true;
      state.error = "";
      state.inpaintedImage = "";
    },
    inpaintingSucceed: (state, action) => {
      state.inpaintedImage = action.payload.inpaintedImage;
    },
    inpaintingFaild: (state, action) => {
      state.inpainting = false;
      state.error = action.payload.error;
    },
    setSelectedImageInpainting: (state, action) => {
      state.selectedImageInpainting = action.payload.selectedImageInpainting;
    },
    setSelectedImageMask: (state, action) => {
      state.selectedImageMask = action.payload.selectedImageMask;
    },

    setGeneratedInppaintedImage: (state, action) => {
      // array pathabo
      state.generatedinppaintedImage = action.payload.generatedinppaintedImage;
    },
    setInpaintedImage: (state, action) => {
      state.inpaintedImage = action.payload.inpaintedImage;
    },
    setInpainting: (state, action) => {
      state.inpainting = action.payload.inpainting;
    },


    // command slices
    setUploadedImageOnStore: (state, action) => {
      // append on array
      state.uploadedImage = action.payload.uploadedImage;
    },
    setSelectedImageOnStore: (state, action) => {
      // append on array
      state.selectedImages = action.payload.selectedImages;
    },
    setGeneratedImagesImageOnStore: (state, action) => {
      // append on array
      state.generatedImages = action.payload.generatedImages;
    },
    
    
  },
});

export const {
  setSelectedImageInpainting,
  setSelectedImageMask,
  setInpaintedImage,
  setGeneratedInppaintedImage,
  fileUploaded,
  inpaintingStarted,
  inpaintingSucceed,
  inpaintingFaild,
  setInpainting,

  // Commandslice
  setSelectedImageOnStore,
  setUploadedImageOnStore,
  setGeneratedImagesImageOnStore

} = CommandSlice.actions;
export default CommandSlice.reducer;
