import React from "react";
import {
    Typography,
    Box,
} from "@mui/material";

const UseCaseOptionsBox = ({
    useCaseArr,
    activeUseCase,
    handleUseCaseOptionClick,
}) => {
    return (
        <Box
            sx={{
                marginTop: "1.5rem",
            }}
        >
            <Typography
                sx={{
                    marginBottom: "0.8rem",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                }}
            >
                Use Cases
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    overflowX: "scroll",
                }}
                style={{
                    gap: "0.9rem",
                }}
            >
                {useCaseArr.map((item, index) => (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            background:
                                activeUseCase.toLowerCase() ===
                                item.title.toLowerCase()
                                    ? "#6363FF"
                                    : "#E6E6FF",
                            color:
                                activeUseCase.toLowerCase() ===
                                item.title.toLowerCase()
                                    ? "white"
                                    : "#6363FF",
                            padding: "0.4rem 1.1rem",
                            borderRadius: "100px",
                            cursor: "pointer",
                            width: "fit-content",

                            "&:hover": {
                                background: "#6363FF",
                                color: "white",
                            },
                        }}
                        style={{ gap: "0.34rem" }}
                        onClick={() => handleUseCaseOptionClick(item.title)}
                    >
                        {item.icon}
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                letterSpacing: "0.01rem",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {item.title}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default UseCaseOptionsBox;
