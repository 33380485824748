import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activefolder: "",
    folders: [],
    modelParams: {
        cfgScale: 7.5,
        seed: null,
        steps: null
    }
};

export const configSilce = createSlice({
    name: "config",
    initialState,
    reducers: {
        setConfig: (state, action) => {
            console.log(action.payload.name, "action.payload.name");
            console.log(action.payload.value, "action.payload.value")
            state[action.payload.name] = action.payload.value;
        },
        setFolder: (state, action) => {
            state.activefolder = action.payload.activefolder;
            state.folders = [action.payload.activefolder, ...state.folders,];
        },
    },
});

export const { setConfig, setFolder } = configSilce.actions;
export default configSilce.reducer;
