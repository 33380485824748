import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    IconButton,
} from "@mui/material";
import mixpanel from "mixpanel-browser";
import {

    HiOutlineX,
} from "react-icons/hi";

import {
    addDoc,
    collection,
    getFirestore,

} from "firebase/firestore";
import { firebaseApp, auth } from "../../firebaseInit";
import { useAuthState } from "react-firebase-hooks/auth";

const HeadshotsFeedbackModal = ({
    showFeedbackModal,
    setShowFeedbackModal,
    newHeadshotsDocId,
}) => {
    const [user, loading] = useAuthState(auth);
    const [unsatisfactoryReasons, setUnsatisfactoryReasons] = useState(
        "Appearance doesn’t resemble the input image"
    );
    const [howToImprove, setHowToImprove] = useState(
        "Provide more customization options"
    );
    const [useAgain, setUseAgain] = useState("Maybe, if the accuracy is enhanced");

    const [unsatisfactoryOtherInput, setUnsatisfactoryOtherInput] =
        useState("");
    const [howToImproveOtherInput, setHowToImproveOtherInput] = useState("");

    const [showUnsatisfactoryOtherInput, setShowUnsatisfactoryOtherInput] =
        useState(false);
    const [showHowToImproveOtherInput, setShowHowToImproveOtherInput] =
        useState(false);

    const [feedback, setFeedback] = useState({
        unsatisfactoryReasons: "",
        howToImprove: "",
        useAgain: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFeedback((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Submit the feedback to a server or Firebase
        console.log({
            unsatisfactoryReasons: showUnsatisfactoryOtherInput
                ? unsatisfactoryOtherInput
                : unsatisfactoryReasons,
            howToImprove: showHowToImproveOtherInput
                ? howToImproveOtherInput
                : howToImprove,
            useAgain: useAgain,
            headshotDocId: newHeadshotsDocId,
        });

        let apiInputData = {
            unsatisfactoryReasons: showUnsatisfactoryOtherInput
                ? unsatisfactoryOtherInput
                : unsatisfactoryReasons,
            howToImprove: showHowToImproveOtherInput
                ? howToImproveOtherInput
                : howToImprove,
            useAgain: useAgain,
            headshotDocId: newHeadshotsDocId,
            userEmail: user.email,
        };

        try {
            const collectionRef = collection(
                getFirestore(firebaseApp),
                "HeadshotsFeedbacks"
            );
            const docRef = await addDoc(collectionRef, apiInputData);
            console.log("Document written with ID: ", docRef.id);
            mixpanel.track("Submitted Feedback Headshot", {
                Description:
                    "Headshots - page - Submitted Feedback headshots",
            });
            setShowFeedbackModal(false);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleUnsatisfactoryReasonsChange = (event) => {
        const { name, value } = event.target;
        setUnsatisfactoryReasons(value);
        if (value === "Other") {
            setShowUnsatisfactoryOtherInput(true);
        } else {
            setShowUnsatisfactoryOtherInput(false);
            setUnsatisfactoryOtherInput("");
        }
    };
    const handleHowToImproveChange = (event) => {
        const { name, value } = event.target;
        setHowToImprove(value);
        if (value === "Other") {
            setShowHowToImproveOtherInput(true);
        } else {
            setShowHowToImproveOtherInput(false);
            setHowToImproveOtherInput("");
        }
    };
    const handleUseAgainChange = (event) => {
        const { name, value } = event.target;
        setUseAgain(value);
    };

    const handleUnsatisfactoryOtherInputChange = (event) => {
        setUnsatisfactoryOtherInput(event.target.value);
    };
    const handleHowToImproveOtherInputChange = (event) => {
        setHowToImproveOtherInput(event.target.value);
    };

    const handleClose = () => {
        setShowFeedbackModal(false);
    };

    return (
        <Box
            sx={{
                /* background: "rgba(32, 30, 37, 0.5)",
                backdropFilter: "20px", */
                background: "rgba(32, 30, 37, 0.5)",
                backdropFilter: "blur(3px)",
                position: "fixed",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    background: "#1E293B",
                    padding: "35px 44px",
                    borderRadius: "0.6rem",
                    maxWidth: "620px",
                    border: "2px solid #475569",
                    position: "relative",
                }}
            >
                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "5px", md: "5px" },
                        right: { xs: "5px", md: "5px" },
                        border: "1.5px solid transparent",
                        "&:hover": {
                            border: "1.5px solid rgba(128, 128, 128, 0.1)",

                            transition: "all 0.15s ease-in",
                        },
                        color: "white",
                    }}
                    onClick={handleClose}
                >
                    <HiOutlineX size={20} />
                </IconButton>
                <Typography
                    variant="h4"
                    color="white"
                    sx={{ mb: 3, fontSize: "28px", fontWeight: "bold" }}
                >
                    Help Us Improve!
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Typography
                            component="legend"
                            color="white"
                            sx={{
                                fontWeight: "600",
                                fontFamily: "Inter",
                            }}
                        >
                            1. What aspect of the AI-generated headshots didn’t meet your expectations?
                        </Typography>

                        <RadioGroup
                            aria-label="satisfaction"
                            name="satisfaction"
                            value={unsatisfactoryReasons}
                            onChange={handleUnsatisfactoryReasonsChange}
                            sx={{
                                color: "white",
                            }}
                        >
                            <FormControlLabel
                                value="Appearance doesn’t resemble the input image"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Appearance doesn’t resemble the input image"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Facial features are not accurately generated"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Facial features are not accurately generated"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Overall image quality is poor"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Overall image quality is poor"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Other"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Other (please specify)"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                        </RadioGroup>
                        {showUnsatisfactoryOtherInput && (
                            <input
                                placeholder="Other reasons.."
                                style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    border: "2px solid #475569",
                                    background: "#242E3D",
                                    outline: "none",
                                    borderRadius: "8px",
                                    padding: "0 1rem",
                                    height: "38px",
                                    fontFamily: "Inter",
                                    color: "white",
                                    fontSize: "1rem",
                                    resize: "none",
                                    marginBottom: "16px",
                                }}
                                /* value={unsatisfactoryReasons}
                                onChange={(e) =>
                                    setUnsatisfactoryReasons(e.target.value)
                                } */
                                value={unsatisfactoryOtherInput}
                                onChange={handleUnsatisfactoryOtherInputChange}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginTop: "1.4rem",
                        }}
                    >
                        <Typography
                            component="legend"
                            color="white"
                            sx={{
                                fontWeight: "600",
                                fontFamily: "Inter",
                            }}
                        >
                            2. How can we improve the AI headshot generation process for you?
                        </Typography>
                        <RadioGroup
                            aria-label="satisfaction"
                            name="satisfaction"
                            value={howToImprove}
                            onChange={handleHowToImproveChange}
                        >
                            <FormControlLabel
                                value="Increase accuracy in facial feature replicatio"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Increase accuracy in facial feature replicatio"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Enhance the resolution and clarity of images"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Enhance the resolution and clarity of images"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Provide more customization options"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Provide more customization options"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Other"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Other (please specify)"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                        </RadioGroup>
                        {showHowToImproveOtherInput && (
                            <input
                                placeholder="Other reasons.."
                                style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    border: "2px solid #475569",
                                    background: "#242E3D",
                                    outline: "none",
                                    borderRadius: "8px",
                                    padding: "0 1rem",
                                    height: "38px",
                                    fontFamily: "Inter",
                                    color: "white",
                                    fontSize: "1rem",
                                    resize: "none",
                                    marginBottom: "16px",
                                }}
                                value={howToImproveOtherInput}
                                onChange={handleHowToImproveOtherInputChange}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginTop: "1.4rem",
                        }}
                    >
                        <Typography
                            component="legend"
                            color="white"
                            sx={{
                                fontWeight: "600",
                                fontFamily: "Inter",
                            }}
                        >
                            3. Would you use this tool again?
                        </Typography>
                        <RadioGroup
                            aria-label="satisfaction"
                            name="satisfaction"
                            value={useAgain}
                            onChange={handleUseAgainChange}
                        >
                            <FormControlLabel
                                value="Definitely, with some improvements"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Definitely, with some improvements"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Maybe, if the accuracy is enhanced"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Maybe, if the accuracy is enhanced"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Unlikely, not satisfied with the results"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Unlikely"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                            <FormControlLabel
                                value="Definitely not"
                                control={
                                    <Radio
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "1.25rem",
                                            },
                                            "&.Mui-checked": {
                                                color: "#22C55E", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label="Definitely not"
                                sx={{
                                    "& .MuiFormControlLabel-label": {
                                        fontSize: "14px",
                                        fontFamily: "Inter",
                                    },
                                }}
                            />
                        </RadioGroup>
                    </Box>

                    {/* <FormControl component="fieldset" sx={{ mb: 4 }}>
                        <FormLabel component="legend" color="white">
                            How can we improve the video generation experience
                            for you?
                        </FormLabel>
                        <RadioGroup
                            aria-label="improvement"
                            name="improvement"
                            value={feedback.improvement}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="customization"
                                control={<Radio />}
                                label="Better customization options"
                            />
                            <FormControlLabel
                                value="quality"
                                control={<Radio />}
                                label="Improved visual and audio quality"
                            />
                            <FormControlLabel
                                value="styles"
                                control={<Radio />}
                                label="More styles to choose from"
                            />
                            <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Other"
                            />
                        </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" sx={{ mb: 4 }}>
                        <FormLabel component="legend" color="white">
                            Would you use this tool again?
                        </FormLabel>
                        <RadioGroup
                            aria-label="use-again"
                            name="useAgain"
                            value={feedback.useAgain}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value="definitely"
                                control={<Radio />}
                                label="Definitely"
                            />
                            <FormControlLabel
                                value="maybe"
                                control={<Radio />}
                                label="Maybe, if improvements are made"
                            />
                            <FormControlLabel
                                value="unlikely"
                                control={<Radio />}
                                label="Unlikely"
                            />
                            <FormControlLabel
                                value="not"
                                control={<Radio />}
                                label="Definitely not"
                            />
                        </RadioGroup>
                    </FormControl> */}

                    {/* <TextField
                        name="additionalComments"
                        label="Additional Comments"
                        
                        fullWidth
                        value={feedback.additionalComments}
                        onChange={handleChange}
                        sx={{
                            mb: 4,
                            backgroundColor: "#334155",
                            borderColor: "#475569",
                            borderRadius: 1,
                            color: "white",
                            te
                        }}
                    /> */}
                    {/* <input
                        placeholder="Specify audience (e.g., 'Tech enthusiasts', 'Young families')"
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            border: "2px solid #475569",
                            background: "#242E3D",
                            outline: "none",
                            borderRadius: "8px",
                            padding: "1rem",
                            fontFamily: "Inter",
                            color: "white",
                            fontSize: "1rem",
                            resize: "none",
                        }}
                    /> */}

                    <Button
                        type="submit"
                        sx={{
                            textTransform: "capitalize",
                            color: "white",
                            diplay: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                            background: "#6464FF",
                            width: "120px",
                            height: "44px",
                            fontWeight: "bold",
                            "&:hover": {
                                background: "#4E51CE",
                            },
                            paddingY: "1rem",
                            borderRadius: "0.3rem",
                            "&:disabled": {
                                background: "#1F2937",
                                color: "#64748B",
                                cursor: "no-drop !important",
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            },
                            fontFamily: "Inter",
                            fontSize: { xs: "0.9rem", md: "0.9rem" },
                            marginTop: "0.8rem",
                        }}
                    /* onClick={() => handleDownload()} */
                    >
                        Submit
                    </Button>
                </form>
            </Box>
        </Box>
    );
};

export default HeadshotsFeedbackModal;
