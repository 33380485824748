import React from "react";
import { Typography, Box, Button, IconButton, Dialog } from "@mui/material";
import { HiOutlineX } from "react-icons/hi";
import avatarGroupsImg from "../../imgs/avatarGroupsImg.svg";
import startTrialPopupShowcaseImage from "../../imgs/startTrialPopupShowcaseImage.png";
import popupShowcaseTrialMobileImg from "../../imgs/popupShowcaseTrialMobileImg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ToastNotification from "../atoms/ToastNotification";

const VideoPricingPopup = ({
    lockWarning,
    setLockWarning,
    resetProhibitedState,
    type,
    showUserProhibitedMessage,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(type, "TYPE");
    let linkToPricing = "../pricing";
    const handleAgree = () => {
        setLockWarning(false);
        if (location.pathname === "/tools") {
            resetProhibitedState();
        }
        if (location.pathname === "/video") {
            linkToPricing = "/pricing?active=video";
        }
        if (location.pathname === "/ai-avatars") {
            linkToPricing = "/ai-avatars-pricing";
        }

        navigate(linkToPricing);
    };
    return (
        <Dialog
            keepMounted
            sx={{
                p: 1,
                "& > div > div": {
                    background: "#F8FAFC",
                    borderRadius: "0.5rem",
                    maxWidth: { xs: "100vw", md: "70vw" },
                    width: { xs: "100vw", md: "fit-content" },
                    overflowY: window.innerWidth < 321 ? "scroll" : "hidden",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxHeight: { xs: "98vh", md: "90vh" },
                    position: "relative",
                    margin: "0px",
                },
                "& > div": {
                    padding: "0",
                },
                background: "rgba(82, 68, 132, 0.5)",
                backdropFilter: "blur(1px)",
            }}
            open={lockWarning}
            aria-describedby="alert-dialog-slide-description"
        >
            {showUserProhibitedMessage && (
                <ToastNotification
                    message={
                        location.pathname === "/video"
                            ? "Ran out of video credits!"
                            : "Its a Pro feature. Please upgrade!"
                    }
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    padding: { xs: "2rem 3rem", md: "3rem 5rem 3.5rem 5rem" },
                    /* gap: { xs: "0px", md: "1.3rem" }, */
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: { xs: "24px", md: "48px" },
                        fontStyle: "normal",
                        fontWeight: "900",
                        lineHeight: { xs: "26px", md: "49px" },
                        letterSpacing: "-0.768px",
                        textAlign: "center",
                        color: "#0F172A",
                        marginBottom: "1rem",
                    }}
                >
                    Transform Your Ideas <br /> into{" "}
                    <Typography
                        variant="span"
                        sx={{
                            background:
                                "linear-gradient(93deg, #4870FF 33.29%, #7342FF 63.37%, #9542FF 93.44%)",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        AISEO Photoshoots
                    </Typography>
                </Typography>
                <Typography
                    sx={{
                        maxWidth: { xs: "80vw", md: "481px" },
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#0F172A",
                        display: { xs: "none", md: "inline-flex" },
                        marginBottom: "1.8rem",
                    }}
                >
                    Join today and dive into the journey of unlimited video
                    creation without any risk!
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "55px",
                        marginTop: { xs: "1.5rem", md: "0" },
                        flexDirection: { xs: "column-reverse", md: "row" },
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                width: { xs: "70vw", md: "390px" },
                                paddingLeft: { xs: "3rem", md: "0" },
                                display: "flex",
                                flexDirection: "column",
                                gap: { xs: "0", md: "0.8rem" },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1.2rem",
                                    alignItems: "start",
                                }}
                            >
                                <img
                                    src="/assets/videoPopupStarIcon.svg"
                                    style={{ width: "20px" }}
                                />
                                <Box
                                    sx={{
                                        color: "##0F172A",
                                        fontFamily: "Inter",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            fontWeight: "bold",
                                            color: "#0F172A",
                                        }}
                                    >
                                        Quick Customization
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            color: "#334155",
                                            display: {
                                                xs: "none",
                                                md: "block",
                                            },
                                        }}
                                    >
                                        Customize every detail from themes to
                                        accents, experimenting with styles to
                                        perfectly capture your vision.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1.2rem",
                                    alignItems: "start",
                                }}
                            >
                                <img
                                    src="/assets/videoPopupRainbowIcon.svg"
                                    style={{ width: "20px" }}
                                />
                                <Box
                                    sx={{
                                        color: "##0F172A",
                                        fontFamily: "Inter",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            fontWeight: "bold",
                                            color: "#0F172A",
                                        }}
                                    >
                                        {" "}
                                        Trendy Video Formats
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            color: "#334155",
                                            display: {
                                                xs: "none",
                                                md: "block",
                                            },
                                        }}
                                    >
                                        Adapt to the latest trends with
                                        customizable, short-form video templates
                                        perfect for social media.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1.2rem",
                                    alignItems: "start",
                                }}
                            >
                                <img
                                    src="/assets/videoPopupMovieIcon.svg"
                                    style={{ width: "20px" }}
                                />
                                <Box
                                    sx={{
                                        color: "##0F172A",
                                        fontFamily: "Inter",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            fontWeight: "bold",
                                            color: "#0F172A",
                                        }}
                                    >
                                        AI-Powered Quality
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            color: "#334155",
                                            display: {
                                                xs: "none",
                                                md: "block",
                                            },
                                        }}
                                    >
                                        Harness AI for professional-grade
                                        outputs, bringing cinematic quality to
                                        your projects with cutting-edge
                                        technology.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "1.2rem",
                                    alignItems: "start",
                                }}
                            >
                                <img
                                    src="/assets/videoPopupRocketIcon.svg"
                                    style={{ width: "20px" }}
                                />
                                <Box
                                    sx={{
                                        color: "##0F172A",
                                        fontFamily: "Inter",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            fontWeight: "bold",
                                            color: "#0F172A",
                                        }}
                                    >
                                        Instant Download
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            fontFamily: "Inter",
                                            display: {
                                                xs: "none",
                                                md: "block",
                                            },
                                            color: "#0F172A",
                                        }}
                                    >
                                        Access and share your videos
                                        immediately, enjoying the convenience of
                                        quick downloads in a fast-paced world.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column-reverse",
                                    md: "row",
                                },
                                gap: { xs: "0.7rem", md: "68px" },
                                margin: "1.34rem 0 0 0",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                /* variant="contained"
                            size="small" */

                                sx={{
                                    background:
                                        "linear-gradient(78deg, #4F46E5 31.4%, #D21DFF 104.77%)",
                                    borderRadius: "30px",
                                    whiteSpace: "nowrap",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                    padding: "0.8rem 1.3rem",

                                    "&:hover": {
                                        background:
                                            "linear-gradient(38deg, #4F46E5 31.4%, #D21DFF 104.77%)",
                                        transition: "all 0.15s ease-in",
                                    },
                                    fontFamily: "Inter",
                                }}
                                onClick={handleAgree}
                            >
                                Upgrade To Generate
                            </Button>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "0.3rem",
                                }}
                            >
                                <Box sx={{ width: "157px" }}>
                                    <img
                                        alt=""
                                        src={avatarGroupsImg}
                                        style={{ width: "100%" }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        textAlign: "center",
                                        color: "#0F172A",

                                        fontWeight: "bold",
                                        maxWidth: "200px",
                                    }}
                                >
                                    250,000+ people already loved our tools.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            /* maxWidth: "350px",
                            height: "640px", */
                            display: "block",
                            width: { xs: "200px", md: "fit-content" },
                            /* display: { xs: "none", md: "block" }, */
                        }}
                    >
                        <img
                            alt=""
                            src="/assets/videoPopupCoverImg.png"
                            style={{
                                width: "100%",
                                height: "100%",
                                display:
                                    window.innerWidth < 768 ? "none" : "block",
                            }}
                        />
                        <img
                            alt=""
                            src="/assets/videoPopupCoverImgMobile.png"
                            style={{
                                width: "100%",
                                height: "100%",
                                display:
                                    window.innerWidth < 768 ? "block" : "none",
                            }}
                        />
                    </Box>
                </Box>

                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "5px", md: "2rem" },
                        right: { xs: "5px", md: "2rem" },
                        border: "1.5px solid transparent",
                        "&:hover": {
                            border: "1.5px solid grey",
                            transition: "all 0.15s ease-in",
                        },
                        color: "#3A3A3A",
                        /* background: theme.palette.mode === "light" ? "transparent" : "" */
                    }}
                    onClick={handleAgree}
                >
                    <HiOutlineX size={25} />
                </IconButton>
            </Box>
        </Dialog>
    );
};

export default VideoPricingPopup;
