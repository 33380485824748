import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Free = ({ handleAgree, open }) => {
    return (
        <>
            <Dialog
                keepMounted
                open={open}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"Please choose a plan"}</DialogTitle>
                <DialogContent>
                    {/* <Button  variant="contained" size="small" onClick={handleAgree}>Buy Credits</Button> */}
                    <DialogContentText id="alert-dialog-slide-description">
                        You have to choose a plan for using Aiseo Art premium features.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                    Please choose a plan. We have a Plan For Every Size Of
                        Business.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={}>Disagree</Button>
                     */}
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleAgree}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Normal = ({ handleAgree, open }) => {
    return (
        <>
            <Dialog
                keepMounted
                open={open}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"You run out of credits."}</DialogTitle>
                <DialogContent>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleAgree}>
                        Buy Credits
                    </Button>
                    <DialogContentText
                        mt={2}
                        id="alert-dialog-slide-description">
                        To use aiseo art please purchase a subscription plan
                        from the pricing page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={}>Disagree</Button>
                     */}
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleAgree}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const DialogModal = ({ open, setOpen, type }) => {
    const navigate = useNavigate();
    // console.log(type, 'TYPE')
    const handleAgree = () => {
        setOpen(false);
        navigate("../pricing");
    };
    return (
        <>
            {type === "Free" ? (
                <Free handleAgree={handleAgree} open={open} />
            ) : (
                <Normal handleAgree={handleAgree} open={open} />
            )}
        </>
    );
};

export default DialogModal;
