import styled from "@emotion/styled";
import { Slider } from "@mui/material";

export const PrettoSlider = styled(Slider)({
    color: "bg.dark",
    height: "40px",
    borderRadius: "5px",
    padding: 0,
    "& .MuiSlider-track": {
        border: "none",
    },
    "& .MuiSlider-thumb": {
        height: "42px",

        width: 10,
        borderRadius: "5px",
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1.2,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 24,
        height: 24,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#7ab4ed",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&:before": { display: "none" },
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        },
        "& > *": {
            transform: "rotate(45deg)",
        },
    },
});
