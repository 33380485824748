import React, { useState } from "react";
import MainProducts from "../../index";
import { instructProductData } from "./instructProductData";
import WarningModal from "../../../form/WarningModal";
import { useDispatch, useSelector } from "react-redux";
import {
    imageUploaded,
    stateChanged,
    generationStarted,
    generationSucceeded,
    generationFailed,
} from "../../../../features/image/adProductSlice";
import { useAPI } from "../../../../StateProvider";
import { getAdProductImage } from "../../../../axios";
import { reduceTheCredit } from "../../../../firestore/dataHandler";
import { uploadProductGenResult } from "../../../../firestore/variationsHandler";
import { Slide } from "@mui/material";

import NeedToBuyAPlan from "../../../model/NeedToBuyAPlan";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InstructProduct = ({ user }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.adProduct);
    const { inputImage, outputs, imageNum, prompt, generating, errorMessage } =
        state;
    const { data, dataFetched } = useAPI();
    let credits = data?.credits;

    const [loginPopup, setLoginPopup] = useState(false);
    const [lockWarning, setLockWarning] = useState(false);

    //onGenerateOutput(e, 'replace')
    const onGenerateOutput = async (e, type, formData) => {
        // if there is no user show the popup...
        if (!user) {
            setLoginPopup(true);
            dispatch(generationFailed({ errorMessage: "" }));
            return;
        }

        //  free user are not allowed to generate..
        if (!data.subscription || data?.subscription.type === "Free") {
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            // navigate to pricing page...
            setLockWarning(true);
            return;
        }

        dispatch(generationStarted());
        /* scrollToLoader(); */

        const cost = imageNum * 2;
        const remaining = credits - cost;
        if (remaining <= 0) {
            dispatch(
                generationFailed({
                    errorMessage:
                        "You don't have enough credits to generate images.",
                })
            );
            return;
        }
        try {
            /* const formData = new FormData(formRef.current);
            formData.append("prompt", prompt);
            formData.append("image_num", imageNum); */
            console.log(formData, " Form Data mine ");
            const result = await getAdProductImage(formData);
            const outputs = result.data;
            dispatch(generationSucceeded({ outputs: outputs }));
            reduceTheCredit(cost);
            uploadProductGenResult({
                images: outputs,
            });
        } catch (error) {
            console.log(error.message);
            dispatch(
                generationFailed({
                    errorMessage: "Something Went Wrong. Please Try Again.",
                })
            );
        }
    };
    return (
        <>
            <WarningModal
                open={loginPopup}
                onToggle={() => setLoginPopup(!loginPopup)}
                message="Please log in with your account or make a new one to start generating images."
            />
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}
            <MainProducts
                user={user}
                credits={credits}
                productData={instructProductData}
                onGenerateOutput={onGenerateOutput}
            />
        </>
    );
};

export default InstructProduct;
