import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const NewMobileMenu = ({
    navItemsData,
    profileMenuItemsData,
    user,
    handleLogout,
    handleLoginNav,
    setIsMobileMenuOpen,
    theme,
    data,
}) => {
    const location = useLocation();

    return (
        <Box>
            <Box
                sx={{
                    background: "#0F172A",
                    position: "fixed",
                    width: "90%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: "1rem",
                    padding: "1rem",
                    top: "60px",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.6rem",
                    border: "2px solid white",
                    zIndex: "1000",
                }}
            >
                <Link
                    sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                            color: "white",
                        },
                        textDecoration: "none",
                        cursor: "pointer",
                        fontSize: "1.1rem",
                        display:
                            location.pathname === "/products/headshot"
                                ? "inline-flex"
                                : "none",
                    }}
                    href="/my-headshots"
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                        }}
                    >
                        History
                    </Typography>
                </Link>
                <Link
                    sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                            color: "white",
                        },
                        textDecoration: "none",
                        cursor: "pointer",
                        fontSize: "1.1rem",
                        display:
                            location.pathname === "/video"
                                ? "inline-flex"
                                : "none",
                    }}
                    href="/my-headshots"
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                        }}
                    >
                        My Videos
                    </Typography>
                </Link>
                <Link
                    sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                            color: "white",
                        },
                        textDecoration: "none",
                        cursor: "pointer",
                        fontSize: "1.1rem",
                        display: data?.hasOwnProperty("credits")
                            ? "flex"
                            : "none",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                        }}
                    >
                        {location.pathname === "/products/headshot"
                            ? data?.credits
                            : data?.vid_credits}{" "}
                        credits
                    </Typography>
                </Link>
                <Link
                    sx={{
                        color: "rgba(255, 255, 255, 0.8)",
                        "&:hover": {
                            color: "white",
                        },
                        textDecoration: "none",
                        cursor: "pointer",
                        fontSize: "1.1rem",
                    }}
                    href="/dashboard"
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                        }}
                    >
                        Dashboard
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default NewMobileMenu;
