import React, { useState, useEffect, useRef, useMemo } from "react";

import emptyPhotos from "../../../imgs/emptyPhotos.svg";
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
} from "firebase/firestore";
import { Box, Button, Typography, CircularProgress } from "@mui/material";

import { firebaseApp } from "../../../firebaseInit";
import NewGenImageCard from "./childAtoms/NewGenImageCard";
import EmptyGenCard from "./childAtoms/EmptyGenCard";

const ITEMS_PER_PAGE = 12;

const GeneratedPhotosBox = ({ user }) => {
    const db = getFirestore(firebaseApp);

    const [loading, setLoading] = useState(true);
    const [singleImages, setSingleImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (user?.email) {
            const q = query(
                collection(db, "tuned_single_images"),
                where("email", "==", user.email),
                orderBy("createdAt", "desc")
            );

            const unsubscribe = onSnapshot(
                q,
                (snapshot) => {
                    const singleImagesData = [];
                    snapshot.forEach((doc) => {
                        if (doc.data().image) {
                            singleImagesData.push({
                                ...doc.data(),
                                id: doc.id,
                            });
                        }
                    });

                    console.log(singleImagesData, "--singleImagesData--");
                    setSingleImages(singleImagesData);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                    setLoading(false);
                }
            );

            return () => unsubscribe();
        }
    }, [user?.email, db]);

    const totalPages = useMemo(() => {
        return Math.ceil(singleImages.length / ITEMS_PER_PAGE);
    }, [singleImages]);

    const paginatedData = useMemo(() => {
        return singleImages.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            currentPage * ITEMS_PER_PAGE
        );
    }, [singleImages, currentPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) return <CircularProgress size={50} />;

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "600px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: "1rem",
                    flexWrap: "wrap",
                    height: "100%",
                    paddingY: "1.3rem",
                    paddingX: "0",
                    alignItems: { xs: "left", md: "left" },
                    justifyContent: { xs: "center", md: "flex-start" },
                }}
            >
                {paginatedData.length > 0 ? (
                    paginatedData.map((item, index) => (
                        <NewGenImageCard
                            item={item}
                            index={index}
                            key={index}
                            allImages={paginatedData}
                        />
                    ))
                ) : (
                    <EmptyGenCard
                        emptyTitle={"No Albums Created!"}
                        emptyDescription={
                            "Start by choosing a model or selecting from our preset themes to generate personalized albums."
                        }
                        emptyImgSrc={emptyPhotos}
                    />
                )}
            </Box>

            {singleImages.length > ITEMS_PER_PAGE && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "1.5rem 0",
                    }}
                >
                    <Box
                        sx={{
                            width: "fit-content",
                        }}
                    >
                        <Button
                            onClick={prevPage}
                            disabled={currentPage === 1}
                            sx={{
                                background: "#1E293B",
                                color: "white",
                                textTransform: "capitalize",
                                "&.Mui-disabled": {
                                    color: "white",
                                    opacity: 0.5,
                                },
                                "&:hover": {
                                    background: "black",
                                    color: "white",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    /* body-medium */
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Prev
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                margin: "0 20px",
                                background: "#4338CA",
                                color: "white",
                                borderRadius: "0.6rem",
                                wdith: "32px",
                                height: "32px",
                                padding: "0px",
                                "&:hover": {
                                    background: "#4338CA",
                                    color: "white",
                                },
                                pointerEvents: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                {currentPage}
                            </Typography>
                        </Button>

                        <Button
                            onClick={nextPage}
                            disabled={currentPage >= totalPages}
                            sx={{
                                background: "#1E293B",
                                color: "white",
                                textTransform: "capitalize",

                                "&.Mui-disabled": {
                                    color: "white",
                                    opacity: 0.5,
                                },
                                "&:hover": {
                                    background: "black",
                                    color: "white",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Next
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default GeneratedPhotosBox;
