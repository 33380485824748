import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Slide } from "@mui/material";
import { useAPI } from "../../../StateProvider";
import InputStageOne from "./InputStages/InputStageOne";
import InputStageTwo from "./InputStages/InputStageTwo";
import InputStageThree from "./InputStages/InputStageThree";
import { generateVideo, generateSubtitleVideo } from "../../../axios";
import ToastNotification from "../../atoms/ToastNotification";
import { useNavigate } from "react-router-dom";

import {
    addDoc,
    collection,
    getFirestore,
    serverTimestamp,
    query,
    where,
    onSnapshot,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    increment,
} from "firebase/firestore";
import { firebaseApp } from "../../../firebaseInit";
import VideoPricingPopup from "../../model/VideoPricingPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InputPhaseMain = ({
    user,
    setIsInputPhase,
    setGeneratedVideoSrc,
    setShowApiError,
    setShowApiSuccess,
    userInputObject,
    setUserInputObject,
}) => {
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();
    const { data } = useAPI();
    const [activeInputStage, setActiveInputStage] = useState(1);

    const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
    const [showFreeCreditsGiven, setShowFreeCreditsGiven] = useState(false);
    const [userProhibited, setUserProhibited] = useState(false);
    const [showUserProhibitedMessage, setShowUserProhibitedMessage] =
        useState(false);

    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

    const [ideaInputValue, setIdeaInputValue] = useState("");
    const [durationInputValue, setDurationInputValue] = useState("");
    const [videoTypeInputValue, setVideoTypeInputValue] = useState("landscape");
    const [genderInputValue, setGenderInputValue] = useState("");
    const [vidStyleInputValue, setVidStyleInputValue] = useState("");
    const [contentFocusInputValue, setContentFocusInputValue] = useState("");
    const [audienceInputValue, setAudienceInputValue] = useState("");
    const [lookFeelInputValue, setLookFeelInputValue] = useState("");
    const [platformInputValue, setPlatformInputValue] = useState("");

    const handleNextButtonDisabled = () => {
        console.log("durationInputValue", durationInputValue)
        console.log("ideaInputValue", ideaInputValue)
        if (activeInputStage === 1) {
            if (ideaInputValue === "") {
                setIsNextButtonDisabled(true);
                // return true;
            } else {
                setIsNextButtonDisabled(false);

            }
            /* else if (videoTypeInputValue === "") {

                setIsNextButtonDisabled(true);

                // return false;
            }  */
            /* else if (durationInputValue === "") {

                setIsNextButtonDisabled(true);

                // return false;
            }  
            else if (videoTypeInputValue === "") {

                setIsNextButtonDisabled(true);

                // return false;
            } 
            */
        } else if (activeInputStage === 2) {
            if (vidStyleInputValue === "") {
                setIsNextButtonDisabled(true);
            } else if (contentFocusInputValue === "") {
                setIsNextButtonDisabled(true);
            } else if (audienceInputValue === "") {
                setIsNextButtonDisabled(true);

            } else {
                setIsNextButtonDisabled(false);
            }

            //skip 2
            // if (accentInputValue === "") {
            //     setIsNextButtonDisabled(true);
            // } else if (toneInputValue === "") {
            //     setIsNextButtonDisabled(true);
            // } else {
            //     setIsNextButtonDisabled(false);
            // }
            //skip 3
            /* if (genderInputValue === "") {
                setIsNextButtonDisabled(true);
            } else if (accentInputValue === "") {
                setIsNextButtonDisabled(true);
            } else if (toneInputValue === "") {
                setIsNextButtonDisabled(true);
            } else {
                setIsNextButtonDisabled(false);
            } */
        } else if (activeInputStage === 3) {
            if (audienceInputValue === "") {
                setIsNextButtonDisabled(true);
            } else if (videoTypeInputValue === "") {
                setIsNextButtonDisabled(true);
            } else {
                setIsNextButtonDisabled(false);
            }
        }
    };

    useEffect(() => {
        handleNextButtonDisabled();
    }, [
        activeInputStage,
        ideaInputValue,
        durationInputValue,
        videoTypeInputValue,
        genderInputValue,
        audienceInputValue,
        lookFeelInputValue,
        platformInputValue,
        vidStyleInputValue,
        contentFocusInputValue,
    ]);

    const updateUserDocument = async () => {
        const userDocRef = doc(db, "arts", user?.email);

        try {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const myAccData = docSnap.data();
                console.log(myAccData, "--myAccData--");
                //setAccountInfo({ ...myAccData });

                let updateFields = {};

                // Check if avatar_subscription.status is not present or not "active"
                if (
                    !myAccData?.vid_subscription?.status ||
                    myAccData?.vid_subscription?.status !== "active"
                ) {
                    // Check if avatarsFreeCredits property exists
                    if (!myAccData.hasOwnProperty("alreadyGotFreeVidCredits")) {
                        console.log("Setting avatars Free credits");
                        updateFields.alreadyGotFreeVidCredits = true;
                        updateFields.vid_credits = 20;
                    }

                    // If any of the properties were missing, update the document
                    if (Object.keys(updateFields).length) {
                        await setDoc(userDocRef, updateFields, { merge: true });
                        setShowFreeCreditsGiven(true);
                        setTimeout(() => {
                            setShowFreeCreditsGiven(false);
                        }, 3200);
                    }
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    useEffect(() => {
        if (data?.vid_subscription?.status !== "active") {
            updateUserDocument();
        }
    }, [user]);

    const generateVideo2 = (apiInputData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    data: {
                        status: "ok",
                        /* download_url:
                            "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/rsotiNq7f65zPLq1rRXe_caption_20240109_115048.mp4", */
                        download_url:
                            apiInputData.video_type === "landscape" ? "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/rsotiNq7f65zPLq1rRXe_caption_20240109_115048.mp4" : "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/c950YhjW8Qv9G8kKisPb.mp4",
                        /* download_url:
                            "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/Ls8IikB6Iu1xP6nPlIlg.mp4", */
                    },
                });
            }, 5000);
            //reject(new Error("Failed to generate video."));
            // setTimeout(() => {
            //     resolve({
            //         data: {
            //             status: "ok",
            //             download_url:
            //                 "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/Ls8IikB6Iu1xP6nPlIlg.mp4",
            //         },
            //     });
            // }, 5 * 60 * 1000); // 5 minutes delay
        });
    };

    const handleVideoCreation = async () => {
        setIsInputPhase(false);

        try {
            console.log("entered try block");
            const finalDurationInputValInNum = videoTypeInputValue === "Portrait" ? "30" : "70";
            console.log({
                content_duration: finalDurationInputValInNum,
                video_type: videoTypeInputValue.toLowerCase(),
            }, "finalDurationInputValInNum")
            const firebaseDocInputData = {
                email: user?.email,
                uid: user?.uid,
                progress: 0,
                remaining_time: null,
                /* status: "pending", */
                video_url: "",
                createdAt: serverTimestamp(),
                user_idea: ideaInputValue,
                video_style: vidStyleInputValue,
                audience_focus: audienceInputValue,
                content_focus: contentFocusInputValue,
                content_duration: finalDurationInputValInNum,
                video_type: videoTypeInputValue.toLowerCase(),

            };
            // Save the data to Firestore
            let ideaToMotionDocRef = await addDoc(
                collection(db, "Idea2Motions"),
                firebaseDocInputData
            );
            console.log(
                "Idea2Motions Document written with ID: ",
                ideaToMotionDocRef.id
            );



            /* {
               "docId": "ZLxEH2H2scYJuECR83xP",
               "email": "say2sankalp90@gmail.com",
               "user_idea": "top 5 workout routine for morning in cold areas",
               "video_style": "Educational",
               "content_focus":"storytelling",
               "audience_focus": "busy professional",
               "content_duration": "120",
               "video_type" :"landscape"
            } */

            const apiInputData = {
                docId: ideaToMotionDocRef.id,
                email: user?.email,
                user_idea: ideaInputValue,
                video_style: vidStyleInputValue,
                audience_focus: audienceInputValue,
                content_focus: contentFocusInputValue,
                content_duration: finalDurationInputValInNum,
                video_type: videoTypeInputValue.toLowerCase(),
            };

            console.log("Helper text23: ", apiInputData);




            console.log("apiInputData - 99- ", apiInputData);
            //return;

            setUserInputObject({
                ...apiInputData,
                platform: platformInputValue,
                /* content_duration: durationInputValue, */
            });

            console.log("io", apiInputData);

            // generateVideo2(apiInputData).then(async (resp) => {
            //     setShowApiSuccess(true);
            //     setTimeout(() => {
            //         setShowApiSuccess(false);
            //     }, 3200);
            //     setGeneratedVideoSrc(resp.data.download_url);
            // });
            // return;

            generateVideo(apiInputData)
                .then(async (resp) => {
                    console.log(resp, "resp1");
                    console.log(resp.data);

                    setShowApiSuccess(true);
                    setTimeout(() => {
                        setShowApiSuccess(false);
                    }, 3200);

                    if (resp.data.status === "ok") {
                        if (!resp.data.download_url) {
                            setIsInputPhase(true);

                            console.log(
                                "Error: " + "Unknown, download url null"
                            );
                            setShowApiError(true);
                            setTimeout(() => {
                                setShowApiError(false);
                            }, 3200);
                            return;
                        }
                        console.log(resp.data, "resp.data");
                        // setGeneratedVideoSrc(resp.data.download_url);
                        // setShowApiSuccess(true);
                        // setTimeout(() => {
                        //     setShowApiSuccess(false);
                        // }, 3200);

                        /* {
                            "docId": "ZLxEH2H2scYJuECR83xP",
                           "email": "say2sankalp90@gmail.com",
                            "bucket_url": "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/ZLxEH2H2scYJuECR83xP.mp4"
                        } */
                        /* {
                            "caption_file": "https://replicate.delivery/pbxt/dtYeMBJmkNQ6cCwyf4n3pbiHOcASDrIxW45Csb2VVyReEeZIB/transcript_out.json",
                            "caption_video": "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/ZLxEH2H2scYJuECR83xP_caption_20231227_151128.mp4",
                            "status": "ok"
                        } */
                        if (resp.data.caption === true) {
                            setGeneratedVideoSrc(resp.data.download_url);
                            setShowApiSuccess(true);
                            setTimeout(() => {
                                setShowApiSuccess(false);
                            }, 3200);
                        } else {
                            const apiInputData2 = {
                                bucket_url: resp.data.download_url,
                                email: user?.email,
                                docId: ideaToMotionDocRef.id,
                            };
                            generateSubtitleVideo(apiInputData2)
                                .then(async (resp2) => {
                                    console.log("resp2", resp2);
                                    if (resp2.data.status === "ok") {
                                        if (!resp2.data.caption_video) {
                                            setIsInputPhase(true);

                                            console.log(
                                                "Error: " +
                                                "Unknown, subtitle download url null"
                                            );
                                            setShowApiError(true);
                                            setTimeout(() => {
                                                setShowApiError(false);
                                            }, 3200);
                                            return;
                                        }
                                        console.log(resp2);
                                        setGeneratedVideoSrc(
                                            resp2.data.caption_video
                                        );
                                        setShowApiSuccess(true);
                                        setTimeout(() => {
                                            setShowApiSuccess(false);
                                        }, 3200);
                                    } else if (resp2.data.status === "error") {
                                        setIsInputPhase(true);

                                        console.log("Error: " + resp.data.message);
                                        setShowApiError(true);
                                        setTimeout(() => {
                                            setShowApiError(false);
                                        }, 3200);
                                    }
                                })
                                .catch((error2) => {
                                    setIsInputPhase(true);

                                    console.log("Error: " + error2.message);
                                    setShowApiError(true);
                                    setTimeout(() => {
                                        setShowApiError(false);
                                    }, 3200);
                                });
                        }

                        // {
                        //     "caption": false,
                        //     "download_url": "https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/wzPlvDQz9GxCSCy4Ce23.mp4",
                        //     "status": "ok"
                        // }

                    } else if (resp.data.status === "error") {
                        setIsInputPhase(true);

                        console.log("Error: " + resp.data.message);
                        setShowApiError(true);
                        setTimeout(() => {
                            setShowApiError(false);
                        }, 3200);
                    }
                })
                .catch((error) => {
                    setIsInputPhase(true);

                    console.log("Error: " + error.message);
                    setShowApiError(true);
                    setTimeout(() => {
                        setShowApiError(false);
                    }, 3200);
                });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    // const handleNextClick = () => {
    //     if (!user) {
    //         setUserNotLoggedIn(true);
    //         setTimeout(() => {
    //             navigate("/login");
    //         }, 1300);
    //         return;
    //     }

    //     /*  if (
    //         !data.vid_subscription ||
    //         data?.vid_subscription.type === "Free" ||
    //         data?.vid_credits < 20 ||
    //         (data?.vid_credits ?? null) === null
    //     ) { */
    //     if (data?.vid_credits < 20 || (data?.vid_credits ?? null) === null) {
    //         console.log("SUBSCRIPTION NOT DEFIENEDs");
    //         setShowUserProhibitedMessage(true);
    //         setTimeout(() => {
    //             setShowUserProhibitedMessage(false);
    //         }, 3200);
    //         setUserProhibited(true);
    //     } else {
    //         handleVideoCreation();
    //         //setIsInputPhase(false);
    //     }
    // };
    const handleNextClick = () => {
        if (activeInputStage < 2) {
            /* if (activeInputStage < 3) { */
            if (!user) {
                setUserNotLoggedIn(true);
                setTimeout(() => {
                    navigate("/login");
                }, 1300);
                return;
            }
            console.log(activeInputStage + 1);
            setActiveInputStage(activeInputStage + 1);
        } else {
            //      if (
            //     !data.vid_subscription ||
            //     data?.vid_subscription.type === "Free" ||
            //     data?.vid_credits < 20 ||
            //     (data?.vid_credits ?? null) === null
            // ) {
            if (
                data?.vid_credits < 20 ||
                (data?.vid_credits ?? null) === null
            ) {
                console.log("SUBSCRIPTION NOT DEFIENEDs");
                setShowUserProhibitedMessage(true);
                setTimeout(() => {
                    setShowUserProhibitedMessage(false);
                }, 3200);
                setUserProhibited(true);
            } else {
                console.log("handleVideoCreation");
                handleVideoCreation();

                // console.log("handleVideoCreation", {
                //     email: user?.email,
                //     user_idea: ideaInputValue,
                //     target_audience: audienceInputValue,
                //     video_style: vidStyleInputValue,
                //     content_focus: contentFocusInputValue,
                //     duration: "long",
                // });
                //setIsInputPhase(false);
            }
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "40px", md: "130px" },
                height: "100%",
                flexDirection: { xs: "column-reverse", md: "row" },
            }}
        >
            {userNotLoggedIn && (
                <ToastNotification
                    message="Please login!"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showFreeCreditsGiven && (
                <ToastNotification
                    message="20 credits granted for FREE! Enjoy.."
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}

            {userProhibited ? (
                <VideoPricingPopup
                    lockWarning={userProhibited}
                    setLockWarning={setUserProhibited}
                    showUserProhibitedMessage={showUserProhibitedMessage}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}
            <Box
                sx={{
                    borderRadius: "12px",
                    border: "1px solid var(--slate-600, #475569)",
                    background: "#0F172A",
                    padding: { xs: "1.6rem 1.4rem", md: "3rem 2.45rem" },
                    width: { xs: "92vw", md: "700px" },
                }}
            >
                {activeInputStage === 1 && (
                    <InputStageOne
                        activeInputStage={activeInputStage}
                        setActiveInputStage={setActiveInputStage}
                        ideaInputValue={ideaInputValue}
                        setIdeaInputValue={setIdeaInputValue}
                        durationInputValue={durationInputValue}
                        setDurationInputValue={setDurationInputValue}
                        videoTypeInputValue={videoTypeInputValue}
                        setVideoTypeInputValue={setVideoTypeInputValue}
                    />
                )}
                {/* {activeInputStage === 2 && (
                    <InputStageThree
                        activeInputStage={activeInputStage}
                        setActiveInputStage={setActiveInputStage}
                        audienceInputValue={audienceInputValue}
                        setAudienceInputValue={setAudienceInputValue}
                        setLookFeelInputValue={setLookFeelInputValue}
                        setPlatformInputValue={setPlatformInputValue}
                    />
                )} */}
                {activeInputStage === 2 && (
                    <InputStageTwo
                        activeInputStage={activeInputStage}
                        setActiveInputStage={setActiveInputStage}
                        setVidStyleInputValue={setVidStyleInputValue}
                        setContentFocusInputValue={setContentFocusInputValue}
                        setAudienceInputValue={setAudienceInputValue}
                    />
                )}
                {activeInputStage === 3 && (
                    <InputStageThree
                        audienceInputValue={audienceInputValue}
                        activeInputStage={activeInputStage}
                        setActiveInputStage={setActiveInputStage}
                        setAudienceInputValue={setAudienceInputValue}
                        setLookFeelInputValue={setLookFeelInputValue}
                        setPlatformInputValue={setPlatformInputValue}
                        videoTypeInputValue={videoTypeInputValue}
                        setVideoTypeInputValue={setVideoTypeInputValue}
                    />
                )}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        sx={{
                            textTransform: "capitalize",
                            color: "white",
                            diplay: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#1E293B",
                            width: "122px",
                            height: "50px",
                            fontWeight: "bold",

                            "&:hover": {
                                background: "#4A5464",
                            },
                            paddingY: "1rem",
                            borderRadius: "0.7rem",
                            "&:disabled": {
                                background: "#1E293B",
                                color: "#64748B",
                                cursor: "no-drop !important",
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            },
                            fontFamily: "Inter",
                        }}
                        onClick={() => {
                            setActiveInputStage(activeInputStage - 1);

                            if (
                                audienceInputValue !== "" &&
                                platformInputValue !== ""
                            ) {
                                setAudienceInputValue("");
                                setPlatformInputValue("");
                            }
                        }}
                        disabled={activeInputStage === 1 ? true : false}
                    >
                        Previous
                    </Button>
                    <Button
                        sx={{
                            textTransform: "capitalize",
                            color: "white",
                            diplay: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#6464FF",
                            width: "122px",
                            height: "50px",
                            fontWeight: "bold",

                            "&:hover": {
                                background: "#4E51CE",
                            },
                            paddingY: "1rem",
                            borderRadius: "0.7rem",
                            "&:disabled": {
                                background: "#1F2937",
                                color: "#64748B",
                                cursor: "no-drop !important",
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            },
                            fontFamily: "Inter",
                        }}
                        onClick={() => handleNextClick()}
                        disabled={isNextButtonDisabled}
                    /* disabled={activeInputStage === 3 ? true : false} */
                    >
                        {/* Generate */}
                        {activeInputStage < 2 ? "Next" : "Generate"}
                        {/* {activeInputStage < 3 ? "Next" : "Generate"} */}
                    </Button>
                </Box>
            </Box>

            <Box
                sx={{
                    width: { xs: "220px", md: "367px" },
                    /* marginTop: { xs: "20rem", md: "0" }, */
                }}
            >
                {activeInputStage === 1 && (
                    <img src="/assets/videoStage1CoverImage.svg" />
                )}
                {activeInputStage === 2 && (
                    <img src="/assets/videoStage2CoverImage.svg" />
                )}
                {activeInputStage === 3 && (
                    <img src="/assets/videoStage3CoverImage.svg" />
                )}
            </Box>
        </Box>
    );
};

export default InputPhaseMain;
