import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import NavBar from "../NavBar/NavBar";
import { useLocation } from "react-router-dom";
import NavBarV3 from "../NavBar/NavBarV3";

const StyledBox = styled(Box)(({ theme }) => ({
    marginTop: "120px",
    minHeight: "100vh",
}));

const WrapperBox = styled(Box)(({ theme }) => ({
    /* background: theme.palette.background.default,
    color: theme.palette.text.primary, */
    background: "#0F172A",
    color: "white",
    scrollBehavior: "smooth",
}));

const StyledBoxWithoutMarginTop = styled(Box)(({ theme }) => ({
    marginTop: "0px",
    minHeight: "100vh",
}));

export default function LayoutV2(props) {
    const location = useLocation();

    const styledBoxArr = [
        "/templates",
        "/video",
        "/audio",
        "/my-audios",
        "/my-videos",
        "/pricing",
        "/dashboard",
        "/rewards",
        "/customTemplate",
        "/ai-avatars",
        "/products/headshot",
        "/my-headshots",
        "/",
    ];

    return (
        <WrapperBox>
            <NavBarV3 />
            {styledBoxArr.includes(location.pathname) ? (
                <StyledBoxWithoutMarginTop>
                    {props.children}
                </StyledBoxWithoutMarginTop>
            ) : (
                <StyledBox>{props.children}</StyledBox>
            )}

            {props.footer}
        </WrapperBox>
    );
}
