import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./features/configs/configSilce";
import dreamBoothReducer from "./features/dreambooth/dreamBoothSlice";
import globalComponentReducer from "./features/globals/globalComponentSlice";
import image2imageReducer from "./features/image/Image2ImageSlice";
import imageInpaintingReducer from "./features/image/ImageInpainting";
import CommandSliceReducer from "./features/image/CommandSlice";
import imageReducer from "./features/image/imageSlice";
import imageVariationReducer from "./features/image/ImageVariation";
import savedImageReducer from "./features/image/savedImageSlice";
import serpImageReducer from "./features/image/serpImageSlice";
import upscaledImageReducer from "./features/image/upscalingSlice";

import adProductReducer from "./features/image/adProductSlice";
import dragganReducer from "./features/image/dragganSlice"
// templates
import customTemplateReducer from "./features/templates/customTemplateSlice";
import templatesReducer from "./features/templates/templateSlice";

import modelConfigReducer from "./features/modelConfig/modelConfigSlice";
import appiconReducer from "./features/tools/appiconSlice";

import toolImgGenReducer from "./features/toolImgGen/toolImgGenSlice";

export const store = configureStore({
    reducer: {
        image: imageReducer,
        upscaledImage: upscaledImageReducer,
        config: configReducer,
        savedImage: savedImageReducer,
        globalComponents: globalComponentReducer,
        image2image: image2imageReducer,
        imageInpainting: imageInpaintingReducer,
        // imageInpainting: imageInpaintingReducer,
        imageVariation: imageVariationReducer,
        CommandSlice: CommandSliceReducer,
        serpImages: serpImageReducer,
        templates: templatesReducer,
        customTemplates: customTemplateReducer,
        dreamBooth: dreamBoothReducer,
        modelConfig: modelConfigReducer,
        adProduct: adProductReducer,
        draggan: dragganReducer,

        // tools store
        animeTools: modelConfigReducer,
        appiconTools: appiconReducer,

        toolImgGen: toolImgGenReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
