import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../StateProvider";
import DialogModal from "./form/DialogModal";
import downloadImage from "./utils/downloadeImage";

const LightBox = ({
  credits,
  user,
  images,
  slectedImageId,
  setShowLightBox,
  askedFrom,
  handlePromptSelection,
  setCurrentTab,
}) => {
  console.log("images", images);
  const [currentImageId, setCurrentImageId] = React.useState(slectedImageId);
  const [warning, setWarning] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const heroImageRef = React.useRef();
  const bottomImageWrapperRef = React.useRef();
  const promtRef = React.useRef();
  const { data, dataFetched } = useAPI();
  let navigate = useNavigate();

  // const [imageDownloader] = useDownloadImage();

  //
  const handleCloseLightBox = () => {
    setShowLightBox(false);
  };

  const handleBottomImageClick = (e) => {
    let id = e.target.getAttribute("id");
    let selectedImage = images.filter((each) => each.id === id);
    console.log(heroImageRef);

    // set the image...
    heroImageRef.current.src = selectedImage[0].src;
    // heroImageRef.current.previousElementSibling.textContent =
    // selectedImage[0].prompt;
    promtRef.current.textContent = selectedImage[0].prompt;
    heroImageRef.current.previousElementSibling.width = selectedImage[0].width;

    // set it as a current image.
    setCurrentImageId(id);

    // remove the active class from everywhere...
    e.target
      .closest(".bottomImageWrapper")
      .querySelectorAll(".active")
      .forEach((each) => {
        each.classList.remove("active");
      });

    // add the active class
    e.target.classList.add("active");
  };

  const promptCssWrapper = (width) => {
    let obj = {
      position: "absolute",
      // top : '1rem',
      // left : '1rem',
      color: "white",
      marginLeft: "auto",
      marginRight: "auto",
      bottom: "1rem",
      opacity: 1,
      // cursor: "pointer",
      // transition: "opacity 0.5s",

      // "&:hover": {
      //     opacity: 1,
      // },
    };
    return obj;
  };
  const promptCss = (width) => {
    let obj = {
      // position: "absolute",
      // top : '1rem',
      // left : '1rem',
      color: "white",
      width: width ? width : "auto",
      marginLeft: "auto",
      marginRight: "auto",
      bottom: "1rem",
      opacity: 0.05,
      cursor: "pointer",
      transition: "opacity 0.5s",

      "&:hover": {
        opacity: 1,
      },
    };
    return obj;
  };

  const handleTryThisClick = () => {
    setShowLightBox(false);
    let selectedImage = images.filter((each) => each.id === currentImageId);
    handlePromptSelection(selectedImage[0]);

    if (askedFrom !== "lexicaImages") {
      setCurrentTab("lexical");
    }
  };

  function showImage() {
    let selectedImage = images.filter((each) => each.id === slectedImageId);
    console.log("SELECTED IMAGE", selectedImage);
    let width = selectedImage[0]?.width ? selectedImage[0].width : "auto";
    return (
      <>
        <Box sx={promptCssWrapper(width)}>
          <Typography
            align="center"
            sx={promptCss(width)}
            variant="body1"
            ref={promtRef}
            className="textBox"
            onMouseEnter={hanldeMouseEnter}
            onMouseLeave={hanldeMouseLeave}
            style={{
              width: "60%",
              color: "white",
            }}
          >
            {selectedImage[0].prompt}
          </Typography>

          <Button
            variant="outlined"
            size="small"
            color="info"
            sx={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              mb: 1,
              color: "white",
              // border : 'white',
              fontWeight: "bold",
            }}
            onClick={() => handleTryThisClick(selectedImage[0])}
          >
            Try This
          </Button>
        </Box>
        <img
          onMouseEnter={hanldeMouseEnter}
          onMouseLeave={hanldeMouseLeave}
          ref={heroImageRef}
          src={selectedImage[0].src}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          height="100%"
          alt=""
        />
      </>
    );
  }

  const handleWarningClose = () => {
    setWarning("");
  };

  const handleLeftClick = () => {
    images.forEach((each, i) => {
      if (each.id === currentImageId) {
        let imagePosition = i;
        let imageBucketLength = images.length;
        if (imagePosition - 1 < imageBucketLength) {
          let obj = images[imagePosition - 1];

          // change the current id...
          setCurrentImageId(obj.id);
          // set the image...
          heroImageRef.current.src = obj.src;
          // heroImageRef.current.previousElementSibling.textContent =
          // obj.prompt;
          promtRef.current.textContent = obj.prompt;

          heroImageRef.current.previousElementSibling.width = obj.width;

          let current = document.querySelector(".lightboxImage.active");
          current.previousElementSibling.classList.add("active");
          current.classList.remove("active");
        }
      }
    });
  };

  const handleDownloadImageButton = (e) => {
    e.preventDefault();

    // if user is Free then redirect to pricing page...
    // if(!data.subscription || data?.subscription.type === "Free"){
    //     navigate("../pricing", { replace: true });
    //     return;
    // }

    if (!user) {
      setWarning("You need to login first.");
      return;
    }

    // if (credits <= 0) {
    //     setWarning("You do not have credit.");
    //     return;
    // }

    let selectedImage = images.filter((each) => each.id === currentImageId);

    const isLexicalImage = selectedImage[0].src.startsWith("https://lexica");

    if (isLexicalImage) {
      downloadImage(selectedImage[0]).then((res) => {
        console.log("response:", res);
        /*
               * No credit reduction for download
              if (res.success === true) {
                  reduceTheCredit(EXPENSE_RATE.lexicaImageDownload);
              }
              */
      });
    } else {
      console.log("selectedImage", selectedImage[0].src);
      const imagesrc = selectedImage[0].src;
      console.log("imagesrc", imagesrc);
      var link = document.createElement("a");
      link.target = "_blank";
      link.href = imagesrc;
      link.download = "Download.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDownloadImageForNormalImageButton = (e) => {
    e.preventDefault();

    // if user is Free then redirect to pricing page...
    // if(!data.subscription || data?.subscription.type === "Free"){
    //     navigate("../pricing", { replace: true });
    //     return;
    // }

    if (!user) {
      setWarning("You need to login first.");
      return;
    }

    // if (credits <= 0) {
    //     setWarning("You do not have credit.");
    //     return;
    // }

    let selectedImage = images.filter((each) => each.id === currentImageId);
    console.log("selected image", selectedImage);
    window.open(selectedImage[0].src, "_blank");
  };

  const hanldeMouseEnter = () => {
    promtRef.current.style.opacity = 1;
  };
  const hanldeMouseLeave = () => {
    promtRef.current.style.opacity = 0.1;
  };

  const handleRightClick = () => {
    // currentImageId.
    // let obj = images.filter((each)=> each.id === currentImageId);
    images.forEach((each, i) => {
      if (each.id === currentImageId) {
        let imagePosition = i;
        let imageBucketLength = images.length;
        if (imagePosition + 1 < imageBucketLength) {
          let obj = images[imagePosition + 1];

          // change the current id...
          setCurrentImageId(obj.id);
          // set the image...
          heroImageRef.current.src = obj.src;
          // heroImageRef.current.previousElementSibling.textContent =
          //     obj.prompt;
          promtRef.current.textContent = obj.prompt;

          heroImageRef.current.previousElementSibling.width = obj.width;

          let current = document.querySelector(".lightboxImage.active");
          current.nextElementSibling.classList.add("active");
          current.classList.remove("active");
        }
      }
    });
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999999,
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
        backgroundColor: "#000000f2",
        // backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogModal
        open={open}
        setOpen={setOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        type="Free"
      />

      <Snackbar
        open={Boolean(warning)}
        autoHideDuration={6000}
        onClose={handleWarningClose}
      >
        <Alert
          onClose={handleWarningClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {warning}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          // background: "white",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          // m: 1,
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            // background: "white",
            // height: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // position: "relative",
            position: "abosulate",
            left: "1rem",
            top: "1rem",
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ position: "relative" }}
          >
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <IconButton
                style={{
                  color: "white",
                  background: "#fffdfd7d",
                }}
                onClick={handleLeftClick}
                color="primary"
                size="large"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "80vh",
                my: 2,
                paddingLeft: "unset",
                marginLeft: "unset",
                overflow: "hidden",
              }}
            >
              {slectedImageId ? showImage() : null}
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <IconButton
                size="large"
                style={{
                  color: "white",
                  position: "absolute",
                  right: "1rem",
                  top: "1rem",
                  background: "#fffdfd7d",
                }}
                onClick={handleCloseLightBox}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                style={{
                  color: "white",
                  background: "#fffdfd7d",
                }}
                onClick={handleRightClick}
                size="large"
              >
                <KeyboardArrowRightIcon />
              </IconButton>

              {askedFrom === "lexicaImages" ? (
                <>
                  <IconButton
                    size="large"
                    style={{
                      color: "white",
                      position: "absolute",
                      right: "1rem",
                      bottom: "1rem",
                      background: "#fffdfd7d",
                    }}
                    onClick={handleDownloadImageButton}
                  >
                    <DownloadIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  size="large"
                  style={{
                    color: "white",
                    position: "absolute",
                    right: "1rem",
                    bottom: "1rem",
                    background: "#fffdfd7d",
                  }}
                  onClick={handleDownloadImageForNormalImageButton}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            className="bottomImageWrapper"
            ref={bottomImageWrapperRef}
            style={{
              flexWrap: "nowrap",
              overflowX: "scroll",
              position: "relative",
            }}
            xs={{
              border: "1px solid black",
              display: "flex",
              overflowX: "scroll",
              flexWrap: "nowrap",
              m: 2,
            }}
          >
            {images &&
              images.map((eachImage) => {
                return (
                  <>
                    <img
                      className={
                        eachImage.id === slectedImageId
                          ? "lightboxImage active"
                          : "lightboxImage"
                      }
                      onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onClick={(e) => handleBottomImageClick(e)}
                      id={eachImage.id}
                      src={eachImage.src}
                      alt=""
                      style={{
                        maxHeight: "60px",
                        margin: "5px",
                        cursor: "pointer",
                        height: "100%",
                      }}
                    />
                  </>
                );
              })}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LightBox;
