import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    image: null,
    upscaling: false,
    succeed: false,
    errorMessage: "",
    upscaled: false,
};

export const upscalingSlice = createSlice({
    name: "upscaledImage",
    initialState,
    reducers: {
        upscaleStarted: (state) => {
            state.upscaling = true;
        },
        upscaleSucceed: (state, action) => {
            state.upscaling = false;
            state.errorMessage = "";
            state.succeed = true;
            state.image = action.payload;
            state.upscaled = true;
        },
        upscaleFailed: (state, action) => {
            state.upscaling = false;
            state.errorMessage = action.payload;
            state.image = null;
        },
        upscaleReset: (state) => {
            state.image = null;
            state.upscaling = false;
            state.succeed = false;
            state.errorMessage = "";
            state.upscaled = false;
        },
    },
});

export const { upscaleStarted, upscaleSucceed, upscaleFailed, upscaleReset } =
    upscalingSlice.actions;
export default upscalingSlice.reducer;
