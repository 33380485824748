import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Slide,
} from "@mui/material";
// import { uploadToStorage } from "../../../firestore/storageHandler";

// import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

// import {
//     imageUploaded,
// } from "../../../features/image/adProductSlice";
// import { saveAs } from "file-saver";
import {
    setGeneratedImage,
} from "../../../features/image/ImageVariation";
import { onGettingIdToken } from "../../../firebaseInit";
import { fetchVariantImages, fetchLexicalData } from "../../../axios/fetchdata";
import { uploadVariationsResult } from "../../../firestore/variationsHandler";
import { useAPI } from "../../../StateProvider";
import NeedToBuyAPlan from "../../model/NeedToBuyAPlan";
import { getCreditBalance } from "../../../firestore/dataHandler";
import { onAuth } from "../../../firestore/authHandlers";
import { resizeImageFile } from "../../utils/resizeImageFile";
import ImageImpaintingUploadField from "../../utils/ImageImpaintingUploadField";
/* import VariationInputBox from "./VariationInputBox";
import VariationOutputBox from "./VariationOutputBox"; */

const EraseReplaceImgUpSection = ({
    user,
    productData,
    credits,
    imageVarient = false,
    imagePainting,
}) => {
    // const uploadedImageSizeRef = React.useRef({ w: 512, h: 512 });
    const dispatch = useDispatch();
    // const state = useSelector((state) => state.adProduct);
    // const { generatedImage } = useSelector((state) => state.imageVariation);
    const { data, dataFetched } = useAPI();

    const optionItems = ["By Url", "Upload Image"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const [rangeValue, setRangeValue] = useState(1);

    // const [selectedImage, setSelectedImage] = useState(null);
    const [inputImgUrl, setInputImgUrl] = useState("");
    // const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [variationError, setVariationError] = useState("");

    const [allFetchedLexicalImages, setAllFetchedLexicalImages] = useState();
    const [images, setImages] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const [lockWarning, setLockWarning] = useState(false);
    const [uploadedImgByInput, setUploadedImgByInput] = useState({});

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const config = useSelector((state) => state.imageVariation);
    // const ifImageisGenerated = config.generatedImage;
    const ifImageisGenerated = config.lexicalImagePathFromVeriationPage;
    //console.log(config, "dsad");
    useEffect(() => {
        if (ifImageisGenerated !== "") {
            // fetch the lexical data..
            console.log("calling", ifImageisGenerated);
            fetchLexicalData(ifImageisGenerated, "variation").then((res) => {
                console.log("CONSOLE FOR HERE", res);
                setAllFetchedLexicalImages(res);
                // setImages(res.splice(0, 20));
                setImages(res);
            });
        }

        // mixpanel.track("Rendering image Variation");
    }, [ifImageisGenerated]);

    const convertImageToBlob = (inputImage) => {
        console.log(inputImage, "img src");
        fetch(inputImage)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                console.log(url, "input text url blob");
            });
        // Create a new FileReader object.
        /* let reader = new FileReader();
        reader.readAsDataURL(inputImage);

        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: "image/jpeg" });

            // Return the Blob object.
            console.log(blob, " url blob ");
            return blob;
        }; */

        // Read the image data from the URL.
        /* if (optionSelected === "Type Url") {
            reader.readAsDataURL(inputImage);

            // Once the image data is read, create a new Blob object.
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: "image/jpeg" });

                // Return the Blob object.
                console.log(blob, " url blob ");
                return blob;
            };
        }

        reader.readAsArrayBuffer(inputImage);
        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: inputImage.type });
            // Return the Blob object.
            return blob;
        }; */
    };

    /*
    const handleInputImgUrlSubmit = (event) => {
        event.preventDefault();
        setSelectedImage(inputImgUrl);
        convertImageToBlob(inputImgUrl);

        //fakeApiBgRemove();
    };
    */

    /*
    const handleDefaultInput = (event) => {
        if (event.target.files && event.target.files[0]) {
            const url = URL.createObjectURL(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            console.log(url, "defaultInput url");
            if (productData.productSlug === "advertisement-product") {
                dispatch(imageUploaded({ inputImage: url }));
            }
        }
        setIsLoadingImg(true);
        //fakeApiBgRemove();
    };
    */

    // async function getImageSize(src) {
    //     return new Promise((resolve, reject) => {
    //         let image = new Image();
    //         image.onload = function () {
    //             let h = this.height;
    //             let w = this.width;
    //             if (w > 512) {
    //                 let ratio = 512 / w;
    //                 // console.log(ratio);
    //                 let revisedHeight = parseInt(h * ratio);
    //                 // console.log(revisedHeight);
    //                 resolve({
    //                     height: revisedHeight,
    //                     width: 512,
    //                 });
    //             } else {
    //                 resolve({
    //                     height: this.height,
    //                     width: this.width,
    //                 });
    //             }
    //         };
    //         image.src = src;
    //     });
    // }

    // const convertBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             resolve(reader.result);
    //         };
    //         reader.onerror = (error) => {
    //             reject(error);
    //         };
    //     });
    // };

    // const processUploadAndGenerate = async (file) => {
    //     // CREDIT REDUCTION
    //     // await reduceTheCredit(EXPENSE_RATE.imageReversal);
    //     // const imageString = await convertBase64(file);
    //     // setUploadedImgsrc(imageString);
    //     const result = await uploadToStorage({
    //         file,
    //         uid: user.uid,
    //     });
    //     const { path } = result;

    //     // transer the url to redux..
    //     if (path) {
    //         dispatch(
    //             setImagePathForImageVariationLexical({
    //                 lexicalImagePathFromVeriationPage: path,
    //             })
    //         );
    //     }
    // };


    // const ImageHandler = async (e) => {
    //     /* UploadImage === null
    //         ? window.scrollTo({
    //               top: inputedImageRef.current.offsetTop + 100,
    //               left: 0,
    //               behavior: "smooth",
    //           })
    //         : window.scrollTo({
    //               top: inputedImageRef.current.offsetTop + 200,
    //               left: 0,
    //               behavior: "smooth",
    //           }); */
    //     const mainfile = e.target.files[0];
    //     console.log(mainfile, "formDara mainFile image from by upload");

    //     // console.log(mainfile);

    //     // console.log("Top", inputedImageRef.current.offsetTop);
    //     let imageUrl = URL.createObjectURL(mainfile);
    //     console.log(imageUrl, "image url from ImageHandler");

    //     const tmpSize = await getImageSize(imageUrl);
    //     setSelectedImage(imageUrl);

    //     uploadedImageSizeRef.current = {
    //         w: tmpSize.width,
    //         h: tmpSize.height,
    //     };
    //     /* setUploadImage(imageUrl); */
    //     // selec image
    //     dispatch(
    //         setSelectedImagVariation({
    //             selectedImageVariation: imageUrl,
    //         })
    //     );

    //     if (mainfile) {
    //         console.log(mainfile);
    //         setUploadedImgByInput(mainfile);
    //     }

    //     // make is ready for lexical image search...
    //     processUploadAndGenerate(mainfile);
    // };

    // const handleInputImgUrlChange = (event) => {
    //     setInputImgUrl(event.target.value);
    //     //fakeApiBgRemove();
    //     processUploadAndGenerate(event.target.value);
    // };

    const requestHandler = (formData) => {
        onGettingIdToken((token) => {
            console.log("Fetching started");
            console.log(token, "token");

            fetchVariantImages({ formData, token })
                .then((data) => {
                    setIsLoading(false);
                    uploadVariationsResult(data.data);
                    console.log("image to dispatch");
                    dispatch(
                        setGeneratedImage({
                            generatedImage: data.data,
                        })
                    );
                })
                .catch((error) => {
                    setVariationError(
                        "Oops! Something Went Wrong. Please Try Again."
                    );
                    setIsLoading(false);
                });
        });
    };

    // const handleImgUrlSubmitClick = () => {
    //     setIsLoading(true);
    //     setVariationError("");
    //     handleSubmit(inputImgUrl);
    // };

    // const handleRangeChange = (event, newValue) => {
    //     setRangeValue(newValue);
    // };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        let imageSrc = "";
        let formElement;
        if (typeof e === "object" && e !== null && e.type) {
            e.preventDefault();
            formElement = uploadedImgByInput;
        } else {
            imageSrc = e;
        }

        if (!data.subscription || data?.subscription.type === "Free") {
            // navigate to pricing page...
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            setLockWarning(true);
            return;
        }

        onAuth(async (current_user) => {
            if (!current_user) {
                //setShowPopup(true);
                setIsLoading(false);
            } else {
                getCreditBalance(current_user.email).then(async (credits) => {
                    const finalCredit = credits - 2;
                    if (finalCredit < 0) {
                        //setOpen(true);
                        setIsLoading(false);
                        return;
                    }

                    let formData;

                    if (optionSelected === "By Url") {
                        //console.log(imageSrc, "Image Source");
                        formData = new FormData();
                        formData.append("input_image", inputImgUrl);
                        formData.append("num_outputs", 1);
                        formData.append("service", "variation");
                        /* console.log(
                            formData.get("input_image"),
                            "formDara image from by url"
                        );
                        for (const [key, value] of formData.entries()) {
                            console.log(key, value);
                        } */
                        requestHandler(formData);
                    } else {
                        formData = new FormData();

                        formData.append("num_outputs", rangeValue);
                        formData.append("service", "variation");
                        // const formProps = Object.fromEntries(formData);
                        //const existingFile = formData.get("input_image");
                        const resizedFile = await resizeImageFile(
                            uploadedImgByInput
                        );
                        resizedFile === null && alert("No data");
                        // console.log(resizedFile, "resizedFile");
                        formData.set("input_image", resizedFile);
                        /* for (const [key, value] of formData.entries()) {
                            console.log(key, value, "myOne");
                        } */
                        requestHandler(formData);
                    }
                });
            }
        });
    };

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     console.log(file, "inputted image");

    //     if (file) {
    //         handleSubmit(e);
    //     }
    // };

    // const downloadImage = () => {
    //     saveAs(generatedImage[0], "download-image.png");
    // };

    return (
        <Box>
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}

            {/* <Box
                sx={{
                    border: `4px dashed ${
                        theme.palette.mode === "light" ? "black" : "#F0F0F0"
                    }`,
                    width: "100%",
                    maxWidth: "900px",
                    borderRadius: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "10rem",
                    margin: "0 auto",
                    cursor: "pointer",
                    position: "relative",
                }}
            >
                <form
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{
                            padding: { xs: "0px", md: "0px" },
                            fontSize: { xs: "0.9rem", md: "1.2rem" },
                            width: "fit-content",
                            height: "fit-content",
                        }}
                    >
                        Choose files to upload images
                    </Typography>
                    <input
                        style={{
                            opacity: "0",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                        }}
                        type="file"
                        name="image_file"
                        onChange={ImageHandler}
                    />
                    
                </form>
            </Box> */}

            <ImageImpaintingUploadField
                imagePainting={imagePainting}
                user={user}
                credits={credits}
                imageVarient={imageVarient}
            />
        </Box>
    );
};

export default EraseReplaceImgUpSection;
