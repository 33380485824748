import React, { useState, useRef } from "react";
import {
    Typography,
    Box,
} from "@mui/material";
import styles from "./imgUpSection.module.css";
import WorkSpace from "./WorkSpace/WorkSpace";
import OptionSelected from "../atoms/OptionSelected";
import { HiChevronDoubleRight } from "react-icons/hi";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    imageUploaded,
    generationStarted,
    generationSucceeded,
    generationFailed,
} from "../../../features/image/adProductSlice";
import { useAPI } from "../../../StateProvider";
import { getAdProductImage } from "../../../axios";
import { reduceTheCredit } from "../../../firestore/dataHandler";
import { uploadProductGenResult } from "../../../firestore/variationsHandler";
import { Slide } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { onAuth } from "../../../firestore/authHandlers";
import { getCreditBalance } from "../../../firestore/dataHandler";
import { resizeImageFile } from "../../utils/resizeImageFile";
import { onGettingIdToken } from "../../../firebaseInit";
import { fetchVariantImages } from "../../../axios/fetchdata";
import { uploadVariationsResult } from "../../../firestore/variationsHandler";
import {
    setGeneratedImage,
} from "../../../features/image/ImageVariation";

import NeedToBuyAPlan from "../../model/NeedToBuyAPlan";
import ToastNotification from "../../atoms/ToastNotification";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/*
const ImageFileInput = ({ fileInputRef, onChange }) => {
    return (
        <>
            <Typography
                onClick={() => fileInputRef.current.click()}
                variant="h6"
                color="text.secondary"
                sx={{ padding: "100px" }}
            >
                Choose files to upload images
            </Typography>
            <input
                style={{ display: "none" }}
                type="file"
                name="image_file"
                ref={fileInputRef}
                onChange={onChange}
            />
        </>
    );
};
*/

const ImgUpSection = ({ productData, user }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const formRef = useRef();
    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.adProduct);
    const {
        imageNum,
        promptText,
        imgNum,
    } = state;
    const { data, dataFetched } = useAPI();
    let credits = data?.credits;

    const [loginPopup, setLoginPopup] = useState(false);
    const [lockWarning, setLockWarning] = useState(false);

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageStrength, setImageStrength] = useState(1);
    const [open, setOpen] = useState(1);
    const [variationError, setVariationError] = useState("");

    //onGenerateOutput(e, 'replace')
    const onGenerateOutput = async (e, type, formData) => {
        // if there is no user show the popup...
        setIsLoading(false);
        setShowSuccessPopup(false);
        setShowErrorPopup(false);

        if (!user) {
            setLoginPopup(true);
            dispatch(generationFailed({ errorMessage: "" }));
            setTimeout(() => {
                navigate("/login");
            }, 3000);
            return;
        }

        //  free user are not allowed to generate..
        if (!data.subscription || data?.subscription.type === "Free") {
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            // navigate to pricing page...
            setLockWarning(true);
            return;
        }

        dispatch(generationStarted());
        /* scrollToLoader(); */

        const cost = imageNum * 2;
        const remaining = credits - cost;
        if (remaining <= 0) {
            dispatch(
                generationFailed({
                    errorMessage:
                        "You don't have enough credits to generate images.",
                })
            );

            return;
        }
        try {
            setIsLoading(true);

            const formData = new FormData(formRef.current);
            formData.append("prompt", promptText);
            formData.append("image_num", imgNum);
            /* if (selectedImage) {
                formData.set("image_file", inputImage);
            } */
            for (let entry of formData.entries()) {
                console.log(entry);
            }
            const result = await getAdProductImage(formData);
            const outputs = result.data;
            dispatch(generationSucceeded({ outputs: outputs }));
            reduceTheCredit(cost);
            uploadProductGenResult({
                images: outputs,
            });
            setIsLoading(false);

            setShowSuccessPopup(true);
        } catch (error) {
            setIsLoading(false);

            console.log(error.message);
            dispatch(
                generationFailed({
                    errorMessage: "Something Went Wrong. Please Try Again.",
                })
            );
            setShowErrorPopup(true);
        }
    };

    /* try {
        
        console.log(formData, " Form Data mine ");
        const result = await getAdProductImage(formData);
        const outputs = result.data;
        dispatch(generationSucceeded({ outputs: outputs }));
        reduceTheCredit(cost);
        uploadProductGenResult({
            images: outputs,
        });
    } */

    const [selectedImage, setSelectedImage] = useState(null);
    const [inputImgUrl, setInputImgUrl] = useState("");

    const [image, setImage] = useState(null);
    const [optionSelected, setOptionSelected] = useState("Type Url");

    const optionItems = ["Type Url", "Upload Image"];
    //console.log(mainProductsData);

    /* handle loading start */

    const [progress, setProgress] = useState(0);
    const [isLoadingImg, setIsLoadingImg] = useState(false);

    /* useEffect(() => {
        const interval = setInterval(() => {
            setProgress((progress) => (progress + 1) % 100);
        }, 20);

        return () => {
            clearInterval(interval);
        };
    }, []); */
    /* handle loading end */

    /* useEffect(() => {
        const img = new window.Image();
        img.src = selectedImage;
        img.onload = () => {
            setImage(img);
        };
    }, [selectedImage, image]); */

    const isMobile = window.innerWidth <= 575.98;

    /* from here down - handle Input */
    const fakeApiBgRemove = () => {
        setTimeout(() => {
            setIsLoadingImg(false);
        }, 2000);
    };

    /* const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            setSelectedImage(event.target.result);
        };

        reader.readAsDataURL(file);
        setIsLoadingImg(true);
        fakeApiBgRemove();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    }; */

    const handleDefaultInput = (event) => {
        if (event.target.files && event.target.files[0]) {
            const url = URL.createObjectURL(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            console.log(url, "defaultInput url");
            if (productData.productSlug === "advertisement-product") {
                dispatch(imageUploaded({ inputImage: url }));
            }
        }
        setIsLoadingImg(true);
        //fakeApiBgRemove();
    };

    /* const onImageUpload = async (e) => {
        // const { path } = await uploadToStorage({ file: e.target.files[0] });
        //console.log(e.target.files[0].type, "File Tpye");
        const url = URL.createObjectURL(selectedImage);
        console.log(url, "my url");
        dispatch(imageUploaded({ inputImage: url }));
        scrollToImage();
    }; */

    const handleInputImgUrlChange = (event) => {
        setInputImgUrl(event.target.value);
        //fakeApiBgRemove();
    };

    const convertImageToBlob = (inputImage) => {
        console.log(inputImage, "img src");
        fetch(inputImage)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                console.log(url, "input text url blob");
            });
        // Create a new FileReader object.
        /* let reader = new FileReader();
        reader.readAsDataURL(inputImage);

        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: "image/jpeg" });

            // Return the Blob object.
            console.log(blob, " url blob ");
            return blob;
        }; */

        // Read the image data from the URL.
        /* if (optionSelected === "Type Url") {
            reader.readAsDataURL(inputImage);

            // Once the image data is read, create a new Blob object.
            reader.onload = function () {
                let blob = new Blob([reader.result], { type: "image/jpeg" });

                // Return the Blob object.
                console.log(blob, " url blob ");
                return blob;
            };
        }

        reader.readAsArrayBuffer(inputImage);
        // Once the image data is read, create a new Blob object.
        reader.onload = function () {
            let blob = new Blob([reader.result], { type: inputImage.type });
            // Return the Blob object.
            return blob;
        }; */
    };

    const handleInputImgUrlSubmit = (event) => {
        event.preventDefault();
        setSelectedImage(inputImgUrl);
        convertImageToBlob(inputImgUrl);

        //fakeApiBgRemove();
    };

    const handleExtraImgClick = (e, currImg) => {
        /* fetch(currImg)
            .then((response) => response.blob())
            .then((blob) => {
                const objectURL = URL.createObjectURL(blob);
                // Use the objectURL as needed
                //console.log(objectURL, " this img url ");
                dispatch(imageUploaded({ inputImage: objectURL }));
            })
            .catch((error) => {
                // Handle any errors
                console.error(error);
            });
        //dispatch(imageUploaded({ inputImage: url }));
        setSelectedImage(currImg);
        //setSelectedImage(e.target.src);
        setIsLoadingImg(true);
        //fakeApiBgRemove(); */
        formRef.current.click(e);
    };

    const onImageUpload = async (e) => {
        // const { path } = await uploadToStorage({ file: e.target.files[0] });
        console.log(e.target.files[0].type, "File Tpye");
        const url = URL.createObjectURL(e.target.files[0]);
        setSelectedImage(URL.createObjectURL(e.target.files[0]));

        dispatch(imageUploaded({ inputImage: url }));

        //scrollToImage();
    };

    const requestHandler = (formData) => {
        onGettingIdToken((token) => {
            console.log(token);
            console.log(formData);

            fetchVariantImages({ formData, token })
                .then((data) => {
                    setIsLoading(false);
                    uploadVariationsResult(data.data);
                    dispatch(
                        setGeneratedImage({
                            generatedImage: data.data,
                        })
                    );
                })
                .catch((error) => {
                    setVariationError(
                        "Oops! Something Went Wrong. Please Try Again."
                    );
                    setIsLoading(false);
                });
        });
    };

    const handleSubmit = async (e) => {
        let imageSrc = "";
        let formElement;
        if (typeof e === "object" && e !== null && e.type) {
            console.log("Submit Handling");
            e.preventDefault();
            formElement = e.currentTarget;
        } else {
            imageSrc = e;
        }

        mixpanel.track("Submit images for generating image Variation");
        // free user are not allowed to generate..
        if (!data.subscription || data?.subscription.type === "Free") {
            // navigate to pricing page...
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            setLockWarning(true);
            return;
        }

        onAuth(async (current_user) => {
            if (!current_user) {
                setShowErrorPopup(true);
                setIsLoading(false);
            } else {
                getCreditBalance(current_user.email).then(async (credits) => {
                    const finalCredit = credits - imageStrength;
                    if (finalCredit < 0) {
                        setOpen(true);
                        setIsLoading(false);
                        return;
                    }

                    let formData;

                    /* if (isext) {
                        formData = new FormData();
                        const imgsrc = document.querySelector(
                            "#selected-input-image"
                        ).src;
                        formData.append("input_image", imgsrc);
                        formData.append("num_outputs", imageStrength);
                        formData.append("service", "variation");
                        console.log(formData.get("input_image"));
                        requestHandler(formData);
                    } else  */
                    if (optionSelected === "Type Url") {
                        formData = new FormData(formElement);
                        formData.append("num_outputs", imageStrength);
                        formData.append("service", "variation");
                        // const formProps = Object.fromEntries(formData);
                        const existingFile = formData.get("input_image");
                        const resizedFile = await resizeImageFile(existingFile);
                        resizedFile === null && alert("No data");
                        formData.set("input_image", resizedFile);
                        requestHandler(formData);
                    } else if (optionSelected === "Upload Image") {
                        //console.log(imageSrc, "Image Source");
                        formData = new FormData();
                        /* formData.append("input_image", imageSrc); */
                        formData.append("input_image", selectedImage);
                        formData.append("num_outputs", 1);
                        formData.append("service", "variation");
                        console.log(formData.get("input_image"));
                        requestHandler(formData);
                    }
                });
            }
        });

        /* if (variantBy !== "url") {
            window.scrollTo({
                top: outputImageRef.current.offsetTop,
                left: 0,
                behavior: "smooth",
            });
        } */
    };

    return (
        <>
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}
            {isLoading && (
                <ToastNotification
                    message="AISEO is generating your image"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {loginPopup && (
                <ToastNotification
                    message="Please Login"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showErrorPopup && (
                <ToastNotification
                    message="An error occurred, Try again"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showSuccessPopup && (
                <ToastNotification
                    message="Image generated Successfully"
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}

            {productData.productHasInputVariations && (
                <OptionSelected
                    optionItems={optionItems}
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                />
            )}
            {productData.productSlug === "advertisement-product" && (
                <Box
                    sx={{
                        border: `4px dashed ${
                            theme.palette.mode === "light" ? "black" : "#F0F0F0"
                        }`,
                        width: "100%",
                        maxWidth: "900px",
                        borderRadius: "2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "10rem",
                        margin: "0 auto",
                        cursor: "pointer",
                    }}
                >
                    <form
                        ref={formRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        onClick={() => fileInputRef.current.click()}
                    >
                        <Typography
                            variant="h6"
                            color="text.secondary"
                            sx={{
                                padding: { xs: "0px", md: "0px" },
                                fontSize: { xs: "0.9rem", md: "1.2rem" },
                                width: "fit-content",
                                height: "fit-content",
                            }}
                        >
                            Choose files to upload images
                        </Typography>
                        <input
                            style={{ display: "none" }}
                            type="file"
                            name="image_file"
                            ref={fileInputRef}
                            onChange={onImageUpload}
                        />
                        {/* <ImageFileInput
                        fileInputRef={fileInputRef}
                        onChange={onImageUpload}
                    /> */}
                    </form>
                </Box>
            )}
            {/* <Button
                sx={{ background: "blue", color: "white" }}
                onClick={handleSubmit}
            >
                Generate Variation
            </Button> */}

            <div className={styles.imgUpSectionContainer}>
                {productData.productSlug !== "advertisement-product" && (
                    <Box
                        className={styles.imgInputBox}
                        sx={{
                            border: `4px dashed ${
                                theme.palette.mode === "light"
                                    ? "black"
                                    : "#F0F0F0"
                            }`,
                        }}
                    >
                        {optionSelected === "Type Url" &&
                        productData.productHasInputVariations ? (
                            <div
                                className={styles.imgInputTextBox}
                                /* onDrop={handleDrop}
                onDragOver={handleDragOver} */
                            >
                                <form onSubmit={handleInputImgUrlSubmit}>
                                    {/* <p>Click, paste, or drop a file here to start.</p> */}
                                    <input
                                        type="url"
                                        placeholder="Type the URL of the image"
                                        value={inputImgUrl}
                                        onChange={handleInputImgUrlChange}
                                        /* onChange={(e) => handleDefaultInput(e)} */
                                    ></input>
                                    <button type="submit">
                                        <HiChevronDoubleRight />
                                    </button>
                                </form>
                            </div>
                        ) : (
                            <Box
                                className={styles.imgInputDropperBox}
                                /* onDrop={handleDrop}
                    onDragOver={handleDragOver} */
                                sx={{
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.mode === "light"
                                                ? "#e5e7eb"
                                                : "#3F3C3C",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#281142"
                                                : "#EFEFEF",
                                        fontWeight: "500",
                                        fontSize: "1.2rem",
                                    }}
                                >
                                    Choose an image
                                </Typography>
                                {/* <Typography
                        sx={{
                            color:
                                theme.palette.mode === "light"
                                    ? "#281142"
                                    : "#EFEFEF",
                            fontWeight: "500",
                            fontSize: "0.9rem",
                        }}
                    >
                        Click, paste, or drop a file here to start.
                    </Typography> */}
                                <input
                                    type="file"
                                    accept="image/png,image/jpeg,image/webp"
                                    onChange={(e) => handleDefaultInput(e)}
                                    required
                                ></input>
                            </Box>
                        )}
                    </Box>
                )}

                {/* <div className={styles.imgOptionalText}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        width="32"
                        height="32"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17 13l-5 5m0 0l-5-5m5 5V6"
                        ></path>
                    </svg>
                    <Typography
                        sx={{
                            color:
                                theme.palette.mode === "light"
                                    ? "#281142"
                                    : "#EFEFEF",
                            fontWeight: "500",
                            fontSize: "0.9rem",
                        }}
                    >
                        Or try with an example
                    </Typography>
                </div>

                <div className={styles.optionalImgContainer}>
                    {productData.productTryImages.map((item, index) => (
                        <img
                            loading="lazy"
                            width="130"
                            height="130"
                            src={item}
                            key={index}
                            onClick={(e) => handleExtraImgClick(e, item)}
                        />
                    ))}
                </div> */}
            </div>

            {/* img preview */}
            {/* {selectedImage ? (
                <div className={styles.imgPreviewContainer}>
                    <div>
                        <img
                            src={selectedImage}
                            alt=""
                            style={{
                                width: "500px",
                                height: "500px",
                                margin: "0 auto",
                            }}
                        />
                    </div>
                    <div className={styles.imgPreviewFormBox}>
                        <textarea placeholder="Describe a new scene or leave this empty">
                            A Tokyo street at night with a blurry background
                        </textarea>
                    </div>
                    <div className={styles.imgPreviewFormButton}>
                        <button>
                            Generate <span>PRO</span>
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )} */}
            {selectedImage ? (
                <WorkSpace
                    productData={productData}
                    selectedImage={selectedImage}
                    onGenerateOutput={onGenerateOutput}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default ImgUpSection;
