import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseInit";
import { onAuth } from "./authHandlers";
const db = getFirestore(firebaseApp);

// query update a template by upvote and downvote
// const voteData = {
//     id: template.id,
//     upvote: liked,
//   };

export const updateTemplateByVote = async (voteData) => {
  const { id, upvote } = voteData;
  const q = query(collection(db, "customTemplates"), where("id", "==", id));
  console.log("user docs id:", id);

  var upvoteValue = 1;
  var downvoteValue = 1;
  var voterDetailsValue = [];

  const querySnapshot = await getDocs(q);
  let docs = [];

  onAuth(async (user) => {
    // console.log("user", user.email);
    // console.log("upvote", upvote);
    var updateData = {
      upvote: upvoteValue,
      voterDetails: [...voterDetailsValue, { email: user.email }],
    };
    try {
      querySnapshot.forEach((item) => {
        docs.push(item.data());
        const docId = item.id;
        const docRef = doc(db, "customTemplates", docId);

        // console.log(item.id);
        // console.log("single template voterDetails:", item.data().voterDetails);
        // console.log("isUpvote:", item.data().upvote);

        if (item.data().upvote >= 0) upvoteValue = item.data().upvote + 1;
        if (item.data().downvote >= 0) upvoteValue = item.data().upvote + 1;
        if (item.data().voterDetails.length > 0)
          voterDetailsValue = item.data().voterDetails;

        if (upvote === true) {
          updateData = {
            upvote: upvoteValue,
            voterDetails: [...voterDetailsValue, { email: user.email }],
          };
        } else {
          updateData = {
            downvote: downvoteValue,
            voterDetails: [{ email: user.email }],
          };
        }

        // console.log("updateData", updateData);

        updateDoc(docRef, updateData);
      });
    } catch (error) {
      console.log(error);
    }
  });

  console.log("single template docs:", docs);
};

export const readOrCreateDocument = async (id, isClicked) => {
  console.log("req.params", id);
  console.log("req.params isClicked", isClicked);

  // const artRef = db.collection("extensionPopup").doc(id);

  const newData = {
    uid: id,
    isClosed: true,
  };

  const docRef = doc(db, "extensionPopup", id);
  const snap = await getDoc(docRef);
  try {
    isClicked && (await setDoc(docRef, newData));
    console.log("isClicked", isClicked);

    // const docSnap = await artRef.get();
    if (snap.exists()) {
      const data = { message: "Document Exist", hide: true };
      return data;
    } else {
      const data = { message: "Document does not exist" };
      // await setDoc(docRef, newData);
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const introPopup = async (id, isClicked, value) => {
  console.log("req.params", id);
  console.log("req.params isClicked", isClicked);

  // const artRef = db.collection("extensionPopup").doc(id);

  const newData = {
    uid: id,
    isClosed: true,
    value: value
  };

  const docRef = doc(db, "introPopup", id);
  const snap = await getDoc(docRef);
  try {
    isClicked && (await setDoc(docRef, newData));
    console.log("isClicked", isClicked);

    // const docSnap = await artRef.get();
    if (snap.exists()) {
      const data = { message: "Document Exist", hide: true };
      return data;
    } else {
      const data = { message: "Document does not exist" };
      // await setDoc(docRef, newData);
      return data;
    }
  } catch (error) {
    return error;
  }
};
