import React, { useState, useEffect, useRef } from "react";

import {
    Box,
    Button,
    IconButton,
    Typography,
    LinearProgress,
} from "@mui/material";
import OptionSelected from "./OptionSelected";
import Layout from "../layout/Layout";
import Footer from "../footer/Footer";
import { useAPI } from "../../StateProvider";

const optionSelectedStyleConfig = {
    option: {
        active: {
            bgColor: "#D5F6FF",
            textColor: "#14AAFF",
        },
        inactive: {
            bgColor: "transparent",
            textColor: "#7A92A5",
        },
    },
    container: {
        lightMode: {
            bgColor: "white",
            borderColor: "#D0F4FF",
            boxShadow: "0px 4px 15px #E8FAFF",
        },
        darkMode: {
            bgColor: "transparent",
            borderColor: "#D0F4FF",
            boxShadow: "none",
        },
    },
};

const CancelSubscription = () => {
    const { data } = useAPI();
    const [progress, setProgress] = React.useState(0);

    const optionItems = ["Art", "Video", "Avatars"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    return (
        <Layout footer={<Footer />}>
            <Box>
                <Typography>Subscriptions</Typography>
                <OptionSelected
                    optionItems={optionItems}
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                    styleConfigProp={optionSelectedStyleConfig}
                />
                {data.subscription}
            </Box>
        </Layout>
    );
};

export default CancelSubscription;
