import React, { useState, useEffect, useContext } from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
    InputAdornment,
    TextField,
    FormControl,
    InputLabel,
    Select,
    FormGroup,
    FormControlLabel,
    Switch,
    Dialog,
    Icon,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import { handleLogout } from "../../firestore/authHandlers";
import {
    HiOutlineSearch,
    HiOutlinePlusCircle,
    HiOutlineArrowSmRight,
    HiOutlineXCircle,
    HiOutlineFolderAdd,
} from "react-icons/hi";
import OptionSelected from "../atoms/OptionSelected";
import { useDispatch, useSelector } from "react-redux";
import {
    customTemplatesFetched,
    templatesFetched,
    templatesSortedSet,
} from "../../features/templates/templateSlice";
import RequestTemplateBox from "./RequestTemplateBox";
import { styled, useTheme } from "@mui/material/styles";
import { uploadToStorage } from "../../firestore/storageHandler";
import {
    serverTimestamp,
    collection,
    setDoc,
    getFirestore,
    addDoc,
    onSnapshot,
    doc,
} from "firebase/firestore";
import { firebaseApp, getFirebaseAuthToken } from "../../firebaseInit";
import ToastNotification from "../atoms/ToastNotification";
import WarningModal from "../form/WarningModal";

const TemplatesHeader = ({
    handleSearchInputChange,
    optionItems,
    optionSelected,
    setOptionSelected,
    handleStockimageSelection,
    handleCustomTemplateChange,
    customTemplatesContainer,
    setRenderedMoreThanOnce,
    user,
    showSortOptions,
    setShowSortOptions,
}) => {
    const theme = useTheme();
    const db = getFirestore(firebaseApp);

    const [loginPopup, setLoginPopup] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showValidationError, setShowValidationError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        category: "",
        description: "",
        imageFile: null,
        imageURL: "",
        accepted: false,
    });
    const templates = useSelector((state) => state.templates.templates);
    const customTemplates = useSelector(
        (state) => state.templates.customTemplates
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    /* const optionItems = ["Templates", "Public Templates"]; */
    //const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const [sortBy, setSortBy] = useState("mostlyused"); // State for storing the selected sort by option

    const handleSortByChange = (event) => {
        setRenderedMoreThanOnce(true);
        const sortBy = event.target.value;
        setSortBy(sortBy);

        // Create a copy of the templates array
        const sortedTemplates = [...templates];

        // Sort the templates based on the selected sortBy option
        if (sortBy === "mostlyviewed") {
            sortedTemplates.sort((a, b) => b.viewQty - a.viewQty);
        } else if (sortBy === "leastviewed") {
            sortedTemplates.sort((a, b) => a.viewQty - b.viewQty);
        } else if (sortBy === "mostlyused") {
            sortedTemplates.sort((a, b) => b.usedQty - a.usedQty);
        } else if (sortBy === "leastused") {
            sortedTemplates.sort((a, b) => a.usedQty - b.usedQty);
        } else if (sortBy === "default") {
            /* dispatch(
                templatesFetched({
                    templates: [...templates],
                })
            ); */
            dispatch(
                templatesFetched({
                    templates: templates.map((template) => {
                        const { createdAt, ...templateWithoutCreatedAt } =
                            template;
                        return templateWithoutCreatedAt;
                    }),
                })
            );
            return;
        }

        // Update the state with the sorted templates
        //console.log(sortedTemplates);
        dispatch(
            templatesFetched({
                templates: sortedTemplates.map((template) => {
                    const { createdAt, ...templateWithoutCreatedAt } = template;
                    console.log(templateWithoutCreatedAt, "dsads");
                    return templateWithoutCreatedAt;
                }),
            })
        );
        /* dispatch(
            templatesFetched({
                templates: [...sortedTemplates],
            })
        ); */
        /* dispatch(templatesSortedSet(sortedTemplates)); */
    };

    const resetFormData = () => {
        console.log("reset");
        setFormData({
            category: "",
            description: "",
            imageFile: null,
            imageURL: "",
            accepted: false,
        });
    };

    /* const handleSubmit = async () => {
        
        if (formData.category !== "" && formData.description !== "") {
            console.log(formData);
        } else {
            console.log("Please fill up category or desc");
        }

        // Now post the data to Firebase
        // You can use a Firebase function here to add the data to your collection

        // Close the modal after submission
        setOpenModal(false);
    }; */

    const handleSubmit = async () => {
        setShowSuccessPopup(false);
        setShowValidationError(false);

        if (!formData.category || !formData.description) {
            // Handle validation errors here
            console.log("Both category and description are required");
            setShowValidationError(true);
            setShowSuccessPopup(false);

            return;
        }

        setIsLoading(true);

        let imageUrl = null;

        if (formData.imageFile) {
            try {
                const uploadResult = await uploadToStorage({
                    file: formData.imageFile,
                    uid: "your-uid",
                }); // Replace 'your-uid' with the actual uid
                imageUrl = uploadResult.url;
            } catch (error) {
                console.error("Error uploading the image:", error);
                return;
            }
        }

        const dataToSubmit = {
            name: formData.category,
            description: formData.description,
            imageUrl: imageUrl,
            accepted: false,
        };
        console.log(dataToSubmit);

        // Now, you can push this data to your Firebase collection
        let reqTemplateRef;
        try {
            // setIsLoading(true);

            // setLoadingMsg("Sending Scenes over to AISEO ...");

            reqTemplateRef = await addDoc(
                collection(db, "templateRequest"),
                dataToSubmit
            );
            setIsLoading(false);
            setShowSuccessPopup(true);

            //setIsLoading(false);
        } catch (err) {
            console.log("Firebase write error:" + err);
            /* setIsLoading(false);
            setErrMsg("Couldn't Send Scenes to AISEO ");
            setIsErr(true); */
        }
        console.log("Document written with ID: ", reqTemplateRef.id);
        resetFormData();
    };

    /* useEffect(() => {
        if (templates && templates.length > 0) {
            const sortedTemplates = [...templates];
            console.log(sortedTemplates, "prevsortedtemplates");
            sortedTemplates.sort((a, b) => b.usedQty - a.usedQty);
            console.log(sortedTemplates, "nextsortedtemplates");

            dispatch(
                templatesFetched({
                    templates: templates.map((template) => {
                        const { createdAt, ...templateWithoutCreatedAt } =
                            template;
                        return templateWithoutCreatedAt;
                    }),
                })
            );
            console.log("ran");
        }
    }, [templates]); */

    const handleCustomSortByChange = (event) => {
        const sortBy = event.target.value;
        setSortBy(sortBy);

        // Create a copy of the templates array
        const sortedTemplates = [...customTemplatesContainer];
        // Sort the templates based on the selected sortBy option
        if (sortBy === "mostpopular") {
            /* sortedTemplates.sort((a, b) => b.upvote - a.upvote); */
            sortedTemplates.sort((a, b) => {
                // Check if both templates have the upvote property
                if (a.upvote && b.upvote) {
                    return b.upvote - a.upvote;
                }
                // Handle templates without the upvote property
                else if (!a.upvote && b.upvote) {
                    return 1; // b comes before a
                } else if (a.upvote && !b.upvote) {
                    return -1; // a comes before b
                }
                // Handle case when both templates don't have the upvote property
                else {
                    return 0; // maintain the existing order
                }
            });
            console.log(sortedTemplates, "sorted Templates my most popular");
            handleCustomTemplateChange(sortedTemplates);
        } else {
            handleCustomTemplateChange(customTemplates);
        }
    };

    const handleArtGenBtnClick = () => {
        navigate("/products/art-generator");
    };

    //
    return (
        <Container
            sx={{
                justifyContent: "space-between",
                /* borderBottom: "1.4px solid #E5E5E5", */
                fontFamily: "Inter, Roboto, Arial, sans-serif",

                padding: { xs: "0.7rem 0.5rem", md: "0.8rem 0" },
            }}
        >
            <WarningModal
                open={loginPopup}
                onToggle={() => setLoginPopup(!loginPopup)}
                message="Please log in with your account or make a new one to start generating images."
            />
            <OptionSelected
                optionItems={optionItems}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
            />
            <Box
                sx={{
                    width: "fit-content",
                    margin: "0.3rem auto 0.6rem auto",
                    height: "2rem",
                }}
            >
                {optionSelected !== "Templates" ? (
                    <FormGroup sx={{}}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={<Switch />}
                            onChange={handleStockimageSelection}
                            label="Stock Images"
                        />
                    </FormGroup>
                ) : null}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    margin: "1rem 0",
                }}
                style={{ gap: window.innerWidth < 768 ? "0.8rem" : "0.6rem" }}
            >
                <Button
                    sx={{
                        textTransform: "capitalize",
                        borderRadius: "0.5rem",
                        background: "#E8E8FF",
                        /* background: "#6464F4", */
                        border: "2px solid #4A4AC9",
                        /* color: "white", */
                        color: "#6464F4",
                        paddingX: "1rem",
                        fontSize: "0.9rem",
                        fontWeight: "800",
                        transition: "none",

                        "&:hover": {
                            background: "#6464F4",
                            color: "white",
                        },
                    }}
                    onClick={() => handleArtGenBtnClick()}
                >
                    <Typography sx={{ marginRight: "0.3rem" }}>
                        Art Generator
                    </Typography>
                    <HiOutlineArrowSmRight size={20} />
                </Button>
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <HiOutlineSearch size={20} />
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleSearchInputChange}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    {optionSelected === "Templates" ? (
                        <Select
                            labelId="sort-by-label"
                            value={sortBy}
                            label="Sort By"
                            sx={{ width: { xs: "100%", md: "200px" } }}
                            onChange={handleSortByChange}
                        >
                            {/*  <MenuItem values="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem> */}
                            {/* <MenuItem value="default">Default</MenuItem> */}
                            <MenuItem value="mostlyused">Mostly Used</MenuItem>
                            <MenuItem value="leastused">Least Used</MenuItem>
                            <MenuItem value="mostlyviewed">
                                Mostly Viewed
                            </MenuItem>
                            <MenuItem value="leastviewed">
                                Least Viewed
                            </MenuItem>
                        </Select>
                    ) : (
                        <Select
                            labelId="sort-by-label"
                            value={sortBy}
                            label="Sort By"
                            sx={{ width: { xs: "100%", md: "200px" } }}
                            onChange={handleCustomSortByChange}
                        >
                            {/*  <MenuItem value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem> */}
                            <MenuItem value="default">Default</MenuItem>
                            <MenuItem value="mostpopular">
                                Mostly Popular
                            </MenuItem>
                        </Select>
                    )}
                </FormControl>
                <Button
                    sx={{
                        background: "#6363FF",
                        color: "white",
                        border: "2px solid #6363FF",
                        /* width: "130px", */
                        borderRadius: "2rem",
                        paddingX: "1rem",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#5050D6",
                        },
                        position: "relative",
                    }}
                    onClick={() =>
                        navigate("../customTemplate", { replace: true })
                    }
                >
                    <Typography
                        sx={{
                            textTransform: "capitalize",
                            marginRight: "0.3rem",
                        }}
                    >
                        {/* Add  */}Custom Template
                    </Typography>
                    <Typography
                        sx={{
                            background: "white",
                            color: "#4F46E5",
                            padding: "0.1rem 0.7rem",
                            fontSize: "0.7rem",
                            borderRadius: "30px",
                            position: "absolute",
                            right: "-1rem",
                            top: "-1rem",
                            border: "1.5px solid #4F46E5",
                        }}
                    >
                        New
                    </Typography>
                    <HiOutlinePlusCircle size="20" />
                </Button>
                <Button
                    sx={{
                        background: "#6363FF",
                        color: "white",
                        border: "2px solid #6363FF",
                        /* width: "130px", */
                        borderRadius: "2rem",
                        paddingX: "1rem",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#5050D6",
                        },
                    }}
                    onClick={() => {
                        if (!user) {
                            setLoginPopup(true);
                            //dispatch(generationFailed({ errorMessage: "" }));
                            return;
                        }
                        setOpenModal(true);
                        setShowSuccessPopup(false);
                    }}
                    /* onClick={() =>
                        navigate("../customTemplate", { replace: true })
                    } */
                >
                    <Typography
                        sx={{
                            textTransform: "capitalize",
                            marginRight: "0.3rem",
                        }}
                    >
                        {/* Add  */}Request Template
                    </Typography>
                    <HiOutlineFolderAdd size="20" />
                </Button>
            </Box>
            <Dialog
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    resetFormData();
                }}
                sx={{
                    "& > div > div": {
                        background:
                            theme.palette.mode === "light"
                                ? "white"
                                : "#1E293B",
                        borderRadius: "0.5rem",
                        maxWidth: { xs: "100vw", md: "70vw" },
                        width: { xs: "100vw", md: "fit-content" },
                        overflowY:
                            window.innerWidth < 321 ? "scroll" : "hidden",
                        overflowX: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        maxHeight: { xs: "98vh", md: "90vh" },
                        position: "relative",
                        margin: "0px",
                    },
                    "& > div": {
                        padding: "0",
                    },
                }}
            >
                <Box sx={{ padding: "2rem" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
                        >
                            Request Template
                        </Typography>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setOpenModal(false);
                                resetFormData();
                                setShowValidationError(false);
                            }}
                            sx={{
                                width: "fit-content",
                                padding: "0",
                            }}
                        >
                            <HiOutlineXCircle size={30} />
                        </IconButton>
                    </Box>
                    <TextField
                        required
                        label="Category"
                        fullWidth
                        margin="normal"
                        value={formData.category}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                category: e.target.value,
                            })
                        }
                    />
                    <TextField
                        required
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={formData.description}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                    />
                    {/* <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                imageFile: e.target.files[0],
                            })
                        }
                    /> */}
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <InputLabel
                            htmlFor="image-upload"
                            sx={{
                                backgroundColor: "#e8e8ff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                border: "2px dashed #6363FF",
                                textAlign: "center",
                            }}
                        >
                            {formData.imageFile
                                ? formData.imageFile.name
                                : "Click to select an image"}
                        </InputLabel>
                        <input
                            type="file"
                            id="image-upload"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) =>
                                setFormData((prev) => ({
                                    ...prev,
                                    imageFile: e.target.files[0],
                                }))
                            }
                        />
                    </Box>
                    {showValidationError ? (
                        <Box
                            sx={{
                                background: "#FFE4E4",
                                color: "red",
                                padding: "0.3rem 0.7rem",
                                borderRadius: "0.4rem",
                                marginTop: "0.35rem",
                            }}
                        >
                            <Typography>
                                ! Please fill up category and description
                            </Typography>
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {/* <IconButton
                            variant="outlined"
                            onClick={() => {
                                setOpenModal(false);
                                resetFormData();
                                setShowValidationError(false);
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                            }}
                        >
                            <HiOutlineXCircle size={30} />
                        </IconButton> */}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleSubmit();
                            }}
                            sx={{
                                borderRadius: "2rem",
                                textTransform: "capitalize",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                marginTop: "0.6rem",
                            }}
                        >
                            <span>
                                {isLoading ? "Sending..." : "Send Request"}
                            </span>
                            <HiOutlineArrowSmRight size={25} />
                        </Button>
                    </Box>
                </Box>
                {showSuccessPopup && (
                    <ToastNotification
                        message="Image generated Successfully"
                        type="success"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
            </Dialog>
        </Container>
    );
};

export default TemplatesHeader;
