import React from "react";

import {
  FormControl,
  IconButton,
  ImageList,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useDeviceChecker from "../hooks/useDeviceChecker";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ImageListItem from "@mui/material/ImageListItem";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../StateProvider";
import DialogModal from "./DialogModal";

import { useDispatch, useSelector } from "react-redux";
import { lexicaImageSelected } from "../../features/image/imageSlice";
import { setSavedImages } from "../../features/image/savedImageSlice";
import { deleteImageFromStorage } from "../../firestore/storageHandler";
import mixpanel from "mixpanel-browser";

const BorderedButton = styled(Button)(({ theme }) => ({
  borderColor: "white",
  color: "white",
  margin: "0 auto",
  bottom: 0,
  marginTop: theme.spacing(1),
  ":hover": {
    borderColor: "white",
  },
}));

const getLexicaImageColumn = (device) => {
  if (device === "sm") return 1;
  else if (device === "md") return 2;
  else return 4;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LexicaImageContainer = ({
  user,
  credits,
  image,
  handlePromptSelection,
  openLightBox,
  tryThis,
  copyPrompt,
  imageId,
  removeImageById,
}) => {
  const overlayRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const copyButton = React.useRef(null);
  const [warning, setWarning] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data, dataFetched } = useAPI();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteButtonRef = React.useRef();
  const lightboxRef = React.useRef();
  const downloadRef = React.useRef();
  const tryThisRef = React.useRef();

  const downloadImage = async (e) => {
    e.preventDefault();
    if (!user || !user.emailVerified) {
      setWarning("You need to login first.");
      return;
    }
    const a = document.createElement("a"); //Create <a>
    // const imageTitle = userGivenTitle.slice(0, 20);
    a.href = image.src;
    a.download = "download.png"; //File name Here
    a.click();
  };

  const handleMouseMove = (param) => {
    if (param === "enter") {
      document.querySelectorAll(".eachItemListBox").forEach((each) => {
        each.style.opacity = 1;
        each.style.display = "none";
      });
      document.querySelectorAll(".eachItemListImage").forEach((each) => {
        each.style.opacity = 1;
      });

      // imgRef.current.style.opacity = 0.2;
      overlayRef.current.style.display = "flex";
    } else {
      imgRef.current.style.opacity = 1;
      overlayRef.current.style.display = "none";
    }
  };

  const prompt = image.prompt;
  const handleWarningClose = () => {
    setWarning("");
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    document.querySelectorAll(".copy-buttons").forEach((elem) => {
      elem.style.background = "unset";
      elem.style.color = "white";
      elem.innerText = "Copy Prompt";
    });
    copyButton.current.style.background = "white";
    copyButton.current.style.color = "black";
    copyButton.current.innerText = "Copied";
  };

  const openWithEditor = (image) => {
    console.log(image);
    dispatch(
      lexicaImageSelected({
        ...image,
        category: "saved",
      })
    );
    navigate({
      pathname: "/image/" + image.id,
    });
  };
  const handleClickOnTheOverlay = (event) => {
    if (
      deleteButtonRef.current.contains(event.target) ||
      lightboxRef.current.contains(event.target) ||
      tryThisRef.current.contains(event.target) ||
      downloadRef.current.contains(event.target)
    ) {
      return;
    } else {
      openLightBox(image.id);
    }
  };

  // handle delete image...
  const handleDeleteImage = (event) => {
    let imageIDWithPng = imageId;
    if (!imageIDWithPng.endsWith(".png")) {
      imageIDWithPng = imageIDWithPng + ".png";
    }

    deleteImageFromStorage(imageIDWithPng, user.email)
      .then((res) => {
        // alert("image id", imageId);
        // show image is deleted;
        setWarning("Image is deleted successfully.");

        // remove the card...
        event.target.closest("li").style.display = "none";
        removeImageById(imageIDWithPng);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <DialogModal
        open={open}
        setOpen={setOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        type="Free"
      />
      <Snackbar
        open={Boolean(warning)}
        autoHideDuration={6000}
        onClose={handleWarningClose}
      >
        <Alert
          onClose={handleWarningClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {warning}
        </Alert>
      </Snackbar>
      <ImageListItem cols={1} rows={1} sx={{ cursor: "pointer" }}>
        <img
          ref={imgRef}
          src={image.src}
          alt={image.prompt}
          loading="lazy"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "5px",
            objectFit: "cover",
          }}
          onMouseEnter={() => handleMouseMove("enter")}
          className="eachItemListImage"
        />

        <Box
          textAlign="center"
          onMouseLeave={() => handleMouseMove("leave")}
          ref={overlayRef}
          onClick={handleClickOnTheOverlay}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            bottom: 0,
            height: "100%",
            // padding: "1.5rem",
            px: 2,
            display: "none",
            background:
              "linear-gradient(180deg,transparent,rgba(0,0,0,.4) 50%,rgba(0,0,0,.9))",
            alignItems: "flex-end",
            transition: ".2s ease-in-out",
            overflow: "hidden",
          }}
          className="eachItemListBox"
        >
          <Box pb={1} sx={{ width: "100%" }}>
            <Box
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
              className="skipClick"
              ref={deleteButtonRef}
            >
              <IconButton
                className="skipClick"
                onClick={handleDeleteImage}
                size="small"
                sx={{
                  color: "#ffffffd0",
                  border: "1px solid lightgray",
                  // background : "black"
                  background: "#D22B2B",
                }}
              >
                {/* import DeleteIcon from '@mui/icons-material/Delete'; */}
                <DeleteIcon className="skipClick" />
                {/* <DeleteOutlineIcon  className="skipClick"/> */}
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              align="center"
              style={{
                color: "#ffffffd0",
              }}
            >
              {prompt.length > 150 ? prompt.slice(0, 150) + " ... ..." : prompt}
            </Typography>
            {/* buttons group... */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  marginRight: "auto",
                }}
                ref={tryThisRef}
              >
                <BorderedButton
                  onClick={() => openWithEditor(image)}
                  disableElevation
                  variant="outlined"
                  sx={{
                    marginRight: ".5rem",
                  }}
                  size="small"
                >
                  Enhance
                </BorderedButton>

                {tryThis ? (
                  <BorderedButton
                    onClick={() => {
                      console.log("Prompt: ", image.prompt);
                      handlePromptSelection(image);
                    }}
                    className="skipClick"
                    disableElevation
                    variant="outlined"
                    size="small"
                  >
                    Try This
                  </BorderedButton>
                ) : null}
                {copyPrompt ? (
                  <BorderedButton
                    onClick={() => copyToClipboard(image.prompt)}
                    ref={copyButton}
                    className="copy-buttons skipClick"
                    disableElevation
                    variant="outlined"
                    size="small"
                  >
                    Copy Prompt
                  </BorderedButton>
                ) : null}
              </Box>
              <Box
                onClick={downloadImage}
                className="skipClick"
                variant="outlined"
                color="primary"
                size="small"
                ref={downloadRef}
                px={1}
                style={{
                  color: "white",
                  fontSize: "1.6rem",
                }}
                sx={{
                  bottom: "0",
                  mt: 1,
                  minWidth: "unset",
                  color: "white",
                }}
              >
                <svg
                  className="skipClick"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1578L16.2428 8.91501L17.657 10.3292L12.0001 15.9861L6.34326 10.3292L7.75748 8.91501L11 12.1575V5Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Box>
              <Box
                onClick={() => openLightBox(image.id)}
                variant="outlined"
                size="small"
                style={{
                  color: "white",
                  fontSize: "1.25rem",
                }}
                ref={lightboxRef}
                sx={{
                  bottom: "0",
                  mt: 1,
                  minWidth: "unset",
                  color: "white",
                }}
              >
                {/* <OpenInFullRoundedIcon /> */}
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8995 4.10052V2.10052H21.8995V10.1005H19.8995V5.51477L14.1213 11.293L12.7071 9.87878L18.4854 4.10052H13.8995Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4.10046 13.8995H2.10046V21.8995H10.1005V19.8995H5.51468L11.2929 14.1212L9.87872 12.707L4.10046 18.4853V13.8995Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Box>
            </Box>
          </Box>
        </Box>
      </ImageListItem>
    </>
  );
};

export default function DisplayGeneratedImages({
  credits,
  handlePromptSelection,
  openLightBox,
  user,
  tryThis,
  copyPrompt,
  setImages,
}) {
  const device = useDeviceChecker();
  const dispatch = useDispatch();

  // generated image...
  const savedImage = useSelector((state) => state.savedImage);
  const images = savedImage.images;
  console.log("IMAGES", images);
  // get the unique folder name...
  let folders = ["All", ...new Set(images.map((item) => item.folderName))];
  folders = folders.filter((each) => each);

  const [allImages, setAllImages] = React.useState(images);
  const [createdCurrentFolder, setCreatedCurrentFolder] = React.useState(
    folders[0]
  );

  // Pagination Related States.....
  const pageSize = 10; // number of images will show inside each page......
  const pageNeeded = Math.ceil(images?.length / pageSize);
  const [pageNumber, setPageNumber] = React.useState(0);

  // Handle folder change....
  const handleChange = (e) => {
    setCreatedCurrentFolder(e.target.value);
    // mixpanel.track("Change Current Folder - Image Generation ");
  };

  const removeImageById = (imageId) => {
    let filterdImage = allImages.filter((each) => each.id !== imageId);
    console.log("PERSONAL", filterdImage);
    setAllImages(filterdImage);
    let gloablGeneratedImage = images.filter((each) => each.id !== imageId);
    console.log("GLOBAL", gloablGeneratedImage);
    dispatch(setSavedImages(gloablGeneratedImage));
    // setGeneratedImage(gloablGeneratedImage);
  };

  const getDisplayableImages = (param) => {
    const { images, pageSize, pageNumber, folder } = param;
    if (folder !== "All") {
      return images.filter((each) => each.folderName === folder);
    } else {
      return images.slice(
        pageNumber * pageSize,
        pageNumber * pageSize + pageSize
      );
    }
  };

  const displayableImages = getDisplayableImages({
    images,
    pageSize,
    pageNumber,
    folder: createdCurrentFolder,
  });

  // Handle pagination change...
  const handlePaginationChange = (e, value) => {
    setPageNumber(value - 1);
    // mixpanel.track("Pagination Event Triggered - Image Generation ");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FormControl
          variant="standard"
          sx={{ m: 1, ml: "auto", minWidth: 220 }}
        >
          <InputLabel id="demo-simple-select-standard-label">
            Select Folder
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={createdCurrentFolder}
            onChange={handleChange}
          >
            {folders.map((each) => (
              <MenuItem value={each}>{each}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <ImageList
        cols={getLexicaImageColumn(device)}
        rowHeight={200}
        gap={14}
        sx={{
          mt: 3,
        }}
      >
        {displayableImages.length !== 0
          ? displayableImages.map((image, i) => (
              <LexicaImageContainer
                imageId={image.id}
                key={image.id}
                user={user}
                image={image}
                sx={{ mt: 4 }}
                credits={credits}
                tryThis={tryThis}
                copyPrompt={copyPrompt}
                handlePromptSelection={handlePromptSelection}
                openLightBox={openLightBox}
                setImages={setImages}
                removeImageById={removeImageById}
              />
            ))
          : null}
      </ImageList>

      {/* Pagination... */}
      {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}

      <Pagination
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
        count={pageNeeded}
        // onChange={(e, value) => setPageApi(value)}
        onChange={(e, value) => handlePaginationChange(e, value)}
        renderItem={(item) => (
          <PaginationItem
            components={{
              previous: ArrowBackIcon,
              next: ArrowForwardIcon,
            }}
            {...item}
          />
        )}
      />
    </>
  );
}
