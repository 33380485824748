import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const LightBlueBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
}));

export function ColoredBox(props) {
    return (
        <Box
            {...props}
            className="bg-light-blue"
            sx={{ p: 2, borderRadius: 2, ...props.sx }}
        />
    );
}

export function ErrorBox({ error }) {
    return (
        <Box
            bgcolor="error.main"
            sx={{ borderRadius: "4px", width: "100%", mt: 2 }}>
            <Typography variant="body2" sx={{ color: "white", px: 2, py: 1 }}>
                {error}
            </Typography>
        </Box>
    );
}

export function InfoBox({ info }) {
    return (
        <Box
            bgcolor="info.main"
            sx={{ borderRadius: "4px", width: "100%", mt: 2 }}>
            <Typography variant="body2" sx={{ color: "white", px: 2, py: 1 }}>
                {info}
            </Typography>
        </Box>
    );
}
export function SuccessBox({ success }) {
    return (
        <Box
            bgcolor="success.main"
            sx={{ borderRadius: "4px", width: "100%", mt: 2 }}>
            <Typography variant="body2" sx={{ color: "white", px: 2, py: 1 }}>
                {success}
            </Typography>
        </Box>
    );
}
