import {
    getFirestore, addDoc, collection, serverTimestamp,
    setDoc, getDoc, doc, updateDoc
} from "firebase/firestore";
import { firebaseApp } from "../firebaseInit";
import { onAuth } from "./authHandlers";
const db = getFirestore(firebaseApp);

export const uploadVariationsResult = async (data) => {
    onAuth(async (user) => {
        await addDoc(collection(db, "variations"), {
            ...data,
            email: user.email,
            uid: user.uid,
            localTime: new Date().getTime(),
        });
    });
};

/**
 * @param {{ images: Array<string> }} data 
 */
export const uploadProductGenResult = async (data) => {
    onAuth(async (user) => {
        await addDoc(collection(db, "ad-products"), {
            outputs: data.images,
            email: user.email,
            uid: user.uid,
            createAt: serverTimestamp()
        });
    });
};

// export const uploadProductGenResult = async (data) => {
//     console.log("Uploading Product Image")
//     onAuth(async (user) => {
//         const email = user.email;
//         const uid = user.uid;
//         const docRef = doc(db, "ad-products", uid);
//         const snap = await getDoc(docRef);
//         const newGeneration = {
//             outputs: data.images,
//             date: new Date().getTime()
//         }

//         if (snap.exists()) {
//             let data = snap.data();
//             let generations = data.generations;
//             if (generations) {
//                 generations = [newGeneration, ...generations]
//                 await updateDoc(docRef, { generations: generations });
//             }
//         } else {
//             setDoc(docRef, {
//                 email: email,
//                 generations: [newGeneration],
//                 createAt: serverTimestamp()
//             })
//         }
//     });
// }