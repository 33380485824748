import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import OptionsBadgeBox from "./atoms/OptionsBadgeBox";
import { HiOutlinePlus } from "react-icons/hi";

const stageData = [
    {
        labelTitle: "Audience",
        labelIcon: "/assets/videoAudiencePromptIcon.svg",
        options: [
            {
                id: "audience1",
                value: "Night Owls",
                isSelected: false,
            },
            {
                id: "audience2",
                value: "Fitness Enthusiasts",
                isSelected: false,
            },
            {
                id: "audience3",
                value: "Busy Professional",
                isSelected: false,
            },
        ],
        customAddOption: true,
    },
    /* 
    {
        labelTitle: "Look and Feel",
        labelIcon: "/assets/videoLookFeelPromptIcon.svg",
        options: [
            {
                id: "lookfeel1",
                value: "Professional",
                isSelected: false,
            },
            {
                id: "lookfeel2",
                value: "Inspiring",
                isSelected: false,
            },
            {
                id: "lookfeel3",
                value: "Clean",
                isSelected: false,
            },
        ],
    }, */
    /* {
        labelTitle: "Platform",
        labelIcon: "/assets/videoPlatformPromptIcon.svg",
        options: [
            {
                id: "platform1",
                value: "Youtube",
                isSelected: false,
            },
            {
                id: "platform2",
                value: "Instagram",
                isSelected: false,
            },
            {
                id: "platform3",
                value: "Tiktok",
                isSelected: false,
            },
        ],
    }, */
    /* {
        labelTitle: "Video Type",
        labelIcon: "/assets/videoPlatformPromptIcon.svg",
        options: [
            {
                id: "landscape",
                value: "Landscape",
                isSelected: false,
            },
            {
                id: "medium",
                value: "Portrait",
                isSelected: false,
            },
        ],
    }, */
];

const InputStageThree = ({
    audienceInputValue,
    setAudienceInputValue,
    setLookFeelInputValue,
    setPlatformInputValue,
    videoTypeInputValue,
    setVideoTypeInputValue
}) => {
    const [showAudienceCustomInput, setShowAudienceCustomInput] =
        useState(false);
    const [isRangeBeingControlled, setIsRangeBeingControlled] = useState(false);
    const [stageTwoData, setStageTwoData] = useState([...stageData]);
    const [isVisible, setIsVisible] = useState(false);

    const handleRangeMouseDown = () => {
        setIsRangeBeingControlled(true);
    };

    const handleRangeMouseUp = () => {
        setIsRangeBeingControlled(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 30);
    }, []);

    const handleOptionValChange = (currVal, currLabelTitle) => {
        if (currLabelTitle === "Audience") {
            setAudienceInputValue(currVal);
        } else if (currLabelTitle === "Look and Feel") {
            setLookFeelInputValue(currVal);
        } else if (currLabelTitle === "Platform") {
            setPlatformInputValue(currVal);
        } else if (currLabelTitle === "Video Type") {
            setVideoTypeInputValue(currVal);
        }
    };

    const handleAudienceInputChange = (e) => {
        setAudienceInputValue(e.target.value);
    };

    return (
        <Box
            sx={{
                opacity: isVisible ? 1 : 0.005,
                transition: "opacity 0.5s ease-in-out",
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: "#FFF",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "22px", md: "30px" },
                        fontStyle: "normal",
                        fontWeight: "800",
                        lineHeight: {
                            xs: "24px",
                            md: "36px",
                        } /* 114.583% */,
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                    }}
                >
                    Final Touches
                </Typography>
                <Typography
                    sx={{
                        color: "#E2E8F0",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: {
                            xs: "1rem",
                            md: "1.2rem",
                        } /* 114.583% */,
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                    }}
                >
                    Ensure everything aligns with your vision.
                </Typography>
            </Box>
            <Box
                sx={{
                    margin: "2.5rem 0",
                }}
            >
                <Box
                    sx={{
                        marginBottom: "1.8rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "46px",
                    }}
                >
                    {stageTwoData.map((it, idx) => (
                        <Box
                            key={idx + 1}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "17px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}
                            >
                                <img
                                    src={it.labelIcon}
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        color: "#E2E8F0",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "0.9rem", md: "1rem" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                    }}
                                >
                                    {it.labelTitle}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                }}
                            >
                                <OptionsBadgeBox
                                    options={it.options}
                                    labelTitle={it.labelTitle}
                                    handleOptionValChange={
                                        handleOptionValChange
                                    }
                                />
                            </Box>
                            {it.customAddOption === true &&
                                !showAudienceCustomInput && (
                                    <Box
                                        sx={{
                                            background: "#A855F7",
                                            color: "white",
                                            padding: {
                                                xs: "8px 12.5px",
                                                md: "12px 20px",
                                            },
                                            borderRadius: "1.6rem",
                                            fontFamily: "Inter",
                                            width: "fit-content",
                                            display: "flex",
                                            gap: "0.4rem",
                                            alignItems: "center",
                                            "&:hover": {
                                                background: "#4F46E5",
                                                transition:
                                                    "all 0.2s ease-in-out",
                                            },
                                            fontSize: {
                                                xs: "0.9rem",
                                                md: "1rem",
                                            },
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            setShowAudienceCustomInput(true)
                                        }
                                    >
                                        <span>Add Custom </span>
                                        <HiOutlinePlus size={18} />
                                    </Box>
                                )}

                            {it.customAddOption === true &&
                                showAudienceCustomInput && (
                                    <input
                                        placeholder="Specify audience (e.g., 'Tech enthusiasts', 'Young families')"
                                        /* multiline
                                        rows={3} */

                                        value={audienceInputValue}
                                        onChange={handleAudienceInputChange}
                                        style={{
                                            width: "100%",
                                            marginTop: "10px",
                                            border: "2px solid #475569",
                                            background: "#242E3D",
                                            outline: "none",
                                            borderRadius: "8px",
                                            padding: "1rem",
                                            fontFamily: "Inter",
                                            color: "white",
                                            fontSize: "1rem",
                                            resize: "none",
                                        }}
                                    />
                                )}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default InputStageThree;
