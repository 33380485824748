import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchig: false,
    generating: false,
    selectedLexicaImage: null,
    selectedGeneratdImage: null,
    urlSearchedImages: [],
    promptSearchedImages: [],
    activeTab: 0,
    generationPrompt: "",
    searchedPrompt: "",
};

export const imageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {
        generationStart: (state) => {
            state.generating = true;
            state.searchig = false;
        },
        generationSucceed: (state, action) => {
            console.log(action.payload, "action.payload")
            state.generating = false;
            state.selectedGeneratdImage = action.payload;
        },
        generationFailed: (state) => {
            state.generating = false;
        },
        promptSearchedSucceed: (state, action) => {
            state.promptSearchedImages = action.payload;
        },
        urlSearchedSucceed: (state, action) => {
            state.urlSearchedImages = action.payload;
        },
        lexicaImageSelected: (state, action) => {
            state.generating = false;
            state.selectedLexicaImage = action.payload;
            state.selectedGeneratdImage = null;
        },
        tabChnage: (state, action) => {
            state.activeTab = action.payload.activeTab;
            state[action.payload.key] = action.payload.value;
        },
        generatedImageSelected: (state, action) => {
            state.selectedGeneratdImage = action.payload;
            state.selectedLexicaImage = null;
        },
    },
});

export const {
    generationStart,
    generationFailed,
    lexicaImageSelected,
    generationSucceed,
    tabChnage,
    promptSearchedSucceed,
    urlSearchedSucceed,
} = imageSlice.actions;
export default imageSlice.reducer;
