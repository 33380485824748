import React, { useState } from "react";
import axios from "axios";
import {
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    MenuItem,
    FormControl,
    Select,
    CircularProgress,
    Tooltip
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineX,
    HiOutlineDownload,
} from "react-icons/hi";
import headshotGenExampleImage from "../../imgs/headshotGenExampleImage.png";
import circleRegenrateIcon from "../../imgs/circleRegenrateIcon.png";
import arrowTopIconHeadshotPitch from "../../imgs/arrowTopIconHeadshotPitch.svg";
import camFlashIconHeadshotPitch from "../../imgs/camFlashIconHeadshotPitch.svg";
import { saveAs } from "file-saver";
import { getFirebaseAuthToken } from "../../firebaseInit";
import { useAPI } from "../../StateProvider";

import ToastNotification from "../atoms/ToastNotification";
import UpgradePopupHeadshot from "./UpgradePopupHeadshot";


const FreeGenImages = ({ generatedImageUrlArr, user, resetStates, freeGenImagesLoading, newHeadshotsDocId }) => {
    const { data } = useAPI();

    const navigate = useNavigate();
    const handleDownloadClick = () => {
        saveAs(generatedImageUrlArr[0], "download-image.png");
    };

    return (
        <Box sx={{
            display: "flex",
            alignItems: { xs: "center", md: "start" },
            gap: "100px",
            flexDirection: {
                xs: "column",
                md: "row"
            }
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: {
                    xs: "column",
                    md: "column"
                },
                alignItems: {
                    xs: "center",
                    md: "start"
                }

            }}>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "32px" /* 133.333% */,
                        letterSpacing: "-0.144px",
                        marginBottom: "30px",
                    }}
                /* sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "32px" ,
                    letterSpacing: "-0.144px",
                    textAlign: "center",
                }} */
                >
                    Your Headshots
                </Typography>

                <Box>
                    {freeGenImagesLoading ? (
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1.9rem",
                            width: "550px",
                            height: "260px",
                            justifyContent: "center"
                        }}>
                            <CircularProgress size={40} />
                        </Box>
                    ) : (
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "30px",
                            flexWrap: "wrap",
                            justifyContent: {
                                xs: "center",
                                md: "flex-start"
                            }
                        }}>
                            {generatedImageUrlArr.map((item, index) => (
                                <img
                                    /* src={imagePreviewUrl} */
                                    key={index}
                                    src={item}
                                    alt="Your"
                                    style={{

                                        width:
                                            window.innerWidth < 768
                                                ? "260px"
                                                : "260px",
                                        height:
                                            window.innerWidth < 768
                                                ? "260px"
                                                : "260px",
                                        objectFit: "cover",
                                        borderRadius: "20px",
                                    }}
                                />
                            ))}
                            {/* <img
                                src="https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="Your"
                                style={{

                                    width:
                                        window.innerWidth < 768
                                            ? "260px"
                                            : "260px",
                                    height:
                                        window.innerWidth < 768
                                            ? "260px"
                                            : "260px",
                                    objectFit: "cover",
                                    borderRadius: "20px",
                                }}
                            />
                            <img
                                src="https://images.pexels.com/photos/2834009/pexels-photo-2834009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                alt="Generated Headshot"
                                style={{

                                    width:
                                        window.innerWidth < 768
                                            ? "260px"
                                            : "260px",
                                    height:
                                        window.innerWidth < 768
                                            ? "260px"
                                            : "260px",
                                    objectFit: "cover",
                                    borderRadius: "20px",
                                }}
                            /> */}
                        </Box>
                    )}

                </Box>
                <Button
                    onClick={() => resetStates()}
                    /* onClick={handleHeadshotGenerateClick} */
                    sx={{
                        background: "#6363FF",
                        color: "white",
                        borderRadius: "70px",
                        textTransform: "capitalize",
                        padding: "12px 27px",
                        marginTop: "50px",
                        /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                        "&:hover": {
                            background: "#4E4EC8",
                            color: "white",
                        },
                    }}
                    disableElevation
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                        }}
                    >
                        Generate Again
                    </Typography>

                    {freeGenImagesLoading ? (
                        <CircularProgress
                            size={20}
                            sx={{
                                color: "white",
                                marginLeft: "0.7rem",
                            }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={circleRegenrateIcon}
                            style={{
                                width: "21px",
                                marginLeft: "0.8rem",
                            }}
                        />
                    )}
                </Button>

            </Box>
            <Box
                sx={{
                    width: { xs: "90%", md: "40%" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "32px" /* 133.333% */,
                            letterSpacing: "-0.144px",
                            textAlign: "center",
                        }}
                    >
                        Unlimited Headshots & Exclusive Albums with PRO!
                    </Typography>
                    <Box
                        sx={{
                            width: { xs: "80vw", md: "450px" },
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "27px auto 0 auto",
                        }}
                    >
                        <img
                            alt=""
                            src="/assets/headshotGenExampleImage2.png"
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </Box>

                    <Button
                        component="span"
                        onClick={() => {
                            navigate("/pricing");
                        }}
                        sx={{
                            background: "#9333EA",
                            /* background: "#A855F7", */
                            color: "white",
                            borderRadius: "70px",
                            textTransform: "capitalize",
                            padding: "12px 27px",
                            margin: "30px auto 0 auto",
                            /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                            "&:hover": {
                                background: "#A855F7",
                                color: "white",
                            },
                            border: "2px solid #C084FC"
                        }}
                        disableElevation
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                textAlign: "center",
                                display: "flex",
                                gap: "0.5rem"
                            }}
                        >
                            <img src="/assets/upgradeSparkIcon.svg" style={{
                                width: "24px"
                            }} />
                            <span>Upgrade to Premium</span>

                        </Typography>
                    </Button>
                </Box>

            </Box>
        </Box>
    )
}

export default FreeGenImages