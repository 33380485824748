import { doc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./firebaseInit";
import {
    getCreditBalance,
    getCurrentSubscription,
} from "./firestore/dataHandler";

export const SubscriptionContext = React.createContext();

export const SubscriptionContextProvider = ({ children }) => {
    const [data, setData] = React.useState({});
    const [dataFetched, setDataFetched] = React.useState(false);
    const [user] = useAuthState(auth);
    // console.log(user);

    React.useEffect(() => {
        if (!user) return;

        localStorage.setItem("uid", user.uid)
        localStorage.setItem("email", user.email)

        getCurrentSubscription(user).then((result) => {
            console.log("RES", result);
            setData(result);
            setDataFetched(true);
        });

        getCreditBalance(user.email, user.uid).then((credits) => {
            setData({
                ...data,
                credits,
            });
        });

        const unsub = onSnapshot(doc(db, "arts", user.email), (doc) => {
            const data = doc.data();
            setData(data);
        });
        return () => unsub();
    }, [user]);

    return (
        <SubscriptionContext.Provider value={{ data, dataFetched }}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export function useAPI() {
    const data = React.useContext(SubscriptionContext);
    return data;
}
