/**
 * Firestore data handling includes reading, updating, deleting data (as per requirements)
 * To ensure easily maintainable/readble code let's follow the following patterns
 * 1. Avoid using Promise constructor when possible.
 *    Firestore CRUD operations return promise by default
 * 2. Avoid redundancy in code. Less code, less bug.
 * 3. Remove unnecessary comments
 * 4. Avoid writing giant function/ better split for very specific job
 */

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, firebaseApp } from "../firebaseInit";
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
const appsumo = httpsCallable(functions, "appsumo");

/**
 *
 * @param {string} email email
 * @param {string} uid google uid
 * @returns
 */
// ! Remove redundancy in following code, doc's been created when user first signed up
const getCreditBalance = async (email) => {
  const docRef = doc(db, "arts", email);
  const snap = await getDoc(docRef);
  const data = snap.data();
  return data.credits;
};

// reduce the credit...
/**
 * @param {number} cost
 */
const reduceTheCredit = async (cost) => {
  if (!cost) return;

  const email = auth.currentUser.email;
  const docRef = doc(db, "arts", email);
  const snap = await getDoc(docRef);

  if (snap.exists()) {
    let data = snap.data();
    if (data.credits) {
      let credits = data.credits;
      //* dont call update if credits are unlimited
      if (isNaN(credits)) return;
      let finalCredit = credits - cost;
      await updateDoc(docRef, { credits: finalCredit });
    }
  } else {
    // doc doesn't exist
  }
};

// check credit status...
//! Redundant code. credit checked when app first mounted
/**
 * @param {number} cost
 */
const checkCreditStatus = async (cost) => {
  return new Promise(async (resolve, reject) => {
    const email = auth.currentUser.email;
    const docRef = doc(db, "arts", email);
    const snap = await getDoc(docRef);

    if (snap.exists()) {
      let data = snap.data();
      if (data.credits || isNaN(data.credits)) {
        let credits = data.credits;
        let finalCredit = credits - cost;
        if (finalCredit < 0) {
          reject({
            success: false,
            message: "out of credit",
          });
        } else {
          resolve({
            success: true,
            message: "This user have enoguh credtis to call the api",
          });
        }
      } else {
        reject({
          success: false,
          message: "no credits here",
        });
      }
    } else {
      // doc doesn't exist
      reject({
        success: false,
        message: "out of credit",
      });
    }
  });
};

// appsumo code runner..
const appsumoCodeRunner = async (code) => {
  let result = await appsumo({
    code: code,
  });
  return result;
};

//! Redundancy: getCurrentSubscription already returning customer id
export const getCustomer = async () => {
  try {
    if (!auth.currentUser) return null;
    const email = auth.currentUser.email;
    const docRef = doc(db, "arts", email);
    const snap = await getDoc(docRef);
    const data = snap.data();
    return data.customer || null;
  } catch (error) {
    return null;
  }
};

export const getCurrentSubscription = async (user) => {
  try {
    if (!user) return null;

    const email = user.email;
    const docRef = doc(db, "arts", email);
    const snap = await getDoc(docRef);
    const data = snap.data();
    return data;
  } catch (error) {
    return null;
  }
};

// export const getAccountsData = async (user) => {
//     try {
//         if (!user) return null;
//         const email = user.email;
//         const docRef = doc(db, "accounts", email);
//         const snap = await getDoc(docRef);
//         const data = snap.data();
//         return data;
//     } catch (erro) {
//         return null;
//     }
// };

function getDateString() {
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return date;
}

export const checkDailyLimit = async (user, credits) => {
  return new Promise(async (resolve, reject) => {
    if (!user) {
      reject();
    }

    if (!Number.isNaN(credits)) {
      resolve();
    } else {
      // This code will only run for unlimited user...
      const email = user.email;
      const docRef = doc(db, "accounts", email);
      const snap = await getDoc(docRef);
      const data = snap.data();
      if (
        data.daily_credit_used >= 400 &&
        data.credit_consuming_date === getDateString()
      ) {
        reject();
        // const timeRef = doc(db, "servertime", "currenttime");
        // await updateDoc(timeRef, { ts: serverTimestamp });
        // const timesnap = await getDoc(timeRef);
        // const ts = timesnap.data().ts;
        // console.log(ts);
        // // TODO: Fix this
        // // const currenttime = console.log(
        // //     data.credit_consuming_date === getDateString()
        // // );
      } else {
        // alert('resolver')
        resolve();
      }
    }
  });
};

const fetchPersonalGeneratedImages = (user) => {
  return new Promise(async (resolve, reject) => {
    const email = user.email;
    const docRef = doc(db, "generations", email);
    const snap = await getDoc(docRef);
    if (snap.exists()) {
      let data = snap.data();
      if (data.created) {
        // const transformed = data.c.map((each) => ({
        //         id: each.name,
        //         src : each.path,
        //         ...each,

        //     }));

        resolve(data.created);
      }
    } else {
      reject("no image");
    }
  });
};

/**
 * @param {{
 *  email: string,
 *  uid: string,
 *  liked: boolean,
 *  src: string,
 *  prompt: string }} reaction
 */
export async function addReaction(reaction) {
  console.log(reaction, "REACTION");
  const reactionRef = doc(collection(db, "reactions"));
  await setDoc(reactionRef, {
    ...reaction,
    timestamp: serverTimestamp(),
  });
}

export async function saveTemplateTodatabase(templateData) {
  // let name = "Martial Arts";
  let name = "Pop Culture";
  // let name = "Rockstars & Musicians";
  let filterdDocumnet = templateData.filter((each) => each.category === name);

  const artDocRef = doc(db, "dreamboothTemplates", name);
  await setDoc(artDocRef, {
    ...filterdDocumnet[0],
  });
}
export async function saveTemplateTodatabaseV2(templateData) {
  // let name = "Rockstars & Musicians";
  let name = "Pop Culture";
  // let name = "Martial Arts";
  let filterdDocumnet = templateData.filter((each) => each.category === name);

  const artDocRef = doc(db, "dreamboothTemplatesV2", name);
  await setDoc(artDocRef, {
    ...filterdDocumnet[0],
  });
}

export async function fetchAllTheDreamBoothTemplatesFromFirebase() {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "dreamboothTemplates")).then((querySnapshot) => {
      const newUserDataArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      resolve(newUserDataArray);
    });
  });
}

export async function fetchAllTheDreamBoothTemplatesFromFirebaseV2() {
  return new Promise((resolve, reject) => {
    getDocs(collection(db, "dreamboothTemplatesV2")).then((querySnapshot) => {
      const newUserDataArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      resolve(newUserDataArray);
    });
  });
}

function setLastAccessed() {
  return new Promise(async (resolve, reject) => {
    const email = auth.currentUser.email;
    const docRef = doc(db, "arts", email);
    const snap = await getDoc(docRef);

    if (snap.exists()) {
      let time = Date.now();
      console.clear();
      await updateDoc(docRef, { lastAccessed: time }).then(() => {
        resolve(time);
      });
    }
  });
}

export {
  getCreditBalance,
  reduceTheCredit,
  checkCreditStatus,
  appsumoCodeRunner,
  fetchPersonalGeneratedImages,
  setLastAccessed,
  // saveTemplateTodatabase
};
