/**
 * @desc expense rate for different services
 */

export const EXPENSE_RATE = {
  stabilityImageGen: 1,
  smallImageGen: 1,
  mediumImageGen: 2,
  largeImageGen: 3,
  lexicaImageDownload: 1,
  imageReversal: 1,
  customTemplateImageGen: 1,
};
