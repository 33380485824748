import React, { useState, useRef } from "react";

import {
  Box,
  Grid,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { onGettingIdToken } from "../../firebaseInit";
// import { useAPI } from "../../StateProvider";
import DialogModal from "../../form/DialogModal";
import WarningModal from "../../form/WarningModal";
import NeedToBuyAPlan from "../../model/NeedToBuyAPlan";

// import mixpanel from "mixpanel-browser";
import { AiFillPlayCircle, AiFillCheckCircle } from "react-icons/ai";
import { imageUploaded } from "../../../features/image/dragganSlice";
import { styled } from '@mui/material/styles'
import { getDragganImage } from "../../../axios";


const FileUploadBox = styled(Box)(({
  theme
}) => ({
  border: `4px dashed ${theme.palette.mode === 'light' ? 'black' : '#F0F0F0'}`,
  width: '100%',
  maxWidth: '900px',
  borderRadius: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '10rem',
  margin: '0 auto',
  cursor: 'pointer',
  position: 'relative',
  padding: theme.spacing(2),
  fontSize: theme.typography.pxToRem(18),
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.typography.pxToRem(14),
  },
}))

const ImageDisplayBox = styled(Box)(({
  theme
}) => ({
  maxWidth: '900px',
  width: '100%',
  margin: '0 auto',
  marginTop: '3rem'
}))

/*
const SelectedImageBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'light' ? '#EFEFEF' : 'black',
  padding: theme.spacing(1),
  border: `3px solid ${theme.palette.mode === 'light' ? 'black' : '#E9E9E9'}`,
  borderRadius: '0.5rem',
  width: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))
*/

/*
const StyledButton = styled(Box)(({ theme }) => ({
  padding: "0.5rem 1.5rem",
  width: "fit-content",
  height: "2.5rem",
  borderRadius: "2rem",
  background:
    "linear-gradient(25deg, #6363FF 10%, #281142 90%)",
  color: "white",
  fontSize: "0.9rem",
  transition: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  border: "1.6px solid white",
  boxShadow: "none",
  margin: "0 auto",

  "&:hover": {
    background:
      "linear-gradient(85deg, #6363FF 10%, #281142 90%)",
    transition: "all 0.3s ease-in",
    boxShadow: " 1px 7px 400px -2px black",
  },
}))
*/

/*
const BottomWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  width: "fit-content",
  margin: "0 auto",
}))
*/

/*
const BottomWrapperBox_2 = styled(Box)(({ theme }) => ({
  width: "350px",
  margin: "2rem auto 0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}))
*/

const DragganImgUpSection = ({ user, productData, credits }) => {
  return <div><FormField /></div>;
};

// Utilities Functions
/*
function clearCanvas(elem) {
  console.log(elem);
  const ctx = elem.getContext("2d");
  ctx.clearRect(0, 0, elem.width, elem.height);
}
*/

/**
* add white pixelated background
* Convert canvas element to file
*/
/*
function getMaskFromCanvas(canvas) {
  const width = canvas.width;
  const height = canvas.height;

  const maskCanvas = document.createElement("canvas");
  const ctx = maskCanvas.getContext("2d");

  maskCanvas.width = width;
  maskCanvas.height = height;
  //apply the old canvas to the new one
  ctx.drawImage(canvas, 0, 0);
  ctx.globalCompositeOperation = "destination-over";
  // Now draw!
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);

  //return the new canvas
  return new Promise((resolve) => {
    maskCanvas.toBlob((blob) => {
      let file = new File([blob], "mask.png", { type: "image/png" });
      resolve(file);
    }, "image/png");
  });
}
*/

async function getImageSize(src) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = function () {
      let h = this.height;
      let w = this.width;
      if (w > 512) {
        let ratio = 512 / w;
        console.log(ratio);
        let revisedHeight = parseInt(h * ratio);
        console.log(revisedHeight);
        resolve({
          height: revisedHeight,
          width: 512,
        });
      } else {
        resolve({
          height: this.height,
          width: this.width,
        });
      }
    };
    image.src = src;
  });
}

/*
async function resizeImageFile(file) {
  let url = URL.createObjectURL(file);
  let optimizedSize = await getImageSize(url);
  return new Promise((resolve, reject) => {
    let w = optimizedSize.width;
    let h = optimizedSize.height;
    let reader = new FileReader();
    reader.onload = function (readerEvent) {
      let image = new Image();
      image.onload = function (imageEvent) {
        let canvas = document.createElement("canvas");
        canvas.width = w;
        canvas.height = h;
        canvas.getContext("2d").drawImage(image, 0, 0, w, h);
        canvas.toBlob((blob) => {
          let file = new File([blob], "image.png", {
            type: "image/png",
          });
          resolve(file);
        }, "image/png");
        // base64 data url
        // let resizedImage = canvas.toDataURL("image/png");
        // resolve(resizedImage)
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}
*/

function FormField({
  user,
  credits
}) {
  const [open, setOpen] = React.useState(false);
  // use user selected/written prompt
  const [showPopup, setShowPopup] = React.useState(false);
  const [lockWarning, setLockWarning] = React.useState(false);

  // const { data } = useAPI();
  const dispatch = useDispatch();

  const [coords, setCoords] = useState([]);
  const [points, setPoints] = useState([]);

  const inputImage = useSelector((state) => state.draggan.inputImage)

  const ImageHandler = async (e) => {
    // window.scrollTo({
    //   top: inputImgRef.current.offsetTop,
    //   left: 0,
    //   behavior: "smooth",
    // });
    const mainfile = e.target.files[0];
    console.log(mainfile);
    // console.log("Top", inputImgRef.current.offsetTop);
    let imageUrl = URL.createObjectURL(mainfile);
    // let imageUrl = await resizeImageFile(mainfile)
    // console.log(imageUrl)
    let imgsize = await getImageSize(imageUrl);

    setPoints([])
    setCoords([])

    //! setUploadImage(imageUrl);
    dispatch(
      imageUploaded({
        inputImage: imageUrl,
        inpaintedImage: "",
        canvasWidth: imgsize.width,
        canvasHeight: imgsize.height,
      })
    );
  };

  const handlesubmit = async (e) => {
    e.preventDefault()
    console.log(coords)
    const source_pixel_coords = `(${coords[0].y_cord}, ${coords[0].x_cord})`
    const target_pixel_coords = `(${coords[1].y_cord}, ${coords[1].x_cord})`
    console.log(source_pixel_coords)
    console.log(target_pixel_coords)

    const formElement = e.currentTarget;
    const formData = new FormData(formElement);
    
    formData.append("source_pixel_coords", source_pixel_coords)
    formData.append("target_pixel_coords", target_pixel_coords)
    formData.append("api_type", "draggan")

    getDragganImage(formData).then((result) => {
      console.log(result)
    }).catch(error => {
      console.log(error)
    })
  };

  // const changeSelectedImageHandler = () => {
  //   dispatch(
  //     inpaintingSucceed({
  //       inpaintedImage: generatedImageSelected,
  //     })
  //   );
  // };

  const handlePopupToggle = (val) => {
    if (val === "open") {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <React.Fragment>
      <WarningModal
        open={showPopup}
        onToggle={handlePopupToggle}
        message="Please log in with your account or make a new one to start generating images."
      />
      <DialogModal
        open={open}
        setOpen={setOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        type="none"
      />

      {/* IF User is free, Show the buy plan popup model */}
      {lockWarning ? (
        <NeedToBuyAPlan
          lockWarning={lockWarning}
          setLockWarning={setLockWarning}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          type="free"
        />
      ) : null}

      <form onSubmit={handlesubmit}>
        <FileUploadSection ImageHandler={ImageHandler} />
        <ImageDisplayBox>
          {inputImage && <>
            {/* <SelectedImageSection inputImage={inputImage} /> */}
            <ImageDisplaySection
              inputImage={inputImage}
              setCoords={setCoords}
              coords={coords}
              points={points}
              setPoints={setPoints}
            />
            {/* <BottomSection /> */}
          </>}
        </ImageDisplayBox>
      </form>
    </React.Fragment>
  );
}

const FileUploadSection = ({ ImageHandler }) => {
  const openFile = useRef();
  return (
    <FileUploadBox onClick={() => openFile.current.click()}>
      <Typography variant="h6" color="text.secondary">
        Choose files to upload images
      </Typography>
      {/* Rest of the file upload section */}
      <input
        style={{ display: "none" }}
        type="file"
        name="image"
        onChange={ImageHandler}
        ref={openFile}
      />
      <a
        href="https://www.loom.com/share/2d189662934d4827a032acbe10996e96"
        target="_blank"
        rel="noreferrer"
      >
        <Tooltip title="A tutorial on how Erase & Replace works.">
          <span style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000
          }}>
            <AiFillPlayCircle fontSize="30" />
          </span>
        </Tooltip>
      </a>
    </FileUploadBox>
  );
};

/*
const AlertSection = ({ generating }) => {
  if (!generating) return null;
  return (
    <Alert severity="info" sx={{ m: 1 }}>
      Your image is being erased and replaced.
      Please wait until it's done.
    </Alert>
  )
}
*/

/*
const BottomSection = ({ inputImgRef }) => {
  return (
    <Grid container ref={inputImgRef} >
      <Grid item xs={12} lg={8}>
        <BottomWrapperBox align="center">
          <AlertSection />
          <BottomWrapperBox_2
            style={{
              gap: "1rem",
            }}
          >
            <TextField
              placeholder="Your text (the change you want in place erased portion)"
              label="Your Prompt"
              multiline
              rows={3}
              name="prompt"
              required
              sx={{
                width: "100%",
                border: "none",
                outline: "none"
              }}
            />
            <StyledButton
              type="submit"
              style={{ gap: "0.3rem" }}
            >
              <>
                <Typography sx={{ fontSize: "1rem" }}>
                  Submit
                </Typography>
                <span style={{ marginTop: "3px" }}>
                  <HiOutlineChip size={20} />
                </span>
              </>
            </StyledButton>
          </BottomWrapperBox_2>
        </BottomWrapperBox>
      </Grid>
    </Grid>
  )
}
*/

/*
const SelectedImageSection = ({ inputImage }) => {
  const theme = useTheme();
  return (
    <SelectedImageBox>
      <Typography>Selected Image</Typography>
      <div className="image_section">
        <img
          onClick={() => {
            console.log("Only God Knows What is this!");
          }}
          style={{
            width: "100px",
            height: "100px",
            marginTop: "0.5rem",
            borderRadius: "0.5rem",
          }}
          className="c-pointer"
          width={200}
          height={100}
          src={inputImage}
          alt="UploadImage"
        />
      </div>
    </SelectedImageBox>
  );
};
*/

const ImageDisplaySection = ({ inputImage, setCoords, coords, points, setPoints }) => {
  // const [coords, setCoords] = useState([]);
  const [dotColor, setDotColor] = useState('red');
  const imageRef = useRef(null);

  const handleImageClick = (event) => {
    const rect = imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (points.length < 2) {
      setPoints([...points, { x, y }]);
    }
    const imageHeight = imageRef.current.offsetHeight;

    const y_cord = imageHeight + rect.top - event.clientY;
    const x_cord = x;

    if (coords.length < 2) {
      setCoords([...coords, { x_cord, y_cord }])
      console.log(x_cord, y_cord)
    }
  };

  const handleUndoClick = () => {
    setPoints(points.slice(0, points.length - 1));
    setCoords(coords.slice(0, coords.length - 1));
  };

  const handleColorChange = (event) => {
    setDotColor(event.target.value);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {points.length > 0 && (
            <button onClick={handleUndoClick}>Undo</button>
          )}

          <label>
            Dot color:
            <select value={dotColor} onChange={handleColorChange}>
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="green">Green</option>
            </select>
          </label>

          <div style={{ position: "relative" }}>
            <img
              ref={imageRef}
              src={inputImage}
              alt="Your"
              onClick={handleImageClick}
              style={{
                maxWidth: "100%",
                height: "auto",
                cursor: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px;"><circle cx="12" cy="12" r="12" style="fill: ${dotColor};"/></svg>') 12 12, auto`
              }}
            />

            {points.map((point, index) => (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  left: point.x,
                  top: point.y,
                  transform: 'translate(-50%, -50%)',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  background: dotColor,
                }}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h2>Steps To Generate</h2>
          <CustomCheck checked={points.length > 0} text="Select a source point(Try clicking over image)" />
          <CustomCheck checked={points.length > 1} text="Select a Target point(Try clicking over image)" />
          <button type="submit">Generate</button>
        </Grid>
      </Grid>
    </>
  );
}

const CustomCheck = ({ checked, text }) => {
  return (
    <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-start", margin: ".5rem" }}>
      <AiFillCheckCircle style={{ color: `${checked ? "green" : "gray"}`, fontSize: "20px", marginRight: ".5rem" }} />
      <p style={{ fontSize: "15px" }}>{text}</p>
    </div>
  )
}


export default DragganImgUpSection;
