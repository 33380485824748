import React, { useState } from "react";
import LayoutV3 from "../layout/LayoutV3";
import FooterV3 from "../footer/FooterV3";
import { Box } from "@mui/material";
import { useAPI } from "../../StateProvider";
import InputPhaseMain from "./InputPhase/InputPhaseMain";
import OutputPhaseMain from "./OutputPhase/OutputPhaseMain";
import ToastNotification from "../atoms/ToastNotification";

const VideoGen = ({ user }) => {
    const { data } = useAPI();

    const [isInputPhase, setIsInputPhase] = useState(true);
    const [showApiError, setShowApiError] = useState(false);
    const [showApiSuccess, setShowApiSuccess] = useState(false);
    const [generatedVideoSrc, setGeneratedVideoSrc] = useState("");
    const [userInputObject, setUserInputObject] = useState({});

    return (
        <LayoutV3 footer={<FooterV3 />}>
            <Box
                sx={{
                    background: "#0F172A",
                    color: "white",
                    padding: { xs: "0.7rem 0.5rem", md: "3rem 6rem" },
                    /* height: "90vh", */
                    /* display: "flex",
                    alignItems: "center",
                    justifyContent: "center", */
                    fontFamily: "Inter",
                }}
            >
                {showApiError && (
                    <ToastNotification
                        message="An error occurred!"
                        type="error"
                        /* type="neutral" */
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
                <Box
                    sx={{
                        /* display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between", */
                        maxWidth: "1700px",
                        margin: "0 auto",
                        height: "100%",
                    }}
                >
                    {isInputPhase ? (
                        <InputPhaseMain
                            user={user}
                            isInputPhase={isInputPhase}
                            setIsInputPhase={setIsInputPhase}
                            setGeneratedVideoSrc={setGeneratedVideoSrc}
                            setShowApiError={setShowApiError}
                            setShowApiSuccess={setShowApiSuccess}
                            userInputObject={userInputObject}
                            setUserInputObject={setUserInputObject}
                        />
                    ) : (
                        <OutputPhaseMain
                            user={user}
                            isInputPhase={isInputPhase}
                            setIsInputPhase={setIsInputPhase}
                            generatedVideoSrc={generatedVideoSrc}
                            setGeneratedVideoSrc={setGeneratedVideoSrc}
                            setShowApiError={setShowApiError}
                            showApiError={showApiError}
                            showApiSuccess={showApiSuccess}
                            userInputObject={userInputObject}
                            setUserInputObject={setUserInputObject}
                        />
                    )}
                </Box>
            </Box>
        </LayoutV3>
    );
};

export default VideoGen;
