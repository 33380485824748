import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    images: [],
};

export const savedImageSlice = createSlice({
    name: "savedImage",
    initialState,
    reducers: {
        setSavedImages: (state, action) => {
            state.images = action.payload;
        },
        addToSavedImages : (state, action)=>{
            state.images = [action.payload , ...state.images]
        } 
    },
});

export const { setSavedImages, addToSavedImages } = savedImageSlice.actions;
export default savedImageSlice.reducer;
