import React from "react";
import FolderSystem from "./FolderSystem";
import ModelSettings from "./ModelSettings";
// import { SubHead } from "../../styled/texts";

import { Box, Card, Grid, Typography } from "@mui/material";

export default function Settings() {
    return (
        <>
            <Card sx={{ display: "flex", mt: 2 }} elevation={0}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <ModelSettings />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FolderSystem />
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}

// export default function Settings() {
//     return (
//         <Box
//             style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 padding: "2rem",
//                 border: "1px solid lightgray",
//                 marginTop: ".5rem",
//                 borderRadius: "5px",
//                 alignItems: "flex-start",
//             }}
//         >
//             <FolderSystem />
//             <ModelSettings />
//         </Box>
//     );
// }
