import React from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
} from "@mui/material";
import WorkSpaceInputBox from "./WorkSpaceInputBox";
import WorkSpaceOutputBox from "./WorkSpaceOutputBox";
import { useTheme } from "@mui/material/styles";
import ExtraHelperBox from "./ExtraHelperBox";

const WorkSpace = ({ productData, selectedImage, onGenerateOutput }) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                /* height: "80vh", */
                background: "transparent",
                /* border: "1px solid black", */
                margin: "4rem 0 0rem 0",
            }}
            style={{ gap: "0.5rem" }}
        >
            <Box sx={{ margin: { xs: "0 0 20rem 0", md: "0" } }}>
                <Box
                    sx={{
                        background:
                            theme.palette.mode === "light"
                                ? "#EFEFEF"
                                : "black",
                        padding: "0.5rem",
                        border: `3px solid ${
                            theme.palette.mode === "light" ? "black" : "#E9E9E9"
                        }`,
                        borderRadius: "0.5rem",
                        width: { xs: "100%", md: "50%" },
                        margin: "0 auto",
                    }}
                >
                    <Typography>Selected Image</Typography>
                    <img
                        src={selectedImage}
                        alt=""
                        style={{
                            width: "100px",
                            height: "100px",
                            marginTop: "0.5rem",
                        }}
                    />
                </Box>
                {/* <ExtraHelperBox productData={productData} /> */}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    height: "80vh",
                    background: "transparent",
                    justifyContent: "center",
                    /* border: "1px solid black", */
                    flexDirection: { xs: "column-reverse", md: "row" },
                    margin: "0rem 0",
                }}
                style={{ gap: "2rem" }}
            >
                <WorkSpaceInputBox
                    productData={productData}
                    selectedImage={selectedImage}
                    onGenerateOutput={onGenerateOutput}
                />
                <WorkSpaceOutputBox
                    productData={productData}
                    selectedImage={selectedImage}
                    onGenerateOutput={onGenerateOutput}
                />
            </Box>
        </Box>
    );
};

export default WorkSpace;
