import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    IconButton,
    Slider,
    Typography,
} from "@mui/material";
import {
    HiPlay,
    HiPause,
    HiOutlineCloudDownload,
    HiOutlineMinus,
} from "react-icons/hi";
import AnotherAudioLoader from "./AnotherAudioLoader.svg";
import StillAudioAnimation from "./StillAudioAnimation.svg";

const CustomAudioPlayer = ({ audioSrc }) => {
    console.log(audioSrc, "audiosrc");
    const [isPlaying, setIsPlaying] = useState(false);
    // const [volume, setVolume] = useState(0.5);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(new Audio(audioSrc));

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
            //audioRef2.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    // const handleVolumeChange = (event, newValue) => {
    //     setVolume(newValue);
    //     audioRef.current.volume = newValue;
    // };

    /* const handlePlaybackSpeedChange = (event) => {
        const selectedSpeed = parseFloat(event.target.value);
        setPlaybackSpeed(selectedSpeed);
        audioRef.current.playbackRate = selectedSpeed;
    }; */

    const handleTimeUpdate = (event) => {
        setCurrentTime(event.target.currentTime);
        if (currentTime === duration) {
            setIsPlaying(false);
        }
    };

    const handleLoadedMetadata = (event) => {
        setDuration(event.target.duration);
    };

    useEffect(() => {
        audioRef.current.currentTime = currentTime;
        audioRef.current.playbackRate = 1.2;
    }, [currentTime]);
    useEffect(() => {
        console.log("kpop");
        console.log("kpop");
    }, [audioSrc]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                border: "1.5px solid rgba(215, 215, 255, 0.7)",
                boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                transition: "all 0.1s ease-in",
                /* "&:hover": {
                    boxShadow:
                        theme.palette.mode === "light"
                            ? "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;"
                            : "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;",
                    transition: "all 0.1s ease-in-out",
                    transform: "scale(1.04)",
                }, */
                padding: "2rem 0.4rem 1rem 0.4rem",
                borderRadius: "0.8rem",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    border: "1.5px solid rgba(100, 100, 244, 0.3)",
                    borderRadius: "100%",
                    padding: "0.5rem",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "150px",
                        height: "150px",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt=""
                        src={
                            isPlaying ? AnotherAudioLoader : StillAudioAnimation
                        }
                        style={{ width: "70%" }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    marginTop: "1rem",
                }}
            >
                <Typography
                    sx={{
                        /* minWidth: 75 */
                        fontSize: "0.9rem",
                    }}
                >
                    0:{currentTime.toFixed()}
                    {/*  {currentTime.toFixed(2)} */}
                </Typography>

                <Slider
                    value={currentTime}
                    onChange={(event, newValue) => setCurrentTime(newValue)}
                    min={0}
                    max={duration}
                    step={0.01}
                    sx={{ width: 200 }}
                />
                <Typography
                    sx={{
                        /* minWidth: 75 */
                        fontSize: "0.9rem",
                    }}
                >
                    0:{duration}
                    {/* {duration.toFixed(2)} */}
                </Typography>
                {/* <IconButton onClick={() => setVolume(volume === 0 ? 0.5 : 0)}>
                    {volume === 0 ? (
                        <HiVolumeOff size={20} />
                    ) : (
                        <HiVolumeUp size={20} />
                    )}
                </IconButton> */}

                {/* <Slider
                    value={volume}
                    onChange={handleVolumeChange}
                    min={0}
                    max={1}
                    step={0.01}
                    aria-label="Volume"
                    sx={{ width: 100  }}
                /> */}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <HiOutlineMinus
                        size={20}
                        style={{
                            color: "rgba(100, 100, 244, 0.3)",
                            marginRight: "1rem",
                        }}
                    />
                    <IconButton onClick={togglePlay} sx={{ color: "#6F6FFA" }}>
                        {isPlaying ? (
                            <HiPause size={50} />
                        ) : (
                            <HiPlay size={50} />
                        )}
                    </IconButton>
                    <HiOutlineMinus
                        size={20}
                        style={{
                            color: "rgba(100, 100, 244, 0.3)",
                            marginLeft: "1rem",
                        }}
                    />
                </Box>

                <IconButton
                    href={audioSrc}
                    target="_blank"
                    download
                    sx={{
                        background: "#6F6FFA",
                        color: "white",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        ":hover": {
                            background: "#5151D2",
                            color: "white",
                        },
                    }}
                >
                    <HiOutlineCloudDownload size={20} />
                </IconButton>

                <audio
                    ref={audioRef}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    src={audioSrc}
                >
                    {/* <source src={audioSrc} /> */}
                </audio>
            </Box>
            {/* <audio
                src={audioSrc}
                controls
                autoPlay
                style={{
                    width: "100%",
                }}
            ></audio> */}
        </Box>
    );
};

export default CustomAudioPlayer;
