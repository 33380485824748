import { createSlice } from "@reduxjs/toolkit";
import { BsNutFill } from "react-icons/bs";

const initialState = {
  cfgScale: 7,
  seed: null,
  steps: 30,
  sampler: "k_dpmpp_2m",
};

export const modelConfigSlice = createSlice({
  name: "modelConfig",
  initialState,
  reducers: {
    setModelConfig: (state, action) => {
      // console.log(action.payload.name, "action.payload.name");
      // console.log(action.payload.value, "action.payload.value")
      state[action.payload.name] = action.payload.value;
    },
    setSteps: (state, action) => {
      state.steps = action.payload.steps;
    },
    setsampler: (state, action) => {
      state.sampler = action.payload.sampler;
    },
    setCfgScale: (state, action) => {
      state.cfgScale = action.payload.cfgScale;
    },
    resetModelConfig: (state) => {
      state.cfgScale = 7.5;
      state.seed = null;
      state.steps = null;
    },
  },
});

export const {
  setModelConfig,
  setCfgScale,
  setSteps,
  setsampler,
  resetModelConfig,
} = modelConfigSlice.actions;
export default modelConfigSlice.reducer;
