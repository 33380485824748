import React, { useState } from "react";

import {
    Box,
    IconButton,
    Grid,
} from "@mui/material";
import {
    HiOutlineDownload,
    HiOutlineArrowsExpand,
    HiOutlineThumbUp,
    HiOutlineThumbDown,
    HiOutlineCheckCircle,
} from "react-icons/hi";
import {
    addDoc,
    collection,
    getFirestore,
    serverTimestamp,
    query,
    where,
    onSnapshot,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    increment,
} from "firebase/firestore";

import { saveAs } from "file-saver";
import { firebaseApp } from "../../firebaseInit";
import DarkToastNotification from "../atoms/DarkToastNotification";
import HeadshotsFeedbackModal from "./HeadshotsFeedbackModal";
import mixpanel from "mixpanel-browser";


const SingleImageCard = ({
    headshot,
    openFullView,
    changeFullViewImgIndex,
    index,
    newHeadshotsDocId,
    feedbackFormSubmitted,
    setFeedbackFormSubmitted,
    albumNameValue
}) => {
    const [likeUpdateSuccess, setLikeUpdateSuccess] = useState(false);
    const [dislikeUpdateSuccess, setDislikeUpdateSuccess] = useState(false);
    const handleDownloadClick = () => {
        saveAs(headshot, "headshot-download.png");
        mixpanel.track("Downloaded Headshots", {
            Description:
                "Headshots - page - Downloaded",
        });
    };

    const db = getFirestore(firebaseApp);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const displayToast = (message, error = false) => {
        setToastMessage(message);
        setIsError(error);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
    };

    const handleLikeClick = async () => {
        let docRef;
        if (albumNameValue !== "") {
            docRef = doc(db, "headshotsAlbums", newHeadshotsDocId);

        } else {
            docRef = doc(db, "headshots", newHeadshotsDocId);

        }

        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();

                const imgObjectsData = [...data.genImagesListOfObjects];

                const currHeadshot = imgObjectsData[index];

                imgObjectsData[index].likeCount = currHeadshot.likeCount
                    ? currHeadshot.likeCount + 1
                    : 1;

                imgObjectsData[index].likedByUser = true;

                await updateDoc(docRef, {
                    ...data,
                    genImagesListOfObjects: [
                        ...imgObjectsData
                    ]
                });

                setLikeUpdateSuccess(true);
                setTimeout(() => setLikeUpdateSuccess(false), 2000);
                displayToast("Liked successfully!");
                mixpanel.track("Likes Headshot", {
                    Description:
                        "Headshots - page - Likes headshots",
                });
            } else {
                console.log("Document does not exist!");
                displayToast("Error updating like!", true);
            }
        } catch (error) {
            console.error("Error in handleLikeClick:", error);
        }
    };

    const handleDislikeClick = async () => {
        let docRef;
        if (albumNameValue !== "") {
            docRef = doc(db, "headshotsAlbums", newHeadshotsDocId);

        } else {
            docRef = doc(db, "headshots", newHeadshotsDocId);

        }

        try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {

                const data = docSnap.data();

                const imgObjectsData = [...data.genImagesListOfObjects];

                const currHeadshot = imgObjectsData[index];

                imgObjectsData[index].dislikeCount = currHeadshot.dislikeCount
                    ? currHeadshot.dislikeCount + 1
                    : 1;

                imgObjectsData[index].likedByUser = false;

                await updateDoc(docRef, {
                    ...data,
                    genImagesListOfObjects: [
                        ...imgObjectsData
                    ]
                });

                setDislikeUpdateSuccess(true);
                setTimeout(() => setDislikeUpdateSuccess(false), 2000);
                displayToast("Disliked successfully!");

                if (!feedbackFormSubmitted) {
                    setFeedbackFormSubmitted(true)
                    setShowFeedbackModal(true);

                } else {
                    setShowFeedbackModal(false);

                }
                mixpanel.track("Dislikes Headshot", {
                    Description:
                        "Headshots - page - Dislikes headshots",
                });
            } else {
                console.log("Document does not exist!");
                displayToast("Error updating dislike!", true);
            }
        } catch (error) {
            console.error("Error in handleDislikeClick:", error);
        }
    };

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            sx={{
                borderRadius: "10px",
            }}
        >
            {showFeedbackModal && (
                <HeadshotsFeedbackModal
                    showFeedbackModal={showFeedbackModal}
                    setShowFeedbackModal={setShowFeedbackModal}
                    newHeadshotsDocId={newHeadshotsDocId}
                />
            )}
            {showToast && (
                <DarkToastNotification
                    message={toastMessage}
                    type={isError ? "error" : "success"}
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Box
                sx={{
                    position: "relative",
                    borderRadius: "15px",
                }}
            >
                <img src={headshot}></img>
                <IconButton
                    sx={{
                        background: "rgba(15, 23, 42, 0.4)",
                        backdropFilter: "blur(2px)",
                        color: "white",

                        "&:hover": {
                            background: "white",
                            color: "#0F172A",

                        },
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                    }}
                    onClick={() => {
                        openFullView();
                        changeFullViewImgIndex(index);
                    }}
                >
                    <HiOutlineArrowsExpand size={20} />
                </IconButton>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        background: "rgba(15, 23, 42, 0.4)",
                        backdropFilter: "blur(2px)",
                        display: "flex",
                        alignItems: "center",
                        gap: "",
                        justifyContent: "space-between",
                        padding: "0.6rem 1.3rem",
                    }}
                >
                    <IconButton
                        sx={{
                            background: "transparent",
                            color: "white",
                            "&:hover": {
                                background: "white",
                                color: "#0F172A",
                            },
                        }}
                        onClick={handleDownloadClick}
                    >
                        <HiOutlineDownload size={20} />
                    </IconButton>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "fit-content",
                            gap: { xs: "0.56rem", md: "0.56rem" },
                        }}
                    >

                        <IconButton
                            onClick={handleLikeClick}
                            /* sx={{
                                // Example: Change background color on success
                                background: likeUpdateSuccess
                                    ? "#4CAF50"
                                    : "#1E293B",
                                border: "2px solid #475569",

                                "&:hover": {
                                    background: likeUpdateSuccess
                                        ? "#4CAF50"
                                        : "#475569",
                                },
                                // other styles
                            }} */
                            sx={{
                                background: likeUpdateSuccess
                                    ? "#4CAF50"
                                    : "transparent",
                                color: "white",
                                "&:hover": {
                                    background: "#4CAF50",
                                    color: "white",
                                },
                            }}
                        >
                            {likeUpdateSuccess ? (
                                <HiOutlineCheckCircle size={20} />
                            ) : (
                                <HiOutlineThumbUp size={20} />
                            )}
                        </IconButton>
                        <IconButton
                            onClick={handleDislikeClick}

                            sx={{
                                background: dislikeUpdateSuccess
                                    ? "#4CAF50"
                                    : "transparent",
                                color: "white",
                                "&:hover": {
                                    background: "#FEC2C2",
                                    color: "#2F2323",
                                },
                            }}
                        >
                            {dislikeUpdateSuccess ? (
                                <HiOutlineCheckCircle size={20} />
                            ) : (
                                <HiOutlineThumbDown
                                    size={20}
                                />
                            )}
                        </IconButton>
                    </Box>

                </Box>
            </Box>
        </Grid>
    );
};

export default SingleImageCard;
