import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    Tooltip,
    Skeleton,
    Slide,
    CircularProgress,
} from "@mui/material";
import { HiOutlineEye, HiOutlinePencil, HiOutlinePlus } from "react-icons/hi";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { updateViewNumber } from "../../firestore/templateHandler";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import NeedToBuyAPlan from "../model/NeedToBuyAPlan";
import ToastNotification from "../atoms/ToastNotification";

import {
    getFirestore,
    doc,
    query,
    onSnapshot,
    where,
    collection,
} from "firebase/firestore";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PublicTemplateCardLatest = ({ template }) => {
    const [user, loading] = useAuthState(auth);
    const variantsFromDb = template.variants;

    const viewNumRef = useRef(null);
    const navigate = useNavigate();
    const theme = useTheme();

    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const [isHovered, setIsHovered] = useState(false);
    const impressions = 230;
    const uses = 210;

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const handleButtonClick = () => {
        // Add your button click logic here
    };

    useEffect(() => {
        const firestore = getFirestore();
        const templatesRef = collection(firestore, "templates");
        const queryRef = query(templatesRef, where("id", "==", template.id));

        const unsubscribe = onSnapshot(queryRef, (snapshot) => {
            snapshot.forEach((doc) => {
                const updatedTemplate = doc.data();
                // Handle the updated template data or perform any necessary actions
            });
        });

        // Cleanup the subscription on component unmount
        return () => unsubscribe();
    }, []);

    // Assuming you have already initialized the Firestore app and obtained the document ID
    const incrementViewQty = async (templateId) => {
        viewNumRef.current.innerText = Number(viewNumRef.current.innerText) + 1;
        console.log("Hello");
        updateViewNumber(templateId, user.email);
    };

    const handleRedirect = (template) => {
        if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                navigate("/login");
            }, 3000);
        } else {
            incrementViewQty(template.id);
            setTimeout(() => {
                navigate(`/templates/${template.id}`);
            }, [1500]);
            //navigate(`/templates/${template.id}`);
        }
    };

    return (
        <>
            {!isLoggedIn && (
                <ToastNotification
                    message="Please log in to use this feature."
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Box
                sx={{
                    borderRadius: "8px",
                    boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                    transition: "all 0.1s ease-in",

                    "&:hover": {
                        boxShadow:
                            theme.palette.mode === "light"
                                ? "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;"
                                : "none",
                        transition: "all 0.1s ease-in-out",
                        transform: "scale(1.04)",
                    },
                    position: "relative",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => handleRedirect(template)}
            >
                {/* {isLoading && (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            minHeight: "200px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <CircularProgress />
                        
                    </Box>
                )} */}
                <Box
                    sx={{
                        display: "flex",
                        /* gap: "0.3rem", */
                        flexDirection: "column",
                        borderRadius: "8px",
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            /* gap: "0.3rem", */
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={variantsFromDb[0].url}
                            style={{
                                width: "50%",
                                height: "auto",
                                aspectRatio: "1/1",
                                borderRadius: "inherit",
                            }}
                            /* onLoad={handleImageLoad} */
                            alt=""
                            loading="lazy"
                        />
                        <img
                            src={variantsFromDb[1].url}
                            style={{
                                width: "50%",
                                height: "auto",
                                aspectRatio: "1/1",
                                borderRadius: "inherit",
                            }}
                            /* onLoad={handleImageLoad} */
                            alt=""
                            loading="lazy"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            /* gap: "0.3rem", */
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={variantsFromDb[2].url}
                            style={{
                                width: "50%",
                                height: "auto",
                                aspectRatio: "1/1",
                                borderRadius: "inherit",
                            }}
                            /* onLoad={handleImageLoad} */
                            alt=""
                            loading="lazy"
                        />
                        <img
                            src={variantsFromDb[3].url}
                            style={{
                                width: "50%",
                                height: "auto",
                                aspectRatio: "1/1",
                                borderRadius: "inherit",
                            }}
                            /* onLoad={handleImageLoad} */
                            alt=""
                            loading="lazy"
                        />
                    </Box>
                </Box>
                {/* <img
                    src={template.url}
                    style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "inherit",
                    }}
                    onLoad={handleImageLoad}
                    alt=""
                /> */}
                <Box
                    sx={{
                        padding: "1rem 0.5rem",
                        height: "4.9rem",
                        borderRadius: "inherit",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            maxWidth: "60%",
                            textTransform: "capitalize",
                        }}
                        style={{ textDecoration: "none" }}
                    >
                        {template.category}
                    </Typography>
                    <Box sx={{ display: "flex" }} style={{ gap: "0.23rem" }}>
                        <Tooltip title={`${template.viewQty} people viewed`}>
                            <Box
                                sx={{ display: "flex", alignItems: "center" }}
                                style={{ gap: "0.14rem" }}
                            >
                                <HiOutlineEye size={16} />
                                <Typography
                                    sx={{ fontSize: "0.87rem" }}
                                    ref={viewNumRef}
                                >
                                    {template.viewQty}
                                    {/* {impressions} */}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Tooltip title={`${template.usedQty} images generated`}>
                            <Box
                                sx={{ display: "flex", alignItems: "center" }}
                                style={{ gap: "0.14rem" }}
                            >
                                <HiOutlinePencil size={16} />
                                <Typography sx={{ fontSize: "0.87rem" }}>
                                    {template.usedQty}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>

                    {/* <Typography sx={{ px: 1, }}>
                    <strong>{template.category}</strong>
                </Typography> */}
                </Box>
                {isHovered && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            /* background: "rgba(99, 99, 255, 0.05)", */
                            //background: "rgba(14, 14, 14, 0.2)",
                            background:
                                "radial-gradient(rgba(88, 188, 255, 0.4), rgba(99, 99, 255, 0.4))",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "inherit",
                        }}
                    >
                        <IconButton
                            onClick={handleButtonClick}
                            sx={{
                                background: "#6363FF",
                                color: "white",
                                border: "2px solid white",
                                "&:hover": {
                                    background: "#5050D6",
                                },
                            }}
                        >
                            <HiOutlinePlus size={25} />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default PublicTemplateCardLatest;
