import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import mixpanel from "mixpanel-browser";
import React from "react";
import { BsFolder } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setConfig, setFolder } from "../../../features/configs/configSilce";

const getMatchedFolders = (folders, searchTerm) => {
  return folders.filter((itm) => {
    return itm.toLowerCase().includes(searchTerm.toLowerCase());
  });
};

export default function FolderSystem() {
  const config = useSelector((state) => state.config);
  let folders = config.folders;
  folders = folders.filter((each) => each);
  const [displayableFolders, setDisplayableFolders] = React.useState(
    folders.slice(0, 5)
  );
  const activefolder = config.activefolder;
  const matchedFolder = folders.filter((f) => f === activefolder)[0];

  const [newFolderName, setNewFolderName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const handleFolderNameChange = (val) => {
    dispatch(
      setConfig({
        name: "activefolder",
        value: val,
      })
    );
    const matched = getMatchedFolders(folders, val);
    setDisplayableFolders(matched.slice(0, 5));
  };

  const handleAddNewFolder = () => {
    dispatch(setFolder({ activefolder }));
    const matched = getMatchedFolders([activefolder, ...folders], activefolder);
    setDisplayableFolders(matched.slice(0, 5));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const [addFolderInput, setAddFolderInput] = React.useState();
  //   this handles the create folder things...
  const handleCreateFolder = () => {
    console.log(addFolderInput);
    // close the popup...
    handleClose();
    // set the current folder name...
    dispatch(
      setConfig({
        name: "activefolder",
        value: addFolderInput,
      })
    );
    // set the folder to folders list...
    dispatch(
      setFolder({
        activefolder: addFolderInput,
      })
    );
    // change the select area value..
    setNewFolderName(addFolderInput);
  };

  const createFolderInputValues = (event) => {
    setAddFolderInput(event.target.value);
    // mixpanel.track("Folder Create Event - Image generation ", {
    //   value: event.target.value,
    // });
  };

  const handleChange = (event) => {
    setNewFolderName(event.target.value);
    // mixpanel.track("Change folder  - Image generation ", {
    //   value: event.target.value,
    // });

    dispatch(
      setConfig({
        name: "activefolder",
        value: event.target.value,
      })
    );
  };

  console.log(config);
  return (
    <div>
      <FormControl
        size="small"
        sx={{
          mr: 1,
          mb: 1,
          minWidth: 300,
          maxWidth: 400,
        }}
      >
        <InputLabel id="folder-select" size="small">
          Select Folder Name
        </InputLabel>
        <Select
          labelId="folder-select"
          id="folder-select"
          // value={newFolderName}
          value={activefolder}
          onChange={handleChange}
          label="Select Folder Name"
        >
          <MenuItem value="">
            <em>Default</em>
          </MenuItem>
          {folders.map((each) => (
            <MenuItem value={each}>{each}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        color="primary"
        aria-label=""
        sx={{ textTransform: "capitalize", p: "3px 15px" }}
        variant="outlined"
        onClick={handleOpen}
      >
        Add Folder <BsFolder style={{ marginLeft: ".3rem" }} />
      </Button>
      <Typography
        color="info"
        variant="body2"
      >
        Choose Folder for Image Output (Leave Blank for No Folder)
      </Typography>
      {/* <Divider light /> */}

      {/* creating folder model... */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            CREATE FOLDER
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Provide Name for your Folder or Project
          </Typography>
          <TextField
            id="filled-basic"
            onChange={createFolderInputValues}
            label="Folder name"
            size="small"
            variant="outlined"
            sx={{ width: "100%", my: 2 }}
          />
          <ButtonGroup
            variant="outlined"
            size="small"
            sx={{
              width: "100%",
              textTransform: "capitalize",
            }}
          >
            <Button
              sx={{ width: "50%", textTransform: "capitalize" }}
              onClick={handleClose}
              color="error"
            >
              Cancel
            </Button>
            <Button
              sx={{ width: "50%", textTransform: "capitalize" }}
              onClick={handleCreateFolder}
            >
              Create Folder
            </Button>
          </ButtonGroup>
        </Card>
      </Modal>

      <TextField
        label="Active Folder"
        id="outlined-size-small"
        defaultValue="Small"
        size="small"
        value={activefolder}
        name="activefolder"
        style={{
          marginBottom: "1rem",
          display: "none",
        }}
        onChange={(e) => handleFolderNameChange(e.target.value)}
      />
      <div
        style={{
          display: "none",
          flexDirection: "row",
          gap: "5px",
        }}
      >
        {!matchedFolder && activefolder ? (
          <FolderChip
            text={"+ Add " + activefolder}
            onClick={handleAddNewFolder}
          />
        ) : null}
        {displayableFolders
          ? displayableFolders.map((elem, i) => {
            return (
              <FolderChip
                key={i}
                text={elem}
                onClick={() => handleFolderNameChange(elem)}
              />
            );
          })
          : null}
      </div>
    </div>
  );
}

const FolderChip = (props) => {
  return (
    <Tooltip title="Click To Select" arrow placement="top">
      <Typography
        variant="body2"
        {...props}
        style={{
          padding: "0 .5rem",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <BsFolder sx={{ mr: 2 }} style={{ marginRight: ".5rem" }} />{" "}
        {props.text}
      </Typography>
    </Tooltip>
  );
};
