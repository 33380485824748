import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { fetchLexicalData } from "../../axios/fetchdata";


import mixpanel from "mixpanel-browser";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #r6r6r6",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  height:'70vh',
  overflowY: "scroll",
};

export default function FilterCategory({
  setFilterPrompt,
  setLexicaImages,
  setFilterImg,
  filterImg,filterRef
}) {
  const [open, setOpen] = React.useState(false);
  const [filteName, setFilteName] = React.useState("Choose A Filter");
  const handleOpen = () => {
    
    setOpen(true);

     mixpanel.track("Art generation - Choose a filter", {
      Description: "On Art generation - user can choose a filter"
    });

  }
  const handleClose = () => setOpen(false);

  const filterHandlere = (item) => {
    handleClose();
    setFilterPrompt(item.Prompt);
    // setFilterImg(item.img)
    setFilterImg(item.image);
    setFilteName(item.Category);

    fetchLexicalData(item.Prompt, "regular").then((res) => {
      setLexicaImages(res);
    });
  };
  const filterHandlere1 = () => {
    handleClose();
    setFilterPrompt(null);
    // setFilterImg(item.img)
    setFilterImg('https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/tempNo1.svg');
    setFilteName("Choose A Filter");

    // fetchLexicalData(item.Prompt, "regular").then((res) => {
    //   setLexicaImages(res);
    // });
  };

   

  return (
    <div>
      <div ref={filterRef} onClick={handleOpen} class="module-border-wrap">
        <div class="module">
          <img
            style={{
              width: "30%",
              marginRight: "5px",
              borderRadius: "5px",
              height: "30px",
              ObjectFit: "cover",
            }}
            src={filterImg}
          />

          {filteName}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={headerStyle} id="modal-modal-title" variant="h6" component="h2">
            Choose A Filter
          </Typography>
          <Typography sx={desStyle} id="modal-modal-description">Experiment with different styles that can be applied to your image.
          </Typography>

          <Grid container>
          <Grid item xs={4} md={2} onClick={() => filterHandlere1()}>
                <Box
                  sx={{
                    // border: `2px solid ${
                    //   filterImg === item.image ? "#eb4de6" : "#fff"
                    // } `,

                    "&:hover": {
                      cursor: "pointer",
                      transition: "0.3s ease-in-out",
                      transform: "scale(1.08)",
                    },
                    padding: "2px",
                    margin: "5px",
                    borderRadius: "5px",
                    background: "#e6e6e6",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      height: "100px",
                      borderRadius: "5px",
                      width: "100%",
                      ObjectFit: "cover",
                    }}
                    src='https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/tempNo1.svg'
                  />
                  <p style={{ padding: "10px 0px" }}>No Filter</p>
                </Box>
              </Grid>
            {catData.map((item, index) => (
              <Grid item xs={4} md={2} onClick={() => filterHandlere(item)}>
                <Box
                  sx={{
                    border: `2px solid ${
                      filterImg === item.image ? "#eb4de6" : "#fff"
                    } `,

                    "&:hover": {
                      cursor: "pointer",
                      transition: "0.3s ease-in-out",
                      transform: "scale(1.08)",
                    },
                    padding: "2px",
                    margin: "5px",
                    borderRadius: "5px",
                    background: "#e6e6e6",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      height: "100px",
                      borderRadius: "5px",
                      width: "100%",
                      ObjectFit: "cover",
                    }}
                    src={item.image}
                  />
                  <p style={{ padding: "10px 0px" }}>{item.Category}</p>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const catData = [
  // {
  //   Category: "colorpop",
  //   image:
  //     "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/1a958477-e67d-4ca8-bcc2-3104ffe9038f.png",

  //   // colorpop style, astronaut

  //   Prompt:
  //     " {Object} by Paul Cadden, extremely detailed, high quality, color pencil, blue background, modern art, surrealism, optical illusion, portrait, 3d anamorphic, faces",
  // },
  {
    Category: "Design illustration",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/templates%2F2dcc8d7a-a241-4453-a2e6-871203fa7f48?alt=media&token=f59b57f5-7a29-4f42-8243-ab34dcd85d4b",

    // colorpop style, astronaut

    Prompt:
      " {Object}, flat style illustration for business ideas, flat design vector, industrial, light and magical, high resolution, engineering/ construction and design, colored cartoon style, light indigo and light gold, cad( computer aided design) , white background --ar 3:2 --v 5",
  },
  {
    Category: "playtoon",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/ad06f4ed-9a04-497a-a92b-e8e1810ecb46.png",

    // 'prompt': "pltn style, astronaut, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",

    Prompt:
      " {Object}, playtoon, 3d, blender, render, cg, computer graphics, art, by Greg Rutkowski",
  },
  {
    Category: "polymode",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2Fpolymode.png?alt=media&token=f37f2415-8b78-448b-8f01-9237c6e5a5d7",

    // 'Prompt': 'polymode style, astronaut, toy, soft smooth lighting, soft pastel colors, 3d blender render, polycount, modular constructivism, pop surrealism, physically based rendering',

    Prompt:
      "polymode style, {Object}, toy, soft smooth lighting, soft pastel colors, 3d blender render, polycount, modular constructivism, pop surrealism, physically based rendering",
  },
  {
    Category: "Woolitize",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/c9c32483-1da6-407b-8b32-531baf2a04b9.png",

    // 'Prompt': 'woolitize, astronaut, soft smooth lighting, soft pastel colors',

    Prompt:
      "3d {Object}, made of wool, colorful, intricate details, looking at the camera, 8k, by Greg Rutkowski",
  },

  {
    Category: " Retro anime ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2FAnime_portrait_of_a__img.png?alt=media&token=3b97fbe7-b1b6-4041-b7ac-7f2e5187b21a",

    // 'Prompt': 'retroanime style, astronaut',

    Prompt:
      "Anime portrait of {Object}, big eyes, small mouth, 80s style, digital painting, looking into camera, square image",
  },

  {
    Category: "retrofuturism",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2Fdownload%20(1).png?alt=media&token=3bc3a6bd-fe96-408d-ae4b-fbbd714b1b28",

    // 'Prompt': 'retrofuturism style, astronaut',

    Prompt:
      " {Object} in the style of retrofuturism, by Aled Lewis, a digital painting, a poster for a retro sci-fi movie, a stylized and detailed spaceship, with a bright and colorful background",
  },

  {
    Category: " origami style",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2F3d_bird_made_of_pape_img.png?alt=media&token=10f85cf1-e4bc-41d3-bcde-d5414f4ef4fc",

    // 'Prompt': 'origami style, astronaut, studio lighting, white background, pastel colors',

    Prompt:
      "3d {Object} made of paper, by [Sipha.st](http://sipha.st/), colorful, geometric, abstract, clean design, simple, minimal, papercraft",
  },
  {
    Category: "playdoh style",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/35da29c4-26ef-432c-914d-e691609a4ffc.png",

    // 'Prompt': 'playdoh style, astronaut',

    Prompt:
      "Cute and colorful {Object}, made of playdoh, by Greg Rutkowski, chibi style, pastel colors, soft lighting, pixar render, unreal engine",
  },

  {
    Category: "polaroid style",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2Fpolaroid-style-car-.png?alt=media&token=a590a5e0-7ecb-4fa4-91ad-c472cd9fa876",

    // 'Prompt': 'polaroid style, astronaut',

    Prompt:
      "{Object} in the middle of the frame, with the background blurred out, in the style of a polaroid photo, with a white border",
  },

  {
    Category: "foodmade style",
    image:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/public_templates%2FA_castle_made_of_oth_img.png?alt=media&token=0bc0cce3-5c54-4318-b3e0-00874090e37b",

    Prompt: "foodmade style, astronaut",
  },

  // {

  // 'Category':' Delicate detail',
  // "image" : "",

  // 'Prompt': '{Object}, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski',
  // },

  // {'Category':' Radiant symmetry' ,
  // "image" : "",

  // 'Prompt': '{Object}, centered, symmetry, painted, intricate, volumetric lighting, beautiful, rich deep colors masterpiece, sharp focus, ultra detailed, in the style of dan mumford and marc simonetti, astrophotography',
  // },
  // {'Category': 'Lush illumination',
  // "image" : "",

  // 'Prompt': '{Object}, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment',
  // },
  // {
  //   Category: "Saturated space",
  //   image:
  //     "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Saturated%20space.png",

  //   Prompt:
  //     " {Object}, outer space, vanishing point, super highway, high speed, digital render, digital painting, beeple, noah bradley, cyril roland, ross tran, trending on artstation",
  // },
  {
    Category: "Neon Mecha",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Neon%20Mecha.png",

    Prompt:
      "{Object}, neon ambiance, abstract black oil, gear mecha, detailed acrylic, grunge, intricate complexity, rendered in unreal engine, photorealistic",
  },
  {
    Category: "Low poly ",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/8abde32c-bdee-4b38-be2c-155108c08ec0",

    Prompt:
      "{Object}, low poly, isometric art, 3d art, high detail, artstation, concept art, behance, ray tracing, smooth, sharp focus, ethereal lighting",
  },
  {
    Category: "Warm box",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Warm%20box.png",

    Prompt:
      "{Object}, golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact james gilleard greg rutkowski chiho aoshima",
  },

  {
    Category: "Cinematic",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Cinematic.png",

    Prompt:
      "{Object}, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger",
  },
  // {'Category': 'Cinematic swarm',
  // "image" : "",

  // 'Prompt': '{Object}, sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner',
  // },
  // {'Category': 'Wasteland',
  // "image" : "",

  // 'Prompt': '{Object}, isometric, digital art, smog, pollution, toxic waste, chimneys and railroads, 3 d render, octane render, volumetrics, by greg rutkowski'
  // },
  // {'Category': 'Flat palette',
  // "image" : "",

  // 'Prompt': '{Object}, acrylic painting, trending on pixiv fanbox, palette knife and brush strokes, style of makoto shinkai jamie wyeth james gilleard edward hopper greg rutkowski studio ghibli genshin impact'},

  // {'Category': 'Ominous escape',
  // "image" : "",

  // 'Prompt': '{Object}, professional ominous concept art, by artgerm and greg rutkowski, an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of simon stalenhag, wayne barlowe, and igor kieryluk.'
  // },
  // {'Category': 'Spielberg',
  // "image" : "",

  // 'Prompt':  '{Object}, cinematic, 4k, epic Steven Spielberg movie still, sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner'
  // },
  // {'Category': 'Royalistic' ,
  // "image" : "",

  // 'Prompt':'{Object}, epic royal background, big royal uncropped crown, royal jewelry, robotic, nature, full shot, symmetrical, Greg Rutkowski, Charlie Bowater, Beeple, Unreal 5, hyperrealistic, dynamic lighting, fantasy art'},
  {
    Category: "Masterpiece",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Masterpiece.png",

    Prompt:
      "{Object}, sf, intricate artwork masterpiece, ominous, matte painting movie poster, golden ratio, trending on cgsociety, intricate, epic, trending on artstation, by artgerm, h. r. giger and beksinski, highly detailed, vibrant, production cinematic character render, ultra high quality model",
  },
  {
    Category: " Wall art",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Wall%20art.png",

    Prompt:
      "{Object}, by Jacob Lawrence and Francis picabia, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger",
  },
  {
    Category: "Black and white 3D",
    image:
      "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/Black%20and%20white%203D.png",

    Prompt:
      " {Object}, black and white still, digital Art, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger",
  },
];


const headerStyle = {
  fontSize:'16px',
  fontWeight:'bold',
  color:'#000000'
}
const desStyle = {
  fontSize:'15px',
  fontWeight:'500',
  color:'#666',
  mb:2
}