import { Divider, Link as MuiLink, Box } from "@mui/material";
import React from "react";
import { BsFacebook } from "react-icons/bs";

export default function FooterV2() {
    return (
        <footer
            style={{
                marginTop: "3rem",
                background: "",
                fontFamily: "Inter",
            }}
            id="footer"
        >
            <Box sx={{ py: 4, px: { xs: "0.5rem", md: "4rem" } }}>
                <ul
                    style={{
                        display: "flex",
                        flexDirection:
                            window.innerWidth < 768 ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "center",
                        /* paddingBlock: "1rem", */
                        listStyle: "none",
                        flexWrap: "wrap",
                        marginBottom: "1.5rem",
                        gap: { xs: "0", md: "0.5rem" },
                    }}
                >
                    {[
                        {
                            key: 1,
                            title: "AISEO Officials",
                            link: "https://aiseo.ai/index.html",
                        },
                        {
                            key: 2,
                            title: "Become An Affiliate",
                            link: "https://aiseo.firstpromoter.com/login",
                        },
                        {
                            key: 3,
                            title: "Privacy Policy",
                            link: "/privacy-and-policy",
                        },
                        {
                            key: 4,
                            title: "Terms",
                            link: "/terms",
                        },
                    ].map((item) => (
                        <li key={item.key}>
                            <MuiLink
                                sx={{
                                    pb: 1,
                                    color: "gray",
                                    textDecoration: "none",
                                    margin: "0 1rem",

                                    "&:hover": {
                                        color: "#6363FF",
                                    },
                                }}
                                href={item.link}
                            >
                                {item.title}
                            </MuiLink>
                        </li>
                    ))}
                </ul>
                <Divider />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "1rem",
                        color: "gray",
                        flexDirection:
                            window.innerWidth < 768 ? "column-reverse" : "row",
                        alignItems: "center",
                    }}
                >
                    <p
                        style={{
                            /* display: "flex",
                            alignItems: "center", */
                            color: "gray",
                        }}
                    >
                        <img
                            src=""
                            style={{ maxWidth: "150px", marginRight: "1rem" }}
                            alt=""
                            srcSet=""
                        />
                        © {new Date().getFullYear()} AISEO Officials. All rights
                        reserved.
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li style={{ marginLeft: "1.5rem" }}>
                            <a
                                href="https://www.facebook.com/groups/154418810108718"
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "#3C7EFF",
                                }}
                            >
                                <BsFacebook style={{ fontSize: "24px" }} />
                            </a>
                        </li>
                    </ul>
                </div>
            </Box>
        </footer>
    );
}
