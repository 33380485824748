import React, { useState, useEffect } from "react";

import mixpanel from "mixpanel-browser";

import newAiModelPlusIcon from "../../../imgs/newAiModelPlusIcon.svg";
import demoModelOne from "../../../imgs/demoModelOne.png";
import demoModelTwo from "../../../imgs/demoModelTwo.png";
import demoModelThree from "../../../imgs/demoModelThree.png";
import demoModelFour from "../../../imgs/demoModelFour.png";
import demoModelFive from "../../../imgs/demoModelFive.png";
import demoModelSix from "../../../imgs/demoModelSix.png";
import {
    getFirestore,
    collection,
    query,
    where,
    onSnapshot,
    doc,
    getDoc,
    setDoc,
} from "firebase/firestore";
import {
    Box,
    Button,
    Typography,
    Slide,
    CircularProgress,
} from "@mui/material";
import { HiOutlineCheck } from "react-icons/hi";
import { firebaseApp } from "../../../firebaseInit";
import { useLocation, useNavigate } from "react-router-dom";
import ToastNotification from "../../atoms/ToastNotification";
import { useAPI } from "../../../StateProvider";

import NeedToBuyAvatarsPlan from "../../model/NeedToBuyAvatarsPlan";
import BuyAvatarsOfferPopup from "../../model/BuyAvatarsOfferPopup";
import { uploadFiles } from "../../../axios";

const premadeModels = [
    {
        name: "AI Trump",
        imgSrc: demoModelOne,
    },
    {
        name: "AI Pet",
        imgSrc: demoModelTwo,
    },
    {
        name: "AI Asian",
        imgSrc: demoModelThree,
    },
    {
        name: "AI Dilyar",
        imgSrc: demoModelFour,
    },
    {
        name: "AI Musk",
        imgSrc: demoModelFive,
    },
    {
        name: "AI Rihanna",
        imgSrc: demoModelSix,
    },
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NewModelSidebarBox = ({
    user,
    toggleModelPopup,
    handleModelOptionClick,
    activeModel,
    setSelectedPresets,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore(firebaseApp);

    const [showFreeCreditsFinishedNotyf, setShowFreeCreditsFinishedNotyf] =
        useState(false);
    const { data } = useAPI();

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [loading, setLoading] = useState(true);
    const [models, setModels] = useState([]);
    const [publicModels, setPublicModels] = useState([]);
    const [accountInfo, setAccountInfo] = useState(null);

    const [userProhibited, setUserProhibited] = useState(false);

    const handleUserCheck = () => {
        console.log("no user");
        if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                if (location.pathname === "/ai-photoshoots") {
                    navigate("/login");
                }
            }, 1300);
            return false;
        }
        return true;
    };

    const handleSubscriptionCheck = (data) => {
        let newCost = 0;

        if (
            !data.avatar_subscription ||
            data?.avatar_subscription.type === "Free" ||
            data?.avatar_subscription.status !== "active"
        ) {
            if (!data.avatar_models || data.avatar_models < 1) {
                console.log("SUBSCRIPTION NOT DEFINED for models");
                setUserProhibited(true);
                setShowFreeCreditsFinishedNotyf(true);
                setTimeout(() => {
                    setShowFreeCreditsFinishedNotyf(false);
                }, 3200);
                return false;
            } else {
                console.log("SUBSCRIPTION DEFINED for models");
                return true;
            }
        } else {
            if (!data.avatar_models || data.avatar_models < 1) {
                console.log("SUBSCRIPTION NOT DEFINED for models 2");
                setUserProhibited(true);
                return false;
            } else {
                console.log("SUBSCRIPTION DEFINED for models 2");
                return true;
            }
        }

        return true;
    };

    const handleTogglePopup = () => {
        console.log("/login");

        if (!handleUserCheck()) return;
        if (!handleSubscriptionCheck(data)) return;

        mixpanel.track("AIPHOTOSHOOTS - New Ai Model - opened", {
            Description:
                "Ai photoshoots - new ai model - clicked from the ai-photoshoots page",
        });

        toggleModelPopup();
    };

    // const [selectedImages, setSelectedImages] = useState([]);
    // const [responseMessage, setResponseMessage] = useState("");

    // const handleImageChange = (e) => {
    //     const files = e.target.files;
    //     setSelectedImages([...selectedImages, ...files]);
    //     console.log([...selectedImages, ...files], "--selectedImages--");
    // };

    // const handleUpload = () => {
    //     //change here api url here

    //     const apiUrl =
    //         "https://us-central1-code-translate-c9640.cloudfunctions.net/art/upload-images"; // Replace with your API endpoint

    //     const formData = new FormData();

    //     selectedImages.forEach((image, index) => {
    //         formData.append(`image${index}`, image);
    //     });

    //     // Convert formData to a plain JavaScript object for logging
    //     const formDataObject = {};
    //     formData.forEach((value, key) => {
    //         formDataObject[key] = value;
    //     });

    //     console.log(typeof formData, "--formDataObject--"); // Log the formData object
    //     console.log(formDataObject, "--formDataObject--"); // Log the formData object

    //     uploadFiles(formData)
    //         .then((resp) => {
    //             console.log(resp);
    //             // setResponseMessage(
    //             //     "Upload successful: " + JSON.stringify(resp)
    //             // );
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             setResponseMessage("Upload failed: " + error.message);
    //         });

    //     /*
    //     fetch(apiUrl, {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //         body: formData,
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! Status: ${response.status}`);
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             setResponseMessage(
    //                 "Upload successful: " + JSON.stringify(data)
    //             );
    //         })
    //         .catch((error) => {
    //             setResponseMessage("Upload failed: " + error.message);
    //         });
    //     */
    // };

    useEffect(() => {
        console.log(user, "--user--");
        if (user?.email) {
            const q = query(
                collection(db, "tuned_models"),
                where("email", "==", user.email)
            );

            const unsubscribe = onSnapshot(
                q,
                (snapshot) => {
                    const modelsData = [];
                    snapshot.forEach((doc) => {
                        modelsData.push({ ...doc.data(), id: doc.id });
                    });
                    setModels(modelsData);
                    setLoading(false);
                    console.log(modelsData, "--modelsData--");
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                    setLoading(false);
                }
            );

            // Cleanup the listener on component unmount
            return () => unsubscribe();
        } else {
            setLoading(false);
        }
    }, [user?.email, db]);

    useEffect(() => {
        if (!user) {
            return;
        }
        const publicModelsOwnerEmail = "satauskz@gmail.com";
        const publicTemplateID1 = "i5jlds3btabd23rpfi3xxg4iay";
        const publicTemplateID2 = "lp4er4tbfjhhmufg3ddg5h3aay";

        const q = query(
            collection(db, "tuned_models"),
            where("email", "==", publicModelsOwnerEmail)
        );

        const unsubscribe = onSnapshot(
            q,
            (snapshot) => {
                let publicModelsData = [];
                snapshot.forEach((doc, idx) => {
                    publicModelsData.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                publicModelsData = publicModelsData.filter(
                    (it) =>
                        it.training_id === publicTemplateID1 ||
                        it.training_id === publicTemplateID2
                );
                //setModels(publicModelsData);
                setPublicModels(publicModelsData);

                setLoading(false);
                console.log(publicModelsData, "--publicModelsData--");
            },
            (error) => {
                console.error("Error fetching publicModelsData: ", error);
                setLoading(false);
            }
        );

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, [user, db]);

    /* const updateUserDocument = async () => {
        const userDocRef = doc(db, "arts", user?.email);

        try {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const myAccData = docSnap.data();
                console.log(myAccData, "--myAccData--");
                setAccountInfo({ ...myAccData });

                if (
                    !myAccData?.avatar_subscription?.status ||
                    myAccData?.avatar_subscription?.status !== "active"
                ) {
                    // Check if the field avatarsFreeCredits already exists
                    if (!docSnap.data().hasOwnProperty("avatarsFreeCredits")) {
                        console.log("Done with setting avatars Free credits");

                        // Update the avatarsFreeCredits in the document

                        await setDoc(
                            userDocRef,
                            { avatarsFreeCredits: 5 },
                            { merge: true }
                        );
                    }
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }; */

    const avatarFreeCreditsLimit = 5;
    const avatarModelsCreditsLimit = 1;
    const avatarsFreeAlbumsCreditsLimit = 1;
    const avatarPhotosCreditsLimit = 30;

    const updateUserDocument = async () => {
        const userDocRef = doc(db, "arts", user?.email);

        try {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const myAccData = docSnap.data();
                console.log(myAccData, "--myAccData--");
                setAccountInfo({ ...myAccData });

                let updateFields = {};

                // Check if avatar_subscription.status is not present or not "active"
                if (
                    !myAccData?.avatar_subscription?.status ||
                    myAccData?.avatar_subscription?.status !== "active"
                ) {
                    // Check if avatarsFreeCredits property exists
                    if (!myAccData.hasOwnProperty("avatarsFreeCredits")) {
                        console.log("Setting avatars Free credits");
                        updateFields.avatarsFreeCredits = 5;
                    }

                    // Check if avatarsFreeModelCredits property exists
                    if (!myAccData.hasOwnProperty("avatar_models")) {
                        console.log("Setting avatars Free Model credits");
                        updateFields.avatar_models = 1;
                    }
                    /* if (!myAccData.hasOwnProperty("avatarsFreeModelCredits")) {
                        console.log("Setting avatars Free Model credits");
                        updateFields.avatarsFreeModelCredits = 1;
                    } */

                    // Check if avatarsFreeAlbumsCredits property exists
                    if (!myAccData.hasOwnProperty("avatarsFreeAlbumsCredits")) {
                        console.log("Setting avatars Free Albums credits");
                        updateFields.avatarsFreeAlbumsCredits = 1;
                    }

                    if (!myAccData.hasOwnProperty("avatar_photos")) {
                        console.log(
                            "Setting avatar_photos Free Albums credits"
                        );
                        updateFields.avatar_photos = 30;
                    }

                    // If any of the properties were missing, update the document
                    if (Object.keys(updateFields).length) {
                        await setDoc(userDocRef, updateFields, { merge: true });
                    }
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    useEffect(() => {
        if (accountInfo?.avatar_subscription?.status !== "active") {
            updateUserDocument();
        }
    }, [user]);

    return (
        <Box>
            {userProhibited ? (
                <>
                    <NeedToBuyAvatarsPlan
                        showFreeCreditsFinishedNotyf={
                            showFreeCreditsFinishedNotyf
                        }
                        lockWarning={userProhibited}
                        setLockWarning={setUserProhibited}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                </>
            ) : null}
            {/* {userProhibited ? (
                <>
                    <NeedToBuyAvatarsPlan
                        lockWarning={userProhibited}
                        setLockWarning={setUserProhibited}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                </>
            ) : null} */}
            {!isLoggedIn && (
                <ToastNotification
                    message="Please login ..."
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Typography
                sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    marginBottom: "1rem",
                }}
            >
                Select AI Model
            </Typography>

            {/* <Box mt={4}>
                <Typography variant="h4" gutterBottom>
                    Image Uploader
                </Typography>
                <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    id="image-upload-input"
                    onChange={handleImageChange}
                />
                <label htmlFor="image-upload-input">
                    <Button variant="contained" component="span">
                        Select Images
                    </Button>
                </label>
                <Button
                    variant="contained"
                    onClick={handleUpload}
                    disabled={selectedImages.length === 0}
                >
                    Upload Images
                </Button>
                <div>{responseMessage}</div>
            </Box> */}
            <Box
                sx={{
                    width: "289px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    border: "2px solid #E0E7FF",
                    borderRadius: "0.7rem",
                }}
            >
                <Box
                    sx={{
                        padding: "16px",
                    }}
                >
                    <Button
                        sx={{
                            background:
                                "linear-gradient(47deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                            /* background:
                                        "linear-gradient(75deg, #EF54FE 0%, #8980FF 57.81%, #62B5FF 100%)", */
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            gap: "11px",
                            textTransform: "capitalize",
                            fontWeight: "600",
                            transform: "transition all 0.3s ease-in",

                            "&:hover": {
                                background:
                                    "linear-gradient(27deg, #EF54FE 0%, #8980FF 45.97%, #6464FF 100%)",
                            },
                            width: "100%",
                            "&:disabled": {
                                background: "#1F2937",
                                color: "#64748B",
                                cursor: "no-drop !important",
                                cursor: "not-allowed",
                                pointerEvents: "all !important",
                            },
                        }}
                        onClick={() => handleTogglePopup()}
                        disabled={
                            data?.hasOwnProperty("credits") ? false : true
                        }
                    >
                        <img
                            src={newAiModelPlusIcon}
                            style={{
                                width: "42px",
                                height: "42px",
                            }}
                        />

                        <span>New AI Model</span>
                    </Button>
                </Box>
                {loading ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "132px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress size={25} />
                    </Box>
                ) : data?.avatar_subscription?.status === "active" ? (
                    models.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.3rem",
                                flexWrap: "wrap",
                                height: "132px",
                                overflowY: "scroll",
                                paddingX: "1rem",
                                alignItems: "center",
                                /* justifyContent: "center", */
                            }}
                        >
                            {accountInfo?.avatar_subscription?.status ===
                                "active" &&
                                models
                                    .filter(
                                        (model) => model.status !== "failed"
                                    )
                                    .map((model, index) => (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "78px",
                                                height: "78px",
                                                borderRadius: "10px",
                                                overflow: "hidden",
                                                position: "relative",
                                                pointerEvents:
                                                    model.status !== "succeeded"
                                                        ? "none"
                                                        : "auto",
                                                cursor:
                                                    model.status !== "succeeded"
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            key={index}
                                            onClick={() => {
                                                handleModelOptionClick(model);
                                                //setSelectedPresets([]);
                                            }}
                                        >
                                            {model.status !== "succeeded" && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        top: "0",
                                                        zIndex: "91",
                                                        background:
                                                            "rgba(0, 0, 0, 0.5)",
                                                        backdropFilter:
                                                            "blur(3px)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <CircularProgress
                                                        size={30}
                                                        sx={{
                                                            color: "white",
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {model.training_id ===
                                                activeModel.training_id && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        top: "0",
                                                        zIndex: "91",
                                                        background:
                                                            "rgba(0, 0, 0, 0.5)",
                                                        backdropFilter:
                                                            "blur(3px)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <HiOutlineCheck size={30} />
                                                </Box>
                                            )}

                                            <img
                                                src={model.selfie}
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    top: "0",
                                                    bottom: "0",
                                                    width: "78px",
                                                    height: "78px",
                                                    zIndex: "80",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    background:
                                                        "rgba(0, 0, 0, 0.5)",
                                                    color: "white",
                                                    fontSize: "13px",
                                                    textAlign: "center",
                                                    position: "absolute",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                    zIndex: "90",
                                                }}
                                            >
                                                {model.title}
                                            </Typography>
                                        </Box>
                                    ))}
                        </Box>
                    )
                ) : (
                    publicModels.length > 0 && (
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.3rem",
                                flexWrap: "wrap",
                                height: "132px",
                                overflowY: "scroll",
                                paddingX: "1rem",
                                alignItems: "center",
                                /* justifyContent: "center", */
                            }}
                        >
                            {models
                                .filter((model) => model.status !== "failed")
                                .map((model, index) => (
                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "78px",
                                            height: "78px",
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                            position: "relative",
                                            pointerEvents:
                                                model.status !== "succeeded"
                                                    ? "none"
                                                    : "auto",
                                            cursor:
                                                model.status !== "succeeded"
                                                    ? "not-allowed"
                                                    : "pointer",
                                        }}
                                        key={index}
                                        onClick={() => {
                                            handleModelOptionClick(model);
                                            //setSelectedPresets([]);
                                        }}
                                    >
                                        {model.status !== "succeeded" && (
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    top: "0",
                                                    zIndex: "91",
                                                    background:
                                                        "rgba(0, 0, 0, 0.5)",
                                                    backdropFilter: "blur(3px)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "white",
                                                }}
                                            >
                                                <CircularProgress
                                                    size={30}
                                                    sx={{
                                                        color: "white",
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {model.training_id ===
                                            activeModel.training_id && (
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    bottom: "0",
                                                    top: "0",
                                                    zIndex: "91",
                                                    background:
                                                        "rgba(0, 0, 0, 0.5)",
                                                    backdropFilter: "blur(3px)",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "white",
                                                }}
                                            >
                                                <HiOutlineCheck size={30} />
                                            </Box>
                                        )}

                                        <img
                                            src={model.selfie}
                                            style={{
                                                position: "absolute",
                                                left: "0",
                                                right: "0",
                                                top: "0",
                                                bottom: "0",
                                                width: "78px",
                                                height: "78px",
                                                zIndex: "80",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                background:
                                                    "rgba(0, 0, 0, 0.5)",
                                                color: "white",
                                                fontSize: "13px",
                                                textAlign: "center",
                                                position: "absolute",
                                                bottom: "0",
                                                left: "0",
                                                right: "0",
                                                zIndex: "90",
                                            }}
                                        >
                                            {model.title}
                                        </Typography>
                                    </Box>
                                ))}
                            {accountInfo?.avatar_subscription?.status ===
                                "active" &&
                                models
                                    .filter(
                                        (model) => model.status !== "failed"
                                    )
                                    .map((model, index) => (
                                        <Box
                                            sx={{
                                                position: "relative",
                                                width: "78px",
                                                height: "78px",
                                                borderRadius: "10px",
                                                overflow: "hidden",
                                                position: "relative",
                                                pointerEvents:
                                                    model.status !== "succeeded"
                                                        ? "none"
                                                        : "auto",
                                                cursor:
                                                    model.status !== "succeeded"
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            key={index}
                                            onClick={() => {
                                                handleModelOptionClick(model);
                                                //setSelectedPresets([]);
                                            }}
                                        >
                                            {model.status !== "succeeded" && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        top: "0",
                                                        zIndex: "91",
                                                        background:
                                                            "rgba(0, 0, 0, 0.5)",
                                                        backdropFilter:
                                                            "blur(3px)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <CircularProgress
                                                        size={30}
                                                        sx={{
                                                            color: "white",
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {model.training_id ===
                                                activeModel.training_id && (
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        top: "0",
                                                        zIndex: "91",
                                                        background:
                                                            "rgba(0, 0, 0, 0.5)",
                                                        backdropFilter:
                                                            "blur(3px)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        color: "white",
                                                    }}
                                                >
                                                    <HiOutlineCheck size={30} />
                                                </Box>
                                            )}

                                            <img
                                                src={model.selfie}
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    right: "0",
                                                    top: "0",
                                                    bottom: "0",
                                                    width: "78px",
                                                    height: "78px",
                                                    zIndex: "80",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    background:
                                                        "rgba(0, 0, 0, 0.5)",
                                                    color: "white",
                                                    fontSize: "13px",
                                                    textAlign: "center",
                                                    position: "absolute",
                                                    bottom: "0",
                                                    left: "0",
                                                    right: "0",
                                                    zIndex: "90",
                                                }}
                                            >
                                                {model.title}
                                            </Typography>
                                        </Box>
                                    ))}
                        </Box>
                    )
                )}
            </Box>
        </Box>
    );
};

export default NewModelSidebarBox;
