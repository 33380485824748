import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //   states
    selectedImageInpainting: "",
    selectedImageMask: "",
    inpaintedImage: "",
    inpainting: false,
    generatedinppaintedImage: [],
    error: "",
    canvasWidth: window.innerWidth < 768 ? 300 : 512,
    canvasHeight: window.innerWidth < 768 ? 300 : 512,
};

export const ImageInpainting = createSlice({
    name: "ImageInpainting",
    initialState,
    reducers: {
        fileUploaded: (state, action) => {
            state.selectedImageInpainting =
                action.payload.selectedImageInpainting;
            state.inpaintedImage = "";
            state.canvasWidth = action.payload.canvasWidth;
            state.canvasHeight = action.payload.canvasHeight;
        },
        inpaintingStarted: (state, action) => {
            state.inpainting = true;
            state.error = "";
            state.inpaintedImage = "";
        },
        inpaintingSucceed: (state, action) => {
            state.inpaintedImage = action.payload.inpaintedImage;
        },
        inpaintingFaild: (state, action) => {
            state.inpainting = false;
            state.error = action.payload.error;
        },
        setSelectedImageInpainting: (state, action) => {
            state.selectedImageInpainting =
                action.payload.selectedImageInpainting;
        },
        setSelectedImageMask: (state, action) => {
            state.selectedImageMask = action.payload.selectedImageMask;
        },

        setGeneratedInppaintedImage: (state, action) => {
            // array pathabo
            state.generatedinppaintedImage =
                action.payload.generatedinppaintedImage;
        },
        setInpaintedImage: (state, action) => {
            state.inpaintedImage = action.payload.inpaintedImage;
        },
        setInpainting: (state, action) => {
            state.inpainting = action.payload.inpainting;
        },
    },
});

export const {
    setSelectedImageInpainting,
    setSelectedImageMask,
    setInpaintedImage,
    setGeneratedInppaintedImage,
    fileUploaded,
    inpaintingStarted,
    inpaintingSucceed,
    inpaintingFaild,
    setInpainting, //   generationStart,
    //   generationFailed,
    //   lexicaImageSelected,
    //   generationSucceed,
    //   tabChnage,
    //   promptSearchedSucceed,
    //   urlSearchedSucceed,
} = ImageInpainting.actions;
export default ImageInpainting.reducer;
