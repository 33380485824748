import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, Slide } from "@mui/material";
import { HiCheck } from "react-icons/hi";
import ToastNotification from "../../../atoms/ToastNotification";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../firebaseInit";
import { useAPI } from "../../../../StateProvider";
import NeedToBuyAvatarsPlan from "../../../model/NeedToBuyAvatarsPlan";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PresetPackageCard = ({
    item,
    index,
    setSelectedPresets,
    selectedPresets,
}) => {
    const theme = useTheme();
    const [user, loading] = useAuthState(auth);
    const { data } = useAPI();

    const [userProhibited, setUserProhibited] = useState(false);
    const [isPresetSelected, setIsPresetSelected] = useState(false);
    const [
        showCannotSelectAnymoreAlbumsNotyf,
        setShowCannotSelectAnymoreAlbumsNotyf,
    ] = useState(false);
    const [mouseOverCard, setMouseOverCard] = useState(false);
    const [isFullView, setIsFullView] = useState(false);
    const [showLimitCrossedNotification, setShowLimitCrossedNotification] =
        useState(false);

    /* const toggleSelect = () => {
        if (selectedPresets.length < 5) {
            setIsPresetSelected(!isPresetSelected);
            let newArr = [...selectedPresets];
            if (newArr.includes(item.id)) {
                newArr = newArr.filter((preset) => preset !== item.id);
                setIsFullView(false);
            } else {
                newArr.push(item.id);
                setIsFullView(true);
            }
            setSelectedPresets(newArr);
            console.log(newArr, "--newArr--");
        } else {
            let newArr = [...selectedPresets];
            if (newArr.includes(item.id)) {
                console.log("Yes");

                setIsPresetSelected(!isPresetSelected);
                newArr = newArr.filter((preset) => preset !== item.id);
                console.log(newArr);
                setIsFullView(false);
                setSelectedPresets(newArr);
            } else {
                setSelectedPresets(newArr);
                console.log(newArr, "--newArr--");
                console.log("cant add more than 5");
                setShowLimitCrossedNotification(true);
                setTimeout(() => {
                    setShowLimitCrossedNotification(false);
                }, 3200);
            }
        } 
        
    }; */

    const toggleSelect = () => {
        const maxSelectionsAllowed =
            data?.avatar_subscription?.status !== "active" ? 1 : 5;

        if (selectedPresets.length < maxSelectionsAllowed) {
            setIsPresetSelected(!isPresetSelected);
            let newArr = [...selectedPresets];
            if (newArr.includes(item.id)) {
                newArr = newArr.filter((preset) => preset !== item.id);
                setIsFullView(false);
            } else {
                newArr.push(item.id);
                setIsFullView(true);
            }
            setSelectedPresets(newArr);
            console.log(newArr, "--newArr--");
        } else {
            let newArr = [...selectedPresets];
            if (newArr.includes(item.id)) {
                console.log("Yes");

                setIsPresetSelected(!isPresetSelected);
                newArr = newArr.filter((preset) => preset !== item.id);
                console.log(newArr);
                setIsFullView(false);
                setSelectedPresets(newArr);
            } else {
                setSelectedPresets(newArr);
                console.log(newArr, "--newArr--");
                /* const limitCrossedMessage =
                    data.avatar_subscription.status !== "active"
                        ? "You cannot add more than 1 package without an active subscription."
                        : "cant add more than 5";
                console.log(limitCrossedMessage); */
                if (
                    data?.avatar_subscription?.status !== "active" ||
                    !data?.avatar_subscription ||
                    !data?.avatar_subscription?.status
                ) {
                    setUserProhibited(true);
                    console.log("not pro");
                    setShowCannotSelectAnymoreAlbumsNotyf(true);
                    setTimeout(() => {
                        setShowCannotSelectAnymoreAlbumsNotyf(false);
                    }, 3200);
                } else {
                    console.log("pro");

                    setUserProhibited(false);
                    setShowLimitCrossedNotification(true);
                    setTimeout(() => {
                        setShowLimitCrossedNotification(false);
                    }, 3200);
                }
                /* setShowLimitCrossedNotification(true);
                setTimeout(() => {
                    setShowLimitCrossedNotification(false);
                }, 3200); */
            }
        }
    };

    return (
        <Box
            sx={{
                position: "relative",
                width: "320px",
                height: "570px",
                /* height: "370px", */
                borderRadius: "18px",
                overflow: "hidden",
                "&:hover > div": {
                    // This targets the direct child div (Box) of the main Box on hover.
                    visibility: "visible",
                    opacity: "1",
                    transition: "all 0.11s ease-in",
                },
                cursor: "pointer",
                background: isPresetSelected
                    ? "#0F172A"
                    : theme.palette.mode === "light"
                    ? "#FBFCFF"
                    : "#1F2937",
                border:
                    theme.palette.mode === "light"
                        ? "2px solid #EAEFFF"
                        : "2px solid rgba(211, 211, 211, 0.12)",
                padding: "24px",
                "&:hover": {
                    // This targets the direct child div (Box) of the main Box on hover.
                    background: "#0F172A",
                    transition: "all 0.11s ease-in",
                },
            }}
            key={index}
            onMouseEnter={() => setMouseOverCard(true)}
            onMouseLeave={() => setMouseOverCard(false)}
        >
            {userProhibited && (
                <NeedToBuyAvatarsPlan
                    showCannotSelectAnymoreAlbumsNotyf={
                        showCannotSelectAnymoreAlbumsNotyf
                    }
                    /* showFreeCreditsFinishedNotyf={showFreeCreditsFinishedNotyf} */
                    lockWarning={userProhibited}
                    setLockWarning={setUserProhibited}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            )}
            {showLimitCrossedNotification && (
                <ToastNotification
                    message={
                        data?.avatar_subscription?.status !== "active"
                            ? "Multiple albums are a premium feature."
                            : "Only 5 albums can be selected."
                    }
                    /* message={`Only ${
                        data?.avatar_subscription?.status !== "active" ? 1 : 5
                    } presets can be selected`} */
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {/* {isFullView && (
                <Box
                    sx={{
                        background: "rgba(27, 15, 70, 0.6)",
                        backdropFilter: "blur(1px)",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        zIndex: 101,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            background: "#F8FAFC",
                            width: "860px",
                            height: "480px",
                            borderRadius: "10px",
                            padding: { xs: "1.6rem 1.8rem", md: "2rem 2.6rem" },
                            position: "relative",
                        }}
                    >
                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "12px",
                                right: "12px",
                            }}
                            onClick={() => setIsFullView(false)}
                        >
                            <HiOutlineX size={27} />
                        </IconButton>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1.6rem",
                                    fontWeight: "bold",
                                    marginBottom: "0.8rem",
                                    display: "block",
                                    textAlign: "left",
                                    width: "100%",
                                }}
                            >
                                {item.category}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "20px",
                                    flexWrap: "wrap",
                                    maxHeight: "350px",
                                    overflowY: "scroll",
                                    width: "100%",
                                    
                                }}
                            >
                                {item.data.map((item2, idx2) => (
                                    <Box key={idx2 + 1} sx={{ width: "30%" }}>
                                        <img
                                            src={item2.images[0]}
                                            style={{
                                                width: "100%",
                                                
                                                borderRadius: "8px",
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )} */}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    height: "100%",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        backgorund: "red",
                        width: "100%",
                    }}
                >
                    <img
                        src={item.cover}
                        style={{
                            width: "100%",
                            aspectRatio: "1 / 1.15",
                            zIndex: "80",
                            borderRadius: "14px",
                        }}
                    />
                    <Typography
                        sx={{
                            color: isPresetSelected
                                ? "white"
                                : mouseOverCard
                                ? "white"
                                : theme.palette.mode === "light"
                                ? "#1E293B"
                                : "white",
                            fontWeight: "600",
                            textAlign: "left",
                            marginTop: "17px",
                            marginBottom: "8px",
                            fontFamily: "Inter",
                        }}
                    >
                        {item.category}
                    </Typography>
                    <Typography
                        sx={{
                            color: isPresetSelected
                                ? "white"
                                : mouseOverCard
                                ? "white"
                                : theme.palette.mode === "light"
                                ? "#1E293B"
                                : "white",
                            fontWeight: "normal",
                            textAlign: "left",
                            marginTop: "0",
                            marginBottom: "8px",
                            fontFamily: "Inter",
                            fontSize: "0.9rem",
                            /* overflow: "hidden",
                            whiteSpace: "nowrap", */
                            /*  width: "100%",
                            textOverflow: "ellipsis", */
                        }}
                    >
                        {/* {item.styleDescription} */}
                        {item.styleDescription.length > 120
                            ? `${item.styleDescription.substring(0, 120)}...`
                            : item.styleDescription}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#0F172A",
                            background: "#FDE047",
                            padding: "0.4rem 0.7rem",
                            borderRadius: "1rem",
                            fontSize: "0.89rem",
                            fontWeight: "bold",
                        }}
                    >
                        {item?.data?.length} Photos
                    </Typography>
                    <Button
                        sx={{
                            background: isPresetSelected
                                ? "#6464FF"
                                : mouseOverCard
                                ? "#6464FF"
                                : theme.palette.mode === "light"
                                ? "#1E293B"
                                : "#6464FF",
                            color: "white",
                            fontWeight: "600",
                            /* margin: "0 auto", */
                            textTransform: "capitalize",
                            "&:hover": {
                                background: "#A855F7",
                            },
                            /* paddingX: "15px", */
                            fontFamily: "Inter",
                            width: "40%",
                        }}
                        onClick={toggleSelect}
                    >
                        {isPresetSelected ? (
                            <>
                                <HiCheck size={20} />
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}
                                >
                                    Selected
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                }}
                            >
                                Select
                            </Typography>
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PresetPackageCard;
