import React, { useState, useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { autoCompletePrompt } from "../../../firestore/cloudfunctionsHandler";
import { generateSDXLimage, faceSwapReplicate } from "../../../firebaseInit";
import { saveAs } from "file-saver";

import {
    doc,
    getDoc,
    getFirestore,
    setDoc,
    updateDoc,
    collection,
    query,
    where,
    getDocs,
    serverTimestamp,
    addDoc,
    onSnapshot,
} from "firebase/firestore";
import { firebaseApp } from "../../../firebaseInit";
import {
    Box,
    Button,
    Slider,
    IconButton,
    TextField,
    Typography,
    Slide,
    CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAPI } from "../../../StateProvider";
import {
    HiOutlineChevronDown,
    HiOutlineX,
    HiOutlineCloudDownload,
} from "react-icons/hi";
import ToastNotification from "../../atoms/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import NeedToBuyAvatarsPlan from "../../model/NeedToBuyAvatarsPlan";
import BuyAvatarsOfferPopup from "../../model/BuyAvatarsOfferPopup";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SidebarInputBox = ({
    promptVal,
    setPromptVal,
    activeModel,
    user,
    setCurrentJobDocId,
    isPresetsSelected,
    selectedPresets,
}) => {
    const { data } = useAPI();

    const navigate = useNavigate();
    const location = useLocation();

    const db = getFirestore(firebaseApp);

    const theme = useTheme();

    const [activeModelId, setActivemodelId] = useState(
        activeModel?.output?.version
    );

    const [freePhotosCreditsAddedToDb, setFreePhotosCreditsAddedToDb] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [presetsFromDb, setPresetsFromDb] = useState([]);

    const [accountInfo, setAccountInfo] = useState(null);

    const [generatedImage, setGeneratedImage] = useState(null);

    const [showAlbumsGenerationSuccess, setShowAlbumsGenerationSuccess] =
        useState(false);

    const [defaultApiErrorMessage, setDefaultApiErrorMessage] =
        useState("Please try again!");
    const [showApiError, setShowApiError] = useState(false);
    const [showValidationError, setShowValidationError] = useState(false);
    const [validationErrMsg, setValidationErrMsg] = useState("");

    const [autocompleteRunning, setAutocompleteRunning] = useState(false);
    const [category, setCategory] = useState("Concept Art");

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [userProhibited, setUserProhibited] = useState(false);
    const [showFreeCreditsFinishedNotyf, setShowFreeCreditsFinishedNotyf] =
        useState(false);
    const [userRanOutOfFreeCredits, setUserRanOutOfFreeCredits] =
        useState(false);

    const [negativePromptVal, setNegativePromptVal] = useState("");
    const [seedVal, setSeedVal] = useState(1);
    const [cfgVal, setCfgVal] = useState(0);
    const [stepCountVal, setStepCountVal] = useState("35");
    const [samplerVal, setSamplerVal] = useState("k_dpmpp_2m");
    //const [negativePromptVal, setNegativePromptVal] = useState("");
    const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);

    const seedMin = 1;
    const seedMax = 10000000;
    const [showSeedInputError, setShowSeedInputError] = useState(false);

    const handlePromptValChange = (event) => {
        setPromptVal(event.target.value); // Update the state with the new value
    };
    const handleNegativePromptValChange = (event) => {
        setNegativePromptVal(event.target.value); // Update the state with the new value
    };
    const handleSeedValChange = (event) => {
        const val = event.target.value;
        if (val >= seedMin && val <= seedMax) {
            setSeedVal(val);
            setShowSeedInputError(false);
        } else {
            setShowSeedInputError(true);
        }
        //setSeedVal(event.target.value);
    };

    const handleCfgValChange = (event, newValue) => {
        setCfgVal(newValue);
    };

    const handleStepCountValChange = (event) => {
        setStepCountVal(event.target.value);
    };

    const handleSamplerValChange = (event) => {
        setSamplerVal(event.target.value);
    };

    const toggleAdvancedOptionsBox = () => {
        setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen);
    };

    useEffect(() => {
        if (user?.email) {
            const q = query(collection(db, "dreamboothTemplatesV2"));

            const unsubscribe = onSnapshot(
                q,
                (snapshot) => {
                    const presetsData = [];
                    snapshot.forEach((doc) => {
                        const data = doc.data();
                        presetsData.push(data);
                    });
                    setPresetsFromDb(presetsData);
                    //setLoading(false);
                    console.log(presetsData, "--presetsData--");
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                    //setLoading(false);
                }
            );

            // Cleanup the listener on component unmount
            return () => unsubscribe();
        }
    }, [user?.email, db]);

    const backfillTimestamps = async () => {
        try {
            const tunedSingleImagesCol = collection(db, "tuned_single_images");

            // You might need to adjust this query to fit the ordering assumption you're making
            const q = query(
                tunedSingleImagesCol,
                where("email", "==", "satauskz@gmail.com")
            ); // ordered by document ID

            const querySnapshot = await getDocs(q);

            let currentDate = new Date("2023-10-01"); // starting date
            const dayInMilliseconds = 24 * 60 * 60 * 1000; // one day

            for (const docSnapshot of querySnapshot.docs) {
                // Check if the document already has a createdAt field
                if (!docSnapshot.data().createdAt) {
                    await updateDoc(
                        doc(db, "tuned_single_images", docSnapshot.id),
                        {
                            createdAt: currentDate,
                        }
                    );

                    // Move to the next day
                    currentDate = new Date(
                        currentDate.getTime() + dayInMilliseconds
                    );
                }
            }
            console.log("Timestamps backfilling completed.");
        } catch (err) {
            console.error("Error updating documents:", err);
        }
    };

    //backfillTimestamps();

    const addImageToDb = async (imgData) => {
        let imageDocRef;
        try {
            // setIsLoading(true);

            // setLoadingMsg("Sending Scenes over to AISEO ...");

            const dataToSubmit = {
                ...imgData,
                training_id: activeModel.training_id,
                model_title: activeModel.title,
                model_generic_name: activeModel.model_name,
                model_metrics: activeModel.metrics,
                model_output_version: activeModelId,
                email: user.email,
                createdAt: serverTimestamp(),
            };

            imageDocRef = await addDoc(
                collection(db, "tuned_single_images"),
                dataToSubmit
            );
            reduceAvatarsSingleCredit();
        } catch (err) {
            console.log("Firebase write error:" + err);
        }
        console.log(
            "Document Image written to tuned_single_images with ID: ",
            imageDocRef.id
        );
        return imageDocRef.id;
    };

    const handleApiErrorDisplay = () => {
        setShowApiError(true);
        setTimeout(() => {
            setShowApiError(false);
            setDefaultApiErrorMessage("Please try again!");
        }, 3200);
    };

    const handleUserCheck = (user, location, navigate) => {
        if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                if (location.pathname === "/ai-photoshoots") {
                    navigate("/login");
                    console.log("/login");
                }
            }, 1300);
            return false;
        }
        return true;
    };

    const getAlbumsImageLength = () => {
        let newAlbumsImageLengthCount = 0;
        selectedPresets.forEach((item2, idx2) => {
            presetsFromDb.forEach((item3, idx3) => {
                if (item2 === item3.id) {
                    newAlbumsImageLengthCount =
                        newAlbumsImageLengthCount + item3.data.length;
                }
            });
        });
        return newAlbumsImageLengthCount;
    };

    const addNecessaryPhotosCreditsForFreeUsers = async () => {
        const userDocRef = doc(db, "arts", user?.email);
        setFreePhotosCreditsAddedToDb(true);

        try {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const myAccData = docSnap.data();
                console.log(myAccData, "--myAccData--");
                setAccountInfo({ ...myAccData });

                let updateFields = {};

                // Check if avatar_subscription.status is not present or not "active"
                if (
                    !myAccData?.avatar_subscription?.status ||
                    myAccData?.avatar_subscription?.status !== "active"
                ) {
                    const albumsLength = getAlbumsImageLength();
                    if (!myAccData.hasOwnProperty("avatar_photos")) {
                        console.log("Setting avatars photos free credits");
                        updateFields.avatar_photos = albumsLength;
                    }

                    // If any of the properties were missing, update the document
                    if (Object.keys(updateFields).length) {
                        await setDoc(userDocRef, updateFields, { merge: true });
                    }
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const handleSubscriptionCheck = () => {
        let newCost = 0;
        let albumsImageLengthCount = getAlbumsImageLength();
        console.log(albumsImageLengthCount, "--albumsImageLengthCount--");
        console.log(data?.avatar_photos, "--data?.avatar_photos--");
        console.log(
            data?.avatar_photos < albumsImageLengthCount,
            "--data?.avatar_photos < albumsImageLengthCount--"
        );

        if (isPresetsSelected) {
            if (
                !data.avatar_subscription ||
                data?.avatar_subscription.type === "Free" ||
                data?.avatar_subscription.status !== "active"
            ) {
                if (
                    !data.avatar_photos ||
                    data.avatar_photos < albumsImageLengthCount ||
                    data.avatarsFreeAlbumsCredits < 1
                ) {
                    console.log("SUBSCRIPTION NOT DEFINED for photos");
                    setUserProhibited(true);
                    setShowFreeCreditsFinishedNotyf(true);
                    setTimeout(() => {
                        setShowFreeCreditsFinishedNotyf(false);
                    }, 3200);
                    return false;
                } else {
                    console.log("SUBSCRIPTION DEFINED for photos");
                    return true;
                }
            } else {
                if (
                    !data.avatar_photos ||
                    data.avatar_photos < albumsImageLengthCount
                ) {
                    console.log("SUBSCRIPTION NOT DEFINED for photos 2");
                    setUserProhibited(true);
                    return false;
                } else {
                    console.log("SUBSCRIPTION DEFINED for photos 2");
                    return true;
                }
            }
        } else {
            if (
                !data.avatar_subscription ||
                data?.avatar_subscription.type === "Free" ||
                data?.avatar_subscription.status !== "active" ||
                (data?.avatar_photos ?? null) === null ||
                data?.avatar_photos < 1
            ) {
                console.log("SUBSCRIPTION NOT DEFINED for 1");
                setUserProhibited(true);
                return false;
            }
        }

        return true;
    };

    const handleFreeAvatarsCreditsCheck = () => {
        if (data?.avatarsFreeCredits < 1) {
            console.log("Avatars free credits unavailable");
            setUserProhibited(true);

            setShowFreeCreditsFinishedNotyf(true);
            setTimeout(() => {
                setShowFreeCreditsFinishedNotyf(false);
            }, 3200);
            return false;
        } else {
            console.log("Credits left:" + data?.avatarsFreeCredits);
            return true;
        }
    };

    const generateSinglePhoto = async (apiInputData) => {
        const maxRetries = 5; // Maximum number of times to retry a function
        let retries = 0;

        while (retries < maxRetries) {
            try {
                console.log(`generateSDXLimage attempt: ${retries + 1}`);

                const responseOne = await generateSDXLimage(apiInputData);
                console.log("Function response:", responseOne);

                if (responseOne.data.status === "ok" && responseOne.data.data) {
                    console.log("loading - faceswap ");

                    let innerRetries = 0;
                    while (innerRetries < maxRetries) {
                        try {
                            console.log(
                                `faceSwapReplicate attempt: ${innerRetries + 1}`
                            );
                            const responseTwo = await faceSwapReplicate({
                                target_image: responseOne.data.data.image,
                                swap_image: activeModel.selfie,
                            });
                            console.log("Function response:", responseTwo);

                            setIsLoading(false);
                            setGeneratedImage(responseTwo.data.data.image);

                            await addImageToDb({
                                ...responseOne.data.data,
                                image: responseTwo.data.data.image,
                            });

                            console.log(
                                "Image successfully generated and saved."
                            );
                            return; // If everything was successful, return from the function to prevent further retries
                        } catch (err) {
                            console.error(
                                "Error calling faceSwapReplicate function:",
                                err
                            );
                            innerRetries++; // Only increment innerRetries if there was an error with faceSwapReplicate
                            if (innerRetries >= maxRetries) {
                                throw new Error(
                                    "Maximum retries reached for faceSwapReplicate"
                                );
                            }
                        }
                    }
                } else {
                    console.log(responseOne.data.status);
                    console.log("error - state");
                    console.log(
                        responseOne.data.status === "error" &&
                            responseOne.data.message.includes("NSFW content")
                    );
                    if (
                        responseOne.data.status === "error" &&
                        responseOne.data.message.includes("NSFW content")
                    ) {
                        console.log("NSFQ CONTENT ERROR");

                        setDefaultApiErrorMessage(
                            "API's safety filters triggered!. \n Please modify prompt and try again."
                        );
                        handleApiErrorDisplay();
                        break;
                    } else {
                        console.log("UNEXPECTED ERROR");
                    }

                    throw new Error(
                        "Unexpected response from generateSDXLimage"
                    );
                }
            } catch (err) {
                if (err.message.includes("faceSwapReplicate")) {
                    console.error(err.message);
                    break;
                } else if (err.message.includes("generateSDXLimage")) {
                    console.error(
                        "Error calling generateSDXLimage function:",
                        err
                    );
                }
                retries++;
            }
        }

        setIsLoading(false);
        console.error("Failed to generate image after several attempts.");
        handleApiErrorDisplay();
    };

    const reduceAvatarsSingleCredit = async () => {
        const userDocRef = doc(db, "arts", user.email);
        try {
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                // If avatarsFreeCredits exists and is greater than 0, decrement it by 1
                if (
                    data?.avatar_subscription &&
                    data?.avatar_subscription?.status === "active"
                ) {
                    if (
                        typeof userData.avatar_photos === "number" &&
                        userData.avatar_photos > 0
                    ) {
                        await setDoc(
                            userDocRef,
                            {
                                ...userData,
                                avatar_photos: userData.avatar_photos - 1,
                            },
                            { merge: true }
                        );
                    } else {
                        console.log(
                            "Cannot reduce avatars photos Credits; it's either not set or less than 1"
                        );
                    }
                } else {
                    if (
                        typeof userData.avatarsFreeCredits === "number" &&
                        userData.avatarsFreeCredits > 0
                    ) {
                        await setDoc(
                            userDocRef,
                            {
                                ...userData,
                                avatarsFreeCredits:
                                    userData.avatarsFreeCredits - 1,
                            },
                            { merge: true }
                        );
                    } else {
                        console.log(
                            "Cannot reduce avatarsFreeCredits; it's either not set or less than 1"
                        );
                    }
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const reduceAvatarsFreeModelCountCredit = async () => {
        const userDocRef = doc(db, "arts", user.email);
        try {
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                // If avatarsFreeCredits exists and is greater than 0, decrement it by 1

                await setDoc(
                    userDocRef,
                    {
                        ...userData,
                        avatarsFreeAlbumsCredits:
                            userData.avatarsFreeAlbumsCredits - 1,
                    },
                    { merge: true }
                ); // merge: true to make sure we only update fields in the document and not overwrite the entire document
                console.log("avatarsFreeAlbumsCredits reduced success");
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };
    const reduceAvatarsFreeCredit = async () => {
        const userDocRef = doc(db, "arts", user.email);
        try {
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();

                // If avatarsFreeCredits exists and is greater than 0, decrement it by 1
                if (
                    typeof userData.avatarsFreeCredits === "number" &&
                    userData.avatarsFreeCredits > 0
                ) {
                    await setDoc(
                        userDocRef,
                        {
                            ...userData,
                            avatarsFreeCredits: userData.avatarsFreeCredits - 1,
                        },
                        { merge: true }
                    ); // merge: true to make sure we only update fields in the document and not overwrite the entire document
                } else {
                    console.log(
                        "Cannot reduce avatarsFreeCredits; it's either not set or less than 1"
                    );
                }
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const generatePhoto = async () => {
        if (!handleUserCheck(user, location, navigate)) return;

        if (isPresetsSelected) {
            if (!handleSubscriptionCheck()) return;

            if (!activeModelId || selectedPresets.length < 1) {
                setShowValidationError(true);

                if (!activeModelId) {
                    setValidationErrMsg("Please select a model!");
                } else if (selectedPresets.length < 1) {
                    setValidationErrMsg("Please select a preset");
                }
                setTimeout(() => {
                    setShowValidationError(false);
                }, 3200);
                return;
            }

            await reduceAvatarsFreeModelCountCredit();

            let jobsRef;
            try {
                const dataToSubmit = {
                    status: "started",
                    training_id: activeModel.training_id,
                    type: "albums_gen_sdxl",
                    email: user.email,
                    uid: user.uid,
                    templates: [...selectedPresets],
                };

                jobsRef = await addDoc(collection(db, "jobs"), dataToSubmit);
            } catch (err) {
                console.log("Firebase write error:" + err);
            }
            console.log("Document Jobs written with ID: ", jobsRef.id);
            setCurrentJobDocId(jobsRef.id);

            setShowAlbumsGenerationSuccess(true);
            setTimeout(() => {
                setShowAlbumsGenerationSuccess(false);
            }, 3200);
            setFreePhotosCreditsAddedToDb(false);
        } else {
            //if(accountInfo?.avatar_subscription?.status === "active") {
            if (data?.avatar_subscription?.status === "active") {
                if (!handleSubscriptionCheck()) return;
            } else {
                if (!handleFreeAvatarsCreditsCheck()) return;
            }
            /* console.log("started");

            return; */
            if (!activeModelId || promptVal === "") {
                setShowValidationError(true);

                if (!activeModelId) {
                    setValidationErrMsg("Please select a model!");
                } else if (promptVal === "") {
                    setValidationErrMsg("Please type a prompt");
                }
                setTimeout(() => {
                    setShowValidationError(false);
                }, 3200);
                return;
            }

            console.log("loading");
            setIsLoading(true);

            const apiInputData = {
                model: activeModelId,
                prompt: promptVal + " TOK",
                /* seed: seedVal, */
                prompt_strength: cfgVal,
                negative_prompt: negativePromptVal,
            };
            console.log(apiInputData, "--apiInputData--");
            //return;

            generateSinglePhoto(apiInputData);
        }
    };

    const handleAutocomplete = () => {
        mixpanel.track("Auto Complete - ai avatars page,", {
            Description: "User used auto complete option to generate image.",
        });
        const description = promptVal;
        //const description = getDescription();
        if (!description) return;
        let style = category;
        // show to auto complete button spinner...
        setAutocompleteRunning(true);
        //document.querySelector(".autocompleteSpinner").style.display = "flex";

        // call the cloud funciton....
        autoCompletePrompt(description, style).then((res) => {
            // stop the auto complete spinner

            setAutocompleteRunning(false);

            // update the search term.
            console.log(res.trim(), "res2");
            setPromptVal(res.trim());
        });
    };

    useEffect(() => {
        setActivemodelId(activeModel?.output?.version);
    }, [activeModel]);

    const handleCloseGeneratedImage = () => {
        setGeneratedImage(null);
    };

    const handleDownloadClick = () => {
        saveAs(generatedImage, "download-image.png");
    };
    return (
        <Box
            sx={{
                marginTop: "1.8rem",
                width: "289px",
            }}
        >
            {userProhibited ? (
                <>
                    <NeedToBuyAvatarsPlan
                        lockWarning={userProhibited}
                        setLockWarning={setUserProhibited}
                        showFreeCreditsFinishedNotyf={
                            showFreeCreditsFinishedNotyf
                        }
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                </>
            ) : null}
            {!isLoggedIn && (
                <ToastNotification
                    message="Please login ..."
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}

            {generatedImage && (
                <Box
                    sx={{
                        background: "rgba(27, 15, 70, 0.6)",
                        backdropFilter: "blur(2px)",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        zIndex: 101,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "600px",
                            height: "auto",
                            borderRadius: "13px",
                            position: "relative",
                        }}
                    >
                        <IconButton
                            sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "white",
                                color: "#6366F1",
                                "&:hover": {
                                    background: "#6366F1",
                                    color: "white",
                                },
                            }}
                            onClick={handleCloseGeneratedImage}
                        >
                            <HiOutlineX size={24} />
                        </IconButton>
                        <IconButton
                            sx={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                background: "white",
                                color: "#6366F1",
                                "&:hover": {
                                    background: "#6366F1",
                                    color: "white",
                                },
                            }}
                            onClick={handleDownloadClick}
                        >
                            <HiOutlineCloudDownload size={24} />
                        </IconButton>
                        <img
                            src={generatedImage}
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        />
                    </Box>
                </Box>
            )}
            {showAlbumsGenerationSuccess && (
                <ToastNotification
                    message="Albums Generation in progress."
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showValidationError && (
                <ToastNotification
                    message={validationErrMsg}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showApiError && (
                <ToastNotification
                    message={defaultApiErrorMessage}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {!isPresetsSelected && (
                <>
                    <Box
                        sx={{
                            marginBottom: "1.8rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                }}
                            >
                                Prompt
                            </Typography>
                            <Button
                                sx={{
                                    background: "#6464FF",
                                    borderRadius: "20px",
                                    color: "white",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    paddingX: "11px",
                                    paddingY: "4px",
                                    textTransform: "capitalize",

                                    "&:hover": {
                                        background: "#5151D2",
                                    },
                                }}
                                onClick={handleAutocomplete}
                            >
                                {autocompleteRunning ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "white",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Autocompleting{" "}
                                        </Typography>
                                        <CircularProgress
                                            size={13}
                                            sx={{
                                                color: "white",
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    "Autocomplete"
                                )}
                            </Button>
                        </Box>
                        <TextField
                            placeholder="Describe your imagination here"
                            multiline // This prop makes the TextField behave like a textarea
                            rows={3} // Initial number of rows
                            value={promptVal}
                            onChange={handlePromptValChange}
                            sx={{
                                width: "100%",
                                marginTop: "10px",
                                "& > div": {
                                    borderRadius: "8px",
                                    borderColor: "#EAEFFF",
                                    padding: "0.8rem",
                                },
                            }}
                        />
                    </Box>

                    {/* negative prompt text input start */}
                    <Box
                        sx={{
                            marginBottom: "1.8rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                }}
                            >
                                Negative Prompt
                            </Typography>
                        </Box>
                        <TextField
                            placeholder="Optional! negative prompt that
                    describes what you don’t want like"
                            multiline // This prop makes the TextField behave like a textarea
                            rows={3} // Initial number of rows
                            value={negativePromptVal}
                            onChange={handleNegativePromptValChange}
                            sx={{
                                width: "100%",
                                marginTop: "10px",
                                "& > div": {
                                    borderRadius: "8px",
                                    borderColor: "#EAEFFF",
                                    padding: "0.8rem",
                                },
                            }}
                        />
                    </Box>

                    {/* advanced */}
                    <Box
                        sx={{
                            marginBottom: "1.8rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.4rem",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                            onClick={toggleAdvancedOptionsBox}
                        >
                            <Typography
                                sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                    userSelect: "none",
                                }}
                            >
                                Advanced Settings
                            </Typography>
                            <HiOutlineChevronDown size={16} />
                        </Box>
                        {isAdvancedOptionsOpen && (
                            <Box
                                sx={{
                                    marginTop: "24px",
                                }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Seed{" "}
                                        </Typography>
                                    </Box>
                                    <TextField
                                        type="number"
                                        placeholder="Just a number"
                                        value={seedVal}
                                        onChange={handleSeedValChange}
                                        inputProps={{
                                            min: 1,
                                            max: 100,
                                        }}
                                        sx={{
                                            width: "100%",
                                            marginTop: "10px",
                                            "& > div": {
                                                borderRadius: "8px",
                                                borderColor: "#EAEFFF",
                                                padding: "0rem",
                                            },
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            fontSize: "12px",
                                            color: "#475569",
                                            marginTop: "8px",
                                        }}
                                    >
                                        Use seed to get similar photo
                                    </Typography>
                                    {showSeedInputError && (
                                        <Typography
                                            mt={1}
                                            color="red"
                                            sx={{
                                                fontSize: "13px",
                                                /* opacity: showSeedInputError ? "1" : "0", */
                                            }}
                                        >
                                            Seed should be between {seedMin} to{" "}
                                            {seedMax}.
                                        </Typography>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        marginTop: "24px",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Prompt Strength{" "}
                                        </Typography>
                                    </Box>
                                    <Slider
                                        value={cfgVal}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                        onChange={handleCfgValChange}
                                        valueLabelDisplay="auto"
                                        /* onChange={handleDurationChange} */
                                    />

                                    <Typography
                                        style={{
                                            fontSize: "12px",
                                            color: "#475569",
                                            marginTop: "2px",
                                        }}
                                    >
                                        Higher prompt strength (aka guidance
                                        scale) will take photos closer to your
                                        prompt.
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </>
            )}

            <Box
                sx={{
                    marginTop: "2rem",
                }}
            >
                <Button
                    sx={{
                        textTransform: "capitalize",
                        color: "white",
                        diplay: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background:
                            theme.palette.mode === "light"
                                ? "#1C1917"
                                : "#6464FF",
                        width: "100%",
                        fontWeight: "bold",

                        "&:hover": {
                            background:
                                theme.palette.mode === "light"
                                    ? "#6464FF"
                                    : "#4848C2",
                        },
                        paddingY: "1rem",
                        borderRadius: "0.7rem",
                        "&:disabled": {
                            background: "#1F2937",
                            color: "#64748B",
                            cursor: "no-drop !important",
                            cursor: "not-allowed",
                            pointerEvents: "all !important",
                        },
                    }}
                    onClick={() => generatePhoto()}
                    disabled={
                        isPresetsSelected
                            ? selectedPresets.length < 1
                                ? true
                                : false
                            : promptVal === ""
                            ? true
                            : false
                    }
                >
                    {isLoading
                        ? "Generating"
                        : isPresetsSelected
                        ? "Generate Albums"
                        : "Generate Photo"}
                    {isLoading && (
                        <CircularProgress
                            size={30}
                            sx={{ color: "white", marginLeft: "1.4rem" }}
                        />
                    )}
                </Button>
            </Box>
        </Box>
    );
};

export default SidebarInputBox;
