import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    Modal,
    MenuItem,
} from "@mui/material";

import { HiOutlinePhotograph, HiOutlinePlay, HiOutlineX } from "react-icons/hi";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ExtraHelperBox = ({ productData }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [singleExample, setSingleExample] = useState(
        productData.singleExample.imgSrc
    );

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "2rem 0 2rem 0",
            }}
            style={{ gap: "0.7rem" }}
        >
            {productData.porductTitle !== "Erase & Replace" && (
                <Button
                    sx={{
                        py: "0rem",
                        px: "0.7rem",
                        width: "7rem",
                        height: "2.5rem",
                        borderRadius: "0.7rem",
                        backgroundColor: "#B6EEFF",
                        color: "#281142",
                        border: "2px solid #90B2CC",

                        fontSize: "0.9rem",
                        transition: "none",

                        "&:hover": {
                            backgroundColor: "#C7F2FF",
                            color: "#281142",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        /* pointerEvents: isLoading ? "none" : "", */
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={handleModalOpen}
                >
                    <Typography
                        sx={{ fontSize: "0.857rem", fontWeight: "bold" }}
                    >
                        Example
                    </Typography>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "4px",
                        }}
                    >
                        <HiOutlinePhotograph size={20} />
                    </span>
                </Button>
            )}

            {productData.productVideoTutorial !== "" && (
                <Button
                    sx={{
                        py: "0rem",
                        px: "0.7rem",
                        width: "7rem",
                        height: "2.5rem",
                        borderRadius: "0.7rem",
                        backgroundColor: "#B6EEFF",
                        color: "#281142",
                        border: "2px solid #90B2CC",

                        fontSize: "0.9rem",
                        transition: "none",

                        "&:hover": {
                            backgroundColor: "#C7F2FF",
                            color: "#281142",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        /* pointerEvents: isLoading ? "none" : "", */
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={() => {
                        //window.location.href = productData.productVideoTutorial;
                        window.open(productData.productVideoTutorial, "_blank");
                        //navigate(productData.productVideoTutorial);
                    }}
                >
                    <Typography
                        sx={{ fontSize: "0.857rem", fontWeight: "bold" }}
                    >
                        Tutorial
                    </Typography>
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "4px",
                        }}
                    >
                        <HiOutlinePlay size={20} />
                    </span>
                </Button>
            )}

            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: 24,
                        background: "white",
                        /* background:
                            theme.palette.mode === "light"
                                ? "white"
                                : "#2C2929", */
                        p: 0,
                        width: {
                            xs: "80vw",
                            md: productData.multipleExamples.isActive
                                ? "80vw"
                                : "500px",
                        },
                        borderRadius: "0.7rem",
                        maxHeight: "80vh",
                        overflowY: "scroll",
                        /* overflowX: "scroll", */
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            position: "sticky",
                            top: "0",
                            background:
                                theme.palette.mode === "light"
                                    ? "white"
                                    : "black",
                            padding: "1rem",
                            width: "100%",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color:
                                    theme.palette.mode === "light"
                                        ? "#281142"
                                        : "white",
                                fontWeight: "bold",
                                fontSize: "1.1rem",
                            }}
                        >
                            Example
                        </Typography>
                        <IconButton
                            onClick={handleModalClose}
                            sx={{
                                background: "#979697",
                                color: "white",

                                "&:hover": {
                                    background: "#6363FF",
                                },
                            }}
                        >
                            <HiOutlineX />
                        </IconButton>
                    </Box>
                    {productData.singleExample.isActive && (
                        <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                            <img
                                src={singleExample}
                                alt="adproductImage"
                                stye={{ width: "100%", height: "100%" }}
                            />
                        </Box>
                    )}
                    {productData.multipleExamples.isActive &&
                        productData.multipleExamples.images.map(
                            (item, index) => (
                                <Box
                                    sx={{ width: { xs: "800px", md: "100%" } }}
                                    key={index}
                                >
                                    <img
                                        src={item}
                                        alt="adproductImage"
                                        stye={{ width: "100%", height: "100%" }}
                                    />
                                </Box>
                            )
                        )}
                </Box>
            </Modal>
        </Box>
    );
};

export default ExtraHelperBox;
