import React, { useState, useRef, useEffect } from "react";
import arrSvg from "./arrSvg.svg";

import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Slider,
    CircularProgress,
    Slide,
    Modal,
    Divider,
    Grid,
    Badge,
    Chip,
    Paper,
} from "@mui/material";
import {
    HiChevronLeft,
    HiChevronRight,
    HiOutlineExternalLink,
    HiOutlineClock,
    HiOutlineArrowSmLeft,
    HiOutlineChip,
    HiOutlineInformationCircle,
    HiOutlineFolder,
    HiOutlineX,
    HiChip,
    HiOutlineArrowSmRight,
    HiOutlinePhotograph,
    HiOutlineVideoCamera,
    HiOutlineViewGrid,
} from "react-icons/hi";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ToolItemBox = ({ item }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleNavigation = (link) => {
        navigate(link);
    };

    return (
        <Grid item xs={12} sm={4}>
            <Box
                sx={{
                    textAlign: "center",
                    background:
                        theme.palette.mode === "light" ? "white" : "#2C2929",
                    /* background: "#EEEEEE", */
                    borderRadius: "1rem",
                    height: { xs: "310px", md: "320px" },
                    boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                    transition: "all 0.1s ease-in",
                    cursor: "pointer",

                    "&:hover": {
                        boxShadow:
                            theme.palette.mode === "light"
                                ? "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;"
                                : "none",
                        transition: "all 0.1s ease-in-out",
                        transform: "scale(1.04)",
                    },

                    "&:hover": {
                        // other hover styles...

                        "& .nested-box": {
                            width: "100%",
                            height: "100%",
                            transform: "scale(1.2)",
                            transition: "all 0.2s ease-in-out",
                        },
                        "& .nestedBtn": {
                            display: "block",
                            transition: "all 0.3s ease-in-out",
                        },
                    },
                    position: "relative",
                    overflow: "hidden",
                }}
                onClick={() => handleNavigation(item.link)}
            >
                {item.new && (
                    <Box sx={{ background: "" }}>
                        <Typography>New</Typography>
                    </Box>
                )}

                {item.title === "Video Generator" ? (
                    <Box
                        className="nested-box"
                        sx={{
                            width: "100%",
                            height: "60%",
                            backgroundImage: `url("${item.imageSrc}")`,

                            backgroundRepeat: "no-repeat",
                            backgroundPosition: `${item.bgPosition}`,
                            borderRadius: "1rem",
                            position: "relative",
                        }}
                    >
                        <video
                            src="https://replicate.delivery/mgxm/08e3a2d5-4594-4934-a34a-6e249744ee52/out.mp4"
                            /* src="https://storage.googleapis.com/code-translate-c9640.appspot.com/animations/video_301b3720-286e-4859-baef-5d03de0f61c2.mp4" */
                            preload="auto"
                            autoPlay
                            /* controls */
                            loop
                            style={{
                                width:
                                    window.innerWidth < 768 ? "100%" : "300px",
                                borderRadius: "0.34rem",
                                borderRadius: "inherit",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                            muted
                        ></video>
                    </Box>
                ) : (
                    <Box
                        className="nested-box"
                        sx={{
                            width: "100%",
                            height: "60%",
                            backgroundImage: `url("${item.imageSrc}")`,
                            /* backgroundImage: `url("https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/7ebf9f51-6689-4e3b-9739-e66397c7b272")`, */
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: `${item.bgPosition}`,
                            /* backgroundPosition: "top", */
                            borderRadius: "1rem",
                            position: "relative",
                        }}
                    ></Box>
                )}

                {/* <img
                    className="nested-box"
                    src="https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/7ebf9f51-6689-4e3b-9739-e66397c7b272"
                    style={{
                        width: "100%",
                        height: "60%",
                        objectFit: "cover",
                    }}
                /> */}
                <Box
                    sx={{
                        padding: "1.2rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        style={{
                            gap: "0.5rem",
                        }}
                    >
                        {item.type === "image" ? (
                            <HiOutlinePhotograph size={25} />
                        ) : item.type === "video" ? (
                            <HiOutlineVideoCamera size={25} />
                        ) : (
                            <HiOutlineViewGrid size={25} />
                        )}
                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "1.2rem", md: "1.2rem" },
                                "@media (max-width:321px)": {
                                    fontSize: "0.97rem", // Update this with the styles you want
                                },
                            }}
                        >
                            {item.title}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: "0.5rem",
                        }}
                    >
                        <Typography
                            sx={{
                                padding: "0",
                                width: "fit-content",
                                textAlign: "left",
                                fontSize: { xs: "1rem", md: "1rem" },
                                "@media (max-width:321px)": {
                                    fontSize: "0.89rem", // Update this with the styles you want
                                },
                            }}
                        >
                            {item.desc}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    className="nestedBtn"
                    sx={{
                        position: "absolute",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "white",
                        animation: "colorchange 3s infinite",
                        "@keyframes colorchange": {
                            "0%": {
                                backgroundColor: "#6464FF",
                            },
                            "50%": {
                                backgroundColor: "#5050D6",
                            },
                            "100%": {
                                backgroundColor: "#6464FF",
                            },
                        },
                        textTransform: "capitalize",
                        padding: "0.3rem 1.3rem",
                        borderRadius: "3rem",
                        fontSize: "1rem",
                        display: "none",
                    }}
                    onClick={() => handleNavigation(item.link)}
                >
                    {item.btnText}
                </Button>
            </Box>
        </Grid>
    );
};

export default ToolItemBox;
