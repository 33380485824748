async function getImageSize(src) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            let h = this.height;
            let w = this.width;
            if (w > 512) {
                let ratio = 512 / w;
                console.log(ratio);
                let revisedHeight = parseInt(h * ratio);
                console.log(revisedHeight);
                resolve({
                    height: revisedHeight,
                    width: 512,
                });
            } else {
                resolve({
                    height: this.height,
                    width: this.width,
                });
            }
        };
        image.src = src;
    });
}

async function resizeImageFile(file) {
    let url = URL.createObjectURL(file);
    let optimizedSize = await getImageSize(url);
    return new Promise((resolve, reject) => {
        let w = optimizedSize.width;
        let h = optimizedSize.height;
        let reader = new FileReader();
        reader.onload = function (readerEvent) {
            let image = new Image();
            image.onload = function (imageEvent) {
                let canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                canvas.getContext("2d").drawImage(image, 0, 0, w, h);
                canvas.toBlob((blob) => {
                    let file = new File([blob], "image.png", {
                        type: "image/png",
                    });
                    resolve(file);
                }, "image/png");
                // base64 data url
                // let resizedImage = canvas.toDataURL("image/png");
                // resolve(resizedImage)
            };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}

export { resizeImageFile, getImageSize };
