import React from "react";
import "./styles.css";

export default function Loader() {
    const mode = localStorage.getItem("mode");
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                position: "relative",
                background: mode === "dark" ? "rgb(20 20 20)" : "white",
            }}>
            <div
                className="loader_icon"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40px",
                    height: "40px",
                }}>
                <img
                    src="/aiseo-symble.png"
                    alt="AISEO LOGO"
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </div>
    );
}
