import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import NavBar from "../NavBar/NavBar";
import { useLocation } from "react-router-dom";

const StyledBox = styled(Box)(({ theme }) => ({
    marginTop: "120px",
    minHeight: "100vh",
}));

const WrapperBox = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    scrollBehavior: "smooth",
}));

const StyledBoxWithoutMarginTop = styled(Box)(({ theme }) => ({
    marginTop: "0px",
    minHeight: "100vh",
}));

export default function Layout(props) {
    const location = useLocation();

    const styledBoxArr = [
        "/templates",
        "/video",
        "/audio",
        "/my-audios",
        "/my-videos",
        "/pricing",
        "/dashboard",
        "/rewards",
        "/customTemplate",
        "/ai-avatars",
        "/cancel-subscription",
        "/",
    ];

    return (
        <WrapperBox>
            <NavBar />
            {styledBoxArr.includes(location.pathname) ? (
                <StyledBoxWithoutMarginTop>
                    {props.children}
                </StyledBoxWithoutMarginTop>
            ) : (
                <StyledBox>{props.children}</StyledBox>
            )}

            {props.footer}
        </WrapperBox>
    );
}
