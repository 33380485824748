import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
    Tooltip,
    TextField,
    FormControl,
    Select,
    Badge,
    Chip,
    Slider,
} from "@mui/material";
import styles from "./workSpaceOutputBox.module.css";
/* import CanvasImage from "../../../CanvasImage"; */

import {
    HiOutlineClock,
    HiOutlineChip,
    HiOutlineX,
    HiOutlineQuestionMarkCircle,
    HiOutlineCloudDownload,
    HiOutlinePhotograph,
} from "react-icons/hi";
import { saveAs } from "file-saver";
/* import CanvasImageNew from "../../../CanvasImageNew"; */
import { useDispatch, useSelector } from "react-redux";

const WorkSpaceOutputBox = ({ productData, selectedImage }) => {
    /* const [isLoading, setIsLoading] = useState(false); */
    const [generatedImage, setGeneratedImage] = useState("");
    const maskedImageRef = useRef(null);
    const canvasRef = useRef(null);
    const [mask, setMask] = useState(null);
    const state = useSelector((state) => state.adProduct);
    const {
        inputImage,
        outputs,
        isLoading,
        imageNum,
        prompt,
        generating,
        errorMessage,
    } = state;

    const currImg =
        "https://images.pexels.com/photos/415980/pexels-photo-415980.jpeg?auto=compress&cs=tinysrgb&w=600";

    const downloadImage = () => {
        if (outputs.length > 0) {
            saveAs(outputs[0], "download-image.png");
        } else {
            saveAs(selectedImage, "download-image.png");
        }
    };

    return (
        <Box
            sx={{
                width: { xs: "100%", lg: "40%" },
                height: "fit-content",
                dislpay: "flex",
                flexDirection: "column",
                padding: "0.8rem",
                background: "#E8FAFF",
                borderRadius: "0.7rem",
                border: "2px solid #B6EEFF",
                position: "relative",
            }}
            style={{ gap: "1rem" }}
        >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                    sx={{
                        py: "0rem",
                        /* px: "0.7rem", */
                        height: "2.5rem",
                        borderRadius: "0.3rem",
                        backgroundColor: "#B6EEFF",
                        color: "#281142",
                        border: "2px solid #90B2CC",

                        fontSize: "0.9rem",
                        transition: "none",

                        "&:hover": {
                            backgroundColor: "#C7F2FF",
                            color: "#281142",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        pointerEvents: isLoading ? "none" : "",
                        position: "absolute",
                        right: 16,
                        top: 16,
                        zIndex: 99,
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={downloadImage}
                >
                    <span /* style={{ marginTop: "10px" }} */>
                        <HiOutlineCloudDownload size={20} />
                    </span>
                </IconButton>
            </Box>
            {!productData.hasCanvas ? (
                <>
                    <Box
                        sx={{
                            /* marginTop: "1rem", */
                            borderRadius: "5px",
                            height: "auto",
                            width: "100%",
                            position: "relative",
                            minHeight: { xs: "100px", md: "300px" },
                        }}
                    >
                        {isLoading ? (
                            <>
                                <div className={`${styles.loaderContainer}`}>
                                    <div className={`${styles.ldsRipple}`}>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </>
                        ) : outputs.length > 0 ? (
                            <>
                                <img
                                    src={outputs[0]}
                                    alt="Your Image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "inherit",
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={selectedImage}
                                    alt="Your Image"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "inherit",
                                    }}
                                />
                            </>
                        )}
                    </Box>
                </>
            ) : (
                <>
                    {/* <CanvasImageNew
                    maskedImageRef={maskedImageRef}
                    canvasRef={canvasRef}
                    setMask={setMask}
                    generatedImageSelected={selectedImage}
                /> */}
                    <div>asdasd</div>
                </>
            )}
            {/* <Box
                sx={{
                    marginTop: "1rem",
                    borderRadius: "5px",
                    height: "auto",
                    width: "100%",
                    position: "relative",
                    minHeight: { xs: "100px", md: "300px" },
                }}
                
            >
                {isLoading ? (
                    <>
                        <div className={`${styles.loaderContainer}`}>
                            <div className={`${styles.ldsRipple}`}>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </>
                ) : generatedImage !== "" ? (
                    <>
                        <img
                            src={generatedImage}
                            alt="Your Image"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "inherit",
                            }}
                        />
                    </>
                ) : (
                    <>
                        <img
                            src={selectedImage}
                            alt="Your Image"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "inherit",
                            }}
                        />
                    </>
                )}
            </Box> */}
        </Box>
    );
};

export default WorkSpaceOutputBox;
