import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React, { Suspense } from "react";
import { BrowserRouter, useLocation, useRoutes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseInit";
import { ColorModeContext, getDesignTokens } from "./theme";
import { SubscriptionContextProvider, useAPI } from "./StateProvider";
import { Provider, useDispatch } from "react-redux";
import { setConfig } from "./features/configs/configSilce";
import { setSavedImages } from "./features/image/savedImageSlice";
import { upscaleReset } from "./features/image/upscalingSlice";
import { fetchPersonalGeneratedImages } from "./firestore/dataHandler";

import AiAvatars from "./components/AiAvatars/AiAvatars";
import AdvertisementProduct from "./components/MainProducts/pages/advertisementProduct/AdvertisementProduct";
import ImageVariation from "./components/MainProducts/pages/imageVariation/ImageVariation";
import InstructProduct from "./components/MainProducts/pages/instructProduct/InstructProduct";

import { store } from "./store";

import Loader from "./components/Loader";
import ScrollToTop from "./components/utils/ScrollToTop";
// import SubscriptionUpgrade from "./components/SubscriptionUpgrage"
import mixpanel from "mixpanel-browser";
import EraseReplace from "./components/MainProducts/pages/eraseReplace/EraseReplace";
import ArtGenerator from "./components/MainProducts/ArtGenerator";
import AudioGenerator from "./components/AudioGenerator/AudioGenerator";
import MyAudios from "./components/AudioGenerator/MyAudios";
import HeadshotMain from "./components/Headshot/HeadshotMain";
import HeadshotsHistory from "./components/Headshot/HeadshotsHistory";
import AiAvatarsPricing from "./components/AiAvatars/AiAvatarsPricing";
import CancelSubscription from "./components/cancelSubscription/CancelSubscription";
import VideoGen from "./components/VideoGen/VideoGen.js";
import MyVideosV2 from "./components/MyVideos/MyVideosV2.js";

const PricingV2 = React.lazy(() =>
    import("./components/Pricing/PricingV2")
);

const ToolsPage = React.lazy(() => import("./components/ToolsPage/ToolsPage"));

const VideoGenerator = React.lazy(() =>
    import("./components/VideoGenerator/VideoGenerator")
);

const MyVideos = React.lazy(() => import("./components/MyVideos/MyVideos.js"));

const ExtensionArtGen = React.lazy(() =>
    import("./components/form/ExtensionArtGen")
);
const SubscriptionUpgrade = React.lazy(() =>
    import("./components/SubscriptionUpgrage")
);

/* const Dashboard = React.lazy(() => import("./components/Dashboard")); */
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const Login = React.lazy(() => import("./components/Login"));
const Signup = React.lazy(() => import("./components/Signup"));
const ForgetPassword = React.lazy(() => import("./components/ForgetPassword"));
const ProtectedRoute = React.lazy(() =>
    import("./components/protectedRoutes/ProtectedRoute")
);
const Subscription = React.lazy(() => import("./components/Subscription"));
const AddRewards = React.lazy(() => import("./components/AddRewards"));
const Faq = React.lazy(() => import("./components/Faq"));
const HandleAlert = React.lazy(() =>
    import("./components/globalComponets/HandleAlert")
);
const SerpImage = React.lazy(() => import("./components/SERP/SerpImage"));
const CreateCustomTemplate = React.lazy(() =>
    import("./components/customTemplate/CreateCustomTemplate")
);

const DreamBoothTemplates = React.lazy(() =>
    import("./components/dreambooth/DreamBoothTemplates")
);

/* mixpanel.init("7eeb86efb67d69a2fdbd222ed96436c4", { debug: true }); */
mixpanel.init("7fa2b4e7a8c2f64d1e25ee9dc95c4aee", { debug: true });

// lazy loaded components

const Appsumo = React.lazy(() => import("./components/Appsumo"));
const Cancel = React.lazy(() => import("./components/Cancel"));
const ImageEditor = React.lazy(() => import("./components/imageEditor"));
const PaymentStatus = React.lazy(() => import("./components/PaymentStatus"));
const Pricing = React.lazy(() => import("./components/Pricing"));
const PrivacyAndPolicy = React.lazy(() =>
    import("./components/PrivacyAndPolicy")
);
const Rewards = React.lazy(() => import("./components/Rewards"));
const TermsAndCondition = React.lazy(() =>
    import("./components/TermsAndCondition")
);
const Verify = React.lazy(() => import("./components/Verify"));

const BulkImageGeneration = React.lazy(() =>
    import("./components/dreambooth/BulkImageGeneration")
);
const AIProducts = React.lazy(() => import("./components/AIProducts"));
const ProductForm = React.lazy(() =>
    import("./components/AIProducts/ProductForm")
);

const ProductTemplates = React.lazy(() =>
    import("./components/AIProducts/ProductTemplates")
);
const PremadeProductImages = React.lazy(() =>
    import("./components/AIProducts/ProductTemplates/PremadeProductImages")
);

const ProductImagesGeneration = React.lazy(() =>
    import("./components/AIProducts/ProductTemplates/ProductImagesGeneration")
);
const Templates = React.lazy(() => import("./components/Templates"));
const Template = React.lazy(() => import("./components/Templates/Template"));
const Stocks = React.lazy(() => import("./components/Templates/Stock"));

const LandingPage = React.lazy(() => import("./components/LandingPage"));
const PremadeImages = React.lazy(() => import("./components/PremadeImages"));
const DreamboothImageGenerationTemplates = React.lazy(() =>
    import("./components/dreambooth/DreamboothImageGenerationTemplates")
);

const AppWrapper = () => {
    const [user, loading] = useAuthState(auth);
    const [authenticated, setAuthenticated] = React.useState(false);

    const { data } = useAPI();
    let credits = data?.credits;
    // const subscription = data?.subscription;
    console.log("DATA 1", data);

    const location = useLocation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!user) return;

        if (user.emailVerified) {
            setAuthenticated(true);
        }

        fetchPersonalGeneratedImages(user).then((res) => {
            // console.log("RESPONSE IS", res);
            // setGeneratedImage(res);
            let finalArray = [];
            res.forEach((each) => {
                let obj = {
                    ...each,
                    src: `https://storage.googleapis.com/code-translate-c9640.appspot.com/generations/${each.id}`,
                };
                finalArray.push(obj);
            });
            // console.log("RES BEFORE DISPATCH", finalArray);
            dispatch(setSavedImages(finalArray));
            // get unique folder names.
            let folders = [
                ...new Set(finalArray.map((item) => item.folderName)),
            ];
            // remove empty strings...
            folders = folders.filter((each) => each);
            // dispatch
            dispatch(
                setConfig({
                    name: "folders",
                    value: folders,
                })
            );
        });
    }, [user, dispatch]);

    React.useEffect(() => {
        // reset the upscaled image
        dispatch(upscaleReset());
    }, [location, dispatch]);

    const routes = useRoutes([
        {
            path: "/",
            element: (
                <Suspense fallback={<Loader />}>
                    <ArtGenerator
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    />
                </Suspense>
            ),
        },
        {
            path: "/ai-photoshoots",
            element: (
                <Suspense fallback={<Loader />}>
                    <AiAvatars
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    // subscription={subscription}
                    />
                </Suspense>
            ),
        },
        {
            path: "/ai-photoshoots-pricing",
            element: (
                <Suspense fallback={<Loader />}>
                    <AiAvatarsPricing
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    // subscription={subscription}
                    />
                </Suspense>
            ),
        },
        {
            path: "/premade-images",
            element: (
                <Suspense fallback={<Loader />}>
                    <PremadeImages />
                </Suspense>
            ),
        },
        {
            path: "/tools",
            element: (
                <Suspense fallback={<Loader />}>
                    {/* <StockimgForm user={user} /> */}
                    <ToolsPage user={user} />
                    {/* <Stockimg /> */}
                </Suspense>
            ),
        },
        {
            path: "/dashboard",
            element: (
                <Suspense fallback={<Loader />}>
                    <ProtectedRoute user={user} loading={loading}>
                        <Dashboard
                            user={user}
                            loading={loading}
                            authenticated={authenticated}
                        />
                    </ProtectedRoute>
                </Suspense>
            ),
        },
        {
            path: "/upgrade-subscription",
            element: (
                <Suspense fallback={<Loader />}>
                    <SubscriptionUpgrade user={user} loading={loading} />
                </Suspense>
            ),
        },

        {
            path: "/image/:id",
            element: (
                <Suspense fallback={<Loader />}>
                    <ImageEditor />
                </Suspense>
            ),
        },
        {
            path: "/templates",
            element: (
                <Suspense fallback={<Loader />}>
                    <Templates user={user} />
                </Suspense>
            ),
        },
        {
            path: "/templates/:id",
            element: (
                <Suspense fallback={<Loader />}>
                    <Template />
                </Suspense>
            ),
        },
        {
            path: "/stocks/:id",
            element: (
                <Suspense fallback={<Loader />}>
                    <Stocks />
                </Suspense>
            ),
        },
        {
            path: "/imageGeneration/templates",
            element: (
                <Suspense fallback={<Loader />}>
                    <DreamBoothTemplates user={user} />
                </Suspense>
            ),
        },
        {
            path: "/imageGeneration/templates/:id",
            element: (
                <Suspense fallback={<Loader />}>
                    <DreamBoothTemplates />
                </Suspense>
            ),
        },
        {
            path: "/login",
            element: (
                <Suspense fallback={<Loader />}>
                    <Login user={user} />
                </Suspense>
            ),
        },
        {
            path: "/signup",
            element: (
                <Suspense fallback={<Loader />}>
                    <Signup user={user} />
                </Suspense>
            ),
        },
        {
            path: "/password",
            element: (
                <Suspense fallback={<Loader />}>
                    <ForgetPassword user={user} />
                </Suspense>
            ),
        },
        {
            path: "/privacy-and-policy",
            element: (
                <Suspense fallback={<Loader />}>
                    <PrivacyAndPolicy user={user} />
                </Suspense>
            ),
        },
        {
            path: "/terms",
            element: (
                <Suspense fallback={<Loader />}>
                    <TermsAndCondition user={user} />
                </Suspense>
            ),
        },
        {
            path: "/verify",
            element: (
                <Suspense fallback={<Loader />}>
                    <Verify user={user} />
                </Suspense>
            ),
        },
        /* {
            path: "/pricing_old",
            element: (
                <Suspense fallback={<Loader />}>
                    <PricingLatest user={user} />
                </Suspense>
            ),
        }, */
        {
            path: "/pricing",
            element: (
                <Suspense fallback={<Loader />}>
                    <PricingV2 user={user} />
                </Suspense>
            ),
        },
        {
            path: "/code",
            element: (
                <Suspense fallback={<Loader />}>
                    <Appsumo user={user} />
                </Suspense>
            ),
        },
        {
            path: "/rewards",
            element: (
                <Suspense fallback={<Loader />}>
                    <Rewards user={user} />
                </Suspense>
            ),
        },
        {
            path: "/addrewards",
            element: (
                <Suspense fallback={<Loader />}>
                    <AddRewards user={user} />
                </Suspense>
            ),
        },
        {
            path: "/payment",
            element: (
                <Suspense fallback={<Loader />}>
                    <PaymentStatus user={user} />
                </Suspense>
            ),
        },
        {
            path: "/cancel",
            element: (
                <Suspense fallback={<Loader />}>
                    <Cancel user={user} />
                </Suspense>
            ),
        },
        {
            path: "/subscription",
            element: (
                <Suspense fallback={<Loader />}>
                    <Subscription user={user} />
                </Suspense>
            ),
        },
        {
            path: "/cancel-subscription",
            element: (
                <Suspense fallback={<Loader />}>
                    <CancelSubscription user={user} />
                </Suspense>
            ),
        },
        {
            path: "/faq",
            element: (
                <Suspense fallback={<Loader />}>
                    <Faq />
                </Suspense>
            ),
        },
        {
            path: "/serpimage",
            element: (
                <Suspense fallback={<Loader />}>
                    <SerpImage />
                </Suspense>
            ),
        },
        {
            path: "/customTemplate",
            element: (
                <Suspense fallback={<Loader />}>
                    <CreateCustomTemplate user={user} credits={credits} />
                </Suspense>
            ),
        },
        {
            path: "*",
            element: <LandingPage />,
        },

        //ai- products
        {
            path: "/ai-products",
            element: (
                <Suspense fallback={<Loader />}>
                    <AIProducts />
                </Suspense>
            ),
        },
        {
            path: "/ai-products/generate-products",
            element: (
                <Suspense fallback={<Loader />}>
                    <ProductForm />
                </Suspense>
            ),
        },
        {
            path: "/ai-products/templates",
            element: (
                <Suspense fallback={<Loader />}>
                    <ProductTemplates />
                </Suspense>
            ),
        },
        {
            path: "/ai-products/premade-products-images",
            element: (
                <Suspense fallback={<Loader />}>
                    <PremadeProductImages />
                </Suspense>
            ),
        },
        {
            path: "/ai-products/products-images-generation",
            element: (
                <Suspense fallback={<Loader />}>
                    <ProductImagesGeneration user={user} />
                </Suspense>
            ),
        },
        {
            path: "/extension",
            element: (
                <Suspense fallback={<Loader />}>
                    <ExtensionArtGen user={user} />
                </Suspense>
            ),
        },
        {
            path: "/video",
            element: (
                <Suspense fallback={<Loader />}>
                    <VideoGen user={user} />
                    {/* <VideoGenerator user={user} /> */}
                </Suspense>
            ),
        },
        {
            path: "/audio",
            element: (
                <Suspense fallback={<Loader />}>
                    <AudioGenerator user={user} />
                </Suspense>
            ),
        },
        {
            path: "/my-audios",
            element: (
                <Suspense fallback={<Loader />}>
                    <MyAudios user={user} />
                </Suspense>
            ),
        },
        {
            path: "/my-videos",
            element: (
                <Suspense fallback={<Loader />}>
                    <MyVideosV2 user={user} />
                    {/* <MyVideos user={user} /> */}
                </Suspense>
            ),
        },
        {
            path: "/products/art-generator",
            element: (
                <Suspense fallback={<Loader />}>
                    <ArtGenerator
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    />
                </Suspense>
            ),
        },
        {
            path: "/products/headshot",
            element: (
                <Suspense fallback={<Loader />}>
                    <HeadshotMain
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    />
                </Suspense>
            ),
        },
        {
            path: "/my-headshots",
            element: (
                <Suspense fallback={<Loader />}>
                    <HeadshotsHistory
                        user={user}
                        loading={loading}
                        authenticated={authenticated}
                        credits={credits}
                    />
                </Suspense>
            ),
        },
        {
            path: "/products/advertisement-product",
            element: (
                <Suspense fallback={<Loader />}>
                    <AdvertisementProduct user={user} />
                </Suspense>
            ),
        },
        {
            path: "/products/image-variation",
            element: (
                <Suspense fallback={<Loader />}>
                    <ImageVariation user={user} credits={credits} />
                </Suspense>
            ),
        },
        {
            path: "/products/instruct",
            element: (
                <Suspense fallback={<Loader />}>
                    <InstructProduct user={user} credits={credits} />
                </Suspense>
            ),
        },
        {
            path: "/products/erase-replace",
            element: (
                <Suspense fallback={<Loader />}>
                    <EraseReplace user={user} credits={credits} />
                </Suspense>
            ),
        },
    ]);

    return routes;
};

export default function App() {
    const [mode, setMode] = React.useState("light");

    React.useEffect(() => {
        if (localStorage.getItem("mode")) {
            setMode(localStorage.getItem("mode"));
        } else {
            localStorage.setItem("mode", "light");
        }
    }, []);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                let prev = localStorage.getItem("mode");
                setMode((prevMode) =>
                    prevMode === "light" ? "dark" : "light"
                );
                localStorage.setItem(
                    "mode",
                    prev === "light" ? "dark" : "light"
                );
            },
        }),
        []
    );

    const theme = React.useMemo(
        () => createTheme(getDesignTokens(mode)),
        [mode]
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <SubscriptionContextProvider>
                    <Provider store={store}>
                        <BrowserRouter>
                            <AppWrapper />
                            <ScrollToTop />
                            <HandleAlert />
                        </BrowserRouter>
                    </Provider>
                </SubscriptionContextProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
