import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//Props to pass
// optionItems is an array of the options we want for the component
// optionSelected and setOptionSelected are destructured from a useState of the parent component and passed as props
// styleConfigProp is a configuration object that we can pass from parent Component for the styling
// a defaultStyleConfig given Below that needs to be included from parent Component

//Code for parent Component
// const optionItems = ["Option 1", "Option 2"];
// const [optionSelected, setOptionSelected] = useState(optionItems[0]);
// <OptionSelected optionItems={optionItems} optionSelected={optionSelected} setOptionSelected={setOptionSelected} styleConfigProp={styleConfigProp} />

const OptionSelected = ({
    optionItems,
    optionSelected,
    setOptionSelected,
    styleConfigProp,
}) => {
    const theme = useTheme();
    const [styleConfig, setStyleConfig] = useState(
        styleConfigProp && Object.keys(styleConfigProp).length !== 0
            ? { ...styleConfigProp }
            : { ...defaultStyleConfig }
    );

    useEffect(() => {
        if (styleConfigProp) {
            if (Object.keys(styleConfigProp)?.length !== 0) {
                setStyleConfig({ ...styleConfigProp });
            }
        }
    }, [styleConfigProp]);

    //Uncomment the next two lines if you want the component to maintain it's own state & also remove the props
    // const optionItems = ["Type Url", "Upload Image"];
    // const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const handleOptionSelect = (e) => {
        setOptionSelected(e.target.innerText);
    };

    return (
        <Box
            sx={{
                background:
                    theme.palette.mode === "light"
                        ? styleConfig.container.lightMode.bgColor
                        : styleConfig.container.darkMode.bgColor,
                width: "fit-content",
                margin: styleConfig?.centered ? "0 auto" : "",
                border: `2px solid ${theme.palette.mode === "light"
                    ? styleConfig.container.lightMode.borderColor
                    : styleConfig.container.darkMode.borderColor}`,
                borderRadius: "30px",
                display: "flex",
                padding: "0.3rem",
                boxShadow:
                    theme.palette.mode === "light"
                        ? styleConfig.container.lightMode.boxShadow
                        : styleConfig.container.darkMode.boxShadow,
            }}
        >
            {optionItems.map((item, index) => (
                <Box
                    key={index}
                    sx={{
                        background:
                            item === optionSelected
                                ? styleConfig.option.active.bgColor
                                : styleConfig.option.inactive.bgColor,
                        /*  background:
                            item === optionSelected ? "#14AAFF" : "transparent", */
                        padding: "0.5rem 1rem",
                        borderRadius: "inherit",
                        cursor: "pointer",
                        color: theme.palette.mode === "dark" ? "white" :
                            item === optionSelected
                                ? styleConfig.option.active.textColor
                                : styleConfig.option.inactive.textColor,
                        fontFamily: "Inter",

                        fontWeight: "600",
                    }}
                    onClick={handleOptionSelect}
                >
                    <Typography>{item}</Typography>
                </Box>
            ))}
        </Box>
    );
};

const defaultStyleConfig = {
    option: {
        active: {
            bgColor: "#D5F6FF",
            textColor: "#14AAFF",
        },
        inactive: {
            bgColor: "transparent",
            textColor: "#7A92A5",
        },
    },
    container: {
        lightMode: {
            bgColor: "white",
            borderColor: "#D0F4FF",
            boxShadow: "0px 4px 15px #E8FAFF",
        },
        darkMode: {
            bgColor: "transparent",
            borderColor: "#D0F4FF",
            boxShadow: "none",
        },
    },
};

export default OptionSelected;

//Notes on how to use
