/**
 * To ensure easily maintainable/readble code let's follow the following patterns
 * 1. Avoid using Promise constructor when possible. 
 *    Firestore CRUD operations return promise by default
 * 2. Avoid redundancy in code. Less code, less bug.
 * 3. Remove unnecessary comments
 * 4. Avoid writing giant function/ better split for very specific job
 */

import {
    doc,
    getDoc,
    getFirestore,
    setDoc,
    updateDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../firebaseInit";
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
const cancelSubscription = httpsCallable(functions, "cancelSubscription");
const getInvoices = httpsCallable(functions, "getInvoices");


const createAccount = async (user, username) => {
    console.log("create account");
    let email = user.email;

    const docRef = doc(db, "accounts", email);
    const docSnap = await getDoc(docRef);

    const artDocRef = doc(db, "arts", email);
    const artDocSnap = await getDoc(docRef);

    if (!artDocSnap.exists()) {
        await setDoc(artDocRef, {
            uid: user.uid,
            credits: 5,
            subscription : {
                type : "Free"
            }
        });
    }

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        const docSet = await setDoc(docRef, {
            name: username ? username : user.displayName,
            uid: user.uid,
            photo: user.photoURL,
            emailVerified: user.emailVerified,
        });

        if (docSet) {
            console.log("done", docRef.id);
        }
    }
};

const updateEmailVerification = async (email) => {
    const docRef = doc(db, "accounts", email);

    const data = {
        emailVerified: true,
    };

    updateDoc(docRef, data)
        .then((docRef) => {
            console.log(
                "A New Document Field has been added to an existing document"
            );
        })
        .catch((error) => {
            console.log(error);
        });
};

const callCancelSubscription = (email)=>{
    return new Promise(async(resolve ,reject)=>{
        await cancelSubscription({
            email : email
        }).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        })
    })
}

const getInvoice = (email)=>{
    return new Promise(async(resolve ,reject)=>{
        console.log("EMAIL ", email);
        await getInvoices({
            email : email
        }).then((res)=>{
            const finalArray = res.data.flat();
            console.log("RESULT IS", finalArray);
            let paidInvoices = finalArray.filter((each)=> each.status === 'paid')
            resolve(paidInvoices);

            // let finalArray = [];
            // res.data.forEach((each)=>{
            //     finalArray.push(each);
            // })
        }).catch((err)=>{
            reject(err);
        })
    })
}

export { createAccount, updateEmailVerification, callCancelSubscription, getInvoice };

