import {
    Box,
    Typography,
    Link,
    IconButton,
    Popover,
    MenuItem,
    Button,
} from "@mui/material";
import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import NavBarV2 from "../NavBar/NavBarV2";
import { styled, useTheme } from "@mui/material/styles";
import imgsrc from "../../imgs/aiseo-symble.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import { handleLogout } from "../../firestore/authHandlers";
import {
    HiOutlineMoon,
    HiOutlineUser,
    HiChevronDown,
    HiOutlineMenuAlt3,
    HiOutlineX,
    HiOutlineSun,
    HiOutlineSearch,
} from "react-icons/hi";

const StyledBox = styled(Box)(({ theme }) => ({
    marginTop: "120px",
    minHeight: "100vh",
}));

const WrapperBox = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    scrollBehavior: "smooth",
}));

const StyledBoxWithoutMarginTop = styled(Box)(({ theme }) => ({
    marginTop: "0px",
    minHeight: "100vh",
}));

const profileMenuItemsData = [
    {
        id: "pItem-1",
        title: "Dashboard",
        to: "/dashboard",
    },
    {
        id: "pItem-2",
        title: "Unlimited Credits",
        to: "/upgrade-subscription",
    },
    {
        title: "🔥 Free Credits",
        to: "/rewards",
        id: "pItem-3",
    },
    {
        title: "My Videos",
        to: "/my-videos",
        id: "pItem-4",
    },
    {
        title: "My Audios",
        to: "/my-audios",
        id: "pItem-5",
    },
];

export default function AiAvatarsLayout(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [user, loading] = useAuthState(auth);

    const [pSubMenuEl, setPSubMenuEl] = useState(null);

    const styledBoxArr = [
        "/templates",
        "/video",
        "/audio",
        "/my-audios",
        "/my-videos",
        "/pricing",
        "/dashboard",
        "/rewards",
        "/customTemplate",
        "/ai-avatars",
        "/ai-photoshoots",
        "/ai-avatars-pricing",
        "/ai-photoshoots-pricing",
        "/",
    ];

    const handleProfileMenuOpen = (event) => {
        setPSubMenuEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setPSubMenuEl(null);
    };

    const handleLogoutClick = () => {
        const value = localStorage.getItem("dontShowMultistepPopup");
        if (value) {
            localStorage.removeItem("dontShowMultistepPopup");
        }
        handleLogout();
    };

    const handleLoginNav = () => {
        navigate("../login");
    };

    return (
        <WrapperBox>
            <Box
                sx={{
                    background:
                        theme.palette.mode === "light"
                            ? "rgba(255, 255, 255, 1)"
                            : "rgba(18, 18, 18, 1)",

                    borderBottom:
                        theme.palette.mode === "light"
                            ? "1.4px solid #E5E5E5"
                            : "1.4px solid #5F6A79",
                    position: "sticky",
                    top: "0",
                    zIndex: 100,
                    backdropFilter: "blur(100px)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",

                        padding: { xs: "0.7rem 0.5rem", md: "0.8rem 4rem" },
                    }}
                >
                    <Link
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        underline="none"
                        href="/"
                    >
                        <img
                            src={imgsrc}
                            style={{
                                width:
                                    window.innerWidth < 768 ? "2.5rem" : "auto",
                                height:
                                    window.innerWidth < 768 ? "auto" : "1.8rem",
                                marginRight: "0.5rem",
                                cursor: "pointer",
                            }}
                            alt=""
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: "block",
                                fontWeight: "bold",
                                color:
                                    theme.palette.mode === "light"
                                        ? "#281142"
                                        : "white",
                            }}
                        >
                            AISEO Art
                        </Typography>
                    </Link>
                    <IconButton
                        sx={{
                            background: "white",
                            /* background: "#6DDAF8", */
                            color: "#281142",
                            border: "2px solid lightgrey",
                            width: "2.5rem",
                            height: "2.5rem",
                            "&:hover": {
                                background: "#6363FF",
                                color: "white",
                            },
                            display: {
                                xs: "block",
                                md: "block",
                            },
                        }}
                        onClick={handleProfileMenuOpen}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {user ? (
                                <img
                                    src={user.photoURL}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        scale: "1.7",
                                        borderRadius: "50%", // Applying the parent's border radius
                                    }}
                                    alt="User Profile"
                                />
                            ) : (
                                <HiOutlineUser />
                            )}
                        </Box>
                    </IconButton>
                    <Popover
                        open={Boolean(pSubMenuEl)}
                        anchorEl={pSubMenuEl}
                        onClose={handleProfileMenuClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            elevation: 1,
                            sx: {
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? "white"
                                        : "#3F3C3C",
                                display: "flex",
                                flexDirection: "column",
                                p: "0.7rem",
                                borderRadius: "0.7rem",
                                border: "1.5px solid #D6D2DA",
                            },
                        }}
                    >
                        {profileMenuItemsData.map(
                            (subProfileItem, subProfileIndex) => (
                                <MenuItem
                                    key={subProfileIndex}
                                    component={Link}
                                    href={subProfileItem.to}
                                    underline="none"
                                    onClick={handleProfileMenuClose}
                                    sx={{
                                        backgroundColor: "#E5E5FA",
                                        color: "#281142",
                                        margin: "0.5rem",
                                        borderRadius: "0.5rem",
                                        "&:hover": {
                                            backgroundColor: "#D3D3FF",
                                        },
                                    }}
                                >
                                    {subProfileItem.title}
                                </MenuItem>
                            )
                        )}
                        {user ? (
                            <MenuItem
                                component={Button}
                                underline="none"
                                onClick={handleLogoutClick}
                                sx={{
                                    backgroundColor: "#6363FF",
                                    color: "white",
                                    margin: "0.5rem",
                                    borderRadius: "0.5rem",
                                    "&:hover": {
                                        backgroundColor: "#5050D6",
                                    },
                                }}
                            >
                                Logout
                            </MenuItem>
                        ) : (
                            <MenuItem
                                component={Button}
                                underline="none"
                                onClick={handleLoginNav}
                                sx={{
                                    backgroundColor: "#6363FF",
                                    color: "white",
                                    margin: "0.5rem",
                                    borderRadius: "0.5rem",
                                    "&:hover": {
                                        backgroundColor: "#5050D6",
                                    },
                                }}
                            >
                                Login
                            </MenuItem>
                        )}
                    </Popover>
                </Box>
            </Box>

            {styledBoxArr.includes(location.pathname) ? (
                <StyledBoxWithoutMarginTop>
                    {props.children}
                </StyledBoxWithoutMarginTop>
            ) : (
                <StyledBox>{props.children}</StyledBox>
            )}

            {props.footer}
        </WrapperBox>
    );
}
