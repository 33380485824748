import React, { useState, useEffect, useMemo } from "react";
import LayoutV3 from "../layout/LayoutV3";
import FooterV3 from "../footer/FooterV3";
import {
    Typography,
    Box,
    Button,
    IconButton,
    Grid,
    CircularProgress,
    Paper
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineDownload,
    HiOutlineChevronLeft,
    HiOutlineChevronRight,
} from "react-icons/hi";
import { useAPI } from "../../StateProvider";
import {
    getFirestore,
    collection,
    query,
    where,
    getDocs,
} from "firebase/firestore";
import SingleImageCard from "./SingleImageCard";
import { saveAs } from "file-saver";
import OptionSelected from "../atoms/OptionSelected";
import AlbumsHistory from "./AlbumsHistory";


const HeadshotsHistory = ({ user }) => {
    const navigate = useNavigate();
    // const { data } = useAPI();
    const [isFullView, setIsFullView] = useState(false);
    const [imageForFullViewIndex, setImageForFullViewIndex] = useState(0);

    const [myHeadshotsData, setMyHeadshotsData] = useState(null);
    const [albumDocsSrcs, setAlbumDocsSrcs] = useState([]);
    const [imageSrcs, setImageSrcs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const optionItems = ["Headshots", "Albums"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);

    const handleDownloadClick = () => {
        saveAs(paginatedSrcs[imageForFullViewIndex], "headshot-download.png");
    };

    useEffect(() => {
        setLoading(true)
        const fetchDocs = async () => {
            console.log("loading");
            console.log(user?.email, "user.email");
            try {
                const db = getFirestore();
                const q = query(
                    collection(db, optionSelected === "Headshots" ? "headshots" : "headshotsAlbums"),
                    where("userEmail", "==", user?.email)
                );
                const querySnapshot = await getDocs(q);
                const documents = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                if (optionSelected !== "Headshots") {
                    setMyHeadshotsData(documents);

                }

                // Creating an array of image sources
                /* const srcs = documents.map((doc) => doc.generatedImages[0]); */
                const srcs = documents.reduce((acc, doc) => {
                    return acc.concat(doc.generatedImages);
                }, []);
                /* const srcs = documents.reduce((acc, doc) => {
                    return acc.concat(doc.generatedImages);
                }, []).reverse();  */
                setAlbumDocsSrcs(documents);
                setImageSrcs(srcs);
                setImageSrcs(srcs);
                console.log(documents, "--docs--");
            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        fetchDocs();
    }, [user, optionSelected]);

    const paginatedSrcs = useMemo(() => {
        const startIdx = (currentPage - 1) * itemsPerPage;
        const endIdx = startIdx + itemsPerPage;
        return imageSrcs.slice(startIdx, endIdx);
    }, [imageSrcs, currentPage, itemsPerPage]);

    const totalPages = Math.ceil(imageSrcs.length / itemsPerPage);

    const openFullView = () => {
        setIsFullView(true);
    };

    const changeFullViewImgIndex = (index) => {
        setImageForFullViewIndex(index);
    };

    const incrementFullViewImgIndex = () => {
        if (imageForFullViewIndex + 1 > paginatedSrcs.length - 1) {
            setImageForFullViewIndex(imageForFullViewIndex);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex + 1);
        }
    };
    const decrementFullViewImgIndex = () => {
        setImageForFullViewIndex(imageForFullViewIndex + 1);
        if (imageForFullViewIndex - 1 < 0) {
            setImageForFullViewIndex(0);
        } else {
            setImageForFullViewIndex(imageForFullViewIndex - 1);
        }
    };

    const styleConfigProp = {
        option: {
            active: {
                bgColor: "#475569",
                textColor: "white",
            },
            inactive: {
                bgColor: "transparent",
                textColor: "white",
            },
        },
        container: {
            lightMode: {
                bgColor: "#1E293B",
                borderColor: "transparent",
                boxShadow: "none",
            },
            darkMode: {
                bgColor: "#1E293B",
                borderColor: "transparent",
                boxShadow: "none",
            },
        },
    };

    return (
        <LayoutV3 footer={<FooterV3 />}>
            {isFullView && (
                <Box
                    sx={{
                        background: "rgba(15, 23, 42, 0.4)",
                        position: "fixed",
                        backdropFilter: "blur(5px)",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        top: "0",
                        zIndex: "1000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            console.log("Outer box clicked");
                            setIsFullView(false);
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { xs: "0.4rem", md: "1.4rem" },
                        }}
                    >
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={decrementFullViewImgIndex}
                        >
                            <HiOutlineChevronLeft size={25} />
                        </IconButton>
                        <Box
                            sx={{
                                width: { xs: "210px", md: "460px" },
                                height: { xs: "210px", md: "460px" },
                                position: "relative",
                                borderRadius: "15px",
                            }}
                        >
                            <img
                                alt=""
                                src={paginatedSrcs[imageForFullViewIndex]}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                            <IconButton
                                sx={{
                                    background: "white",
                                    color: "#0F172A",
                                    "&:hover": {
                                        background: "#6363FF",
                                        color: "white",
                                    },
                                    position: "absolute",
                                    top: "12px",
                                    right: "12px",
                                }}
                                onClick={handleDownloadClick}
                            >
                                <HiOutlineDownload size={25} />
                            </IconButton>
                        </Box>
                        <IconButton
                            sx={{
                                background: "#707683",
                                color: "white",
                                "&:hover": {
                                    background: "#6363FF",
                                    color: "white",
                                },
                            }}
                            onClick={incrementFullViewImgIndex}
                        >
                            <HiOutlineChevronRight size={25} />
                        </IconButton>
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    background: "#0F172A",
                    color: "white",
                    padding: { xs: "0.7rem 1rem", md: "1rem 7rem" },
                    minHeight: "90vh",
                    width: "100%",
                }}
            >
                {" "}
                <Box
                    sx={{
                        marginTop: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    {/* <Typography
                        sx={{
                            color: "#FFF",
                            
                            fontFamily: "Inter",
                            fontSize: { xs: "20px", md: "30px" },
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: { xs: "23px", md: "36px" },
                            letterSpacing: "-0.225px",
                        }}
                    >
                        History
                    </Typography> */}
                    <OptionSelected optionItems={optionItems} optionSelected={optionSelected} setOptionSelected={setOptionSelected} styleConfigProp={styleConfigProp} />
                    <Button
                        onClick={() => {
                            navigate("/products/headshot");
                        }}
                        sx={{
                            background: "#6363FF",
                            color: "white",
                            borderRadius: "70px",
                            textTransform: "capitalize",
                            padding: "12px 27px",
                            /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                            "&:hover": {
                                background: "#4E4EC8",
                                color: "white",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: { xs: "14px", md: "16px" },
                            }}
                        >
                            Generate Headshots
                        </Typography>
                    </Button>
                </Box>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "500px",
                        }}
                    >
                        <CircularProgress size={90} />
                    </Box>
                ) : optionSelected === "Headshots" ? (
                    <>
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                marginTop: "1rem",
                            }}
                        >
                            {paginatedSrcs.map((headshot, index) => (
                                <SingleImageCard
                                    key={index}
                                    headshot={headshot}
                                    openFullView={openFullView}
                                    changeFullViewImgIndex={
                                        changeFullViewImgIndex
                                    }
                                    index={index}
                                />
                            ))}
                        </Grid>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "1.5rem 0",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "fit-content",
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.max(prev - 1, 1)
                                        )
                                    }
                                    sx={{
                                        background: "#1E293B",
                                        color: "white",
                                        textTransform: "capitalize",
                                    }}
                                    disabled={currentPage === 1}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Prev
                                    </Typography>
                                </Button>
                                <Button
                                    sx={{
                                        margin: "0 20px",
                                        background: "#4338CA",
                                        color: "white",
                                        borderRadius: "0.6rem",
                                        wdith: "32px",
                                        height: "32px",
                                        padding: "0px",
                                        "&:hover": {
                                            background: "#4338CA",
                                            color: "white",
                                        },
                                        pointerEvents: "none",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        {currentPage}
                                    </Typography>
                                </Button>
                                {/* <Button
                                    sx={{
                                        margin: "0 20px",
                                        background: "#4338CA",
                                    }}
                                >
                                    Page {currentPage} of {totalPages}
                                </Button> */}
                                <Button
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.min(prev + 1, totalPages)
                                        )
                                    }
                                    disabled={currentPage === totalPages}
                                    sx={{
                                        background: "#1E293B",
                                        color: "white",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            /* body-medium */
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Next
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <AlbumsHistory albumDocsSrcs={albumDocsSrcs} />

                )}
            </Box>
        </LayoutV3>
    );
};

export default HeadshotsHistory;
