export const profileMenuItemsData = [
    {
        id: "pItem-1",
        title: "Dashboard",
        to: "/dashboard",
    },
    {
        id: "pItem-2",
        title: "Unlimited Credits",
        to: "/upgrade-subscription",
    },
    {
        title: "🔥 Free Credits",
        to: "/rewards",
        id: "pItem-3",
    },
    {
        title: "My Videos",
        to: "/my-videos",
        id: "pItem-4",
    },
    {
        title: "My Audios",
        to: "/my-audios",
        id: "pItem-5",
    },
    /* {
        id: "pItem-3",
        title: "Logout",
        to: "/",
    }, */
];
