import React, { useState, useEffect } from "react";
import { Box, Typography, Slider, Button } from "@mui/material";
import OptionsBadgeBox from "./atoms/OptionsBadgeBox";

const stageData = [
    /* {
        labelTitle: "Duration",
        labelIcon: "/assets/videoTimePromptIcon.svg",
        options: [
            {
                id: "short",
                value: "Short",
                isSelected: false,
            },
            {
                id: "medium",
                value: "Medium",
                isSelected: false,
            },
            {
                id: "long",
                value: "Long",
                isSelected: false,
            },
        ],
    }, */
    /* {
        labelTitle: "Format",
        labelIcon: "/assets/formatVideoIcon.svg",
        options: [
            {
                id: "Shorts",
                value: "Shorts",
                isSelected: false,
            },
            {
                id: "Reels",
                value: "Reels",
                isSelected: false,
            },
            {
                id: "Long Video",
                value: "Long Video",
                isSelected: false,
            },
        ],
    }, */
];
/* const stageData = [
    {
        labelTitle: "Duration",
        labelIcon: "/assets/videoTimePromptIcon.svg",
        options: [
            {
                id: "short",
                value: "Short",
                isSelected: false,
            },
            {
                id: "medium",
                value: "Medium",
                isSelected: false,
            },
            {
                id: "long",
                value: "Long",
                isSelected: false,
            },
        ],
    },
]; */

const InputStageOne = ({
    ideaInputValue,
    setIdeaInputValue,
    durationInputValue,
    setDurationInputValue,
    videoTypeInputValue,
    setVideoTypeInputValue
}) => {
    const [isRangeBeingControlled, setIsRangeBeingControlled] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [stageOneData, setStageOneData] = useState([...stageData]);

    const handleRangeMouseDown = () => {
        setIsRangeBeingControlled(true);
    };

    const handleRangeMouseUp = () => {
        setIsRangeBeingControlled(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 30);
    }, []);

    const handleIdeaInputValueChange = (e) => {
        setIdeaInputValue(e.target.value);
    };

    /* const handleDurationInputValueChange = (e) => {
        setDurationInputValue(e.target.value);
    }; */

    const handleOptionValChange = (currVal, currLabelTitle) => {
        // console.log("kol");
        // console.log(currVal);
        // console.log(currLabelTitle);
        // return;

        if (currLabelTitle === "Duration") {
            console.log(currVal);
            setDurationInputValue(currVal);
        } else if (currLabelTitle === "Format") {
            //"Portrait", "Landscape"

            if (currVal !== "Long Video") {
                console.log("kol-Portrait");

                setVideoTypeInputValue("Portrait");
            } else {
                console.log("kol-Landscape");

                setVideoTypeInputValue("Landscape");
            }

        } /* else if (currLabelTitle === "Tone") {
            setToneInputValue(currVal);
        } */
    };

    return (
        <Box
            sx={{
                opacity: isVisible ? 1 : 0.005, // Set opacity based on visibility
                transition: "opacity 0.5s ease-in-out", //
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: "#FFF",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "22px", md: "30px" },
                        fontStyle: "normal",
                        fontWeight: "800",
                        lineHeight: {
                            xs: "24px",
                            md: "36px",
                        } /* 114.583% */,
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                    }}
                >
                    Transform Your Ideas into Videos
                </Typography>
                <Typography
                    sx={{
                        color: "#E2E8F0",
                        fontFamily: "Inter",
                        letterSpacing: "-0.576px",
                        fontSize: { xs: "0.9rem", md: "1rem" },
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: {
                            xs: "1rem",
                            md: "1.2rem",
                        },
                        textAlign: { xs: "center", md: "left" },
                        textAlign: "center",
                        marginBottom: "8px",
                        display: { xs: "none", md: "block" },
                    }}
                >
                    Type in your concept, set the duration, and let's craft a
                    video for you.
                </Typography>
            </Box>
            <Box
                sx={{
                    margin: "2.5rem 0",
                }}
            >
                <Box
                    sx={{
                        marginBottom: "1.8rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <img
                            src="/assets/videoPlayPromptIcon.svg"
                            style={{
                                width: "24px",
                                height: "24px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                                color: "#E2E8F0",
                                fontFamily: "Inter",
                                letterSpacing: "-0.576px",
                                fontSize: { xs: "0.9rem", md: "1rem" },
                                fontStyle: "normal",
                                fontWeight: "800",
                            }}
                        >
                            Share your idea below
                        </Typography>
                    </Box>
                    <textarea
                        placeholder="e.g. Mindfulness Meditation..."
                        multiline
                        rows={3}
                        value={ideaInputValue}
                        onChange={handleIdeaInputValueChange}
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            border: "2px solid #475569",
                            background: "#242E3D",
                            outline: "none",
                            borderRadius: "8px",
                            padding: "1rem",
                            fontFamily: "Inter",
                            color: "white",
                            fontSize: "1rem",
                            resize: "none",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "1.8rem",
                        flexDirection: "column"
                    }}
                >
                    {stageOneData.map((it, idx) => (
                        <Box
                            key={idx + 1}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "17px",

                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}
                            >
                                <img
                                    src={it.labelIcon}
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                        color: "#E2E8F0",
                                        fontFamily: "Inter",
                                        letterSpacing: "-0.576px",
                                        fontSize: { xs: "0.9rem", md: "1rem" },
                                        fontStyle: "normal",
                                        fontWeight: "800",
                                    }}
                                >
                                    {it.labelTitle}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1.5rem",
                                }}
                            >
                                <OptionsBadgeBox
                                    options={it.options}
                                    labelTitle={it.labelTitle}
                                    handleOptionValChange={
                                        handleOptionValChange
                                    }
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
                {/* <Box
                    sx={{
                        marginBottom: "1.8rem",
                        display: "none",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.6rem",
                        }}
                    >
                        <img
                            src="/assets/videoTimePromptIcon.svg"
                            style={{
                                width: "24px",
                                height: "24px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                            }}
                        >
                            Duration (max: 60 seconds)
                        </Typography>
                    </Box>
                    <Slider
                        min={30}
                        max={60}
                        value={durationInputValue}
                        onChange={handleDurationInputValueChange}
                        valueLabelDisplay={
                            isRangeBeingControlled ? "on" : "off"
                        }
                        aria-labelledby="range-slider"
                        onMouseDown={handleRangeMouseDown}
                        onMouseUp={handleRangeMouseUp}
                        sx={{
                            color: "#22C55E",
                            height: 8,
                            "& .MuiSlider-track": {
                                border: "none",
                            },
                            "& .MuiSlider-thumb": {
                                height: 24,
                                width: 24,
                                backgroundColor: "#fff",
                                border: "2px solid currentColor",
                                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                {
                                    boxShadow: "inherit",
                                },
                                "&:before": {
                                    display: "none",
                                },
                            },
                            "& .MuiSlider-valueLabel": {
                                lineHeight: 1.2,
                                fontSize: 12,
                                background: "unset",
                                padding: 0,
                                width: 32,
                                height: 32,
                                borderRadius: "50% 50% 50% 0",
                                backgroundColor: "#52af77",
                                transformOrigin: "bottom left",
                                transform:
                                    "translate(50%, -100%) rotate(-45deg) scale(0)",
                                "&:before": { display: "none" },
                                "&.MuiSlider-valueLabelOpen": {
                                    transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                },
                                "& > *": {
                                    transform: "rotate(45deg)",
                                },
                            },
                        }}
                    />
                </Box> */}
            </Box>
        </Box>
    );
};

export default InputStageOne;
