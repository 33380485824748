export default function downloadImage(image) {
  return new Promise((resolve, reject) => {
    console.log("image", image);
    const imageTitle = image.prompt.split(" ").join("_");
    const reqURL = image.src;
    fetch(reqURL)
      .then((response) => {
        return response.blob();
      })
      .then((imageBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = function () {
          const base64data = reader.result;
          const a = document.createElement("a"); //Create <a>
          a.href = base64data;
          a.download = imageTitle + "aiseo_art"; //File name Here
          a.click();
          resolve({
            success: true,
          });
        };
      });
  });
}

export const downloadImageFromSrc = async ({ src, filename }) => {
  try {
    const image = await fetch(src);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log("Error Found.....");
    const a = document.createElement("a"); //Create <a>
    a.href = src;
    a.download = "download.png";
    window.open(a);
  }
};
