export const promptsForUseCaseArr = [
    {
        promptText: "Chiptune beats for an 8-bit retro game.",
        promptType: "Gaming",
    },
    {
        promptText: "Dark orchestral melodies for epic boss battles.",
        promptType: "Gaming",
    },
    {
        promptText: "Soothing harp strums for peaceful village scenes.",
        promptType: "Gaming",
    },
    {
        promptText: "Fast-paced drums capturing a car chase sequence.",
        promptType: "Gaming",
    },
    {
        promptText: "Tense strings setting the mood for a stealth mission.",
        promptType: "Gaming",
    },
    {
        promptText: "Synthetic bells echoing in a virtual cavern.",
        promptType: "Synth",
    },
    {
        promptText: "Old radio tunes melding with modern synths.",
        promptType: "Synth",
    },
    {
        promptText: "Harmonic bowls resonating with the universe's vibrations.",
        promptType: "Meditation",
    },
    {
        promptText: "Serene strings transporting to mountainous stillness.",
        promptType: "Meditation",
    },
    {
        promptText: "Mystical chimes floating in a celestial expanse.",
        promptType: "Meditation",
    },

    {
        promptText: "Uplifting intro jingle for morning talk shows.",
        promptType: "Podcast",
    },
    {
        promptText: "Relaxed piano chords setting the scene for interviews.",
        promptType: "Podcast",
    },
    {
        promptText: "Mysterious undertones for a deep-dive documentary.",
        promptType: "Podcast",
    },

    {
        promptText: "Vintage vinyl crackles under a jazzy saxophone.",
        promptType: "Sample",
    },
    {
        promptText: "Urban streetscape: honks, chatter, and distant music.",
        promptType: "Sample",
    },
    {
        promptText: "Orchestral stabs meeting the rhythm of heartbeat.",
        promptType: "Sample",
    },
    {
        promptText: "Sweeping orchestral theme for a grand epic journey.",
        promptType: "Film & Video",
    },

    {
        promptText: "Mellow acoustic vibes for an indie romance montage.",
        promptType: "Film & Video",
    },
    {
        promptText: "Whimsical woodwinds for a charming animated tale.",
        promptType: "Film & Video",
    },
    {
        promptText: "Dark ambient drones for a mysterious horror sequence.",
        promptType: "Film & Video",
    },

    {
        promptText: "Chillhop rhythm with rain for cozy evenings.",
        promptType: "Lofi",
    },
    {
        promptText: "Slow-tempo beats for a tranquil midnight study.",
        promptType: "Lofi",
    },
    {
        promptText: "Muffled drum patterns evoking old-school radio vibes.",
        promptType: "Lofi",
    },
    {
        promptText: "Melancholic guitar strums for a nostalgic cityscape.",
        promptType: "Lofi",
    },
    {
        promptText: "Soft synthesizer waves blending with nature's whispers.",
        promptType: "Lofi",
    },
];
