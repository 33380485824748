import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import logosrc from "../imgs/aiseo-symble.png";

// components
import ImageGenForm from "./form/ImageGenForm";

import { Badge, Container, Tab, Tabs, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setLastAccessed } from "../firestore/dataHandler";

import { introPopup } from "../firestore/showBannerHandler";

const MultiStepPopup = React.lazy(() =>
    import("./MultiStepPopup/MultiStepPopup")
);

// import Exhibition from './paintings/Exhibition';

const BorderedBox = styled(Box)(({ theme }) => ({
    border: "1px solid",
    display: "flex",
    justifyContent: "center",
    borderColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.12)"
            : "lightgray",
    borderRadius: "5px",
    borderBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        border: "none",
        padding: 0,
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: -13,
        top: -5,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: "0 5px",
        fontSize: "9px",
        zIndex: "99",
    },
}));

const styleNewUser = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "1px solid #00000063",
    boxShadow: 24,
    borderRadius: "4px",
    p: 4,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export default function LandingPage({
    user,
    credits,
    setImages,
    setSearchTerm,
    landingPrompt,
}) {
    const dispatch = useDispatch();
    const [tab, setTab] = React.useState(0);
    const image2imageRef = React.useRef();
    const imageVariationRef = React.useRef();
    const imageinstructRef = React.useRef();
    const imageEraseRef = React.useRef();
    const [urlSearchedLexicaImages, setUrlSearchLexicaImgages] = React.useState(
        []
    );
    const [lexicaImages, setLexicaImages] = React.useState([]);
    const [dreamImages, setDreamImages] = React.useState([]);
    const [urlSearchTerm, setUrlSearchTerm] = React.useState("");
    const [addVal, setAddVal] = React.useState(initData[0]);
    const [promptSearchTerm, setPromptSearchTerm] = React.useState("");

    const [selectedImageMaineFile, setSelectedImageMaineFile] =
        React.useState(null);

    const [Image2ImageState, setImage2ImageState] = React.useState("");
    const [ImageVariationState, setImageVariationState] = React.useState("");
    const [hideintroPopup, setHideintroPopup] = React.useState(true);
    const navigate = useNavigate();

    const location = useLocation();

    React.useEffect(() => {
        const q = new URLSearchParams(location.search);
        if (q.get("tool") === "variant") setTab(2);
    }, []);

    const image2imageRefhandler = () => {
        image2imageRef.current.click();
    };
    const imageinstructRefhandler = () => {
        imageinstructRef.current.click();
    };
    const imageVariationRefhandler = () => {
        imageVariationRef.current.click();
    };
    const imageEraseRefhandler = () => {
        imageEraseRef.current.click();
    };

    const [openNewSignupModal, setOpenNewSignupModal] = React.useState(false);
    const handleOpenNewSignupModal = () => setOpenNewSignupModal(true);
    const handleCloseNewSignupModal = () => {
        const isClicked = true;
        const value = addVal;
        introPopup(user?.uid, isClicked, value);
        setTimeout(() => {
            setOpenNewSignupModal(false);
        }, 1000);
    };

    const addNeed = (e) => {
        // console.log('targert',e.target.value)

        setAddVal(e.target.value);
    };

    React.useEffect(() => {
        if (!user) return;
        // set last accessed..
        setLastAccessed().then((res) => {});
    }, [user]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const singleKey = queryParams.get("user");
        if (!singleKey) return;

        if (singleKey === "new") {
            return handleOpenNewSignupModal();
        }
    }, []);

    useEffect(() => {
        // if (!user) return;
        const isClicked = false;
        // console.log("Consoled log for user: *** ------------");
        introPopup(user?.uid, isClicked).then((data) => {
            data?.hide && setHideintroPopup(true);
            !data?.hide && handleOpenNewSignupModal();
            data?.message === "Document does not exist" &&
                setHideintroPopup(false);
            // !data?.hide && setHideBanner(false);
        });
    }, [user]);

    useEffect(() => {
        // if (!user) return;
        console.log(landingPrompt, "l PROMPT");
        setPromptSearchTerm(landingPrompt);
    }, [landingPrompt]);

    const handleTabNavigation = (link) => {
        navigate(`/products/${link}`);
    };

    return (
        <>
            {/* <Layout footer={<Footer />}> */}
            <Box
                sx={{
                    minHeight: "300px",
                    borderRadius: 2,
                    position: "relative",
                    /* marginTop: {
                        xs: window.innerWidth < 330 ? "700px" : "500px",
                        md: "0",
                    }, */
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        width: "100%",
                        transform: "translateY(-40%)",
                        fontSize: "1rem",
                    }}
                    /* style={{
                        margin: "0 auto",
                    }} */
                >
                    <Logo />
                    <Typography align="center">Powered by AISEO</Typography>
                    <Typography align="center" sx={{ color: "gray" }}>
                        With AISEO art you can paint your imagination.
                    </Typography>
                </div>
            </Box>
            {/** Tab Section */}
            <Container>
                <BorderedBox>
                    <Tabs
                        centered
                        // variant="scrollable"
                        value={tab}
                        onChange={(e, newVal) => setTab(newVal)}
                    >
                        <Tab label="Art Generation" />
                        <Tab
                            label="Advertisement Product"
                            onClick={() =>
                                handleTabNavigation("advertisement-product")
                            }
                        />
                        <Tab
                            label="image variation"
                            /* ref={imageVariationRef} */
                            onClick={() =>
                                handleTabNavigation("image-variation")
                            }
                        />
                        <Tab
                            label="erase and replace"
                            /* ref={imageEraseRef} */
                            onClick={() => handleTabNavigation("erase-replace")}
                        />
                        <Tab
                            sx={{ padding: "12px 34px 12px 12px" }}
                            /* sref={imageinstructRef} */
                            label={
                                <StyledBadge badgeContent="New" color="primary">
                                    Instruct
                                </StyledBadge>
                            }
                            onClick={() => handleTabNavigation("instruct")}
                        />
                        {/* <Tab label="Art Generation" />
                        <Tab label="Advertisement Product" />
                        <Tab label="image variation" ref={imageVariationRef} />
                        <Tab label="erase and replace" ref={imageEraseRef} />
                        <Tab
                            sx={{ padding: "12px 34px 12px 12px" }}
                            ref={imageinstructRef}
                            label={
                                <StyledBadge badgeContent="New" color="primary">
                                    Instruct
                                </StyledBadge>
                            }
                        /> */}

                        <Box
                            sx={{
                                position: "relative",
                                cursor: "pointer",
                                display: "none",
                            }}
                            onClick={() => navigate("/ai-avatars")}
                        >
                            <Tooltip
                                title="AI Avatars is the process of finetuning the AI on 10 images to then create more images with the likeness trained. Use it for profile pictures, avatars and so much more!"
                                placement="top"
                                arrow
                            >
                                {/* <Badge badgeContent="coming soon" color="info" sx={{
                  top: "14px !important",
                  right: "-37px !important"
                }}>
                </Badge> */}
                                {/* <Tab label="Dreambooth"/> */}
                                <Typography
                                    sx={{
                                        padding: "12px 16px",
                                        // color: "gray",
                                    }}
                                >
                                    AI Avatars
                                </Typography>
                            </Tooltip>
                            {/* <Box
                sx={{
                  position: "absolute",
                  top: "1px",
                  right: "-62px",
                  background: "lightblue",
                  borderRadius: "25px",
                  padding: "2px 0.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#0288d1",
                  // backgroundColor: "#DD6E0F ",
                  color: "#fff",
                  fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                  fontSize: "12px",
                }}
              >
                coming soon
              </Box> */}
                        </Box>
                    </Tabs>
                    {/* <button onClick={image2imageRefhandler}>Dihan</button> */}
                </BorderedBox>
            </Container>
            <TabPanel value={tab} mt={5} index={0}>
                <ImageGenForm
                    setSelectedImageMaineFile={setSelectedImageMaineFile}
                    image2imageRefhandler={image2imageRefhandler}
                    imageinstructRefhandler={imageinstructRefhandler}
                    imageVariationRefhandler={imageVariationRefhandler}
                    imageEraseRefhandler={imageEraseRefhandler}
                    setImage2ImageState={setImage2ImageState}
                    setImageVariationState={setImageVariationState}
                    user={user}
                    credits={credits}
                    lexicaImages={lexicaImages}
                    setLexicaImages={setLexicaImages}
                    images={dreamImages}
                    setImages={setDreamImages}
                    searchTerm={promptSearchTerm}
                    setSearchTerm={setPromptSearchTerm}
                    tab={tab}
                    index={0}
                    landingPrompt={landingPrompt}
                />
            </TabPanel>
            {/* <TabPanel value={tab} index={1}>
        <ImportUrlForm
          user={user}
          credits={credits}
          images={urlSearchedLexicaImages}
          setImages={setUrlSearchLexicaImgages}
          searchTerm={urlSearchTerm}
          setSearchTerm={setUrlSearchTerm}
          tab={tab}
          index={1}
        />
      </TabPanel> */}

            {/* <TabPanel value={tab} index={1}>
                <AdProduct credits={credits} user={user} />
            </TabPanel>

            <TabPanel value={tab} index={2}>
                <ImportImageVariationUrlForm
                    credits={credits}
                    user={user}
                    imageVarient={true}
                    setSearchTerm={setSearchTerm}
                    setImages={setImages}
                />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <ImportImageImpaintingUrlForm
                    credits={credits}
                    user={user}
                    imageVarient={true}
                    imagePainting={true}
                />
            </TabPanel>

            <TabPanel value={tab} index={4}>
                <CommandForm
                    selectedImageMaineFile={selectedImageMaineFile}
                    setSelectedImageMaineFile={setSelectedImageMaineFile}
                    credits={credits}
                    user={user}
                    imageVarient={true}
                    imagePainting={true}
                />
            </TabPanel> */}
            {/* <MultiStepPopup /> */}

            {/* Global Popup */}
            {/* {globalPopup ? <GlobalModel setGlobalPopup={setGlobalPopup} globalPopup={globalPopup} /> : null} */}
            {/* </Layout> */}
        </>
    );
}

const Logo = () => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem 0",
            }}
        >
            <div
                style={{
                    width: window.innerWidth < 768 ? "30px" : "60px",
                    height: window.innerWidth < 768 ? "30px" : "60px",
                }}
            >
                <img
                    src={logosrc}
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                />
            </div>
            <Typography
                variant="h1"
                align="center"
                sx={{
                    fontSize: { xs: "1.2rem", md: "3rem" },
                    mx: 3,
                    letterSpacing: "2px",
                }}
            >
                AISEO Art
            </Typography>
        </div>
    );
};

const initData = [
    "Write SEO 1 meta tags",
    "Write SEO  meta tags",
    "Write SEO  dmeta tags",
    "Write SEO  meta tags",
    "Write SEO  meta tags",
    "Write SEO  meta tag",
];
