import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    handleAlert: {
        status : false,
        message : ""
    },
    upcomingInvoices: []
};

export const globalComponentSlice = createSlice({
    name: "savedImage",
    initialState,
    reducers: {
        setHandleAlert: (state, action) => {
            state.handleAlert = {
                status : action.payload.status,
                message : action.payload.message
            }
        },
        setUpcomingInvoices: (state, action ) => {
            state.upcomingInvoices = action.payload.upcomingInvoices;
        }
    },
});

export const { setHandleAlert, setUpcomingInvoices } = globalComponentSlice.actions;
export default globalComponentSlice.reducer;
