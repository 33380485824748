import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import * as React from "react";
// import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

// const BootstrapDialogTitle = (props) => {
//     const { children, onClose, ...other } = props;

//     return (
//         <DialogTitle
//             sx={{ m: 0, p: 2 }}
//             {...other}>
//             {children}
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: "absolute",
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </DialogTitle>
//     );
// };

export default function WarningModal({ open, onToggle, message }) {
    //   const [open, setOpen] = React.useState(false);

    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };
    //   const handleClose = () => {
    //     setOpen(false);
    //   };

    const handleCloseBtnClick = ()=>{
        onToggle("close")
    }

    return (
        <div>
            <BootstrapDialog
                sx={{ mb: 5 }}
                onClose={() => onToggle("close")}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <Box
                    sx={{
                        p: 3,
                        minWidth: "400px",
                        textAlign: "center",
                        borderRadious : "10px"
                    }}>
                    <ClearIcon
                        sx={{
                            position : "absolute",
                            right : '15px',
                            top : '15px',
                            cursor : 'pointer'
                        }}
                        onClick={handleCloseBtnClick}
                    ></ClearIcon>
                    <Typography variant="h6" gutterBottom align="left" mt={2} mb={2}>{message}</Typography>
                    <Box
                        sx={{
                            display : 'flex',
                            flexDirection : 'row',
                            justifyContent : 'space-between',
                            gap : '10px'
                        }}
                    >
                        <Link
                            to="/login"
                            style={{ textDecoration: "none" , width : '100%' }}>
                            <Button
                                sx={{ my: 1 , width: '100%' }}
                                autoFocus
                                variant="outlined">
                                Login
                            </Button>
                        </Link>
                        <Link
                            to="/signup"
                            style={{ textDecoration: "none", width : '100%' }}>
                            <Button
                                sx={{ my: 1 , width : '100%'}}
                                autoFocus
                                variant="contained">
                                Sign Up
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </BootstrapDialog>
        </div>
    );
}
