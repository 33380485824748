import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    IconButton,
} from "@mui/material";
import styles from "./workSpaceOutputBox.module.css";

/* import CanvasImage from "../../../CanvasImage"; */

import {
    HiOutlineCloudDownload,
} from "react-icons/hi";
import { saveAs } from "file-saver";
/* import CanvasImageNew from "../../../CanvasImageNew"; */
import { useSelector } from "react-redux";

const VariationOutputBox = ({
    productData,
    /*     generatedImage, */
    isLoading,
    selectedImage,
    optionSelected,
    inputImgUrl,
}) => {
    const { generatedImage } = useSelector((state) => state.imageVariation);
    /* const [isLoading, setIsLoading] = useState(false); */
    const [activeImage, setActiveImage] = useState(generatedImage[0]);
    const [displayVariations, setDisplayVariations] = useState(true);

    const handleActiveImageChange = (activeIndex) => {
        setActiveImage(generatedImage[activeIndex]);
    };

    const downloadImage = () => {
        if (generatedImage.length > 0) {
            saveAs(activeImage, "download-image.png");
        } else {
            saveAs(selectedImage, "download-image.png");
        }
    };

    useEffect(() => {
        setActiveImage(selectedImage);
        setDisplayVariations(false);
    }, [selectedImage]);

    useEffect(() => {
        setActiveImage(generatedImage[0]);
        setDisplayVariations(true);
        console.log(generatedImage, "loasid");
    }, [generatedImage]);

    useEffect(() => {
        if (optionSelected === "By Url" && generatedImage.length === 1) {
            setActiveImage(generatedImage[0]);
        } else {
            setActiveImage(selectedImage);
        }
    }, [optionSelected]);

    return (
        <Box
            /* sx={{
                width: { xs: "100%", lg: "40%" },
                height: "fit-content",
                dislpay: "flex",
                flexDirection: "column",
                padding: "0.8rem",
                background: "#E8FAFF",
                borderRadius: "0.7rem",
                border: "2px solid #B6EEFF",
                position: "relative",
            }} */
            sx={{
                width: {
                    xs: "100%",
                    lg: optionSelected === "By Url" ? "100%" : "40%",
                },
                margin: optionSelected === "By Url" ? "0 auto" : "none",
                height: "fit-content",
                dislpay: "flex",
                flexDirection: "column",
                padding: "0.8rem",
                background: "#E8FAFF",
                borderRadius: "0.7rem",
                border: "2px solid #B6EEFF",
                position: "relative",
            }}
            style={{ gap: "1rem" }}
        >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                    sx={{
                        py: "0rem",
                        /* px: "0.7rem", */
                        height: "2.5rem",
                        borderRadius: "0.3rem",
                        backgroundColor: "#B6EEFF",
                        color: "#281142",
                        border: "2px solid #90B2CC",

                        fontSize: "0.9rem",
                        transition: "none",

                        "&:hover": {
                            backgroundColor: "#C7F2FF",
                            color: "#281142",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        pointerEvents: isLoading ? "none" : "",
                        position: "absolute",
                        right: 16,
                        top: 16,
                        zIndex: 99,
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={downloadImage}
                >
                    <span /* style={{ marginTop: "10px" }} */>
                        <HiOutlineCloudDownload size={20} />
                    </span>
                </IconButton>
            </Box>
            <Box
                sx={{
                    /* marginTop: "1rem", */
                    borderRadius: "5px",
                    height: "auto",
                    width: "100%",
                    position: "relative",
                    minHeight: { xs: "100px", md: "300px" },
                }}
            >
                {isLoading ? (
                    <>
                        <div className={`${styles.loaderContainer}`}>
                            <div className={`${styles.ldsRipple}`}>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </>
                ) : generatedImage.length > 0 ? (
                    <>
                        <img
                            src={activeImage}
                            alt="Your"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "inherit",
                            }}
                        />
                    </>
                ) : (
                    <>
                        <img
                            src={selectedImage}
                            alt="Your"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "inherit",
                            }}
                        />
                    </>
                )}
            </Box>
            {!isLoading &&
                displayVariations &&
                optionSelected === "Upload Image" &&
                generatedImage.length > 1 && (
                    <Box
                        sx={{ margin: "0.6rem 0 0 0" }}
                        style={{ gap: "0.3rem" }}
                    >
                        <Typography sx={{ color: "#000000" }}>
                            Variations{" "}
                        </Typography>
                        <Box
                            sx={{ display: "flex", margin: "0.4rem 0" }}
                            style={{ gap: "0.3rem" }}
                        >
                            {generatedImage.length > 1 &&
                                generatedImage.map((item, index) => (
                                    <Box
                                        sx={{
                                            width: "100px",
                                            borderRadius: "0.4rem",
                                            border: `3px solid ${
                                                activeImage ===
                                                generatedImage[index]
                                                    ? "#6464F4"
                                                    : "transparent"
                                            }`,
                                            padding: "0.1rem",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            handleActiveImageChange(index)
                                        }
                                    >
                                        <img
                                            src={generatedImage[index]}
                                            alt="Your"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                borderRadius: "0.3rem",
                                            }}
                                        />
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                )}
        </Box>
    );
};

export default VariationOutputBox;
