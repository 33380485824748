import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { HiOutlineChip } from "react-icons/hi";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { checkDailyLimit } from "../../firestore/dataHandler";
// import { reduceTheCredit } from "../../firestore/dataHandler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UndoIcon from "@mui/icons-material/Undo";
import Slide from "@mui/material/Slide";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { fetchLexicalData } from "../../axios/fetchdata";
import { EXPENSE_RATE } from "../../constants/creditsExpenseRatio";
// import { imageGeneration } from "../../firebaseInit";
import { autoCompletePrompt } from "../../firestore/cloudfunctionsHandler";
import { uploadGenerateImageToStorage } from "../../firestore/storageHandler";
import { useAPI } from "../../StateProvider";
import LightBox from "../LightBox";
import NeedToBuyAPlan from "../model/NeedToBuyAPlan";
import randomImageArray from "../randoms/random";
import { LightBlueBox } from "../styled/boxes";
import DialogModal from "./DialogModal";
import DisplayImages from "./DisplayImages";
import DisplayLexicaImages from "./DisplayLexicaImages";
import WarningModal from "./WarningModal";

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { generationSucceed } from "../../features/image/imageSlice";

import debounce from "lodash.debounce";
import { addToSavedImages } from "../../features/image/savedImageSlice";

import "../../App.css";
import { imageGeneration_replicate } from "../../axios/fetchdata";
import { onGettingIdToken } from "../../firebaseInit";
import DisplayGeneratedImages from "./DisplayGeneratedImages";
import Settings from "./Settings";

import mixpanel from "mixpanel-browser";
import CustomeTemplateModal from "./CustomeTemplateModal";
import FilterCategory from "./FilterCategory";
import ToastNotification from "../atoms/ToastNotification";
import Templates from "../Templates";
import TemplatesNew from "../Templates/TemplatesNew";
import OptionSelected from "../atoms/OptionSelected";

const styleConfigProp = {
    option: {
        active: {
            bgColor: "#0F172A",
            textColor: "white",
        },
        inactive: {
            bgColor: "transparent",
            textColor: "#0F172A",
        },
    },
    container: {
        lightMode: {
            bgColor: "#EEF2FF",
            borderColor: "#DADFEB",
            boxShadow: "0px 4px 15px #E8FAFF",
        },
        darkMode: {
            bgColor: "#EEF2FF",
            borderColor: "#DADFEB",
            boxShadow: "none",
        },
    },
};

const BorderedBox = styled(Box)(({ theme }) => ({
    border: "1px solid",
    borderColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.12)"
            : "lightgray",
    borderRadius: "5px",
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: ".25rem 1rem 1rem 1rem",
    [theme.breakpoints.down("sm")]: {
        border: "none",
        padding: 0,
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "2px solid #e6e6e6",
    boxShadow: 24,
    borderRadious: 30,
    p: 4,
};

const tooltipText =
    "The categories are made for the autocomplete prompt. So when you select the category and click autocomplete, the AI tries to autocompletes the prompt based on the selected category.";

export default function ImageGenForm({
    credits,
    user,
    images,
    setImages,
    lexicaImages,
    setLexicaImages,
    searchTerm,
    setSearchTerm,
    image2imageRefhandler,
    imageVariationRefhandler,
    setImage2ImageState,
    setImageVariationState,
    imageEraseRefhandler,
    imageinstructRefhandler,
    setSelectedImageMaineFile,
    landingPrompt,
}) {
    const sizes = [
        { id: 1, width: 1024, height: 1024, title: "Square" },
        { id: 3, width: 768, height: 1024, title: "Vertical" },
        { id: 2, width: 1024, height: 768, title: "Horizontal" },
    ];
    /*  const sizes = [
        { id: 1, width: 512, height: 512, title: "Square" },
        { id: 2, width: 768, height: 512, title: "Horizontal" },
        { id: 3, width: 512, height: 768, title: "Vertical" },
    ]; */

    const config = useSelector((state) => state.config);
    const activeFolder = config.activefolder;

    // const folders = ["New Folder", "Landscape", "Welcome"];
    const [imageSize, setImageSize] = React.useState(1);
    const [currentFolder, setCurrentFolder] = React.useState();
    // const [cfgScale, setCfgScale] = React.useState(null);
    // const [seed, setSeed] = React.useState(null);
    const modelConfig = useSelector((state) => state.modelConfig);

    const [imageGenerating, setImageGenerating] = React.useState(false);
    const { data, dataFetched } = useAPI();
    let navigate = useNavigate();
    // const subscription = data?.subscription;

    // const [generatedImage, setGeneratedImage] = React.useState();

    const savedImage = useSelector((state) => state.savedImage);
    const generatedImage = savedImage?.images;
    console.log(generatedImage);
    // use user selected/written prompt
    const [showPopup, setShowPopup] = React.useState(false);

    // this popup is for running out of credit things.
    const [open, setOpen] = React.useState(false);
    const [limitExceed, setLimitExceed] = React.useState(false);
    const [warning, setWarning] = React.useState("");

    const [lockWarning, setLockWarning] = React.useState(false);

    // Model Name and version...
    const [modelName, setModelName] = React.useState(
        "cjwbw/stable-diffusion-v1-5"
    );
    const [modelTitle, setModelTitle] = React.useState("SD");
    const [modelVersion, setModelVersion] = React.useState(
        "f370727477aa04d12d8c0b5c4e3a22399296c21cd18ff67cd7619710630fe3cb"
    );

    // show and hide light box...
    const [showLightBox, setShowLightBox] = React.useState(false);
    const [slectedImageId, setSlectedImageId] = React.useState();

    // category Related store..
    const [category, setCategory] = React.useState("Concept Art");
    const [oldStack, setOldStack] = React.useState();
    const [modelProvider, setModelProvider] = React.useState("stability");
    const [filterPrompt, setFilterPrompt] = React.useState("");
    const [filterImg, setFilterImg] = React.useState(
        "https://storage.googleapis.com/code-translate-c9640.appspot.com/customTemplateGenerations/templates/tempNo1.svg"
    );

    // current tab
    const [currentTab, setCurrentTab] = React.useState("templates");
    // Advanced Settings toggler...
    const [advancedSettingsToggler, setAdvancedSettingsToggler] =
        React.useState(false);

    const optionItems = ["Templates", "Generated Images"];
    const [optionSelected, setOptionSelected] = React.useState(optionItems[0]);
    // search params
    const [searchParams] = useSearchParams();

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);

        // mixpanel.track("Categories - image generation,", {
        //   Description: "Which categories uses user to generate images.",
        //   category: event.target.value,
        // });
    };
    let categoryList = [
        "Photography",
        "Portrait",
        "Concept Art",
        "Architecture",
        "Fashion",
        "3D",
        "Videogames",
        "Graphic Design",
        "Wallpaper",
        "Cinematic",
    ];

    console.log("user remain credits: ", credits);
    // const device = useDeviceChecker();

    const formRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const undoRef = React.useRef(null);
    const filterRef = React.useRef(null);

    const dispatch = useDispatch();

    const renderImageSizeString = (val) => {
        const temp = sizes.filter((s) => s.id === val)[0];
        return `${temp.width}px - ${temp.height}px (${temp.title})`;
    };

    const getImageSize = (val) => {
        const temp = sizes.filter((s) => s.id === val)[0];
        return { width: temp.width, height: temp.height, title: temp.title };
    };

    const handleImageSizeChange = (e) => {
        console.log(e.target.value);
        setImageSize(e.target.value);
        // mixpanel.track("Image size selector", {
        //   Description: "Image size selector for image generation",
        //   value: e.target.value,
        // });
    };

    const checkImageExist = (res) => {
        console.log("ALL URL", res);
        res.forEach((url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url.src;
                img.onload = () => {
                    console.log("WORKING", url);
                    resolve(true);
                };
                img.onerror = () => {
                    console.log("Not WORKING", url);
                    resolve(false);
                };
            });
        });
    };

    React.useEffect(() => {
        const queryPrompt = searchParams.get("prompt");
        if (queryPrompt) {
            setDescription(queryPrompt);
            window.scrollTo({
                top: formRef.current.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
            // TODO: setSearchTerm for lexica image searching
        }
    }, []);

    React.useEffect(() => {
        if (!searchTerm) return;
        setDescription(searchTerm);
    }, [searchTerm]);

    React.useEffect(() => {
        setLexicaImages(randomImageArray);
    }, [setLexicaImages]);

    const debouncedLexicaSearch = React.useMemo(
        () =>
            debounce((event) => {
                if (event.keyCode === 17) {
                    console.log("CONTROL CLICKED");
                    return;
                }
                console.log("TEST EVENT 1", event);
                const text = event.target.value;
                if (!text) return;
                fetchLexicalData(text, "regular").then((res) => {
                    setLexicaImages(res);
                });
            }, 2500),
        [setLexicaImages]
    );

    const debouncedLexicalPaste = React.useMemo(
        () =>
            debounce((event) => {
                event.preventDefault();
                console.log("TEST EVENT 2", event);
                if (event.key === "Control" && event.ctrlKey) {
                    alert("paset");
                }
                const text = event.target.value;
                if (!text) return;
                setTimeout(() => {
                    fetchLexicalData(text, "paste").then((res) => {
                        setLexicaImages(res);
                    });
                }, 2500);
            }, 0),
        []
    );

    let lastWordCount = 0;
    let lastText;

    // handle Generation button click...
    const handleSubmit = (e) => {
        e.preventDefault();
        mixpanel.track("Generate button - Landing page", {
            Description: "Generate new image from landing page",
        });

        // dispatch(
        //     generationSucceed({
        //         id: "id",
        //         src: "string",
        //         prompt: "prompt",

        //         category: "generated",

        //     })
        // );
        // return;

        const formData = new FormData(e.currentTarget);
        const description = formData.get("description");
        console.log(
            "Description: **************************************" + description,
            filterPrompt
        );

        let str = filterPrompt;
        str = str.replace("{Object}", description);

        console.log(
            "Description: **************************************: str: ",
            str
        );

        if (str === "") {
            str = description;
        }
        // if(!dataFetched) return;

        console.log(user);
        if (!user || !user.emailVerified) {
            setShowPopup(true);
            setImageGenerating(false);
            return;
        }

        console.log("SUBSCRIPTION IS", data);

        // if (credits === 0) {
        //   setLockWarning(true);
        //   return;
        // }

        // console.log(
        //   "SUBSCRIPTION NOT DEFIENEDs typeof",
        //   typeof data.subscription.type
        // );
        //
        //  free user are not allowed to generate..

        // || data?.subscription.type === "Free"

        if (!data.subscription || data?.subscription.type === "Free") {
            console.log("SUBSCRIPTION NOT DEFIENEDs");

            // navigate to pricing page...
            setLockWarning(true);
            // dispatch(
            //     setHandleAlert({
            //         status : true,
            //         message : "Image generation feature is a premium feature. Please select a plan"
            //     })
            // )

            // After four seconds remove this one... wala...
            setTimeout(() => {
                // dispatch(
                //     setHandleAlert({
                //         status : false,
                //         message : ""
                //     })
                // )
                // navigate("../pricing", { replace: true });
            }, 4000);

            setImageGenerating(false);
            return;
        } else {
            console.log("okey");
        }

        if (data?.credits < 1) {
            console.log("SUBSCRIPTION NOT DEFIENEDs");

            // navigate to pricing page...
            setLockWarning(true);
            // dispatch(
            //     setHandleAlert({
            //         status : true,
            //         message : "Image generation feature is a premium feature. Please select a plan"
            //     })
            // )

            // After four seconds remove this one... wala...
            setTimeout(() => {
                // dispatch(
                //     setHandleAlert({
                //         status : false,
                //         message : ""
                //     })
                // )
                // navigate("../pricing", { replace: true });
            }, 4000);

            setImageGenerating(false);
            return;
        } else {
            console.log("okey");
        }

        const tempSize = getImageSize(imageSize);
        const sizeTitle = tempSize.title;
        // console.log(tempSize);

        let cost;
        if (sizeTitle === "Square") cost = EXPENSE_RATE.smallImageGen;
        else cost = EXPENSE_RATE.mediumImageGen;

        const finalCredit = credits - cost;
        if (finalCredit < 0) {
            console.log("CREDIT LESS");
            setOpen(true);
            setImageGenerating(false);
            return;
        }
        // if (credits < 0) {
        //   console.log("CREDIT LESS");
        //   setOpen(true);
        //   setImageGenerating(false);
        //   return;
        // }
        // console.log(credits, "CREDITS");
        onGettingIdToken((token) => {
            checkDailyLimit(user, credits)
                .then(() => {
                    // for vertical/horizontal image 2 credits will be consumed

                    // console.log("modelTitle", modelTitle);
                    // console.log("modelVersion", modelVersion);

                    // console.log(str);
                    // console.log(tempSize.width);
                    // console.log(tempSize.height);
                    // console.log(modelConfig.seed);
                    // console.log(modelConfig.cfgScale);

                    imageGeneration_replicate({
                        text: str,
                        width: tempSize.width,
                        height: tempSize.height,
                        seed: modelConfig.seed,
                        cfgScale: modelConfig.cfgScale,
                        provider: modelProvider,
                        size: imageSize === 1 ? "square" : "vertical",
                        token: token,
                        modelName: modelName,
                        modelVersion: modelVersion,
                    })
                        .then(async (result) => {
                            // console.log(result, "Generation Result");

                            if (result.status === "error") {
                                setWarning(result.message);
                                setImageGenerating(false);
                                return;
                            }

                            const imguid = uuidv4();
                            const imageData = {
                                height: result.height,
                                width: result.width,
                                prompt: result.prompt,
                                seed: result.seed,
                                cfgScale: result.cfg_scale,
                                steps: result.steps,
                            };
                            setImages([
                                {
                                    ...imageData,
                                    imageString: result.imageString,
                                    userGivenTitle: str,
                                    email: user.email,
                                    id: imguid,
                                    folderName: activeFolder,
                                },
                            ]);

                            await uploadGenerateImageToStorage({
                                ...imageData,
                                imageString: result.imageString,
                                userGivenTitle: str,
                                email: user.email,
                                id: imguid,
                                folderName: activeFolder,
                            });

                            let srcUrl = `https://storage.googleapis.com/code-translate-c9640.appspot.com/generations/${imguid}.png`;

                            dispatch(
                                addToSavedImages({
                                    src: result.imageString,
                                    id: imguid,
                                    folderName: activeFolder,
                                    ...imageData,
                                })
                            );

                            dispatch(
                                generationSucceed({
                                    id: imguid,
                                    src: result.imageString,
                                    category: "generated",
                                    url: srcUrl,
                                    ...imageData,
                                })
                            );

                            setImageGenerating(false);
                            // Credits are being reduced from flask cloud function
                            // reduceTheCredit(cost);
                        })
                        .catch((error) => {
                            setWarning("Something went wrong.");
                            setImageGenerating(false);
                        });
                })
                .catch((err) => {
                    console.log("ERR", err);
                    // Limit Exceed
                    setLimitExceed(true);
                    setImageGenerating(false);
                });
        });
        // check credits status..

        mixpanel.track("Art generation", {
            Description: "User clicked for Art generation",
        });
    };

    const handlePromptSelection = (image) => {
        let text = image.prompt;
        setSearchTerm(text);
        console.log("generation prmpt", image);
        window.scrollTo({
            top: formRef.current.offsetTop - 100,
            left: 0,
            behavior: "smooth",
        });
        inputRef.current.focus();

        // fetch the lexical data..
        fetchLexicalData(image.src, "regular").then((res) => {
            setLexicaImages(res);
        });
    };
    // const handlePromptSelectionLightBox = (image) => {
    //     let text = image.prompt;
    //     setSearchTerm(text);
    //     console.log(image);
    //     window.scrollTo({
    //         top: formRef.current.offsetTop - 100,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    //     inputRef.current.focus();

    //     // fetch the lexical data..
    //     fetchLexicalData(image.src).then((res) => {
    //         setLexicaImages(res);
    //     });
    // };

    const handlePopupToggle = (val) => {
        if (val === "open") {
            setShowPopup(true);
        } else {
            setShowPopup(false);
        }
    };

    const handleExampleClick = () => {
        // mixpanel.track("Image generation examaple - landing ", {
        //   Description: "Check image generation examaple for generating images ",
        // });
        let text =
            "splash of random vivid colour, HDR, UHD, 64K, Highly detailed, Vivid Colors, Unreal engine, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski  ";
        setSearchTerm(text);
        setTimeout(() => {
            fetchLexicalData(text, "example").then((res) => {
                setLexicaImages(res);
            });
        }, 3000);
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    // Open Light box..
    const openLightBox = async (id) => {
        setShowLightBox(true);
        setSlectedImageId(id);
    };

    // handle autocomplete button click..
    const handleAutocomplete = () => {
        mixpanel.track("Auto Complete - landingPage,", {
            Description: "User used auto complete option to generate image.",
        });
        // if result is empty return..
        const description = getDescription();
        if (!description) return;
        let style = category;
        // show to auto complete button spinner...
        document.querySelector(".autocompleteSpinner").style.display = "flex";
        // console.log({text, category});

        // call the cloud funciton....
        autoCompletePrompt(description, style).then((res) => {
            // console.log(res);
            // stop the auto complete spinner
            document.querySelector(".autocompleteSpinner").style.display =
                "none";
            // update the search term.
            console.log(res.trim(), "res2");
            setSearchTerm(res);
            // update the undo state...
            setOldStack(description);

            // show the undo button.
            // console.log(undoRef);
            undoRef.current.style.display = "flex";

            // fetch the lexical data..
            console.log("AFTER PROMPT GEN IT WILL CALL");
            fetchLexicalData(res, "regular").then((res1) => {
                setLexicaImages(res1);
            });
        });
    };

    // handle undo click....
    const handleUndoClick = () => {
        // setOldStack();
        setSearchTerm(oldStack);
        // hide the undo button.
        undoRef.current.style.display = "none";
    };

    const handleTabSelectionClick = (event) => {
        if (event.target.classList.contains("generated")) {
            // alert("generated");
            setCurrentTab("generated");
        } else if (event.target.classList.contains("community")) {
            // alert("community");
            setCurrentTab("lexical");
        }
    };

    // handle advanced settigs toggler...
    const handleAdvancedSettingsToggler = (event) => {
        setAdvancedSettingsToggler(!advancedSettingsToggler);
    };

    const [currentTabValue, setCurrentTabValue] = React.useState(0);

    const changeCurrentTabValue = (event, newValue) => {
        setCurrentTabValue(newValue);
        if (newValue === 0) {
            setCurrentTab("templates");
            /* setCurrentTab("lexical"); */
        } else if (newValue === 1) {
            setCurrentTab("generated");
        }
        // mixpanel.track("Image Tab - Landing page", {
        //   Description: "Image generation from comunity images or Generated Images",
        //   value: currentTabValue,
        // });
    };
    const setDescription = (val) => {
        const elem = inputRef.current;
        //console.log(elem);
        const input = elem.querySelector("textarea");
        input.value = val;
    };

    const getDescription = () => {
        const elem = inputRef.current;
        const input = elem.querySelector("textarea");
        return input.value;
    };

    // handle Template button click.
    const handleTemplatesBtnClick = () => {
        //navigate("/templates");
        navigate("/templates", { state: { makePublicTemplateActive: "Yes" } });

        /* navigate("./templates"); */
        mixpanel.track("Templates", {
            Description: "Redirect templates from Art generation",
        });
        // mixpanel.track("templates - landingPage,", {
        //   Description: "From landing page",
        // });
    };
    const handlePublicTemplatesBtnClick = () => {
        navigate("/templates");
        /* navigate("./templates"); */
        mixpanel.track("Templates", {
            Description: "Redirect templates from Art generation",
        });
        // mixpanel.track("templates - landingPage,", {
        //   Description: "From landing page",
        // });
    };
    const handleStokeBtnClick = () => {
        navigate("./templates");
        // mixpanel.track("templates - landingPage,", {
        //   Description: "From landing page",
        // });
    };

    // handle dreambooth button click..
    const handleDreamBoothBtnClick = () => {
        navigate("/tools");
        /* navigate("./tools"); */

        mixpanel.track("Tools", {
            Description: "Redirect tools from Art generation",
        });
        // navigate("./templates?tab=public");
        // navigate("./ai-avatars");

        // mixpanel.track("Ai avatars landing page", {
        //   Description: "From landing page",
        // });
    };

    const handleDreamBoothBtnClick1 = () => {
        // navigate("./tools");
        navigate("/templates?tab=public");
        // navigate("./ai-avatars");

        // mixpanel.track("Ai avatars landing page", {
        //   Description: "From landing page",
        // });
    };

    const handleNavigateToAiPhotoshoots = async () => {
        mixpanel.track("AIPHOTOSHOOTS Badge - clicked", {
            Description:
                "Ai photoshoots - new badge button - clicked from the landing",
            /* category: event.target.value, */
        });
        console.log("mix panel should get added");
        navigate("/ai-photoshoots");
    };

    const handleVideoGeneratorBtnClick = () => {
        navigate("./products/headshot");
        // navigate("/video");
        // navigate("./ai-avatars");

        // mixpanel.track("Ai avatars landing page", {
        //   Description: "From landing page",
        // });
    };
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const singleKey = queryParams.get("template_prompt");
        console.log("singleKey", singleKey);
        if (!singleKey) return;

        if (singleKey) {
            return setSearchTerm(singleKey);
        }
    }, []);

    const MemoizedDisplayImages = React.memo(function () {
        return (
            <DisplayImages
                imageinstructRefhandler={imageinstructRefhandler}
                setSelectedImageMaineFile={setSelectedImageMaineFile}
                imageEraseRefhandler={imageEraseRefhandler}
                images={images}
                selectedSize={getImageSize(imageSize)}
            />
        );
    });

    const { state } = useLocation();

    React.useEffect(() => {
        if (state) {
            console.log("s", state);
            console.log(state.prompt);
            // handlePromptSelection(state.prompt);
            if (landingPrompt !== "") {
                setDescription(state.prompt);
            } else {
                setSearchTerm(state.prompt);
            }
            // fetch the lexical data..
            fetchLexicalData(state.prompt, "regular").then((res) => {
                setLexicaImages(res);
            });
        }
    }, []);

    /* React.useEffect(() => {
        console.log(landingPrompt, "lprompt");
        if (state) {
            console.log("s", state);
            console.log(state.prompt);
            // handlePromptSelection(state.prompt);
            if (landingPrompt !== "") {
                setSearchTerm(landingPrompt);
            } else {
                setSearchTerm(state.prompt);
            }
            // fetch the lexical data..
            fetchLexicalData(state.prompt, "regular").then((res) => {
                setLexicaImages(res);
            });
        }
    }, [landingPrompt]); */

    const [openCustomeTemplModal, setOpenCustomeTemplModal] =
        React.useState(false);
    const handleOpen = () => setOpenCustomeTemplModal(true);
    const handleClose = () => setOpenCustomeTemplModal(false);

    // useEffect(() => {
    //   console.log("data", typeof data?.subscription?.type);
    //   // data?.subscription?.type === undefined && filterRef.current.click();
    //   // data?.subscription === null && filterRef.current.click();  {return filterRef.current.click()}
    //   if (!user) return;
    //   // if(user &&  data?.subscription == null) alert("show modal")
    //   // if(user &&  data?.subscription?.type === "undefined" )   alert("show modal")
    //   // handleOpen();
    //   data?.subscription?.type === "Free" ||
    //     (data?.subscription === undefined && filterRef.current.click());
    // }, []);

    return (
        <React.Fragment>
            <WarningModal
                open={showPopup}
                onToggle={handlePopupToggle}
                message="Please log in with your account or make a new one to start generating images."
            />
            <DialogModal
                open={open}
                setOpen={setOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                type="none"
            />

            {/* IF User is free, Show the buy plan popup model */}
            {lockWarning ? (
                <>
                    <NeedToBuyAPlan
                        lockWarning={lockWarning}
                        setLockWarning={setLockWarning}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                    {/* <ToastNotification
                        message="You run out of credits. Start free trial!"
                        type="error"
                        duration={40000}
                        position="topRight"
                    /> */}
                </>
            ) : null}

            {/* show or hide lightbox popup. */}
            {showLightBox ? (
                <LightBox
                    credits={credits}
                    user={user}
                    copyPrompt={true}
                    tryThis={false}
                    setShowLightBox={setShowLightBox}
                    slectedImageId={slectedImageId}
                    setCurrentTab={setCurrentTab}
                    images={
                        currentTab === "lexical" ? lexicaImages : generatedImage
                    }
                    handlePromptSelection={handlePromptSelection}
                    askedFrom={
                        currentTab === "lexical"
                            ? "lexicaImages"
                            : "generatedImage"
                    }
                ></LightBox>
            ) : null}

            <Container>
                <BorderedBox className="pt5" ref={formRef}>
                    <form
                        onSubmit={(e) => {
                            setWarning("");
                            setImageGenerating(true);
                            handleSubmit(e);
                        }}
                    >
                        <Box
                            sx={{
                                mb: 2,
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                            style={{
                                gap: "0.8rem",
                            }}
                            spacing={2}
                            direction="row"
                        >
                            {/* from here was commented out */}
                            {/* <Tooltip title={tooltipText} placement="top" arrow>
                <ErrorOutlineIcon
                  sx={{
                    cursor: "pointer",
                    color: "#616161",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </Tooltip> */}

                            {/* Category... */}
                            {/* <FormControl
                size="small"
                sx={{
                  minWidth: 150,
                  color: "white",
                  marginLeft: "unset !important",
                }}
              >
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select-label-id"
                  value={category}
                  label="Category"
                  onChange={handleCategoryChange}
                  renderValue={(value) => (
                    <Typography sx={{ fontSize: ".9rem" }}>{value}</Typography>
                  )}
                >
                  {categoryList.map((elem, i) => (
                    <MenuItem value={elem} key={i} sx={{ fontSize: "0.9rem" }}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

                            <FilterCategory
                                data={data}
                                filterRef={filterRef}
                                setLexicaImages={setLexicaImages}
                                filterImg={filterImg}
                                setFilterImg={setFilterImg}
                                setFilterPrompt={setFilterPrompt}
                            />
                            <Button
                                sx={{
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                    height: 32,
                                }}
                                id="autocomplete"
                                variant="outlined"
                                onClick={handleAutocomplete}
                            >
                                <span>Autocomplete Prompt with AI</span>
                                <span style={{ marginLeft: ".5rem" }}>
                                    <CircularProgress
                                        className="autocompleteSpinner"
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            // display : 'flex',
                                            aligItems: "center",
                                            justifyContent: "center",
                                            display: "none",
                                        }}
                                    />
                                </span>
                            </Button>
                            <Button
                                style={{
                                    // marginLeft : 'unset',
                                    padding: "unset",
                                    minWidth: "unset",
                                    display: "none",
                                }}
                                onClick={handleUndoClick}
                                ref={undoRef}
                            >
                                <Tooltip title="Undo" placement="top" arrow>
                                    <UndoIcon
                                        style={{
                                            width: "1rem",
                                            height: "1rem",
                                        }}
                                    ></UndoIcon>
                                </Tooltip>
                            </Button>

                            {/* <Badge badgeContent="new" color="info">
                               
              

                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDreamBoothBtnClick}
                                    sx={{
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        marginLeft: ".5rem",
                                    }}
                                >
                                    Tools
                                </Button>

                            </Badge> */}

                            <Badge badgeContent="new" color="info">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleVideoGeneratorBtnClick}
                                    sx={{
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        marginLeft: ".5rem",
                                    }}
                                >
                                    Headshot generator
                                </Button>

                            </Badge>
                            <Badge badgeContent="new" color="info">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleNavigateToAiPhotoshoots}
                                    sx={{
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        marginLeft: ".5rem",
                                    }}
                                >
                                    AI Photoshoots
                                </Button>
                            </Badge>
                            {/* <Badge badgeContent="new" color="info">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDreamBoothBtnClick1}
                                    sx={{
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        marginLeft: ".5rem",
                                    }}
                                >
                                    Public templates
                                </Button>
                            </Badge> */}
                            <Badge
                                sx={{
                                    marginLeft: ".5rem",
                                    marginRight: ".5rem",
                                }}
                                badgeContent="new"
                                color="info"
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        marginleft: "1.4rem",
                                    }}
                                    onClick={handleTemplatesBtnClick}
                                >
                                    Templates
                                </Button>
                            </Badge>

                            {/* button */}

                            {/* <Button
                  variant="outlined"
                  size="small"
                  onClick={handleStokeBtnClick}
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginRight: "1rem",
                  }}
                >
                  Stock Image Gen
                </Button> */}

                            {/* <Badge sx={{
                // mx : 2
              }} badgeContent="new" color="info">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginLeft: ".5rem",
                    marginRight :'.5rem'
                  }}
                  onClick={handleDreamBoothBtnClick}
                >
                  AI Avatars
                </Button>
              </Badge> */}
                            {/* button */}
                            {/* <Badge badgeContent="new" color="info">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginLeft: ".5rem",
                  }}
                  onClick={handleDreamBoothBtnClick}
                >
                  DreamBooth
                </Button>
              </Badge> */}
                        </Box>

                        {/* Image Desription */}
                        {limitExceed ? (
                            <Box
                                sx={{
                                    background: "red",
                                    padding: "0.5rem 1rem",
                                    color: "white",
                                    mb: 2,
                                    borderRadious: "4px",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{ color: "white" }}
                                >
                                    You Exceeded the daily generation limit
                                    which is put to prevent bots and abuse of
                                    the service. The limit applies just to image
                                    generation. You could still use unlimited
                                    image downloads and enhancement with
                                    comunity images. Also you are able to use
                                    unlimited image reversals and enhancements.
                                </Typography>
                            </Box>
                        ) : null}
                        {warning ? (
                            <Box
                                sx={{
                                    background: "red",
                                    padding: "0.5rem 1rem",
                                    color: "white",
                                    mb: 2,
                                    borderRadious: "4px",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{ color: "white" }}
                                >
                                    {warning}
                                </Typography>
                            </Box>
                        ) : null}
                        <Box
                            className="generate_wrapper"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box textAlign="center">
                                <CustomeTemplateModal
                                    data={data}
                                    setModelName={setModelName}
                                    setModelTitle={setModelTitle}
                                    modelTitle={modelTitle}
                                    setModelVersion={setModelVersion}
                                    setSearchTerm={setSearchTerm}
                                    setModelProvider={setModelProvider}
                                />
                            </Box>
                            <TextField
                                fullWidth
                                multiline
                                ref={inputRef}
                                name="description"
                                sx={{
                                    marginX: { sm: 1, xs: 0 },
                                    marginY: { sm: 0, xs: 1 },
                                }}
                                // value={searchTerm}
                                // onChange={(e) => setSearchTerm(e.target.value)}
                                onPaste={debouncedLexicalPaste}
                                onKeyUp={debouncedLexicaSearch}
                                onBlur={(e) => setSearchTerm(e.target.value)}
                                size="small"
                                placeholder="Describe your imagination here..."
                            />
                            <Box textAlign="center">
                                <Button
                                    type="submit"
                                    sx={{
                                        py: "0.5rem",
                                        px: "1.5rem",
                                        width: "fit-content",
                                        /*  height: "2.5rem", */
                                        borderRadius: "2rem",
                                        background:
                                            "linear-gradient(25deg, #6363FF 10%, #281142 90%)",
                                        color: "white",
                                        fontSize: "0.9rem",
                                        transition: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        border: "1.6px solid white",
                                        boxShadow: "none",
                                        /* margin: "0 auto", */

                                        "&:hover": {
                                            background:
                                                "linear-gradient(85deg, #6363FF 10%, #281142 90%)",
                                            transition: "all 0.3s ease-in",
                                            /* boxShadow: " 1px 7px 400px -2px black", */
                                        },
                                    }}
                                    disabled={imageGenerating}
                                    disableElevation
                                    style={{
                                        gap: "0.3rem",
                                    }}
                                    /* onClick={(e) => handleSubmit(e)} */
                                >
                                    <>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                            Generate
                                        </Typography>
                                        <span style={{ marginTop: "3px" }}>
                                            <HiOutlineChip size={20} />
                                        </span>
                                    </>
                                </Button>
                                {/* <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={imageGenerating}
                                    disableElevation
                                >
                                    Generate
                                </Button> */}
                            </Box>
                        </Box>

                        {/* Bottom stack... */}
                        <Box
                            mt={2}
                            /* sx={boxStyle} */ sx={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                            style={{
                                gap: "0.8rem",
                            }}
                        >
                            {/* Image Size Selection */}
                            <FormControl
                                sx={{
                                    minWidth: 250,
                                }}
                                variant="outlined"
                                size="small"
                            >
                                <InputLabel id="image-size-select-label">
                                    Image Size
                                </InputLabel>
                                <Select
                                    labelId="image-size-select-label"
                                    id="image-size-selection"
                                    value={imageSize}
                                    label="Image Size"
                                    renderValue={(value) => (
                                        <Typography sx={{ fontSize: ".9rem" }}>
                                            {renderImageSizeString(value)}
                                        </Typography>
                                    )}
                                    onChange={handleImageSizeChange}
                                >
                                    {sizes.map((elem) => (
                                        <MenuItem
                                            value={elem.id}
                                            key={elem.id}
                                            style={{
                                                // color: 'white'
                                                // padding: "5px 10px",
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            {renderImageSizeString(elem.id)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* { id: 1, width: 512, height: 512, title: "Square" },
    { id: 2, width: 768, height: 512, title: "Horizontal" },
    { id: 3, width: 512, height: 768, title: "Vertical" }, */}

                            <Box sx={resizeContainer}>
                                <Button
                                    // variant={imageSize === 1 ? "contained" : 'outlined' }
                                    // variant="text"
                                    onClick={() => setImageSize(1)}
                                    sx={
                                        imageSize === 1
                                            ? activebtnstyle
                                            : btnstyle
                                    }
                                >
                                    Square
                                </Button>
                                <Button
                                    // variant={imageSize === 2 ? "contained" : 'outlined' }
                                    onClick={() => setImageSize(2)}
                                    sx={
                                        imageSize === 2
                                            ? activebtnstyle
                                            : btnstyle
                                    }
                                >
                                    Horizontal
                                </Button>
                                <Button
                                    // variant={imageSize === 3 ? "contained" : 'outlined' }

                                    onClick={() => setImageSize(3)}
                                    sx={
                                        imageSize === 3
                                            ? activebtnstyle
                                            : btnstyle
                                    }
                                >
                                    Vertical
                                </Button>
                            </Box>

                            <Button
                                variant="text"
                                onClick={handleExampleClick}
                                sx={{
                                    mb: 1,
                                    marginLeft: ".5rem",
                                    textTransform: "capitalize",
                                    p: "0 10px",
                                    alignSelf: "flex-end",
                                }}
                            >
                                Example
                            </Button>

                            {/* advance toggler... */}
                            {/* <FormControlLabel
                                value={advancedSettingsToggler}
                                control={
                                    <Switch color="primary" size="small" />
                                }
                                label="Advanced Settings"
                                labelPlacement="start"
                                onChange={handleAdvancedSettingsToggler}
                            /> */}
                            <Button
                                id="autocomplete"
                                variant="text"
                                // onClick={handleExampleClick}
                                endIcon={
                                    advancedSettingsToggler ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )
                                }
                                onClick={handleAdvancedSettingsToggler}
                                sx={{
                                    mb: 1,
                                    marginLeft: ".5rem",
                                    textTransform: "capitalize",
                                    p: "0 10px",
                                    alignSelf: "flex-end",
                                }}
                            >
                                Advanced Settings
                            </Button>
                        </Box>

                        {advancedSettingsToggler ? (
                            <Settings />
                        ) : // <Stack
                        //     spacing={2}
                        //     direction="row"
                        //     mt={2}
                        //     sx={{ alignItems: "center" }}>
                        //     <Box>
                        //         {/* Select Folder Name */}
                        //         <FormControl
                        //             sx={{
                        //                 minWidth: 100,
                        //                 color: "white",
                        //                 width: "fit-content",
                        //             }}
                        //             variant="standard"
                        //             size="small">
                        //             <InputLabel id="folder-name-select-label">
                        //                 Folder Name
                        //             </InputLabel>
                        //             <Select
                        //                 labelId="folder-name-select-label"
                        //                 id="folder-name-select"
                        //                 value={currentFolder}
                        //                 label="Image Size"
                        //                 sx={{
                        //                     // padding: '5px 10px',
                        //                     fontSize: "10px",
                        //                     // maxWidth: 400
                        //                 }}
                        //                 renderValue={(value) => (
                        //                     <Typography>
                        //                         {/* {renderImageSizeString(value)}
                        //                          */}
                        //                         {value}
                        //                     </Typography>
                        //                 )}
                        //                 onChange={handleFolderChangeClick}>
                        //                 {folders.map((elem) => (
                        //                     <MenuItem
                        //                         value={elem.title}
                        //                         key={elem.id}
                        //                         style={{
                        //                             // color: 'white'
                        //                             padding: "5px 10px",
                        //                         }}>
                        //                         {/* {renderImageSizeString(elem.id)}
                        //                          */}
                        //                         {elem.title}
                        //                     </MenuItem>
                        //                 ))}
                        //             </Select>
                        //         </FormControl>

                        //         {/* Add Folder */}
                        //         <IconButton
                        //             aria-label="delete"
                        //             size="small">
                        //             <NoteAddIcon fontSize="small" />
                        //         </IconButton>
                        //     </Box>
                        // </Stack>
                        null}

                        {imageGenerating ? (
                            <Box sx={{ width: "100%", my: 2 }}>
                                <LinearProgress />
                            </Box>
                        ) : null}
                    </form>
                </BorderedBox>
            </Container>

            <Box sx={{ width: "100%", px: 2 }}>
                <Box
                    sx={{
                        padding: "0",
                        borderRadius: "inherit",
                        margin: "2rem auto 0 auto",
                        width: "90%",
                    }}
                >
                    <MemoizedDisplayImages
                        imageinstructRefhandler={imageinstructRefhandler}
                        setSelectedImageMaineFile={setSelectedImageMaineFile}
                        imageEraseRefhandler={imageEraseRefhandler}
                    />
                </Box>

                {generatedImage.length !== 0 ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <OptionSelected
                            optionItems={optionItems}
                            optionSelected={optionSelected}
                            setOptionSelected={setOptionSelected}
                            styleConfigProp={styleConfigProp}
                        />
                        {/* <Tabs
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                            value={currentTabValue}
                            onChange={changeCurrentTabValue}
                            aria-label="disabled tabs example"
                        >
                            <Tab
                                label="Templates"
                                sx={{
                                    minWidth: "50%",
                                    textTransform: "capitalize",
                                    borderBottom: "1px solid lightgray",
                                }}
                            />
                            
                            <Tab
                                label="Generated Images"
                                sx={{
                                    minWidth: "50%",
                                    textTransform: "capitalize",
                                    borderBottom: "1px solid lightgray",
                                }}
                            />
                        </Tabs> */}
                    </Box>
                ) : null}

                {/* {generatedImage ? 
                    <Box>
                        <ButtonGroup
                                sx={{
                                    display : "flex",
                                    justifyContent : "center",
                                
                                }}
                                aria-label="frequency-change"
                                disableFocusRipple
                                disableRipple
                                disableElevation>
                            <Button 
                                onClick={handleTabSelectionClick} 
                                className="community"
                                sx={{
                                    width: '20%'
                                }}
                                variant={currentTab === "lexical" ? "contained" : "outlined"}
                                >Community Images</Button>
                            <Button 
                                onClick={handleTabSelectionClick} 
                                className="generated"
                                sx={{
                                    width: '20%'
                                }}
                                variant={currentTab === "generated" ? "contained" : "outlined"}
                            >Generated Images</Button>
                        </ButtonGroup>
                    </Box> 
                : null} */}

                {/* {currentTab === "lexical" ? (
                    <DisplayLexicaImages
                        setSelectedImageMaineFile={setSelectedImageMaineFile}
                        image2imageRefhandler={image2imageRefhandler}
                        imageinstructRefhandler={imageinstructRefhandler}
                        imageVariationRefhandler={imageVariationRefhandler}
                        setImage2ImageState={setImage2ImageState}
                        setImageVariationState={setImageVariationState}
                        images={lexicaImages}
                        setImages={setImages}
                        credits={credits}
                        copyPrompt={false}
                        tryThis={true}
                        handlePromptSelection={handlePromptSelection}
                        openLightBox={openLightBox}
                        user={user}
                    />
                ) : (
                    <DisplayGeneratedImages
                        // images={generatedImage}
                        setImages={setImages}
                        credits={credits}
                        copyPrompt={false}
                        tryThis={true}
                        handlePromptSelection={handlePromptSelection}
                        openLightBox={openLightBox}
                        user={user}
                    ></DisplayGeneratedImages>
                )} */}
                {optionSelected === "Templates" ? (
                    <TemplatesNew user={user} />
                ) : (
                    <DisplayGeneratedImages
                        // images={generatedImage}
                        setImages={setImages}
                        credits={credits}
                        copyPrompt={false}
                        tryThis={true}
                        handlePromptSelection={handlePromptSelection}
                        openLightBox={openLightBox}
                        user={user}
                    ></DisplayGeneratedImages>
                )}
                {/* {currentTab === "templates" ? (
                    <Templates user={user} />
                ) : (
                    <DisplayGeneratedImages
                        // images={generatedImage}
                        setImages={setImages}
                        credits={credits}
                        copyPrompt={false}
                        tryThis={true}
                        handlePromptSelection={handlePromptSelection}
                        openLightBox={openLightBox}
                        user={user}
                    ></DisplayGeneratedImages>
                )} */}
            </Box>
            <Modal
                open={openCustomeTemplModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor
                        ligula.
                    </Typography>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

const resizeContainer = { display: "none" };

const boxStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};

const btnstyle = {
    padding: "2px 10px",
    borderRadius: "1px",
    boxShadow: "none",
    // background: 'linear-gradient(to right, rgb(100 136 255), rgb(158 121 249))',
    m: 0,
    textTransform: "capitalize",

    alignSelf: "flex-end",
    border: "1px solid rgb(100 136 255)",
};

const activebtnstyle = {
    border: "1px solid rgb(100 136 255)",
    padding: "2px 10px",
    borderRadius: "1px",
    boxShadow: "none",
    background: "linear-gradient(to right, rgb(100 136 255), rgb(158 121 249))",
    color: "#fff",
    m: 0,
    textTransform: "capitalize",

    alignSelf: "flex-end",
};
