import React, { useState } from "react";
import {
    Typography,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UseCasePromptsBox = ({
    currentActivePromptArr,
    handlePromptChange,
    activeUseCase,
}) => {
    console.log(currentActivePromptArr, "currentActivePromptArr");
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState(
        currentActivePromptArr[0]
    );
    const handleSelectChange = (event, item) => {
        setSelectedValue(event.target.value);

        handlePromptChange(event.target.value);
    };

    return (
        <Box
            sx={{
                width: { xs: "100%", md: "20%" },
                display: "flex",
                flexDirection: "column",
                gap: "1.2rem",
                height: { xs: "fit-content", md: "50vh" },
                overflowY: "scroll",
                overflowX: "hidden",
                alignItems: "center",
                padding: "0.5rem 0",
            }}
        >
            <Typography
                sx={{
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    textAlign: "left",
                    width: { xs: "100%", md: "90%" },
                }}
            >
                {activeUseCase}
            </Typography>{" "}
            <Select
                value={selectedValue}
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                required
                sx={{
                    width: { xs: "100%", md: "90%" },
                    display: { xs: "block", md: "none" },
                }}
            >
                {currentActivePromptArr.map((item, index) => (
                    <MenuItem value={item.promptText} key={index}>
                        {item.promptText}
                    </MenuItem>
                ))}
            </Select>
            {/* {currentActivePromptArr && (

                <Select
                    value={selectedValue}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    required
                >
                    {currentActivePromptArr.map((item, index) => (
                        <MenuItem value={item} key={index}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            )} */}
            {currentActivePromptArr.map((item, index) => (
                <Box
                    sx={{
                        borderRadius: "8px",
                        boxShadow:
                            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                        transition: "all 0.1s ease-in",
                        "&:hover": {
                            boxShadow:
                                theme.palette.mode === "light"
                                    ? "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;"
                                    : "rgba(145, 158, 171, 0.4) 0px 0px 2px 0px, rgba(145, 158, 171, 0.3) 0px 12px 24px 0px;",
                            transition: "all 0.1s ease-in-out",
                            transform: "scale(1.04)",
                        },
                        padding: "0.7rem 1.4rem",
                        width: "90%",
                        cursor: "pointer",
                        display: { xs: "none", md: "block" },
                    }}
                    onClick={() => handlePromptChange(item.promptText)}
                >
                    <Typography>{item.promptText}</Typography>
                </Box>
            ))}
        </Box>
    );
};

export default UseCasePromptsBox;
