import React, { useState, useEffect, useContext } from "react";
import {
    Typography,
    Box,
    Link,
    Button,
    IconButton,
    Popover,
    MenuItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import { handleLogout } from "../../firestore/authHandlers";
import {
    HiOutlineUser,
    HiOutlineMenuAlt3,
    HiOutlineX,
    HiCurrencyDollar,
} from "react-icons/hi";
import { ColorModeContext } from "../../theme";
import imgsrc from "../../imgs/aiseo-symble.png";
import { navItemsData } from "./navItemsData";
import { profileMenuItemsData } from "./profileMenuItemsData";
import { useTheme } from "@mui/material/styles";
import { useAPI } from "../../StateProvider";
import NewMobileMenu from "./NewMobileMenu";

const NavBarV3 = () => {
    const { data } = useAPI();

    const [user, loading] = useAuthState(auth);
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const [subMenuEl, setSubMenuEl] = useState(null);
    const [subMenuElTwo, setSubMenuElTwo] = useState(null);
    const [pSubMenuEl, setPSubMenuEl] = useState(null);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(
        window.innerWidth < 900
    );

    let customLink = "";

    if (location.pathname === "/video") {
        customLink = "/pricing?active=video";
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobileDevice(window.innerWidth < 900);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleLoginNav = () => {
        navigate("../login");
    };

    const handleMobileMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleMenuOneOpen = (event) => {
        setSubMenuEl(event.currentTarget);
    };

    const handleMenuOneClose = () => {
        setSubMenuEl(null);
    };

    const handleMenuTwoOpen = (event) => {
        setSubMenuElTwo(event.currentTarget);
    };

    const handleMenuTwoClose = () => {
        setSubMenuElTwo(null);
    };

    const handleProfileMenuOpen = (event) => {
        setPSubMenuEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setPSubMenuEl(null);
    };

    const handleLogoutClick = () => {
        const value = localStorage.getItem("dontShowMultistepPopup");
        if (value) {
            localStorage.removeItem("dontShowMultistepPopup");
        }
        handleLogout();
    };

    return (
        <Box
            sx={{
                height: "70px",
                background: "#0F172A",
                color: "white",
                padding: { xs: "0.7rem 0.5rem", md: "0.8rem 6rem" },
                borderBottom: "2px solid #334155",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: "1700px",
                    margin: "0 auto",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "1.8rem",
                            height: "1.8rem",
                            marginRight: "0.5rem    ",
                        }}
                    >
                        <img
                            src={imgsrc}
                            alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            display:
                                location.pathname === "/video"
                                    ? "none"
                                    : "block",
                            fontWeight: "bold",
                            color: "white",
                            fontFamily: "Inter",
                        }}
                    >
                        AISEO
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            display: "block",
                            fontWeight: "bold",
                            color: "white",
                            marginLeft: "0.3rem",
                            fontFamily: "Inter",
                        }}
                    >
                        {location.pathname === "/video" ||
                            location.pathname === "/my-videos"
                            ? "Video Generator"
                            : location.pathname === "/products/headshot"
                                ? "Headshots"
                                : "Headshots"}
                    </Typography>
                </Box>

                {/* {!user || data?.subscription?.type !== "Free" ? ( */}
                {/* {!user || data?.subscription?.type === "Free" ? (
                    <Button
                        sx={{
                            color: "white",
                            borderRadius: "50px",
                            border: "1.5px solid rgba(255, 255, 255, 0.45)",
                            textTransform: "capitalize",
                            paddingX: "1.6rem",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            "&:hover": {
                                background: "rgba(255, 255, 255, 0.1)",
                                border: "1.5px solid white",
                            },
                        }}
                        onClick={() => {
                            navigate("/pricing");
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "semi-bold",
                            }}
                        >
                            Upgrade
                        </Typography>
                        <img src={upgradeStarIcon} />
                    </Button>
                ) : (
                    <Box>asd</Box>
                )} */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <IconButton
                        sx={{
                            background: "#6363FF",
                            color: "white",
                            border: "2px solid #6363FF",
                            width: "2.4rem",
                            height: "2.4rem",
                            "&:hover": {
                                background: "#6363FF",
                                color: "white",
                            },
                            borderRadius: "0.3rem",
                            display: { sm: "block", md: "none" },
                        }}
                        onClick={handleMobileMenuClick}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            {isMobileDevice && isMobileMenuOpen ? (
                                <HiOutlineX />
                            ) : (
                                <HiOutlineMenuAlt3 />
                            )}
                        </Box>
                    </IconButton>

                    <Box
                        sx={{
                            /*  display: "flex", */
                            alignItems: "center",
                            gap: "1.3rem",
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <Link
                            sx={{
                                color: "rgba(255, 255, 255, 0.8)",
                                "&:hover": {
                                    color: "white",
                                },
                                textDecoration: "none",
                                cursor: "pointer",
                                display:
                                    location.pathname === "/products/headshot"
                                        ? "inline-flex"
                                        : "none",
                            }}
                            href="/my-headshots"
                        >
                            <Typography>History</Typography>
                        </Link>
                        <Link
                            sx={{
                                color: "rgba(255, 255, 255, 0.8)",
                                "&:hover": {
                                    color: "white",
                                },
                                textDecoration: "none",
                                cursor: "pointer",
                                display:
                                    location.pathname === "/video"
                                        ? "inline-flex"
                                        : "none",
                            }}
                            href="/my-videos"
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                }}
                            >
                                My Videos
                            </Typography>
                        </Link>
                        <Link
                            sx={{
                                color: "rgba(255, 255, 255, 0.8)",
                                "&:hover": {
                                    color: "white",
                                },
                                textDecoration: "none",
                                cursor: "pointer",
                                display:
                                    location.pathname === "/my-videos"
                                        ? "inline-flex"
                                        : "none",
                            }}
                            href="/video"
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                }}
                            >
                                Generate Videos
                            </Typography>
                        </Link>
                        <Link
                            sx={{
                                color: "white",
                                /* color: "rgba(255, 255, 255, 0.8)",
                                "&:hover": {
                                    color: "white",
                                }, */
                                textDecoration: "none",
                                cursor: "pointer",
                                display: data?.hasOwnProperty("credits")
                                    ? "flex"
                                    : "none",
                                alignItems: "center",
                                gap: "0.2rem",
                            }}
                        >
                            <HiCurrencyDollar size={20} color="#6366F1" />
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                }}
                            >
                                {location.pathname === "/products/headshot" || location.pathname === "/my-headshots"
                                    ? data?.credits
                                    : data?.vid_credits
                                        ? data?.vid_credits
                                        : 0}{" "}
                                credits
                            </Typography>
                        </Link>
                        <React.Fragment>
                            {
                                <IconButton
                                    sx={{
                                        background: "white",
                                        /* background: "#6DDAF8", */
                                        color: "#281142",
                                        border: "2px solid lightgrey",
                                        width: "2.5rem",
                                        height: "2.5rem",
                                        "&:hover": {
                                            background: "#6363FF",
                                            color: "white",
                                        },
                                    }}
                                    onClick={handleProfileMenuOpen}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        {user ? (
                                            <img
                                                src={user.photoURL}
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                    scale: "1.7",
                                                    borderRadius: "50%", // Applying the parent's border radius
                                                }}
                                                alt="User Profile"
                                            />
                                        ) : (
                                            <HiOutlineUser />
                                        )}
                                    </Box>
                                </IconButton>
                            }
                            <Popover
                                open={Boolean(pSubMenuEl)}
                                anchorEl={pSubMenuEl}
                                onClose={handleProfileMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                PaperProps={{
                                    elevation: 1,
                                    sx: {
                                        /* display: "flex", */
                                        display: { sm: "none", md: "flex" },

                                        flexDirection: "column",
                                        p: "0.7rem",
                                        borderRadius: "0.7rem",
                                        border: "1.5px solid #D6D2DA",
                                    },
                                }}
                            >
                                {profileMenuItemsData.map(
                                    (subProfileItem, subProfileIndex) => (
                                        <MenuItem
                                            key={subProfileIndex}
                                            component={Link}
                                            href={subProfileItem.to}
                                            underline="none"
                                            onClick={handleProfileMenuClose}
                                            sx={{
                                                backgroundColor: "#E5E5FA",
                                                color: "#281142",
                                                margin: "0.5rem",
                                                borderRadius: "0.5rem",
                                                "&:hover": {
                                                    backgroundColor: "#D3D3FF",
                                                },
                                            }}
                                        >
                                            {subProfileItem.title}
                                        </MenuItem>
                                    )
                                )}
                                {user ? (
                                    <MenuItem
                                        component={Button}
                                        underline="none"
                                        onClick={handleLogoutClick}
                                        sx={{
                                            backgroundColor: "#6363FF",
                                            color: "white",
                                            margin: "0.5rem",
                                            borderRadius: "0.5rem",
                                            "&:hover": {
                                                backgroundColor: "#5050D6",
                                            },
                                        }}
                                    >
                                        Logout
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        component={Button}
                                        underline="none"
                                        onClick={handleLoginNav}
                                        sx={{
                                            backgroundColor: "#6363FF",
                                            color: "white",
                                            margin: "0.5rem",
                                            borderRadius: "0.5rem",
                                            "&:hover": {
                                                backgroundColor: "#5050D6",
                                            },
                                        }}
                                    >
                                        Login
                                    </MenuItem>
                                )}
                            </Popover>
                        </React.Fragment>
                    </Box>
                </Box>
            </Box>
            {isMobileMenuOpen && isMobileDevice && (
                <NewMobileMenu
                    navItemsData={navItemsData}
                    profileMenuItemsData={profileMenuItemsData}
                    handleLoginNav={handleLoginNav}
                    handleLogout={handleLogoutClick}
                    user={user}
                    setIsMobileMenuOpen={setIsMobileDevice}
                    theme={theme}
                    data={data}
                />
            )}
        </Box>
    );
};

export default NavBarV3;
