import {
    HiOutlinePause,
    HiOutlineVolumeUp,
    HiOutlineVolumeOff,
    HiOutlineDownload,
    HiOutlineArrowsExpand,
} from "react-icons/hi";
import React, { useState, useRef, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { Box, IconButton, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import "./VideoPlayer.css";

const ReelVideoPlayer = ({ generatedVideoSrc }) => {
    const videoRef = useRef(null);
    const fullSizeBtnRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(100);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    const togglePlay = () => {
        console.log("play");
        if (videoRef.current.paused) {
            console.log("play");

            videoRef.current.play();
            setIsPlaying(true);
        } else {
            console.log("pause");

            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const toggleMute = () => {
        if (videoRef.current.muted) {
            videoRef.current.muted = false;
            setIsMuted(false);
        } else {
            videoRef.current.muted = true;
            setIsMuted(true);
        }
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
        videoRef.current.volume = newValue / 100;
    };

    const handleTimeUpdate = () => {
        const current = videoRef.current.currentTime;
        const dur = videoRef.current.duration;
        setCurrentTime(current);
        setDuration(dur);
        setProgress((current / dur) * 100);
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    // Formatting time to display
    const formatTime = (seconds) => {
        const result = new Date(seconds * 1000).toISOString().substr(11, 8);
        return result.startsWith("00:") ? result.substr(3) : result;
    };

    const handleProgressChange = (event, newValue) => {
        setProgress(newValue);
        videoRef.current.currentTime =
            (newValue / 100) * videoRef.current.duration;
    };

    /* const handleTimeUpdate = () => {
        const currentTime =
            (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setProgress(currentTime);
    }; */

    const handleDownload = () => {
        // Implement download logic here
        saveAs(generatedVideoSrc);
    };

    const wrapperStyle = {
        position: "relative",
        width: "100%", // Full width of the parent
        paddingTop: "177.77%", // 16:9 aspect ratio (16 / 9 = 177.77%)
        borderRadius: "1.5rem",
    };

    const videoStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    };

    /* return (
        <div style={wrapperStyle}>
            <video style={videoStyle} controls>
                <source
                    src="https://storage.googleapis.com/code-translate-c9640.appspot.com/idea2motion/SwLlZJATQiv1u4Hr8hGN.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    ); */

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                paddingTop: "177.77%",
                borderRadius: "1.5rem",
                overflow: "hidden",
            }}
        >
            <video
                ref={videoRef}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "inherit",
                }}
                onTimeUpdate={handleTimeUpdate}
                onEnded={() => setIsPlaying(false)}
                onLoadedMetadata={() => setDuration(videoRef.current.duration)}
            >
                <source src={generatedVideoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    background: "rgba(30, 41, 59, 0.3)",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    padding: "0.5rem",
                }}
            >
                <IconButton onClick={handleDownload} sx={{ color: "white" }}>
                    <HiOutlineDownload size={28} />
                </IconButton>

                <IconButton onClick={toggleFullscreen} sx={{ color: "white" }}>
                    <HiOutlineArrowsExpand size={28} />
                </IconButton>
            </Box> */}
            <IconButton
                onClick={handleDownload}
                sx={{
                    color: "white",
                    background: "rgba(30, 41, 59, 0.5)",
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    "&:hover": {
                        background: "rgba(30, 41, 59, 0.8)",
                    },
                }}
            >
                <HiOutlineDownload size={25} />
            </IconButton>

            <IconButton
                onClick={toggleFullscreen}
                ref={fullSizeBtnRef}
                sx={{
                    color: "white",
                    background: "rgba(30, 41, 59, 0.5)",
                    position: "absolute",
                    top: "8px",
                    left: "8px",
                    "&:hover": {
                        background: "rgba(30, 41, 59, 0.8)",
                    },
                }}
            >
                <HiOutlineArrowsExpand size={25} />
            </IconButton>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    background: "rgba(30, 41, 59, 0.5)",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    padding: "0.5rem",
                    justifyContent: "space-between",
                }}
            >
                {/* Video Controls (Play/Pause, Volume, Progress Slider, Download, etc.) */}
                {/* Video Controls */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.4rem",
                    }}
                >
                    <IconButton onClick={togglePlay} sx={{ color: "white" }}>
                        {isPlaying ? (
                            <HiOutlinePause size={28} />
                        ) : (
                            <img
                                src="/assets/vidPlayIcon.svg"
                                style={{ width: "28px", height: "28px" }}
                            />
                        )}
                    </IconButton>

                    <Slider
                        value={progress}
                        onChange={handleProgressChange}
                        min={0}
                        max={100}
                        aria-label="Video Progress"
                        sx={{ color: "white", width: "120px" }} // Adjust the width as needed
                    />
                </Box>
                {/* <Typography variant="body2">
                    {progress.toFixed(2).replace(".", " : ")}
                </Typography> */}

                <Typography
                    variant="body2"
                    sx={{ color: "white", whiteSpace: "nowrap" }}
                >
                    {formatTime(currentTime)} / {formatTime(duration)}
                </Typography>

                {/* Fullscreen Button */}

                <IconButton onClick={toggleMute} sx={{ color: "white" }}>
                    {isMuted ? (
                        <HiOutlineVolumeOff size={28} />
                    ) : (
                        <HiOutlineVolumeUp size={28} />
                    )}
                </IconButton>

                {/* Optional: Volume Slider (if you want a separate slider for volume control) */}
                {/* <Slider
    value={volume}
    onChange={handleVolumeChange}
    min={0}
    max={100}
    aria-label="Volume"
    sx={{ color: "white", width: "100px" }}  // Adjust the width as needed
/> */}
            </Box>
        </div>
    );
};

export default ReelVideoPlayer;
