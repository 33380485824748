import React from "react";
import { Typography, Box, Button, IconButton, Dialog } from "@mui/material";
import { HiOutlineX } from "react-icons/hi";
import avatarGroupsImg from "../../imgs/avatarGroupsImg.svg";
import startTrialPopupShowcaseImage from "../../imgs/startTrialPopupShowcaseImage.png";
import popupShowcaseTrialMobileImg from "../../imgs/popupShowcaseTrialMobileImg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ToastNotification from "../atoms/ToastNotification";

const NeedToBuyAPlan = ({
    lockWarning,
    setLockWarning,
    resetProhibitedState,
    type,
    showUserProhibitedMessage,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(type, "TYPE");
    let linkToPricing = "../pricing";
    const handleAgree = () => {
        setLockWarning(false);
        if (location.pathname === "/tools") {
            resetProhibitedState();
        }
        if (location.pathname === "/video") {
            linkToPricing = "/pricing?active=video";
        }
        if (location.pathname === "/ai-avatars") {
            linkToPricing = "/ai-avatars-pricing";
        }

        navigate(linkToPricing);
    };
    return (
        <Dialog
            keepMounted
            sx={{
                p: 1,
                "& > div > div": {
                    background:
                        theme.palette.mode === "light" ? "white" : "#1E293B",
                    borderRadius: "0.5rem",
                    maxWidth: { xs: "100vw", md: "70vw" },
                    width: { xs: "100vw", md: "fit-content" },
                    overflowY: window.innerWidth < 321 ? "scroll" : "hidden",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxHeight: { xs: "98vh", md: "90vh" },
                    position: "relative",
                    margin: "0px",
                },
                "& > div": {
                    padding: "0",
                },
                background: "rgba(82, 68, 132, 0.5)",
                backdropFilter: "blur(1px)",
            }}
            open={lockWarning}
            aria-describedby="alert-dialog-slide-description"
        >
            {showUserProhibitedMessage && (
                <ToastNotification
                    message="Its a Pro feature. Please upgrade!"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    padding: { xs: "0", md: "4rem" },
                    gap: { xs: "0px", md: "160px" },
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "90%", md: "50%" },
                        height: { xs: "97vh", md: "fit-content" },
                        paddingTop: { xs: "2rem", md: "0" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xs: "center", md: "start" },
                        }}
                    >
                        <Typography
                            sx={{
                                color:
                                    theme.palette.mode === "light"
                                        ? "#0F172A"
                                        : "white",
                                fontSize: { xs: "1.6rem", md: "40px" },
                                width: { xs: "fit-content", md: "373px" },
                                lineHeight: { xs: "1.7rem", md: "50px" },
                                fontWeight: "800",
                                letterSpacing: "-0.1rem",
                                marginBottom: { xs: "0.5rem", md: "1rem" },
                            }}
                        >
                            Unleash Your Inner Artist!
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "bold",
                                color:
                                    theme.palette.mode === "light"
                                        ? "#0F172A"
                                        : "#DBE2EA",
                            }}
                        >
                            Join today and get a 3-day free trial with unlimited
                            access to all premium features. No strings attached.
                        </Typography>
                        <Box
                            sx={{
                                width: "260px",
                                height: "210px",
                                display: { xs: "block", md: "none" },
                                marginTop: "0.5rem",
                            }}
                        >
                            <img
                                alt=""
                                src={popupShowcaseTrialMobileImg}
                                style={{ width: "100%", height: "100%" }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            margin: "24px 0",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.9rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🚀</span>
                            <Box>
                                <Typography
                                    sx={{
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Instant Art Generation
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Create mesmerizing artworks in seconds.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🧠</span>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Smart Prompts
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Feed our AI your ideas, and it'll guide you
                                    with creative prompts, pushing your artistry
                                    to new horizons.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🎨</span>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    Full Customization
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Tailor your creations to your unique style
                                    and preference.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "8px",
                                fontSize: { xs: "0.9rem", md: "16px" },
                            }}
                        >
                            <span>🤖</span>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        fontWeight: "bold",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "white",
                                        fontSize: { xs: "0.9rem", md: "16px" },
                                    }}
                                >
                                    User-Friendly Interface
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "#0F172A"
                                                : "#BDC6CE",
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    Even if you're new to digital art, our
                                    intuitive UI ensures a smooth journey.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            background:
                                theme.palette.mode === "light"
                                    ? "#1E293B"
                                    : "#0F172A",
                            color: "#F8FAFC",
                            padding: "0.9rem",
                            borderRadius: "10px",
                            display: { xs: "none", md: "flex" },
                            gap: "8px",
                        }}
                    >
                        <span>⏰</span>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                Time-Sensitive Offer
                            </Typography>
                            <Typography>
                                The art world waits for no one. Dive in now and
                                grab your exclusive 3-day free trial with
                                unrestricted access to all our features.{" "}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column-reverse", md: "row" },
                            gap: { xs: "0.7rem", md: "68px" },
                            margin: "1.34rem 0 0 0",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            /* variant="contained"
                            size="small" */
                            /* onClick={handleAgree} */
                            sx={{
                                background: "#4F46E5",
                                borderRadius: "30px",
                                whiteSpace: "nowrap",
                                color: "white",
                                fontSize: "14px",
                                fontWeight: "bold",
                                textTransform: "capitalize",

                                "&:hover": {
                                    background: "#4949C0",
                                    transition: "all 0.15s ease-in",
                                },
                                fontFamily: "Inter",
                            }}
                            onClick={handleAgree}
                        >
                            <Typography sx={{ padding: "6px 18px" }}>
                                {" "}
                                {location.pathname === "/ai-avatars"
                                    ? "Purchase"
                                    : "Start Free Trial"}
                            </Typography>
                        </Button>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                            }}
                        >
                            <Box sx={{ width: "157px" }}>
                                <img
                                    alt=""
                                    src={avatarGroupsImg}
                                    style={{ width: "100%" }}
                                />
                            </Box>

                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    color:
                                        theme.palette.mode === "light"
                                            ? "#0F172A"
                                            : "white",
                                    fontWeight: "bold",
                                    maxWidth: "200px",
                                }}
                            >
                                250,000+ people already loved our tools.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        maxWidth: "350px",
                        height: "640px",
                        display: { xs: "none", md: "block" },
                    }}
                >
                    <img
                        alt=""
                        src={startTrialPopupShowcaseImage}
                        style={{ width: "100%", height: "100%" }}
                    />
                </Box>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: { xs: "5px", md: "2rem" },
                        right: { xs: "5px", md: "2rem" },
                        border: "1.5px solid transparent",
                        "&:hover": {
                            border: "1.5px solid grey",
                            transition: "all 0.15s ease-in",
                        },
                    }}
                    onClick={handleAgree}
                >
                    <HiOutlineX size={25} />
                </IconButton>
            </Box>
        </Dialog>
    );
};

export default NeedToBuyAPlan;
