export const heroButtonsData = [
    {
        title: "Art Generator",
        link: "/products/art-generator",
        type: "image",
        isNew: false,
        desc: "Generate stunning arts effortlessly with this tool.",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/5ea82eb4-5b19-4ba4-8e72-145ebc74645e",
        bgPosition: "top",
    },
    {
        title: "Advertisement Product",
        link: "/products/advertisement-product",
        type: "image",
        isNew: false,
        desc: "Create stunning product images effortlessly with this tool.",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/e29ed69b-a837-4645-8580-cedcbb1e073d",
        bgPosition: "center",
    },
    {
        title: "Image Variation",
        link: "/products/image-variation",
        type: "image",
        isNew: false,
        desc: "Create stunning image variations effortlessly with our cutting-edge tool. ",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/landingImages/variationDemoImageLanding.png",
        bgPosition: "center",
    },
    {
        title: "Instruct",
        link: "/products/instruct",
        type: "image",
        isNew: false,
        desc: "Instruct your image to change in any way possible with styling and dynamics.",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/landingImages/instructImgDemo.png",
        bgPosition: "center",
    },
    {
        title: "Erase & Replace",
        link: "/products/erase-replace",
        type: "image",
        isNew: false,
        desc: "Erase parts of the images you upload and replace with anything you need",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/landingImages/eraseReplaceDemoImg.png",
        bgPosition: "center",
    },
    {
        title: "Video Generator",
        link: "/video",
        type: "video",
        isNew: true,
        desc: "Generate videos with utlimate flexiblity at your ease.",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/5ea82eb4-5b19-4ba4-8e72-145ebc74645e",
        bgPosition: "top",
    },
    {
        title: "Tools",
        link: "/tools",
        type: "tools",
        isNew: true,
        desc: "Next generation image tools at your service for your daily needs",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/landingImages/toolsDemoImg.png",
        bgPosition: "center",
    },
    {
        title: "Templates",
        link: "/templates",
        type: "tools",
        isNew: true,
        desc: "Curate templates for generating images of trending categories.",
        btnText: "Generate",
        imageSrc:
            "https://storage.googleapis.com/code-translate-c9640.appspot.com/landingImages/templatesDemoPic.png",
        bgPosition: "center",
    },
    /* {
        title: "Advertisement Product",
        link: "/advertisement-product",
    },
    {
        title: "Advertisement Product",
        link: "/advertisement-product",
    },
    {
        title: "Advertisement Product",
        link: "/advertisement-product",
    },
    {
        title: "Advertisement Product",
        link: "/advertisement-product",
    },
    {
        title: "Advertisement Product",
        link: "/advertisement-product",
    }, */
];
