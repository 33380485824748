import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inputImage: "",
    generating: false,
    outputs: [],
    errorMessage: "",
    canvasWidth: 0,
    canvasHeight: 0
};

export const Draggan = createSlice({
    name: "adProduct",
    initialState,
    reducers: {
        imageUploaded: (state, action) => {
            state.errorMessage = "";
            state.inputImage = action.payload.inputImage;
            state.outputs = [];
            state.generating = false;
            state.canvasHeight = action.payload.canvasHeight;
            state.canvasWidth = action.payload.canvasWidth;
        },
        // changeInputs: (state, action) => {
        //     state.promptText = action.payload.text;
        //     state.imgNum = action.payload.num;
        // },
        // stateChanged: (state, action) => {
        //     state[action.payload.name] = action.payload.value;
        //     state.outputs = [];
        // },
        // generationStarted: (state) => {
        //     state.outputs = [];
        //     state.errorMessage = "";
        //     state.generating = true;
        //     state.isLoading = true;
        // },
        // generationSucceeded: (state, action) => {
        //     state.outputs = action.payload.outputs;
        //     state.allImages = [...state.allImages, action.payload.outputs];
        //     state.generating = false;
        //     state.isLoading = false;
        // },
        // generationFailed: (state, action) => {
        //     state.outputs = [];
        //     state.generating = false;
        //     state.errorMessage = action.payload.errorMessage;
        //     state.isLoading = false;
        // },
    },
});

export const {
    imageUploaded,
    // stateChanged,
    // generationStarted,
    // generationSucceeded,
    // generationFailed,
    // changeInputs,
} = Draggan.actions;
export default Draggan.reducer;
