import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Headshot.css";
import {
    Typography,
    Box,
    Button,
    IconButton,
    MenuItem,
    FormControl,
    Select,
    CircularProgress,
    TextField,
} from "@mui/material";
import {
    HiOutlineX,
} from "react-icons/hi";
// import headshotGenExampleImage from "../../imgs/headshotGenExampleImage.png";
// import circleRegenrateIcon from "../../imgs/circleRegenrateIcon.png";
// import arrowTopIconHeadshotPitch from "../../imgs/arrowTopIconHeadshotPitch.svg";
// import camFlashIconHeadshotPitch from "../../imgs/camFlashIconHeadshotPitch.svg";
// import arrSvg from "../../imgs/arrSvg.svg";
// import { saveAs } from "file-saver";
import {
    collection,
    getFirestore,
    addDoc,
} from "firebase/firestore";
import { firebaseApp, getFirebaseAuthToken } from "../../firebaseInit";

import ToastNotification from "../atoms/ToastNotification";
import ProGeneratedImages from "./ProGeneratedImages";
import ProGenImages from "./ProGenImages";
import UpgradePopupHeadshot from "./UpgradePopupHeadshot";
import { useAPI } from "../../StateProvider";
import mixpanel from "mixpanel-browser";
import OptionSelected from "../atoms/OptionSelected";


const ProVersionHeadshot = ({ user, credits }) => {
    const db = getFirestore(firebaseApp);



    const { data } = useAPI();
    const [apiResponse, setApiResponse] = useState("");
    const [generationFailedErrorMsg, setGenerationFailedErrorMsg] =
        useState("");
    const [showGenerationFailedError, setShowGenerationFailedError] =
        useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [validationMessage, setValidationMessage] = useState("");
    const [showRunOutOfCreditsError, setShowRunOutOfCreditsError] = useState(false);
    const [showValidationError, setShowValidationError] = useState(false);
    const [showPleaseWaitPopup, setShowPleaseWaitPopup] = useState(false);
    const [showCannotUploadMoreError, setShowCannotUploadMoreError] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showMainError, setShowMainError] = useState(false);
    const [genderInputVal, setGenderInputVal] = useState("");
    const [dragging, setDragging] = useState(false);
    // const [image, setImage] = useState(null);
    // const [imagePreviewUrl, setImagePreviewUrl] = useState("");
    const [images, setImages] = useState([]); // New state for handling multiple images
    const [imagePreviewUrls, setImagePreviewUrls] = useState([]);

    const [generatedImageUrlArr, setGeneratedImageUrlArr] = useState([]);
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const [proGenImagesLoading, setProGenImagesLoading] = useState(false);

    const [newHeadshotsDocId, setNewHeadshotsDocId] = useState("");
    const [albumNameValue, setAlbumNameValue] = useState("");

    const optionItems = ["Headshots", "Albums"];
    const [optionSelected, setOptionSelected] = useState(optionItems[0]);


    useEffect(() => {
        mixpanel.track("Total Visitors", {
            Description:
                "Headshots - page - total visitors",
            /* category: event.target.value, */
        });
    }, [])

    const handleDrop = (e) => {
        e.preventDefault();
        const files = [...e.dataTransfer.files];
        if (images.length + files.length <= 5) {
            setImages((prevImages) => [...prevImages, ...files]);
            setImagePreviewUrls((prevUrls) => [
                ...prevUrls,
                ...files.map((file) => URL.createObjectURL(file)),
            ]);
        } else {
            setShowCannotUploadMoreError(true);
            setTimeout(() => {
                setShowCannotUploadMoreError(false);
            }, 3200);
        }
        setDragging(false);
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        if (images.length + files.length <= 5) {
            setImages((prevImages) => [...prevImages, ...files]);
            setImagePreviewUrls((prevUrls) => [
                ...prevUrls,
                ...files.map((file) => URL.createObjectURL(file)),
            ]);
        } else {
            setShowCannotUploadMoreError(true);
            setTimeout(() => {
                setShowCannotUploadMoreError(false);
            }, 3200);
        }
        e.target.value = null;
    };

    const handleHeadshotGenerateClick = async () => {
        console.log("dsadas");
        if (images.length === 0 || images.length < 4 || genderInputVal === "") {
            setShowValidationError(true);
            if (images.length === 0) {
                setValidationMessage("Please select an image");
            } else if (images.length < 4) {
                setValidationMessage("Please select 4 images");
            } else {
                setValidationMessage("Please select a gender");
            }

            setTimeout(() => {
                setShowValidationError(false);
            }, 3200);
            return;
        }

        const generationQtyPerImage = 4;
        const pricePerImage = 2;
        if (
            data?.credits <
            images.length * generationQtyPerImage * pricePerImage
        ) {
            console.log("Please buy credits");
            setShowUpgradePopup(true);
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        images.forEach((image, index) => {
            formData.append(`image${index + 1}`, image); // Start indexing from 1
        });

        /* images.forEach((image, index) => {
            formData.append(`image${index}`, image);
        }); */
        formData.append("gender", genderInputVal);
        formData.append("uid", user.uid);
        formData.append("userEmail", user.email);
        formData.append("api_type", "generate_multiple_headshots");
        formData.append("isProUser", data?.subscription?.type === "Free" ? false : true);

        console.log("isProUser", data?.subscription?.type === "Free" ? false : true)


        const token = await getFirebaseAuthToken();

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const saveDocId = await saveMultipleHeadshotsDocToDb();

            setCurrentPage(1);
            formData.append("saved_doc_id", saveDocId);
            console.log("saved_doc_id", saveDocId);
            const response = await axios.post(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/API",
                formData,
                config
            );
            // const response = await axios.post(
            //     "http://127.0.0.1:8080/API",
            //     formData,
            //     config
            // );

            // console.log(response.data, "--response.data--");

            // //setCurrentPage(1);

            // setApiResponse(response.data);
        } catch (error) {
            console.error("Error uploading images:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveMultipleHeadshotsToDb = async (headshots) => {
        const current_time = new Date().toLocaleString();

        const heahshotsDocRef = await addDoc(collection(db, "headshots"), {
            gender: genderInputVal,
            /* seed: 0, */
            userEmail: user.email, // replace with the actual user email
            generatedImages: [...headshots], // replace with the actual list of generated images
            timeStamp: current_time,
            status: "completed",
        });

        console.log(
            "Document successfully written with ID: ",
            heahshotsDocRef.id
        );
    };

    const saveMultipleHeadshotsDocToDb = async (headshots) => {
        const current_time = new Date().toLocaleString();

        const headshotsDocRef = await addDoc(collection(db, "headshots"), {
            gender: genderInputVal,
            /* seed: 0, */
            userEmail: user.email, // replace with the actual user email
            generatedImages: [], // replace with the actual list of generated images
            timeStamp: current_time,
            status: "processing",
        });

        console.log(
            "Document successfully written with ID: ",
            headshotsDocRef.id
        );

        setNewHeadshotsDocId(headshotsDocRef.id);

        return headshotsDocRef.id;
    };

    const handleHeadshotGenerateClick2 = async () => {
        const isProUser = data?.subscription?.type === "Free" ? false : true;
        console.log("dsadas");
        if (optionSelected === "Headshots") {
            if (images.length === 0 || images.length < 4 || genderInputVal === "") {
                setShowValidationError(true);
                if (images.length === 0) {
                    setValidationMessage("Please select an image");
                } else if (images.length < 4) {
                    setValidationMessage("Please select 4 images");
                } else {
                    setValidationMessage("Please select a gender");
                }

                setTimeout(() => {
                    setShowValidationError(false);
                }, 3200);
                return;
            }
        } else {
            if (images.length === 0 || images.length < 4 || genderInputVal === "" || albumNameValue === "") {
                setShowValidationError(true);
                if (images.length === 0) {
                    setValidationMessage("Please select an image");
                } else if (images.length < 4) {
                    setValidationMessage("Please select 4 images");
                } else if (albumNameValue === "") {
                    setValidationMessage("Please add a album name");
                } else {
                    setValidationMessage("Please select a gender");
                }

                setTimeout(() => {
                    setShowValidationError(false);
                }, 3200);
                return;
            }
        }



        //const generationQty = isProUser ? 4 : 1;
        //const generationQty = 5;
        const generationQty = optionSelected === "Headshots" ? 5 : 20;
        if (
            data?.credits < generationQty
        ) {

            console.log("Please buy credits");
            setShowUpgradePopup(true);
            return;
        }

        console.log(isProUser ? "Pro" : "free")
        console.log(generationQty, "generationQty")
        console.log("helping text")

        mixpanel.track("Generations headshots", {
            Description:
                "Headshots - page - total generations",
            /* category: event.target.value, */
        });


        setCurrentPage(1);

        setIsLoading(true);

        setProGenImagesLoading(true);

        const token = await getFirebaseAuthToken();

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        console.log("Config--", config);


        const formData = new FormData();
        images.forEach((image, index) => {
            if (index < 4) {
                formData.append(`image${index + 1}`, image); // Start indexing from 1
            }
        });


        formData.append("albumName", optionSelected === "Headshots" ? "" : albumNameValue);
        formData.append("gender", genderInputVal);
        formData.append("uid", user.uid);
        formData.append("userEmail", user.email);
        formData.append("api_type", "generate_multiple_headshots");
        formData.append("isProUser", data?.subscription?.type === "Free" ? false : true);


        try {
            const resp = await axios.post(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/API_headshot_gen/",
                /* "http://127.0.0.1:4000/API_headshot_gen/", */
                /* "https://us-central1-code-translate-c9640.cloudfunctions.net/API", */
                formData,
                config
            );

            const imgOutput = await [...resp.data.data.generated_img_output_url];
            const outputDocId = resp.data.data.saved_doc_id;
            setNewHeadshotsDocId(outputDocId);

            console.log("response min: ", resp);
            console.log("response min: ", false || [...resp.data.data.generated_img_output_url].length < 1);
            setGeneratedImageUrlArr(imgOutput);

            mixpanel.track("Successful Generations Headshots", {
                Description:
                    "Headshots - page - Successful generations",
            });




        } catch (err) {
            console.error("Error generating headshots:", err);
            setShowMainError(true);
            setIsLoading(false);
            setProGenImagesLoading(false);
            setTimeout(() => {
                setShowMainError(false);

            }, 4000)
            resetStates();
            mixpanel.track("Failed Generations Headshots", {
                Description:
                    "Headshots - page - Unsuccessful generations",
            });
        } finally {
            setIsLoading(false);
            setProGenImagesLoading(false);

        }


    };

    const handleGenderInputValChange = (e) => {
        setGenderInputVal(e.target.value);
    };

    const removeUploadedImage = (index) => {
        setImagePreviewUrls((prevUrls) =>
            prevUrls.filter((_, idx) => idx !== index)
        );
        setImages((prevImages) => prevImages.filter((_, idx) => idx !== index));
        document.getElementById("file-input").value = null;
    };

    /* const handleDownloadClick = () => {
        saveAs(generatedImageUrl, "download-image.png");
    }; */

    const resetStates = () => {
        setApiResponse("");
        setGenerationFailedErrorMsg("");
        setShowGenerationFailedError(false);
        setCurrentPage(0);
        setValidationMessage("");
        setShowValidationError(false);
        setIsLoading(false);
        setGenderInputVal("");
        setDragging(false);
        setImages([]);
        setImagePreviewUrls([]);
        setGeneratedImageUrlArr([]);
        setNewHeadshotsDocId("")
        setAlbumNameValue("")
    };

    const closeUpgradePopup = () => {
        setShowUpgradePopup(!showUpgradePopup);
    };

    const handleAlbumNameValueChange = (e) => {
        setAlbumNameValue(e.target.value);
    }

    const styleConfigProp = {
        option: {
            active: {
                bgColor: "#475569",
                textColor: "white",
            },
            inactive: {
                bgColor: "transparent",
                textColor: "white",
            },
        },
        container: {
            lightMode: {
                bgColor: "#1E293B",
                borderColor: "transparent",
                boxShadow: "none",
            },
            darkMode: {
                bgColor: "#1E293B",
                borderColor: "transparent",
                boxShadow: "none",
            },
        },
    };
    return (
        <Box
            sx={{
                width: "100%",
            }}
        >
            {showUpgradePopup && (
                <UpgradePopupHeadshot closeUpgradePopup={closeUpgradePopup} />
            )}
            {showMainError && (
                <ToastNotification
                    message="Error! Please try again."
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showPleaseWaitPopup && (
                <ToastNotification
                    message="Please do not leave the page."
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showValidationError && (
                <ToastNotification
                    message={validationMessage}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showRunOutOfCreditsError && (
                <ToastNotification
                    message="Oops! Just ran out of credits!"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showCannotUploadMoreError && (
                <ToastNotification
                    message="Only 5 images at a time!"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showGenerationFailedError && (
                <ToastNotification
                    message={generationFailedErrorMsg}
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {currentPage === 0 && (
                <Box
                    sx={{
                        background: "#0F172A",
                        color: "white",
                        display: "flex",
                        gap: "7rem",
                        alignItems: "center",
                        /* padding: { xs: "0.7rem 0.5rem", md: "0.8rem 4rem" }, */
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "600px",
                            margin: "0 auto",
                            padding: { xs: "0 0.7rem", md: "0" },
                            width: "100vw",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                /* h1 */
                                fontFamily: "Inter",
                                fontSize: { xs: "30px", md: "48px" },
                                fontStyle: "normal",
                                fontWeight: "800",
                                lineHeight: {
                                    xs: "32px",
                                    md: "55px",
                                } /* 114.583% */,
                                letterSpacing: "-0.576px",
                                textAlign: "center",
                            }}
                        >
                            Create Your{" "}
                            <br />
                            <span style={{ color: "#818CF8" }}>
                                Professional
                            </span>

                            {" "}Headshot
                        </Typography>



                        <Box
                            sx={{
                                margin: {
                                    xs: "48px auto 0 auto",
                                    md: "32px 0 0 0 ",
                                },
                                width: { xs: "80vw", md: "100%" },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: {
                                        xs: "center",
                                        md: "center",
                                    },
                                    margin: "20px 0 0.6rem 0",
                                }}
                            >
                                <OptionSelected optionItems={optionItems} optionSelected={optionSelected} setOptionSelected={setOptionSelected} styleConfigProp={styleConfigProp} />
                            </Box>
                            {
                                optionSelected !== "Headshots" && (
                                    <>
                                        <Typography
                                            sx={{
                                                marginBottom: "0",
                                                fontFamily: "Inter",

                                                fontWeight: "500",
                                                lineHeight: "24px",
                                                /*  opacity: optionSelected !== "Headshots" ? 1 : 0.005,
                                                 transition: "opacity 0.5s ease-in-out", */

                                            }}
                                        >
                                            Album Name
                                        </Typography>

                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Write Your Album Name Here "
                                            sx={{
                                                input: { color: 'white' },

                                                marginTop: "5px",
                                                "& > div": {
                                                    borderRadius: "8px",
                                                    background: "#242E3D",
                                                    border: "1px solid #334155",
                                                },
                                                marginBottom: "1rem",
                                                /* opacity: optionSelected !== "Headshots" ? 1 : 0.005,
                                                transition: "opacity 0.5s ease-in-out", */

                                            }}
                                            value={albumNameValue}
                                            onChange={handleAlbumNameValueChange}

                                        />
                                    </>
                                )
                            }

                            <Typography
                                sx={{
                                    marginBottom: "0",
                                    fontFamily: "Inter",

                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Select Your Gender
                            </Typography>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    marginTop: "5px",
                                    "& > div": {
                                        borderRadius: "8px",
                                        background: "#242E3D",
                                        border: "1px solid #334155",
                                    },
                                }}
                            >
                                <Select
                                    labelId="cfg-val-label"
                                    value={genderInputVal}
                                    onChange={handleGenderInputValChange}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                        },
                                        "& . MuiList-root": {
                                            background: "red !important"
                                        },
                                        color: "white",
                                        "& .MuiSelect-icon": {
                                            // This targets the dropdown icon
                                            color: "white", // Change this to your desired color
                                        },
                                    }}
                                >
                                    <MenuItem value={"man"}>Man</MenuItem>
                                    <MenuItem value={"woman"}>Woman</MenuItem>
                                    {/* {cfgValArr.map((cfg) => (
                            <MenuItem key={cfg} value={cfg}>
                                {cfg}
                            </MenuItem>
                        ))} */}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                margin: {
                                    xs: "1.7rem auto 0 auto",
                                    md: "1.7rem 0 0 0",
                                },
                                width: { xs: "80vw", md: "100%" },
                            }}
                        >
                            <Typography
                                sx={{
                                    marginBottom: "0.4rem",
                                    fontFamily: "Inter",

                                    fontWeight: "500",
                                    lineHeight: "24px",
                                }}
                            >
                                Upload Photos {" "}<span style={{
                                    color: "#94A3B8"
                                }}>(4 photos minimum)</span>
                            </Typography>
                            <Box
                                onDrop={handleDrop}
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={() => setDragging(true)}
                                onDragLeave={() => setDragging(false)}
                                sx={{
                                    background: "#242E3D",
                                    border: "1px solid #334155",
                                    borderRadius: "8px",

                                    /* padding: { xs: "20px", md: "64px" }, */
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderColor: dragging
                                        ? "#6363FF"
                                        : "#334155",
                                    width: "100%",
                                    minHeight: {
                                        xs: "fit-content",
                                        md: "200px",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: { xs: "20px", md: "50px" },
                                }}
                            >
                                {imagePreviewUrls.length > 0 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            gap: "1rem",
                                            width: "100%",
                                            /* flexDirection: {
                                                xs: "col",
                                                md: "row",
                                            }, */
                                        }}
                                    >
                                        {imagePreviewUrls.map((url, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    width: "100px",
                                                    height: "100px",
                                                    position: "relative",
                                                    borderRadius: "1rem",
                                                }}
                                            >
                                                <img
                                                    src={url}
                                                    alt={`Preview ${index}`}
                                                    style={{
                                                        maxWidth: "100%",
                                                        height: "100%",
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{
                                                        background: "#fee2e2",
                                                        color: "#ef4444",
                                                        position: "absolute",
                                                        top: "-8px",
                                                        right: "-8px",
                                                        "&:hover": {
                                                            background:
                                                                "#ef4444",
                                                            color: "white",
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        removeUploadedImage(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <HiOutlineX size={20} />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </Box>
                                ) : (
                                    <>
                                        {/* <label htmlFor="file-input">
                                            <Button
                                                variant="contained"
                                                component="span"
                                                sx={{
                                                    background: "#111827",
                                                    color: "white",
                                                    borderRadius: "7px",
                                                    textTransform: "capitalize",
                                                    padding: "12px 22px",
                                                }}
                                                disableElevation
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                    }}
                                                >
                                                    Upload Image
                                                </Typography>
                                            </Button>
                                        </label>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "14px",
                                                marginTop: "30px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                }}
                                            >
                                                or
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                }}
                                            >
                                                Drag and drop an image
                                            </Typography>
                                        </Box> */}
                                    </>
                                )}
                                <>
                                    <label htmlFor="file-input" style={{
                                        marginTop: "2rem"
                                    }}>
                                        <Button
                                            variant="contained"
                                            component="span"
                                            sx={{
                                                background: "#111827",
                                                color: "white",
                                                borderRadius: "7px",
                                                textTransform: "capitalize",
                                                padding: "12px 22px",
                                            }}
                                            disableElevation
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                }}
                                            >
                                                Upload Image
                                            </Typography>
                                        </Button>
                                    </label>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "14px",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                            }}
                                        >
                                            or
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                            }}
                                        >
                                            Drag and drop an image
                                        </Typography>
                                    </Box>
                                </>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    id="file-input"
                                    onChange={handleFileChange}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                color: "#22C55E",
                                /* p-ui-medium */
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "24px" /* 150% */,
                                textAlign: "center",
                                margin: "0.6rem 0 1rem 0",
                                display: "none"
                            }}
                        >
                            Pro feature: Get 4 high quality headshots at once
                        </Typography>
                        <Box
                            sx={{
                                textAlign: "center",
                                marginTop: "1.2rem"
                            }}
                        >
                            <Button
                                /* component="span" */
                                onClick={handleHeadshotGenerateClick2}
                                sx={{
                                    background: "#6363FF",
                                    color: "white",
                                    borderRadius: "70px",
                                    textTransform: "capitalize",
                                    padding: "12px 27px",
                                    marginTop: "30px auto 0 auto",
                                    /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                                    "&:hover": {
                                        background: "#4E4EC8",
                                        color: "white",
                                    },
                                }}
                                disableElevation
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                    }}
                                >
                                    Generate Headshots
                                </Typography>
                                {isLoading && (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: "white",
                                            marginLeft: "0.7rem",
                                        }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
            {/* {currentPage === 1 && (
                <ProGeneratedImages
                    newHeadshotsDocId={newHeadshotsDocId}
                    
                    user={user}
                    resetStates={resetStates}
                />
            )} */}
            {currentPage === 1 && (
                <ProGenImages
                    generatedImageUrlArr={generatedImageUrlArr}
                    user={user}
                    resetStates={resetStates}
                    proGenImagesLoading={proGenImagesLoading}
                    newHeadshotsDocId={newHeadshotsDocId}
                    albumNameValue={albumNameValue}
                />
            )}
            {/* {currentPage === 1 && (
                <Box>
                    <Box>dasda asd</Box>
                    {proGenImagesLoading ? (
                        <Box>Loading....</Box>
                    ) : (
                        <Box>
                            {generatedImageUrl.map((item, index) => (
                                <Box key={index}>
                                    <img
                                        src={item}
                                        style={{
                                            width: "360px",
                                            height: "540px",
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            )} */}
        </Box>
    );
};

export default ProVersionHeadshot;
