import React, { useState } from "react";
import { Box, Link, Button, Popover, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
    HiChevronDown,
    HiOutlineUserRemove,
    HiOutlineUserAdd,
} from "react-icons/hi";

const MobileMenu = ({
    navItemsData,
    profileMenuItemsData,
    user,
    handleLogout,
    handleLoginNav,
    setIsMobileMenuOpen,
    theme,
}) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [subMenuEl, setSubMenuEl] = useState(null);
    const [subMenuElTwo, setSubMenuElTwo] = useState(null);

    const handleMenuOneOpen = (event) => {
        setSubMenuEl(event.currentTarget);
    };

    const handleMenuOneClose = () => {
        setSubMenuEl(null);
    };

    const handleMenuTwoOpen = (event) => {
        setSubMenuElTwo(event.currentTarget);
    };

    const handleMenuTwoClose = () => {
        setSubMenuElTwo(null);
    };

    return (
        <Box
            sx={{
                display: { xs: "block", sm: "block", md: "none" },
                minHeight: "100vh",
                fontFamily: "Inter, Roboto, Arial, sans-serif",
                padding: "0.5rem",
                display: "flex",
                flexDirection: "column",
            }}
            style={{
                gap: "0.5rem",
            }}
        >
            {navItemsData.map((item, index) => {
                if (item.to === "") {
                    return (
                        <React.Fragment key={index}>
                            <Button
                                key={index}
                                onClick={
                                    item.title === "Products"
                                        ? handleMenuTwoOpen
                                        : handleMenuOneOpen
                                }
                                sx={{
                                    py: "0rem",
                                    px: "0.7rem",
                                    height: "2.5rem",
                                    borderRadius: "0.7rem",
                                    backgroundColor: "transparent",
                                    color:
                                        theme.palette.mode === "light"
                                            ? "#6363FF"
                                            : "#90CAF9",
                                    fontSize: "0.9rem",
                                    transition: "none",
                                    "&:hover": {
                                        backgroundColor: "#6363FF",
                                        color: "white",
                                    },
                                    display: {
                                        xs: "flex",
                                        sm: "flex",
                                        md: "none",
                                    },
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: "bold",
                                }}
                                style={{
                                    gap: "0.3rem",
                                }}
                            >
                                <span>{item.title}</span>
                                <span style={{ marginTop: "3px" }}>
                                    <HiChevronDown />
                                </span>
                            </Button>
                            <Popover
                                open={
                                    item.title === "Products"
                                        ? Boolean(subMenuElTwo)
                                        : Boolean(subMenuEl)
                                }
                                anchorEl={
                                    item.title === "Products"
                                        ? subMenuElTwo
                                        : subMenuEl
                                }
                                onClose={
                                    item.title === "Products"
                                        ? handleMenuTwoClose
                                        : handleMenuOneClose
                                }
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                PaperProps={{
                                    elevation: 1,
                                    sx: {
                                        backgroundColor:
                                            theme.palette.mode === "light"
                                                ? "white"
                                                : "#3F3C3C",
                                        display: {
                                            xs: "flex",
                                            sm: "flex",
                                            md: "none",
                                        },
                                        flexDirection: "column",
                                        p: "0.7rem",
                                        borderRadius: "0.7rem",
                                        border: "1.5px solid #D6D2DA",
                                        width: "80vw",
                                    },
                                }}
                            >
                                {item.subMenu.map((subItem, subIndex) => (
                                    <MenuItem
                                        key={subIndex}
                                        component={Link}
                                        href={subItem.to}
                                        underline="none"
                                        onClick={handleMenuOneClose}
                                        sx={{
                                            backgroundColor: "#E5E5FA",
                                            color: "#281142",
                                            margin: "0.5rem",
                                            borderRadius: "0.5rem",
                                            "&:hover": {
                                                backgroundColor: "#D3D3FF",
                                            },
                                            display: {
                                                xs: "flex",
                                                sm: "flex",
                                                md: "none",
                                            },
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                        target={
                                            subItem.to.startsWith("http")
                                                ? "_blank"
                                                : undefined
                                        }
                                    >
                                        {subItem.title}
                                    </MenuItem>
                                ))}
                            </Popover>
                        </React.Fragment>
                    );
                }

                return (
                    <Link
                        href={item.to}
                        key={item.id}
                        underline="none"
                        sx={{
                            py: "0rem",
                            px: "1.3rem",
                            height: "2.5rem",
                            borderRadius: "0.7rem",
                            backgroundColor:
                                item.to === currentPath
                                    ? "#6363FF"
                                    : "transparent",
                            color:
                                item.to === currentPath
                                    ? "white"
                                    : theme.palette.mode === "light"
                                    ? "#6363FF"
                                    : "#90CAF9",
                            fontSize: "0.9rem",

                            "&:hover": {
                                backgroundColor: "#6363FF",
                                color: "white",
                            },
                            display: { xs: "flex", sm: "flex", md: "none" },
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.2rem",
                            fontWeight: "bold",
                            cursor: "pointer",
                        }}
                    >
                        <span>{item.title}</span>
                    </Link>
                );
            })}
            {profileMenuItemsData.map((subProfileItem, subProfileIndex) => (
                <Link
                    href={subProfileItem.to}
                    underline="none"
                    key={subProfileItem.id}
                    sx={{
                        py: "0rem",
                        px: "1.3rem",
                        height: "2.5rem",
                        borderRadius: "0.7rem",
                        backgroundColor:
                            subProfileItem.to === currentPath
                                ? "#6363FF"
                                : "transparent",
                        color:
                            subProfileItem.to === currentPath
                                ? "white"
                                : theme.palette.mode === "light"
                                ? "#6363FF"
                                : "#90CAF9",
                        fontSize: "0.9rem",

                        "&:hover": {
                            backgroundColor: "#6363FF",
                            color: "white",
                        },
                        display: { xs: "flex", sm: "flex", md: "none" },
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.2rem",
                        fontWeight: "bold",
                    }}
                >
                    {subProfileItem.title}
                </Link>
            ))}
            {user ? (
                <Button
                    sx={{
                        py: "0rem",
                        px: "0.7rem",
                        height: "2.5rem",
                        borderRadius: "0.7rem",
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? "white"
                                : "#2C2929",
                        border: "2px solid #5050D6",
                        color:
                            theme.palette.mode === "light"
                                ? "#6363FF"
                                : "#90CAF9",
                        fontSize: "0.9rem",
                        transition: "none",
                        "&:hover": {
                            backgroundColor: "#5050D6",
                            color: "white",
                        },
                        display: { xs: "flex", sm: "flex", md: "none" },
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={handleLogout}
                >
                    <span style={{ textTransform: "capitalize" }}>Logout</span>
                    <span style={{ marginTop: "3px" }}>
                        <HiOutlineUserRemove />
                    </span>
                </Button>
            ) : (
                <Button
                    sx={{
                        py: "0rem",
                        px: "0.7rem",
                        height: "2.5rem",
                        borderRadius: "0.7rem",
                        backgroundColor: "white",
                        border: "2px solid #5050D6",
                        color: "#5050D6",
                        fontSize: "0.9rem",
                        transition: "none",
                        "&:hover": {
                            backgroundColor: "#5050D6",
                            color: "white",
                        },
                        display: { xs: "flex", sm: "flex", md: "none" },
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                    }}
                    style={{
                        gap: "0.3rem",
                    }}
                    onClick={handleLoginNav}
                >
                    <span style={{ textTransform: "capitalize" }}>Login</span>
                    <span style={{ marginTop: "3px" }}>
                        <HiOutlineUserAdd />
                    </span>
                </Button>
            )}
        </Box>
    );
};

export default MobileMenu;
