import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import redshift from "../../imgs/model/redshift.png";
import AnalogDiffusion from "../../imgs/model/amalog.png";
import DisneyModel from "../../imgs/model/disney.png";
import AnimeDiffusion from "../../imgs/model/anim.png";
import DiscoDiffusion from "../../imgs/model/Discofusion.png";
import PortraitPlus from "../../imgs/model/protraitPlus.png";
import openJourney from "../../imgs/model/journey.png";
import SDModel from "../../imgs/model/stableD.png";

import { GiCheckMark } from "react-icons/gi";
import mixpanel from "mixpanel-browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #e6e6e6",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const imgStyle = {
  borderRadius: "10px",
  position: "relative",
};

const selectedStyle = {
  position: " absolute",
  top: "-10px",
  right: "-10px",
  fontSize: " 42px",
  border: "3px solid #8c61f9",
  borderRadius: "50%",
  height: "50px",
  width: "50px",
  backgroundColor: "#fff",
};

const CustomeTemplateModal = ({
  handleAgree,
  setSearchTerm,
  setModelTitle,
  modelTitle,
  setModelName,
  setModelVersion,
  setModelProvider,
  data,
  filterRef,
}) => {
  const [openCustomeTemplModal, setOpenCustomeTemplModal] =
    React.useState(false);
  const handleOpen = () => {
    mixpanel.track("Art generation", {
      Description: "Art generation - select  models"
    });
    setOpenCustomeTemplModal(true)};
  const handleClose = () => setOpenCustomeTemplModal(false);

  const teplateHandler = (item) => {
    const { model_name, provider, model_version, shortName, title, prompt } =
      item;
    console.log("item", item);

    setModelName(model_name);
    setModelTitle(shortName);
    setModelVersion(model_version);
    setSearchTerm(prompt);
    setModelProvider(provider);

    handleClose();
  };

  useEffect(() => {
    console.log("data", data?.subscription);
    data?.subscription === null && handleOpen();
    // handleOpen();
  }, []);

  return (
    <Box>
      <span
        onClick={handleOpen}
        className="modelBtn"
        ref={filterRef}
        // sx={{width:'350px'}}
      >
        Model : {modelTitle} <span className="modelNo"> [10+]</span>
      </span>
      <Modal
        open={openCustomeTemplModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            {ModelData.map((item, index) => (
              <Grid
                onClick={() => teplateHandler(item)}
                key={index}
                item
                md={3}
              >
                <Box sx={{ p: 1 }}>
                  <Box className="containerimg" sx={imgStyle}>
                    <img
                      id="gfg-img"
                      style={{
                        border:
                          item.shortName === modelTitle && "4px solid #8c61f9",
                        objectFit: "cover",
                        height: "300px",
                        width: "100%",
                      }}
                      src={item.img}
                    />
                    <div className="title">
                      <b> {item.title}</b>
                      <p>{item.description}</p>
                    </div>
                    {/* {item.shortName === modelTitle && (
                      <Box sx={selectedStyle}>
                        <GiCheckMark color="black" />
                      </Box>
                    )} */}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomeTemplateModal;

const ModelData = [
  {
    model_name: "cjwbw/disco-diffusion-style",
    model_version:
      "191406e6a98a2a0f922ebd4ea063e43b09eb004334e2a01955aeaf761c1b772b",
    title: "Disco Diffusion",
    description: "Get fascinating wallpaper",
    prompt: "{prompt/e.g: tower bridge} a photo of ddfusion style",
    img: DiscoDiffusion,
    shortName: "DD",
    provider: "replicate",
  },
  {
    model_name: "cjwbw/analog-diffusion",
    model_version:
      "1f7f51e8b2e43ade14fb7d6d62385854477e078ac870778aafecf70c0a6de006",
    title: "Analog Diffusion",
    description: "Get a diverse analog photographs",
    prompt: "analog style {prompt/e.g: a gaudy mansion interior}",
    img: AnalogDiffusion,
    shortName: "AnD",
    provider: "replicate",
  },
  {
    model_name: "nitrosocke/redshift-diffusion",
    model_version:
      "b78a34f0ec6d21d22ae3b10afd52b219cec65f63362e69e81e4dce07a8154ef8",
    title: "Red Shift",
    description: "High resolution 3D artworks",
    prompt: "redshift style {prompt/e.g: Ford Mustang}",
    img: redshift,
    shortName: "RS",
    provider: "replicate",
  },
  {
    model_name: "prompthero/openjourney",
    model_version:
      "9936c2001faa2194a261c01381f90e65261879985476014a0a37a334593a05eb",
    title: "open Journey",
    description: "Generate high resolution and ultra realistic photos",
    prompt:
      "mdjrny-v4 style a highly detailed matte painting of a {scene} by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece",
    img: openJourney,
    shortName: "OJ",
    provider: "replicate",
  },
  {
    model_name: "cjwbw/eimis_anime_diffusion",
    model_version:
      "a409b0769c91cfb3ecfa61698babd73ae34aee400f7894b1f02d28526631ec97",
    title: "Anime Diffusion",
    description: "Get high-quality anime images",
    prompt:
      "a {character}, Phoenix {character}, fluffy hair, war, a hell on earth, Beautiful and detailed explosion, Cold machine, Fire in eyes, burning, Metal texture, Exquisite cloth, Metal carving, volume, best quality, normal hands, Metal details, Metal scratch, Metal defects, masterpiece, best quality, best quality, illustration, highres, masterpiece, contour deepening, illustration,beautiful detailed glow",
    img: AnimeDiffusion,
    shortName: "AD",
    provider: "replicate",
  },
  {
    model_name: "cjwbw/portraitplus",
    model_version:
      "629a9fe82c7979c1dab323aedac2c03adaae2e1aecf6be278a51fde0245e20a4",
    title: "Portrait Plus",
    description: "Get diverse, close to medium range portraits of people",
    prompt: "portrait+ style {prompt/e.g: Hary poter}",
    img: PortraitPlus,
    shortName: "PP",
    provider: "replicate",
  },
  {
    model_name: "tstramer/mo-di-diffusion",
    model_version:
      "4cee26e8ef4979d7faa7118eb938b258cea03b2b99f23796248e4d93ba5c4e25",
    title: "Disney Model",
    description: "Generate your Disney style cartoon",
    prompt: "a photo of an astronaut riding a horse on mars",
    img: DisneyModel,
    shortName: "DM",
    provider: "replicate",
  },
  {
    model_name: "stable-diffusion-v1-5",
    model_version:
      "f370727477aa04d12d8c0b5c4e3a22399296c21cd18ff67cd7619710630fe3cb",
    title: "Stable Diffusion",
    description:
      "Generate awesome images with the fastest and most reliable model",
    prompt:
      "splash of random vivid colour, HDR, UHD, 64K, Highly detailed, Vivid Colors, Unreal engine, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski",
    img: SDModel,
    shortName: "SD",
    provider: "stability",
  },
  // {
  //   'model_name' : "cjwbw/eimis_anime_diffusion",
  //   'model_version' : "a409b0769c91cfb3ecfa61698babd73ae34aee400f7894b1f02d28526631ec97",
  //   'title': "Disco Diffusion",
  //   'description': "Get fascinating wallpaper",
  //   'prompt': "a girl, Phoenix girl, fluffy hair, war, a hell on earth, Beautiful and detailed explosion, Cold machine, Fire in eyes, burning, Metal texture, Exquisite cloth, Metal carving, volume, best quality, normal hands, Metal details, Metal scratch, Metal defects, masterpiece, best quality, best quality, illustration, highres, masterpiece, contour deepening, illustration,(beautiful detailed girl),beautiful detailed glow",
  // },
];
