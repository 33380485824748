import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

document.getScroll = function () {
    console.log("SCROLL");
    var sx,
        sy,
        d = document,
        r = d.documentElement,
        b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
};

const root = ReactDOM.createRoot(document.getElementById("aiseo__art"));
root.render(<App />);
