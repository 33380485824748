import React from "react";

import { ImageList, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import useDeviceChecker from "../hooks/useDeviceChecker";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ImageListItem from "@mui/material/ImageListItem";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { IoFlashSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { EXPENSE_RATE } from "../../constants/creditsExpenseRatio";
import { useAPI } from "../../StateProvider";
import DialogModal from "./DialogModal";

import { useDispatch } from "react-redux";
import { lexicaImageSelected } from "../../features/image/imageSlice";
import { setSelectedImagVariation } from "../../features/image/ImageVariation";
import { reduceTheCredit } from "../../firestore/dataHandler";

const BorderedButton = styled(Button)(({ theme }) => ({
  borderColor: "white",
  color: "white",
  margin: "0 auto",
  bottom: 0,
  marginTop: theme.spacing(1),
  ":hover": {
    borderColor: "white",
  },
}));

const getLexicaImageColumn = (device) => {
  if (device === "sm") return 1;
  else if (device === "md") return 2;
  else return 4;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const ImageCard = ({ template }) => {
//   return (
//       <div className="template_card">
//           <Card sx={{ borderRadius: "8px" }} elevation={4}>
//               <img
//                   src={template.url}
//                   style={{ width: "100%", height: "auto" }}
//                   alt=""
//               />
//               <div className="card_contents">
//                   <Typography>
//                       <strong>{template.category}</strong>
//                   </Typography>
//               </div>
//           </Card>
//       </div>
//   );
// };

const LexicaImageContainer = ({
  user,
  credits,
  image,
  handlePromptSelection,
  openLightBox,
  tryThis,
  copyPrompt,
  image2imageRefhandler,
  imageVariationRefhandler,
  setImage2ImageState,
  setImageVariationState,
}) => {
  const overlayRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const copyButton = React.useRef(null);
  const [warning, setWarning] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data, dataFetched } = useAPI();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = data?.subscription;

  const downloadImage = async (e) => {
    e.preventDefault();

    console.log(credits);
    if (!user || !user.emailVerified) {
      setWarning("You need to login first.");
      return;
    }

    //  * No credit reduction for download (26 oct 2022) (FUck this logic. credit is started consuming from 11 Nov 2022)
    const cost = EXPENSE_RATE.lexicaImageDownload;
    const finalCredit = credits - cost;
    console.log(finalCredit);
    console.log(subscription);
    console.log(data);

    if (finalCredit < 0 && !subscription.type.startsWith("Appsumo")) {
      setWarning("You do not have credit.");
      return;
    }

    const imageTitle = image.prompt.split(" ").join("_").slice(0, 20);
    const reqURL = image.src;
    fetch(reqURL)
      .then((response) => {
        return response.blob();
      })
      .then((imageBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = function () {
          const base64data = reader.result;
          console.log(base64data);
          const a = document.createElement("a"); //Create <a>
          a.href = base64data;
          a.download = imageTitle + "aiseo_art"; //File name Here
          a.click();
        };

        // EVERY ONE WILL BE CHARGED FOR DOWNLOADING IMAGES. EXCEPT(appsumo users)
        if (!subscription || !subscription.type.startsWith("Appsumo")) {
          reduceTheCredit(EXPENSE_RATE.lexicaImageDownload);
        }
      });
  };

  const handleMouseMove = (param) => {
    if (param === "enter") {
      document.querySelectorAll(".eachItemListBox").forEach((each) => {
        each.style.opacity = 1;
        each.style.display = "none";
      });
      document.querySelectorAll(".eachItemListImage").forEach((each) => {
        each.style.opacity = 1;
      });

      // imgRef.current.style.opacity = 0.2;
      overlayRef.current.style.display = "flex";
    } else {
      imgRef.current.style.opacity = 1;
      overlayRef.current.style.display = "none";
    }
  };

  const prompt = image.prompt;

  const handleWarningClose = () => {
    setWarning("");
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    document.querySelectorAll(".copy-buttons").forEach((elem) => {
      elem.style.background = "unset";
      elem.style.color = "white";
      elem.innerText = "Copy Prompt";
    });
    copyButton.current.style.background = "white";
    copyButton.current.style.color = "black";
    copyButton.current.innerText = "Copied";
  };

  const openWithEditor = (image) => {
    console.log(image);
    dispatch(
      lexicaImageSelected({
        ...image,
        category: "searched",
      })
    );
    navigate({
      pathname: "/image/" + image.id,
    });
  };
  const handleClickOnTheOverlay = (event) => {
    // alert("HI");
    console.log(event.target);
    if (!event.target.classList.contains("skipClick")) {
      console.log("INSIDE");
      openLightBox(image.id);
    } else {
      console.log("OUTSIDE");
    }
  };

  return (
    <>
      <DialogModal
        open={open}
        setOpen={setOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        type="Free"
      />
      <Snackbar
        open={Boolean(warning)}
        autoHideDuration={6000}
        onClose={handleWarningClose}
      >
        <Alert
          onClose={handleWarningClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {warning}
        </Alert>
      </Snackbar>
      <ImageListItem cols={1} rows={1} sx={{ cursor: "pointer" }}>
        <img
          ref={imgRef}
          src={`${image.src}`}
          alt={image.prompt}
          loading="lazy"
          style={{
            borderRadius: "5px",
            objectFit: "cover",
            minHeight: 250,
            maxHeight: 300,
          }}
          onMouseEnter={() => handleMouseMove("enter")}
          className="eachItemListImage"
        />
        <Box
          textAlign="center"
          onMouseLeave={() => handleMouseMove("leave")}
          ref={overlayRef}
          onClick={handleClickOnTheOverlay}
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            bottom: 0,
            height: "100%",
            // padding: "1.5rem",
            px: 2,
            display: "none",
            background:
              "linear-gradient(180deg,transparent,rgba(0,0,0,.4) 50%,rgba(0,0,0,.9))",
            alignItems: "flex-end",
            transition: ".2s ease-in-out",
            overflow: "hidden",
          }}
          className="eachItemListBox"
        >
          <Box pb={1} sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              align="center"
              style={{
                color: "#ffffffd0",
              }}
            >
              {prompt.length > 150 ? prompt.slice(0, 150) + " ... ..." : prompt}
            </Typography>
            {/* buttons group... */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  marginRight: "auto",
                }}
              >
                <BorderedButton
                  onClick={() => openWithEditor(image)}
                  disableElevation
                  variant="outlined"
                  sx={{
                    marginRight: ".5rem",
                  }}
                  size="small"
                >
                  Enhance
                </BorderedButton>

                {/* {tryThis ? (
                                    <BorderedButton
                                        onClick={() =>
                                            handlePromptSelection(image)
                                        }
                                        className="skipClick"
                                        disableElevation
                                        variant="outlined"
                                        size="small">
                                        Try This
                                    </BorderedButton>
                                ) : null} */}
                {copyPrompt ? (
                  <BorderedButton
                    onClick={() => copyToClipboard(image.prompt)}
                    ref={copyButton}
                    className="copy-buttons skipClick"
                    disableElevation
                    variant="outlined"
                    size="small"
                  >
                    Copy Prompt
                  </BorderedButton>
                ) : null}
                {/* <BorderedButton
                  // onClick={() => handlePromptSelection(image.prompt)}

                  onClick={() => {
                    imageVariationRefhandler();
                    setImageVariationState(image.src);
                    console.log("image.src", image.src);
                    dispatch(
                      setSelectedImagVariation({
                        selectedImageVariation: image.src,
                      })
                    );
                  }}
                  className="skipClick"
                  disableElevation
                  variant="outlined"
                  size="small"
                  sx={{
                    marginLeft: ".5rem",
                  }}
                >
                  Variation
                </BorderedButton> */}
                {/* <BorderedButton
                  onClick={() => {
                    image2imageRefhandler();
                    setImage2ImageState(image.src);
                    console.log("image.src", image.src);
                    dispatch(
                      setSelectedImage({
                        selectedImage: image.src,
                      })
                    );
                  }}
                  className="skipClick"
                  disableElevation
                  variant="outlined"
                  size="small"
                >
                  Image2image
                </BorderedButton> */}
              </Box>
              <Tooltip title="Image Variation" followCursor>
                <Box
                  className="skipClick"
                  variant="outlined"
                  color="primary"
                  size="small"
                  // px={1}
                  style={{
                    color: "white",
                    fontSize: "1.6rem",
                  }}
                  sx={{
                    bottom: "0",
                    mt: 1,
                    minWidth: "unset",
                    color: "white",
                  }}
                >
                  <IoFlashSharp
                    onClick={() => {
                      imageVariationRefhandler();
                      setImageVariationState(image.src);
                      console.log("image.src", image.src);
                      dispatch(
                        setSelectedImagVariation({
                          selectedImageVariation: image.src,
                        })
                      );
                    }}
                  />
                </Box>
              </Tooltip>
              <Box
                onClick={downloadImage}
                className="skipClick"
                variant="outlined"
                color="primary"
                size="small"
                px={1}
                style={{
                  color: "white",
                  fontSize: "1.6rem",
                }}
                sx={{
                  bottom: "0",
                  mt: 1,
                  minWidth: "unset",
                  color: "white",
                }}
              >
                <svg
                  className="skipClick"
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1578L16.2428 8.91501L17.657 10.3292L12.0001 15.9861L6.34326 10.3292L7.75748 8.91501L11 12.1575V5Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Box>
              {/* <Button
                                    onClick={downloadImage}
                                    disableElevation
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{  bottom: "0", mt: 2 , minWidth : 'unset' , color : 'white'}}>
                                    <FileDownloadIcon />
                                </Button> */}
              <Box
                onClick={() => openLightBox(image.id)}
                variant="outlined"
                size="small"
                style={{
                  color: "white",
                  fontSize: "1.25rem",
                }}
                sx={{
                  bottom: "0",
                  mt: 1,
                  minWidth: "unset",
                  color: "white",
                }}
              >
                {/* <OpenInFullRoundedIcon /> */}
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8995 4.10052V2.10052H21.8995V10.1005H19.8995V5.51477L14.1213 11.293L12.7071 9.87878L18.4854 4.10052H13.8995Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M4.10046 13.8995H2.10046V21.8995H10.1005V19.8995H5.51468L11.2929 14.1212L9.87872 12.707L4.10046 18.4853V13.8995Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </Box>
            </Box>
          </Box>
        </Box>
      </ImageListItem>
    </>
  );
};

export default function DisplayLexicaImagesForImageVariation({
  images,
  credits,
  handlePromptSelection,
  openLightBox,
  user,
  tryThis,
  copyPrompt,
  image2imageRefhandler,
  imageVariationRefhandler,
  setImage2ImageState,
  setImageVariationState,
}) {
  const device = useDeviceChecker();
  const [step, setStep] = React.useState(0);
  const [showWarning, setShowWarning] = React.useState(false);
  const maxIndex = (step + 1) * 20;
  // const displayableImages = images.slice(0, maxIndex);
  const [displayableImages, setDisplayableImages] = React.useState(
    images.slice(0, 20)
  );
  console.log(maxIndex);
  console.log(displayableImages);

  const handleStepIncrement = (e) => {
    if (step === 2) {
      setShowWarning(true);
    } else {
      setStep((step) => step + 1);
      setDisplayableImages([
        ...displayableImages,
        ...images.slice((step + 1) * 20, (step + 2) * 20),
      ]);
    }
  };

  React.useEffect(() => {
    setStep(0);
    setShowWarning(false);
    setDisplayableImages(images.slice(0, 20));
  }, [images]);
  return (
    <>
      <Typography
        text="center"
        mt={4}
        variant="h6"
        sx={{
          textAlign: "center",
        }}
      >
        Similar Community Images
      </Typography>
      <ImageList
        // variant="quilted"
        cols={getLexicaImageColumn(device)}
        sx={{ mt: 5, width: "100%" }}
        // rowHeight={250}
        gap={14}
        variant="masonry"
      >
        {images.length !== 0 ? (
          displayableImages.map((image, i) => (
            <LexicaImageContainer
              image2imageRefhandler={image2imageRefhandler}
              imageVariationRefhandler={imageVariationRefhandler}
              setImage2ImageState={setImage2ImageState}
              setImageVariationState={setImageVariationState}
              key={image.id}
              user={user}
              image={image}
              sx={{ mt: 4 }}
              credits={credits}
              tryThis={tryThis}
              copyPrompt={copyPrompt}
              handlePromptSelection={handlePromptSelection}
              openLightBox={openLightBox}
            />
          ))
        ) : (
          <></>
        )}
      </ImageList>
      <Box textAlign="center" sx={{ mt: 5 }}>
        {showWarning ? (
          <Typography variant="body2">No more images to display.</Typography>
        ) : (
          <Button onClick={handleStepIncrement}>See More</Button>
        )}
      </Box>
    </>
  );
}
