import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    storeToolsDetails,
    toolsDetailsbyCat,
} from "../../firestore/storageHandler";

//import { addJob, deleteJob, editJob, getJobs, getJob } from "./jobAPI";
import {
    genImgApiFunc,
    genImgApiFuncTwo,
    getHistoryIamges,
    genSocailMediaContent,
} from "./toolImgGenApi";

const initialState = {
    activeTool: {
        title: "Anime",
        slug: "Anime",
        color: "black",
        options: ["Vertical", "Horizontal", "Square"],
        defaultStr:
            "1boy, bishounen, casual, indoors, sitting, coffee shop, bokeh",
        mainStr:
            " a {object}, Metal texture, Exquisite cloth, Metal carving, volume, best quality, normal hands, Metal details, Metal scratch, Metal defects, masterpiece, best quality, best quality, illustration, highres, masterpiece, contour deepening, illustration,beautiful detailed glow  ",
        imgSrc: "https://art.aiseo.ai/static/media/anim.c812a583d0f07a40dad0.png",
        category: "category-1",
        imgName: "anim.png",
    },

    isLoading: false,
    isError: false,
    errorType: "",
    error: "",
    historyImages: [],
    generatedImage: "",
    isUserProhibited: false,
    gImg: "",
    postCaption: "",
    postGenerateCount: 0,
};

// async thunks
export const generateImage = createAsyncThunk(
    "toolImgGen/generateImage",
    async (data) => {
        const result = await genImgApiFunc(data);
        return result;
    }
);

export const generateImageTwo = createAsyncThunk(
    "toolImgGen/generatedImageTwo",
    async (data) => {
        const result = await genImgApiFuncTwo(data);
        return result;
    }
);
export const generateSocailMediaContent = createAsyncThunk(
    "toolImgGen/generateSocailMediaContent",
    async (data) => {
        const result = await genSocailMediaContent(data);
        return result;
    }
);

// create slice
const toolImgGenSlice = createSlice({
    name: "toolImgGen",
    initialState,
    reducers: {
        changeActiveTool: (state, action) => {
            state.activeTool = action.payload;
            state.generatedImage = "";
            state.postCaption = "";
            state.postGenerateCount = 0;
        },
        changePrompt: (state, action) => {
            state.activeTool.defaultStr = action.payload;
        },
        toggleFreeUsersMsg: (state, action) => {
            state.isUserProhibited = !state.isUserProhibited;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(generateImage.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
                state.generatedImage = "";
            })
            .addCase(generateImage.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                storeToolsDetails({
                    email: action.payload.userEmail,
                    image: action.payload.path,
                    category: state.activeTool.slug,
                    prompt: action.payload.prompt,
                });
                state.activeTool = state.activeTool;
                state.generatedImage = action.payload.path;
            })
            .addCase(generateImage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;
            })
            .addCase(generateSocailMediaContent.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
                state.generatedImage = "";
                state.postCaption = "";
                state.postGenerateCount = state.postGenerateCount + 1;
            })
            .addCase(generateSocailMediaContent.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                storeToolsDetails({
                    email: action.payload.userEmail,
                    image: action.payload.path,
                    category: state.activeTool.slug,
                    prompt: action.payload.prompt,
                });
                state.activeTool = state.activeTool;
                state.generatedImage = action.payload.path;
                state.postCaption = action.payload.postCaption;
                state.postGenerateCount = state.postGenerateCount + 1;
            })
            .addCase(generateSocailMediaContent.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error?.message;
            });
    },
});

export default toolImgGenSlice.reducer;
export const { changeActiveTool, changePrompt, toggleFreeUsersMsg } =
    toolImgGenSlice.actions;
