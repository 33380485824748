import { v4 as uuidv4 } from 'uuid';



// let randomImageArray1 = [
//     { csds
//         id: uuidv4(),
//         gallery: "https://lexica.art?q=506a05d5-ef46-4e1e-91a0-5c895ec4e530",
//         src: "https://lexica-serve-encoded-images.sharif.workers.dev/md/506a05d5-ef46-4e1e-91a0-5c895ec4e530",
//         srcSmall:
//             "https://lexica-serve-encoded-images.sharif.workers.dev/sm/506a05d5-ef46-4e1e-91a0-5c895ec4e530",
//         prompt: "concept art skull by jama jurabaev, the skull is decorated with art deco patterns, cinematic shot, trending on artstation, high quality, brush stroke ",
//         width: 896,
//         height: 512,
//         seed: "555252570",
//         grid: false,
//         model: "stable-diffusion",
//         guidance: 7,
//         promptid: "de021624-6f29-42e5-b75d-881350ebe92e",
//         nsfw: false,
//     },
//     {
//         id: uuidv4(),
//         gallery: "https://lexica.art?q=0b9e4633-b55b-4510-aaed-89280bf5eebc",
//         src: "https://lexica-serve-encoded-images.sharif.workers.dev/md/0b9e4633-b55b-4510-aaed-89280bf5eebc",
//         srcSmall:
//             "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0b9e4633-b55b-4510-aaed-89280bf5eebc",
//         prompt: "zouk bazaar egypt wonderland greeble tent multicolor official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws cartoon vibrant ",
//         width: 1280,
//         height: 768,
//         seed: "557619845",
//         grid: false,
//         model: "stable-diffusion",
//         guidance: 7,
//         promptid: "363a0193-6c41-4245-a591-e7caa8553bff",
//         nsfw: false,
//     },
//     {
//         id: uuidv4(),
//         gallery: uuidv4(),
//         src: "https://lexica-serve-encoded-images.sharif.workers.dev/md/1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
//         srcSmall:
//             "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
//         prompt: "A crackling portal to hell opening in an idyllic heavenly sky, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed",
//         width: 704,
//         height: 512,
//         seed: "63534388",
//         grid: false,
//         model: "stable-diffusion",
//         guidance: 7,
//         promptid: "77ed2246-2e7f-46dc-8895-6f567f02bf60",
//         nsfw: false,
//     },
//     {
//         id: uuidv4(),
//         gallery: "https://lexica.art?q=c2e4a5b6-f770-4cb9-8862-44813874a58b",
//         src: "https://lexica-serve-encoded-images.sharif.workers.dev/md/c2e4a5b6-f770-4cb9-8862-44813874a58b",
//         srcSmall:
//             "https://lexica-serve-encoded-images.sharif.workers.dev/sm/c2e4a5b6-f770-4cb9-8862-44813874a58b",
//         prompt: "underground mine tunnel, golden hour, intricate oil painting, high detail illustration, sharp high detail, manga and anime 1 9 9 9, official fanart behance hd artstation by jesper ejsing and makoto shinkai, 4 k, ",
//         width: 1024,
//         height: 704,
//         seed: "1441249749",
//         grid: false,
//         model: "stable-diffusion",
//         guidance: 15,
//         promptid: "4dc720ef-6fbd-4ed5-bdc3-71876eea7c45",
//         nsfw: false,
//     },
//         {
//                 "id": uuidv4(),
//                 "gallery": "https://lexica.art?q=ced2c7e9-0fa3-40f6-94a3-51708956e4bb",
//                 "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/ced2c7e9-0fa3-40f6-94a3-51708956e4bb",
//                 "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/ced2c7e9-0fa3-40f6-94a3-51708956e4bb",
//                 "prompt": "fantasy elven forest, behance hd by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws global illumination",
//                 "width": 1280,
//                 "height": 768,
//                 "seed": "1411106216",
//                 "grid": false,
//                 "model": "stable-diffusion",
//                 "guidance": 13,
//                 "promptid": "a4907367-9f95-4802-ad4b-c208f1a94e27",
//                 "nsfw": false
//             },
        
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=13e47d8e-b037-4001-9089-95b385e8c5b8",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/13e47d8e-b037-4001-9089-95b385e8c5b8",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/13e47d8e-b037-4001-9089-95b385e8c5b8",
//         "prompt": "one punch ma, trending on artstation, pixiv, hyperdetailed Unreal Engine 4k 8k ultra HD, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean Marc Simonetti Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea Sakimichan, yuru camp, illustration, digital art, concept art",
//         "width": 1024,
//         "height": 1024,
//         "seed": "277116281",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "f2f738b7-6622-43bb-a0e0-65f07f09d828",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=457e4bb3-7132-4d20-9900-d63e93c87356",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/457e4bb3-7132-4d20-9900-d63e93c87356",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/457e4bb3-7132-4d20-9900-d63e93c87356",
//         "prompt": "portrait, collector of souls, Dynamic lighting, cinematic, establishing shot, extremely high detail, photo realistic, cinematic lighting, pen and ink, intricate line drawings, post processed, concept art, artstation, matte painting, style by Raphael Lacoste, Eddie Mendoza",
//         "width": 512,
//         "height": 704,
//         "seed": "3077255935",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "c8a162cf-0a3a-48a9-afdd-65b04238f687",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=8fbf877c-c54c-4e93-aa79-f013fc04e969",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/8fbf877c-c54c-4e93-aa79-f013fc04e969",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/8fbf877c-c54c-4e93-aa79-f013fc04e969",
//         "prompt": "a heavenly ninja warrior, hellish landscape, flowing backlit hair, painting by geg ruthowski, alphonse murac, craig mullins, ruan jia, wlop, yoji shinkawa, collaborative artwork, exquisitely high quality and detailed ",
//         "width": 1024,
//         "height": 768,
//         "seed": "247575506",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 12,
//         "promptid": "1b99b963-412f-42ed-83df-895a6b79af2f",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=0490626b-2a2a-4906-9748-6be50b66868a",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/0490626b-2a2a-4906-9748-6be50b66868a",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0490626b-2a2a-4906-9748-6be50b66868a",
//         "prompt": "A long shot of a man djing at night under the stars, beautiful, digital art, artstation, hyperrealistic, 8k, unreal engine, octane render, trending on artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha",
//         "width": 512,
//         "height": 512,
//         "seed": "1766256284",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 10,
//         "promptid": "fab21831-8fab-4ee4-9b07-8baa4bf2d9ec",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=91b0c3d6-4d41-4845-a1cb-6c618b165d82",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/91b0c3d6-4d41-4845-a1cb-6c618b165d82",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/91b0c3d6-4d41-4845-a1cb-6c618b165d82",
//         "prompt": "Man djing under the galaxy, digital art, artstation, hyperrealistic, 4k, unreal engine, octane render, trending on artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha",
//         "width": 704,
//         "height": 512,
//         "seed": "1775880779",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "3e3d5ec2-05fc-4296-b684-e5ffaca443e1",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
//         "prompt": "A wide shot of a man djing at night under the stars, beautiful, digital art, artstation, hyperrealistic, 8k, unreal engine, octane render, trending on artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha",
//         "width": 512,
//         "height": 704,
//         "seed": "1766256284",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 8,
//         "promptid": "0a6e673e-210b-4758-934f-503e983ec571",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=1489baab-d306-4b1e-b1d9-400b34179f81",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1489baab-d306-4b1e-b1d9-400b34179f81",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1489baab-d306-4b1e-b1d9-400b34179f81",
//         "prompt": "pure love is patient love is kind ; ultra realistic, concept art, intricate details, eerie, haunting, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha ",
//         "width": 512,
//         "height": 512,
//         "seed": "1462341595",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 13,
//         "promptid": "b5f4755f-b79b-4e74-af33-3c5df2ab787f",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=65fb1e4e-3bc3-42bd-b5de-9ceb782d68e6",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/65fb1e4e-3bc3-42bd-b5de-9ceb782d68e6",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/65fb1e4e-3bc3-42bd-b5de-9ceb782d68e6",
//         "prompt": "portrait of a fantasy woman, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha ",
//         "width": 576,
//         "height": 704,
//         "seed": "3624411895",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "55e8c52c-7ff0-440c-9ab0-8d00751c4dab",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=058865ef-a1ab-4210-b697-f7da0621cba1",
//         "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/485e93ad-f7f0-4302-8602-a9410ba324a8",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/058865ef-a1ab-4210-b697-f7da0621cba1",
//         "prompt": "girl in solarpunk village, evening, 4 k, ultra realistic, beautiful eyes, epic lighting, starry sky, magical, glowing forest, mushrooms, machines, high detail, masterpiece, trending on artstation by artgerm and akihito tsukushi and alphonse mucha  ",
//         "width": 512,
//         "height": 768,
//         "seed": "87690109",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "4439051a-4365-46a1-9a5a-3a498852712a",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=12a9db6b-3cd8-4786-b24e-d1dc543f5c4a",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/12a9db6b-3cd8-4786-b24e-d1dc543f5c4a",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/12a9db6b-3cd8-4786-b24e-d1dc543f5c4a",
//         "prompt": "girl in solarpunk village, evening, 4 k, ultra realistic, beautiful eyes, epic lighting, starry sky, magical, glowing forest, mushrooms, machines, high detail, masterpiece, trending on artstation by artgerm and akihito tsukushi and alphonse mucha  ",
//         "width": 768,
//         "height": 512,
//         "seed": "2387809719",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "73d694cc-2b91-4486-a4fe-d74eb8682edd",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=1209e8d3-b52a-4cda-9ecb-357fe779621f",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1209e8d3-b52a-4cda-9ecb-357fe779621f",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1209e8d3-b52a-4cda-9ecb-357fe779621f",
//         "prompt": "hyperrealistic mixed media collaboration by thomas eakes greg rutkowski xiang duan mike judge, perfect symmetry, dim volumetric lighting, 8 k octane beautifully detailed render, post - processing, portrait, extremely hyper - detailed, intricate, epic composition, highly detailed realistic attributes, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, ",
//         "width": 512,
//         "height": 512,
//         "seed": "2348217735",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "49293cee-5765-4856-8196-818c04119c67",
//         "nsfw": false
//     }, 
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=49353f68-a55b-4a46-9d93-1bf900541ed1",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/49353f68-a55b-4a46-9d93-1bf900541ed1",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/49353f68-a55b-4a46-9d93-1bf900541ed1",
//         "prompt": "Vertical street, hyperrealism, no blur, 4k resolution, ultra detailed, style of John Berkey, Hans Thoma, Ivan Shishkin, Tyler Edlin, Thomas Kinkad",
//         "width": 512,
//         "height": 512,
//         "seed": "4175584369",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "8ba0c947-6b7a-4a17-b220-c48ba01ecff3",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=5bf93b0f-7951-425c-a0a6-7e32d0f67e3c",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/5bf93b0f-7951-425c-a0a6-7e32d0f67e3c",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/5bf93b0f-7951-425c-a0a6-7e32d0f67e3c",
//         "prompt": "art by Greg Rutkowski",
//         "width": 512,
//         "height": 512,
//         "seed": "1672098420",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "b647db55-7b3a-448f-8e0a-098b378040ee",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=6a7c2a4d-6ac8-48c7-96f0-8f9295deff3f",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/6a7c2a4d-6ac8-48c7-96f0-8f9295deff3f",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/6a7c2a4d-6ac8-48c7-96f0-8f9295deff3f",
//         "prompt": "greg rutkowski art poster swamp town ",
//         "width": 512,
//         "height": 768,
//         "seed": "2886683776",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 16,
//         "promptid": "8ee029af-6612-498e-8487-0bd2e2a00158",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=83ce0daf-3018-4675-a179-ae85a35a6237",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/83ce0daf-3018-4675-a179-ae85a35a6237",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/83ce0daf-3018-4675-a179-ae85a35a6237",
//         "prompt": "eternal city, city of secrets, purple, environment art, fantasy art, landscape art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing ",
//         "width": 512,
//         "height": 640,
//         "seed": "2889417049",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "e8acdbc7-9438-4840-ab25-3bb5a867dbc0",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=88692024-76e5-4b99-a67d-709b6a33054b",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/88692024-76e5-4b99-a67d-709b6a33054b",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/88692024-76e5-4b99-a67d-709b6a33054b",
//         "prompt": "Vertical street, hyperrealism, no blur, 4k resolution, ultra detailed, style of John Berkey, Hans Thoma, Ivan Shishkin, Tyler Edlin, Thomas Kinkad",
//         "width": 512,
//         "height": 512,
//         "seed": "4175584369",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "8ba0c947-6b7a-4a17-b220-c48ba01ecff3",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/ab4ec18a-6421-4eea-b718-cf061059d407",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/ab4ec18a-6421-4eea-b718-cf061059d407",
//         "prompt": "a concept art painting of bioshock style city with a woman with back to camera looking out of a window by tuomas korpi, pablo carpio, gilles beloeil, cedric peyravernay, trending on artstation, highly detailed, atmospheric, directional lighting, cinematic ",
//         "width": 704,
//         "height": 1024,
//         "seed": "3280607562",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 10,
//         "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=b7d6b1b9-8ef3-48a5-8a4d-25c6c6fc5efb",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/b7d6b1b9-8ef3-48a5-8a4d-25c6c6fc5efb",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/b7d6b1b9-8ef3-48a5-8a4d-25c6c6fc5efb",
//         "prompt": "hyperrealistic photography a cyberpunk dungeon in the style of ashley wood and phil hale, masterpiece, award - winning, sharp focus, intricate concept art, ambient lighting, 8 k, artstation ",
//         "width": 512,
//         "height": 1024,
//         "seed": "1585016267",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 6,
//         "promptid": "a59df9fd-455f-4def-88ca-2c8116738e64",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=0e298197-1ccf-4895-9f8f-dfa32acea6c3",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/0e298197-1ccf-4895-9f8f-dfa32acea6c3",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0e298197-1ccf-4895-9f8f-dfa32acea6c3",
//         "prompt": "shadow mage casting dark magic in the daytime by charlie bowater ",
//         "width": 512,
//         "height": 512,
//         "seed": "23516699",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "419e3959-2fb8-4073-8426-b2dedcd90d2d",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=26961a1a-3543-4eef-86dc-e185b8b609c5",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/26961a1a-3543-4eef-86dc-e185b8b609c5",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/26961a1a-3543-4eef-86dc-e185b8b609c5",
//         "prompt": "a beautiful painting of voldemort performing an occult ritual, by greg rutkowski featured on artstation, dark spell, magic vfx, particles, depth of field, bokeh, black smoke, sparks ",
//         "width": 512,
//         "height": 832,
//         "seed": "2824759380",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "c4d24398-502f-49c6-b2b4-fb357c68a7a2",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=400cebd3-ae1a-475f-85ec-f79ac457bdb2",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/400cebd3-ae1a-475f-85ec-f79ac457bdb2",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/400cebd3-ae1a-475f-85ec-f79ac457bdb2",
//         "prompt": "stunning female wizard casting his shadow spell, highly detailed painting by gaston bussiere, craig mullins, j. c. leyendecker, 8 k ",
//         "width": 512,
//         "height": 512,
//         "seed": "119494677",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 11,
//         "promptid": "151d14a7-d4ed-4e58-896e-8ba50a7a622c",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=4c45869c-b39f-4229-97a6-d575cb6fbb12",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/4c45869c-b39f-4229-97a6-d575cb6fbb12",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/4c45869c-b39f-4229-97a6-d575cb6fbb12",
//         "prompt": "a fancy portrait of a beautiful dark magician women covered in deep blue flames flames by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, final fantasy , mythical, 8k photorealistic, cinematic lighting, HD, high details, atmospheric,",
//         "width": 512,
//         "height": 768,
//         "seed": "3795763274",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "e1ffd6c2-fa33-4e75-ad1d-d13015baf407",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=61b4f5ce-b289-4abd-a0da-8cfa511a814b",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/61b4f5ce-b289-4abd-a0da-8cfa511a814b",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/61b4f5ce-b289-4abd-a0da-8cfa511a814b",
//         "prompt": "a beautiful cinematic female Necromancer Sorceress, galatic shamen with Quantum energy fantasy, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece",
//         "width": 512,
//         "height": 704,
//         "seed": "3180761963",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 15,
//         "promptid": "2ce375b4-e2ed-4da0-ac79-e02b59edb298",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=84ee046e-dd31-4454-a83b-105b9a1dc0f1",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/84ee046e-dd31-4454-a83b-105b9a1dc0f1",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/84ee046e-dd31-4454-a83b-105b9a1dc0f1",
//         "prompt": "a beautiful portrait of a young Demon women covered in deep purple flames with an intense look on her face by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, Bloodborne , 8k photorealistic, cinematic lighting, HD, high details, atmospheric , trending on artstation",
//         "width": 512,
//         "height": 768,
//         "seed": "3858300386",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "dd52ef7f-5124-4a86-961c-93f69bdd424f",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=8f955d09-d350-4609-9cbd-d72c482314b0",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/8f955d09-d350-4609-9cbd-d72c482314b0",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/8f955d09-d350-4609-9cbd-d72c482314b0",
//         "prompt": "beautiful young witch, glowing eyes, magic, high detail, dramatic light, digital art, dark, painted by seb mckinnon and greg rutkowski, trending on artstation ",
//         "width": 512,
//         "height": 512,
//         "seed": "755185535",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "7c8e2650-3532-4dfd-8362-9674c057f33c",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=507ca944-cbc8-4f5e-a5bb-5257d72da5de",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/507ca944-cbc8-4f5e-a5bb-5257d72da5de",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/507ca944-cbc8-4f5e-a5bb-5257d72da5de",
//         "prompt": "explosion made of cotton, beach, fantasy, intricate, amazing composition, colorful watercolor, by ruan jia, by maxfield parrish, by marc simonetti, by hikari shimoda, by robert hubert, by zhang kechun, illustration, gloomy ",
//         "width": 832,
//         "height": 512,
//         "seed": "3155292359",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 12,
//         "promptid": "cfed3818-034d-41d5-991b-1a9e9f791a9f",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=50c43ead-b194-4b85-bf1e-d59c49aa85eb",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/50c43ead-b194-4b85-bf1e-d59c49aa85eb",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/50c43ead-b194-4b85-bf1e-d59c49aa85eb",
//         "prompt": "color powder explosion on black background, particles, fine detail, hyperrealism, james jean, golden ratio, sharp focus, octane render, sidefx houdini, artstation, vfx ",
//         "width": 512,
//         "height": 512,
//         "seed": "3339988495",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 14,
//         "promptid": "6b684898-679b-402b-af5e-8bbd35f564e8",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
//         "prompt": "product - view of diorama of the shape of the universe, occult cosmology, occult abiogenesis, hyper - detailed, crystals, plastic, rubber, wood, glass, playdoh, slimy, wet, mist, smoke, oil, realistic materials, daylight, fujifilm velvia 5 0, color photography, sigma 2 8 mm, engulfed in neon flames ",
//         "width": 704,
//         "height": 512,
//         "seed": "231582325",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "7d977c2a-db52-42cb-9201-6ebece302c50",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=0a67c418-d520-49c8-948d-87ecc7cdcc97",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/0a67c418-d520-49c8-948d-87ecc7cdcc97",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0a67c418-d520-49c8-948d-87ecc7cdcc97",
//         "prompt": "Portrait of Sam Gray wearing a sombrero, by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation",
//         "width": 512,
//         "height": 512,
//         "seed": "692765136",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "529311cb-9be7-4dfc-a51c-56f7c42d380b",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=217f1372-0f96-4f37-b0d0-2a31a9d6a8b4",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/217f1372-0f96-4f37-b0d0-2a31a9d6a8b4",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/217f1372-0f96-4f37-b0d0-2a31a9d6a8b4",
//         "prompt": "portrait of a man with a long duster, almost grey hair and a cowboy hat, harsh good looking face, drawn by Ruan Jia, fantasy art, dramatic lighting, digital art,highly detailed",
//         "width": 512,
//         "height": 512,
//         "seed": "3580971506",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "eed2d6b3-ffde-4bd9-a01d-af15f0c7a592",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=319b0c8d-e080-4276-b4a8-f8b7d49fdc1e",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/319b0c8d-e080-4276-b4a8-f8b7d49fdc1e",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/319b0c8d-e080-4276-b4a8-f8b7d49fdc1e",
//         "prompt": "hyperrealist portrait of a rodeo cowboy by jeremy mann and alphonse mucha, fantasy art, photo realistic, dynamic lighting, artstation, poster, volumetric lighting, very detailed faces, 4 k, award winning ",
//         "width": 512,
//         "height": 768,
//         "seed": "3159019363",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 11,
//         "promptid": "6f544040-cf3f-46b3-9d4f-ec8a5fe5cbfa",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=4a1bedff-ddcd-4acf-b82b-cbdc5abae6df",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/4a1bedff-ddcd-4acf-b82b-cbdc5abae6df",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/4a1bedff-ddcd-4acf-b82b-cbdc5abae6df",
//         "prompt": "bust portrait of a well - dressed mushroom man with a mushroom for a head, dramatic lighting, illustration by greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation ",
//         "width": 512,
//         "height": 512,
//         "seed": "3755562695",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "1ae8c459-8d53-4f39-bea9-c1e12da9281b",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=5f785be3-7b3c-4625-8fea-9916c859bee6",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/5f785be3-7b3c-4625-8fea-9916c859bee6",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/5f785be3-7b3c-4625-8fea-9916c859bee6",
//         "prompt": "Man named Sam Gray, by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation",
//         "width": 512,
//         "height": 512,
//         "seed": "1414771770",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "5d6c28dd-6926-405d-801e-529352fc37d4",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=673b07fe-c2ae-4c6b-acf5-7f290690e9b0",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/673b07fe-c2ae-4c6b-acf5-7f290690e9b0",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/673b07fe-c2ae-4c6b-acf5-7f290690e9b0",
//         "prompt": "Vintage portrait of a gentleman, highly detailed, digital painting, art by Stanley Lau and Artgerm and magali villeneuve and Alphonse Mucha, artstation, octane render, cgsociety",
//         "width": 512,
//         "height": 512,
//         "seed": "2176384691",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "6baf2632-a844-4833-bd2e-2f8f4f20085a",
//         "nsfw": false,
//         "loadable": true
//     },
    
//     {
//         "id":uuidv4(),
//         "gallery": "https://lexica.art?q=0db1d69e-5a77-4640-bbac-dea171fe0530",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/0db1d69e-5a77-4640-bbac-dea171fe0530",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0db1d69e-5a77-4640-bbac-dea171fe0530",
//         "prompt": "2 0 1 9 hong kong riot portrait by jean honore fragonard. depth of field. high definition. 8 k. photography. ",
//         "width": 1024,
//         "height": 704,
//         "seed": "396663371",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "3f0f08c6-71fb-4fb2-bbba-dfb29a3a8258",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=2ca4335d-ddee-47c0-8a8b-63b7602be33c",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/2ca4335d-ddee-47c0-8a8b-63b7602be33c",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/2ca4335d-ddee-47c0-8a8b-63b7602be33c",
//         "prompt": "kids fighting in a crowded classroom, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face",
//         "width": 896,
//         "height": 512,
//         "seed": "2861436755",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "302b45d4-d745-41ed-a0ed-268177d2c248",
//         "nsfw": false
//     },
//     {
//         "id":uuidv4(),
//         "gallery": "https://lexica.art?q=55c4d517-b708-4a40-8086-4687a29d73aa",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/55c4d517-b708-4a40-8086-4687a29d73aa",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/55c4d517-b708-4a40-8086-4687a29d73aa",
//         "prompt": "annulment of difference by Howard David Johnson by Jeremy Geddes cinematic, coherent, realistic faces, clear, detailed, intricate, dramatic lighting, establishing shot, 8k resolution",
//         "width": 1024,
//         "height": 512,
//         "seed": "2341865312",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "33824bf4-6eed-4161-b0fc-a3d0ab82b62d",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=976188e4-e0e0-42e1-a877-5e17f06da5bf",
//         "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/17cb0d44-3420-49c4-a33d-6af2a00140f6",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/976188e4-e0e0-42e1-a877-5e17f06da5bf",
//         "prompt": "cowboy outlaw sitting on a horse. serigraph. screen - printed. sun - bleached highlights on colt's face, hat in 4 colors  ",
//         "width": 704,
//         "height": 512,
//         "seed": "4000277076",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "e6cffc99-8c75-4e19-bfeb-8f3e4f9161ee",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=ad804ac2-bb43-4d19-b3b2-18e14197d9aa",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/ad804ac2-bb43-4d19-b3b2-18e14197d9aa",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/ad804ac2-bb43-4d19-b3b2-18e14197d9aa",
//         "prompt": "annulment of difference by Howard David Johnson by Jeremy Geddes cinematic, coherent, realistic faces, clear, detailed, intricate, dramatic lighting, establishing shot, 8k resolution",
//         "width": 1024,
//         "height": 512,
//         "seed": "2341865312",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "33824bf4-6eed-4161-b0fc-a3d0ab82b62d",
//         "nsfw": false
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=1e5e4f4e-7934-4e06-901b-1dbe755584f6",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1e5e4f4e-7934-4e06-901b-1dbe755584f6",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1e5e4f4e-7934-4e06-901b-1dbe755584f6",
//         "prompt": "a pirate ship, an underwater landscape painted by, mc escher, gordon onslow ford, georgia o'keeffe and ivan aivazovsky, cinematic light, god rays, unreal engine, zbrush central, ",
//         "width": 704,
//         "height": 512,
//         "seed": "1116630319",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "64c041df-858c-46fb-8b9b-99acd86b284a",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=73b860d8-8987-4cdf-820c-da0cb4b5a758",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/73b860d8-8987-4cdf-820c-da0cb4b5a758",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/73b860d8-8987-4cdf-820c-da0cb4b5a758",
//         "prompt": "the age of discovery, red moon over stormy ocean, huge clouds in the form of a gigantic octopus, the greatest adventurer standing on a 1 5 th century sampan boat holding a medieval compass, dappled silver lighting, epic, atmospheric, highly detailed, by igor morski, jacek yerka, alexander jansson, james christensen, tomek setowski, cinematic ",
//         "width": 1024,
//         "height": 512,
//         "seed": "2441378333",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "9a0e7c4f-7f78-4b1f-b67d-ffbbc8f5de48",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=07b49ea3-ea4c-47bc-880f-d423586dea46",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/07b49ea3-ea4c-47bc-880f-d423586dea46",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/07b49ea3-ea4c-47bc-880f-d423586dea46",
//         "prompt": "cult of pichu, cgsociety, trending on artstation, ultra detailed ",
//         "width": 512,
//         "height": 512,
//         "seed": "3198128825",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 7,
//         "promptid": "993a07ad-594d-44b6-953e-cdb4042261ad",
//         "nsfw": false,
//         "loadable": true
//     },
//     {
//         "id": uuidv4(),
//         "gallery": "https://lexica.art?q=482455e5-dedd-4f5f-bf01-a07e249b0a90",
//         "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/482455e5-dedd-4f5f-bf01-a07e249b0a90",
//         "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/482455e5-dedd-4f5f-bf01-a07e249b0a90",
//         "prompt": "cute cat, by victo ngai and andreas rocha and greg rutkowski, trending on artstation, unreal engine, 8 k hd wallpaperjpeg artifact, blur, artfact ",
//         "width": 640,
//         "height": 960,
//         "seed": "3264102896",
//         "grid": false,
//         "model": "stable-diffusion",
//         "guidance": 11,
//         "promptid": "dced8976-8930-4f22-be36-570b7481c629",
//         "nsfw": false,
//         "loadable": true
//     },
//     // {
//     //     "id": "4c8cdba7-b8f8-4204-b1d8-8d497410a221",
//     //     "gallery": "https://lexica.art?q=4c8cdba7-b8f8-4204-b1d8-8d497410a221",
//     //     "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/4c8cdba7-b8f8-4204-b1d8-8d497410a221",
//     //     "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/4c8cdba7-b8f8-4204-b1d8-8d497410a221",
//     //     "prompt": "cute and fluffy ninja hack'n slash cat fighting with a cool sword, full body, artstation, highly detailed, colorfull, digital painting, deep focus, sharp, smooth, rossdraws, by jason felix by steve argyle by tyler jacobson by peter mohrbacher, cinematic lighting, smooth, sharp focus, hd wallpaper, cinematic ",
//     //     "width": 512,
//     //     "height": 512,
//     //     "seed": "3613859401",
//     //     "grid": false,
//     //     "model": "stable-diffusion",
//     //     "guidance": 6,
//     //     "promptid": "3e0a2694-9df5-4ac3-931a-e3b1f14fd77b",
//     //     "nsfw": false,
//     //     "loadable": true
//     // },
//     // {
//     //     "id": "e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "gallery": "https://lexica.art?q=e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "prompt": "cartoonish kitten wearing a wizard cat and a fake beard floating in space, bright stars, anime, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed",
//     //     "width": 512,
//     //     "height": 512,
//     //     "seed": "1212564430",
//     //     "grid": false,
//     //     "model": "stable-diffusion",
//     //     "guidance": 7,
//     //     "promptid": "c71e6a6a-6f0c-4d1d-b633-8522885e25e3",
//     //     "nsfw": false,
//     //     "loadable": true
//     // },   {
//     //     "id": "e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "gallery": "https://lexica.art?q=e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/e383b006-8392-4647-9c8c-59c7778acaa3",
//     //     "prompt": "cartoonish kitten wearing a wizard cat and a fake beard floating in space, bright stars, anime, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed",
//     //     "width": 512,
//     //     "height": 512,
//     //     "seed": "1212564430",
//     //     "grid": false,
//     //     "model": "stable-diffusion",
//     //     "guidance": 7,
//     //     "promptid": "c71e6a6a-6f0c-4d1d-b633-8522885e25e3",
//     //     "nsfw": false,
//     //     "loadable": true
//     // },
// ];


let randomImageArray = [
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=0490626b-2a2a-4906-9748-6be50b66868a",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/0490626b-2a2a-4906-9748-6be50b66868a",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0490626b-2a2a-4906-9748-6be50b66868a",
        "prompt": "A long shot of a man djing at night under the stars, beautiful, digital art, artstation, hyperrealistic, 8k, unreal engine, octane render, trending on artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha",
        "width": 512,
        "height": 512,
        "seed": "1766256284",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "fab21831-8fab-4ee4-9b07-8baa4bf2d9ec",
        "nsfw": false,
        "loadable": true
    },
    {
        id: uuidv4(),
        gallery: "https://lexica.art?q=1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
        src: "https://lexica-serve-encoded-images.sharif.workers.dev/md/1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
        srcSmall:
            "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
        prompt: "A crackling portal to hell opening in an idyllic heavenly sky, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed",
        width: 704,
        height: 512,
        seed: "63534388",
        grid: false,
        model: "stable-diffusion",
        guidance: 7,
        promptid: "77ed2246-2e7f-46dc-8895-6f567f02bf60",
        nsfw: false,
    },
    {
        id: uuidv4(),
        gallery: "https://lexica.art?q=506a05d5-ef46-4e1e-91a0-5c895ec4e530",
        src: "https://lexica-serve-encoded-images2.sharif.workers.dev/md/0e7804ed-4cde-42d1-8749-0a531d56c2b5",
        srcSmall:
            "https://lexica-serve-encoded-images.sharif.workers.dev/sm/506a05d5-ef46-4e1e-91a0-5c895ec4e530",
        prompt: "portrait of beautiful happy young ana de armas, ethereal, realistic anime, trending on pixiv, detailed, clean lines, sharp lines, crisp lines, award winning illustration, masterpiece, 4 k, eugene de blaas and ross tran, vibrant color scheme, intricately detailed",
        width: 896,
        height: 512,
        seed: "555252570",
        grid: false,
        model: "stable-diffusion",
        guidance: 7,
        promptid: "de021624-6f29-42e5-b75d-881350ebe92e",
        nsfw: false,
    },
    {
        id: uuidv4(),
        gallery: "https://lexica.art?q=0b9e4633-b55b-4510-aaed-89280bf5eebc",
        src: "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F10.png?alt=media&token=c3936f18-d344-4cae-8f8c-d0e417b9383e",
        srcSmall:
            "https://lexica-serve-encoded-images.sharif.workers.dev/sm/0b9e4633-b55b-4510-aaed-89280bf5eebc",
        prompt: "analog style, analog style, perfekt girl, artgerm Style, gerald brom, atey ghailan, mike mignola, vibrant colors, hard shadows, strong rim light, plain background, comic cover art, trending on artstation",
        width: 1280,
        height: 768,
        seed: "557619845",
        grid: false,
        model: "stable-diffusion",
        guidance: 7,
        promptid: "363a0193-6c41-4245-a591-e7caa8553bff",
        nsfw: false,
    },
    {
        id: uuidv4(),
        gallery: "https://lexica.art?q=1e0e3f14-e72a-43b5-8113-bd633c9dde6f",
        src: "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F11.png?alt=media&token=4faf0aea-cfc2-453d-bf4e-0a1b6f210b7c",
        srcSmall:
            "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F11.png?alt=media&token=4faf0aea-cfc2-453d-bf4e-0a1b6f210b7c",
        prompt: "lana del rey perfect woman face, cleavage, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment",
        width: 704,
        height: 512,
        seed: "63534388",
        grid: false,
        model: "stable-diffusion",
        guidance: 7,
        promptid: "77ed2246-2e7f-46dc-8895-6f567f02bf60",
        nsfw: false,
    },
    {
        id: uuidv4(),
        gallery: "https://lexica.art?q=c2e4a5b6-f770-4cb9-8862-44813874a58b",
        src: "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F12.png?alt=media&token=09e6a3f7-2455-4f83-ad31-d0f6325d3032",
        srcSmall:
            "https://lexica-serve-encoded-images.sharif.workers.dev/sm/c2e4a5b6-f770-4cb9-8862-44813874a58b",
        prompt: "pltn style, Cyberpunk futuristic male athlete, head to shoulder pin up portrait, handsomel, muscular, sweating,wearing sports uniform, high contrast, Sharp eyes, sharp photo, matte painting by Stanley Lau artgerm, WLOP saturated colors, 30megapixel, ad photo, , cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
        width: 1024,
        height: 704,
        seed: "1441249749",
        grid: false,
        model: "stable-diffusion",
        guidance: 15,
        promptid: "4dc720ef-6fbd-4ed5-bdc3-71876eea7c45",
        nsfw: false,
    },
        {
                "id": uuidv4(),
                "gallery": "https://lexica.art?q=ced2c7e9-0fa3-40f6-94a3-51708956e4bb",
                "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F13.png?alt=media&token=3921e5b6-3612-48a7-ba15-9a32cfb8a1b3",
                "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/ced2c7e9-0fa3-40f6-94a3-51708956e4bb",
                "prompt": "Photorealistic Character commission art, Digital character art, RPG Custom Character Portrait, DnD commission, fantasy portrait, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger",
                "width": 1280,
                "height": 768,
                "seed": "1411106216",
                "grid": false,
                "model": "stable-diffusion",
                "guidance": 13,
                "promptid": "a4907367-9f95-4802-ad4b-c208f1a94e27",
                "nsfw": false
            },
        
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=13e47d8e-b037-4001-9089-95b385e8c5b8",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F14.png?alt=media&token=b25bff3a-f205-4385-83d8-6faecf6dca0d",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/13e47d8e-b037-4001-9089-95b385e8c5b8",
        "prompt": "art painting, cinematic, 4k, epic Steven Spielberg movie still, sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner",
        "width": 1024,
        "height": 1024,
        "seed": "277116281",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "f2f738b7-6622-43bb-a0e0-65f07f09d828",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=457e4bb3-7132-4d20-9900-d63e93c87356",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F9.png?alt=media&token=caddba24-6c16-4bcf-ba64-02f86ac50ed2",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/457e4bb3-7132-4d20-9900-d63e93c87356",
        "prompt": "life is but a dream woman wearing a see-through lace bodice, delicate face, by Renaud Matgen, head and shoulders portrait, 8k resolution concept art portrait by Greg Rutkowski, Artgerm, WLOP, Alphonse Mucha dynamic lighting hyperdetailed intricately detailed Splash art trending on Artstation triadic colors Unreal Engine 5 volumetric lighting",
        "width": 512,
        "height": 704,
        "seed": "3077255935",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "c8a162cf-0a3a-48a9-afdd-65b04238f687",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=8fbf877c-c54c-4e93-aa79-f013fc04e969",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F16.png?alt=media&token=baebe81b-69d7-468d-bbe2-d7f04e007ade",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/8fbf877c-c54c-4e93-aa79-f013fc04e969",
        "prompt": "pltn style, Adèle Exarchopoulos As The Fierce Stunning Ethereal Goddess Of The Winter Intricate Elegant, Closeup View, White Fur And Black Leather Clothing, Hood, Blood, Fog, Snowflakes, Snow, Celtic Art, Photo Realistic, 32K, Highly Detailed, Digital Painting, Artstation, Concept Art, Smooth, Sharp Focus, Illustration, Art By Artgerm And Greg Rutkowski And Alphonse Mucha, Heavily Influenced By Frank Frazetta And Boris Vallejo, Inpaint Can't Remix!, Designed By HL, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
        "width": 1024,
        "height": 768,
        "seed": "247575506",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 12,
        "promptid": "1b99b963-412f-42ed-83df-895a6b79af2f",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=0490626b-2a2a-4906-9748-6be50b66868a",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F17.png?alt=media&token=0a9c8e30-700b-4554-9100-5f9eb03e5a8f",
        "srcSmall": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F17.png?alt=media&token=0a9c8e30-700b-4554-9100-5f9eb03e5a8f",
        "prompt": "pltn style, hacker bunny wearing a hoodie in the street Cinematic Still Shot , extremly realistic, 4k, photography, autumn, leaves, trees, best quality, samdoesarts, portrait, intricate detail, relaxing, brown, bunny!!!, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
        "width": 512,
        "height": 512,
        "seed": "1766256284",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "fab21831-8fab-4ee4-9b07-8baa4bf2d9ec",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=91b0c3d6-4d41-4845-a1cb-6c618b165d82",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F18.png?alt=media&token=f7b2fd33-e55c-41d1-805e-a4b68f190010",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/91b0c3d6-4d41-4845-a1cb-6c618b165d82",
        "prompt": "night vision sensor Outlaw in the setting Bioshock, trending on artstation, golden glow from the armor, sharp focus, studio photo, intricate details, highly detailed, unreal engine, FullHD resolution concept art portrait by Alex Hacout, Artgerm, WLOP, Alphonse Mucha dynamic lighting detailed Splash art trending on Artstation, cinematic",
        "width": 704,
        "height": 512,
        "seed": "1775880779",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "3e3d5ec2-05fc-4296-b684-e5ffaca443e1",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F19.png?alt=media&token=ea80f67f-4fcb-499a-8f84-f928b4675118",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
        "prompt": "pltn style, Mireille Darc As The Stunning Ethereal Vampire Goddess Of The Winter Intricate Elegant, Closeup View, Fur And Black Leather Clothing, Blood, Fog, Snowflakes, Snow, Photo Realistic, 32K, Highly Detailed, Digital Painting, Artstation, Concept Art, Smooth, Sharp Focus, Illustration, Art By Artgerm And Greg Rutkowski And Alphonse Mucha, Heavily Influenced By Frank Frazetta And Boris Vallejo, Inpaint Can't Remix!, Designed By HL, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
        "width": 512,
        "height": 704,
        "seed": "1766256284",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 8,
        "promptid": "0a6e673e-210b-4758-934f-503e983ec571",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=1489baab-d306-4b1e-b1d9-400b34179f81",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F20.png?alt=media&token=0d293db0-3e2d-4d59-815c-673f8552c79b",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1489baab-d306-4b1e-b1d9-400b34179f81",
        "prompt": "the analog style, dimensional shadows, Beauty, global illumination, z-axis depth, detailed and intricate abstract environment, by Joan Miro, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by luis ricardo falero",
        "width": 512,
        "height": 512,
        "seed": "1462341595",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 13,
        "promptid": "b5f4755f-b79b-4e74-af33-3c5df2ab787f",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=65fb1e4e-3bc3-42bd-b5de-9ceb782d68e6",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F21.png?alt=media&token=54826d61-aafc-4ea9-a49f-039430032efc",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/65fb1e4e-3bc3-42bd-b5de-9ceb782d68e6",
        "prompt": "medium portrait of a grizzly bear biker on a motorcycle, studio lighting, quality photography by Wes Anderson , trending on artstation, sharp focus, studio photo, intricate details, highly detailed, by greg rutkowski",
        "width": 576,
        "height": 704,
        "seed": "3624411895",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "55e8c52c-7ff0-440c-9ab0-8d00751c4dab",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=058865ef-a1ab-4210-b697-f7da0621cba1",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/485e93ad-f7f0-4302-8602-a9410ba324a8",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/058865ef-a1ab-4210-b697-f7da0621cba1",
        "prompt": "girl in solarpunk village, evening, 4 k, ultra realistic, beautiful eyes, epic lighting, starry sky, magical, glowing forest, mushrooms, machines, high detail, masterpiece, trending on artstation by artgerm and akihito tsukushi and alphonse mucha  ",
        "width": 512,
        "height": 768,
        "seed": "87690109",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "4439051a-4365-46a1-9a5a-3a498852712a",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=12a9db6b-3cd8-4786-b24e-d1dc543f5c4a",
        "src": "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/random%2F22.png?alt=media&token=fa0c508c-9dd7-4b6d-82e9-d2cf20b43b7a",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/12a9db6b-3cd8-4786-b24e-d1dc543f5c4a",
        "prompt": "pltn style, a red black white shining goddess, black hair, soft lips, soft clothes, strong lighting, dynamic pose, slightly tilted, rising, round pupils, fine pores, few freckles, colored background, oil on canvas, 3D, Octane Render, high detailed, intricate details, perfect composition, beautiful detailed, insanely detailed, trending on artstation, 4K, artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, cute big circular reflective eyes, Pixar render, unreal engine cinematic smooth, intricate detail",
        "width": 768,
        "height": 512,
        "seed": "2387809719",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "73d694cc-2b91-4486-a4fe-d74eb8682edd",
        "nsfw": false
    },
    // {
    //     "id": uuidv4(),
    //     "gallery": "https://lexica.art?q=1209e8d3-b52a-4cda-9ecb-357fe779621f",
    //     "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1209e8d3-b52a-4cda-9ecb-357fe779621f",
    //     "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1209e8d3-b52a-4cda-9ecb-357fe779621f",
    //     "prompt": "hyperrealistic mixed media collaboration by thomas eakes greg rutkowski xiang duan mike judge, perfect symmetry, dim volumetric lighting, 8 k octane beautifully detailed render, post - processing, portrait, extremely hyper - detailed, intricate, epic composition, highly detailed realistic attributes, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, ",
    //     "width": 512,
    //     "height": 512,
    //     "seed": "2348217735",
    //     "grid": false,
    //     "model": "stable-diffusion",
    //     "guidance": 7,
    //     "promptid": "49293cee-5765-4856-8196-818c04119c67",
    //     "nsfw": false
    // }, 
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=49353f68-a55b-4a46-9d93-1bf900541ed1",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/02feea8d-9c4c-498d-945c-70b99976a576",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/49353f68-a55b-4a46-9d93-1bf900541ed1",
        "prompt": "( ( ( ( ( ( ( ( ( ( ( ( loose watercolor flowers ) ) ) ) ) ) ) ) ) ) ) ) by prafull sawant and michał jasiewicz and eudes correia",
        "width": 512,
        "height": 512,
        "seed": "4175584369",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "8ba0c947-6b7a-4a17-b220-c48ba01ecff3",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=5bf93b0f-7951-425c-a0a6-7e32d0f67e3c",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/4d4107a9-ddba-4590-b406-40b877e7f09c",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/5bf93b0f-7951-425c-a0a6-7e32d0f67e3c",
        "prompt": "kashuu kiyomitsu ; rhododendron flower made of paper in a paper world, fractal, deep, depth, bright stained glass, jigsaw, highly detailed, origami, sophisticated",
        "width": 512,
        "height": 512,
        "seed": "1672098420",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "b647db55-7b3a-448f-8e0a-098b378040ee",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=6a7c2a4d-6ac8-48c7-96f0-8f9295deff3f",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/fbdf4b59-5d70-42cc-b487-2c1a589cf48b",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/6a7c2a4d-6ac8-48c7-96f0-8f9295deff3f",
        "prompt": "detailed artstation style, futuristic utopian fantasy, futuristic - natural - beauty, doorway to utopia, an ornate multi - colored doorway opens on to a fantastical landscape, a place of astonishing beauty and fun, vibrant, fantasy - utopian architecture",
        "width": 512,
        "height": 768,
        "seed": "2886683776",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 16,
        "promptid": "8ee029af-6612-498e-8487-0bd2e2a00158",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=83ce0daf-3018-4675-a179-ae85a35a6237",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/17cb0d44-3420-49c4-a33d-6af2a00140f6",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/83ce0daf-3018-4675-a179-ae85a35a6237",
        "prompt": "cowboy outlaw sitting on a horse. serigraph. screen - printed. sun - bleached highlights on colt's face, hat in 4 colors",
        "width": 512,
        "height": 640,
        "seed": "2889417049",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "e8acdbc7-9438-4840-ab25-3bb5a867dbc0",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=88692024-76e5-4b99-a67d-709b6a33054b",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/8f1bee02-a075-409d-8222-963a4f59c1c7",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/88692024-76e5-4b99-a67d-709b6a33054b",
        "prompt": "a god giving birth to a new universe and then dissolving itself into it, in the style of wlop, illustration, epic, fantasy, hyper detailed, smooth, unreal engine, sharp focus, ray tracing",
        "width": 512,
        "height": 512,
        "seed": "4175584369",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "8ba0c947-6b7a-4a17-b220-c48ba01ecff3",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/50abcfc6-581a-4fa7-88ce-cae6c920bdc5",
        "srcSmall": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/50abcfc6-581a-4fa7-88ce-cae6c920bdc5",
        "prompt": "A masterpiece portrait of a hamster holding a sword. Hamster is wearing a knights helmet. Very detailed. intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano",
        "width": 704,
        "height": 1024,
        "seed": "3280607562",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
        "src": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/89888fb6-ba95-4975-afd0-89b16c7204bf",
        "srcSmall": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/89888fb6-ba95-4975-afd0-89b16c7204bf",
        "prompt": "a character in a cyberpunk world, cyberpunk, cyberpsycho, photorealistic, ultra detailed, harsh neon lights, octane, bokeh, cyber, cyberpunk city, feature, 8k",
        "width": 704,
        "height": 1024,
        "seed": "3280607562",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
        "src": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/5fd9f625-fb38-42c3-97a2-37b0b3ef503d",
        "srcSmall": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/5fd9f625-fb38-42c3-97a2-37b0b3ef503d",
        "prompt": "Abstract painting of a a record player, with chaotic big splashy paint splotches, bright rainbow colour palette, blocky black shapes, contemporary art, modern art, textured, collaged, digitally painted, abstracted, surreal, dreamlike, 8K resolution, vibrant, intense.",
        "width": 704,
        "height": 1024,
        "seed": "3280607562",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
        "src": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/93524f11-3989-48e4-97c5-52d4911afe67",
        "srcSmall": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/93524f11-3989-48e4-97c5-52d4911afe67",
        "prompt": "Abstract painting of a a cityscape, with chaotic big splashy paint splotches, bright rainbow colour palette, blocky black shapes, contemporary art, modern art, textured, collaged, digitally painted, abstracted, surreal, dreamlike, 8K resolution, vibrant, intense.",
        "width": 704,
        "height": 1024,
        "seed": "3280607562",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=ab4ec18a-6421-4eea-b718-cf061059d407",
        "src": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/ebdc5c45-3d43-4bea-aec3-8764ca6b3088",
        "srcSmall": "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/ebdc5c45-3d43-4bea-aec3-8764ca6b3088",
        "prompt": "long shot scenic professional photograph of a waterfall, perfect viewpoint, highly detailed, wide-angle lens, hyper realistic, with dramatic sky, polarizing filter, natural lighting, vivid colors, everything in sharp focus, HDR, UHD, 64K",
        "width": 704,
        "height": 1024,
        "seed": "3280607562",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 10,
        "promptid": "31660e1f-f37c-42fe-9d90-5e74fb207f5e",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/cf562a99-f7e5-4ea0-b7bd-7d23a4c80c64",
        "prompt": "A wide shot of a man djing at night under the stars, beautiful, digital art, artstation, hyperrealistic, 8k, unreal engine, octane render, trending on artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha",
        "width": 512,
        "height": 704,
        "seed": "1766256284",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 8,
        "promptid": "0a6e673e-210b-4758-934f-503e983ec571",
        "nsfw": false,
        "loadable": true
    }, 
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=976188e4-e0e0-42e1-a877-5e17f06da5bf",
        "src": "https://lexica-serve-encoded-images2.sharif.workers.dev/md/17cb0d44-3420-49c4-a33d-6af2a00140f6",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/976188e4-e0e0-42e1-a877-5e17f06da5bf",
        "prompt": "cowboy outlaw sitting on a horse. serigraph. screen - printed. sun - bleached highlights on colt's face, hat in 4 colors  ",
        "width": 704,
        "height": 512,
        "seed": "4000277076",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "e6cffc99-8c75-4e19-bfeb-8f3e4f9161ee",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/8edfaefd-e6b6-45be-9b74-bd6cff2fc9c3",
        "prompt": "product - view of diorama of the shape of the universe, occult cosmology, occult abiogenesis, hyper - detailed, crystals, plastic, rubber, wood, glass, playdoh, slimy, wet, mist, smoke, oil, realistic materials, daylight, fujifilm velvia 5 0, color photography, sigma 2 8 mm, engulfed in neon flames ",
        "width": 704,
        "height": 512,
        "seed": "231582325",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "7d977c2a-db52-42cb-9201-6ebece302c50",
        "nsfw": false
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=1e5e4f4e-7934-4e06-901b-1dbe755584f6",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1e5e4f4e-7934-4e06-901b-1dbe755584f6",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1e5e4f4e-7934-4e06-901b-1dbe755584f6",
        "prompt": "a pirate ship, an underwater landscape painted by, mc escher, gordon onslow ford, georgia o'keeffe and ivan aivazovsky, cinematic light, god rays, unreal engine, zbrush central, ",
        "width": 704,
        "height": 512,
        "seed": "1116630319",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "64c041df-858c-46fb-8b9b-99acd86b284a",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=73b860d8-8987-4cdf-820c-da0cb4b5a758",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/73b860d8-8987-4cdf-820c-da0cb4b5a758",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/73b860d8-8987-4cdf-820c-da0cb4b5a758",
        "prompt": "the age of discovery, red moon over stormy ocean, huge clouds in the form of a gigantic octopus, the greatest adventurer standing on a 1 5 th century sampan boat holding a medieval compass, dappled silver lighting, epic, atmospheric, highly detailed, by igor morski, jacek yerka, alexander jansson, james christensen, tomek setowski, cinematic ",
        "width": 1024,
        "height": 512,
        "seed": "2441378333",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "9a0e7c4f-7f78-4b1f-b67d-ffbbc8f5de48",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=07b49ea3-ea4c-47bc-880f-d423586dea46",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/07b49ea3-ea4c-47bc-880f-d423586dea46",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/07b49ea3-ea4c-47bc-880f-d423586dea46",
        "prompt": "cult of pichu, cgsociety, trending on artstation, ultra detailed ",
        "width": 512,
        "height": 512,
        "seed": "3198128825",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "993a07ad-594d-44b6-953e-cdb4042261ad",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=482455e5-dedd-4f5f-bf01-a07e249b0a90",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/482455e5-dedd-4f5f-bf01-a07e249b0a90",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/482455e5-dedd-4f5f-bf01-a07e249b0a90",
        "prompt": "cute cat, by victo ngai and andreas rocha and greg rutkowski, trending on artstation, unreal engine, 8 k hd wallpaperjpeg artifact, blur, artfact ",
        "width": 640,
        "height": 960,
        "seed": "3264102896",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 11,
        "promptid": "dced8976-8930-4f22-be36-570b7481c629",
        "nsfw": false,
        "loadable": true
    },
    {
        "id": uuidv4(),
        "gallery": "https://lexica.art?q=1209e8d3-b52a-4cda-9ecb-357fe779621f",
        "src": "https://lexica-serve-encoded-images.sharif.workers.dev/md/1209e8d3-b52a-4cda-9ecb-357fe779621f",
        "srcSmall": "https://lexica-serve-encoded-images.sharif.workers.dev/sm/1209e8d3-b52a-4cda-9ecb-357fe779621f",
        "prompt": "hyperrealistic mixed media collaboration by thomas eakes greg rutkowski xiang duan mike judge, perfect symmetry, dim volumetric lighting, 8 k octane beautifully detailed render, post - processing, portrait, extremely hyper - detailed, intricate, epic composition, highly detailed realistic attributes, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, ",
        "width": 512,
        "height": 512,
        "seed": "2348217735",
        "grid": false,
        "model": "stable-diffusion",
        "guidance": 7,
        "promptid": "49293cee-5765-4856-8196-818c04119c67",
        "nsfw": false
    }, 
];


randomImageArray.sort(() => Math.random() - 0.5);

export default randomImageArray;