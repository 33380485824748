import axios from "axios";
import blobToBase64 from "../components/utils/blobToBase64";
import { getLexicaImages } from "../firebaseInit";

// What is seed?
// A number between 0 and 4,294,967,295 that is used as starting point for the image generation

// new one....https://us-central1-aiseo-official.cloudfunctions.net/lexicaImages
const fetchLexicalData1 = (text) => {
    return new Promise(async (resolve, reject) => {
        console.log("TEXT", text);
        // const options = {
        //     url: 'http://localhost:3000/api/home',
        //     method: 'POST',
        //     headers: {
        //       'Accept': 'application/json',
        //       'Content-Type': 'application/json;charset=UTF-8'
        //     },
        //     data: {
        //       name: 'David',
        //       age: 45
        //     }
        //   };

        // const result = await axios.post(`https://us-central1-aiseo-official.cloudfunctions.net/lexicaImages`, options);

        // if(result){
        //     resolve(result.data.slice(0, 48));
        // }
        // https://us-central1-aiseo-official.cloudfunctions.net/lexicaImages
        fetch("http://127.0.0.1:5001/aiseo-official/us-central1/lexicaImages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid: "za52QgcqlZJF0gp7KEdz",
                pid: "lC53NngzPdqolwFxUNG0",
                data: { url: text },
                logs: 0,
            }),
        })
            .then((res) => {
                console.log("RES WE FOUND", res);
                if (res.status === 200) {
                    res.json().then((result) => {
                        resolve(result.data.slice(0, 48));
                    });
                }
            })
            .catch((error) => {
                console.log("GO ERROR", error);
                // resolve(error);
            });
    });
};

// new one....https://us-central1-aiseo-official.cloudfunctions.net/lexicaImages
const fetchLexicalData = (text, calledFor) => {
    return new Promise(async (resolve, reject) => {
        if (calledFor === "variation" || calledFor === "reversal") {
            console.log("CALLED_FOR", calledFor, "POSITION1");

            // lexicalImages...onRequest from aiseo....
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                url: text,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                // redirect: 'follow'
            };

            fetch(
                "https://us-central1-aiseo-official.cloudfunctions.net/lexicaImages",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    // console.log(result);
                    resolve(result.images.slice(0, 48));
                })
                .catch((error) => console.log("error", error));

            // If image reversal is EXAMPLE and PASTE
        } else if (
            calledFor === "example" ||
            calledFor === "paste" ||
            calledFor === "regular"
        ) {
            // console.log("CALLED_FOR", calledFor, "POSITION2");
            // lexicalImages...onRequest from aiseo....
            fetch(
                "https://us-central1-aiseotest.cloudfunctions.net/aiseo/LexicaImages",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        uid: "za52QgcqlZJF0gp7KEdz",
                        pid: "lC53NngzPdqolwFxUNG0",
                        data: { url: text },
                        logs: 0,
                    }),
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    // console.log(result, "LEXICA RESULT 3");
                    if (result) {
                        resolve(result.images.slice(0, 48));
                    }
                })
                .catch((error) => {
                    // console.log("GOT ERROR", text);
                    // resolve(error);
                });
        } else {
            // last
            // console.log("REGULAR ONE", calledFor, text);
            //     return new Promise((resolve, reject) => {
            getLexicaImages({
                url: text,
            }).then((result) => {
                // console.log(result.data.slice(0, 48), "LEXICA RESULT 3");
                // resolve(result);
                resolve(result.data.slice(0, 48));
            });
        }
    });
};

// "replicate" in function's name exists for historical reason
// check the url to know which functions is being used
const imageGeneration_replicate = async (param) => {
    param.seed = parseInt(param.seed);
    param.cfgScale = parseInt(param.cfgScale);
    if (!param.seed) param.seed = Math.ceil(Math.random() * 100000000);
    if (!param.cfgScale) param.cfgScale = 7.5;
    if (!param.steps) param.steps = 30;

    const config = {
        headers: {
            Authorization: `Bearer ${param.token}`,
            "Content-Type": "application/json",
        },
    };

    console.log(param, "Param");

    const providers = ["replicate", "banana-dev", "stability"];
    // const provider =
    const data = {
        prompt: param.text,
        width: param.width,
        height: param.height,
        cfg_scale: param.cfgScale,
        seed: param.seed,
        image_size: param.size,
        steps: param.steps,
        model_name: param.modelName,
        model_version: param.modelVersion,
        // provider: "replicate",
        provider: param.provider,
        // provider: providers[2],
    };

    console.log("data : **********************: ", param.token);
    console.log("data : **********************: ", data);

    // const url = "http://127.0.0.1:4000";
    // const url = "https://us-central1-code-translate-c9640.cloudfunctions.net/image_generation_replicate";

    const url =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/image_generation_v2";

    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(url, data, config);
            console.log(result, "RESULT");

            if (result.data.status === "error") {
                resolve({
                    status: result.data.status,
                    message: result.data.message,
                });
            }

            if (data.provider === "replicate") {
                // from image url to base64 string
                const imageSrc = result.data.images[0];
                const imgResult = await fetch(imageSrc);
                const blob = await imgResult.blob();
                const base64 = await blobToBase64(blob);
                console.log(result.data, "RESULT");
                resolve({
                    steps: param.steps,
                    ...result.data,
                    status: "ok",
                    imageString: base64,
                });
            } else {
                resolve({
                    steps: param.steps,
                    ...result.data,
                    status: "ok",
                    imageString:
                        "data:image/png;base64," + result.data.imageString,
                });
            }
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};

/* export const fetchVariantImages = ({ formData, token }) => {
    const url1 =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/image_variation";
    const maxRetries = 15;

    const attemptFetch = async () => {
        return fetch(url1, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error("Request failed with status " + res.status);
            }
            return res.json();
        });
    };

    const recursiveFetch = (retries) => {
        if (retries >= maxRetries) {
            throw new Error("Maximum retries exceeded");
        }

        return attemptFetch().catch((error) => {
            console.log("Error occurred:", error);
            console.log("Retrying...");

            return recursiveFetch(retries + 1);
        });
    };

    return recursiveFetch(0);
}; */
export const fetchVariantImages = async ({ formData, token }) => {
    let promptApiAttempt = 0;
    let response;

    const url1 =
        "https://us-central1-code-translate-c9640.cloudfunctions.net/image_variation";
    const callImageVariationApi = async () => {
        let resp;
        try {
            promptApiAttempt++;
            if (promptApiAttempt > 1) {
                //setLoadingMsg("We are Generating Scenes again ...");
                console.log("We are Generating Scenes again ...");
            } else {
                //setLoadingMsg("We are Generating Scenes ...");
                console.log("We are Generating Scenes ...");
            }
            /* setLoadingMsg("We are Generating Scenes ..."); */
            console.log("trying try again");

            resp = await axios.post(url1, formData, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });

            if (resp.data) {
                return resp.data;
            }

            //setIsLoading(false);
        } catch (err) {
            //console.log("error occurred trying again");
            console.log(err);
            console.log("We are Generating Scenes again ...");
            // setLoadingMsg("We are Generating Scenes again ...");
            return callImageVariationApi(); // recursive call

            //setIsLoading(false);
        }
    };

    response = await callImageVariationApi();
    // console.log(response.data.data);
    return response;
};
/* export const fetchVariantImages = async ({ formData, token }) => {
    // const numOutputs = parseInt(formData.get("num_outputs"));
    // const promises = Array.apply(null, Array(numOutputs)).map((_, i) => {
    //     return new Promise(async (resolve, reject) => {
    //         formData.set("num_outputs", 1);
    //         try {
    //             const resp = await fetch(
    //                 // "http://127.0.0.1:4000",
    //                 "https://us-central1-code-translate-c9640.cloudfunctions.net/image_variation",
    //                 {
    //                     method: "POST",
    //                     body: formData,
    //                     headers: {
    //                         Authorization: "Bearer " + token,
    //                     },
    //                 }
    //             )
    //             const json = await resp.json();
    //             const url = json.data[0];
    //             resolve(url);
    //         } catch(error){
    //             resolve("");
    //         }
    //     })
    // })

    // Promise.all(promises).then(result => {
    //     console.log(result);
    // })

    console.log(typeof formData.get("num_outputs"));

    return new Promise((resolve, reject) => {
        fetch(
            // "http://127.0.0.1:4000",
            "https://us-central1-code-translate-c9640.cloudfunctions.net/image_variation",
            {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                resolve(data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}; */

export { fetchLexicalData, imageGeneration_replicate };
