import DownloadIcon from "@mui/icons-material/Download";
import { Button, IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../styled/buttons";
import { BiLike, BiDislike } from "react-icons/bi";
import { onAuth } from "../../firestore/authHandlers";
import { addReaction } from "../../firestore/dataHandler";
import mixpanel from "mixpanel-browser";
import { setSelectedImagVariation } from "../../features/image/ImageVariation";

import {
    fileUploaded,
    inpaintingStarted,
    inpaintingSucceed,
    inpaintingFaild,
    setInpainting,
} from "../../features/image/ImageInpainting";
import { IoFlashSharp } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { setSelectedImageOnStore } from "../../features/image/CommandSlice";

function ImageContainer({
    imageEraseRefhandler,
    imageinstructRefhandler,
    base64,
    title,
    userGivenTitle,
    selectedSize,
    setSelectedImageMaineFile,
}) {
    const src = base64;
    const navigate = useNavigate();
    const generatedImage = useSelector(
        (state) => state.image.selectedGeneratdImage
    );
    const dispatch = useDispatch();
    const [liked, setLiked] = React.useState(null);

    console.log(generatedImage, "SELECTED GENERATED IMAGE");

    const downloadImage = (e) => {
        e.preventDefault();
        // mixpanel.track("download image - art generation");
        // console.log('Download..')
        // return;
        // console.log(src, "SRC")
        // return;
        const a = document.createElement("a"); //Create <a>
        const imageTitle = userGivenTitle.slice(0, 20);
        a.href = src;
        a.download = imageTitle.split(" ").join("_") + "_img.png"; //File name Here
        a.click();
    };

    const handleReaction = (e, state) => {
        e.preventDefault();
        console.log("Image Liked");
        console.log(generatedImage);
        if (state === "liked") {
            setLiked(true);
            mixpanel.track("like image - art generation");
        } else {
            setLiked(false);
            mixpanel.track("dislike image - art generation");
        }
        onAuth(function (user) {
            if (!user) return;
            let reaction = {
                email: user.email,
                uid: user.uid,
                imageid: generatedImage.id,
                url: generatedImage.url,
                prompt: generatedImage.prompt,
            };
            if (state === "liked") reaction.liked = true;
            else reaction.disliked = true;
            addReaction(reaction);
        });
    };

    const openWithEditor = () => {
        navigate({
            pathname: "/image/" + generatedImage.id,
        });
    };

    return (
        <Box
            item
            xs={12}
            sm={12}
            sx={{
                width: "100%",
                position: "relative",
            }}
        >
            {/* <div style={{ position: "relative", margin: "0 auto", width: "100%" }}> */}
            <Box
                sx={{
                    margin: "0 auto",
                    marginBottom: "3rem",
                    maxWidth: `${selectedSize.width}px`,
                    width: `100%`,
                    height: `auto`,
                    /* width: `${selectedSize.width}px`,
                    height: `${selectedSize.height}px`, */
                    position: "relative",
                    boxShadow:
                        "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;",
                    padding: "0.7rem",
                    borderRadius: "1rem",
                }}
            >
                <img
                    src={src}
                    alt={userGivenTitle}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "1rem",

                        /* boxShadow:
                            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px 0px;", */
                    }}
                />
                {/* <Button onClick={downloadImage} variant="outlined" sx={{ mt: 1 }}>
                    <DownloadIcon /> Download
                </Button> */}
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "3.8rem",
                        right: "1.3rem",
                        color: "black",
                        display: "flex",
                    }}
                    style={{
                        gap: "0.6rem",
                    }}
                >
                    <Tooltip title="Image Instruct" followCursor>
                        <Button
                            /* sx={{
                                position: "absolute",
                                right: "135px",
                                bottom: 0,
                                marginBottom: "1rem",
                                background: "white",
                                color: "black",
                            }} */
                            sx={{
                                background: "rgba(255, 255, 255, 0.9)",
                                color: "#6363FF",

                                "&:hover": {
                                    background: "rgba(255, 255, 255, 1)",
                                    color: "#6363FF",
                                },
                                borderRadius: "0.6rem",
                            }}
                            onClick={() => {
                                /* imageinstructRefhandler();
                                setSelectedImageMaineFile(src);

                                // console.log("image.src", src)

                                dispatch(
                                    setSelectedImageOnStore({
                                        selectedImages: src,
                                    })
                                ); */
                                console.log("asdsa asdasd");

                                navigate("/products/instruct", {
                                    state: { selectedImageFromRoute: src },
                                });
                                /* navigate("./products/instruct", {
                                    state: { selectedImageFromRoute: src },
                                }); */
                            }}
                        >
                            <RiEdit2Fill fontSize={23} />
                            {/* <MdOutlineIntegrationInstructions  fontSize={23} /> */}
                        </Button>
                    </Tooltip>

                    <Tooltip title="Enhance" followCursor>
                        <Button
                            onClick={() => openWithEditor()}
                            /* sx={{
                                position: "absolute",
                                right: "60px",
                                bottom: 0,
                                marginBottom: "1rem",
                                background: "white",
                                color: "black",
                            }} */
                            sx={{
                                background: "rgba(255, 255, 255, 0.9)",
                                color: "#6363FF",

                                "&:hover": {
                                    background: "rgba(255, 255, 255, 1)",
                                    color: "#6363FF",
                                },
                                borderRadius: "0.6rem",
                            }}
                        >
                            <IoFlashSharp fontSize={23} />
                        </Button>
                    </Tooltip>
                    <Button
                        onClick={downloadImage}
                        sx={{
                            background: "#6363FF",
                            color: "white",

                            "&:hover": {
                                background: "#5555D7",
                            },
                            borderRadius: "0.6rem",
                        }}
                    >
                        <DownloadIcon sx={{ color: "white" }} />
                    </Button>
                </Box>
                {/* <Tooltip title="Image Instruct" followCursor>
                    <Button
                        sx={{
                            position: "absolute",
                            right: "135px",
                            bottom: 0,
                            marginBottom: "1rem",
                            background: "white",
                            color: "black",
                        }}
                        onClick={() => {
                            imageinstructRefhandler();
                            setSelectedImageMaineFile(src);

                            // console.log("image.src", src)

                            dispatch(
                                setSelectedImageOnStore({
                                    selectedImages: src,
                                })
                            );
                        }}
                    >
                        <RiEdit2Fill fontSize={23} />
                    </Button>
                </Tooltip>

                <Tooltip title="Enhance" followCursor>
                    <Button
                        onClick={() => openWithEditor()}
                        sx={{
                            position: "absolute",
                            right: "60px",
                            bottom: 0,
                            marginBottom: "1rem",
                            background: "white",
                            color: "black",
                        }}
                    >
                        <IoFlashSharp fontSize={23} />
                    </Button>
                </Tooltip> */}

                {/* <Tooltip title="Erase & Replace" followCursor>
          <Button
            sx={{
              position: "absolute",
              right: "135px",
              bottom: 0,
              marginBottom: "1rem",
              background: "white",
              color: "black",
            }}
            onClick={() => {
              imageEraseRefhandler();

              dispatch(
                fileUploaded({
                  selectedImageInpainting: src,
                  canvasWidth: 512,
                  canvasHeight: 512,
                })
              );
            }}
          >
            <RiEdit2Fill fontSize={23} />
          </Button>
        </Tooltip> */}
                {/* <StyledButton onClick={downloadImage}>
                    <DownloadIcon sx={{ color: "black" }} />
                </StyledButton> */}

                <IconButton
                    title="like"
                    onClick={(e) => handleReaction(e, "liked")}
                    color={liked ? "primary" : "inherit"}
                >
                    <BiLike />
                </IconButton>
                <IconButton
                    title="dislike"
                    onClick={(e) => handleReaction(e, "disliked")}
                    color={liked !== null && !liked ? "primary" : "inherit"}
                >
                    <BiDislike />
                </IconButton>
            </Box>
            {/* <StyledButton onClick={() => openWithEditor()} sx={{ mr: "5rem" }}>
                Open With Editor
            </StyledButton> */}
            {/* <StyledButton onClick={downloadImage}>
                <DownloadIcon />
            </StyledButton> */}

            {/* <Box
                sx={{
                    position: "absolute",
                    width: `${selectedSize.width}px`,
                    height: `${selectedSize.height}px`,
                }}>
                <StyledButton onClick={downloadImage}>
                    <DownloadIcon />
                </StyledButton>
            </Box> */}
        </Box>
    );
}

export default function DisplayImages({
    imageEraseRefhandler,
    images,
    selectedSize,
    setSelectedImageMaineFile,
    imageinstructRefhandler,
}) {
    console.log(selectedSize);
    return (
        <>
            {/* <Typography align="center" sx={{ fontSize: "1.5rem", lineHeight: "2.25rem" }}>
                <strong>Generated Images</strong>
            </Typography> */}
            <Grid container spacing={0} sx={{ margin: "0 auto" }}>
                {images.length !== 0
                    ? images.map((item, i) => {
                          return (
                              <ImageContainer
                                  imageinstructRefhandler={
                                      imageinstructRefhandler
                                  }
                                  setSelectedImageMaineFile={
                                      setSelectedImageMaineFile
                                  }
                                  imageEraseRefhandler={imageEraseRefhandler}
                                  base64={item.imageString}
                                  key={item.id}
                                  title={item.title}
                                  userGivenTitle={item.userGivenTitle}
                                  selectedSize={selectedSize}
                              />
                          );
                      })
                    : null}
            </Grid>
        </>
    );
}
