import React, { useState, useEffect } from "react";
import stripeSvgIcon from "../../imgs/stripeSvgIcon.svg";
import whiteCheckIcon from "../../imgs/whiteCheckIcon.png";
import aiAvatarsPricingCoverImg from "../../imgs/aiAvatarsPricingCoverImg.png";
import { Box, Button, Typography, Link } from "@mui/material";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import AiAvatarsPricingCard from "./AiAvatarsPricingCard";
import { getCustomer } from "../../firestore/dataHandler";
import { checkout } from "../../firebaseInit";
import mixpanel from "mixpanel-browser";

const featuresDataArr = [
    "Describe your ideal avatar and let AISEO's AI bring it to life.",
    "Enjoy high-resolution, detailed photoshoots every time.",
    "Use the albums feature to manage and group your creations with ease.",
    "Get your photoshoots instantly, perfect for quick needs.",
    "Fine-tune and adjust until you achieve the perfect visual representation.",
];

const yearlyPricingData = [
    {
        name: "Photoshoots_Pro",
        title: "Pro",
        desc: "Take 500x 4k AI Photos And Train 2 Models Per Month",
        price: 9,
        freq: "year",
        models: 2,
        photos: 500,
    },
    {
        name: "Photoshoots_Premium",
        title: "Premium",
        desc: "Take 2500x 4k AI Photos And Train 10 Models Per Month",
        price: 29,
        freq: "year",
        models: 10,
        photos: 2500,
    },
    {
        name: "Photoshoots_Business",
        title: "Business",
        desc: "Take 10000x 4k AI Photos And Train 50 Models Per Month",
        freq: "year",
        price: 99,
        models: 50,
        photos: 10000,
    },
];

const monthlyPricingData = [
    {
        name: "Photoshoots_Pro",
        title: "Pro",
        desc: "Take 500x 4k AI Photos And Train 2 Models Per Month",
        price: 14,
        freq: "month",
        models: 2,
        photos: 500,
    },
    {
        name: "Photoshoots_Premium",
        title: "Premium",
        desc: "Take 2500x 4k AI Photos And Train 10 Models Per Month",
        price: 49,
        freq: "month",
        models: 10,
        photos: 2500,
    },
    {
        name: "Photoshoots_Business",
        title: "Business",
        desc: "Take 10000x 4k AI Photos And Train 50 Models Per Month",
        price: 149,
        freq: "month",
        models: 50,
        photos: 10000,
    },
];

const AiAvatarsPricing = ({ user }) => {
    const navigate = useNavigate();
    const currentPlan = "Premium";
    const [userSelectedPricingFreq, setUserSelectedPricingFreq] =
        useState("yearly");
    const [cardsSelected, setCardsSelected] = useState([false, true, false]);

    useEffect(() => {
        mixpanel.track("AIPHOTOSHOOTS - Pricing Page - visited", {
            Description:
                "Ai photoshoots - Pricing Page - visited after clicking upgrade from photoshoots pricing popup",
            /* category: event.target.value, */
        });
    }, []);

    const getActivePricing = () => {
        let activeIndex = 0;
        cardsSelected.forEach((it, idx) => {
            if (it === true) {
                activeIndex = idx;
            }
        });

        if (userSelectedPricingFreq === "monthly") {
            return monthlyPricingData[activeIndex];
        } else {
            return yearlyPricingData[activeIndex];
        }
    };

    async function handlePurchaseButtonClick(e) {
        console.log("Purchase Button Clicked");
        const item = getActivePricing();
        if (user) {
            try {
                const customer = await getCustomer();
                let checkoutParam = {
                    planType: item.name,
                    planFreq: item.freq,
                    customerId: customer?.id || null,
                    price: item.price,
                    promocode: true,
                };

                mixpanel.track("AIPHOTOSHOOTS - Stripe Page - visited", {
                    Description:
                        "Ai photoshoots - Stripe Page - visited after selecting a plan and continue from photoshoots pricing page",
                    UserEmail: user?.email,
                    /* category: event.target.value, */
                });

                console.log(checkoutParam, "--checkoutParam--");

                const result = await checkout(checkoutParam);
                const data = await result.data;
                const url = JSON.parse(data).url;

                window.location.href = url;
            } catch (error) {
                console.log(error);
            } finally {
                //setPurchasing(true);
            }
        } else {
            return navigate("/login?flow=pricing");
        }
    }
    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
            }}
        >
            <Box
                sx={{
                    background: "#111111",
                    color: "white",
                    width: { xs: "100vw", md: "40vw" },
                    padding: { xs: "1.5rem 2rem", md: "3rem 6rem" },
                }}
            >
                <Link
                    href="/ai-photoshoots"
                    sx={{
                        display: "flex",
                        gap: "0.3rem",
                        alignItems: "center",
                        textDecoration: "none",
                    }}
                >
                    <HiChevronLeft size={20} color="white" />
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "0.9rem",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            lineHeight: "1rem",
                            textTransform: "capitalize",
                        }}
                    >
                        Return to AI Photoshoots
                    </Typography>
                </Link>
                <Box
                    sx={{
                        marginTop: "2rem",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "35px",
                            fontStyle: "normal",
                            fontWeight: "900",
                            lineHeight: "37px",
                            letterSpacing: "-0.768px",
                            background:
                                "linear-gradient(92deg, #DE60FF -0.92%, #4BDEFF 105.77%)",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        }}
                    >
                        AISEO Photoshoots
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "35px",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "37px",
                            letterSpacing: "-0.768px",
                            color: "white",
                        }}
                    >
                        Perfect Photoshoot in Moments
                    </Typography>
                </Box>
                <Box>
                    <Box
                        sx={{
                            width: "100%",
                            height: "auto",
                            marginTop: "2rem",
                        }}
                    >
                        <img
                            alt=""
                            src={aiAvatarsPricingCoverImg}
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.6rem",
                            maxWidth: "70%",
                            marginTop: "1.6rem",
                        }}
                    >
                        {featuresDataArr.map((featureText, index) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.4rem",
                                }}
                                key={index + 1}
                            >
                                <img
                                    alt=""
                                    src={whiteCheckIcon}
                                    style={{
                                        width: "0.9rem",
                                        height: "0.9rem",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "Inter",
                                        fontSize: "0.9rem",
                                        fontStyle: "normal",
                                        fontWeight: "normal",
                                        lineHeight: "1rem",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {featureText}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.45rem",
                            alignItems: "center",
                            marginTop: "1.5rem",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Inter",
                                fontSize: "0.9rem",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "1rem",
                                textTransform: "capitalize",
                                opacity: "0.9",
                            }}
                        >
                            Powered by{" "}
                        </Typography>
                        <img
                            alt=""
                            src={stripeSvgIcon}
                            style={{
                                width: "50px",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    /* background: "#111111",
                    color: "white", */
                    background: "white",
                    width: { xs: "100vw", md: "60vw" },
                    padding: { xs: "1.5rem 2rem", md: "3rem 6rem" },
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            color: "#202224",
                            fontFamily: "Inter",
                            fontSize: "0.9rem",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            lineHeight: "1rem",
                            textTransform: "capitalize",
                        }}
                    >
                        Plans
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontSize: "40px",
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: "42px",
                            letterSpacing: "-0.768px",
                            color: "#202224",
                            marginTop: "1.6rem",
                        }}
                    >
                        Upgrade your plan
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.78rem",
                            marginTop: "1.6rem",
                        }}
                    >
                        <Typography
                            sx={{
                                color:
                                    userSelectedPricingFreq === "monthly"
                                        ? "#202224"
                                        : "rgba(32, 34, 36, 0.6)",
                                fontFamily: "Inter",
                                fontSize: "0.9rem",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "1rem",
                                textTransform: "capitalize",
                                padding: "0.4rem 0.65rem",
                                borderRadius: "0.6rem",
                                border:
                                    userSelectedPricingFreq === "monthly"
                                        ? "2px solid #202224"
                                        : "2px solid rgba(32, 34, 36, 0.3)",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setUserSelectedPricingFreq("monthly")
                            }
                        >
                            Monthly
                        </Typography>
                        <Typography
                            sx={{
                                color:
                                    userSelectedPricingFreq !== "monthly"
                                        ? "#202224"
                                        : "rgba(32, 34, 36, 0.6)",
                                fontFamily: "Inter",
                                fontSize: "0.9rem",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                lineHeight: "1rem",
                                textTransform: "capitalize",
                                padding: "0.4rem 0.65rem",
                                borderRadius: "0.6rem",
                                border:
                                    userSelectedPricingFreq !== "monthly"
                                        ? "2px solid #202224"
                                        : "2px solid rgba(32, 34, 36, 0.3)",
                                cursor: "pointer",
                            }}
                            onClick={() => setUserSelectedPricingFreq("yearly")}
                        >
                            Yearly
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            marginTop: "1.8rem",
                            display: "flex",
                            alignItems: "center",
                            gap: "1.2rem",
                            flexDirection: { xs: "column", md: "row" },
                        }}
                    >
                        {userSelectedPricingFreq === "monthly"
                            ? monthlyPricingData.map((item, index) => (
                                  <AiAvatarsPricingCard
                                      item={item}
                                      key={index}
                                      index={index}
                                      currentPlan={currentPlan}
                                      cardsSelected={cardsSelected}
                                      setCardsSelected={setCardsSelected}
                                      user={user}
                                  />
                              ))
                            : yearlyPricingData.map((item, index) => (
                                  <AiAvatarsPricingCard
                                      item={item}
                                      key={index}
                                      index={index}
                                      currentPlan={currentPlan}
                                      cardsSelected={cardsSelected}
                                      setCardsSelected={setCardsSelected}
                                      user={user}
                                  />
                              ))}
                    </Box>
                    <Box>
                        <Box>
                            {/* <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "1.3rem",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    color: "rgba(32, 34, 36, 0.9)",
                                    marginTop: "0.9rem",
                                }}
                            >
                                Subtotal: $29 per month
                            </Typography> */}
                        </Box>
                        <Button
                            sx={{
                                background: "#6366F1",
                                color: "white",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                padding: "0.7rem 1.5rem",
                                borderRadius: "0.4rem",
                                margin: "1rem 0 0 0",

                                "&:hover": {
                                    background: "#5356C2",
                                },
                            }}
                            onClick={(e) => handlePurchaseButtonClick(e)}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AiAvatarsPricing;
