import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomButton = styled(ButtonBase)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f4f4f4",
    // ...theme.typography.body2,
    background: "white",
    padding: theme.spacing(.75),
    margin: theme.spacing(2),
    opacity: 0.9,
    borderRadius: "10px",
    position : "absolute",
    right :0,
    bottom :0
}));

export const StyledButton = ({ children, onClick }) => {
    return <CustomButton onClick={onClick}>{children}</CustomButton>;
};
