import React, { useState, useEffect, useRef, Suspense } from "react";
import {
    Box,
    Dialog,
    Button,
    ButtonGroup,
    Card,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    Tooltip,
    Skeleton,
    Slide,
    CircularProgress,
    Container,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import {
    HiOutlineEye,
    HiOutlinePencil,
    HiOutlinePlus,
    HiOutlineSearch,
    HiOutlineFolderAdd,
    HiOutlineArrowSmRight,
    HiOutlineXCircle,
    HiExternalLink,
} from "react-icons/hi";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { updateViewNumber } from "../../firestore/templateHandler";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseInit";
import NeedToBuyAPlan from "../model/NeedToBuyAPlan";
import ToastNotification from "../atoms/ToastNotification";
import { useDispatch, useSelector } from "react-redux";
import { uploadToStorage } from "../../firestore/storageHandler";
import {
    getAllPublicTemplates,
    getAllTemplates,
    updateTemplateByVote,
} from "../../firestore/templateHandler";
import {
    serverTimestamp,
    collection,
    setDoc,
    getFirestore,
    addDoc,
    onSnapshot,
    doc,
} from "firebase/firestore";
import { firebaseApp, getFirebaseAuthToken } from "../../firebaseInit";
import {
    customTemplatesFetched,
    templatesFetched,
    templatesSortedSet,
} from "../../features/templates/templateSlice";
import TemplateCardLatest from "./TemplateCardLatest";
import TemplateCardLatestV2 from "./TemplateCardLatestV2";
import Loader from "../Loader";
import WarningModal from "../form/WarningModal";
import OptionSelected from "../atoms/OptionSelected";

const TemplatesNew = ({ user }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const db = getFirestore(firebaseApp);
    const dispatch = useDispatch();
    const [searchTermDefault, setSearchTermDefault] = useState("");
    const [sortBy, setSortBy] = useState("mostlyused"); // State for storing the selected sort by option
    const [myTemplates, setMyTemplates] = useState([
        {
            viewQty: 200,
            usedQty: 700,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 100,
            usedQty: 800,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 400,
            usedQty: 900,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 40,
            usedQty: 1000,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 200,
            usedQty: 700,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 100,
            usedQty: 800,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 400,
            usedQty: 900,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 40,
            usedQty: 1000,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 200,
            usedQty: 700,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 100,
            usedQty: 800,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 400,
            usedQty: 900,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 40,
            usedQty: 1000,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 200,
            usedQty: 700,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
        {
            viewQty: 100,
            usedQty: 800,
            url: "https://storage.googleapis.com/code-translate-c9640.appspot.com/templates/60ba1dc1-906b-46bf-9977-2535b5d6c90f",
        },
    ]);
    const [templatesAreLoading, setTemplatesAreLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [loginPopup, setLoginPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [formData, setFormData] = useState({
        category: "",
        description: "",
        imageFile: null,
        imageURL: "",
        accepted: false,
    });
    const [showValidationError, setShowValidationError] = useState(false);

    let templates = useSelector((state) => state.templates.templates);

    useEffect(() => {
        getAllTemplates().then((data) => {
            data.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            dispatch(
                templatesFetched({
                    templates: data.sort((a, b) => b.usedQty - a.usedQty),
                })
            );
        });
    }, []);

    const handlePublicTemplatesBtnClick = () => {
        navigate("/templates?tab=public");
    };

    const resetFormData = () => {
        console.log("reset");
        setFormData({
            category: "",
            description: "",
            imageFile: null,
            imageURL: "",
            accepted: false,
        });
    };

    const handleSubmit = async () => {
        setShowSuccessPopup(false);
        setShowValidationError(false);

        if (!formData.category || !formData.description) {
            // Handle validation errors here
            console.log("Both category and description are required");
            setShowValidationError(true);
            setShowSuccessPopup(false);

            return;
        }

        setIsLoading(true);

        let imageUrl = null;

        if (formData.imageFile) {
            try {
                const uploadResult = await uploadToStorage({
                    file: formData.imageFile,
                    uid: "your-uid",
                }); // Replace 'your-uid' with the actual uid
                imageUrl = uploadResult.url;
            } catch (error) {
                console.error("Error uploading the image:", error);
                return;
            }
        }

        const dataToSubmit = {
            name: formData.category,
            description: formData.description,
            imageUrl: imageUrl,
            accepted: false,
        };
        console.log(dataToSubmit);

        // Now, you can push this data to your Firebase collection
        let reqTemplateRef;
        try {
            // setIsLoading(true);

            // setLoadingMsg("Sending Scenes over to AISEO ...");

            reqTemplateRef = await addDoc(
                collection(db, "templateRequest"),
                dataToSubmit
            );
            setIsLoading(false);
            setShowSuccessPopup(true);

            //setIsLoading(false);
        } catch (err) {
            console.log("Firebase write error:" + err);
            /* setIsLoading(false);
            setErrMsg("Couldn't Send Scenes to AISEO ");
            setIsErr(true); */
        }
        console.log("Document written with ID: ", reqTemplateRef.id);
        resetFormData();
    };

    const handleSearchInputChange = (e) => {
        setSearchTermDefault(e.target.value.toLowerCase());
    };

    const handleSortByChange = (event) => {
        //setRenderedMoreThanOnce(true);
        const sortBy = event.target.value;
        setSortBy(sortBy);

        // Create a copy of the templates array
        const sortedTemplates = [...templates];

        // Sort the templates based on the selected sortBy option
        if (sortBy === "mostlyviewed") {
            sortedTemplates.sort((a, b) => b.viewQty - a.viewQty);
        } else if (sortBy === "leastviewed") {
            sortedTemplates.sort((a, b) => a.viewQty - b.viewQty);
        } else if (sortBy === "mostlyused") {
            sortedTemplates.sort((a, b) => b.usedQty - a.usedQty);
        } else if (sortBy === "leastused") {
            sortedTemplates.sort((a, b) => a.usedQty - b.usedQty);
        } else if (sortBy === "default") {
            /* dispatch(
                templatesFetched({
                    templates: [...templates],
                })
            ); */
            dispatch(
                templatesFetched({
                    templates: templates.map((template) => {
                        const { createdAt, ...templateWithoutCreatedAt } =
                            template;
                        return templateWithoutCreatedAt;
                    }),
                })
            );
            return;
        }

        // Update the state with the sorted templates
        //console.log(sortedTemplates);
        dispatch(
            templatesFetched({
                templates: sortedTemplates.map((template) => {
                    const { createdAt, ...templateWithoutCreatedAt } = template;
                    console.log(templateWithoutCreatedAt, "dsads");
                    return templateWithoutCreatedAt;
                }),
            })
        );
        /* dispatch(
            templatesFetched({
                templates: [...sortedTemplates],
            })
        ); */
        /* dispatch(templatesSortedSet(sortedTemplates)); */
    };
    return (
        <Container>
            <WarningModal
                open={loginPopup}
                onToggle={() => setLoginPopup(!loginPopup)}
                message="Please log in with your account or make a new one to start generating images."
            />

            <Box
                sx={{
                    marginTop: "1rem",
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: "1.6rem", md: "3rem" },
                        fontWeight: "semibold",
                        textAlign: "center",
                    }}
                >
                    Artful AI Templates
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                    }}
                >
                    Kickstart Your Creative Journey with Ready-to-Use
                    Inspirations
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    gap: "1rem",
                    justifyContent: "center",
                    margin: "2.5rem 0",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "70%", md: "37%" },
                    }}
                >
                    <TextField
                        placeholder="Search"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <HiOutlineSearch size={20} />
                                </InputAdornment>
                            ),
                        }}
                        value={searchTermDefault}
                        onChange={handleSearchInputChange}
                        sx={{
                            width: "100%",
                        }}
                    />
                </Box>
                <FormControl variant="outlined" size="small">
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-by-label"
                        value={sortBy}
                        label="Sort By"
                        sx={{ width: { xs: "100%", md: "200px" } }}
                        onChange={handleSortByChange}
                        size="small"
                    >
                        {/*  <MenuItem value="option1">Option 1</MenuItem>
                        <MenuItem value="option2">Option 2</MenuItem> */}
                        {/* <MenuItem value="default">Default</MenuItem> */}
                        <MenuItem value="mostlyused">Mostly Used</MenuItem>
                        <MenuItem value="leastused">Least Used</MenuItem>
                        <MenuItem value="mostlyviewed">Mostly Viewed</MenuItem>
                        <MenuItem value="leastviewed">Least Viewed</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    sx={{
                        background: "#6363FF",
                        color: "white",
                        border: "2px solid #6363FF",
                        /* width: "130px", */
                        borderRadius: "2rem",
                        paddingX: "1rem",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#5050D6",
                        },
                        position: "relative",
                        textTransform: "capitalize",
                    }}
                    onClick={() => handlePublicTemplatesBtnClick()}
                >
                    <Typography
                        sx={{
                            marginRight: "0.5rem",
                        }}
                    >
                        Public Templates
                    </Typography>
                    <HiExternalLink size={22} />
                </Button>
                {/* <Button
                    sx={{
                        background: "#6366F1",
                        color: "white",
                        "&:hover": {
                            background: "#5658D0",
                        },
                        textTransform: "capitalize",
                        width: "200px",
                        padding: "0.5rem",
                    }}
                    size="medium"
                >
                    Request Template
                </Button> */}
                <Button
                    sx={{
                        background: "#6363FF",
                        color: "white",
                        border: "2px solid #6363FF",
                        /* width: "130px", */
                        borderRadius: "2rem",
                        paddingX: "1rem",
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "#5050D6",
                        },
                    }}
                    onClick={() => {
                        if (!user) {
                            setLoginPopup(true);
                            //dispatch(generationFailed({ errorMessage: "" }));
                            return;
                        }
                        setOpenModal(true);
                        setShowSuccessPopup(false);
                        console.log("Henlo");
                    }}
                    size="medium"

                    /* onClick={() =>
                        navigate("../customTemplate", { replace: true })
                    } */
                >
                    <Typography
                        sx={{
                            textTransform: "capitalize",
                            marginRight: "0.3rem",
                        }}
                    >
                        {/* Add  */}Request Template
                    </Typography>
                    <HiOutlineFolderAdd size="20" />
                </Button>
            </Box>
            <Suspense fallback={<Loader />}>
                <Box>
                    <Grid container spacing={2}>
                        {templates
                            .filter((item) =>
                                item.category
                                    .toLowerCase()
                                    .includes(searchTermDefault)
                            )
                            .map((template, j) => (
                                <Grid item key={j} xs={12} sm={6} md={4} lg={3}>
                                    <Box
                                        /* to={`/templates/${template.id}`} */

                                        style={{
                                            textDecoration: "none",
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TemplateCardLatestV2
                                            template={template}
                                            key={j}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            </Suspense>
            <Dialog
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                    resetFormData();
                }}
                sx={{
                    "& > div > div": {
                        background:
                            theme.palette.mode === "light"
                                ? "white"
                                : "#1E293B",
                        borderRadius: "0.5rem",
                        maxWidth: { xs: "100vw", md: "70vw" },
                        width: { xs: "100vw", md: "fit-content" },
                        overflowY:
                            window.innerWidth < 321 ? "scroll" : "hidden",
                        overflowX: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        maxHeight: { xs: "98vh", md: "90vh" },
                        position: "relative",
                        margin: "0px",
                    },
                    "& > div": {
                        padding: "0",
                    },
                }}
            >
                <Box sx={{ padding: "2rem" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
                        >
                            Request Template
                        </Typography>
                        <IconButton
                            variant="outlined"
                            onClick={() => {
                                setOpenModal(false);
                                resetFormData();
                                setShowValidationError(false);
                            }}
                            sx={{
                                width: "fit-content",
                                padding: "0",
                            }}
                        >
                            <HiOutlineXCircle size={30} />
                        </IconButton>
                    </Box>
                    <TextField
                        required
                        label="Category"
                        fullWidth
                        margin="normal"
                        value={formData.category}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                category: e.target.value,
                            })
                        }
                    />
                    <TextField
                        required
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        value={formData.description}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                    />
                    {/* <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                imageFile: e.target.files[0],
                            })
                        }
                    /> */}
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <InputLabel
                            htmlFor="image-upload"
                            sx={{
                                backgroundColor: "#e8e8ff",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                border: "2px dashed #6363FF",
                                textAlign: "center",
                            }}
                        >
                            {formData.imageFile
                                ? formData.imageFile.name
                                : "Click to select an image"}
                        </InputLabel>
                        <input
                            type="file"
                            id="image-upload"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(e) =>
                                setFormData((prev) => ({
                                    ...prev,
                                    imageFile: e.target.files[0],
                                }))
                            }
                        />
                    </Box>
                    {showValidationError ? (
                        <Box
                            sx={{
                                background: "#FFE4E4",
                                color: "red",
                                padding: "0.3rem 0.7rem",
                                borderRadius: "0.4rem",
                                marginTop: "0.35rem",
                            }}
                        >
                            <Typography>
                                ! Please fill up category and description
                            </Typography>
                        </Box>
                    ) : null}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        {/* <IconButton
                            variant="outlined"
                            onClick={() => {
                                setOpenModal(false);
                                resetFormData();
                                setShowValidationError(false);
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                            }}
                        >
                            <HiOutlineXCircle size={30} />
                        </IconButton> */}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleSubmit();
                            }}
                            sx={{
                                borderRadius: "2rem",
                                textTransform: "capitalize",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                marginTop: "0.6rem",
                            }}
                        >
                            <span>
                                {isLoading ? "Sending..." : "Send Request"}
                            </span>
                            <HiOutlineArrowSmRight size={25} />
                        </Button>
                    </Box>
                </Box>
                {showSuccessPopup && (
                    <ToastNotification
                        message="Image generated Successfully"
                        type="success"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
            </Dialog>
        </Container>
    );
};

export default TemplatesNew;
