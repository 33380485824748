import React, { useState, useEffect } from "react";
import LayoutV3 from "../layout/LayoutV3";
import FooterV3 from "../footer/FooterV3";
import { Box, Typography, Grid, CircularProgress, Link } from "@mui/material";
import {
    collection,
    query,
    where,
    getDocs,
    getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "../../firebaseInit";
import { useTheme } from "@mui/material/styles";
import VideoCard2 from "./VideoCard2";

const MyVideosV2 = ({ user }) => {
    const theme = useTheme();
    const db = getFirestore(firebaseApp);
    const [videoRefs, setVideoRefs] = useState([]);

    const [myVideosData, setMyVideosData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getDocumentsByEmail() {
            setIsLoading(true);
            const q = query(
                collection(db, "Idea2Motions"),
                where("email", "==", user.email)
            );

            const querySnapshot = await getDocs(q);

            let docs = [];
            querySnapshot.forEach((doc) => {
                let currDocData = doc.data();
                if (
                    currDocData.video_url !== "" &&
                    currDocData.progress !== 0
                ) {
                    docs.push({ id: doc.id, ...doc.data() });
                }
            });

            setMyVideosData(docs);
            setIsLoading(false);

            console.log(docs, "docs - io");
        }
        if (user?.email) {
            getDocumentsByEmail();
        }
    }, [user]);

    useEffect(() => {
        if (myVideosData && myVideosData.length > 0) {
            videoRefs.current = new Array(myVideosData.length).fill(null);
        }
        setVideoRefs((myVideosData) =>
            Array(myVideosData.length)
                .fill()
                .map((_, i) => myVideosData[i] || React.createRef())
        );
    }, [myVideosData]);

    return (
        <LayoutV3 footer={<FooterV3 />}>
            <Box
                sx={{
                    background: "#0F172A",
                    color: "white",
                    padding: { xs: "0.7rem 0.5rem", md: "3rem 6rem" },

                    fontFamily: "Inter",
                }}
            >
                {isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "5rem",
                        }}
                    >
                        <CircularProgress size={70} />
                    </Box>
                ) : myVideosData.length > 0 ? (
                    <>
                        <Typography
                            sx={{
                                color: "#FFF",

                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: { xs: "22px", md: "30px" },
                                fontStyle: "normal",
                                fontWeight: "600",
                                letterSpacing: "-0.225px",
                            }}
                        >
                            My Videos
                        </Typography>
                        <Typography
                            sx={{
                                color: "#FFF",

                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: { xs: "14px", md: "16px" },
                                fontStyle: "normal",
                                fontWeight: "500",
                                marginTop: "0.8rem",
                            }}
                        >
                            A showcase of your AI-generated video creations.
                        </Typography>

                        <Grid
                            container
                            spacing={5}
                            sx={{
                                marginTop: "3rem",
                            }}
                        >
                            {myVideosData.map(
                                (item, index) =>
                                    item.video_url !== "" &&
                                    item.progress !== 0 && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            key={index}
                                        >
                                            <VideoCard2
                                                key={item.key}
                                                item={item.video_url}
                                                index={index}
                                                videoRefs={videoRefs}
                                                theme={theme}
                                                handleMouseEnter
                                                handleMouseLeave
                                            />
                                        </Grid>
                                    )
                            )}
                        </Grid>
                    </>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1.5px solid #475569",
                            borderRadius: "0.8rem",
                            padding: "3rem 3.5rem",
                            width: "fit-content",
                            margin: "3rem auto",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                textAlign: "center",
                                color: "white",
                                fontSize: { xs: "22px", md: "30px" },
                                fontWeight: "bold",
                            }}
                        >
                            No Videos Yet!
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                textAlign: "center",
                                color: "white",
                                marginTop: "0.9rem",
                                marginBottom: "30px",
                            }}
                        >
                            Dive in and transform your ideas into engaging
                            videos with just a few clicks.
                        </Typography>

                        <Link
                            href="/video"
                            sx={{
                                background: "#6366F1",
                                color: "white",
                                padding: "1rem 26px",
                                borderRadius: "0.5rem",
                                fontFamily: "Inter",
                                fontWeight: "600",
                                textTransform: "capitalize",
                                textDecoration: "none",

                                "&:hover": {
                                    background: "#4B4DB8",
                                    transition: "all 0.15s ease-in",
                                },
                            }}
                        >
                            Get started now!
                        </Link>
                    </Box>
                )}
            </Box>
        </LayoutV3>
    );
};

export default MyVideosV2;
