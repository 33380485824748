import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebaseInit";
import { handleLogout } from "../../firestore/authHandlers";
import imgsrc from "../../imgs/aiseo-symble.png";
import useDeviceChecker from "../hooks/useDeviceChecker";
import { useAPI } from "./../../StateProvider";
import { ImCross } from "react-icons/im";
import CancelIcon from "@mui/icons-material/Cancel";

import { styled } from "@mui/material/styles";
import DarkModeSwitch from "../styled/DarkModeSwitch";
import { onGettingGeneratedImages } from "../../firestore/dreamboothHandler";
import { useEffect } from "react";
import { readOrCreateDocument } from "../../firestore/showBannerHandler";
const drawerWidth = 300;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? "rgba(31, 31, 31,.6)"
      : "rgba(255,255,255,0.8)",
  borderBottom: "1px solid lightgray",
  color: theme.palette.text.primary,
}));

function Nav(props) {
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const appbarRef = React.useRef(null);
  const location = useLocation();
  const device = useDeviceChecker();
  const [user, loading] = useAuthState(auth);
  const [showTopbar, setShowTopbar] = React.useState(true);
  const [haveModel, setHaveModel] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showExtBanner, setShowExtBanner] = React.useState(false);
  const [hideBanner, setHideBanner] = React.useState(true);
  const theme = useTheme();

  const { data, dataFetched } = useAPI();
  // console.log("DATA", data);fsdfsdfd
  let subscription = data?.subscription;

  // const [fixedNav, setFixednav] = React.useState(false);

  let navItems;

  React.useEffect(() => {
    const handleScrolling = () => {
      const navHeight = appbarRef.current.getBoundingClientRect().height;
      const scrolltop = document.documentElement.scrollTop;
      if (scrolltop > navHeight) {
        setShowTopbar(false);
      } else {
        setShowTopbar(true);
      }
    };
    document.addEventListener("scroll", handleScrolling);
    return () => {
      document.removeEventListener("scroll", handleScrolling);
    };
  }, [appbarRef]);

  React.useEffect(() => {
    // console.log("New model user: ", user);
    user &&
      onGettingGeneratedImages((data) => {
        const newImageGenerationModel = data.filter(
          (item) => item.status === "succeeded"
        );
        newImageGenerationModel.length > 0 && setHaveModel(true);
      });
  });

  if (user) {
    navItems = [
      // {
      //     title: "🔥 Earn Free Credits",
      //     to: "/rewards",
      // },

      {
        title: "Chrome Extension",
        to: "/ai-avatars",
      },
      {
        title: "AI Avatars",
        to: "/ai-avatars",
      },
      {
        title: "Art",
        to: "/",
      },
      {
        title: "Dashboard",
        to: "/dashboard",
      },
      {
        title: "Pricing",
        to: "/pricing",
      },
      {
        title: "FAQ",
        to: "/faq",
      },
    ];
  } else {
    navItems = [
      {
        title: "Chrome Extension",
        to: "/ai-avatars",
      },
      {
        title: "AI Avatars",
        to: "/ai-avatars",
      },
      {
        title: "Art",
        to: "/",
      },
      {
        title: "Pricing",
        to: "/pricing",
      },
      {
        title: "FAQ",
        to: "/faq",
      },
      // {
      //     title: "🔥 Free Credits",
      //     to: "/rewards",
      // },
      // {
      //   title: "Login",
      //   to: "/login",
      // },
    ];
  }

  //   document.addEventListener("scroll", handleScrolling);
  //   return () => {
  //     document.removeEventListener("scroll", handleScrolling);
  //   };
  // }, [appbarRef]);

  if (user) {
    navItems = [
      // {
      //     title: "🔥 Earn Free Credits",
      //     to: "/rewards",
      // },
      {
        title: "Chrome Extension",
        to: "https://chrome.google.com/webstore/detail/aiseo-art-extension/hbdpcocpnklcmdnencjgopobbmmjeadb",
      },
      {
        title: "Blog Img Gen",
        to: "https://aiseo.ai/blog-images/index.html",
      },
      {
        title: "My Models",
        to: "/ai-avatars/imageGeneration",
      },

      {
        title: "Art",
        to: "/",
      },
      {
        title: "Dashboard",
        to: "/dashboard",
      },
      {
        title: "Pricing",
        to: "/pricing",
      },
      // {
      //   title: "SERP Images",
      //   to: "/serpimage",
      // },
    ];
  } else {
    navItems = [
      {
        title: "Chrome Extension",
        to: "https://chrome.google.com/webstore/detail/aiseo-art-extension/hbdpcocpnklcmdnencjgopobbmmjeadb",
      },
      {
        title: "Blog Img Gen",
        to: "https://aiseo.ai/blog-images/index.html",
      },
      {
        title: "Art",
        to: "/",
      },
      {
        title: "Pricing",
        to: "/pricing",
      },
      // {
      //     title: "🔥 Free Credits",
      //     to: "/rewards",
      // },
      // {
      //   title: "Login",
      //   to: "/login",
      // },
    ];
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 2,
          cursor: "pointer",
        }}
      >
        <img
          src={imgsrc}
          style={{
            width: "auto",
            height: "28px",
            marginRight: ".5rem",
          }}
          alt=""
        />
        <Typography variant="h6" sx={{ my: 2 }}>
          AISEO
        </Typography>
        <Typography variant="h6" sx={{ my: 2 }}>
          Art
        </Typography>
      </Box>
      <List>
        {navItems.map((item) => (
          <Link to={item.to} key={item.to} style={{ textDecoration: "none" }}>
            <MobileNavItem
              title={item.title}
              location={location}
              currentPath={location.pathname === item.to}
            />
          </Link>
        ))}

        {/* show the free credit to only Appsumo1 Users... */}
        {/* show the free credit to only Appsumo1 Users... */}
        {subscription && subscription.type === "Appsumo1" ? (
          <Link to="/rewards" style={{ textDecoration: "none" }}>
            <Button sx={{}}>🔥 Free Credits</Button>
          </Link>
        ) : null}

        {user ? (
          <div onClick={handleLogout}>
            <MobileNavItem title="Logout" currentPath={false} />
          </div>
        ) : null}

        <a
          style={{ textDecoration: "none" }}
          href="https://aiseo-art.frill.co/roadmap"
          target="_blank"
          rel="noreferrer"
        >
          <MobileNavItem title="Roadmap" location="#" currentPath={false} />
        </a>
      </List>
      <Divider />{" "}
      <Box sx={{ p: 2 }}>
        <Typography variant="body2">
          <strong>AISEO Tools</strong>
        </Typography>
        {[
          {
            title: "Paraphrasing Tool",
            href: "https://aiseo.ai/tools/paraphrasing-tool.html",
          },
          {
            title: "Readability Improver",
            href: "https://aiseo.ai/tools/hemingway-editor.html",
          },
          
          {
            title: "Ourank Article",
            href: "https://aiseo.ai/article-writer/index.html",
          },
          {
            title: "Bypass AI detection",
            href: "https://aiseo.ai/AI-tools/bypass-ai.html",
          },
          {
            title: "Blog Img Gen",
            href: "https://aiseo.ai/blog-images/index.html",
          },
          {
            title: "Humanize AI Text",
            to: "https://humanizeaitext.ai/",
        },
        ].map((item, i) => (
          <a
            style={{
              textDecoration: "none",
              color: theme.palette.mode === "dark" ? "#eee" : "#121212",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={item.href}
            key={i}
          >
            <Typography variant="body2" sx={{ pt: 2 }}>
              {item.title}
            </Typography>
          </a>
        ))}
      </Box>
      <Divider />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { pathname } = useLocation();
  // console.log(pathname, "PathName ----- ");

  const getNavButtonVariant = (to) => {
    // console.log(to, "PathName To-*** ");
    if (to === "/") {
      if (to === pathname) return "contained";
      else return "text";
    } else {
      return to === pathname ? "contained" : "text";
    }
  };

  const changeNavigation = () => {
    navigate("../upgrade-subscription");
  };
  let isRemove = localStorage.getItem("bannerRemove");

  useEffect(() => {
    isRemove = localStorage.getItem("bannerRemove");
    setShowBanner(isRemove);
  }, [showBanner]);
  // useEffect(() => {
  //   isRemove = localStorage.getItem("bannerExtRemove");
  //   setShowExtBanner(isRemove);
  // }, [showExtBanner]);
  // console.log("isRemove", isRemove);

  let subscriptionData = data?.subscription;

  const handleLoginNav = () => {
    navigate("../login");
  };

  useEffect(() => {
    // if (!user) return;
    const isClicked = false;
    // console.log("Consoled log for user: *** ------------");
    readOrCreateDocument(user?.uid, isClicked).then((data) => {
      // console.log("Consoled log for data ------------", data);
      data?.hide && setHideBanner(true);
      data?.message === "Document does not exist" && setHideBanner(false);
      // !data?.hide && setHideBanner(false);
    });
  }, [user]);
  // console.log("User details", data.subscription?.type);
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "100%" }}>
        <StyledAppBar
          ref={appbarRef}
          component="nav"
          elevation={0}
          position={showTopbar ? "absolute" : "fixed"}
        >
          {showTopbar && device !== "sm" ? (
            <>
              <TopBar data={data} user={user} showTopbar={showTopbar} />
              {pathname === "/" && user?.uid && (
                <ExtensionPopup
                  uid={user?.uid}
                  user={user}
                  setShowExtBanner={setShowExtBanner}
                  isClicked={showExtBanner}
                  hideBanner={hideBanner}
                  setHideBanner={setHideBanner}
                />
              )}
            </>
          ) : null}

          <Box
            sx={{
              display: `${showBanner ? "none" : "block"}`,
              position: "relative",
            }}
          >
            {user && subscription?.type === "Growth" && (
              <Typography
                sx={{
                  background:
                    "linear-gradient(89.31deg, #EF54FD 7.09%, #9A6BFF 48.86%, #62B4FF 96.2%)",
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: 17,
                  padding: "4px",
                  color: "white",
                }}
              >
                Get unlimited Image generations & variations with Scale plan
                (Get 2 months free ✌️ with Annual)
                <Button
                  onClick={changeNavigation}
                  sx={{
                    backgroundColor: "#fff",
                    color: "#EF54FD",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    fontWeight: 500,
                    fontSize: 11,
                    padding: "4px",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#62B4FF",
                    },
                  }}
                >
                  upgrade
                </Button>
              </Typography>
            )}
            {user && subscription?.type === "Scale" && (
              <Typography
                sx={{
                  backgroundColor: "#8cb2d7",
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: 17,
                  padding: "4px",
                  color: "white",
                }}
              >
                Get unlimited Image generation & variations with Scale Annual
                (Get 2 months free ✌️)
                <Button
                  onClick={changeNavigation}
                  sx={{
                    backgroundColor: "#9440fa",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    fontWeight: 400,
                    fontSize: 11,
                    textTransform: "capitalize",
                    padding: "4px",
                    color: "white",
                    marginLeft: "10px",
                    "&:hover": {
                      backgroundColor: "#5915ad",
                    },
                  }}
                >
                  upgrade
                </Button>
              </Typography>
            )}
            <Box
              onClick={() => {
                setShowBanner(true);
                localStorage.setItem("bannerRemove", true);
              }}
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
                cursor: "pointer",
              }}
            >
              <ImCross color="#fff" />
            </Box>
          </Box>

          <Toolbar style={{ minHeight: "54px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={imgsrc}
              style={{
                width: "auto",
                height: "1.8rem",
                marginRight: ".5rem",
                cursor: "pointer",
              }}
              alt=""
              onClick={() => navigate("../")}
            />
            <Typography
              variant="h6"
              component="div"
              onClick={() => navigate("../")}
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block", cursor: "pointer" },
              }}
            >
              AISEO Art
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map(
                (item) =>
                  item.title === "My Models" ? (
                    haveModel && (
                      <Link
                        to={item.to}
                        key={item.title}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button variant={getNavButtonVariant(item.to)} sx={{}}>
                          {item.title}
                        </Button>
                      </Link>
                    )
                  ) : item.title === "Chrome Extension" || item.title === "Blog Img Gen" ? (
                    <a
                      target="_blank"
                      href={item.to}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button variant={getNavButtonVariant(item.to)} sx={{}}>
                        {item.title}
                      </Button>
                    </a>
                  ) : (
                    <Link
                      to={item.to}
                      key={item.title}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button
                        disableElevation
                        variant={getNavButtonVariant(item.to)}
                      >
                        {item.title}
                      </Button>
                    </Link>
                  )
                // ) : (
                //   item.title !== "My Models" &&
                //   haveModel && (
                //     <Link
                //       to={item.to}
                //       key={item.title}
                //       style={{ textDecoration: "none" }}
                //     >
                //       <Button sx={{}}>{item.title}</Button>
                //     </Link>
                //   )
                // )
              )}

              {/* show the free credit to only Appsumo1 Users... */}

              <Link to="/rewards" style={{ textDecoration: "none" }}>
                <Button sx={{}}>🔥 Free Credits</Button>
              </Link>
              {device !== "sm" ? (
                <a
                  style={{ textDecoration: "none" }}
                  href="https://aiseo-art.frill.co/roadmap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>Roadmap</Button>
                </a>
              ) : null}
              {/* {user ? (
                <Link to="/rewards" style={{ textDecoration: "none" }}>
                  <Button sx={{}}>🔥 Free Credits</Button>
                </Link>
              ) : null} */}
            </Box>
            {user && device !== "sm" ? (
              <Button onClick={handleLogout} sx={{}}>
                Logout
              </Button>
            ) : (
              <Button onClick={handleLoginNav} sx={{}}>
                Login
              </Button>
            )}
          </Toolbar>
        </StyledAppBar>
      </Box>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

const MobileNavItem = (props) => {
  return (
    <ListItem disablePadding>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: 1,
        }}
      >
        <Box
          sx={{
            width: "3px",
            bgcolor: `${props.currentPath ? "primary.main" : "transparent"}`,
          }}
        ></Box>
        <ListItemButton sx={{ height: "40px", color: "primary.main" }}>
          <ListItemText primary={props.title} />
        </ListItemButton>
      </Box>
    </ListItem>
  );
};

const TopBarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  // display: "flex",
  alignItems: "center",
  background: theme.palette.mode === "dark" ? "" : "#f7f7f7",
}));

const TopBar = ({ showTopbar, user, data }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const changeNavigation = () => {
    navigate("../upgrade-subscription");
  };

  let subscription = data?.subscription;

  return (
    <TopBarWrapper sx={{ px: 3 }}>
      {/* left side */}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <strong>AISEO Tools:</strong>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {[
            {
              title: "Paraphrasing Tool",
              href: "https://aiseo.ai/tools/paraphrasing-tool.html",
            },
            {
              title: "Readability Improver",
              href: "https://aiseo.ai/tools/hemingway-editor.html",
            },
            {
              title: "Ourank Article",
              href: "https://aiseo.ai/article-writer/index.html",
            },
            {
              title: "Bypass AI detection",
              href: "https://aiseo.ai/AI-tools/bypass-ai.html",
            },
            {
              title: "Blog Img Gen",
              href: "https://aiseo.ai/blog-images/index.html",
            },
            {
              title: "Humanize AI Text",
              to: "https://humanizeaitext.ai/",
          },
          ].map((item, i) => (
            <a
              style={{
                textDecoration: "none",
                margin: "0 0.5rem",
                color: theme.palette.mode === "dark" ? "#eee" : "#121212",
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={item.href}
              key={i}
            >
              <Typography variant="body2">{item.title}</Typography>
            </a>
          ))}
        </Box>
      </Box>
      {/* right side */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {user && subscription?.type === "Growth" && (
          <Typography
            onClick={changeNavigation}
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              textDecoration: "underline",
              color: "#9a6bff",
              cursor: "pointer",
            }}
          >
            Get unlimited credits
          </Typography>
        )}
        {user && subscription?.type === "Scale" && (
          <Button
            // onClick={changeNavigation}   #EF54FD 7.09%, #9A6BFF 48.86%, #62B4FF
            sx={{
              backgroundColor: "transparent",
              textAlign: "center",
              fontFamily: "Helvetica",
              fontWeight: 600,
              fontSize: 13,
              textTransform: "capitalize",

              color: "#62B4FF",
              borderBottom: "1px solid #EF54FD",
              marginTop: "2px",

              background:
                "linear-gradient(89.31deg, #EF54FD 7.09%, #9A6BFF 48.86%, #62B4FF 96.2%)",
              backgroundClip: "text",
              textFillColor: "transparent",

              marginLeft: "10px",
              "&:hover": {
                backgroundColor: "#5915ad",
              },
            }}
          >
            Get 2 months free ✌️
          </Button>
        )}
        {/* <Link
                    to="/rewards"
                    style={{
                        textDecoration: "none",
                        // margin: "0 0.5rem",
                    }}>
                     <Typography variant="body2">🔥 Earn Free Credits</Typography>
                </Link> */}
        {/* <Button onClick={colorMode.toggleColorMode}>Toggle Mode</Button> */}
        <DarkModeSwitch />
      </Box>
    </TopBarWrapper>
  );
};

export default Nav;

const ExtensionPopup = ({
  setHideBanner,
  hideBanner,
  isClicked = true,
  uid,
  setShowExtBanner,
  user,
}) => {
  const { data } = useAPI();
  const extBtnHandler = () => {
    if (data?.subscription && data?.subscription.type !== "Free") {
      readOrCreateDocument(uid, isClicked).then((data) =>
        console.log(data)
      );
      setTimeout(() => {
        setShowExtBanner(true);
        setHideBanner(true);
      }, 1000);
    } else {
      // console.log("SUBSCRIPTION NOT DEFIENEDs");
      // setShowExtBanner(true);
      setHideBanner(true);
    }

    // localStorage.setItem("bannerExtRemove", true);
  };

  useEffect(() => {
    // console.log("Consoled log user ------------", user);
    setShowExtBanner(true); // isCheck ke true korar jnne

    // if (!data.subscription || data?.subscription.type === "Free") {
    //   // console.log("SUBSCRIPTION NOT DEFIENEDs");
    //   setHideBanner(false);
    // }
  }, []);

  return (
    <Box
      sx={{
        display: `${hideBanner ? "none" : "block"}`,
      }}
    >
      <Box sx={popupStyle}>
        <Typography sx={titileExt}>
          AISEO Art Chrome
          <small style={{ marginLeft: "10px" }}>
            Generate image variations, everywhere on the web.
          </small>
        </Typography>
        <Box>
          <a
            href="https://chrome.google.com/webstore/detail/aiseo-art-extension/hbdpcocpnklcmdnencjgopobbmmjeadb"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" sx={chromebtn}>
              Add To Chrome
            </Button>
          </a>
          <Box onClick={() => extBtnHandler()} sx={{ cursor: "pointer" }}>
            <Box sx={crossStyle}>
              <CancelIcon sx={{ color: "#262525" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const crossStyle = {
  position: "absolute",
  top: "-10px",
  right: "-10px",
  border: "1px solid #262525",
  borderRadius: "50%",
  background: "#fff",
  height: "20px",
  width: "20px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const popupStyle = {
  position: "relative",
  textAlign: "center",
  border: "2px solid #e6e6e6",
  background:
    "linear-gradient(166deg, rgb(204 172 233) 7%, rgb(160 190 243) 50%, rgb(226 190 255) 93%)",
  width: "95%",
  margin: "0 auto",
  padding: "6px 30px",
  borderRadius: "5px",
  color: "#000",
  fontFamily: "Inter",

  display: "none",
  justifyContent: "space-between",
};
const chromebtn = {
  border: "1px solid #e6e6e6",
  color: "#fff",
  padding: "4px 10px",
  background: "#262525",
  fontSize: "11px",
  borderRadius: "20px",
  "&:hover": {
    color: "rgba(154,71,227,1)",
    background: "#fff",
  },
};

const titileExt = {
  display: "flex",
  justifyContent: "space-between",
  alignSelf: "center",
  fontSize: "20px",
  alignItems: "center",
};
