import React, { useState, useRef } from "react";
import axios from "axios";
import AudioLoader from "./AudioLoader.svg";
import {
    Container,
    Typography,
    Box,
    Button,
    Slider,
    Slide,
    Divider,
    TextField,
} from "@mui/material";
import {
    HiOutlineClock,
    HiOutlineChip,
    HiOutlineMusicNote,
    HiOutlineExclamationCircle,
} from "react-icons/hi";
import Layout from "../layout/Layout";
import Footer from "../footer/Footer";
import UseCaseOptionsBox from "./UseCaseOptionsBox";
import { useTheme } from "@mui/material/styles";
import UseCasePromptsBox from "./UseCasePromptsBox";
import ToastNotification from "../atoms/ToastNotification";
import { getFirebaseAuthToken } from "../../firebaseInit";
import {
    serverTimestamp,
} from "firebase/firestore";
import { useAPI } from "../../StateProvider";
import NeedToBuyAPlan from "../model/NeedToBuyAPlan";
import { useNavigate, useLocation } from "react-router-dom";
import { useCaseArr } from "./useCaseArr";
import { promptsForUseCaseArr } from "./promptsForUseCaseArr";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AudioGenerator = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = useAPI();
    const promptInputRef = useRef();
    const defaultActiveUseCase = "all";
    const theme = useTheme();
    const [activeUseCase, setActiveUseCase] = useState(defaultActiveUseCase);
    const [currentActivePromptArr, setCurrentActivePromptArr] =
        useState(promptsForUseCaseArr);
    const [durationValue, setDurationValue] = useState(8);
    const [activePrompt, setActivePrompt] = useState("");
    const [recentAudios, setRecentAudios] = useState([]);
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmptyPrompt, setIsEmptyPrompt] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [userProhibited, setUserProhibited] = useState(false);

    const handleUseCaseOptionClick = (currentUseCase) => {
        const lowercaseCurrentUseCase = currentUseCase.toLowerCase();

        setActiveUseCase(lowercaseCurrentUseCase);

        if (lowercaseCurrentUseCase !== "all") {
            const filteredPromptsArr = getFilteredPromptsArr(
                lowercaseCurrentUseCase
            );
            setCurrentActivePromptArr(filteredPromptsArr, promptsForUseCaseArr);
        } else {
            resetCurrentActivePromptArr(promptsForUseCaseArr);
        }
    };

    const getFilteredPromptsArr = (
        lowercaseCurrentUseCase,
        prevUnfilteredArr
    ) => {
        const filteredPromptsArr = promptsForUseCaseArr.filter((prompt) => {
            return prompt.promptType.toLowerCase() === lowercaseCurrentUseCase;
        });

        return filteredPromptsArr;
    };

    const resetCurrentActivePromptArr = () => {
        setCurrentActivePromptArr(promptsForUseCaseArr);
    };

    const handleDurationChange = (event, newValue) => {
        setDurationValue(newValue);
    };

    const handlePromptChange = (currPrompt) => {
        if (currPrompt !== "") {
            setIsEmptyPrompt(false);
        }
        setActivePrompt(currPrompt);
    };

    const handleUserCheck = (user, location, navigate) => {
        if (!user) {
            setIsLoggedIn(false);
            setTimeout(() => {
                if (location.pathname === "/audio") {
                    navigate("/login");
                }
            }, 3000);
            return false;
        }
        return true;
    };

    const handleSubscriptionCheck = (data) => {
        let newCost = 0;
        if (durationValue < 21) {
            newCost = 0.5 * durationValue;
        } else {
            newCost = 10;
        }
        if (
            !data.subscription ||
            data?.subscription.type === "Free" ||
            data?.credits < newCost ||
            (data?.credits ?? null) === null
        ) {
            console.log("SUBSCRIPTION NOT DEFINED");
            setUserProhibited(true);
            return false;
        }
        /* if (
            !data.vid_subscription ||
            data?.vid_subscription.type === "Free" ||
            data?.vid_credits < durationValue ||
            (data?.vid_credits ?? null) === null
        ) {
            console.log("SUBSCRIPTION NOT DEFINED");
            setUserProhibited(true);
            return false;
        } */
        return true;
    };

    const handlePromptCheck = (activePrompt) => {
        if (activePrompt === "") {
            setIsEmptyPrompt(true);
            autoFocusPromptTextArea();
            return false;
        }
        return true;
    };

    const handleGenerateAudio = async (activePrompt, durationValue) => {
        console.log("loading");
        setIsLoading(true);

        // const API_URL =
        //     "https://us-central1-code-translate-c9640.cloudfunctions.net/API_music/";
        const API_URL = "http://127.0.0.1:8080/";
        const token = await getFirebaseAuthToken();
        const data = {
            api_type: "text_to_music",
            prompt: activePrompt.toString(),
            duration: Number(durationValue),
        };
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        console.log(config, "config")
        try {
            const response = await axios.post(API_URL, data, config);
            const resData = response.data.data;
            console.log("Response:", resData);

            let prevRecentAudios = recentAudios;
            console.log(prevRecentAudios, "prevRecentAudios");

            prevRecentAudios.push({
                ...resData,
                time_stamp: serverTimestamp(),
                user_email: user.email,
            });

            setRecentAudios(prevRecentAudios);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error:", error.message);
            setIsError("Couldn't generate Audio. Please try again later");
        }
    };

    const handleGenerateAudioClick = async () => {
        if (!handleUserCheck(user, location, navigate)) return;
        if (!handleSubscriptionCheck(data)) return;
        if (!handlePromptCheck(activePrompt)) return;
        handleGenerateAudio(activePrompt, durationValue);
    };

    const autoFocusPromptTextArea = () => {
        const textarea = promptInputRef.current.querySelector("textarea");

        // Check if the textarea exists before setting focus
        if (textarea) {
            textarea.focus();
        }
    };
    return (
        <Layout footer={<Footer />}>
            {/* {userProhibited ? (
                <>
                    <StartTrialPopup
                        lockWarning={userProhibited}
                        setLockWarning={setUserProhibited}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                </>
            ) : null} */}
            {userProhibited ? (
                <>
                    <NeedToBuyAPlan
                        lockWarning={userProhibited}
                        setLockWarning={setUserProhibited}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        type="free"
                    />
                </>
            ) : null}
            <Container
                sx={{
                    padding: { xs: "0.7rem 0.5rem", md: "0.8rem 0" },
                }}
            >
                {!isLoggedIn && (
                    <ToastNotification
                        message="Please log in to use this feature."
                        type="neutral"
                        duration={3000}
                        position="bottomLeft"
                    />
                )}
                {isError ? (
                    <ToastNotification
                        message={isError}
                        type="error"
                        duration={30000}
                        position="bottomLeft"
                    />
                ) : null}

                <UseCaseOptionsBox
                    useCaseArr={useCaseArr}
                    activeUseCase={activeUseCase}
                    handleUseCaseOptionClick={handleUseCaseOptionClick}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        margin: "2rem 0",
                        gap: "4rem",
                    }}
                >
                    <UseCasePromptsBox
                        currentActivePromptArr={currentActivePromptArr}
                        handlePromptChange={handlePromptChange}
                        activeUseCase={activeUseCase}
                    />

                    <Box
                        sx={{
                            padding: "0.5rem 0",
                            flexGrow: "1",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "0.34rem",
                                alignItems: "center",
                            }}
                        >
                            <HiOutlineMusicNote size={20} />
                            <Typography
                                sx={{
                                    fontSize: "1.1rem",
                                    fontWeight: "bold",
                                }}
                            >
                                Generate Music
                            </Typography>
                        </Box>
                        <TextField
                            sx={{
                                width: { xs: "100%", md: "60%" },
                                marginTop: "1rem",
                                "& > div": {
                                    borderRadius: "0.5rem",
                                },
                            }}
                            multiline
                            rows={4}
                            placeholder="Describe the music you want or write the 1st line"
                            value={activePrompt}
                            onChange={(e) => handlePromptChange(e.target.value)}
                            ref={promptInputRef}
                        />
                        {isEmptyPrompt ? (
                            <Box
                                sx={{
                                    margin: "0.6rem 0",
                                    color: "red",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.45rem",
                                }}
                            >
                                <HiOutlineExclamationCircle size={20} />
                                <Typography>Please include a prompt</Typography>
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "300px",
                                },
                                display: "flex",
                                flexDirection: "column",
                                margin: "1rem 0",
                            }}
                            style={{ gap: "0.4rem" }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                style={{ gap: "0.3rem" }}
                            >
                                <HiOutlineClock size={20} />
                                <Typography
                                    sx={{
                                        fontSize: "1.1rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Duration
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                style={{ gap: "1rem" }}
                            >
                                <Typography
                                    style={{
                                        background: "#B6B6FF",
                                        color:
                                            theme.palette.mode === "light"
                                                ? "black"
                                                : "black",
                                        display: "block",
                                        whiteSpace: "nowrap",
                                        padding: "0.3rem 0.6rem",
                                    }}
                                >
                                    {durationValue}:00 s
                                </Typography>
                                <Slider
                                    value={durationValue}
                                    min={1}
                                    max={30}
                                    onChange={handleDurationChange}
                                />
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                textTransform: "capitalize",
                                background: "#6363FF",
                                color: "white",
                                padding: "0.5rem 1.6rem",
                                borderRadius: "1rem",
                                display: "flex",
                                gap: "0.4rem",

                                "&:hover": {
                                    background: "#4949C0",
                                },
                            }}
                            onClick={() => handleGenerateAudioClick()}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                Generate
                            </Typography>
                            <HiOutlineChip size={20} />
                        </Button>
                        <Box
                            sx={{
                                borderRadius: isLoading ? "1rem" : "0rem",
                                border: isLoading
                                    ? "2px solid rgba(145, 158, 171, 0.4)"
                                    : "0px",
                                marginTop: isLoading ? "1.5rem" : "0",
                                padding: "0px",
                            }}
                        >
                            {isLoading ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "300px",
                                        display: "flex",
                                        gap: "0.7rem",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img
                                        alt=""
                                        src={AudioLoader}
                                        style={{
                                            width: "50%",
                                            height: "50%",
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span>
                                            AISEO is generating your Audio
                                        </span>
                                        <HiOutlineMusicNote /> <span>...</span>
                                    </Typography>
                                </Box>
                            ) : recentAudios.length > 0 ? (
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "1.1rem",
                                            fontWeight: "bold",
                                            margin: "2rem 0 0.4rem 0",
                                        }}
                                    >
                                        Recent
                                    </Typography>
                                    <Box
                                        sx={{
                                            borderRadius: "1rem",
                                            border: "2px solid rgba(145, 158, 171, 0.4)",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                padding: "1rem 1.5rem",
                                                borderBottom:
                                                    "1.5px solid rgba(211, 211, 211, 0.7)",
                                            }}
                                        >
                                            <Box sx={{ width: "50%" }}>
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Prompt
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "20%" }}>
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Status
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "30%" }}>
                                                <Typography
                                                    sx={{ fontWeight: "bold" }}
                                                >
                                                    Media
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        {recentAudios.map((item, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    padding: "1rem 1.5rem",
                                                    gap: "0.6rem",
                                                    borderBottom:
                                                        index <
                                                            recentAudios.length - 1
                                                            ? "1.5px solid rgba(211, 211, 211, 0.7)"
                                                            : "",
                                                }}
                                            >
                                                <Box sx={{ width: "50%" }}>
                                                    <Typography>
                                                        {item.initial_prompt}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: "20%" }}>
                                                    <Typography
                                                        sx={{
                                                            background:
                                                                "rgba(111, 111, 250, 0.2)",
                                                            padding: "0.3rem",
                                                            borderRadius:
                                                                "0.7rem",
                                                            width: "fit-content",
                                                        }}
                                                    >
                                                        {item.status}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: "30%" }}>
                                                    <audio
                                                        src={
                                                            item.generated_audio_url
                                                        }
                                                        controls
                                                        autoplay
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    ></audio>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Layout>
    );
};

export default AudioGenerator;
