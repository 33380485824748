import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Slide,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { onGettingIdToken } from "../../../firebaseInit";
import ToastNotification from "../../atoms/ToastNotification";
import NeedToBuyAPlan from "../../model/NeedToBuyAPlan";

import { useAPI } from "../../../StateProvider";

import { setSelectedImageOnStore } from "../../../features/image/CommandSlice";
import { useDispatch } from "react-redux";
import InstructInputBox from "./InstructInputBox";
import InstructOutputBox from "./InstructOutputBox";
import { useNavigate, useLocation } from "react-router-dom";

const InstructImgUpSection = ({ user, credits, productData }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { data } = useAPI();
    const selectedImgFromRoute = location.state?.selectedImageFromRoute;

    // const onImageUpload = () => {
    //     console.log("dsad");
    // };

    const [isLoading, setIsLoading] = useState();
    const [showError, setShowError] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [generatedImage, setGeneratedImage] = useState();
    const [textAreaValue, setTextAreaValue] = useState("");
    const [imgMainFile, setImageMainFile] = useState("");

    const [loginPopup, setLoginPopup] = useState(false);
    const [lockWarning, setLockWarning] = useState(false);

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        if (selectedImgFromRoute) {
            setSelectedImage(selectedImgFromRoute);
            const base64Image = selectedImgFromRoute.replace(
                /^data:image\/(png|jpeg|jpg);base64,/,
                ""
            );

            const byteCharacters = atob(base64Image);
            const byteArrays = [];

            for (let i = 0; i < byteCharacters.length; i++) {
                byteArrays.push(byteCharacters.charCodeAt(i));
            }

            const byteArray = new Uint8Array(byteArrays);
            const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the type according to the image format

            const file = new File([blob], "image.png", { type: "image/png" }); // Adjust the filename and type accordingly
            setImageMainFile(file);
            console.log(selectedImgFromRoute, "sel img from route");
        }
    }, [selectedImgFromRoute]);

    const ImageHandler = async (e) => {
        /* UploadImage === null
          ? window.scrollTo({
              top: inputedImageRef.current.offsetTop + 100,
              left: 0,
              behavior: "smooth",
            })
          : window.scrollTo({
              top: inputedImageRef.current.offsetTop + 200,
              left: 0,
              behavior: "smooth",
            }); */
        const mainfile = e.target.files[0];
        //setSelectedImageMaineFile(mainfile);
        console.log(mainfile);
        //console.log("Top", inputedImageRef.current.offsetTop);
        let imageUrl = URL.createObjectURL(mainfile);
        setImageMainFile(mainfile);
        // let imageUrl = await resizeImageFile(mainfile)

        setSelectedImage(imageUrl);

        console.log("imageUrl", imageUrl);
        // let imgsize = await getImageSize(imageUrl);

        // const ImageSelected = useSelector(
        //   (state) => state.imageInpainting.selectedImage
        // );
        //console.log("ImageSelected selectedImagesSlice", selectedImagesSlice);
        /* setUploadImage(imageUrl);
        setUploadImageForStore([...UploadImageForStore, imageUrl]);
        setUploadedImage([...UploadedImage, mainfile]);
        setSelectredImage(imageUrl); */
        dispatch(
            setSelectedImageOnStore({
                selectedImages: imageUrl,
            })
        );

        // dispatch(
        //   setSelectedImage({
        //     selectedImage: imageUrl,
        //   })
        // );
        //console.log("UploadedImage", UploadedImage);
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setShowErrorPopup(false);
        setShowSuccessPopup(false);

        setIsLoading(true);
        // mixpanel.track("Submitting for image erase and replace ");
        if (!user || !user.emailVerified) {
            //setShowPopup(true);
            setLoginPopup(true);
            setIsLoading(false);
            setTimeout(() => {
                navigate("/login");
            }, 3000);

            return;
        }
        // free user are not allowed to generate..
        if (!data.subscription || data?.subscription.type === "Free") {
            // navigate to pricing page...
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            setLockWarning(true);
            setIsLoading(false);

            return;
        }

        let cost = 1;

        const finalCredit = credits - cost;
        if (finalCredit < 0) {
            console.log("CREDIT LESS");
            //setOpen(true);
            setIsLoading(false);

            return;
        }

        const formElement = e.currentTarget;
        const formData = new FormData(formElement);
        const updatedFormData = new FormData();

        const existingPrompt = formData.get("instruction_text");
        console.log("existingPrompt", existingPrompt);
        updatedFormData.append("instruction_text", textAreaValue);

        // var file = new File([selectedImagesSlice], "name")
        // updatedFormData.append("input_image", file);
        // selectedImagesSlice
        // if (selectedImageMaineFile === null) {

        //   var file = new File([selectedImagesSlice], "name")
        // updatedFormData.append("input_image", file);

        //   // updatedFormData.append("input_image", selectedImageMaineFile);
        // }
        // else{

        // }

        // const existingFile = formData.get("image");

        /* if (typeof selectedImageMaineFile === "object") {
            const resizedFile = await resizeImageFile(selectedImageMaineFile);
            updatedFormData.append("input_image", resizedFile);
        } */

        //console.log("selectedImageMaineFile", typeof selectedImageMaineFile);

        //updatedFormData.append("input_image", selectedImageMaineFile);

        const formProps = Object.fromEntries(updatedFormData);

        console.log("formData: formProps", formProps);

        updatedFormData.append("input_image", imgMainFile);

        // const existingFile = formData.get("image");
        // const resizedFile = await resizeImageFile(selectedImageMaineFile);

        // formData.set("image", resizedFile);

        onGettingIdToken((token) => {
            console.log("Token: ", token);
            console.log("data for instruct_pix2pix:", formProps);
            console.log(updatedFormData, "updatedFormData");
            for (const [key, value] of updatedFormData.entries()) {
                console.log(key, value, "myOne");
            }
            fetch(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/instruct_pix2pix",
                {
                    method: "POST",
                    body: updatedFormData,
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log("Command Response", data);
                    console.log("Command Response", data.data.images);
                    setGeneratedImage(data.data.images);
                    setShowSuccessPopup(true);
                    //setGenerateedImage(data.data.images);
                    setIsLoading(false);

                    // dispatch(
                    //   inpaintingSucceed({
                    //     inpaintedImage: data.data[0],
                    //   })
                    // );

                    // setTimeout(() => {
                    //   dispatch(
                    //     setInpainting({
                    //       inpainting: false,
                    //     })
                    //   );
                    // }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    setShowError(true);
                    setTimeout(() => setShowError(false), 10000);
                    setShowErrorPopup(true);
                    // dispatch(
                    //   inpaintingFaild({
                    //     error: "Something went wrong",
                    //   })
                    // );
                });
        });
        console.log("Targeted Values: ", e.currentTarget);
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Box>
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}
            {isLoading && (
                <ToastNotification
                    message="AISEO is generating your image"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {loginPopup && (
                <ToastNotification
                    message="Please Login"
                    type="neutral"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showErrorPopup && (
                <ToastNotification
                    message="An error occurred, Try again"
                    type="error"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            {showSuccessPopup && (
                <ToastNotification
                    message="Image generated Successfully"
                    type="success"
                    duration={3000}
                    position="bottomLeft"
                />
            )}
            <Box
                sx={{
                    border: `4px dashed ${
                        theme.palette.mode === "light" ? "black" : "#F0F0F0"
                    }`,
                    width: "100%",
                    maxWidth: "900px",
                    borderRadius: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "10rem",
                    margin: "0 auto",
                    cursor: "pointer",
                    position: "relative",
                }}
            >
                <form
                    /* ref={formRef} */
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    /* onClick={() => fileInputRef.current.click()} */
                >
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{
                            padding: { xs: "0px", md: "0px" },
                            fontSize: { xs: "0.9rem", md: "1.2rem" },
                            width: "fit-content",
                            height: "fit-content",
                        }}
                    >
                        Choose files to upload images
                    </Typography>
                    <input
                        style={{
                            opacity: "0",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                        }}
                        type="file"
                        name="image_file"
                        /* ref={fileInputRef} */
                        onChange={ImageHandler}
                    />
                    {/* <ImageFileInput
                        fileInputRef={fileInputRef}
                        onChange={onImageUpload}
                    /> */}
                </form>
            </Box>
            {selectedImage && (
                <Box sx={{ margin: "2rem 0" }}>
                    <Box sx={{ margin: { xs: "0 0 0rem 0", md: "0" } }}>
                        <Box
                            sx={{
                                background:
                                    theme.palette.mode === "light"
                                        ? "#EFEFEF"
                                        : "black",
                                padding: "0.5rem",
                                border: `3px solid ${
                                    theme.palette.mode === "light"
                                        ? "black"
                                        : "#E9E9E9"
                                }`,
                                borderRadius: "0.5rem",
                                width: { xs: "100%", md: "50%" },
                                margin: "0 auto",
                            }}
                        >
                            <Typography>Selected Image</Typography>
                            <img
                                src={selectedImage}
                                alt=""
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "0.5rem",
                                }}
                            />
                        </Box>
                        {/* <ExtraHelperBox productData={productData} /> */}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            height: "fit-content",
                            justifyContent: "center",
                            /* border: "1px solid black", */
                            flexDirection: { xs: "column-reverse", md: "row" },
                            marginTop: "4rem",
                        }}
                        style={{ gap: "2rem" }}
                    >
                        <InstructInputBox
                            productData={productData}
                            handlesubmit={handlesubmit}
                            textAreaValue={textAreaValue}
                            setTextAreaValue={setTextAreaValue}
                        />
                        <InstructOutputBox
                            productData={productData}
                            selectedImage={selectedImage}
                            isLoading={isLoading}
                            generatedImage={generatedImage}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default InstructImgUpSection;
