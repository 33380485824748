/**
 * Firestore data handling includes reading, updating, deleting data (as per requirements)
 * To ensure easily maintainable/readble code let's follow the following patterns
 * 1. Avoid using Promise constructor when possible.
 *    Firestore CRUD operations return promise by default
 * 2. Avoid redundancy in code. Less code, less bug.
 * 3. Remove unnecessary comments
 * 4. Avoid writing giant function/ better split for very specific job
 */

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseInit";
import { onAuth } from "./authHandlers";
const db = getFirestore(firebaseApp);

/**
 * @desc collect all payment docs for a user
 * @param {{ email: string, category: string, used: boolean }} param
 * @returns
 */
export const getPaymentsDocs = async (param) => {
  console.log("param", param);
  const { email, used, category } = param;
  const ref = query(
    collection(db, "payments"),
    where("email", "==", email), // TODO:  On user
    where("used", "==", used), //false initially
    where("category", "==", category) // cate: dreambooth
  );
  const snapshot = await getDocs(ref);

  let docs = [];
  snapshot.forEach((doc) => {
    let data = doc.data();
    console.log(data);
    docs.push({ ...data, pdid: doc.id }); // pdid -> payment doc id
  });

  console.log("docs", docs);
  return docs; // use false thakle api call korte dibo other wise go to checkout.
};

const updateModelStatus = async ({ docId, status, version }) => {
  console.log(status, version);
  const ref = doc(db, "dreambooth", docId);
  await updateDoc(ref, { status: status, version: version });
};

/**
 * @desc collect all model docs for a user
 * @param {{ email: string }} param
 * @returns
 */
export const getModelDocs = async (param) => {
  console.log("Firestore Called :getModelDocs");
  console.log("Email: after ", param.email);
  const ref = query(
    collection(db, "dreambooth"),
    where("email", "==", param.email)
  );
  const snapshot = await getDocs(ref);
  console.log(snapshot);

  const docs = [];
  try {
    snapshot.forEach((doc) => {
      let data = doc.data();
      data.docId = doc.id;
      if (data.model) docs.push(data);
    });
    const finalDocs = docs.map(async (data) => {
      console.log("Fianl data;", data.id);
      // if (data.status && data.status !== "succeeded") {
      //   const result = await checkTrainingStatus({ modelId: data.id });
      //   console.log("result", result);
      //   await updateModelStatus({
      //     docId: data.docId,
      //     status: result.data.status,
      //     version: result.data.version,
      //   });
      //   data.status = result.data.status;
      // }
      return {
        model: data.model,
        version: data.version,
        id: data.id,
        generationLeft: data.generationLeft,
        input: data.input,
        status: data.status,
        mdid: data.docId,
        displayName: data.displayName,
      };
    });
    return Promise.all(finalDocs);
  } catch (error) {
    console.log(error);
    return docs;
  }
};

// export const getGeneratedImages = async (email) => {
//   const ref = query(
//     collection(db, "dreambooth"),
//     where("email", "==", email)
//   );
//   const snapshot = await getDocs(ref);

//   let docs = [];
//   snapshot.forEach((doc) => {
//     let data = doc.data();
//     console.log(data);
//     docs.push(data);
//   });
//   return docs;
// }

export const onGettingGeneratedImages = async (callback) => {
  // console.log("Firestore called. :onGettingGeneratedImages");
  onAuth(async (user) => {
    if (user.email) {
      const ref = query(
        collection(db, "dreambooth"),
        where("email", "==", user.email)
      );
      const snapshot = await getDocs(ref);

      let docs = [];
      snapshot.forEach((doc) => {
        let data = doc.data();
        // console.log(data);
        let newData = {
          ...data,
          docId: doc.id,
        };
        docs.push(newData);
      });
      callback(docs);
    } else {
      callback([]); 
    }
  });
};

// export const updateBulkStatus = (docId) => {
//   return new Promise(function (resolve, reject) {
//     console.log("docId", docId);

//     const ref = collection(db, "dreambooth", docId);
//     ref
//       .updateDoc({
//         bulkStatus: {
//           status: "started",
//         },
//       })
//       .then(() => {
//         console.log("started");
//         resolve("okay");
//       })
//       .catch((err) => console.log("error", err));
//   });
// };

export const updateBulkStatus = async (docId) => {
  const docRef = doc(db, "dreambooth", docId);
  await updateDoc(docRef, {
    bulkStatus: {
      status: "started",
      test: true,
    },
  });
  console.log("update done");
};

export const updateBulkStatus1 = async (docId) => {
  const docRef = doc(db, "dreambooth", docId);
  await updateDoc(docRef, {
    bulkStatus: {
      status: "started",
      test: true,
    },
  });
  console.log("update done");
};

/**
 * @desc create a document in bulkgenerations collection and update generation status
 * @param {Array} param0.templates templates array which provide prompts
 * @param {string} param0.modelId Dreambooth model id
 * @param {string} param0.docId doc id from dreambooth collection
 * @returns
 */
export const createBulkGenerations = async ({ templates, modelId, docId }) => {
  console.log("Api call succ B:", templates, modelId, docId);
  return new Promise(async (resolve, reject) => {
    onAuth(async (user) => {
      try {
        const ref1 = collection(db, "bulkgenerations");
        await addDoc(ref1, {
          email: user.email,
          uid: user.uid,
          templates: templates,
          modelId: modelId,
          type: "dreambooth",
        });

        const ref2 = doc(db, "dreambooth", docId);
        await updateDoc(ref2, {
          bulkStatus: {
            status: "started",
            test: true,
          },
        });

        resolve({ status: "ok" });
        // if (res1 && res2) {
        // }
      } catch (error) {
        resolve({ status: "error" });
      }
    });
  });
};

/**
 * Create a doc in dreambooth collection
 * @param data
 * @returns
 */
export const createNewModelDoc = async (data) => {
  return new Promise((resolve, reject) => {
    onAuth(async (user) => {
      if (user.email) {
        console.log("SUPER DATA", data);
        // ref object.
        const ref = collection(db, "dreambooth");
        addDoc(ref, {
          email: user.email,
          uid: user.uid,
          generationsLeft: 100,
          charecter: data.charecter,
          identifier: data.identifier,
          displayName: data.displayName,
          servingUrl: data.servingUrl,
          instancePrompt: data.instancePrompt,
          classPrompt: data.classPrompt,
          modelName: data.modelName,
          selectedTemplates: data.selectedTemplates,
          paid: false,
        })
          .then((res) => {
            console.log(res.id, "DOC ID");
            resolve(res.id);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      } else {
        reject("User is not logged in.");
      }
    });
  });
};

let textData = {
  servingUrl:
    "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/dreambooth%2F2d5fa192-b9d2-4567-9d54-4afa68175df4%2Fdata.zip?alt=media&token=3b173f93-6dfa-4d42-b6c9-6286f056ac05",
  displayName: "Saikat's model",
  error: null,
  email: "mdabutahersaikat@gmail.com",
  classPrompt: "a photo of a Male",
  paidAmount: 1400,
  instancePrompt: "a photo of a adazmk Male",
  paid: true,
  version: null,
  outputs: [
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/EBgqKcfetmsYgEAujSerxOIXpIYoE8D1XAbguGNG8o7VXGRgA/out-0.png",
    "https://replicate.delivery/pbxt/DEteLu8EKnwEGCLf1PxLUgWhUeZUNbTZOmVH69SMehgovMiAB/out-0.png",
    "https://replicate.delivery/pbxt/DEteLu8EKnwEGCLf1PxLUgWhUeZUNbTZOmVH69SMehgovMiAB/out-0.png",
    "https://replicate.delivery/pbxt/DEteLu8EKnwEGCLf1PxLUgWhUeZUNbTZOmVH69SMehgovMiAB/out-0.png",
  ],
  selectedTemplates: ["Artistic&Modern", "tinder"],
  uid: "rX3A2OuxbsYzptQzQyOkOd4ZMRk2",
  model: "dilyarbuzan/akupvc7svue",
  input: {
    instance_data:
      "https://firebasestorage.googleapis.com/v0/b/code-translate-c9640.appspot.com/o/dreambooth%2F2d5fa192-b9d2-4567-9d54-4afa68175df4%2Fdata.zip?alt=media&token=3b173f93-6dfa-4d42-b6c9-6286f056ac05",
    instance_prompt: "a photo of a adazmk Male",
    max_train_steps: 2000,
    class_prompt: "a photo of a Male",
  },
  identifier: "adazmk",
  modelName: "dilyarbuzan/akupvc7svue",
  webhook_completed:
    "https://us-central1-code-translate-c9640.cloudfunctions.net/webhookReplicate",
  generationsLeft: 17,
  logs: null,
  charecter: "Male",
  created_at: "2022-12-10T12:05:47.035718Z",
  status: "succeeded",
  id: "n6y3zmb6wnh7bnb6jds3bfdyyi",
  docId: "LkqIoxzx7ePD7cA7LGs7",
};

export const saveThisImageTodatabaseAndConsumeCredits = async (
  imageUrl,
  selectedPackagesRedux
) => {
  let modelObj = selectedPackagesRedux.generationPageSelectedModel;
  // let modelObj = textData;
  let modelId = modelObj.docId;
  // console.log("MODEL ID", modelId);
  const ref = doc(db, "dreambooth", modelId);
  // const snap = doc(db, "dreambooth", docId);
  const snapshot = await getDoc(ref);
  const data = snapshot.data();
  // console.log("DATA", data);
  // checkout here.
  if (data.hasOwnProperty("outputs") && data.outputs.length !== 0) {
    console.log("1");
    // it's already have the output...
    let newOutputs = [...data.outputs, imageUrl];
    let generationLeft = modelObj.generationsLeft - 1;

    updateDoc(ref, {
      outputs: newOutputs,
      generationsLeft: generationLeft,
    });
  } else {
    console.log("2");
    let generationLeft = modelObj.generationsLeft - 1;
    // this is the first one...
    updateDoc(ref, {
      outputs: [imageUrl],
      generationsLeft: generationLeft,
    });
  }
};
