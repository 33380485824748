import {
    Alert,
    Box,
    Grid,
    Slide,
    Tooltip,
    Typography,
    TextField,
    Button,
} from "@mui/material";
import {
    HiOutlineChip,
} from "react-icons/hi";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onGettingIdToken } from "../../firebaseInit";
import { useAPI } from "../../StateProvider";
import CanvasImage from "../CanvasImage";
import DialogModal from "../form/DialogModal";
import WarningModal from "../form/WarningModal";
import NeedToBuyAPlan from "../model/NeedToBuyAPlan";
import { useTheme } from "@mui/material/styles";

import mixpanel from "mixpanel-browser";
import { AiFillPlayCircle } from "react-icons/ai";
import {
    fileUploaded,
    inpaintingFaild,
    inpaintingStarted,
    inpaintingSucceed,
    setInpainting,
} from "../../features/image/ImageInpainting";

/*
const DashedBox = styled(Box)(({ theme }) => ({
    border: "3px dashed",
    borderColor: theme.palette.mode === "dark" ? "gray" : "lightgray",
    borderRadius: "15px",

    "&:hover": {
        cursor: "pointer",
    },
}));

const InputBox = styled(Box)(({ theme }) => ({
    display: "flex",
    marginTop: "20px",
    border: "1px solid",
    borderColor: "lightgray",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
}));

const InpaintButton = styled(ButtonBase)(({ theme }) => ({
    fontSize: ".85rem",
    padding: theme.spacing(0.5, 2),
    fontWeight: 600,
    color: theme.palette.mode === "dark" ? "black" : "white",
    background: theme.palette.mode === "dark" ? "white" : "black",
    width: "180px",
    "&:hover": {},
}));
*/

// Utilities Functions
function clearCanvas(elem) {
    console.log(elem);
    const ctx = elem.getContext("2d");
    ctx.clearRect(0, 0, elem.width, elem.height);
}

/**
 * add white pixelated background
 * Convert canvas element to file
 */
function getMaskFromCanvas(canvas) {
    const width = canvas.width;
    const height = canvas.height;

    const maskCanvas = document.createElement("canvas");
    const ctx = maskCanvas.getContext("2d");

    maskCanvas.width = width;
    maskCanvas.height = height;
    //apply the old canvas to the new one
    ctx.drawImage(canvas, 0, 0);
    ctx.globalCompositeOperation = "destination-over";
    // Now draw!
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    //return the new canvas
    return new Promise((resolve) => {
        maskCanvas.toBlob((blob) => {
            let file = new File([blob], "mask.png", { type: "image/png" });
            resolve(file);
        }, "image/png");
    });
}

async function getImageSize(src) {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            let h = this.height;
            let w = this.width;
            if (w > 512) {
                let ratio = 512 / w;
                console.log(ratio);
                let revisedHeight = parseInt(h * ratio);
                console.log(revisedHeight);
                resolve({
                    height: revisedHeight,
                    width: 512,
                });
            } else {
                resolve({
                    height: this.height,
                    width: this.width,
                });
            }
        };
        image.src = src;
    });
}

async function resizeImageFile(file) {
    let url = URL.createObjectURL(file);
    let optimizedSize = await getImageSize(url);
    return new Promise((resolve, reject) => {
        let w = optimizedSize.width;
        let h = optimizedSize.height;
        let reader = new FileReader();
        reader.onload = function (readerEvent) {
            let image = new Image();
            image.onload = function (imageEvent) {
                let canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                canvas.getContext("2d").drawImage(image, 0, 0, w, h);
                canvas.toBlob((blob) => {
                    let file = new File([blob], "image.png", {
                        type: "image/png",
                    });
                    resolve(file);
                }, "image/png");
                // base64 data url
                // let resizedImage = canvas.toDataURL("image/png");
                // resolve(resizedImage)
            };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}

function ImageImpaintingUploadField({
    user,
    credits,
    imageVarient,
    imagePainting,
}) {
    const theme = useTheme();

    const openFile = useRef();
    const [UploadImage, setUploadImage] = React.useState(null);

    // const [mask, setMask] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    // use user selected/written prompt
    const [showPopup, setShowPopup] = React.useState(false);
    const [lockWarning, setLockWarning] = React.useState(false);

    const { data } = useAPI();

    const dispatch = useDispatch();

    const inputedImageRef = React.useRef(null);
    const maskedImageRef = React.useRef(null);
    const canvasRef = useRef(null);
    // get data from redux
    const generatedImageSelected = useSelector(
        (state) => state.imageInpainting.selectedImageInpainting
    );

    // boolean value to track api call
    const inpainting = useSelector((state) => state.imageInpainting.inpainting);

    const openFilehandler = () => {
        openFile.current.click();
    };

    const ImageHandler = async (e) => {
        UploadImage === null
            ? window.scrollTo({
                  top: inputedImageRef.current.offsetTop + 100,
                  left: 0,
                  behavior: "smooth",
              })
            : window.scrollTo({
                  top: inputedImageRef.current.offsetTop + 200,
                  left: 0,
                  behavior: "smooth",
              });
        const mainfile = e.target.files[0];
        console.log(mainfile);
        console.log("Top", inputedImageRef.current.offsetTop);
        let imageUrl = URL.createObjectURL(mainfile);
        // let imageUrl = await resizeImageFile(mainfile)
        // console.log(imageUrl)
        let imgsize = await getImageSize(imageUrl);
        setUploadImage(imageUrl);
        dispatch(
            fileUploaded({
                selectedImageInpainting: imageUrl,
                inpaintedImage: "",
                canvasWidth: imgsize.width,
                canvasHeight: imgsize.height,
            })
        );
    };

    const handlesubmit = async (e) => {
        mixpanel.track("Submitting for image erase and replace ");
        e.preventDefault();
        if (!user || !user.emailVerified) {
            setShowPopup(true);
            return;
        }
        // free user are not allowed to generate..
        if (!data.subscription || data?.subscription.type === "Free") {
            // navigate to pricing page...
            console.log("SUBSCRIPTION NOT DEFIENEDs");
            setLockWarning(true);
            return;
        }

        let cost = 1;
        // if (sizeTitle === "Square") cost = EXPENSE_RATE.smallImageGen;
        // else cost = EXPENSE_RATE.mediumImageGen;

        const finalCredit = credits - cost;
        if (finalCredit < 0) {
            console.log("CREDIT LESS");
            setOpen(true);
            return;
        }

        // const imageSize = await getImageSize("https://replicate.delivery/pbxt/3gTCeYmiiv1KU62MKAGwzUTSZPdWQMIMNJikYVMqv1Ggo0fPA/out-0.png")
        // dispatch(
        //     fileUploaded({
        //         selectedImageInpainting: "https://replicate.delivery/pbxt/3gTCeYmiiv1KU62MKAGwzUTSZPdWQMIMNJikYVMqv1Ggo0fPA/out-0.png",
        //         inpaintedImage: "",
        //         canvasWidth: imageSize.width,
        //         canvasHeight: imageSize.height,
        //     })
        // );

        // console.log(canvasRef, "CANVAS REF");
        // clearCanvas(canvasRef.current);
        // return;
        // window.scrollTo({
        //     top: inputedImageRef.current.offsetTop + 400,
        //     left: 0,
        //     behavior: "smooth",
        // });

        const formElement = e.currentTarget;
        const maskFile = await getMaskFromCanvas(canvasRef.current);
        const formData = new FormData(formElement);
        const formProps = Object.fromEntries(formData);

        console.log("formData: formProps", formProps);
        formData.append("mask", maskFile);
        formData.append("service", "inpaint");

        const existingFile = formData.get("image");
        const resizedFile = await resizeImageFile(existingFile);
        formData.set("image", resizedFile);
        console.log(formData.get("image"));

        onGettingIdToken((token) => {
            console.log("Token: ", token);
            console.log("data for inpaintain:", formData);
            fetch(
                "https://us-central1-code-translate-c9640.cloudfunctions.net/image_inpaint",
                // "https://us-central1-code-translate-c9640.cloudfunctions.net/image_to_image",
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log("Inpainted Response", data.data);
                    // setHaveResponseData(data.data);
                    clearCanvas(canvasRef.current);
                    dispatch(
                        inpaintingSucceed({
                            inpaintedImage: data.data[0],
                        })
                    );

                    setTimeout(() => {
                        dispatch(
                            setInpainting({
                                inpainting: false,
                            })
                        );
                    }, 1500);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(
                        inpaintingFaild({
                            error: "Something went wrong",
                        })
                    );
                });
        });
        console.log("Targeted Values: ", e.currentTarget);
    };

    const changeSelectedImageHandler = () => {
        dispatch(
            inpaintingSucceed({
                inpaintedImage: generatedImageSelected,
            })
        );
    };

    const handlePopupToggle = (val) => {
        if (val === "open") {
            setShowPopup(true);
        } else {
            setShowPopup(false);
        }
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <>
            <WarningModal
                open={showPopup}
                onToggle={handlePopupToggle}
                message="Please log in with your account or make a new one to start generating images."
            />
            <DialogModal
                open={open}
                setOpen={setOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                type="none"
            />

            {/* <Box sx={{ py: 2 }}>
                <SubHead>Inpainting</SubHead>
                <Typography gutterBottom>
                    Image inpainting let you change a very specific portion of
                    an image.
                </Typography>
            </Box> */}

            {/* IF User is free, Show the buy plan popup model */}
            {lockWarning ? (
                <NeedToBuyAPlan
                    lockWarning={lockWarning}
                    setLockWarning={setLockWarning}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                    type="free"
                />
            ) : null}

            <form
                onSubmit={(e) => {
                    dispatch(inpaintingStarted());
                    handlesubmit(e);
                }}
                style={{ display: "flex", flexDirection: "column" }}
            >
                {/* <DashedBox sx={{ position: "relative" }} align="center"> */}
                <Box
                    sx={{
                        border: `4px dashed ${
                            theme.palette.mode === "light" ? "black" : "#F0F0F0"
                        }`,
                        width: "100%",
                        maxWidth: "900px",
                        borderRadius: "2rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "10rem",
                        margin: "0 auto",
                        cursor: "pointer",
                        position: "relative",
                    }}
                >
                    <Typography
                        onClick={openFilehandler}
                        variant="h6"
                        color="text.secondary"
                        sx={{
                            padding: { xs: "0px", md: "0px" },
                            fontSize: { xs: "0.9rem", md: "1.2rem" },
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        Choose files to upload images
                    </Typography>
                    {imageVarient ? (
                        <input
                            style={{ display: "none" }}
                            // accept=".jpg,.jpeg,.png"
                            type="file"
                            name="image"
                            // name="init_image"
                            onChange={(e) => ImageHandler(e)}
                            ref={openFile}
                        />
                    ) : (
                        <input
                            style={{ display: "none" }}
                            // accept=".jpg,.jpeg,.png"
                            type="file"
                            name="image"
                            onChange={(e) => ImageHandler(e)}
                            ref={openFile}
                        />
                    )}
                    <a
                        href="https://www.loom.com/share/2d189662934d4827a032acbe10996e96"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Tooltip title="A tutorial on how Erase & Replace works.">
                            <span
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    /* zIndex: 100, */
                                }}
                            >
                                <AiFillPlayCircle fontSize="30" />
                            </span>
                        </Tooltip>
                    </a>
                </Box>
                {/* </DashedBox> */}

                {/* CanvasImage Component */}
                {/* {selectedImage && (
                <Box sx={{ margin: "2rem 0" }}>
                    <Box sx={{ margin: { xs: "0 0 0rem 0", md: "0" } }}>
                        <Box
                            sx={{
                                background:
                                    theme.palette.mode === "light"
                                        ? "#EFEFEF"
                                        : "black",
                                padding: "0.5rem",
                                border: `3px solid ${
                                    theme.palette.mode === "light"
                                        ? "black"
                                        : "#E9E9E9"
                                }`,
                                borderRadius: "0.5rem",
                                width: { xs: "100%", md: "50%" },
                                margin: "0 auto",
                            }}
                        >
                            <Typography>Selected Image</Typography>
                            <img
                                src={selectedImage}
                                alt=""
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginTop: "0.5rem",
                                }}
                            />
                        </Box>
                        <ExtraHelperBox productData={productData} />
                    </Box>

                   
                </Box>
            )} */}
                {generatedImageSelected && (
                    <Box sx={{ margin: "2rem 0" }}>
                        <Box sx={{ margin: { xs: "0 0 0rem 0", md: "0" } }}>
                            <Box
                                sx={{
                                    background:
                                        theme.palette.mode === "light"
                                            ? "#EFEFEF"
                                            : "black",
                                    padding: "0.5rem",
                                    border: `3px solid ${
                                        theme.palette.mode === "light"
                                            ? "black"
                                            : "#E9E9E9"
                                    }`,
                                    borderRadius: "0.5rem",
                                    width: { xs: "100%", md: "50%" },
                                    margin: "0 auto",
                                }}
                            >
                                <Typography>Selected Image</Typography>
                                <div
                                    className="image_section"
                                    style={{ width: "100%" }}
                                >
                                    {/* <p>Duhan </p> */}
                                    <img
                                        onClick={() =>
                                            changeSelectedImageHandler()
                                        }
                                        className="c-pointer"
                                        width={200}
                                        height={100}
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            marginTop: "0.5rem",
                                            borderRadius: "0.5rem",
                                        }}
                                        /* style={{
                                        objectFit: "cover",
                                        // height: "300px",
                                        border: ` ${"3px solid #cb56fd"} `,
                                        cursor: "pointer",
                                        margin: "10px",
                                        padding: "3px",
                                        borderRadius: "10px",
                                    }} */
                                        src={generatedImageSelected}
                                        alt="UploadImage"
                                    />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                )}
                {generatedImageSelected && (
                    <CanvasImage
                        maskedImageRef={maskedImageRef}
                        canvasRef={canvasRef}
                        // setMask={setMask}
                        generatedImageSelected={generatedImageSelected}
                    />
                )}

                <Grid
                    container
                    ref={inputedImageRef}
                    /* sx={{ width: "90vw", background: "red" }} */
                >
                    {generatedImageSelected && (
                        <Grid item xs={12} lg={8}>
                            <Box
                                align="center"
                                sx={{
                                    // marginTop: "100px",
                                    display: "flex",
                                    flexDirection: "column",
                                    // border: "3px dashed #e6e6e6",
                                    borderRadius: "15px",
                                    /* padding: "30px 100px", */
                                    width: "fit-content",
                                    margin: "0 auto",
                                }}
                            >
                                {inpainting && (
                                    <Alert severity="info" sx={{ m: 1 }}>
                                        Your image is being erased and replaced.
                                        Please wait until it's done.
                                    </Alert>
                                )}
                                <Box
                                    sx={{
                                        width: "350px",
                                        margin: "2rem auto 0 auto",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                    }}
                                    style={{
                                        gap: "1rem",
                                    }}
                                >
                                    <TextField
                                        placeholder="Your text (the change you want in place erased portion)"
                                        label="Your Prompt"
                                        multiline
                                        rows={3}
                                        name="prompt"
                                        required
                                        sx={{
                                            width: "100%",
                                            border: "none",
                                            outline: "none",
                                            /* "& textarea": {
                                resize: "vertical",
                                maxHeight: "150px",
                            }, */
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        sx={{
                                            py: "0.5rem",
                                            px: "1.5rem",
                                            width: "fit-content",
                                            /*  height: "2.5rem", */
                                            borderRadius: "2rem",
                                            background:
                                                "linear-gradient(25deg, #6363FF 10%, #281142 90%)",
                                            color: "white",
                                            fontSize: "0.9rem",
                                            transition: "none",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                            border: "1.6px solid white",
                                            boxShadow: "none",
                                            /* margin: "0 auto", */

                                            "&:hover": {
                                                background:
                                                    "linear-gradient(85deg, #6363FF 10%, #281142 90%)",
                                                transition: "all 0.3s ease-in",
                                                /* boxShadow: " 1px 7px 400px -2px black", */
                                            },
                                        }}
                                        style={{
                                            gap: "0.3rem",
                                        }}
                                        /* onClick={(e) => handleSubmit(e)} */
                                    >
                                        <>
                                            <Typography
                                                sx={{ fontSize: "1rem" }}
                                            >
                                                Submit
                                            </Typography>
                                            <span style={{ marginTop: "3px" }}>
                                                <HiOutlineChip size={20} />
                                            </span>
                                        </>
                                    </Button>
                                    {/* <InputBase
                                        sx={{
                                            width: "100%",
                                            fontSize: "1rem",
                                            padding: ".25rem 1rem",
                                        }}
                                        name="prompt"
                                        required
                                        type="text"
                                        placeholder="Your text (the change you want in place erased portion)"
                                    /> */}

                                    {/* <input
                                        style={{
                                            border: "none",
                                            width: "100%",
                                            fontSize: "16px",
                                            color: "",
                                            padding: "0px 10px",
                                            height: "42px",
                                            borderTopLeftRadius: "5px",
                                            borderBottomLeftRadius: "5px",
                                        }}
                                        name="prompt"
                                        type="text"
                                    /> */}
                                    {/* <InpaintButton
                                        type="submit"
                                        disableElevation
                                        disableRipple
                                        disabled={inpainting}
                                    >
                                        Erase & Replace
                                    </InpaintButton> */}
                                    {/* <button type="submit">Submit dihan</button> */}
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </form>
        </>
    );
}

/*
const sizes = [
    { id: 1, width: 512, height: 512, title: "Square" },
    { id: 2, width: 768, height: 512, title: "Horizontal" },
    { id: 3, width: 512, height: 768, title: "Vertical" },
];
*/

export default ImageImpaintingUploadField;
