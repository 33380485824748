import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import {
    Typography,
    Box,
    Button,
    IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineX,
} from "react-icons/hi";
// import headshotGenExampleImage from "../../imgs/headshotGenExampleImage.png";
// import circleRegenrateIcon from "../../imgs/circleRegenrateIcon.png";
import starsReviewHeadshotPopup from "../../imgs/starsReviewHeadshotPopup.png";
import headshotPopupCoverImg from "../../imgs/headshotPopupCoverImg.png";
import headshotUpgradePopupImg2 from "../../imgs/headshotUpgradePopupImg2.png";
import arrowTopIconHeadshotPitch from "../../imgs/arrowTopIconHeadshotPitch.svg";
import camFlashIconHeadshotPitch from "../../imgs/camFlashIconHeadshotPitch.svg";
import starsHeadshotIcon from "../../imgs/starsHeadshotIcon.png";
import umbrellaHeadshotIcon from "../../imgs/umbrellaHeadshotIcon.png";

// import arrSvg from "../../imgs/arrSvg.svg";
// import { saveAs } from "file-saver";
// import { firebaseApp, getFirebaseAuthToken } from "../../firebaseInit";
// import { useAPI } from "../../StateProvider";
// import ToastNotification from "../atoms/ToastNotification";

const UpgradePopupHeadshot = ({ closeUpgradePopup }) => {


    const navigate = useNavigate();

    useEffect(() => {

        mixpanel.track("Upgrade Popup seen", {
            Description:
                "Headshots - page - Upgrade Popup seen",
        });

    }, [])

    const handleNavigation = async () => {
        //closeUpgradePopup();

        mixpanel.track("Pricing page visited", {
            Description: "Headshots - page - Pricing page visited",
        }, () => {
            // Navigate after the tracking event has been sent
            navigate("/pricing");
        });





    }
    return (
        <Box
            sx={{
                background: "rgba(15, 23, 42, 0.4)",
                position: "fixed",
                backdropFilter: "blur(5px)",
                left: "0",
                bottom: "0",
                right: "0",
                top: "0",
                zIndex: "1000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    background: "white",
                    color: "rgba(15, 23, 42, 1)",
                    maxWidth: { xs: "100vw", md: "70vw" },
                    display: "flex",
                    gap: "3rem",
                    borderRadius: "10px",
                    padding: { xs: "1.4rem", md: "3.7rem" },
                    position: "relative",
                    height: { xs: "100vh", md: "fit-content" },
                    overflowY: "scroll",
                }}
            >
                <IconButton
                    sx={{
                        position: "absolute",
                        top: "12px",
                        right: "12px",
                    }}
                    onClick={handleNavigation}
                >
                    <HiOutlineX size={25} />
                </IconButton>
                <Box>
                    <Typography
                        sx={{
                            /*  color: "#0F172A", */
                            /* h1 */
                            fontFamily: "Inter",
                            fontSize: { xs: "22px", md: "48px" },
                            fontStyle: "normal",
                            fontWeight: "800",
                            lineHeight: { xs: "24px", md: "55px" },
                            letterSpacing: "-0.576px",
                            maxWidth: "477px",
                            marginBottom: "24px",
                        }}
                    >
                        Upgrade to Premium & Unlock Limitless Possibilities!
                    </Typography>
                    <Typography
                        sx={{
                            /*  color: "#0F172A", */
                            /* h1 */
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px" /* 114.583% */,
                            maxWidth: "382px",
                            marginBottom: "24px",
                            display: { xs: "none", md: "block" },
                        }}
                    >
                        Say goodbye to limitations and craft the perfect
                        headshot for every occasion.
                    </Typography>
                    <Box
                        sx={{
                            display: { xs: "block", md: "none" },
                        }}
                    >
                        <img
                            alt=""
                            src={headshotUpgradePopupImg2}
                            style={{ width: "80vw" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.6rem",
                            alignItems: "start",
                            margin: "0.7rem 0",
                        }}
                    >
                        <img
                            alt=""
                            src={arrowTopIconHeadshotPitch}
                            style={{
                                width: "21px",
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: { xs: "16px", md: "20px" },
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Unlimited Uploads
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    lineHeight: "17px" /* 142.857% */,
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Don't limit your creativity; upload as many
                                photos as you want!
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.6rem",
                            alignItems: "start",
                            margin: "0.7rem 0",
                        }}
                    >
                        <img
                            alt=""
                            src={camFlashIconHeadshotPitch}
                            style={{
                                width: "21px",
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: { xs: "16px", md: "20px" },
                                    marginBottom: "0.3rem",
                                }}
                            >
                                4-for-1 Special
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    lineHeight: "17px" /* 142.857% */,
                                    marginBottom: "0.3rem",
                                }}
                            >
                                For every photo you upload, receive Four
                                distinct headshot variations.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.6rem",
                            alignItems: "start",
                            margin: "0.7rem 0",
                        }}
                    >
                        <img
                            alt=""
                            src={starsHeadshotIcon}
                            style={{
                                width: "21px",
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: { xs: "16px", md: "20px" },
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Advanced AI Magic
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    lineHeight: "17px" /* 142.857% */,
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Experience our enhanced AI algorithms for
                                premium-quality headshot transformations.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "0.6rem",
                            alignItems: "start",
                            margin: "0.7rem 0",
                        }}
                    >
                        <img
                            alt=""
                            src={umbrellaHeadshotIcon}
                            style={{
                                width: "21px",
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontStyle: "normal",
                                    fontWeight: "800",
                                    lineHeight: { xs: "16px", md: "20px" },
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Priority Support
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    lineHeight: "17px" /* 142.857% */,
                                    marginBottom: "0.3rem",
                                }}
                            >
                                Get top-tier assistance whenever you need it.
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <img
                            alt=""
                            src={starsReviewHeadshotPopup}
                            style={{
                                width: "88px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "800",
                                lineHeight: "20px" /* 142.857% */,
                                marginBottom: "0.3rem",
                            }}
                        >
                            4.9/5 (Loved by 250,000+ Users)
                        </Typography>
                    </Box>

                    <Button
                        /* component="span" */
                        onClick={handleNavigation}
                        sx={{
                            background: "#6363FF",
                            color: "white",
                            borderRadius: "70px",
                            textTransform: "capitalize",
                            padding: "12px 27px",
                            marginTop: "30px",
                            /* pointerEvents: image && genderInputVal ? "auto" : "none", */
                            "&:hover": {
                                background: "#4E4EC8",
                                color: "white",
                            },
                        }}
                        disableElevation
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                            }}
                        >
                            Upgrade Now
                        </Typography>
                        {/* {isLoading && (
                    <CircularProgress
                        size={20}
                        sx={{
                            color: "white",
                            marginLeft: "0.7rem",
                        }}
                    />
                )} */}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: "359px",
                        display: { xs: "none", md: "inline-flex" },
                    }}
                >
                    <img
                        alt=""
                        src={headshotPopupCoverImg}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UpgradePopupHeadshot;
