import { grey } from "@mui/material/colors";
import React from "react";

export const getDesignTokens = (mode) => ({
    palette: {
        primary: {
            main: "#6363FF", // Replace with your desired primary color
        },
        mode,
        ...(mode === "light"
            ? {
                  // palette values for light mode
                  text: {
                      primary: grey[900],
                      secondary: grey[800],
                  },
                  navbg: {
                      main: "rgba(255,255,255,.9)",
                  },
                  background: {
                      default: "#fcfcfc",
                      paper: "#fcfcfc",
                  },
              }
            : {
                  // palette values for dark mode
                  background: {
                      default: "#121212",
                      paper: "#121212",
                  },
                  text: {
                      primary: "#e0e0e0",
                      secondary: grey[500],
                  },
                  navbg: {
                      main: "rgba(0,0,0,.6)",
                  },
              }),
    },
});

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {},
});
