import React, { useState } from "react";
import { Box, Typography, TextField, Slider, Button } from "@mui/material";

const OptionsBadgeBox = ({ options, labelTitle, handleOptionValChange }) => {
    const [optionItemSelected, setOptionItemSelected] = useState(null);

    const handleOptionToggle = (item) => {
        //console.log(item.value);
        setOptionItemSelected(item.value);
        handleOptionValChange(item.value, labelTitle);
    };
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
                flexWrap: "wrap",
            }}
        >
            {options.map((item, index) => (
                <Box
                    sx={{
                        padding: { xs: "8px 12.5px", md: "12px 20px" },
                        borderRadius: "1.6rem",
                        fontFamily: "Inter",
                        /* background: "#1E293B", */
                        background:
                            optionItemSelected === item.value
                                ? "#A855F7"
                                : "#1E293B",
                        /* background: isOptionSelected ? "#A855F7" : "#1E293B", */
                        width: "fit-content",
                        cursor: "pointer",
                        color: "white",
                        "&:hover": {
                            background: "#A855F7",
                            transition: "all 0.2s ease-in-out",
                        },
                        fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                    onClick={() => handleOptionToggle(item)}
                >
                    {item.value}
                </Box>
            ))}
        </Box>
    );
};

export default OptionsBadgeBox;
