import React from "react";
import { Box, Typography } from "@mui/material";

const EmptyGenCard = ({ emptyTitle, emptyDescription, emptyImgSrc }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3rem",
                background: "#FBFCFF",
                border: "2px solid #EAEFFF",
                borderRadius: "0.7rem",
                padding: "1.6rem 2rem",
                width: { xs: "80vw", md: "fit-content" },
                flexDirection: { xs: "column-reverse", md: "row" },
                margin: { xs: "0 auto", md: "0" },
                height: "fit-content",
            }}
        >
            <Box
                sx={{
                    maxWidth: { xs: "fit-content", md: "420px" },
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: { xs: "19px", md: "30px" },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: { xs: "23px", md: "36px" },
                        letterSpacing: "-0.225px",
                    }}
                >
                    {emptyTitle}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: { xs: "0.9rem", md: "18px" },
                        fontStyle: "normal",
                        fontWeight: { xs: "normal", md: "500" },
                        lineHeight: { xs: "1rem", md: "29px" },
                    }}
                >
                    {emptyDescription}
                </Typography>
            </Box>
            <img
                src={emptyImgSrc}
                style={{ width: window.innerWidth < 768 ? "250px" : "350px" }}
            />
        </Box>
    );
};

export default EmptyGenCard;
